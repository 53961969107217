import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import EllipsisLoader from '../EllipsisLoader';
import SlideOut from '../SlideOut';
import DateRangeMenuInputs from './DateRangeMenuInputs';
import DateRangeQuickSelect from './DateRangeQuickSelect';

const DateRangeMenu = (props) => {
  const cardWidth = 500;
  const phoneSize = 415;
  const menu = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const currentX = menu.current.getBoundingClientRect().x;
    const screenSize = window.innerWidth;
    if (cardWidth + currentX > screenSize) {
      const offsetValue = screenSize - (cardWidth + currentX);
      setOffset((prevValue) => prevValue + offsetValue);
    }
    if (screenSize <= phoneSize) {
      setOffset(0);
    }
  }, [menu.current, offset]);

  return (
    <div ref={menu} style={{ left: `${offset}px` }} className="drs-card">
      <div className="card-header">
        <h4 className="drs-card-header-title">Date Filter</h4>
        <button className="close float-right" onClick={props.handleShowHide}>
          <span>&times;</span>
        </button>
      </div>
      <span className="drs-flex">
        <SlideOut hide={!props.isLoading} width="499px">
          <div className="card-body drs-body-padding drs-pb-12">
            <div className="drs-loader-container">
              <div className="drs-loader">
                <EllipsisLoader text="Loading" />
              </div>
            </div>
          </div>
        </SlideOut>
        <SlideOut hide={props.isLoading} width="499px">
          <div className="card-body drs-body-padding">
            <DateRangeQuickSelect
              activeDateObject={props.activeQuickDateObject}
              onSelection={props.handleQuickSelectClick}
              disabled={props.disabled || props.isLoading}
              additionalOptions={props.additionalOptions}
              removeItems={props.removeItems}
            />
          </div>
        </SlideOut>
      </span>
      {props.includeCustomSelect ? (
        <div className="drs-custom-range-section">
          <h4>Custom Range</h4>
          <div className="drs-h-39">
            <DateRangeMenuInputs
              dates={props.dates}
              currentOperation={props.currentOperation}
              isLoading={props.isLoading}
              onChange={props.handleDatePickerChange}
              disabled={props.disabled || props.isLoading}
            />
          </div>
        </div>
      ) : (
        <span />
      )}
      <div className="card-body drs-body-padding--footer">
        <button
          type="button"
          className="btn btn-primary drs-button-override drs-action-button float-right drs-margin-25"
          onClick={props.handleApply}
          disabled={!props.activeApply || props.disabled || props.isLoading}
        >
          {props.intl.formatMessage({ id: 'apply' })}
        </button>
        <button
          type="button"
          onClick={props.handleShowHide}
          className="btn btn-secondary drs-action-button"
        >
          {props.intl.formatMessage({ id: 'close' })}
        </button>
      </div>
    </div>
  );
};

DateRangeMenu.propTypes = {
  intl: PropTypes.shape({}),
  dates: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  currentOperation: PropTypes.shape({
    id: PropTypes.string,
    calendars: PropTypes.number,
  }),
  activeQuickDateObject: PropTypes.string,
  activeApply: PropTypes.bool,
  disabled: PropTypes.bool,
  handleShowHide: PropTypes.func,
  handleDatePickerChange: PropTypes.func,
  handleQuickSelectClick: PropTypes.func,
  handleApply: PropTypes.func,
  isLoading: PropTypes.bool,
  includeCustomSelect: PropTypes.bool,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      from: PropTypes.shape({}),
      until: PropTypes.shape({}),
    })
  ),
  removeItems: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(DateRangeMenu);
