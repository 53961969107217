import { add, isNaN, map } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { parseNumber } from '../../../utils/number-util';
import { fetchEmployees } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import * as actions from '../actions';
import {
  selectCategories,
  selectPurchaseOrderInEdit,
  selectVendors,
} from '../purchaseOrderReducer';
import PurchaseOrderForm from './PurchaseOrderForm';

const PurchaseOrderFormWrapper = (props) => {
  const calculateFormItems = () => {
    const { fields, setSubTotal } = props;
    const addedAmount = map(fields, (field) => {
      if (!field || isNaN(field.quantity * parseNumber(field.price || ''))) {
        return 0;
      }
      return field.quantity * parseNumber(field.price);
    });

    setSubTotal(addedAmount);
  };

  const calculateWithDiscount = (cost, discount) => {
    const costWithDiscount = cost - (parseNumber(discount) || 0);
    if (costWithDiscount < 0) {
      return 0;
    }

    return costWithDiscount;
  };

  const calculateFormTotals = () => {
    const { taxAmount, shippingAmount, setAdditionalCost } = props;

    const additionalCost = add(parseNumber(taxAmount), parseNumber(shippingAmount));
    setAdditionalCost(additionalCost);
  };

  useEffect(() => {
    props.fetchEmployees();
    props.fetchPurchaseOrderCategories();
    props.fetchPurchaseOrderVendors();
  }, []);

  useEffect(calculateFormItems, [props.fields]);
  useEffect(calculateFormTotals, [
    props.taxAmount,
    props.shippingAmount,
    props.discount,
    props.fields,
  ]);

  return <PurchaseOrderForm {...props} calculateWithDiscount={calculateWithDiscount} />;
};

PurchaseOrderFormWrapper.defaultProps = {
  categories: [],
  vendors: [],
  shippingAmount: '0',
  taxAmount: '0',
  discount: '0',
};

PurchaseOrderFormWrapper.propTypes = {
  fetchPurchaseOrderVendors: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  vendors: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  taxAmount: PropTypes.string,
  shippingAmount: PropTypes.string,
  discount: PropTypes.string,
  // eslint-disable-next-line
  initialValues: PropTypes.shape({}),
  isNew: PropTypes.bool, // eslint-disable-line
};

const initialFormValues = () => ({
  orderDate: moment().format('YYYY-MM-DD'),
  trackingNumber: '',
  memo: '',
});

const selector = formValueSelector('purchaseOrder');
const mapStateToProps = (state, ownProps) => {
  const initialValues = ownProps.isNew
    ? initialFormValues(state)
    : selectPurchaseOrderInEdit(state) || ownProps.initialValues;
  return {
    categories: selectCategories(state),
    vendors: selectVendors(state),
    employees: selectEmployees(state),
    fields: selector(state, 'items'),
    taxAmount: selector(state, 'taxAmount'),
    shippingAmount: selector(state, 'shippingAmount'),
    discount: selector(state, 'discount'),
    initialValues,
  };
};

const mapStateToDispatch = (dispatch) =>
  bindActionCreators(
    {
      fetchPurchaseOrderCategories: actions.fetchPurchaseOrderCategories,
      fetchPurchaseOrderVendors: actions.fetchPurchaseOrderVendors,
      setSubTotal: actions.setSubTotal,
      setAdditionalCost: actions.setAdditionalCost,
      fetchEmployees,
    },
    dispatch
  );

const enhance = compose(
  connect(mapStateToProps, mapStateToDispatch),
  reduxForm({ form: 'purchaseOrder' })
);

export default enhance(PurchaseOrderFormWrapper);
