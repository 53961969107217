import SelectSearch, { fuzzySearch, SelectedOptionValue } from 'react-select-search';
import { RequiredWrapper } from '../../../../../core/formValidation';
import { IntlType } from '../../../../../utils/typing';
import { Site } from '../../../../site-selection/models';
import { SearchOption } from './SiteSelector';

interface Props {
  intl: IntlType;
  addSelectedSite: (selectedSiteId: SelectedOptionValue | SelectedOptionValue[]) => void;
  searchOptions: SearchOption[];
  selectedSites: Site[];
}

const SiteSelectorInput = (props: Props): JSX.Element => {
  const { intl, addSelectedSite, searchOptions, selectedSites } = props;

  const getClassNames = (elementTag: string): string | undefined => {
    if (elementTag === 'container') {
      return 'selection-container';
    }

    if (elementTag === 'value') {
      return 'select-input-container';
    }

    if (elementTag === 'input') {
      return 'site-selector-input form-control';
    }

    if (elementTag === 'select') {
      return 'suggested-site-list-container';
    }

    if (elementTag === 'options') {
      return 'suggested-site-list';
    }

    if (elementTag === 'option') {
      return 'list-item';
    }

    return;
  };

  const emptyMessageRenderer = () => {
    return (
      <div className="list-item not-found">{intl.formatMessage({ id: 'notFound' })}</div>
    ) as unknown as string; // Hacky workaround for faulty types
  };

  return (
    <div className="site-selection-with-icon">
      <RequiredWrapper value={selectedSites}>
        <SelectSearch
          options={searchOptions}
          filterOptions={fuzzySearch}
          onChange={addSelectedSite}
          emptyMessage={emptyMessageRenderer}
          placeholder={intl.formatMessage({ id: 'selectSite' })}
          className={getClassNames as (classes: string) => string}
          search
        />
      </RequiredWrapper>

      <i className="icon icon-magnifying-glass" />
    </div>
  );
};

export default SiteSelectorInput;
