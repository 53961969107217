/* eslint-disable react/no-multi-comp */
import classNames from 'classnames';
import { ContentState, convertFromHTML, Editor, EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EditorBlockButton from './EditorBlockButton';
import EditorDropdown from './EditorDropdown';

const HEADER_TYPES = [
  { label: 'No Heading', style: 'unstyled' },
  { label: 'Heading 1', style: 'header-one' },
  { label: 'Heading 2', style: 'header-two' },
  { label: 'Heading 3', style: 'header-three' },
  { label: 'Heading 4', style: 'header-four' },
  { label: 'Heading 5', style: 'header-five' },
  { label: 'Heading 6', style: 'header-six' },
];

const BLOCK_TYPES = [
  {
    icon: 'icon icon-text',
    style: 'unordered-list-item',
    title: 'Unordered List',
  },
  { icon: 'icon icon-list', style: 'ordered-list-item', title: 'Ordered List' },
];

const INLINE_TYPES = [
  { label: 'B', style: 'BOLD', decoration: { fontWeight: 'bold' } },
  { label: 'I', style: 'ITALIC', decoration: { fontStyle: 'italic' } },
  {
    label: 'U',
    style: 'UNDERLINE',
    decoration: { textDecoration: 'underline' },
  },
];

export default class RichTextEditor extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };

    this.props.onChange(stateToHTML(this.state.editorState.getCurrentContent()));
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.onInitialValueUpdate();
    } else {
      const content = ContentState.createFromText(this.props.placeholder);
      const newState = EditorState.createWithContent(content);
      // eslint-disable-next-line
      this.setState({
        editorState: newState,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { onChange } = this.props;
    const newValue = stateToHTML(this.state.editorState.getCurrentContent());
    const inputValueChanged = prevProps.value !== newValue;

    if (inputValueChanged) {
      onChange(newValue);
    }
  }

  onInitialValueUpdate = () => {
    const blocksFromHTML = convertFromHTML(this.props.initialValue);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.setState(
      {
        editorState: EditorState.createWithContent(state),
      },
      () => {
        setTimeout(() => {
          this.focus();
        });
      }
    );
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onEditorStateChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  toggleBlockType = (blockType) => {
    this.onEditorStateChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineType = (style) => {
    this.onEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  };

  render() {
    const blockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(this.state.editorState.getSelection().getStartKey())
      .getType();

    return (
      <React.Fragment>
        <div onMouseDown={this.focus}>
          {this.editor && (
            <div className="editor-toolbar">
              {INLINE_TYPES.map((type) => (
                <button
                  key={type.label}
                  className={classNames('toolbar-button', 'toolbar-block-button')}
                  onClick={() => this.toggleInlineType(type.style)}
                  style={type.decoration}
                >
                  {type.label}
                </button>
              ))}
              {BLOCK_TYPES.map((type) => (
                <EditorBlockButton
                  key={type.icon}
                  active={type.style === blockType}
                  icon={type.icon}
                  title={type.title}
                  onToggle={this.toggleBlockType}
                  style={type.style}
                />
              ))}
              <div className="toolbar-separator" />
              <EditorDropdown
                headers={HEADER_TYPES}
                onToggle={this.toggleBlockType}
                active={blockType}
              />
            </div>
          )}
          <Editor
            editorState={this.state.editorState}
            onChange={this.onEditorStateChange}
            handleKeyCommand={this.handleKeyCommand}
            ref={(element) => {
              this.editor = element;
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
