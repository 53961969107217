// TODO: move to components folder
import classNames from 'classnames';
import { oneOfType, PropTypes } from 'prop-types';
import radium from 'radium';
import React from 'react';

const styles = {
  disabled: {
    border: '2px solid #2097f4',
    color: '#2097f4',
    borderRadius: '3px',
    background: 'none',
    padding: '1px 20px',
    fontSize: '12px',
  },
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  styleName: oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)),
  type: PropTypes.string,
  isEnabled: PropTypes.bool,
};

export const Button = ({ children, onClick, styleName, type, isEnabled, ...rest }) => {
  const btnType = type || 'button';

  const btnClasses = classNames('button', styleName);
  return (
    <button
      style={!isEnabled ? styles.disabled : null}
      className={btnClasses}
      type={btnType}
      onClick={onClick}
      disabled={!isEnabled}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = propTypes;

Button.defaultProps = {
  isEnabled: true,
};

export default radium(Button);

export const SecondaryButton = ({ children, onClick, styleName, type, isEnabled, ...rest }) => {
  const btnType = type || 'button';
  const btnClasses = classNames('btn', 'btn-success', styleName);
  return (
    <button className={btnClasses} type={btnType} onClick={onClick} disabled={!isEnabled} {...rest}>
      {children}
    </button>
  );
};

SecondaryButton.propTypes = propTypes;

SecondaryButton.defaultProps = {
  isEnabled: true,
};

const iconButtonStyles = {
  color: '#2097f4',
  background: 'none',
  border: '0px',
  padding: '1px 10px',
  fontSize: '18px',
};

export const IconButton = ({ children, onClick, styleName, type, isEnabled, ...rest }) => {
  const btnType = type || 'button';
  const btnClasses = classNames('btn', 'btn-link', 'btn-icon', styleName);
  return (
    <button
      style={iconButtonStyles}
      className={btnClasses}
      type={btnType}
      onClick={onClick}
      disabled={!isEnabled}
      {...rest}
    >
      {children}
    </button>
  );
};

IconButton.propTypes = propTypes;

IconButton.defaultProps = {
  isEnabled: true,
};
