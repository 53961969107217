import PropTypes from 'prop-types';
import React from 'react';

const EllipsisLoader = ({ text }) => <div className="el-loader">{text}</div>;

EllipsisLoader.propTypes = {
  text: PropTypes.string,
};

export default EllipsisLoader;
