import { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import { userHasSettingsEnabledRedir } from '../core/auth';
import Settings from '../views/settings/index';

const SettingsRoute = (): ReactElement<any> => {
  return (
    <Switch>
      <Route exact path="/:site/settings">
        <Settings />
      </Route>
    </Switch>
  );
};

export default userHasSettingsEnabledRedir(SettingsRoute);
