import { put, takeEvery } from 'redux-saga/effects';
import { LOGOUT_REQUEST, UNAUTHORIZED } from '../core/constants';

function* logoutUser() {
  yield put({ type: LOGOUT_REQUEST });
}

// eslint-disable-next-line
export function* requestErrorSaga() {
  yield takeEvery(UNAUTHORIZED, logoutUser);
}
