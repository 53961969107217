import * as _ from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { selectProducts } from '../../products/reducer';
import AddWashCountsForm from '../components/AddWashCountsForm';
import { selectWashCounts } from '../reducer';

export class AddWashCountsDialog extends Component {
  static defaultProps = {
    products: [],
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      })
    ),
    washCounts: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.string.isRequired,
      })
    ),
    initialValues: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    // eslint-disable-next-line
    newProducts: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line
    newProductFields: PropTypes.arrayOf(PropTypes.object),
    isNew: PropTypes.bool,
  };

  onReady = () => {
    this.props.triggerSubmit('washCounts');
  };

  onSubmit = (values) => {
    const existingProducts = _.omit(values, ['newProducts', 'timestamp']);
    const washCounts = _.values(existingProducts).filter((washCount) => washCount.count);

    const newProducts = values.newProducts || [];
    const timestamp = moment(values.timestamp).utc().toISOString();
    const data = { timestamp, washCounts, newProducts };
    this.props.onSubmit(data);
  };

  configureProductLabels() {
    const { products } = this.props;
    return products.reduce((all, prod) => {
      const { name, id } = prod;
      return { ...all, [`product_${id}`]: { productId: id, name } };
    }, {});
  }

  render() {
    // TODO maybe use hoc which handles isOpen, closeDialog?
    const { closeDialog, isOpen, isNew, products, washCounts, errors } = this.props;
    const productLabels = this.configureProductLabels();
    const initialFormValues = this.props.initialValues || {
      ...productLabels,
      timestamp: moment(),
    };
    // const submitDisabled = this.productErrorCheck();
    const submitDisabled = checkErrors(errors);

    return (
      <ModalDialog
        title="End of Day Wash Counts"
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <AddWashCountsForm
          onSubmit={this.onSubmit}
          products={products}
          washCounts={washCounts}
          initialValues={initialFormValues}
          isNew={isNew}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('washCounts');
const selector = formValueSelector('washCounts');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  const newProductFields = selector(state, 'newProducts');
  return {
    products: selectProducts(state),
    washCounts: selectWashCounts(state),
    errors,
    newProductFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWashCountsDialog);
