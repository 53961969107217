import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, submit } from 'redux-form';
// import deepEqual from 'deep-equal';
import { hideDialog, showDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkDisabled } from '../../../core/utils';
import { fetchEmployees } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import OpenWorkLogForm from '../components/OpenWorkLogForm';

class OpenWorkLogDialog extends Component {
  static defaultProps = {
    employees: [],
  };

  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.object),
    openWorkLogs: PropTypes.arrayOf(PropTypes.object),
    employeeId: PropTypes.number,
    addWorkLog: PropTypes.func.isRequired,
    saveWorkLog: PropTypes.func.isRequired,
    fetchOpenWorkLogs: PropTypes.func.isRequired,
    fetchEmployees: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    openLog: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  componentDidMount() {
    this.props.fetchOpenWorkLogs();
    this.props.fetchEmployees();
  }

  onReady = () => {
    this.props.triggerSubmit('openWorkLogForm');
  };

  onSubmit = (values) => {
    const { employeeId } = values;
    const newWorkLog = { employeeId, start: moment() };
    this.props.addWorkLog(newWorkLog);
  };

  render() {
    // TODO maybe use hoc which handles isOpen, closeDialog?
    const { closeDialog, isOpen, employees, employeeId, openWorkLogs, openLog } = this.props;

    const disabledCases = [openLog, !employeeId];

    return (
      <ModalDialog
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={checkDisabled(disabledCases)}
      >
        <OpenWorkLogForm
          onSubmit={this.onSubmit}
          employees={employees}
          openWorkLogs={openWorkLogs}
          openLog={openLog}
          showDialog={this.props.showDialog}
          saveWorkLog={this.props.saveWorkLog}
        />
      </ModalDialog>
    );
  }
}

const selector = formValueSelector('openWorkLogForm');

const mapStateToProps = (state, ownProps) => {
  const employeeId = selector(state, 'employeeId');
  const openLog = ownProps.openWorkLogs.filter((log) => log.employeeId === employeeId)[0] || null;
  return {
    employees: selectEmployees(state),
    employeeId,
    openLog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
      showDialog,
      fetchEmployees,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenWorkLogDialog);
