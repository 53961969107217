import { debounce, isEqual, pick } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import { getApiStatus } from '../../../core/utils';
import { selectModal } from '../../../store/modal';
import { fetchProducts } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { selectSiteExtId } from '../../settings/reducer';
import * as actions from '../actions';
import {
  selectAdjustmentNotes,
  selectAdjustments,
  selectAdjustmentsPaginationInfo,
  selectCombinedCounts,
  selectFromDate,
  selectUntilDate,
} from '../reducer';
import { POSCountAdjustmentsPropTypes } from '../types';

const styles = {
  mainContainer: {
    padding: '40px 20px',
    height: '100%',
    borderRadius: '3px',
    backgroundColor: '#fff',
  },
  tableHeader: {
    fontSize: '18px',
  },
  tableContainer: {
    marginBottom: '20px',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    border: '2px solid #2097f4',
    color: '#2097f4',
    borderRadius: '3px',
    background: 'none',
    padding: '1px 20px',
    fontSize: '12px',
    ':hover': {
      filter: 'invert(100%)',
    },
  },
};

export class POSCountAdjustments extends Component {
  static propTypes = POSCountAdjustmentsPropTypes;

  static defaultProps = { adjustments: [] };

  state = {
    searchResults: [],
  };

  componentDidMount() {
    this.fetchAsOfDate();
    this.props.fetchProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.dialogPropsChanged(prevProps) && this.props.dialogOpen) {
      this.showAdjustmentDialog();
    }
    const currentDates = this.getFormattedDates();
    const prevDates = [
      prevProps.fromDate.format('YYYY-MM-DD'),
      prevProps.untilDate.format('YYYY-MM-DD'),
    ];
    if (!isEqual(prevDates, currentDates)) {
      this.fetchAsOfDate();
    }
  }

  getFormattedDates = () => [
    this.props.fromDate.format('YYYY-MM-DD'),
    this.props.untilDate.format('YYYY-MM-DD'),
  ];

  getPaginationInfo = () => ({
    ...this.props.paginationInfo,
    goToPage: this.fetchAdjustmentsPage,
  });

  getRowClickInfo = () => ({
    callback: (data) => {
      this.rowClickFunction(data);
    },
  });

  getSearchInfo = () => [
    {
      index: 0,
      colSpan: 5,
      element: (
        <input
          placeholder={this.props.intl.formatMessage({
            id: 'searchForOperation',
          })}
          className="form-control data-table-search"
          onChange={(e) => this.getFuzzyOperationMatches(e.target.value)}
        />
      ),
    },
  ];

  getFuzzyOperationMatches = debounce((searchText) => {
    const results = [];
    const lowerCasedSearchText = searchText.toLowerCase();

    this.props.adjustments.forEach((adjustment) => {
      const tokens = adjustment.operationName.split('');
      let searchPosition = 0;

      for (const token of tokens) {
        if (token.toLowerCase() === lowerCasedSearchText[searchPosition]) {
          searchPosition += 1;
          if (searchPosition >= lowerCasedSearchText.length) {
            break;
          }
        }
      }

      if (searchPosition === lowerCasedSearchText.length) {
        results.push(adjustment);
      }
    });

    this.setState({
      searchResults: results.length > 0 ? results : null,
    });
  }, 500);

  dialogPropsChanged = (prevProps) => {
    const properties = [
      'fromDate',
      'untilDate',
      'products',
      'notes',
      'adjustments',
      'combinedCount',
    ];
    const previousDialogProps = pick(prevProps, properties);
    const currentDialogProps = pick(this.props, properties);

    return !isEqual(previousDialogProps, currentDialogProps);
  };

  rowClickFunction = (data) => {
    this.props.showDialog('SHOW_ADJUSTMENT_NOTES', data);
  };

  fetchAdjustmentsPage = (page = 0) => {
    const [from, until] = this.getFormattedDates();
    this.props.fetchAdjustments(from, until, page);
  };

  fetchAsOfDate = (signal) => {
    const [from, until] = this.getFormattedDates();
    if (this.props.extId) {
      this.props.fetchCoreCounts(from, until, signal, this.props.extId);
    }

    this.props.fetchAdjustments(from, until, 0, signal);

    if (from === until) {
      this.props.fetchNotes(from, until, signal);
    }
  };

  toHeaders = (id) => ({ name: this.props.intl.formatMessage({ id }) });

  toData = (operation) => ({
    _id: operation.id,
    operationName: operation.operationName,
    numAdjustments: operation.numAdjustments,
    creator: operation.creator,
    date: moment(operation.date).format('MM/DD/YYYY'),
    notes: operation.notes,
  });

  initializeDataTable = () => ({
    data: (this.state.searchResults || this.props.adjustments).map(this.toData),
    headers: ['operationName', 'numAdjustments', 'creator', 'date', 'notes'].map(this.toHeaders),
    options: { styleHeader: { backgroundColor: '#e6e6ea' } },
    addedClass: 'wash-count-table adjustment-table shrinked-table',
    rowClickInfo: this.getRowClickInfo(),
    paginationInfo: this.getPaginationInfo(),
    searchInfo: this.getSearchInfo(),
    showLoader: this.props.loaders.areAdjustmentsLoading,
  });

  goToWashCountsPage = () => {
    const id = this.props.match.params.site;
    this.props.history.push(`/${id}/washcounts/`);
  };

  showAdjustmentDialog = () => {
    const {
      adjustWashCount,
      fromDate,
      untilDate,
      products,
      notes,
      adjustments,
      combinedCounts,
      extId,
    } = this.props;

    this.props.showDialog('ADJUST_POS_COUNT', {
      date: fromDate,
      formData: { notes, adjustments, products, combinedCounts },
      onSubmit: (data) => {
        adjustWashCount(data, fromDate.format('YYYY-MM-DD'), untilDate.format('YYYY-MM-DD'), extId);
      },
    });
  };

  showingSingleDay = () => this.props.fromDate.isSame(this.props.untilDate, 'day');

  render = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-top-gutter">
          <DateRangeSelector
            dates={[this.props.fromDate, this.props.untilDate]}
            onSubmit={(dates) => {
              this.props.setFromDate(dates.from);
              this.props.setUntilDate(dates.until);
            }}
            isLoading={this.props.loaders.areAdjustmentsLoading}
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-12 col-lg-12 col-top-gutter wash-tables"
          style={styles.mainContainer}
        >
          <section className="adjustments">
            <div className="page-action-buttons" style={styles.actions}>
              {this.showingSingleDay() && (
                <button className="button" onClick={this.showAdjustmentDialog}>
                  {this.props.intl.formatMessage({ id: 'addAdjustment' })}
                </button>
              )}
              <button className="ml-1 button" onClick={this.goToWashCountsPage}>
                {this.props.intl.formatMessage({ id: 'washCounts' })}
              </button>
            </div>
            <h2 style={styles.tableHeader}>
              {this.props.intl.formatMessage({ id: 'adjustments' })}
            </h2>
            <DataTable {...this.initializeDataTable()} />
          </section>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const loaders = {
    areAdjustmentsLoading: getApiStatus(state, actions.FETCH_ADJUSTMENTS),
  };

  return {
    fromDate: selectFromDate(state),
    untilDate: selectUntilDate(state),
    adjustments: selectAdjustments(state),
    paginationInfo: selectAdjustmentsPaginationInfo(state),
    products: selectProducts(state),
    combinedCounts: selectCombinedCounts(state),
    notes: selectAdjustmentNotes(state),
    dialogOpen: selectModal(state).modalType,
    extId: selectSiteExtId(state),
    loaders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showDialog,
      fetchProducts,
      ...actions,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter);

export default enhance(POSCountAdjustments);
