import { PropTypes } from 'prop-types';
import React from 'react';

const DataTableHeaderSelect = ({ selectedRows, multiSelectEnabled, updateSelectedRows, data }) => {
  const checkboxIdentity = `header-check-${Math.random()}`;
  const numberOfSelectedRows = Object.keys(selectedRows || {}).length;

  const getCheckboxRef = (element) => {
    const checkbox = element;

    if (checkbox) {
      checkbox.indeterminate = numberOfSelectedRows > 0 && numberOfSelectedRows < data.length;
    }
  };

  return multiSelectEnabled ? (
    <th scope="col" className="text-center dr-multi-select-header">
      <input
        id={checkboxIdentity}
        type="checkbox"
        data-testid="datatable-header-checkbox"
        className="dr-styled-checkbox"
        onChange={() => updateSelectedRows(data)}
        checked={numberOfSelectedRows === data.length}
        ref={getCheckboxRef}
      />
      <label data-testid="datatable-header-checkbox-label" htmlFor={checkboxIdentity} />
    </th>
  ) : (
    <React.Fragment />
  );
};

DataTableHeaderSelect.propTypes = {
  updateSelectedRows: PropTypes.func,
  selectedRows: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  multiSelectEnabled: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DataTableHeaderSelect;
