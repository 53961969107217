import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSites } from '../../core/api/sites';
import apiRequest from '../../core/api/utils';
import * as auth from '../../core/auth';
import * as storage from '../../core/storage';
import * as actions from './actions';

export function* logout() {
  yield call(storage.removeJwtToken);
  yield call(storage.removeRefreshToken);
  yield call(storage.removeMultiSites);

  yield put(actions.logoutSuccess());
}

function* getSelectedSite() {
  const { results: sites } = yield call(apiRequest, fetchSites);
  let selectedSite = yield call(storage.getSelectedSite);

  if (!selectedSite) {
    selectedSite = sites[0].id;

    if (selectedSite) {
      yield call(storage.setSelectedSite, selectedSite);
    }
  }

  return { selectedSite };
}

function* authenticate({ user }) {
  try {
    const { username, password } = user;

    const response = yield call(auth.login, { name: username, password, appKey: 'manager' });

    const { jwtToken, refreshToken } = response;

    if (!jwtToken) {
      return null;
    }

    yield call(storage.setJwtToken, jwtToken);
    yield call(storage.setRefreshToken, refreshToken);

    const { selectedSite } = yield call(getSelectedSite);

    yield put(actions.loginSuccess(jwtToken, refreshToken, selectedSite));
    yield put(actions.refreshSuccess(jwtToken, refreshToken));

    return response;
  } catch (error) {
    yield put(actions.loginFailure(error));

    return null;
  }
}

export function* refresh(tokens) {
  try {
    const response = yield call(auth.refresh, tokens);

    const { jwtToken, refreshToken } = response;

    if (!jwtToken) {
      return null;
    }

    yield call(storage.setJwtToken, jwtToken);
    yield call(storage.setRefreshToken, refreshToken);
    yield put(actions.refreshSuccess(jwtToken, refreshToken));

    return response;
  } catch (error) {
    yield put(actions.refreshFailure());

    return null;
  }
}

export function* loginFlowSaga() {
  yield takeEvery(actions.LOGIN_REQUEST, authenticate);
}

export function* logoutFlowSaga() {
  yield takeEvery(actions.LOGOUT_REQUEST, logout);
}
