import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { deleteReclamationInEdit, getReclamation } from '../actions';
import { selectReclamationInEdit } from '../reducer';
import ReclamationFormWrapper from './ReclamationFormWrapper';

const ReclamationEdit = (props) => {
  const params = useParams();
  const location = useLocation();
  const reclamationInEdit = useSelector(selectReclamationInEdit);

  useEffect(() => {
    const reclamationdId = params.reclamation;
    props.getReclamation(reclamationdId);
    return () => {
      props.deleteReclamationInEdit();
    };
  }, []);

  return (
    reclamationInEdit && (
      <div className="container-fluid" data-testid="reclamationEdit">
        <ReclamationFormWrapper
          initialReclamation={reclamationInEdit}
          isNew={false}
          location={location}
        />
      </div>
    )
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReclamation, deleteReclamationInEdit }, dispatch);
};

export default connect(null, mapDispatchToProps)(ReclamationEdit);
