import * as api from '../api';

// eslint-disable-next-line
export const fetchEmployees = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/employees`, args);
};

export const postEmployee = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/employees`, args);
};

export const getEmployee = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/employees/${id}`, args);
};

export const patchEmployee = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/employees/${id}`, args);
};

export const archiveEmployee = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/employees/${id}/archive`, args);
};

export const unarchiveEmployee = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/employees/${id}/unarchive`, args);
};
