import { PropTypes } from 'prop-types';
import React from 'react';
import Button from './Button';
import UserGroupPermissions from './UserGroupPermissions';

const styles = {
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const PageActions = ({ actionFunction, actions }) => {
  const buttons = actions.map((x, index) => (
    <UserGroupPermissions key={index} itemRef={x.permissionKey}>
      <Button
        key={index}
        styleName={x.classes}
        onClick={() => actionFunction(x.dialogType, x.dialogProps)}
      >
        {x.text}
      </Button>
    </UserGroupPermissions>
  ));
  return (
    <div data-testid="page-actions" style={styles.actions} className="page-action-buttons">
      {buttons}
    </div>
  );
};

PageActions.propTypes = {
  actionFunction: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      dialogType: PropTypes.string,
      dialogProps: PropTypes.object,
      text: PropTypes.string.isRequired,
      permissionKey: PropTypes.string,
    })
  ).isRequired,
};

export default PageActions;
