import PropTypes from 'prop-types';
import React from 'react';

const TinyTable = (props) => {
  let options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
  return (
    <div className="table-container">
      <table className="minWidth140">
        <caption className="table-caption">{props.caption}</caption>
        <tbody>
          {props.data.map((row, index) => (
            <tr className="standard-row" key={index}>
              <td
                className={row.className && row.className[0]}
                style={{
                  paddingLeft: '16px',
                  paddingTop: index === props.data.length - 1 ? '14px' : '',
                }}
              >
                {row.name}
              </td>
              <td
                className={row.className && row.className[1]}
                style={{
                  fontWeight: 'bold',
                  paddingTop: index === props.data.length - 1 ? '14px' : '',
                }}
              >
                ${Number(row.value).toLocaleString(undefined, options)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TinyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  caption: PropTypes.string,
};

export default TinyTable;
