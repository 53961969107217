import Feedback from './models';

const initialState = {
  feedback: [],
  feedbackInEdit: {},
};

const fetchFeedbackSuccess = (state, response) => {
  const feedback = response.results.map((f) => new Feedback(f));
  return {
    ...state,
    feedback,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const getFeedbackItemSuccess = (state, feedback) => {
  return {
    ...state,
    feedbackInEdit: new Feedback(feedback),
  };
};

export const feedback = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_FEEDBACK_SUCCESS':
      return fetchFeedbackSuccess(state, action.response);
    case 'GET_FEEDBACK_ITEM_SUCCESS':
      return getFeedbackItemSuccess(state, action.response);
    default:
      return state;
  }
};

export const selectFeedback = (state) => {
  return state.feedback.feedback;
};

export const selectFeedbackInEdit = (state) => {
  return state.feedback.feedbackInEdit;
};

export const selectTotal = (state) => {
  return state.feedback.total;
};

export const selectPage = (state) => {
  return state.feedback.page;
};

export const selectPageSize = (state) => {
  return state.feedback.pageSize;
};
