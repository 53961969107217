import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DefaultModalComponent = ({
  intl,
  children,
  title,
  isOpen,
  close,
  className,
  showFooter,
  onSubmit,
  defaultModal,
}) => {
  const Component = defaultModal || children;
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody data-testid="modal">
        <Component intl={intl} onSubmit={onSubmit}>
          {children}
        </Component>
      </ModalBody>
      {showFooter && (
        <ModalFooter className="full-width">
          <Button className="submit-button delete" color="secondary" onClick={close}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
          <Button className="submit-button confirm" color="primary" onClick={onSubmit}>
            {title}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DefaultModalComponent;
