import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface LinkingModalProps {
  intl: any;
  children: any;
  isOpen: boolean;
  close: any;
  linkTasks: any;
  className: string;
  disabled: boolean;
  showFooter: boolean;
}

const TaskLinkingModal = ({
  intl,
  children,
  isOpen,
  close,
  linkTasks,
  className,
  disabled,
  showFooter,
}: LinkingModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}></ModalHeader>
      <ModalBody data-testid="modal" className="linked-tasks">
        {children}
      </ModalBody>
      {showFooter && (
        <ModalFooter className="full-width">
          <Button
            className="submit-button confirm"
            color="secondary"
            onClick={linkTasks}
            disabled={disabled}
          >
            {intl.formatMessage({ id: 'linkTasks' })}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default TaskLinkingModal;
