import React from 'react';
import { Field, reduxForm } from 'redux-form';

export const NoteTextArea = (field) => {
  return <textarea {...field.input} className="form-control" rows="10" />;
};

const NoteForm = () => {
  return (
    <div>
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <Field name="text" id="text" className="form-control" component={NoteTextArea} />
      </div>
    </div>
  );
};

export default reduxForm({ form: 'addNote' })(NoteForm);
