import * as api from '../api';

export const fetchPurchaseOrders = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/purchase-orders`, args);
};

export const fetchPurchaseOrder = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/purchase-orders/${id}`, args);
};

export const receivePurchaseOrders = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-orders/${id}/receive`, args);
};

export const unreceivePurchaseOrders = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-orders/${id}/unreceive`, args);
};

export const postPurchaseOrder = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-orders`, args);
};

export const archivePurchaseOrder = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-orders/${id}/archive`, args);
};

export const putPurchaseOrder = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPut(`/manager/sites/${siteId}/purchase-orders/${id}`, args);
};
