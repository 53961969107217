import { takeEvery, call } from 'redux-saga/effects';
import * as toastMsg from '../../core/toastMessages';

function* vendingSalesSaga() {
  yield call(toastMsg.vendingSalesUpdated);
}

export function* watchExpensesSagas() {
  yield takeEvery('UPDATE_VENDING_SALES_SUCCESS', vendingSalesSaga);
}
