import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import { fetchDetergentData, upsertDetergentData } from '../actions';
import { selectInventoryData } from '../reducer';

const Big = require('big.js');

export const DetergentInventoryData = ({
  intl,
  getInventoryData,
  inventory,
  inventoryData,
  upsertData,
  dialog,
}) => {
  useEffect(() => {
    getInventoryData({ id: inventory.id, page: 0, direction: 'desc' });
  }, []);

  const initializeDataTable = () => {
    const toHeaders = (id) => ({ name: intl.formatMessage({ id }) });

    /* eslint-disable */
    const toData = (data) => ({
      _id: data.id,
      timestamp: moment(data.timestamp).format('MM/DD/YYYY'),
      quantityOfContainers: data.quantityOfContainers,
      container: Big(data.container).toFixed(2),
      price: `$ ${Big(data.totalPrice).toFixed(2)}`,
      gallonsLeft: `${Big(data.gallonsLeft).toFixed(2)} gallons`,
    });

    return {
      headers: ['timestamp', 'quantityOfContainers', 'container', 'price', 'gallonsLeft'].map(
        toHeaders
      ),
      data: inventoryData.map(toData),
    };
  };

  const actions = [
    {
      dialogType: 'UPSERT_DETERGENT_DATA',
      text: intl.formatMessage({ id: 'upsertDetergentData' }),
      dialogProps: {
        title: intl.formatMessage({ id: 'upsertDetergentData' }),
        onSubmit: upsertData,
        inventoryInEdit: inventory,
        isNew: false,
      },
    },
  ];

  return (
    <React.Fragment>
      <PageActions actionFunction={dialog} actions={actions} />
      <DataTable {...initializeDataTable()} />
    </React.Fragment>
  );
};

DetergentInventoryData.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  getInventoryData: PropTypes.func.isRequired,
  dialog: PropTypes.func.isRequired,
  upsertData: PropTypes.func.isRequired,
  inventory: PropTypes.shape({ id: PropTypes.number }).isRequired,
  inventoryData: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  inventoryData: selectInventoryData(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInventoryData: fetchDetergentData,
      dialog: showDialog,
      upsertData: upsertDetergentData,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(DetergentInventoryData);
