import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchSiteOperations, showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { addProduct, archiveProduct, fetchProducts, updateProduct } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { selectSiteOperations } from '../reducer';

export const Products = ({
  intl,
  getProducts,
  postProduct,
  patchProduct,
  removeProduct,
  products,
  getSiteOperations,
  operations,
  dialog,
}) => {
  useEffect(() => {
    getProducts();
    getSiteOperations();
  }, []);

  const editProduct = (event, dataRow) => {
    const operation = operations.find((op) => op.id === dataRow._coreOperationId);
    const product = products.find((p) => p.id === dataRow._id);

    const dialogProps = {
      title: intl.formatMessage({ id: 'editProduct' }),
      onSubmit: patchProduct,
      isNew: false,
      operations,
      operation,
      product,
    };

    dialog('UPSERT_PRODUCT', dialogProps);
  };

  const deleteProduct = (event, { _id, name }) => {
    const query = { id: _id };
    const confirmProps = {
      onReady: removeProduct.bind(null, query),
      text: intl.formatMessage({ id: 'deleteProductConfirmation' }, { name }),
      title: intl.formatMessage({ id: 'deleteProduct' }),
    };
    dialog('CONFIRM_DIALOG', confirmProps);
  };

  const initializeDataTable = () => {
    const toHeaders = (id) => ({ name: intl.formatMessage({ id }) });
    const headers = ['name', 'creator'].map(toHeaders);
    headers.push({
      name: intl.formatMessage({ id: 'action' }),
      cell: {
        component: TableActionButtonsCell,
        mixIns: {
          buttons: [
            { icon: 'icon-trash', buttonAction: deleteProduct },
            { icon: 'icon-edit', buttonAction: editProduct },
          ],
        },
      },
    });
    const toData = (product) => ({
      _id: product.id,
      _coreOperationId: product.coreOperationId,
      name: product.name,
      creator: product.creator,
    });
    return {
      headers,
      data: products.map(toData),
    };
  };

  const addDialogProps = {
    title: 'Add Product',
    onSubmit: postProduct,
    isNew: true,
    operations,
  };

  return (
    <React.Fragment>
      <PageActions
        actionFunction={dialog}
        modalProps={addDialogProps}
        actions={[
          {
            dialogType: 'UPSERT_PRODUCT',
            dialogProps: addDialogProps,
            text: 'Add Product',
            permissionKey: addPermissionRefs.products,
          },
        ]}
      />
      <DataTable {...initializeDataTable()} />
    </React.Fragment>
  );
};

Products.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  getProducts: PropTypes.func.isRequired,
  postProduct: PropTypes.func.isRequired,
  patchProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  getSiteOperations: PropTypes.func.isRequired,
  dialog: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  operations: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  products: selectProducts(state),
  operations: selectSiteOperations(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProducts: fetchProducts,
      postProduct: addProduct,
      patchProduct: updateProduct,
      removeProduct: archiveProduct,
      getSiteOperations: fetchSiteOperations,
      dialog: showDialog,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(Products);
