import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';

const EditorDropdown = (props) => {
  const toggle = (e) => {
    const value = e.target.value;
    props.onToggle(value);
  };

  return (
    <select
      className={classNames('toolbar-button', 'toolbar-header-button')}
      value={props.active}
      onChange={toggle}
    >
      {props.headers.map((header, id) => (
        <option key={id} value={header.style}>
          {header.label}
        </option>
      ))}
    </select>
  );
};

EditorDropdown.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      style: PropTypes.string.isRequired,
    })
  ),
  active: PropTypes.string.isRequired,
};

export default EditorDropdown;
