import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';

class AdjustmentDetailDialog extends Component {
  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    intl: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    operationName: PropTypes.string,
    numAdjustments: PropTypes.number,
    creator: PropTypes.string,
    date: PropTypes.string,
    notes: PropTypes.string,
  };

  render() {
    const { closeDialog, isOpen, intl, operationName, numAdjustments, creator, date, notes } =
      this.props;

    return (
      <ModalDialog
        title={intl.formatMessage({ id: 'adjustment' })}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
      >
        <form className="form-group">
          <label htmlFor="notes">{intl.formatMessage({ id: 'operationName' })}</label>
          <div className="form-group">{operationName}</div>
          <label htmlFor="notes">{intl.formatMessage({ id: 'numAdjustments' })}</label>
          <div className="form-group">{numAdjustments}</div>
          <label htmlFor="notes">{intl.formatMessage({ id: 'creator' })}</label>
          <div className="form-group">{creator}</div>
          <label htmlFor="notes">{intl.formatMessage({ id: 'date' })}</label>
          <div className="form-group">{date}</div>
          <label htmlFor="notes">{intl.formatMessage({ id: 'notes' })}</label>
          <div className="form-group">{notes}</div>
        </form>
      </ModalDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
    },
    dispatch
  );
};

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(AdjustmentDetailDialog);
