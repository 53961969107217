import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

const SystemStatus = () => {
  const intl = useIntl();
  const [tommyManagerStatus, setTommyManagerStatus] = useState(
    intl.formatMessage({ id: 'loading' })
  );

  const fetchTommyManagerStatus = async () => {
    try {
      const response = await fetch('https://status.tommys-express.com/api/v2/components.json');
      const data = await response.json();

      const tommyManagerComponent = data.components.find(
        (component: { id: string }) => component.id === 'd6qy11c51rql'
      );
      if (tommyManagerComponent) {
        setTommyManagerStatus(tommyManagerComponent.status);
      } else {
        intl.formatMessage({ id: 'componentNotFound' });
      }
    } catch (error) {
      console.error('Error fetching Tommy Manager status:', error);
      setTommyManagerStatus(intl.formatMessage({ id: 'unknown' }));
    }
  };

  useEffect(() => {
    fetchTommyManagerStatus();
    const intervalDuration = moment.duration(5, 'minutes').asMilliseconds();
    const interval = setInterval(fetchTommyManagerStatus, intervalDuration);
    return () => clearInterval(interval);
  }, []);
  const statusColor =
    tommyManagerStatus === 'operational' ||
    tommyManagerStatus === 'degraded_performance' ||
    tommyManagerStatus === intl.formatMessage({ id: 'loading' })
      ? 'green'
      : 'red';

  return (
    <div className="status-container">
      <div className="mobile-status-container">
        {tommyManagerStatus && <span className={`status-mobile ${statusColor}`}></span>}
        <a href="https://status.tommys-express.com/" className={`btn btn-secondary status-button`}>
          <i className="uil uil-heart-rate"></i>
        </a>
      </div>
      <div className="desktop-status-container">
        <span className={`status-circle ${statusColor}`} />
        <a href="https://status.tommys-express.com/" className="status-link">
          {intl.formatMessage({ id: 'systemStatus' })}
        </a>
      </div>
    </div>
  );
};

export default SystemStatus;
