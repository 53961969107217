import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import Fade from '../Fade';
import ChangeLogMenu from './ChangeLogMenu';

const ChangeLog = (props) => {
  const [hide, setHide] = useState(true);
  const { onUndo } = props;

  const rows = props.changes.map((change, i) => (
    <tr key={i} className="fm-changeLogRow">
      <td>
        <p className="fm-changeText-wrap">{change.text}</p>
      </td>
      <td>
        <button
          className="btn btn-link fm-float-right"
          onClick={() => onUndo(change.id, change.type)}
        >
          {props.intl.formatMessage({ id: 'undo' })}
        </button>
      </td>
    </tr>
  ));

  return (
    <div>
      <button className="button" onClick={() => setHide((isHidden) => !isHidden)}>
        {props.intl.formatMessage({ id: 'changes' })}
      </button>
      <Fade hide={hide}>
        <ChangeLogMenu>{rows}</ChangeLogMenu>
      </Fade>
    </div>
  );
};

ChangeLog.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onUndo: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

const enhance = compose(injectIntl);

export default enhance(ChangeLog);
