import { chain, groupBy, map, sumBy } from 'lodash';
import { spacesToCamelCaseString } from '../../core/utils';
import { formatAmount } from '../../utils/money-util';
import { ReclamationClaimType } from '../reclamations/models';
import { ReportType } from './models';

const toFormattedHeaders = (formatMessage) => (id) => ({
  name: formatMessage({ id }),
});

const toTotalsByCurrency = (costs) => (currency) => {
  return formatAmount(
    sumBy(costs[currency], (cost) => Number(cost.total)),
    currency
  );
};

const getTotalCost = (costs) => {
  const costsGroupedByCurrency = groupBy(costs, 'currency');

  return (
    Object.keys(costsGroupedByCurrency)
      .map(toTotalsByCurrency(costsGroupedByCurrency))
      .join(', ') || 0
  );
};

const countGetRepaired = (counts) => counts.Repaired + counts.UnderRepair + counts.Resolved;

/* eslint-disable */
export const formatReportTableData = (reportType, formatMessage, data) => {
  switch (reportType) {
    case ReportType.ClaimsByDamageArea:
      const costsGroupedByCurrency = chain(data)
        .map('repairCosts')
        .flatten()
        .groupBy('currency')
        .value();

      const summaryTotalString =
        Object.keys(costsGroupedByCurrency)
          .map(toTotalsByCurrency(costsGroupedByCurrency))
          .join(', ') || 0;

      const countsByStatus = map(data, 'countsByStatus');
      const notCausedTotal = sumBy(countsByStatus, 'NotCaused');
      const getRepairedTotal =
        sumBy(countsByStatus, 'Repaired') +
        sumBy(countsByStatus, 'UnderRepair') +
        sumBy(countsByStatus, 'Resolved');

      return {
        headers: ['damagedArea', 'getRepaired', 'notCaused', 'totalCost', 'total'].map(
          toFormattedHeaders(formatMessage)
        ),
        summary: [
          { index: 0, value: formatMessage({ id: 'grandTotal' }) },
          { index: 1, value: getRepairedTotal },
          { index: 2, value: notCausedTotal },
          { index: 3, value: summaryTotalString },
          { index: 4, value: sumBy(data, 'count') },
        ],
        data: data.map((report, i) => ({
          _id: i + 1,
          damagedArea: spacesToCamelCaseString(report.carPart),
          getRepaired: countGetRepaired(report.countsByStatus),
          notCaused: report.countsByStatus.NotCaused,
          cost: getTotalCost(report.repairCosts),
          grandTotal: report.count,
        })),
      };
    case ReportType.ClaimsByVehicleModel:
      const carParts = Object.keys(ReclamationClaimType).filter((key) => key !== 'All');

      return {
        headers: ['vehicle', ...carParts, 'total'].map(toFormattedHeaders(formatMessage)),
        summary: [
          { index: 0, value: formatMessage({ id: 'grandTotal' }) },
          ...carParts.map((prop, i) => ({
            index: i + 1,
            value: sumBy(data, ({ countsByCarPart }) => countsByCarPart[prop]),
          })),
          { index: 12, value: sumBy(data, 'count') },
        ],
        data: data.map((carMake, i) => {
          const carPartProperties = {};

          carParts.forEach((carPart) => {
            carPartProperties[carPart] = carMake.countsByCarPart[carPart];
          });
          if (carMake.count > 0) {
            return {
              _id: i + 1,
              _makeId: carMake.makeId,
              vehicleMake: carMake.make,
              ...carPartProperties,
              grandTotal: carMake.count,
            };
          } else {
            return [];
          }
        }),
      };
    default:
      return [];
  }
};
