import { PropTypes } from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

const TaskCompleteForm = ({ employees }) => {
  const renderEmployees = ({ input }) => {
    return (
      <select {...input} className="form-control">
        {employees.map((e) => {
          const fullName = `${e.lastName}, ${e.firstName}`;
          return (
            <option key={e.id} value={e.id}>
              {fullName}
            </option>
          );
        })}
      </select>
    );
  };

  renderEmployees.propTypes = {
    input: PropTypes.shape({}),
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="employeeId">Team Member</label>
        <Field
          name="employeeId"
          id="employeeId"
          component={renderEmployees}
          parse={(value) => Number(value)}
        />
      </div>
    </div>
  );
};

TaskCompleteForm.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default reduxForm({ form: 'taskComplete' })(TaskCompleteForm);
