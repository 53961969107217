export const UserReportViewType = {
  Single: 1,
  Multi: 2,
};

export const PowerBiView = {
  Single: 'Single',
  Multi: 'Multi',
};

export const PowerBiPageType = {
  MultiMembership: 'ReportSection9bb741c58368502f6f9c',
  MultiOperations: 'ReportSection5e88adfa6452b7cd3117',
  MultiMarketing: 'ReportSection303fc4f693ce71284c48',
  MultiWashCount: 'ReportSection79dddbf981d615f94140',
  MultiCustomerBehavior: 'ReportSection993900c0aa99d0d10c11',
  SingleMembership: 'ReportSectionf43802a9b5d945adb411',
  SingleOperations: 'ReportSection2e9ef76b72c3091d44bb',
  SingleMarketing: 'ReportSectionbc2697336397423a4962',
  SingleWashCount: 'ReportSection86cbfe434a57c471d901',
  SingleCustomerBehavior: 'ReportSectionaf83289c3995aeb08606',
};

export const PowerBiPages = {
  MultiMembership: 0,
  MultiOperations: 1,
  MultiMarketing: 2,
  MultiWashCount: 3,
  MultiCustomerBehavior: 4,
  SingleMembership: 5,
  SingleOperations: 6,
  SingleMarketing: 7,
  SingleWashCount: 8,
  SingleCustomerBehavior: 9,
  Terms: 10,
};
