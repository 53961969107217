import moment from 'moment';
import * as queryString from 'query-string';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { fetchEmployees } from '../../employees/actions';
import { selectPage, selectPageSize, selectTotal } from '../../employees/reducer';
import * as actions from '../actions';
import { selectShiftInEdit, selectShifts } from '../reducer';

const styles = {
  tableHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

const Shifts = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const shifts = useSelector(selectShifts);
  const initialShiftInEdit = useSelector(selectShiftInEdit);
  const filter = query.type;
  const total = useSelector(selectTotal);
  const page = useSelector(selectPage);
  const pageSize = useSelector(selectPageSize);

  useEffect(() => {
    props.fetchShifts();
    props.fetchEmployees();
  }, []);

  const getShiftsPage = (newPage) => {
    props.fetchShifts({ query: { newPage } });
  };

  const getPaginationInfo = () => {
    return {
      total: total,
      page: page,
      pageSize: pageSize,
      goToPage: getShiftsPage,
    };
  };

  const editShift = (event, { _id, _employeeId }) => {
    props.getShift(_id, _employeeId);
    const dialogProps = {
      title: 'Edit Shift',
      onSubmit: props.saveShift,
      isNew: false,
    };
    props.showDialog('UPSERT_SHIFT', dialogProps);
  };

  const deleteShift = (event, { _id, _employeeId, description, employeeName }) => {
    const confirmProps = {
      onReady: props.deleteShift,
      onReadyParams: [_id, _employeeId],
      text: `Are you sure you want to archive shift: ${description} for ${employeeName}?`,
      title: 'Archive Shift',
    };
    props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  const initializeDatatable = (initialShifts) => {
    const data = initialShifts.map((shift) => {
      const employeeName = `${shift.employee.lastName}, ${shift.employee.firstName}`;
      return {
        _id: shift.id,
        _employeeId: shift.employeeId,
        employeeName,
        name: shift.name,
        description: shift.getDescription(intl.formatMessage),
        start: moment(shift.start).format('MM/DD/YYYY'),
        end: moment(shift.end).format('MM/DD/YYYY'),
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'employee' }) },
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'description' }) },
      { name: intl.formatMessage({ id: 'startDate' }) },
      { name: intl.formatMessage({ id: 'endDate' }) },
      {
        name: intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-trash', buttonAction: deleteShift },
              { icon: 'icon-edit', buttonAction: editShift },
            ],
          },
        },
      },
    ];

    return {
      data,
      headers,
    };
  };

  const addDialogProps = {
    title: 'Add Shift',
    onSubmit: props.addShift,
    isNew: true,
  };
  return (
    <section className="page-main-content">
      <div className="table-buttons">
        <div></div>
        <PageActions
          actionFunction={props.showDialog}
          modalProps={addDialogProps}
          actions={[
            {
              dialogType: 'UPSERT_SHIFT',
              dialogProps: addDialogProps,
              text: 'Add Shift',
              permissionKey: addPermissionRefs.team,
            },
          ]}
        />
      </div>
      <DataTable paginationInfo={getPaginationInfo()} scrolling {...initializeDatatable(shifts)} />
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      showDialog,
      fetchEmployees,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Shifts);
