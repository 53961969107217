import * as _ from 'lodash';
import { RequestStatus } from '../types/request-status';
import { Alert } from '../views/alerts/models';

interface AuthState {
  jwtToken: string;
  refreshToken: string;
  zendeskWidgetKey: any;
  session: any;
  selectedSite: number | null;
  loadingSelf: boolean;
  status: { type: RequestStatus; message: string };
  userGroups: [];
  operatorGroup: any;
  alerts: [];
  alertsTotal: number;
}

export const initialState: AuthState = {
  jwtToken: '',
  refreshToken: '',
  zendeskWidgetKey: null,
  session: null,
  selectedSite: null,
  loadingSelf: false,
  status: { type: 'idle', message: '' },
  userGroups: [],
  operatorGroup: null,
  alerts: [],
  alertsTotal: 0,
};

const loginRequest = (state: AuthState) => {
  return {
    ...state,
  };
};

const loginSuccess = (
  state: AuthState,
  jwtToken: string,
  refreshToken: string,
  selectedSite: number
) => {
  return {
    ...state,
    jwtToken,
    refreshToken,
    selectedSite,
  };
};

const loginFailure = (state: AuthState, err: any) => {
  const message = _.get(err, 'error.message', 'Unexpected error occured.');
  return {
    ...state,
    status: {
      type: 'failure',
      message,
    },
  };
};

const passwordTokenCheck = (state: AuthState, message: string) => {
  const type = message === 'invalid' ? 'failure' : '';
  const newMessage = message === 'invalid' ? 'Token is invalid' : '';
  return {
    ...state,
    status: {
      type,
      message: newMessage,
    },
  };
};

const logout = () => {
  return initialState;
};

const setUserGroups = (state: AuthState, action: any) => {
  return {
    ...state,
    userGroups: action.response.results,
  };
};
const setOperatorGroup = (state: AuthState, action: any) => {
  return {
    ...state,
    operatorGroup: action.response,
  };
};
const passwordResetFailure = (state: AuthState, error: string) => {
  return {
    ...state,
    status: {
      type: 'failure',
      message: error,
    },
  };
};

const refreshSuccess = (state: AuthState, jwtToken: string, refreshToken: string) => {
  return {
    ...state,
    jwtToken,
    refreshToken,
  };
};

const setSelectedSite = (state: AuthState, siteId: number) => {
  return {
    ...state,
    selectedSite: siteId,
  };
};

const setZendeskWidgetKey = (state: AuthState, response: any) => {
  const zendeskWidgetKey =
    (response && response.zendeskConfiguration && response.zendeskConfiguration.managerWidgetKey) ||
    null;
  return {
    ...state,
    zendeskWidgetKey,
  };
};

const requestSelf = (state: AuthState) => {
  return {
    ...state,
    loadingSelf: true,
  };
};

const getSelfSuccess = (state: AuthState, user: any) => {
  return {
    ...state,
    session: user,
    loadingSelf: false,
  };
};

const getSelfFailed = () => {
  return initialState;
};

const forgotPasswordSuccess = (state: AuthState) => {
  return {
    ...state,
    status: {
      type: 'success',
      message: 'emailSent',
    },
  };
};

const passwordResetSuccess = (state: AuthState) => {
  return {
    ...state,
    status: {
      type: 'success',
      message: 'passwordUpdated',
    },
  };
};

const statusReset = (state: AuthState) => {
  return {
    ...state,
    status: {
      type: '',
      message: null,
    },
  };
};

const statusError = (state: AuthState, message: string) => {
  return {
    ...state,
    status: {
      type: 'failure',
      message,
    },
  };
};

const setSuccessStatus = (state: AuthState, message: string) => {
  return {
    ...state,
    status: {
      type: 'success',
      message,
    },
  };
};

const fetchAlertsSuccess = (state: AuthState, response: any) => {
  const { results, total } = response;
  const alerts = results.map((alert: any) => new Alert(alert));
  return {
    ...state,
    alerts,
    alertsTotal: total,
  };
};

// TODO: use action constants
export function auth(state = initialState, action: any) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return loginRequest(state);
    case 'LOGIN_SUCCESS': {
      const { jwtToken, refreshToken, selectedSite } = action;
      return loginSuccess(state, jwtToken, refreshToken, selectedSite);
    }
    case 'LOGIN_FAILURE':
      return loginFailure(state, action.error);
    case 'LOGOUT_SUCCESS':
      return logout();
    case 'REFRESH_SUCCESS':
      return refreshSuccess(state, action.jwtToken, action.refreshToken);
    case 'SET_SELECTED_SITE':
      return setSelectedSite(state, action.id);
    case 'FETCH_ZENDESK_KEY_SUCCESS':
      return setZendeskWidgetKey(state, action.response);
    case 'GET_SELF_REQUEST':
      return requestSelf(state);
    case 'GET_SELF_SUCCESS':
      return getSelfSuccess(state, action.user);
    case 'GET_SELF_FAILURE':
      return getSelfFailed();
    case 'FORGOT_PASSWORD_REQUEST_SUCCESS':
      return forgotPasswordSuccess(state);
    case 'PASSWORD_RESET_REQUEST_FAILURE':
      return passwordResetFailure(state, action.error);
    case 'PASSWORD_RESET_REQUEST_SUCCESS':
      return passwordResetSuccess(state);
    case 'STATUS_RESET':
      return statusReset(state);
    case 'STATUS_ERROR':
      return statusError(state, action.message);
    case 'SET_STATUS_SUCCESS':
      return setSuccessStatus(state, action.message);
    case 'PASSWORD_TOKEN_CHECK_REQUEST_SUCCESS':
      return passwordTokenCheck(state, action.response?.tokenStatus);
    case 'FETCH_USER_GROUPS_SUCCESS':
      return setUserGroups(state, action);
    case 'FETCH_OPERATOR_GROUP_SUCCESS':
      return setOperatorGroup(state, action);
    case 'FETCH_ALERTS_SUCCESS':
      return fetchAlertsSuccess(state, action.response);
    default:
      return state;
  }
}

// Selectors
export const selectUser = (state: any) => {
  const session = state.auth.session;
  return session;
};

export const selectJwt = (state: any) => {
  return state.auth.jwtToken;
};

export const isAuthenticated = (state: any) => {
  const user = selectUser(state);
  return user != null;
};

export const selectUserName = (state: any) => {
  const user = selectUser(state);
  return user && `${user.lastName}, ${user.firstName}`;
};

export const selectAuthError = (state: any) => {
  return state.auth.status && state.auth.status.type === 'failure' ? state.auth.status : '';
};

export const selectSite = (state: any) => {
  return state.auth.selectedSite;
};

export const selectSession = (state: any) => {
  return state.auth.session;
};

export const selectRole = (state: any) => {
  return state.auth.session && state.auth.session.role;
};

export const selectOperatorGroupId = (state: any) => {
  return state.auth.session && state.auth.session.operatorGroupId;
};

export const selectPermissions = (state: any) => {
  return _.get(state, 'auth.session.permissions', {});
};

export const selectUserGroup = (state: any) => {
  return _.get(state, 'auth.session.userGroup', {});
};
export const selectOperatorGroup = (state: any) => {
  return state.auth.operatorGroup;
};
export const selectUserGroupDynamicPermissions = (state: any) => {
  return _.get(state, 'auth.session.userGroup.dynamicPermissions', {});
};

export const selectAnalyticsEnabled = (state: any) => {
  return _.get(state, 'auth.session.permissions.analytics.enabled', false);
};

export const selectZendeskWidgetKey = (state: any) => {
  return state.auth.zendeskWidgetKey;
};

export const statusSelector = (state: any) => {
  return _.get(state, 'auth.status', '');
};

export const selectUserGroups = (state: any) => {
  return state.auth.userGroups;
};

export const selectAlerts = (state: any) => {
  return state.auth.alerts;
};

export const selectAlertsTotal = (state: any) => {
  return state.home.alertsTotal;
};
