import * as utils from '../../core/utils';

export const PriorityType = {
  Low: 0,
  Medium: 1,
  High: 2,
};

export const FeedbackType = {
  Positive: 0,
  Neutral: 1,
  Negative: 2,
};

export const ActionType = ['NoneNeeded', 'Resolved', 'NotYetResolved'];

export const ActionDisplayFormats = {
  NoneNeeded: 'None Needed',
  Resolved: 'Resolved',
  NotYetResolved: 'Not Yet Resolved',
};

export const getActionString = (index) => {
  return ActionDisplayFormats[index];
};

export default class Feedback {
  constructor(props = {}) {
    this.id = props.id;
    this.type = utils.toEnum(FeedbackType, props.type);
    this.priority = utils.toEnum(PriorityType, props.priority);
    this.timestamp = props.timestamp;
    this.comment = props.comment;
    this.customerName = props.customerName;
    this.actionTaken = props.actionTaken;
  }
}
