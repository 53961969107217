import { useEffect, useState } from 'react';

export default function useAbortController(updateOnChange) {
  const [abortController, setAbortController] = useState(new window.AbortController());

  const abortSignal = () => {
    abortController.abort();

    setAbortController(new window.AbortController());
  };

  useEffect(abortSignal, updateOnChange);

  return abortController;
}
