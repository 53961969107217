import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setArchivedFilter } from '../actions';

interface Props {
  archivedFilter: boolean;
}

export const ArchivedFilter = (props: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setArchivedFilter(event.target.checked));
  };

  return (
    <div className="form-group archived-checkbox">
      <label>
        <input
          data-testid="archived-checkbox"
          type="checkbox"
          className="custom-checkbox"
          onChange={handleChange}
          checked={props.archivedFilter}
        />
        {intl.formatMessage({ id: 'includeArchived' })}
      </label>
    </div>
  );
};

export default ArchivedFilter;
