import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { toString } from '../../../core/utils';
import { setReportType } from '../actions';
import { ReportType } from '../models';

export class ReportTypeSelector extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    setReportType: PropTypes.func.isRequired,
  };

  state = {
    value: -1,
  };

  getOptions = () => {
    return Object.keys(ReportType)
      .map((key) => toString(ReportType, key))
      .map((option, i) => (
        <option key={i} value={ReportType[option]}>
          {this.props.intl.formatMessage({ id: option })}
        </option>
      ));
  };

  handleChange = (event) => {
    const value = Number(event.target.value);
    this.setState({ value }, () => {
      this.props.setReportType(value);

      return { value };
    });
  };

  render = () => (
    <div className="form-group report-type-selector">
      <label>{this.props.intl.formatMessage({ id: 'reportType' })}</label>
      <select className="form-control" value={this.state.value} onChange={this.handleChange}>
        <option value="-1" disabled>
          Select a report
        </option>
        {this.getOptions()}
      </select>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setReportType }, dispatch);
};

export default compose(connect(null, mapDispatchToProps), injectIntl)(ReportTypeSelector);
