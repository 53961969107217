import React, { useEffect, useState } from 'react';
import moment from 'moment';
import VendingSalesRow from './VendingSalesRow';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearFileUploadStatus, updateVendingSales } from '../actions';
import { selectFileUploadStatus } from '../purchaseOrderReducer';

interface VendingSalesFormProps {
  employeeId: number;
  sites: any;
  formReset: any;
  intl: any;
}

const VendingSalesForm = ({ employeeId, sites, formReset, intl }: VendingSalesFormProps) => {
  const [displayedSites, setDisplayedSites] = useState(10);
  const [validRows, setValidRows] = useState<number[]>([]);
  const dispatch = useDispatch();
  const fileUploadStatus = useSelector(selectFileUploadStatus);
  const form = useForm({
    shouldUseNativeValidation: true,
    progressive: true,
  });
  const showMoreSites = () => {
    setDisplayedSites(displayedSites + 10);
  };
  const hasValidRow = (siteId: number, valid: boolean) => {
    const rowIndex = validRows.indexOf(siteId);
    const newRows = [...validRows];
    if (valid) {
      if (rowIndex == -1) newRows.push(siteId);
    } else {
      if (rowIndex > -1) newRows.splice(rowIndex, 1);
    }
    setValidRows(newRows);
  };
  const handleFormSubmit = (formData: any) => {
    const filteredSales = formData.sales.reduce((result: any, item: any) => {
      if (!isNaN(item.amount)) {
        const filteredRow = {
          siteId: item.siteId,
          amount: item.amount,
          statementPeriod: item.statementPeriod,
        };
        result.push(filteredRow);
      }
      return result;
    }, []);
    dispatch(
      updateVendingSales({
        employeeId: employeeId,
        sales: filteredSales,
      })
    );
    formReset();
  };
  const handleFormUploadMessage = () => {
    dispatch(clearFileUploadStatus());
  };

  useEffect(() => {
    handleFormUploadMessage();
  }, [fileUploadStatus]);
  return (
    <>
      <div className="vending-sales upload-form enhanced-form">
        <form
          id="vendingTotals"
          name="vendingTotals"
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          {sites.slice(0, displayedSites).map((site: any, i: any) => (
            <React.Fragment key={i}>
              <VendingSalesRow
                site={site}
                form={form}
                index={i}
                validCallback={hasValidRow}
                intl={intl}
              />
            </React.Fragment>
          ))}
        </form>
        {displayedSites < sites.length && (
          <button className="button" onClick={showMoreSites}>
            {intl.formatMessage({ id: 'showMore' })}
          </button>
        )}
      </div>
      <button
        className="button confirm full-width"
        type="submit"
        form="vendingTotals"
        disabled={!validRows.length}
      >
        {intl.formatMessage({ id: 'submit' })}
      </button>
    </>
  );
};

export default VendingSalesForm;
