export const initialState = {
  carMakes: [],
  carModels: {},
};

const setCarMakes = (state, response) => {
  return {
    ...state,
    carMakes: response.results,
  };
};

const setCarModels = (state, response, meta) => {
  const carModels = {
    ...state.carModels,
  };
  if (meta && meta.makeId) {
    carModels[meta.makeId] = response.results;
  }
  return {
    ...state,
    carModels,
  };
};

export function carModel(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CAR_MAKES_SUCCESS':
      return setCarMakes(state, action.response);
    case 'FETCH_CAR_MODELS_SUCCESS':
      return setCarModels(state, action.response, action.meta);
    default:
      return state;
  }
}

export const selectCarMakes = (state) => {
  return state.carModel.carMakes;
};

export const selectCarModels = (state) => {
  return state.carModel.carModels;
};
