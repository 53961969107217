import { useMemo } from 'react';
import { formatAmount } from '../../../utils/money-util';

const useRefundData = (refunds) => {
  const getRefundData = () => {
    const refundIds = ['pm-credit-card-refunds', 'pm-cash-refunds', 'pm-pay-as-you-go-refunds'];

    return refunds
      .filter((refund) => refundIds.includes(refund.id))
      .map((refund) => refund.formatToTableData());
  };

  const getPayAsYouGoRefundSubRows = () => {
    const paygRefundId = 'pm-pay-as-you-go-refunds';

    if (!refunds[0]?.id) {
      return {
        _id: '0',
        name: 'Unknown',
        amount: '',
        tax: '',
        total: '',
      };
    }
    return refunds
      .filter(({ id }) => id !== paygRefundId && id.includes('pm-pay-as-you-go'))
      .map((refund) => {
        return {
          _id: refund.id,
          name: refund.name,
          amount: '',
          tax: '',
          total: formatAmount(refund.total, refund.currency),
        };
      });
  };

  return useMemo(() => {
    return {
      data: getRefundData(),
      subRows: getPayAsYouGoRefundSubRows(),
    };
  }, [refunds]);
};

export default useRefundData;
