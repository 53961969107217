import { Big } from 'big.js';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BaseKpi from '../../../core/components/BaseKpi';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { getApiStatus } from '../../../core/utils';
import { setAnalyticsPage } from '../../analytics/actions';
import { FETCH_PRODUCT_PLAN_COUNTS } from '../../wash-counts/actions';

interface Props {
  fromDate: any;
  untilDate: any;
  plansCount: number;
  popSingleWash: number;
}

const getConversionRate = (instaClubTotal: number, singleWashes: string) => {
  try {
    return Big(instaClubTotal).div(singleWashes).times(100).toFixed(2);
  } catch (err) {
    return singleWashes;
  }
};

const InstaClubKpi = (props: Props): JSX.Element => {
  const singleWashes: string = Big(props.popSingleWash).plus(props.plansCount).toString();
  const conversionRate = getConversionRate(props.plansCount, singleWashes.toString());
  const instaClubLoading = useSelector((state) => getApiStatus(state, FETCH_PRODUCT_PLAN_COUNTS));
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const pushToAnalytics = () => {
    dispatch(setAnalyticsPage('Single-Sales Report'));
    history.push(`analytics`);
  };

  const dailyInstaClubCard = (): JSX.Element => {
    return (
      <div className="insta-club-kpi-row">
        <React.Fragment>
          <div data-testid="insta-club-sales">
            <BaseKpi
              countClassName={'insta-club-kpi-count'}
              headerClassName={'insta-club-kpi-title'}
              header={'instaClubSales'}
              count={props.plansCount}
              isLoading={instaClubLoading}
            />
          </div>
          <div data-testid="total-clubs-gained">
            <BaseKpi
              countClassName={'insta-club-kpi-count'}
              headerClassName={'insta-club-kpi-title'}
              header={'totalClubsGained'}
              secondHeader={'uniqueClubsGained'}
              count={singleWashes}
              isLoading={instaClubLoading}
            />
          </div>
          <div data-testid="insta-club-pop-total">
            <BaseKpi
              countClassName={'insta-club-kpi-count'}
              headerClassName={'insta-club-kpi-title'}
              header={'popTotal'}
              secondHeader={'percentClubsSold'}
              count={`${conversionRate}%`}
              isLoading={instaClubLoading}
            />
          </div>
        </React.Fragment>
      </div>
    );
  };

  return (
    <section className="overview-chart-card insta-club-kpi-body">
      <div>
        <div className="chart-title">{intl.formatMessage({ id: 'laneOneSales' })}</div>
        {dailyInstaClubCard()}
        <div className="button-with-loader-overview-container">
          <ButtonWithLoader style="button full-width bold gradient" handleClick={pushToAnalytics}>
            <React.Fragment>{intl.formatMessage({ id: 'mySalesReport' })}</React.Fragment>
          </ButtonWithLoader>
        </div>
      </div>
    </section>
  );
};

export default InstaClubKpi;
