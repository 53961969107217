import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router';
import { Col, Form, Label, Row } from 'reactstrap';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { requiredInput as RequiredInput } from '../../../core/formValidation';
import '../../../styles/set-password-form.scss';
import useSetPasswordForm from '../hooks/useSetPasswordForm';
import SetPasswordHeader from './SetPasswordHeader';
import LandingPageFooter from './LandingPageFooter';
import LoginSlideshow from './LoginSlideshow';

interface MatchParams {
  token: string;
}

interface Props extends RouteComponentProps<MatchParams> {} //eslint-disable-line

const SetPasswordForm = (props: Props): JSX.Element => {
  const {
    formik,
    handleSubmitClick,
    statusMessage,
    removeStatusMessage,
    setErrorMessage,
    checkValidationToken,
  } = useSetPasswordForm();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams<MatchParams>();
  const formDisabled = !formik.isValid || formik.isSubmitting || passwordsMatch;
  const intl = useIntl();

  const getTextInput = (
    fieldName: string,
    placeholderId: string,
    type: string,
    disabled: boolean = false // eslint-disable-line
  ) => {
    return {
      id: fieldName,
      type,
      className: 'set-password-form-input',
      placeholder: intl.formatMessage({ id: placeholderId }),
      ...formik.getFieldProps(fieldName),
      disabled,
    };
  };

  useEffect(() => {
    checkValidationToken(token);
  }, []);
  useEffect(() => {
    if (statusMessage.message === 'passwordUpdated') {
      setTimeout(() => {
        history.push('/login');
        removeStatusMessage();
      }, 2000);
    }
  }, [statusMessage.message]);
  useEffect(() => {
    const password = formik.getFieldProps('password').value;
    const confirmPassword = formik.getFieldProps('confirmPassword').value;
    if (confirmPassword) {
      if (password === confirmPassword) {
        if (password.length < 6) {
          setPasswordsMatch(true);
          setErrorMessage('passwordLength');
        }
        if (password.length >= 6) {
          setPasswordsMatch(false);
          return removeStatusMessage();
        }
      }
      if (password !== confirmPassword) {
        setPasswordsMatch(true);
        setErrorMessage('passwordMustMatch');
      }
    }
  }, [formik.getFieldProps('confirmPassword').value, formik.getFieldProps('password').value]);
  return statusMessage.message === 'Token is invalid' ? (
    <div className="login-container">
      <LoginSlideshow />
      <div className="login-form">
        <SetPasswordHeader />
        <div className="set-password-error alert alert-danger">
          {intl.formatMessage({ id: 'invalidTokenMessage' })}
        </div>
        <LandingPageFooter />
      </div>
    </div>
  ) : (
    <div className="login-container">
      <LoginSlideshow />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="login-form"
        data-testid="set-password-form"
      >
        <SetPasswordHeader />
        <Row>
          <Col className="login-input-remove-padding">
            <Label for="password" className="set-password-form-input-label">
              {intl.formatMessage({ id: 'password' })}
            </Label>
            <RequiredInput input={getTextInput('password', 'password', 'password')} />
          </Col>
        </Row>
        <Row>
          <Col className="login-input-remove-padding">
            <Label for="confirmPassword" className="set-password-form-input-label">
              {intl.formatMessage({ id: 'confirmPassword' })}
            </Label>
            <RequiredInput input={getTextInput('confirmPassword', 'confirmPassword', 'password')} />
          </Col>
        </Row>
        {statusMessage && statusMessage.type === 'success' && (
          <div className="set-password-error alert alert-success">
            {intl.formatMessage({ id: statusMessage.message })}
          </div>
        )}
        {statusMessage && statusMessage.type === 'failure' && (
          <div data-testid="alert-message" className="set-password-error alert alert-danger">
            {intl.formatMessage({ id: statusMessage.message })}
          </div>
        )}
        {statusMessage && statusMessage.type !== 'success' && (
          <ButtonWithLoader
            handleClick={handleSubmitClick}
            disabled={formDisabled}
            isLoading={formik.isSubmitting}
          >
            <div className="set-password-button-text">{intl.formatMessage({ id: 'send' })}</div>
          </ButtonWithLoader>
        )}
        <LandingPageFooter />
      </Form>
    </div>
  );
};

export default SetPasswordForm;
