import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FileType } from '../../views/claimDetail/models';
import { showDialog } from '../actions';
import * as utils from '../utils';
import FileReader from './FileReader';

class UploadSection extends Component {
  static propTypes = {
    fileType: PropTypes.string.isRequired,
    addFile: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
  };

  handleFiles = (files) => {
    let fileTypeValid = false;
    let fileType;

    if (/image/.test(files.fileList[0].type)) {
      fileTypeValid = true;
      fileType = FileType.image;
    } else if (/video/.test(files.fileList[0].type)) {
      fileTypeValid = true;
      fileType = FileType.video;
    }

    if (fileTypeValid) {
      this.openUploadModal(files, fileType);
    }
  };

  handleFileUpload = ({ file, description }) => {
    const fd = new FormData();

    fd.append('file', file, file.name);
    fd.append('description', description);

    this.props.addFile(fd);
  };

  openUploadModal = (file, fileType) => {
    this.props.showDialog('UPSERT_FILE', {
      onSubmit: this.handleFileUpload,
      file,
      fileType,
    });
  };

  render() {
    return (
      <div className="upload-section">
        <FileReader
          handleFiles={this.handleFiles}
          fileType={utils.toString(FileType, this.props.fileType)}
          title="Upload"
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showDialog,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(UploadSection);
