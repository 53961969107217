import { useEffect, useState } from 'react';
import { useAppContext } from '../../providers/AppProvider';
import Alerts from './Alerts';
import SystemStatus from './SystemStatus';
import SiteSelector from '../../views/site-selection/containers/index';

interface Props {
  setNavState: (isNavOpen: boolean) => void;
  isNavOpen: boolean;
  username: string;
}

export const Nav = (props: Props) => {
  const { theme } = useAppContext();
  const { username } = props;

  useEffect(() => {
    const link = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement;
    if (link) {
      link.href = theme.favicon || 'assets/icon/favicon.ico';
    }
  }, [theme.favicon]);

  return (
    <nav className="n-nav">
      <button className="nav-mobile-button" onClick={() => props.setNavState(!props.isNavOpen)}>
        <i className="uil uil-bars" />
        <i className="uil uil-angle-left"></i>
      </button>
      <span className="n-site-name">
        <div className="sites-container">
          <SiteSelector />
        </div>
      </span>
      <section className="nav-top-menus n-menus">
        <SystemStatus />
        <span className="nav-username">{username}</span>
        <Alerts />
      </section>
    </nav>
  );
};

export default Nav;
