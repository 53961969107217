import { Utility } from './models';

const initialState = {
  utilities: [],
  utilityInEdit: {},
};

const fetchUtilitiesSuccess = (state, response) => {
  const utilities = response.results.map((u) => new Utility(u));
  return {
    ...state,
    utilities,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const getUtilitySuccess = (state, utility) => {
  return {
    ...state,
    utilityInEdit: new Utility(utility),
  };
};

export const utilities = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_UTILITIES_SUCCESS':
      return fetchUtilitiesSuccess(state, action.response);
    case 'GET_UTILITY_SUCCESS':
      return getUtilitySuccess(state, action.response);
    default:
      return state;
  }
};

export const selectUtilities = (state) => {
  return state.utilities.utilities;
};

export const selectUtilityInEdit = (state) => {
  return state.utilities.utilityInEdit;
};

export const selectTotal = (state) => {
  return state.utilities.total;
};

export const selectPage = (state) => {
  return state.utilities.page;
};

export const selectPageSize = (state) => {
  return state.utilities.pageSize;
};
