import isFunction from 'lodash/isFunction';
import omitBy from 'lodash/omitBy';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Loader from './Loader';

// TODO: remove button styles and use Button
const styles = {
  actions: {
    padding: 0,
    minWidth: '85px',
  },
  edit: {
    padding: '0.5rem',
    color: '#000',
  },
  subRow: {
    borderTop: 'none',
    paddingTop: '0',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
  subRowNamePadding: {
    paddingLeft: '32px',
  },
  highlightColoring: {
    color: 'red',
  },
};

const DataRow = ({
  data,
  rowClicked,
  isSubRow,
  navigationInfo,
  rowClickInfo,
  history,
  setSelectedRow,
  rowInEdit,
  isChecked,
  highlighted,
  rowComponents,
}) => {
  history = useHistory();
  const isReactElement = (value) => {
    return value && value.$$typeof === Symbol.for('react.element');
  };
  const highlightInformation = data._highlightInformation || {};

  const filtered = omitBy(data, (value, key) => /^_/.test(key));

  const mixInComponentFields = (cellData) => {
    if (rowComponents) {
      const totalLength = cellData.length + Object.keys(rowComponents).length;
      const output = [];

      let adjustment = 0;
      for (let i = 0; i < totalLength; i++) {
        if (rowComponents[i]) {
          output.push('');
          adjustment++;
        } else {
          output.push(cellData[i - adjustment]);
        }
      }

      return output;
    }

    return cellData;
  };

  const { navigationProperty } = navigationInfo || {};
  const id = data[navigationProperty];

  const goTo = () => {
    if (navigationInfo.path === ' ') {
      history.push(`${history.location.pathname}/${id}/${navigationInfo.path}`);
    } else {
      const path = navigationInfo.path.replace(':id', id);
      history.push(path);
    }
  };

  const getOnClick = () => {
    if (navigationInfo) {
      return goTo;
    }
    if (rowClickInfo) {
      return () => {
        rowClickInfo.callback(data);
      };
    }
    if (data._onClick) {
      return data._onClick;
    }
    return rowClicked;
  };

  const getRowClassNames = () => {
    const classNames = ['dr-row'];

    if (highlighted) {
      classNames.push('highlighted');
    }
    if (navigationInfo || rowClickInfo) {
      classNames.push('clickable-datarow');
    }
    if (data._isOldPrice) {
      classNames.push('dr-old-price');
    }
    if (data._classNames) {
      classNames.push(...data._classNames);
    }
    if (data._isArchived) {
      classNames.push('archived');
    }

    return classNames.join(' ');
  };

  const getTdClassNames = (index) => {
    if (data._tdClassNames) {
      return data._tdClassNames[index] || [];
    }

    return [];
  };

  const cells = mixInComponentFields(values(filtered)).map((value, index) => {
    const addPadding = isSubRow && index === 0;
    // Show extra components
    if (value && typeof value === 'object' && !isReactElement(value)) {
      return (
        <td
          style={{
            ...(isSubRow ? styles.subRow : {}),
            ...(addPadding ? styles.subRowNamePadding : {}),
          }}
          key={index}
          onClick={getOnClick()}
        >
          <span>{value.value}</span>
          {value.extras}
        </td>
      );
    }

    if (rowComponents) {
      if (rowComponents[index] && isFunction(rowComponents[index].component)) {
        const MakeComponent = rowComponents[index].component;
        const processedProps = {
          isChecked,
          rowData: data,
        };

        if (rowComponents[index].mixIns) {
          processedProps.mixIns = rowComponents[index].mixIns;
        }

        return (
          <td key={index}>
            <MakeComponent {...processedProps} />
          </td>
        );
      }
    }

    const highlightColoring =
      highlightInformation.highlight && index === highlightInformation.index;

    // Value only
    return (
      <td
        style={{
          ...(isSubRow ? styles.subRow : {}),
          ...(addPadding ? styles.subRowNamePadding : {}),
          ...(highlightColoring ? styles.highlightColoring : {}),
        }}
        data-testid="datatable-row-cell"
        key={index}
        onClick={getOnClick()}
        className={getTdClassNames(index)}
      >
        {value}
      </td>
    );
  });

  const loaderSpinnerOptions = {
    width: '50px',
    height: '50px',
  };

  const checkboxIdentity = `multi-check-${Math.random()}`;

  return (
    <React.Fragment>
      <tr className={getRowClassNames()} data-testid="data-row">
        {!!setSelectedRow && (
          <td className="text-center dr-multi-select-cell">
            <input
              id={checkboxIdentity}
              className="dr-styled-checkbox"
              data-testid="datatable-row-checkbox"
              type="checkbox"
              checked={!!isChecked}
              onChange={() => setSelectedRow(data)}
            />
            <label htmlFor={checkboxIdentity} />
          </td>
        )}
        {cells}
        {data._loading && (
          <td className="dr-loader-container">
            <Loader options={loaderSpinnerOptions} />
          </td>
        )}
      </tr>
      {data._extra && data._extra.value && (
        <tr className="dr-row dr-extra-data-row">
          <td colSpan="8" className="dr-extra-data-cell">
            <span className="dr-extra-data-header">{data._extra.header} </span>
            <span>{data._extra.value}</span>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

DataRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  rowClicked: PropTypes.func,
  isSubRow: PropTypes.bool,
  navigationInfo: PropTypes.shape({
    navigationProperty: PropTypes.string,
    path: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  rowClickInfo: PropTypes.shape({
    callback: PropTypes.func.isRequired,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }),
  setSelectedRow: PropTypes.func,
  isChecked: PropTypes.bool,
  rowComponents: PropTypes.shape({}),
};

export default DataRow;
