import { ContentState, convertFromHTML, Editor, EditorState } from 'draft-js';
import { PropTypes } from 'prop-types';
import React from 'react';

const ReadOnlyEditor = ({ text }) => {
  const getState = () => {
    const blocksFromHTML = convertFromHTML(text);

    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(content);
  };

  return (
    <div className="readonly-editor">{text && <Editor editorState={getState()} readOnly />}</div>
  );
};

export default ReadOnlyEditor;

ReadOnlyEditor.propTypes = {
  text: PropTypes.string,
};
