import * as API from '../../core/api/reclamations';
import { API_REQUEST } from '../../core/actions';
import { ActionExternalCall, ActionInternalCall } from '../../utils/typing';

export const FETCH_REWASHES = 'FETCH_REWASHES';
export const SET_REWASH_FROM_DATE = 'SET_REWASH_FROM_DATE';
export const SET_REWASH_UNTIL_DATE = 'SET_REWASH_UNTIL_DATE';

export const fetchRewashes = (query: any): ActionExternalCall => {
  return {
    type: API_REQUEST,
    callAPI: API.getReclamations,
    action: FETCH_REWASHES,
    data: { query },
  };
};

export const setFromDate = (date: string): ActionInternalCall => ({
  type: SET_REWASH_FROM_DATE,
  payload: date,
});
export const setUntilDate = (date: string): ActionInternalCall => ({
  type: SET_REWASH_UNTIL_DATE,
  payload: date,
});
