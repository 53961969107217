import React from 'react';
import { useDispatch } from 'react-redux';

interface FilterProps {
  intl: any;
  selectedFilter: string;
  options: any[];
  label: string;
  setFilter: any;
}

const TaskFilter = ({ intl, selectedFilter, options, label, setFilter }: FilterProps) => {
  const dispatch = useDispatch();
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setFilter(event.target.value));
  };

  return (
    <div className="form-group">
      <label className="bold">{label}</label>
      <select className="form-control" value={selectedFilter} onChange={handleOnChange}>
        <option value="All">{intl?.formatMessage({ id: 'all' })}</option>
        {options.map((taskType, i) => (
          <option key={`${taskType}-${i}`} value={taskType.type}>
            {taskType.message}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TaskFilter;
