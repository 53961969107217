import * as _ from 'lodash';
import moment from 'moment';
import { selectApiRequests } from '../store/apiRequestReducer';
import { selectActionQueue } from '../store/queueReducer';

// eslint-disable-next-line
export const isChecked = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    const lower = value.toLowerCase();
    if (lower === 'true') {
      return true;
    }
    if (lower === 'false') {
      return false;
    }
  }
  return undefined;
};

export const toEnum = (type, value) => {
  return _.isString(value) ? type[value] : value;
};

export const toString = (type, value) => {
  return _.isString(value) ? value : _.invert(type)[value];
};

export const prefix = (number) => {
  const numToPrefix = typeof number !== 'string' ? number.toString() : number;
  return numToPrefix.length > 1 ? numToPrefix : `0${numToPrefix}`;
};

export const checkDisabled = (cases) => {
  return cases.filter((c) => c).length > 0;
};

export const getKeyFromEnum = (enumType, value) => {
  return _.invert(enumType)[value];
};

export const formatStringToDate = (dateStr) => {
  let year = dateStr.substring(0, 4);
  let month = dateStr.substring(4, 6);
  let day = dateStr.substring(6, 8);

  return `${month}/${day}/${year}`;
};

export const formatTimeElapsed = (duration) => {
  return `
    ${Math.floor(duration.asHours())} hrs
    ${duration.minutes()} min
    ${duration.seconds()} sec`;
};

export function spacesToCamelCaseString(string) {
  if (!string) {
    return '';
  }
  return (
    string
      // lowercase the first character
      .replace(/^./, (str) => str.toLowerCase())
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
}

// Sometimes moment has trouble formatting the date in MM/DD/YYYY format
export function momentInMMDDYYYYFormat(due) {
  if (!moment.isMoment(due)) {
    return due;
  }
  const addZeroIfNeeded = (number) => (number < 10 ? `0${number}` : number);
  const month = due.month() + 1;
  const day = due.date();
  return `${addZeroIfNeeded(month)}/${addZeroIfNeeded(day)}/${due.year()}`;
}

export const getApiStatus = (state, apiRequest) => {
  const apiRequests = selectApiRequests(state);
  const actionQueue = selectActionQueue(state);

  const pendingRequest = _.get(apiRequests, `${apiRequest}.isLoading`, false);
  const queuedUpRequest = actionQueue.some(({ action }) => action === apiRequest);

  return pendingRequest || queuedUpRequest;
};

// Generates a unique Id
export function generateUniqId() {
  return `${Math.floor(Math.random() * Date.now())}`;
}

export function randomHexColorGenerator() {
  return '#000000'.replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
}

export function setRandomColor() {
  const o = Math.round,
    r = Math.random,
    s = 255;
  return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
}
