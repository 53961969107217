import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { INITIALIZED } from '../core/constants';
import rootSaga from '../sagas';
import apiRequestCounter from './apiRequestCounter';
import queueHandler from './queueHandler';
import rootReducer from './rootReducer';

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [apiRequestCounter, queueHandler, sagaMiddleware];

  // Store enhancers
  const enhancers = [];

  // store init
  // eslint-disable-next-line
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  store.dispatch({ type: INITIALIZED });

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      /* eslint-disable */
      const nextRootReducer = require('./rootReducer').default;
      store.replaceReducer(nextRootReducer);
      /* eslint-enable */
    });
  }

  return store;
}
