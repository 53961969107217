import * as api from '../api';

export const fetchUtilities = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/utilities`, args);
};

export const getUtility = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/utilities/${id}`, args);
};

export const postUtility = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/utilities`, args);
};

export const patchUtility = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/utilities/${id}`, args);
};

export const deleteUtility = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callDelete(`/manager/sites/${siteId}/utilities/${id}`, args);
};
