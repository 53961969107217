import { RequestStatus } from '../../types/request-status';
import Employee from './models';

export interface SearchParams {
  page: number;
  includeArchived?: boolean;
}

interface EmployeesState {
  employees: Employee[];
  employeeInEdit: Employee | Record<string, unknown>;
  page: number;
  total: number;
  pageSize: number;
  addEmployeeStatus: RequestStatus;
  editEmployeeStatus: RequestStatus;
  archiveEmployeeStatus: RequestStatus;
  unarchiveEmployeeStatus: RequestStatus;
  searchParams: SearchParams;
}

interface Action {
  type: string;
  response?: any;
  addEmployeeStatus?: RequestStatus;
  editEmployeeStatus?: RequestStatus;
  archiveEmployeeStatus?: RequestStatus;
  unarchiveEmployeeStatus?: RequestStatus;
  meta?: any;
  searchParams?: SearchParams;
}

interface MetaData {
  filteredEmployee: number;
}

const initialState: EmployeesState = {
  employees: [],
  employeeInEdit: {},
  page: 1,
  total: 0,
  pageSize: 0,
  addEmployeeStatus: 'idle',
  editEmployeeStatus: 'idle',
  archiveEmployeeStatus: 'idle',
  unarchiveEmployeeStatus: 'idle',
  searchParams: {
    page: 0,
    includeArchived: false,
  },
};

const fetchEmployeesSuccess = (
  state: EmployeesState,
  response: any,
  meta?: MetaData
): EmployeesState => {
  let employees = response.results.map((e: Employee) => {
    return new Employee(e);
  });

  if (meta?.filteredEmployee) {
    employees = employees.filter((employee: Employee) => employee.id !== meta.filteredEmployee);
  }

  return {
    ...state,
    employees,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const getEmployeeSuccess = (state: EmployeesState, employee: Employee): EmployeesState => {
  return {
    ...state,
    employeeInEdit: new Employee(employee),
  };
};

const setAddEmployeeSuccess = (state: EmployeesState): EmployeesState => {
  return {
    ...state,
    addEmployeeStatus: 'success',
  };
};

const setAddEmployeeFailure = (state: EmployeesState): EmployeesState => {
  return {
    ...state,
    addEmployeeStatus: 'failure',
  };
};

const setAddEmployeeStatus = (state: EmployeesState, action: Action): EmployeesState => {
  return {
    ...state,
    addEmployeeStatus: action.addEmployeeStatus as RequestStatus,
  };
};

const setEditEmployeeSuccess = (state: EmployeesState): EmployeesState => {
  return {
    ...state,
    editEmployeeStatus: 'success',
  };
};

const setEditEmployeeFailure = (state: EmployeesState): EmployeesState => {
  return {
    ...state,
    editEmployeeStatus: 'failure',
  };
};

const setEditEmployeeStatus = (state: EmployeesState, action: Action): EmployeesState => {
  return {
    ...state,
    editEmployeeStatus: action.editEmployeeStatus as RequestStatus,
  };
};

const setArchiveEmployeeStatus = (state: EmployeesState, action: Action): EmployeesState => {
  return {
    ...state,
    archiveEmployeeStatus: action.archiveEmployeeStatus as RequestStatus,
  };
};

const setUnarchiveEmployeeStatus = (state: EmployeesState, action: Action): EmployeesState => {
  return {
    ...state,
    unarchiveEmployeeStatus: action.unarchiveEmployeeStatus as RequestStatus,
  };
};

const setSearchParams = (state: EmployeesState, action: Action): EmployeesState => {
  return {
    ...state,
    searchParams: action.searchParams as SearchParams,
  };
};

export const employees = (state = initialState, action: Action): EmployeesState => {
  switch (action.type) {
    case 'FETCH_EMPLOYEES_SUCCESS':
      return fetchEmployeesSuccess(state, action.response, action.meta);
    case 'GET_EMPLOYEE_SUCCESS':
      return getEmployeeSuccess(state, action.response);
    case 'ADD_EMPLOYEE_SUCCESS':
      return setAddEmployeeSuccess(state);
    case 'ADD_EMPLOYEE_FAILURE':
      return setAddEmployeeFailure(state);
    case 'SET_ADD_EMPLOYEE_STATUS':
      return setAddEmployeeStatus(state, action);
    case 'SAVE_EMPLOYEE_SUCCESS':
      return setEditEmployeeSuccess(state);
    case 'SAVE_EMPLOYEE_FAILURE':
      return setEditEmployeeFailure(state);
    case 'SET_SAVE_EMPLOYEE_STATUS':
      return setEditEmployeeStatus(state, action);
    case 'ARCHIVE_EMPLOYEE_SUCCESS':
      return setArchiveEmployeeStatus(state, { ...action, archiveEmployeeStatus: 'success' });
    case 'ARCHIVE_EMPLOYEE_FAILURE':
      return setArchiveEmployeeStatus(state, { ...action, archiveEmployeeStatus: 'failure' });
    case 'SET_ARCHIVE_EMPLOYEE_STATUS':
      return setArchiveEmployeeStatus(state, action);
    case 'UNARCHIVE_EMPLOYEE_SUCCESS':
      return setUnarchiveEmployeeStatus(state, { ...action, unarchiveEmployeeStatus: 'success' });
    case 'UNARCHIVE_EMPLOYEE_FAILURE':
      return setUnarchiveEmployeeStatus(state, { ...action, unarchiveEmployeeStatus: 'failure' });
    case 'SET_UNARCHIVE_EMPLOYEE_STATUS':
      return setUnarchiveEmployeeStatus(state, action);
    case 'SET_SEARCH_PARAMS':
      return setSearchParams(state, action);
    default:
      return state;
  }
};

export const selectEmployees = (state: any): Employee[] => {
  return state.employees.employees;
};

export const selectEmployeeInEdit = (state: any): Employee | Record<string, unknown> => {
  return state.employees.employeeInEdit;
};

export const selectTotal = (state: any): number => {
  return state.employees.total;
};

export const selectPage = (state: any): number => {
  return state.employees.page;
};

export const selectPageSize = (state: any): number => {
  return state.employees.pageSize;
};

export const selectAddEmployeeStatus = (state: any): RequestStatus => {
  return state.employees.addEmployeeStatus;
};

export const selectEditEmployeeStatus = (state: any): RequestStatus => {
  return state.employees.editEmployeeStatus;
};

export const selectArchiveEmployeeStatus = (state: any): RequestStatus => {
  return state.employees.archiveEmployeeStatus;
};

export const selectUnarchiveEmployeeStatus = (state: any): RequestStatus => {
  return state.employees.unarchiveEmployeeStatus;
};

export const selectSearchParams = (state: any): SearchParams => {
  return state.employees.searchParams;
};
