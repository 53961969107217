import { useHistory, useParams } from 'react-router-dom';
import InventoryDetail from './InventoryDetail';

const InventoryDetailPage = (props) => {
  return (
    <div className="container-fluid">
      <section className="page-main-content">
        <InventoryDetail isPage={true} />
      </section>
    </div>
  );
};

export default InventoryDetailPage;
