import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { azure } from '../views/analytics/reducer';
import { claimReports } from '../views/claims/reducer';
import { detergents } from '../views/detergents/reducer';
import { employees } from '../views/employees/reducer';
import { purchaseOrders } from '../views/expenses/purchaseOrderReducer';
import { utilities } from '../views/expenses/utilityReducer';
import { utilityUnits } from '../views/expenses/utilityUnitReducer';
import { feedback } from '../views/feedback/reducer';
import { inventory } from '../views/inventory/reducer';
import { home } from '../views/overview/reducer';
import { products } from '../views/products/reducer';
import { reclamations } from '../views/reclamations/reducer';
import { siteSettings } from '../views/settings/reducer';
import { shifts } from '../views/shifts/reducer';
import { siteSelection } from '../views/site-selection/reducer';
import { taskDetail } from '../views/taskDetail/reducer';
import { tasks } from '../views/tasks/reducer';
import { washCounts } from '../views/wash-counts/reducer';
import { workLogs } from '../views/work-logs/reducer';
import { apiRequests } from './apiRequestReducer';
import { auth } from './authReducer';
import { carModel } from './carModelReducer';
import { modal } from './modal';
import { actionQueue } from './queueReducer';
import { theme } from './themeReducer';
import { rewashes } from '../views/rewashes/reducer';
import { uploadForms } from '../views/expenses/uploadsReducer';

export default combineReducers({
  home,
  theme,
  auth,
  azure,
  carModel,
  siteSelection,
  siteSettings,
  products,
  tasks,
  taskDetail,
  detergents,
  reclamations,
  rewashes,
  claimReports,
  employees,
  shifts,
  feedback,
  inventory,
  utilityUnits,
  utilities,
  purchaseOrders,
  uploadForms,
  washCounts,
  workLogs,
  form: formReducer,
  toastr: toastrReducer,
  modal,
  apiRequests,
  actionQueue,
});
