import * as utils from '../../core/utils';

export const InventoryType = {
  Part: 0,
  Tool: 1,
  Equipment: 2,
  Detergent: 3,
};

export const ContainerSizes = [2.6, 5.2, 15, 30, 55];

export class InventoryHistory {
  constructor(props = {}) {
    this.id = props.id;
    this.property = props.property;
    this.instanceId = props.instanceId;
    this.oldValue = props.oldValue;
    this.newValue = props.newValue;
    this.createdAt = props.createdAt;
  }
}

export class Inventory {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.number = props.number;
    this.inventoryTypeId = props.inventoryTypeId;
    this.siteId = props.siteId;
    this.description = props.description;
    this.historyLogs = props?.historyLogs?.map((log) => new InventoryHistory(log)) || [];
    this.installedAt = props.installedAt;
    this.preferredStockLevel = props.preferredStockLevel;
    this.createdAt = props.createdAt;
    this.vendorUrl = props.vendorUrl;
    this.inventoryData = props.inventoryData;
    this.unitOfMeasure = props.unitOfMeasure;
    this.onHand = props.onHand;
    this.employeeId = props.employeeId;
    this.priority = props.priority;
    this.groupId = props.groupId;
    this.templateId = props.templateID;
  }
}
