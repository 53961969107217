const initialState = {
  queue: [],
};

export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE';

const addActionToQueue = (state, { actionToBeAdded }) => {
  return {
    queue: [...state.queue, actionToBeAdded],
  };
};

const removeActionFromQueue = (state) => {
  return {
    queue: state.queue.slice(1),
  };
};

export function actionQueue(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_QUEUE:
      return addActionToQueue(state, action);
    case REMOVE_FROM_QUEUE:
      return removeActionFromQueue(state);
    default:
      return state;
  }
}

export const selectActionQueue = (state) => {
  return state.actionQueue.queue;
};
