import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { showDialog } from '../../../core/actions';
import DetailHeader from '../../../core/components/DetailHeader';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import {
  deletePermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import * as utils from '../../../core/utils';
import {
  deleteReclamation,
  archiveReclamation,
  unarchiveReclamation,
  deleteReclamationInEdit,
  getReclamation,
} from '../../reclamations/actions';
import useDamageClaimOptions from '../../reclamations/hooks/useDamageClaimOptions';
import Reclamation, { ReclamationStatus } from '../../reclamations/models';
import { selectReclamationInEdit } from '../../reclamations/reducer';
import ClaimDetailContent from '../components/ClaimDetailContent';
import ClaimDetailInfo from '../components/ClaimDetailInfo';
import IssueSiteApproval from '../components/IssueSiteApproval';

export const ClaimDetail = (props) => {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const reclamationInEdit = useSelector(selectReclamationInEdit);
  useDamageClaimOptions();

  useEffect(() => {
    const id = params.claim;
    dispatch(getReclamation(id));

    return function cleanUp() {
      dispatch(deleteReclamationInEdit());
    };
  }, []);

  const getTitles = (reclamation) => {
    const status = utils.toString(ReclamationStatus, reclamation.status);
    return [
      {
        children: (
          <div className="detail-status" key="status">
            <p className="button inverted">{status && intl.formatMessage({ id: status })}</p>
          </div>
        ),
      },
      {
        className: 'detail-id',
        label: intl.formatMessage({ id: 'issue' }),
        content: reclamation.id,
      },
      {
        className: 'detail-date',
        label: intl.formatMessage({ id: 'issueDate' }),
        content: moment(reclamation.issueDate).format('MM-DD-YYYY LT'),
      },
      {
        className: 'detail-created',
        label: intl.formatMessage({ id: 'issueCreated' }),
        content: moment(reclamation.createdAt).format('MM-DD-YYYY LT'),
      },
    ];
  };

  const closeView = () => {
    const { site } = params;
    history.push(`/${site}/claims`);
  };

  const _deleteReclamation = () => {
    const { id, type } = reclamationInEdit;
    const { site } = params;

    const query = {
      id,
      type,
      callback: () => dispatch(deleteReclamationInEdit()),
      navigate: () => {
        history.push(`/${site}/claims`);
      },
    };

    const confirmProps = {
      onReady: () => {
        dispatch(deleteReclamation(query));
      },
      text: `${intl.formatMessage({
        id: 'confirmReclamationDelete',
      })}: ${id}?`,
      title: intl.formatMessage({ id: 'deleteReclamation' }),
    };

    dispatch(showDialog('CONFIRM_DIALOG', confirmProps));
  };

  const _archiveReclamation = () => {
    const { id, type } = reclamationInEdit;
    const { site } = params;

    const query = {
      id,
      type,
      callback: () => dispatch(deleteReclamationInEdit()),
      navigate: () => {
        history.push(`/${site}/claims`);
      },
    };

    const confirmProps = {
      onReady: () => {
        dispatch(archiveReclamation(query));
      },
      text: `${intl.formatMessage({
        id: 'confirmReclamationArchive',
      })}: ${id}?`,
      title: intl.formatMessage({ id: 'archiveReclamation' }),
    };

    dispatch(showDialog('CONFIRM_DIALOG', confirmProps));
  };

  const _unarchiveReclamation = () => {
    const { id, type } = reclamationInEdit;
    const { site } = params;

    const query = {
      id,
      type,
      callback: () => dispatch(deleteReclamationInEdit()),
      navigate: () => {
        history.push(`/${site}/claims`);
      },
    };

    const confirmProps = {
      onReady: () => {
        dispatch(unarchiveReclamation(query));
      },
      text: `${intl.formatMessage({
        id: 'confirmReclamationUnarchive',
      })}: ${id}?`,
      title: intl.formatMessage({ id: 'unarchiveReclamation' }),
    };

    dispatch(showDialog('CONFIRM_DIALOG', confirmProps));
  };

  const editReclamation = () => {
    const path = history.location.pathname.trim();
    history.push(`${path}edit`);
  };

  const generateButtons = (archived) => {
    if (archived) {
      return [
        {
          onClick: _unarchiveReclamation,
          label: intl.formatMessage({ id: 'unarchive' }),
          class: 'button mr-2',
          permissionKey: deletePermissionRefs.warranty,
        },
        {
          onClick: editReclamation,
          label: intl.formatMessage({ id: 'edit' }),
          permissionKey: editPermissionRefs.warranty,
        },
      ];
    } else {
      return [
        {
          onClick: _archiveReclamation,
          label: intl.formatMessage({ id: 'archive' }),
          class: 'button mr-2',
          permissionKey: deletePermissionRefs.warranty,
        },
        {
          onClick: editReclamation,
          label: intl.formatMessage({ id: 'edit' }),
          permissionKey: editPermissionRefs.warranty,
        },
      ];
    }
  };

  const reclamation = new Reclamation(reclamationInEdit || {});
  const archived = reclamation.archivedAt !== null;
  return (
    <div className="container-fluid">
      <section className="page-main-content">
        <DetailButtonGroup history={history} intl={intl} buttons={generateButtons(archived)} />
        <IssueSiteApproval reclamation={reclamation} />
        <DetailHeader titles={getTitles(reclamation)} />
        <ClaimDetailInfo reclamation={reclamation} />
        <ClaimDetailContent reclamation={reclamation} />
      </section>
    </div>
  );
};

export default ClaimDetail;
