import * as api from '../api';

// eslint-disable-next-line
export const fetchOperatorGroup = (args) => {
  const {
    options: { groupId },
  } = args;
  return api.callGet(`/operator-groups/${groupId}`, args);
};

export const fetchOperatorGroupPublic = (args) => {
  const {
    options: { groupId },
  } = args;
  return api.callGet(`/operator-groups/${groupId}/public-properties`, args);
};
