import '../../../../styles/submit-user-button.scss';
import { IntlType } from '../../../../utils/typing';

interface Props {
  intl: IntlType;
  handleClick: () => void;
  disabled: boolean;
}

const CreateUserButton = (props: Props): JSX.Element => {
  const { intl, handleClick, disabled } = props;

  return (
    <button
      className="submit-user-button"
      onClick={handleClick}
      disabled={disabled}
      data-testid="create-user-button"
    >
      {intl.formatMessage({ id: 'createUser' })}
    </button>
  );
};

export default CreateUserButton;
