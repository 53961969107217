import { PropTypes } from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const DetergentCostPerCar = ({ data }) => {
  const labels = data.map((i) => i.detergentName);
  const currentCosts = data.map((i) => Number(i.costPerCarWeightedAverage));
  const maxValue = Math.max(...currentCosts);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Cost',
        backgroundColor: '#333333',
        data: currentCosts,
      },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: { display: false },
          ticks: { max: maxValue, min: 0 },
        },
      ],
    },
  };

  const bar = maxValue > 0 && <Bar data={chartData} options={options} />;
  return <div>{bar}</div>;
};

DetergentCostPerCar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DetergentCostPerCar;
