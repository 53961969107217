import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { PowerBiView, UserReportViewType } from '../models';

export const PermissionViewButtons = ({ permissionLevel, view, changeView, includesSingle }) => {
  switch (permissionLevel) {
    case UserReportViewType.Single:
      return (
        <div className="powerbi-button-container">
          <button
            className={classNames(
              'powerbi-right-buttons',
              'powerbi-left-buttons',
              'powerbi-active'
            )}
            id="SingleView"
          >
            Single Site View
          </button>
        </div>
      );
    case UserReportViewType.Multi:
      return (
        <div className="powerbi-button-container">
          {includesSingle && (
            <button
              className={
                view === PowerBiView.Single
                  ? classNames('powerbi-active', 'powerbi-right-buttons')
                  : classNames('powerbi-inactive', 'powerbi-right-buttons')
              }
              onClick={view !== PowerBiView.Single ? () => changeView(PowerBiView.Single) : null}
              id="SingleView"
            >
              Single Site View
            </button>
          )}
          <button
            className={
              view === PowerBiView.Multi
                ? classNames('powerbi-active', 'powerbi-left-buttons')
                : classNames('powerbi-inactive', 'powerbi-left-buttons')
            }
            onClick={view !== PowerBiView.Multi ? () => changeView(PowerBiView.Multi) : null}
            id="MultiView"
          >
            Multi-Site View
          </button>
        </div>
      );
    default:
      return <div />;
  }
};

export default PermissionViewButtons;

PermissionViewButtons.propTypes = {
  permissionLevel: PropTypes.number,
  view: PropTypes.string,
  changeView: PropTypes.func,
  includesSingle: PropTypes.bool,
};
