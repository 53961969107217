import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { selectEmployees } from '../../employees/reducer';
import TaskCompleteForm from '../components/TaskCompleteForm';

export class CompleteTaskAOTDialog extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isValid: PropTypes.bool,
    closeDialog: PropTypes.func.isRequired,
    onReady: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    task: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('taskComplete');
  };

  onSubmit = (values) => {
    const data = {
      ...values,
      taskId: this.props.task.id,
    };

    this.props.onReady(data);
  };

  getTaskCompleteForm = (initialValues) => (
    <TaskCompleteForm
      onSubmit={this.onSubmit}
      employees={this.props.employees}
      task={this.props.task}
      initialValues={initialValues}
      enableReinitialize
    />
  );

  showTaskCompleteForm = () => this.props.isValid === true || this.props.isValid === undefined;

  render() {
    const { closeDialog, isOpen, employees, title, text, intl } = this.props;
    const initialValues = { employeeId: employees[0] && employees[0].id };
    return (
      <ModalDialog
        title={title}
        onReady={this.showTaskCompleteForm() ? this.onReady : undefined}
        isOpen={isOpen}
        close={closeDialog}
      >
        {this.showTaskCompleteForm() && text && (
          <div className="complete-task-aot-text">{text}</div>
        )}
        {this.showTaskCompleteForm() ? (
          this.getTaskCompleteForm(initialValues)
        ) : (
          <div className="pre-completion-redirect-message">
            {intl.formatMessage({ id: 'redirectToTodaysTasks' })}
          </div>
        )}
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => ({ employees: selectEmployees(state) });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(CompleteTaskAOTDialog);
