import React from 'react';
import { useAppContext } from '../../../providers/AppProvider';

const Logo = () => {
  const { theme } = useAppContext();

  return <img src={theme.logo} alt="logo" />;
};

export default Logo;
