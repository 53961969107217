import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import DetergentCostPerCar from '../../../core/components/DetergentCostPerCar';
import DetergentLevels from '../../../core/components/DetergentLevels';

const DetergentCharts = ({ intl, detergents, chartData, asOfDateProps }) => (
  <React.Fragment>
    <div className="col-sm-6">
      <DetergentLevels data={detergents} />
    </div>
    <div className="col-sm-6">
      <h5>{intl.formatMessage({ id: 'costPerCar' })}</h5>
      <DateRangeSelector
        dates={[asOfDateProps.fromDate, asOfDateProps.untilDate]}
        onSubmit={(dates) => {
          asOfDateProps.setFromDate(dates.from);
          asOfDateProps.setUntilDate(dates.until);
        }}
      />
      <DetergentCostPerCar data={chartData} />
    </div>
  </React.Fragment>
);

DetergentCharts.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object),
  chartData: PropTypes.arrayOf(PropTypes.object),
  asOfDateProps: PropTypes.shape({}).isRequired,
};

export default injectIntl(DetergentCharts);
