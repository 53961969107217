import { find } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions, selectUserGroup } from '../../store/authReducer';
import { UserGroupPermission } from '../../views/employees/models';
import Permission, { hasRole, RoleTypes } from './Permission';
import { hasUserPermission, UserPermissions } from './UserPermissions';

interface Props {
  itemRef: string;
  minRole: string;
  children: React.ReactChild | React.ReactChildren;
}

export const viewPermissionRefs = {
  admin: 'system.admin',
  task: 'manager.task.view',
  site: 'manager.site.view',
  detergent: 'manager.detergent.view',
  warranty: 'manager.warranty.view',
  rewash: 'manager.rewash.view',
  team: 'manager.team.view',
  expense: 'manager.expense.view',
  expense_vendor: 'manager.expense_vendor.view',
  inventory: 'manager.inventory.view',
  feedback: 'manager.feedback.view',
  wash_count: 'manager.wash_count.view',
  wash_count_adjustments: 'manager.wash_count_adjustments.view',
  settings: 'manager.site_settings.view',
  damage: 'manager.damage_options.view',
  products: 'manager.products.view',
  manageUsers: 'manager.manage_users',
  analytics: 'manager.analytics.view',
  multi_site: 'manager.multi_site.view',
  revenue_comparison: 'manager.charts.revenue_comparison',
  window_sales: 'manager.charts.window_sales',
  wash_count_chart: 'manager.charts.wash_counts',
  claims_chart: 'manager.charts.claims',
  cost_per_car: 'manager.charts.cost_per_car',
  survey_chart: 'manager.charts.surveys',
  interior_filter: 'manager.wash_count.view_interior_filter',
  wash_analytics: 'manager.wash_count.wash_analytics',
  view_maintenance_page: 'manager.task.view_maintenance_page',
  view_logs: 'manager.task.view_logs',
  view_upload: 'manager.expense.view_upload',
  essential_parts: 'manager.inventory.essential_parts',
  hourly_comparision_chart: 'manager.charts.hourly_wash_kpi',
};

export const addPermissionRefs = {
  task: 'manager.task.add',
  site: 'manager.site.add',
  detergent: 'manager.detergent.add',
  warranty: 'manager.warranty.add',
  rewash: 'manager.rewash.add',
  team: 'manager.team.add',
  expense: 'manager.expense.add',
  expense_vendor: 'manager.expense_vendor.add',
  inventory: 'manager.inventory.add',
  feedback: 'manager.feedback.add',
  wash_count: 'manager.wash_count.add',
  wash_count_adjustments: 'manager.wash_count_adjustments.add',
  settings: 'manager.site_settings.add',
  damage: 'manager.damage_options.add',
  products: 'manager.products.add',
  analytics: 'manager.analytics.add',
};

export const editPermissionRefs = {
  task: 'manager.task.edit',
  site: 'manager.site.edit',
  detergent: 'manager.detergent.edit',
  warranty: 'manager.warranty.edit',
  rewash: 'manager.rewash.edit',
  team: 'manager.team.edit',
  expense: 'manager.expense.edit',
  expense_vendor: 'manager.expense_vendor.edit',
  inventory: 'manager.inventory.edit',
  feedback: 'manager.feedback.edit',
  wash_count: 'manager.wash_count.edit',
  wash_count_adjustments: 'manager.wash_count_adjustments.edit',
  settings: 'manager.site_settings.edit',
  damage: 'manager.damage_options.edit',
  products: 'manager.products.edit',
  analytics: 'manager.analytics.edit',
};

export const deletePermissionRefs = {
  task: 'manager.task.delete',
  site: 'manager.site.delete',
  detergent: 'manager.detergent.delete',
  warranty: 'manager.warranty.delete',
  rewash: 'manager.rewash.delete',
  team: 'manager.team.delete',
  expense: 'manager.expense.delete',
  expense_vendor: 'manager.expense_vendor.delete',
  inventory: 'manager.inventory.delete',
  feedback: 'manager.feedback.delete',
  wash_count: 'manager.wash_count.delete',
  wash_count_adjustments: 'manager.wash_count_adjustments.delete',
  settings: 'manager.site_settings.delete',
  damage: 'manager.damage_options.delete',
  products: 'manager.products.delete',
  analytics: 'manager.analytics.delete',
};

export const findPermissionRef = (
  permissions: UserGroupPermission[],
  ref: string
): UserGroupPermission | undefined => {
  return find(permissions, ['ref', ref]);
};

export const UserGroupPermissions = (props: Props): React.ReactElement<any> | JSX.Element => {
  const permissions = useSelector(selectPermissions);
  const userGroup = useSelector(selectUserGroup);
  const { itemRef, minRole, children } = props;
  const enhancedRole = findPermissionRef(userGroup.permissions, viewPermissionRefs.admin)
    ? 'Admin'
    : userGroup.role;

  if (hasRole(enhancedRole, RoleTypes.OperatorGroupAdmin)) {
    return (
      <Permission role={enhancedRole} minRole={minRole}>
        {children}
      </Permission>
    );
  }

  if (hasUserPermission(itemRef)) {
    return (
      <UserPermissions
        role={enhancedRole}
        minRole={minRole}
        permissionKey={itemRef}
        permissions={permissions}
      >
        {children}
      </UserPermissions>
    );
  }

  return findPermissionRef(userGroup.permissions, itemRef) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <React.Fragment />
  );
};

UserGroupPermissions.defaultProps = {
  minRole: 'SiteManager',
};

export default UserGroupPermissions;
