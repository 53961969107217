import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import FeedbackEditForm from '../components/FeedbackEditForm';
import Feedback from '../models';
import { selectFeedbackInEdit } from '../reducer';

const initialFormValues = {
  customerName: null,
  type: 0,
  comment: null,
  priority: 0,
  actionTaken: 0,
};

class UpsertFeedbackDialog extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    initialFeedback: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool,
    errors: PropTypes.shape({}),
  };

  onReady = () => {
    this.props.triggerSubmit('feedback');
  };

  render() {
    const { closeDialog, isOpen, initialFeedback, isNew, onSubmit } = this.props;
    const enableReinitialize = !isNew;
    const submitDisabled = checkErrors(this.props.errors);

    return (
      <ModalDialog
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <FeedbackEditForm
          onSubmit={onSubmit}
          initialValues={initialFeedback}
          enableReinitialize={enableReinitialize}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('feedback');

const mapStateToProps = (state, ownProps) => {
  const initialFeedback = ownProps.isNew
    ? new Feedback(initialFormValues)
    : selectFeedbackInEdit(state);
  const errors = getErrors(state);

  return {
    initialFeedback,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertFeedbackDialog);
