import { debounce, find, isEqual, omit } from 'lodash';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import DataTable from '../../../core/components/DataTable';
import { selectReclamationDamageOptions } from '../../reclamations/reducer';
import { getCarMakeReports, getCarModelReports, removeCarModelReport } from '../actions';
import { formatReportTableData } from '../helpers';
import { ReportType } from '../models';
import {
  selectCarMakeReports,
  selectCarModelReports,
  selectFromDate,
  selectUntilDate,
} from '../reducer';
import { MakeModelReportPropTypes } from '../types';

export class MakeModelReport extends PureComponent {
  static propTypes = MakeModelReportPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      searchResults: null,
      searchResultMakes: [],
    };
  }

  componentDidMount = () => {
    this.props.getCarMakeReports({ query: this.props.dateQuery });
  };

  componentDidUpdate = (prevProps) => {
    const carMakeReportsChanged = prevProps.carMakeReports !== this.props.carMakeReports;
    const datesChanged = !isEqual(
      omit(prevProps.dateQuery, 'additionalSiteIds'),
      this.props.dateQuery
    );

    if (datesChanged) {
      this.props.getCarMakeReports({ query: this.props.dateQuery });

      Object.keys(this.props.carModelReports).forEach(this.getCarModelReports);
    }

    if (carMakeReportsChanged) {
      const reports = this.props.carMakeReports;

      // eslint-disable-next-line
      this.setState(({ searchResultMakes }) => ({
        searchResults:
          searchResultMakes.length > 0
            ? reports.filter(({ makeId }) => searchResultMakes.includes(makeId))
            : reports,
      }));
    }
  };

  getCarModelsAsSubRows = () => {
    const subRows = [];
    const carMakes = this.props.carMakeReports || [];
    const subRowObjects = carMakes.map(this.toSubRowObjects);

    subRowObjects.forEach((object) => {
      const parentCarMake = find(carMakes, { makeId: object.parentRowId });
      const carModels = this.props.carModelReports[object.parentRowId] || [];

      if (parentCarMake) {
        subRows.push({
          parentRow: object.parentRowId,
          rows: {
            data: carModels.map(this.toCorrectModelFormat),
            headers: object.columns,
          },
        });
      }
    });

    return subRows;
  };

  getFuzzyCarMakeMatches = debounce((searchText) => {
    const results = [];
    const resultMakes = [];
    const lowerCasedSearchText = searchText.toLowerCase();

    this.props.carMakeReports.forEach((carMake) => {
      const tokens = carMake.make.split('');
      let searchPosition = 0;

      for (const token of tokens) {
        if (token.toLowerCase() === lowerCasedSearchText[searchPosition]) {
          searchPosition += 1;
          if (searchPosition >= lowerCasedSearchText.length) {
            break;
          }
        }
      }

      if (searchPosition === lowerCasedSearchText.length) {
        results.push(carMake);
        resultMakes.push(carMake.makeId);
      }
    });

    this.setState({
      searchResults: results.length > 0 ? results : null,
      searchResultMakes: resultMakes,
    });
  }, 500);

  getSubRowInfo = () => ({
    identifier: '_makeId',
    displayProperty: 'vehicleMake',
    subRows: this.getCarModelsAsSubRows(),
    subRowFetchFunction: this.getCarModelReports,
    subRowRemovalFunction: this.props.removeCarModelReport,
  });

  getSearchInfo = () => [
    {
      index: 0,
      colSpan: 3, // The input should be a bit wider since the AreaDamage table is a bit crowded
      element: (
        <input
          placeholder={this.props.intl.formatMessage({
            id: 'searchForVehicle',
          })}
          className="form-control data-table-search"
          onChange={(e) => this.getFuzzyCarMakeMatches(e.target.value)}
        />
      ),
    },
  ];

  getColGroup = () => (
    <colgroup>
      <col className="first-column" />
    </colgroup>
  );

  getCarModelReports = (makeId) => {
    this.props.getCarModelReports({ query: this.props.dateQuery, makeId });
  };

  toCorrectModelFormat = (carModel) => {
    const carPartProperties = {};

    this.props.reclamationDamageOptions.forEach((carPart) => {
      let value = carPart.value;
      if (carPart.value === 'Trim / Molding') {
        value = 'Trim Or Molding';
      }
      carPartProperties[value] = carModel.countsByCarPart[value.replace(/\s+/g, '')];
    });

    return {
      _id: carModel.id,
      vehicleMake: carModel.model,
      ...carPartProperties,
      grandTotal: carModel.count,
    };
  };

  toSubRowObjects = (make) => ({
    parentRowId: make.makeId,
    columns: ['vehicleModel', 'grandTotal'],
  });

  reportTableDataGetter = (searchResults) => {
    const data = searchResults || this.props.carMakeReports;

    return formatReportTableData(
      ReportType.ClaimsByVehicleModel,
      this.props.intl.formatMessage,
      data
    );
  };

  initializeDatatable = () => ({
    ...this.reportTableDataGetter(this.state.searchResults),
    addedClass: 'report-table shrinked-table',
    subRowInfo: this.getSubRowInfo(),
    searchInfo: this.getSearchInfo(),
    colGroup: this.getColGroup(),
    scrolling: true,
  });

  render = () => <DataTable {...this.initializeDatatable()} />;
}

const mapStateToProps = (state) => {
  const [fromDate, untilDate] = [selectFromDate(state), selectUntilDate(state)];
  return {
    carMakeReports: selectCarMakeReports(state),
    carModelReports: selectCarModelReports(state),
    fromDate,
    untilDate,
    dateQuery: {
      fromDate: fromDate.format('YYYY-MM-DD'),
      untilDate: untilDate.format('YYYY-MM-DD'),
    },
    reclamationDamageOptions: selectReclamationDamageOptions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCarMakeReports,
      getCarModelReports,
      removeCarModelReport,
    },
    dispatch
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(MakeModelReport);
