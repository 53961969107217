import { createBrowserHistory } from 'history';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Router } from 'react-router';
import { hideDialog as hideDialogAction } from '../core/actions';
import RootRoute from '../routes/RootRoute';
import googleAnalytics from '../utils/google-analytics-gtag';

const isProductionBuild = window.location.hostname === 'manager.tommys-express.com';
const trackingKey = isProductionBuild ? 'UA-173498189-1' : 'UA-173498189-2';
const gaGtag = googleAnalytics(trackingKey);
const history = createBrowserHistory();

const RouteProvider = () => {
  const dispatch = useDispatch();

  history.listen((location) => {
    gaGtag.gtag('config', trackingKey, { page_path: location.pathname });

    dispatch(hideDialogAction());
  });

  return (
    <Router history={history}>
      <RootRoute />
    </Router>
  );
};

export default RouteProvider;
