import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEmployees,
  setSearchParams as setSearchParamsAction,
} from '../../../../employees/actions';
import Employee from '../../../../employees/models';
import {
  SearchParams,
  selectEmployees,
  selectPage,
  selectPageSize,
  selectSearchParams,
  selectTotal,
} from '../../../../employees/reducer';
import { selectMultiSiteSelection } from '../../../../site-selection/reducer';
import { selectSiteExtId } from '../../../reducer';

interface EmployeeProps {
  employees: Employee[];
  siteId: number;
  multiSite: any[];
  getEmployees: (selectedPage?: number) => void;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  setSearchParams: (params: any) => void;
  searchParams: SearchParams;
}

const useEmployees = (): EmployeeProps => {
  const dispatch = useDispatch();

  const employees: Employee[] = useSelector(selectEmployees);
  const siteId: number = useSelector(selectSiteExtId);
  const multiSite: any[] = useSelector(selectMultiSiteSelection);
  const total: number = useSelector(selectTotal);
  const page: number = useSelector(selectPage);
  const pageSize: number = useSelector(selectPageSize);
  const searchParams: SearchParams = useSelector(selectSearchParams);

  const getEmployees = () => {
    const query = {
      key: 'id',
      direction: 'desc',
      page: searchParams.page,
      includeArchived: searchParams.includeArchived,
    };

    dispatch(fetchEmployees({ data: { query } }));
  };

  const setSearchParams = (params: any) => {
    dispatch(
      setSearchParamsAction({
        ...searchParams,
        ...params,
      })
    );
  };

  return {
    employees,
    getEmployees,
    siteId,
    multiSite,
    pagination: {
      page,
      pageSize,
      total,
    },
    setSearchParams,
    searchParams,
  };
};

export default useEmployees;
