import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose } from 'redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import SuggestedItemPicker from '../../../core/components/SuggestedItemPicker';
import { fieldRequired, requiredSelect, TextArea } from '../../../core/formValidation';
import { toString } from '../../../core/utils';
import {
  PluralReclamationType,
  ReclamationIncidentArea,
  ReclamationStatus,
  ReclamationType,
  ReclamationWashType,
} from '../models';
import { selectReclamationDamageOptions } from '../reducer';
import CustomerDetails from './CustomerDetails';
import DamageInformation from './DamageInformation';
import RewashCauses from './RewashCauses';
import VehicleDetails from './VehicleDetails';
import { EmployeeMultiSelect } from '../../../core/components/EmployeeMultiSelect';
import { map } from 'lodash';

export const ReclamationForm = (props) => {
  // eslint-disable-next-line
  const {
    intl,
    type,
    carParts,
    affectedArea,
    history,
    employees,
    disabled,
    onReady,
    title,
    sites,
    isNew,
    match,
    fields,
  } = props;
  const render =
    (array = []) =>
    ({ input }) => {
      return (
        // onFocus has to be overridden due to dropdowns not working properly with it
        <select {...input} onFocus={null} className="form-control">
          {array.map((e) => (
            <option key={e.id} value={e.id}>
              {e.value || `${e.lastName}, ${e.firstName}`}
            </option>
          ))}
        </select>
      );
    };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion">
      <span>{suggestion.name}</span>
      <small>Site number: {suggestion.extId}</small>
    </div>
  );

  const getFormArrayProps = () => {
    const selectedEmployeeIds = map(fields, 'employeeId');
    return {
      employees,
      createdFields: fields,
      remainingEmployees: employees.filter((e) => !selectedEmployeeIds.includes(e.id)),
    };
  };

  return (
    <div className="container-fluid" data-testid="reclamationFormWrapper">
      <section className="page-main-content">
        <h1>
          {isNew && type === ReclamationType.Claim
            ? intl.formatMessage({ id: 'addCustomerDetails' })
            : title}
        </h1>
        <div className="form-horizontal">
          {employees.length > 0 && (
            <div className="form-group row" key="0">
              <div className="col-sm-6">
                <label htmlFor="employeeId">{intl.formatMessage({ id: 'employee' })}</label>
                <FieldArray
                  id="employeeIds"
                  name="employeeIds"
                  data-testid="employees-multi-select"
                  component={EmployeeMultiSelect}
                  {...getFormArrayProps()}
                />
              </div>
            </div>
          )}
          {[
            !isNew && type === ReclamationType.Claim && (
              <div className="form-group row" key="1">
                <div className="col-sm-6">
                  <label htmlFor="status">Status</label>
                  <Field
                    id="status"
                    name="status"
                    className="form-control"
                    component={requiredSelect}
                    parse={Number}
                    validate={[fieldRequired]}
                  >
                    <option value={ReclamationStatus.Incomplete} disabled>
                      {intl.formatMessage({ id: 'Incomplete' })}
                    </option>
                    <option value={ReclamationStatus.NeedsAttention}>
                      {intl.formatMessage({ id: 'NeedsAttention' })}
                    </option>
                    <option value={ReclamationStatus.Investigating}>
                      {intl.formatMessage({ id: 'Investigating' })}
                    </option>
                    <option value={ReclamationStatus.NeedsRepair}>
                      {intl.formatMessage({ id: 'NeedsRepair' })}
                    </option>
                    <option value={ReclamationStatus.UnderRepair}>
                      {intl.formatMessage({ id: 'UnderRepair' })}
                    </option>
                    <option disabled>------</option>
                    <option value={ReclamationStatus.Repaired}>
                      {intl.formatMessage({ id: 'Repaired' })}
                    </option>
                    <option value={ReclamationStatus.Resolved}>
                      {intl.formatMessage({ id: 'Resolved' })}
                    </option>
                    <option value={ReclamationStatus.NotCaused}>
                      {intl.formatMessage({ id: 'NotCaused' })}
                    </option>
                    <option value={ReclamationStatus.Denied}>
                      {intl.formatMessage({ id: 'Denied' })}
                    </option>
                    <option value={ReclamationStatus.Completed}>
                      {intl.formatMessage({ id: 'Completed' })}
                    </option>
                  </Field>
                </div>
              </div>
            ),
            (!isNew || type === ReclamationType.Rewash) && (
              <div className="form-group row" key="2">
                <div className="form-group col-sm-6">
                  <label htmlFor="description">Description</label>
                  <Field
                    className="form-control"
                    name="description"
                    id="description"
                    component={TextArea}
                    type="text"
                    aria-describedby="descriptionHelp"
                    placeholder={intl.formatMessage({ id: 'description' })}
                    maxLength="255"
                    data-testid="description-reclaimation-form"
                  />
                </div>
              </div>
            ),
            type === ReclamationType.Claim && (
              <div className="form-group row" key="4">
                <div className="form-group col-sm-6 d-flex flex-column">
                  <label htmlFor="issueDate">Incident date</label>
                  <Field
                    className="form-control"
                    name="issueDate"
                    id="issueDate"
                    component={DateTimePicker}
                    showToday
                    type="text"
                    aria-describedby="descriptionHelp"
                    placeholder="Add issue date"
                    placeholderText={`${moment(Date.now()).format('MM/DD/YY h:mm a')}`}
                    timeProps={{
                      showTimeSelect: true,
                      timeFormat: 'hh:mm aa',
                      timeIntervals: 15,
                      dateFormat: 'MM/dd/YYY h:mm aa',
                      timeCaption: 'Time',
                    }}
                  />
                </div>
                {!isNew && (
                  <div className="form-group col-sm-6">
                    <label htmlFor="issueSite">Issue site</label>
                    <Field
                      id="issueSiteId"
                      name="issueSiteId"
                      className="form-control"
                      component={(input) => (
                        <SuggestedItemPicker
                          {...input}
                          items={sites}
                          renderSuggestion={renderSuggestion}
                          placeholder="addIssueSite"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="form-group col-sm-6">
                  <label htmlFor="issueSite">Incident Wash Type</label>
                  <Field
                    id="issueWashType"
                    name="issueWashType"
                    className="form-control"
                    component={requiredSelect}
                    parse={Number}
                    validate={[fieldRequired]}
                  >
                    <option value={ReclamationWashType.Exterior}>
                      {intl.formatMessage({ id: 'exterior' })}
                    </option>
                    <option value={ReclamationWashType.Interior}>
                      {intl.formatMessage({ id: 'interior' })}
                    </option>
                    <option value={ReclamationWashType.Both}>
                      {intl.formatMessage({ id: 'both' })}
                    </option>
                  </Field>
                </div>
              </div>
            ),
          ]}
          <CustomerDetails />
          <VehicleDetails />
          {(!isNew || type === ReclamationType.Rewash) && [
            type === ReclamationType.Claim && (
              <div data-toggle="collapse" key="5">
                <DamageInformation />
                <div className="form-group row">
                  <div className="form-group col-sm-6">
                    <label htmlFor="affectedArea">Car Parts</label>
                    <Field
                      name="claimDamagePartId"
                      id="carPart"
                      component={render(carParts)}
                      parse={(value) => +value}
                    />
                  </div>
                  {affectedArea !== undefined && (
                    <div className="form-group col-sm-6">
                      <label htmlFor="affectedArea">Affected Area</label>
                      <Field
                        name="claimDamageAreaId"
                        id="affectedArea"
                        component={render(affectedArea)}
                        parse={(value) => +value}
                      />
                    </div>
                  )}
                </div>
              </div>
            ),
            type === ReclamationType.Rewash && <RewashCauses key="6" />,
          ]}
        </div>
        <Button
          color="secondary"
          className="button"
          onClick={() =>
            history.push(`/${match.params.site}/${toString(PluralReclamationType, type)}`)
          }
        >
          Cancel
        </Button>{' '}
        <Button color="primary" className="button confirm" onClick={onReady} disabled={disabled}>
          Ok
        </Button>
      </section>
    </div>
  );
};

ReclamationForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  type: PropTypes.number,
  carParts: PropTypes.arrayOf(PropTypes.shape({})),
  affectedArea: PropTypes.arrayOf(PropTypes.shape({})),
  onReady: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      site: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isNew: PropTypes.bool.isRequired,
};

const formSelector = formValueSelector('reclamation');

const mapStateToProps = (state) => {
  const results = { type: formSelector(state, 'type') };
  const carPartData = selectReclamationDamageOptions(state);
  const damageOption = carPartData.find((data) => data.value === 'Stationary Antenna');
  if (damageOption) {
    const carPart = formSelector(state, 'claimDamagePartId') || damageOption.id;

    const affectedArea = carPartData.find((i) => i.id === +carPart).options;
    results.carParts = carPartData;
    results.affectedArea = affectedArea;
  }
  return {
    ...results,
    fields: formSelector(state, 'employeeIds'),
  };
};
// TODO: add validator

export default compose(
  injectIntl,
  connect(mapStateToProps, null),
  reduxForm({ form: 'reclamation' }),
  withRouter
)(ReclamationForm);
