import { API_REQUEST } from '../../core/actions';
import * as passwordManagement from '../../core/api/user-password-management';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REFRESH_REQUEST = 'REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAILURE = 'REFRESH_FAILURE';

export const GET_SELF_REQUEST = 'GET_SELF_REQUEST';
export const GET_SELF_SUCCESS = 'GET_SELF_SUCCESS';
export const GET_SELF_FAILURE = 'GET_SELF_FAILURE';

export const PASSWORD_TOKEN_CHECK_REQUEST = 'PASSWORD_TOKEN_CHECK_REQUEST';
export const PASSWORD_TOKEN_CHECK_REQUEST_SUCCESS = 'PASSWORD_TOKEN_CHECK_REQUEST_SUCCESS';
export const PASSWORD_TOKEN_CHECK_REQUEST_FAILURE = 'PASSWORD_TOKEN_CHECK_REQUEST_FAILURE';

export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS';

export const STATUS_RESET = 'STATUS_RESET';
export const STATUS_ERROR = 'STATUS_ERROR';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';

export const checkTokenStatus = (token) => {
  return {
    type: API_REQUEST,
    callAPI: passwordManagement.postCheckPasswordResetToken,
    data: { passwordResetToken: token },
    action: PASSWORD_TOKEN_CHECK_REQUEST,
  };
};

export const forgotPassword = (username) => {
  return {
    type: API_REQUEST,
    callAPI: passwordManagement.postForgotPassword,
    data: { username },
    action: FORGOT_PASSWORD_REQUEST,
  };
};

export const setPassword = ({ token, password }) => {
  return {
    type: API_REQUEST,
    callAPI: passwordManagement.postResetPassword,
    data: { passwordResetToken: token, password },
    action: PASSWORD_RESET_REQUEST,
  };
};

export const statusReset = () => {
  return { type: STATUS_RESET };
};

export const statusError = (message) => {
  return { type: STATUS_ERROR, message };
};

export const login = (user) => {
  return { type: LOGIN_REQUEST, user };
};
export const loginFailure = (error) => {
  return { type: LOGIN_FAILURE, error };
};
export const loginSuccess = (jwtToken, refreshToken, selectedSite) => {
  return {
    type: LOGIN_SUCCESS,
    jwtToken,
    refreshToken,
    selectedSite,
  };
};

export const logout = () => {
  return { type: LOGOUT_REQUEST };
};
export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export const refreshSuccess = (jwtToken, refreshToken) => {
  return { type: REFRESH_SUCCESS, jwtToken, refreshToken };
};

export const refreshFailure = () => {
  return { type: REFRESH_FAILURE };
};

export const refreshRequest = (tokens) => {
  return { type: REFRESH_REQUEST, tokens };
};

export const getSelfRequest = () => {
  return { type: GET_SELF_REQUEST };
};

export const getSelfSuccess = (user) => {
  return { type: GET_SELF_SUCCESS, user };
};

export const getSelfFailure = () => {
  return { type: GET_SELF_FAILURE };
};
