export default class WorkLog {
  constructor(props) {
    this.id = props.id;
    this.start = props.start;
    this.employeeId = props.employeeId;
    this.employee = props.employee;
    this.end = props.end;
    this.completed = props.completed;
  }
}
