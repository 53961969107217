import React from 'react';
import { Field } from 'redux-form';
import { fieldRequired, requiredInput } from '../../../core/formValidation';

const CustomerDetails = () => {
  return (
    <div>
      <div className="form-group row" style={{ marginBottom: '0' }}>
        <div className="col-sm-6">
          <label>Customer Details</label>
        </div>
      </div>
      <div className="form-group row">
        <div className="form-group col">
          <Field
            className="form-control"
            name="customerName"
            id="customerName"
            component={requiredInput}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Name"
            validate={[fieldRequired]}
            data-testid="customerName-reclaimation-form"
          />
        </div>
        <div className="form-group col-sm-6">
          <Field
            className="form-control"
            name="customerEmail"
            id="customerEmail"
            component={requiredInput}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Email"
            validate={[fieldRequired]}
            data-testid="customerEmail-reclaimation-form"
          />
        </div>
        <div className="form-group col-sm-6">
          <Field
            className="form-control"
            name="customerPhone"
            id="customerPhone"
            component={requiredInput}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Phone number"
            validate={[fieldRequired]}
            data-testid="customerPhone-reclaimation-form"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
