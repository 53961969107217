import { isEqual } from 'lodash';
import { PropTypes } from 'prop-types';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { hasRole, RoleTypes } from '../../../core/components/Permission';
import Tabs from '../../../core/components/Tabs';
import {
  findPermissionRef,
  viewPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { PermissionKeys } from '../../../core/components/UserPermissions';
import { selectPermissions, selectRole, selectUserGroup } from '../../../store/authReducer';
import * as actions from '../actions';
import PurchaseOrders from './PurchaseOrders';
import Utilities from './Utilities';
import Uploads from './Upload';

export const component = (props) => {
  const [tabType, setTabType] = useState('purchaseOrders');
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();
  const query = queryString.parse(location.search);
  const filter = query.types;
  const permissions = useSelector(selectPermissions);
  const userGroup = useSelector(selectUserGroup);
  const currentRole = useSelector(selectRole);

  const tabComponents = [
    {
      component: (
        <div className="col-sm-12">
          <PurchaseOrders permissions={permissions} />
        </div>
      ),
      title: intl.formatMessage({ id: 'purchaseOrders' }),
      permissionKey: viewPermissionRefs.expense || PermissionKeys.viewPurchaseOrdersTab,
      type: 'purchaseOrders',
    },
    {
      component: (
        <div className="col-sm-12">
          <Utilities filter={filter} permissions={permissions} />
        </div>
      ),
      title: intl.formatMessage({ id: 'utilities' }),
      permissionKey: viewPermissionRefs.expense || PermissionKeys.viewUtilsTab,
      type: 'utilities',
    },
    {
      component: (
        <div className="col-sm-12">
          <Uploads />
        </div>
      ),
      title: intl.formatMessage({ id: 'upload' }),
      permissionKey: viewPermissionRefs.view_upload,
      type: 'upload',
    },
  ];

  const getTabsProps = () => ({
    tabs: tabComponents,
    onClick: setTabType,
    selectedType: tabType,
  });

  const setTabs = () => {
    if (
      hasRole(currentRole, RoleTypes.OperatorGroupAdmin) ||
      findPermissionRef(userGroup.permissions, viewPermissionRefs.expense)
    ) {
      return setTabType(tabComponents[0].type);
    }
    const viableTab = tabComponents.find((t) => permissions[t.permissionKey]);
    if (!viableTab) {
      history.push(`/${params.site}/overview`);
    } else {
      setTabType(viableTab.type);
    }
  };

  useEffect(() => {
    setTabs();
  }, [permissions]);

  const visibleComponent = tabComponents.find((t) => t.type === tabType);
  return (
    <div className="container-fluid">
      <Tabs {...getTabsProps()} />
      <section className="page-main-content">
        <div className="row">{visibleComponent.component}</div>
      </section>
    </div>
  );
};

component.propTypes = {
  filter: PropTypes.string,
  permissions: PropTypes.shape({}),
};

export const Expenses = React.memo(component, isEqual);

export default connect(null, actions)(Expenses);
