import PropTypes from 'prop-types';
import React, { ReactNode, useEffect, useState } from 'react';

interface Props {
  hide: boolean;
  children: ReactNode;
  styling?: string;
}

const Fade = ({ hide, children, styling }: Props) => {
  const [render, setRender] = useState(!hide);
  useEffect(() => {
    if (!hide) {
      setRender(true);
    }
  }, [hide]);

  const onTransitionEnd = () => {
    if (hide) {
      setRender(false);
    }
  };

  return (
    <div
      className={!hide ? `f-popover-show ${styling || ''}` : 'f-popover-hide'}
      onTransitionEnd={onTransitionEnd}
    >
      {render && children}
    </div>
  );
};

Fade.defaultProps = {
  hide: false,
};

Fade.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.bool,
};

export default Fade;
