import React from 'react';
import Loader from './Loader';

interface Props {
  handleClick: () => any;
  disabled?: boolean;
  isLoading?: boolean;
  style?: string;
  options?: { height: string; width: string };
  children?: JSX.Element;
}

const ButtonWithLoader = (props: Props): JSX.Element => {
  const { handleClick, disabled, isLoading = false, style } = props;
  const options = {
    height: props.options?.height || '40px',
    width: props.options?.width || '40px',
  };

  const className = style || 'button-with-loader';

  return (
    <button
      className={`${className}`}
      onClick={handleClick}
      disabled={disabled || isLoading}
      data-testid="button-with-loader"
    >
      {isLoading ? <Loader options={options} /> : props.children}
    </button>
  );
};

export default ButtonWithLoader;
