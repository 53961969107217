import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Select } from '../../../core/formValidation';
import { ProgramEntryType } from '../models';

export const SelectEquipments = ({ input, equipment, handleProductChange }) => (
  <select {...input} onChange={handleProductChange} className="form-control w-100">
    {equipment.map((p, index) => (
      <option key={index} value={p.id}>
        {p.name}
      </option>
    ))}
  </select>
);

SelectEquipments.propTypes = {
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  handleProductChange: PropTypes.func,
};

class ProgramAddressFields extends Component {
  static propTypes = {
    startAddress: PropTypes.number,
    ...SelectEquipments.propTypes,
  };

  addAddressField = (fields) => {
    const latestField = fields.get(fields.length - 1);
    fields.push({
      address: latestField ? latestField.address + 1 : fields.length,
      type: ProgramEntryType.LaneController,
    });
  };

  render() {
    const { fields, intl } = this.props;

    return (
      <React.Fragment>
        {fields.map((item, index) => {
          return (
            <div className="form-inline mb-2" key={index}>
              <div className="mr-2 flex-1">
                <Field
                  name={`${item}.address`}
                  parse={(value) => Number(value, 10)}
                  component="input"
                  type="number"
                  className="form-control w-100"
                />
              </div>
              <div className="flex-1">
                <Field
                  className="form-control w-100"
                  name={`${item}.type`}
                  component={Select}
                  parse={(value) => Number(value)}
                >
                  <option value={ProgramEntryType.LaneController}>
                    {intl.formatMessage({ id: 'laneController' })}
                  </option>
                  <option value={ProgramEntryType.EntranceModule}>
                    {intl.formatMessage({ id: 'entranceModule' })}
                  </option>
                  <option value={ProgramEntryType.VacuumController}>
                    {intl.formatMessage({ id: 'vacuumController' })}
                  </option>
                  <option value={ProgramEntryType.ROController}>
                    {intl.formatMessage({ id: 'roController' })}
                  </option>
                  <option value={ProgramEntryType.GWCController}>
                    {intl.formatMessage({ id: 'gwcController' })}
                  </option>
                  <option value={ProgramEntryType.IndusoftHMI}>
                    {intl.formatMessage({ id: 'indusoftHmi' })}
                  </option>
                  <option value={ProgramEntryType.InteriorController}>
                    {intl.formatMessage({ id: 'interiorController' })}
                  </option>
                </Field>
              </div>
              <button
                className="btn btn-secondary ml-2"
                style={{ display: 'inline-block' }}
                type="button"
                onClick={() => fields.remove(index)}
              >
                <i className="icon icon-cross" />
              </button>
            </div>
          );
        })}
        <div className="wash-counter-register-buttons-container">
          <button
            id="add-program-manual-address-button"
            className="btn btn-secondary"
            type="button"
            onClick={() => this.addAddressField(fields)}
          >
            {intl.formatMessage({ id: 'addManualAddress' })}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default ProgramAddressFields;
