import { IntlType } from '../../../../utils/typing';

interface Props {
  intl: IntlType;
  handleClick: () => void;
}

const NextPageButton = (props: Props): JSX.Element => {
  const { intl, handleClick } = props;

  return (
    <button className="next-page-button" onClick={handleClick}>
      {intl.formatMessage({ id: 'nextPage' })}
    </button>
  );
};

export default NextPageButton;
