import { get, isNil } from 'lodash';
import QueueAction from '../core/queueAction';
import { ADD_TO_QUEUE, REMOVE_FROM_QUEUE, selectActionQueue } from './queueReducer';

const getQueueFromState = (state) => {
  return selectActionQueue(state.getState());
};

const removeFromQueue = (state) => {
  state.dispatch({ type: REMOVE_FROM_QUEUE });
};

const dispatchNextActionFromQueue = (state) => {
  const nextAction = getQueueFromState(state)[0];

  if (nextAction) {
    state.dispatch({
      ...nextAction,
      meta: {
        ...nextAction.meta,
        queueAction: QueueAction.DispatchFromQueue,
      },
    });
  }
};

const addActionToQueue = (action, state, next) => {
  state.dispatch({ type: ADD_TO_QUEUE, actionToBeAdded: action });

  const queue = getQueueFromState(state);

  if (queue.length === 1) {
    next(action);
  }
};

const queueHandler = (state) => (next) => (action) => {
  const queueAction = get(action, 'meta.queueAction');

  if (isNil(queueAction) || queueAction === QueueAction.DispatchFromQueue) {
    next(action);
  } else if (queueAction === QueueAction.AddToQueue) {
    addActionToQueue(action, state, next);
  } else if (queueAction === QueueAction.RemoveFromQueue) {
    removeFromQueue(state);
    dispatchNextActionFromQueue(state);
    next(action);
  }
};

export default queueHandler;
