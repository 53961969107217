import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskLocations } from '../../tasks/reducer';
import { selectLocationFilter } from '../../tasks/reducer';
import { getTaskLocations } from '../../tasks/actions';
import { setLocationFilter } from '../../tasks/actions';
import TaskFilter from './TaskFilters';

interface LocationProps {
  intl: any;
}

export /**
 * Dropdown for filtering tasks by location
 *
 * @param {LocationProps} { intl } - Internationalization object for translations and formatting
 * @return {*}  {JSX.Element} - Dropdown for filtering tasks by location
 */
const LocationFilter = ({ intl }: LocationProps): JSX.Element => {
  const dispatch = useDispatch();
  const selectedValue = useSelector(selectLocationFilter);
  const locations = useSelector(selectTaskLocations);

  useEffect(() => {
    if (!locations.length) {
      dispatch(getTaskLocations());
    }
  }, []);

  return (
    <TaskFilter
      intl={intl}
      selectedFilter={selectedValue}
      options={locations}
      label={intl?.formatMessage({ id: 'taskLocation' })}
      setFilter={setLocationFilter}
    />
  );
};

export default LocationFilter;
