import { get, isNull, isUndefined, omitBy } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector, getFormSyncErrors, isDirty, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { toEnum } from '../../../core/utils';
import { ProductCategory } from '../../products/models';
import * as actions from '../actions';
import ProductForm from '../components/ProductForm';

export const UpsertProductDialog = ({
  triggerSubmit,
  isOpen,
  isNew,
  hide,
  errors,
  title,
  operations,
  onSubmit,
  operation,
  product,
  selectedOperationId,
  changeProductName,
  isCustomProductNameChecked,
  isFormDirty,
}) => {
  const changeProductNameWhenOperationChanges = () => {
    const selectedOperation = operations.find((op) => op.id === selectedOperationId);

    if (selectedOperation && !isCustomProductNameChecked && isFormDirty) {
      changeProductName(selectedOperation.name);
    }
  };
  useEffect(changeProductNameWhenOperationChanges, [selectedOperationId]);

  const onReady = () => triggerSubmit('product');

  const omitNullAndUndefinedValues = (output) => {
    return omitBy(output, (value) => isNull(value) || isUndefined(value));
  };

  const getDataToBeSubmitted = (formValues) => {
    let output = {
      name: formValues.name,
      coreOperationId: formValues.operation,
      category: formValues.category,
    };

    if (!isNew) {
      output.productId = product.id;
    }

    output = omitNullAndUndefinedValues(output);

    return output;
  };

  const handleSubmit = (formValues) => {
    const output = getDataToBeSubmitted(formValues);

    onSubmit(output);
  };

  const submitDisabled = checkErrors(errors);

  const initialFormValues = !isNew && {
    name: get(product, 'name'),
    operation: get(operation, 'id'),
    category: product ? toEnum(ProductCategory, product.category) : null,
  };

  return (
    <ModalDialog
      title={title}
      onReady={onReady}
      isOpen={isOpen}
      close={hide}
      disabled={submitDisabled}
    >
      <ProductForm
        initialValues={initialFormValues || {}}
        onSubmit={handleSubmit}
        operations={operations}
        isCustomProductNameChecked={isCustomProductNameChecked}
      />
    </ModalDialog>
  );
};

UpsertProductDialog.propTypes = {
  triggerSubmit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeProductName: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isCustomProductNameChecked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isFormDirty: PropTypes.bool,
  selectedOperationId: PropTypes.number,
  operations: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({}),
  operation: PropTypes.shape({}),
  product: PropTypes.shape({}),
};

const getErrors = getFormSyncErrors('product');

const formSelector = formValueSelector('product');

const mapStateToProps = (state) => {
  return {
    isFormDirty: isDirty('product')(state),
    isCustomProductNameChecked: formSelector(state, 'isCustomProductNameChecked'),
    selectedOperationId: formSelector(state, 'operation'),
    productName: formSelector(state, 'name'),
    errors: getErrors(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const changeProductName = (newName) => {
    return change('product', 'name', newName);
  };

  return bindActionCreators(
    {
      ...actions,
      triggerSubmit: submit,
      hide: hideDialog,
      changeProductName,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertProductDialog);
