import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchHourlyWashCounts } from '../actions';
import HourlyWashCountsDataGetter from '../components/HourlyWashCountsDataGetter';
import { selectFromDate, selectHourlyWashCounts, selectUntilDate } from '../reducer';

export const HourlyCounts = (props) => {
  return <HourlyWashCountsDataGetter {...props} />;
};

const mapStateToProps = (state) => {
  return {
    hourlyWashCounts: selectHourlyWashCounts(state),
    fromDate: selectFromDate(state).format('YYYY-MM-DD'),
    untilDate: selectUntilDate(state).format('YYYY-MM-DD'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchHourlyWashCounts,
    },
    dispatch
  );
};

const enhance = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps));

export default enhance(HourlyCounts);
