import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generateUniqId } from '../../utils';
import FocusSelect from './FocusSelect';
import FocusWindow from './FocusWindow';
import useMenuHistory from './hooks/useMenuHistory';
import { MenuProvider } from './menuContext';

export default function FocusMenu(props) {
  const [inFocus, setInFocus] = useState(null);
  const [dataDraft, setDataDraft] = useState(_cloneDeep(props.data));
  const [parentOptions, setParentOptions] = useState(_map(dataDraft, props.parentLabelKey));
  const dataWithUniqId = useRef([]);
  const { onSubmit } = props;

  useEffect(() => {
    const newDraft = _cloneDeep(props.data).map((data) => {
      const editData = data;
      editData._uniqId = generateUniqId();
      editData[props.childKey] = editData[props.childKey].map((option) => {
        const newOption = option;
        newOption._uniqId = generateUniqId();
        return newOption;
      });
      return editData;
    });
    dataWithUniqId.current = newDraft;
    setDataDraft(_cloneDeep(newDraft));
  }, [props.data]);

  useEffect(() => {
    setParentOptions((pOptions) => {
      const newOptions = _map(dataDraft, props.parentLabelKey);
      if (!_isEqual(pOptions, newOptions)) {
        return newOptions;
      }

      return pOptions;
    });
  }, [dataDraft, props.parentLabelKey]);

  const {
    logAddParent,
    logRemoveParent,
    logEditChild,
    logAddChild,
    logRemoveChild,
    outputLog,
    clearLog,
    undo,
  } = useMenuHistory(
    dataWithUniqId.current,
    props.parentLabelKey,
    props.childKey,
    props.childLabelKey,
    setDataDraft
  );

  const title = useMemo(() => {
    return inFocus !== null && dataDraft[inFocus] ? dataDraft[inFocus][props.parentLabelKey] : null;
  }, [inFocus, dataDraft]);

  const handleChange = useCallback(
    (edit, id, uniqId) => {
      const draftCopy = Object.assign([], dataDraft);
      if (!edit && id !== null) {
        logRemoveParent({ change: dataDraft[id] }, uniqId);
        draftCopy.splice(id, 1);
        setInFocus(null);
        setDataDraft(draftCopy);
        setParentOptions(_map(draftCopy, props.parentLabelKey));
        return;
      }
      const change = { change: edit };
      const changeId = `${dataDraft[inFocus]._uniqId}:${uniqId}`;

      if (inFocus !== null) {
        if (draftCopy[inFocus][props.childKey].length > edit[props.childKey].length) {
          logRemoveChild(change, changeId);
        }

        if (draftCopy[inFocus][props.childKey].length === edit[props.childKey].length) {
          logEditChild(change, changeId);
        }

        if (draftCopy[inFocus][props.childKey].length < edit[props.childKey].length) {
          logAddChild(change, changeId);
        }

        draftCopy[inFocus] = edit;
        setDataDraft(draftCopy);
      }
    },
    [dataDraft, inFocus]
  );

  const handleAddParent = (name) => {
    setDataDraft((draft) => {
      const newEntry = {
        _uniqId: generateUniqId(),
        [props.parentLabelKey]: name,
        [props.childKey]: [],
      };
      const newDraft = [...draft];
      logAddParent({ change: newEntry }, newEntry._uniqId);
      newDraft.unshift(newEntry);
      setParentOptions(_map(newDraft, props.parentLabelKey));
      return newDraft;
    });
  };

  const handleSubmit = () => {
    const output = dataDraft.map((entry) => {
      const cleanOpt = entry[props.childKey].map((options) => {
        // eslint-disable-next-line
        const { _uniqId, ...cleanedOptions } = options;
        return cleanedOptions;
      });

      // eslint-disable-next-line
      const { _uniqId, ...cleanedEntry } = entry;
      cleanedEntry[props.childKey] = cleanOpt;

      return cleanedEntry;
    });
    clearLog();

    onSubmit(output);
  };

  const handleUndo = useCallback(
    (id, type) => {
      const newDraft = undo(id, type);
      setDataDraft(newDraft);
    },
    [undo]
  );

  return (
    <div className="fm-container">
      <MenuProvider>
        <div className="fm-content">
          <FocusSelect
            options={parentOptions}
            onClick={setInFocus}
            onAdd={handleAddParent}
            labelKey={props.parentLabelKey}
            onChange={handleChange}
          />
        </div>
        <div className="fm-windowWrapper">
          <FocusWindow
            content={dataDraft[inFocus]}
            title={title}
            contentKey={props.childKey}
            labelKey={props.childLabelKey}
            log={outputLog}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onUndo={handleUndo}
          />
        </div>
      </MenuProvider>
    </div>
  );
}

FocusMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  parentLabelKey: PropTypes.string.isRequired,
  childKey: PropTypes.string.isRequired,
  childLabelKey: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};
