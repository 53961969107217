import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { generateUniqId } from '../../utils';
import DataTable from '../DataTable';
import TableActionButtonsCell from '../TableActionButtonsCell';
import TableEditCell from '../TableEditCell';
import AddBar from './AddBar';
import ChangeLog from './ChangeLog';
import { MenuContext } from './menuContext';

const FocusWindow = (props) => {
  const { setHasChanged, setRowInEdit, rowInEdit, hasChanged } = useContext(MenuContext);
  const { labelKey, onSubmit } = props;
  const prepairData = () => {
    const data = props.content[props.contentKey]
      .filter((entry) => entry)
      .map((entry, i) => ({
        _id: i,
        [`_${props.contentKey}`]: entry[labelKey],
      }));

    return data;
  };

  useEffect(() => {
    if (!props.log.length) {
      setHasChanged(false);
    }
  }, [props.log]);

  const handleDelete = useCallback(
    (index) => {
      const removedChildUniqId = props.content[props.contentKey][index]._uniqId;
      const newContent = {
        ...props.content,
        [props.contentKey]: [...props.content[props.contentKey]],
      };
      newContent[props.contentKey].splice(index, 1);
      props.onChange(newContent, index, removedChildUniqId);
      setHasChanged(true);
      setRowInEdit(null);
    },
    [props.contentKey, props.content, props.onChange]
  );

  const handleInputApply = (e, rowData, update) => {
    const newContent = {
      ...props.content,
      [props.contentKey]: [...props.content[props.contentKey]],
    };
    newContent[props.contentKey][rowData._id][labelKey] = update;
    props.onChange(newContent, rowData._id, newContent[props.contentKey][rowData._id]._uniqId);
    setRowInEdit(null);
    setHasChanged(true);
  };

  const handleApply = () => {
    onSubmit();
    setHasChanged(false);
  };

  const handleAddChild = (value) => {
    const newContent = {
      ...props.content,
      [props.contentKey]: [...props.content[props.contentKey]],
    };
    const newId = generateUniqId();
    newContent[props.contentKey].push({
      [labelKey]: value,
      _uniqId: newId,
    });
    props.onChange(newContent, null, newId);
    setHasChanged(true);
  };

  const header = useMemo(
    () => [
      {
        name: `${props.contentKey}`.charAt(0).toUpperCase() + props.contentKey.slice(1),
        cell: {
          component: TableEditCell,
          mixIns: {
            onApply: handleInputApply,
            inEdit: rowInEdit,
            idField: '_id',
            valueField: `_${props.contentKey}`,
            onCancel: () => setRowInEdit(null),
          },
        },
      },
      {
        name: props.intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-edit', buttonAction: (e, rowData) => setRowInEdit(rowData._id) },
              { icon: 'icon-trash', buttonAction: (e, rowData) => handleDelete(rowData._id) },
            ],
          },
        },
      },
    ],
    [rowInEdit, handleDelete, props.contentKey, props.content]
  );

  return (
    <div>
      <div>
        <div className="fm-float-left">
          <ChangeLog changes={props.log} onUndo={props.onUndo} />
        </div>
        <button
          className="button primary fm-focusWindow-apply"
          onClick={handleApply}
          disabled={!hasChanged}
        >
          {props.intl.formatMessage({ id: 'apply' })}
        </button>
      </div>
      {props.content && (
        <div>
          <div className="fm-display-flex">
            <span className="fm-margin-auto fm-inline-block">
              <h4 className="fm-inline-block">{props.title}</h4>
            </span>
          </div>
          <hr className="fm-focusWindow-divider" />
          <div className="fm-focusWindow-container">
            <div className="fm-focusWindow-content">
              <h5>{props.intl.formatMessage({ id: 'addNewOption' })}</h5>
              <div className="fm-focusWindow-grayBox">
                <AddBar onAdd={handleAddChild} />
              </div>
            </div>
            <DataTable data={prepairData()} headers={header} scrolling />
          </div>
        </div>
      )}
    </div>
  );
};

FocusWindow.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
    _uniqId: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        _uniqId: PropTypes.string,
      })
    ),
  }),
  contentKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  log: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onUndo: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

const enhance = compose(injectIntl);

export default enhance(FocusWindow);
