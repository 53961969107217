import { Input, Label } from 'reactstrap';
import '../../../../styles/include-archived-checkbox.scss';
import { IntlType } from '../../../../utils/typing';

interface Props {
  intl: IntlType;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
}

const IncludeArchivedCheckbox = ({ intl, handleChange }: Props): JSX.Element => {
  return (
    <>
      <Input
        id="includeArchived"
        type="checkbox"
        className="dr-styled-checkbox"
        onChange={handleChange}
      />
      <Label for="includeArchived" className="checkbox-mark" />
      <Label className="ml-2 align-self-center">
        {intl.formatMessage({ id: 'includeArchived' })}
      </Label>
    </>
  );
};

export default IncludeArchivedCheckbox;
