import { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, bindActionCreators } from 'redux';
import { hideDialog } from '../../../core/actions';
import Loader from '../../../core/components/Loader';
import TaskCompletionModal from './TaskCompletionModal';
import CompleteTaskList from './TaskList';
import { Collapse } from 'reactstrap';
import CustomSelectInput from '../../../core/components/CustomSelectInput';

class TaskCompletionDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    completeTask: PropTypes.func.isRequired,
  };

  state = {
    taskListToggle: true,
    selectedTeamMember: null,
  };

  handleToggle = () => {
    this.setState({ taskListToggle: !this.state.taskListToggle });
  };

  handleTeamMemberChange = (e) => {
    this.setState({ selectedTeamMember: e });
  };

  render() {
    return (
      <TaskCompletionModal
        intl={this.props.intl}
        className="enhanced-dialog medium padded"
        title={this.props.title}
        completeTask={this.props.completeTask}
        teamMember={this.state.selectedTeamMember}
        isOpen={this.props.isOpen}
        close={this.props.closeDialog}
        selectedTasks={this.props.selectedTasks}
        showFooter={true}
      >
        <>
          {this.props.title === 'Complete' ? (
            <span>
              <h2>{this.props.intl.formatMessage({ id: 'confirmTaskCompletion' })}</h2>
              <p>{this.props.intl.formatMessage({ id: 'confirmTaskCompletionDescription' })}</p>
            </span>
          ) : (
            <span>
              <h2>{this.props.intl.formatMessage({ id: 'confirmTaskSnooze' })}</h2>
              <p>{this.props.intl.formatMessage({ id: 'confirmTaskSnoozeDescription' })}</p>
            </span>
          )}
          <Suspense fallback={Loader}>
            <div>
              <h3>{this.props.intl.formatMessage({ id: 'teamMembers' })}:</h3>
              <CustomSelectInput
                options={_.map(this.props.employees, (employee) => ({
                  value: employee.id,
                  label: `${employee.firstName} ${employee.lastName}`,
                }))}
                value={this.state.selectedTeamMember}
                onChange={(e) => this.handleTeamMemberChange(e)}
                multi={false}
              />
            </div>
            <div onClick={() => this.handleToggle()}>
              <h3>
                {this.state.taskListToggle ? (
                  <i className="icon icon-chevron-down" />
                ) : (
                  <i className="icon icon-chevron-right" />
                )}
              </h3>
            </div>
            <Collapse isOpen={this.state.taskListToggle}>
              <CompleteTaskList selectedTasks={this.props.selectedTasks} intl={this.props.intl} />
            </Collapse>
          </Suspense>
        </>
      </TaskCompletionModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeDialog: hideDialog }, dispatch);
};

const enhance = compose(injectIntl, connect(null, mapDispatchToProps));

export default enhance(TaskCompletionDialog);
