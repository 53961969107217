import * as api from '../api';

export const fetchSites = (args) => {
  return api.callGet('/manager/sites', args);
};

export const getDefaultSite = (args) => {
  return fetchSites(args)
    .then((res) => {
      return res.results.length
        ? res.results
        : Promise.reject({ error: { message: 'Default site not found.' } });
    })
    .then((sites) => {
      return sites[0].managerId;
    });
};

export const patchSite = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}`, args);
};

export const fetchSite = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}`, args);
};

export const fetchSiteOperations = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/sites/${siteId}/operations`, args);
};
