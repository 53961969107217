import Shift from './models';

const initialState = {
  shifts: [],
  shiftInEdit: {},
};

const fetchShiftsSuccess = (state, response) => {
  const shifts = response.results.map((s) => new Shift(s));
  return {
    ...state,
    shifts,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const getShiftSuccess = (state, shift) => {
  return {
    ...state,
    shiftInEdit: new Shift(shift),
  };
};

export const shifts = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SHIFTS_SUCCESS':
      return fetchShiftsSuccess(state, action.response);
    case 'GET_SHIFT_SUCCESS':
      return getShiftSuccess(state, action.response);
    default:
      return state;
  }
};

export const selectShifts = (state) => {
  return state.shifts.shifts;
};

export const selectShiftInEdit = (state) => {
  return state.shifts.shiftInEdit;
};

export const selectTotal = (state) => {
  return state.shifts.total;
};

export const selectPage = (state) => {
  return state.shifts.page;
};

export const selectPageSize = (state) => {
  return state.shifts.pageSize;
};
