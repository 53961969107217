import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useAppContext } from '../../providers/AppProvider';

const DateTimePicker = (props) => {
  const {
    input: { value },
    placeholderText,
    showToday,
    required,
    className,
    timeProps,
    disabled,
  } = props;
  const tz = sessionStorage.getItem('timeZone')
    ? sessionStorage.getItem('timeZone')
    : useAppContext().timeZone;

  const today = showToday ? 'Today' : null;
  const selected = value ? moment.tz(value, tz) : null;
  const classNames = `form-control ${className || ''}`;

  const onChange = (date) => {
    if (date) {
      const formattedDate = timeProps
        ? moment(date).format('YYYY-MM-DD HH:mm')
        : moment(date).format('YYYY-MM-DD');
      const dateInSiteTimeZone = moment.tz(formattedDate, tz);
      props.input.onChange(dateInSiteTimeZone);
    } else {
      props.input.onChange(date);
    }
  };

  const getSelected = () => (value ? moment.tz(value, tz).format('YYYY-MM-DDTHH:mm') : null);
  return (
    <DatePicker
      className={classNames}
      placeholderText={placeholderText}
      todayButton={today}
      selected={selected && moment(getSelected()).toDate()}
      onChange={(date) => onChange(date)}
      required={required}
      disabled={disabled}
      fixedHeight
      {...timeProps}
    />
  );
};

DateTimePicker.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any, onChange: PropTypes.func }).isRequired,
  timeProps: PropTypes.shape({}),
  placeholderText: PropTypes.string,
  showToday: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DateTimePicker;
