import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import { hasRole, RoleTypes } from '../../../core/components/Permission';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { toString } from '../../../core/utils';
import { selectRole } from '../../../store/authReducer';
import { fetchInventory } from '../../inventory/actions';
import { InventoryType } from '../../inventory/models';
import { selectInventory } from '../../inventory/reducer';
import { fetchProducts } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { deleteDetergent, getDetergent } from '../actions';
import { selectDetergents, selectPage, selectPageSize, selectTotal } from '../reducer';

export const DetergentTableContainer = ({ ...props }) => {
  useEffect(() => {
    props.fetchProducts();
    props.fetchInventory({
      inventoryType: InventoryType.Detergent,
      includeAll: true,
    });
  }, [props.primarySite, props.multiSites]);
  const getPageActions = () => {
    const actions = [
      {
        dialogType: 'ADD_DETERGENT_READING',
        text: props.intl.formatMessage({ id: 'addReading' }),
        classes: 'mr-2',
        permissionKey: addPermissionRefs.detergent,
      },
    ];
    if (hasRole(props.role, RoleTypes.OperatorAdmin)) {
      actions.push({
        dialogType: 'ADD_DETERGENT',
        text: props.intl.formatMessage({ id: 'addDetergent' }),
        dialogProps: {
          products: props.products,
          inventory: props.inventory,
        },
      });
    }

    return actions;
  };

  const editDetergent = (event, { _id }) => {
    props.getDetergent(_id);
    props.showDialog('EDIT_DETERGENT', {
      products: props.products,
      inventory: props.inventory,
    });
  };

  const removeDetergent = (event, { _id, name }) => {
    const confirmProps = {
      onReady: props.deleteDetergent.bind(null, _id),
      text: props.intl.formatMessage({ id: 'deleteDetergentConfirmation' }, { name }),
      title: props.intl.formatMessage({ id: 'deleteDetergent' }),
    };
    props.showDialog('CONFIRM_DIALOG', confirmProps);
  };
  const getDetergentsPage = (page) => {
    props.fetchInventory({ query: { page } });
  };

  const initializeDatatable = () => {
    const roundData = (readings) => {
      return readings ? Math.round(readings) : null;
    };

    const data = props.detergents.map((detergent) => ({
      _id: detergent.id,
      name: detergent.name,
      capacity: roundData(detergent.capacity),
      latestReading: detergent.latestReading && roundData(detergent.latestReading.value),
      alarmLevel: roundData(detergent.alarmLevel),
      lastReadDate:
        detergent.latestReading &&
        moment(detergent.latestReading.timestamp).format('MM/DD/YYYY h:mm:ss a'),
    }));

    const headers = [
      { name: props.intl.formatMessage({ id: 'name' }) },
      { name: props.intl.formatMessage({ id: 'capacity' }) },
      { name: props.intl.formatMessage({ id: 'currentLevel' }) },
      { name: props.intl.formatMessage({ id: 'alarmLevel' }) },
      { name: props.intl.formatMessage({ id: 'lastRead' }) },
      {
        name: props.intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-trash', buttonAction: removeDetergent },
              { icon: 'icon-edit', buttonAction: editDetergent },
            ],
          },
        },
      },
    ];

    const paginationInfo = {
      ...props.pageInfo,
      goToPage: getDetergentsPage,
    };

    return {
      data,
      headers,
      paginationInfo,
      scrolling: true,
    };
  };

  return (
    <React.Fragment>
      <PageActions actions={getPageActions()} actionFunction={props.showDialog} />
      <DataTable {...initializeDatatable()} />
    </React.Fragment>
  );
};

DetergentTableContainer.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventory: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageInfo: PropTypes.shape({}).isRequired,
  showDialog: PropTypes.func.isRequired,
  fetchInventory: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  getDetergent: PropTypes.func.isRequired,
  deleteDetergent: PropTypes.func.isRequired,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  detergents: selectDetergents(state),
  products: selectProducts(state),
  inventory: selectInventory(state),
  role: selectRole(state),
  primarySite: selectSiteExtId(state),
  multiSites: selectMultiSiteSelection(state),
  pageInfo: {
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDialog,
      getDetergent,
      deleteDetergent,
      fetchInventory,
      fetchProducts,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(DetergentTableContainer);
