import { arrayOf, bool, func, instanceOf, number, PropTypes, shape, string } from 'prop-types';
import { FleetPayAsYouGoDiscount } from './models';

const sharedPropTypes = {
  intl: PropTypes.shape({}).isRequired,
  showDialog: func.isRequired,
  setFromDate: func.isRequired,
  setUntilDate: func.isRequired,
  adjustments: arrayOf(shape({})),
  loaders: shape({}),
  history: shape({ push: func.isRequired }).isRequired,
  match: shape({
    params: shape({ site: string }).isRequired,
  }).isRequired,
  fromDate: shape({ isSame: func, format: func }),
  untilDate: shape({ format: func }),
};

export const WashCountsPropTypes = {
  ...sharedPropTypes,
  adjustWashCount: func.isRequired,
  fetchCoreCounts: func.isRequired,
  fetchCorePayAsYouGoCounts: func.isRequired,
  fetchCoreDiscounts: func.isRequired,
  fetchCorePayAsYouGoDiscounts: func.isRequired,
  fetchCoreFleetPayAsYouGoDiscounts: func.isRequired,
  fetchProducts: func.isRequired,
  addWashCounts: func.isRequired,
  combinedCounts: shape({
    summary: arrayOf(shape({})),
  }).isRequired,
  showingCoreCountsDate: shape({ fromDate: string, untilDate: string }),
  discounts: arrayOf(shape({})),
  payAsYouGoDiscounts: arrayOf(shape({})),
  fleetPayAsYouGoDiscounts: arrayOf(instanceOf(FleetPayAsYouGoDiscount)),
  washCounts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  extId: number,
  tabType: string,
};

export const POSCountAdjustmentsPropTypes = {
  ...sharedPropTypes,
  loaders: shape({
    areAdjustmentsLoading: bool,
  }),
  fetchAdjustments: func.isRequired,
  paginationInfo: shape({}),
  extId: number,
};

export const AdjustPOSCountDialogPropTypes = {
  intl: PropTypes.shape({}).isRequired,
  onSubmit: func.isRequired,
  closeDialog: func.isRequired,
  triggerSubmit: func.isRequired,
  date: shape({ format: func.isRequired }),
  errors: shape({}),
  isOpen: bool.isRequired,
};
