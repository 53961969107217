import * as api from '../api';

// TODO: rename to getTasks
export const fetchTasks = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/tasks`, args);
};

export const patchTask = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/tasks/${id}`, args);
};

export const postTask = (args) => {
  const { siteId } = args.options;
  return api.callPost(`/manager/sites/${siteId}/tasks`, args);
};

export const postTaskNote = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/tasks/${id}/notes`, args);
};

export const getTask = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/tasks/${id}`, args);
};

export const archiveTask = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/tasks/${id}/archive`, {
    options: args.options,
  });
};
export const unLinkTask = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/tasks/${id}/unlink`, args);
};
