import { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, bindActionCreators } from 'redux';
import { hideDialog } from '../../../core/actions';
import TaskUnlinkModal from './TaskUnlinkModal';
import TaskLink from './TaskLink';
import Loader from '../../../core/components/Loader';
import { map } from 'lodash';
import { Badge } from 'reactstrap';

class UnlinkTaskDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    unLinkTask: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const title = 'Confirm Task Link Removal';
    return (
      <TaskUnlinkModal
        intl={this.props.intl}
        className="enhanced-dialog low padded"
        title={title}
        unLinkTask={this.props.unLinkTask}
        linkedTask={this.props.linkedTask}
        isOpen={this.props.isOpen}
        close={this.props.closeDialog}
        showFooter={true}
      >
        <>
          <h1>
            {' '}
            {this.props.intl.formatMessage(
              { id: 'taskUnlinkConfirm' },
              { name: this.props.task.name }
            )}
          </h1>
          <p>{this.props.intl.formatMessage({ id: 'taskUnlinkDescription' })}</p>
        </>
      </TaskUnlinkModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeDialog: hideDialog }, dispatch);
};

const enhance = compose(injectIntl, connect(null, mapDispatchToProps));

export default enhance(UnlinkTaskDialog);
