import * as api from '../api';

export const getMakes = (args) => {
  return api.callGet('/car-query/makes/', args);
};

export const getModels = (args) => {
  const {
    data: { makeId },
  } = args;
  return api.callGet(`/car-query/makes/${makeId}/models`, args);
};
