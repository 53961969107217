import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import _, { find, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import baseChart from '../../../core/components/BaseChart';
import DataTable from '../../../core/components/DataTable';
import Loader from '../../../core/components/Loader';
import { getApiStatus, randomHexColorGenerator } from '../../../core/utils';
import { formatAmount } from '../../../utils/money-util';
import { selectSiteExtId } from '../../settings/reducer';
import {
  selectMultiSiteNames,
  selectMultiSiteSelectionCoreIds,
} from '../../site-selection/reducer';
import RevenueCount from './RevenueCount';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

interface Props {
  bestDayChartData: {
    x: string;
    y: string;
  }[];
  chartData: RevenueChartData;
  RevenueBestDay: any[];
  fromDate: string;
  untilDate: string;
  siteId: string;
  currency: string;
}

export type RevenueChartData = { [siteId: number]: { x: string; y: string }[] };

export const RevenueComparisonKpi = (props: Props): JSX.Element => {
  const [chartInfo, setChartInfo] = useState({ data: { datasets: [], total: '0' }, options: {} });
  const [chartTotal, setChartTotal] = useState('0');
  const [chartView, setChartView] = useState(true);
  const barRef = useRef<any>();
  const primarySiteId = useSelector(selectSiteExtId);
  const multiSiteIds = useSelector(selectMultiSiteSelectionCoreIds);
  const fetchRevenueData = useSelector((state) => getApiStatus(state, 'FETCH_REVENUE_SUMMARY'));
  const siteNames = useSelector(selectMultiSiteNames);
  const intl = useIntl();
  const fromDate = moment(props.fromDate);
  const untilDate = moment(props.untilDate);
  const currentDate = moment();
  const sameDay: boolean = fromDate.isSame(untilDate, 'date');
  const bestDayData = _.cloneDeep(props.bestDayChartData);
  const revenueData: RevenueChartData = _.cloneDeep(props.chartData);
  const bestDay: string = props.RevenueBestDay?.toString() || '0';
  const todayDate = fromDate.toISOString().split('T')[0];
  const newBestDayData = map(bestDayData, (revenue: { x: string; y: string }) => ({
    x: revenue.x.replace(revenue.x.split('T')[0], todayDate),
    y: revenue.y,
  }));
  const handleRevenueChartEndPoint = () => {
    if (Object.keys(props.chartData).length && revenueData[primarySiteId]) {
      const newEndpoint = {
        x:
          sameDay && fromDate.isSame(currentDate, 'date')
            ? currentDate.add(1, 'hours').toISOString()
            : untilDate.toISOString(),
        y: revenueData[primarySiteId][revenueData[primarySiteId].length - 1]?.y,
      };
      revenueData[primarySiteId].push(newEndpoint);
      setChartTotal(newEndpoint.y?.toString());
    }
  };

  const createDataSets = () => {
    let dataset = [];
    const baseDataSet = {
      borderDash: [10, 5],
      pointStyle: ['circle'],
      pointRadius: function (context: { dataIndex: number; dataset: { data: string | any[] } }) {
        if (context.dataIndex == context.dataset.data.length - 1) {
          return 8;
        } else {
          return 0;
        }
      },
    };
    const bestDayDataset = {
      label: intl.formatMessage({ id: 'bestDay' }) + props.siteId,
      data: newBestDayData,
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: 'rgb(0,214,99)',
      baseDataSet,
    };
    if (multiSiteIds.length) {
      const allSiteIds = [primarySiteId, ...multiSiteIds];
      dataset = map(allSiteIds, (id) => {
        return {
          label: getSiteName(id),
          data: revenueData[id],
          backgroundColor: randomHexColorGenerator(),
          borderColor: randomHexColorGenerator(),
          baseDataSet,
        };
      });

      dataset.push(bestDayDataset);
      return dataset;
    }
    dataset = [
      {
        label: props.siteId,
        data: revenueData[primarySiteId],
        backgroundColor: 'rgba(255,0,0,0)',
        borderColor: 'rgb(190, 33, 38)',
        baseDataSet,
      },
    ];

    dataset.push(bestDayDataset);
    return dataset;
  };

  const createChartData = () => {
    const options: any = {
      responsive: true,
      borderSkipped: false,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            beginAtZero: true,
          },
          type: 'time',
          min: fromDate.set('hour', 7).toISOString(),
          max:
            sameDay && fromDate.isSame(currentDate, 'date')
              ? currentDate.add(1, 'hours').toISOString()
              : untilDate.add(1, 'hours').toISOString(),
          time: {
            unit: sameDay ? 'hour' : 'day',
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        title: {
          position: 'top',
          align: 'start',
          display: false,
          font: {
            size: 30,
          },
        },
        legend: {
          usePointStyle: 'true',
          pointStyle: 'circle',
          position: 'bottom',
          labels: {
            usePointStyle: 'true',
            pointStyle: 'circle',
            textAlign: 'center',
            boxWidth: 6,
            display: true,
          },
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: bestDay,
              yMax: bestDay,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
            },
          },
        },
      },
    };
    const data: any = {
      total: chartTotal,
      type: 'line',
      datasets: createDataSets(),
    };

    setChartInfo({
      data,
      options,
    });
  };

  const getSiteName = (id: number) => {
    return find(siteNames, (site) => site.id === id)?.name || id;
  };

  const subRowInfo = () =>
    multiSiteIds.length
      ? {
          identifier: 'name',
          displayProperty: 'name',
          subRows: getSubRows(),
        }
      : {
          identifier: 'name',
          displayProperty: 'name',
          subRows: [],
        };

  const getSubRows = () => {
    return _.map([primarySiteId, ...multiSiteIds], (id) => ({
      parentRow: getSiteName(id),
      rows: {
        data: singleSiteDataAndSubRows(id),
        headers: [
          { name: intl.formatMessage({ id: 'name' }) },
          { name: intl.formatMessage({ id: 'time' }) },
          { name: intl.formatMessage({ id: 'totalRevenue' }) },
        ],
        options: {
          styleHeader: {
            backgroundColor: '#e6e6ea',
          },
        },
      },
    }));
  };

  const multiSiteDataTable = (extId: number) => {
    if (!revenueData[extId]) {
      return {
        name: 'Unknown',
        time: 'unknown',
        totalRevenue: 'unknown',
      };
    }
    return {
      name: getSiteName(extId),
      time: sameDay
        ? moment(revenueData[extId][revenueData[extId].length - 1]?.x).format('dddd, h:mm:ss a')
        : moment(revenueData[extId][revenueData[extId].length - 1]?.x).format('dddd, MMMM Do YYYY'),
      totalRevenue: formatAmount(revenueData[extId][revenueData[extId].length - 1]?.y, 'usd'),
    };
  };

  const singleSiteDataAndSubRows = (extId: number) => {
    if (!revenueData[extId]) {
      return [
        {
          name: 'Unknown',
          time: 'unknown',
          totalRevenue: 'unknown',
        },
      ];
    }
    return _.map(revenueData[extId], (revObj) => ({
      name: getSiteName(extId),
      x: sameDay
        ? moment(revObj.x).format('dddd, h:mm:ss a')
        : moment(revObj.x).format('dddd, MMMM Do YYYY'),
      y: formatAmount(revObj.y, 'usd', true),
    }));
  };

  const initializeDataTable = (isSubRow?: boolean) => {
    const headers = [
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'time' }) },
      { name: intl.formatMessage({ id: 'totalRevenue' }) },
    ];
    let data: any[] = [];
    if (multiSiteIds.length || isSubRow) {
      const allSiteIds = [primarySiteId, ...multiSiteIds];
      data = map(allSiteIds, (extId: number) => {
        return multiSiteDataTable(extId);
      });

      return {
        data,
        headers,
        options: {
          styleHeader: {
            backgroundColor: '#e6e6ea',
          },
        },
        scrolling: true,
      };
    }

    data = singleSiteDataAndSubRows(primarySiteId);
    return {
      data,
      headers,
      scrolling: true,
    };
  };

  useEffect(() => {
    if (revenueData && primarySiteId && revenueData[primarySiteId]) {
      createChartData();
      handleRevenueChartEndPoint();
    }
  }, [props.chartData]);
  return (
    <div>
      <section className="container-fluid overview-chart-card">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'revenueComparison' })}</div>
          <button className="button small" onClick={() => setChartView(!chartView)}>
            {chartView
              ? intl.formatMessage({ id: 'tableView' })
              : intl.formatMessage({ id: 'chartView' })}
          </button>
        </div>
        {!fetchRevenueData && Object.keys(chartInfo.data).length ? (
          <div>
            {chartView ? (
              <div>
                <div className="revenue-graph">
                  <Line ref={barRef} options={chartInfo.options} data={chartInfo.data} />
                </div>
                <div className="revenue-counter-one">
                  <RevenueCount
                    text={props.siteId + intl.formatMessage({ id: 'revenueSales' })}
                    count={chartInfo.data.total}
                    currency={props.currency}
                  />
                </div>
              </div>
            ) : (
              <div>
                <DataTable {...initializeDataTable()} subRowInfo={subRowInfo()} />
              </div>
            )}
          </div>
        ) : (
          <div className="powerbi-loading">
            <Loader />
          </div>
        )}
      </section>
    </div>
  );
};

export default baseChart(RevenueComparisonKpi);
