import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ProjectModalProps {
  intl: any;
  children: any;
  title: string;
  isOpen: boolean;
  close: any;
  onReady: any;
  onReset: any;
  className: string;
  disabled: boolean;
  showFooter: boolean;
  onReadyParams: any[];
}

const ProjectModal = ({
  intl,
  children,
  title,
  isOpen,
  close,
  onReady,
  onReset,
  className,
  disabled,
  showFooter,
  onReadyParams,
}: ProjectModalProps) => {
  const onReadyWithParams = () => {
    onReady(...onReadyParams);
  };
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter>
          <Button
            className="submit-button delete"
            color="secondary"
            onClick={onReset}
            disabled={disabled}
          >
            <i className="icon icon-trash"></i> {intl.formatMessage({ id: 'cancel' })}
          </Button>
          {onReady && !onReadyParams ? (
            <Button
              className="submit-button confirm"
              color="primary"
              onClick={onReady}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'addNewProject' })}
            </Button>
          ) : onReady && onReadyParams ? (
            <Button color="primary" onClick={onReadyWithParams} disabled={disabled}>
              {intl.formatMessage({ id: 'addNewProject' })}
            </Button>
          ) : null}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ProjectModal;
