import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { selectEmployees } from '../../employees/reducer';
import * as actions from '../actions';
import ShiftEditForm from '../components/ShiftEditForm';
import { selectShiftInEdit } from '../reducer';

const initialFormValues = {
  start: moment(),
  // Seed with a year in advance for now, adjust once backend makes optional
  end: moment().add(1, 'years'),
  weeklySchedule: moment().day().toString(),
  interval: 0, // weekly
};

class UpsertShiftDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    initialShift: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool,
    title: PropTypes.string.isRequired,
    employees: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.shape({}),
  };

  onReady = () => {
    this.props.triggerSubmit('shift');
  };

  render() {
    const { closeDialog, isOpen, initialShift, onSubmit, isNew } = this.props;
    const enableReinitialize = !isNew;
    const submitDisabled = checkErrors(this.props.errors);

    return (
      <ModalDialog
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <ShiftEditForm
          onSubmit={onSubmit}
          initialValues={initialShift}
          enableReinitialize={enableReinitialize}
          employees={this.props.employees}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('shift');

const mapStateToProps = (state, ownProps) => {
  const initialShift = ownProps.isNew ? initialFormValues : selectShiftInEdit(state);
  const errors = getErrors(state);

  return {
    initialShift,
    employees: selectEmployees(state),
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertShiftDialog);
