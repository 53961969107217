import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { getFormSyncErrors, submit, reset } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import {
  selectSiteModelYears,
  selectSiteOperationTypes,
  selectSiteTotalCarCount,
} from '../../settings/reducer';
import { selectEmployees } from '../../employees/reducer';
import ProjectEditForm from './ProjectEditForm';
import { Interval, TaskType } from '../models';
import { selectTaskInEdit, selectTaskLocations, selectTaskPrefixes } from '../../tasks/reducer';
import ProjectModal from './ProjectModal';

const initialFormValues = {
  start: moment(),
  weeklySchedule: moment().day().toString(),
  monthlySameWeekday: false,
  alertIfOverdue: true,
  type: TaskType.Maintenance,
  interval: Interval.CarCount,
  intervalCount: 1,
  hourlySchedule: [],
  carCount: 0,
};

class UpsertProjectDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    triggerReset: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    isMasterTask: PropTypes.bool,
    title: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
  };

  onReady = () => {
    this.props.triggerSubmit('project');
  };

  onReset = () => {
    this.props.triggerReset('project');
  };

  onSubmit = (project) => {
    console.log('Submitted!');
  };

  render() {
    const {
      closeDialog,
      isOpen,
      initialTask,
      isNew,
      modelYears,
      totalCarCount,
      isMasterTask,
      taskPrefixList,
      taskLocationList,
      siteOperationTypeList,
      siteModelYearList,
      employees,
    } = this.props;

    const enableReinitialize = !isNew;
    const disabled = checkErrors(this.props.errors, 'years');

    return (
      <ProjectModal
        intl={this.props.intl}
        className="enhanced-dialog"
        title={this.props.title}
        onReady={this.onReady}
        onReset={this.onReset}
        isOpen={isOpen}
        close={closeDialog}
        disabled={disabled}
        showFooter={true}
      >
        <ProjectEditForm className="project-dialog" onSubmit={this.onSubmit} />
      </ProjectModal>
    );
  }
}

const getErrors = getFormSyncErrors('project');

const mapStateToProps = (state, ownProps) => {
  const initialTask = ownProps.isNew
    ? initialFormValues
    : selectTaskInEdit(state) || ownProps.initialTask;
  const errors = getErrors(state);
  return {
    initialTask,
    errors,
    totalCarCount: selectSiteTotalCarCount(state),
    taskPrefixList: selectTaskPrefixes(state),
    taskLocationList: selectTaskLocations(state),
    siteModelYearList: selectSiteModelYears(state),
    siteOperationTypeList: selectSiteOperationTypes(state),
    employees: selectEmployees(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { closeDialog: hideDialog, triggerSubmit: submit, triggerReset: reset },
    dispatch
  );
};

const enhance = compose(injectIntl, connect(null, mapDispatchToProps));

export default enhance(UpsertProjectDialog);
