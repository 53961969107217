import { useEffect } from 'react';
import { RequestStatus } from '../../../types/request-status';

const useFormTimeout = (closeDialog: () => void, statuses: RequestStatus[]): void => {
  useEffect(() => {
    let timeoutHandler: NodeJS.Timeout;

    for (const status of statuses) {
      if (status === 'success') {
        timeoutHandler = setTimeout(() => {
          closeDialog();
        }, 2500);
      }
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, statuses);
};

export default useFormTimeout;
