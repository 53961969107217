export class SiteModelYear {
  constructor(props = {}) {
    this.id = props.id;
    this.year = props.year;
  }
}

export const counterFormType = {
  Exterior: 'washCounter',
  Interior: 'interiorWashCounter',
};

export const ManualEntryType = {
  ReadyToWash: 0,
  PushButton: 1,
};

export const ProgramEntryType = {
  LaneController: 0,
  EntranceModule: 1,
  VacuumController: 2,
  ROController: 3,
  GWCController: 4,
  IndusoftHMI: 5,
  InteriorController: 6,
};
export class SiteOperationType {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
  }
}
