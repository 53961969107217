import { useEffect, useState } from 'react';

const InventoryStockLevel = (props: any) => {
  const { rowData, mixIns } = props;
  const [value, setValue] = useState(rowData._onHand);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    mixIns.levelOnChange(rowData._id, e.target.value);
    setValue(e.target.value);
  };

  const stockPercentageColor = () => {
    if (rowData._preferredStockLevel && rowData._preferredStockLevel > 0) {
      const percentage = rowData._onHand / rowData._preferredStockLevel;
      if (percentage > 0.8 && percentage < 3) {
        return 'green';
      } else if (percentage > 0.4) {
        return 'yellow';
      } else {
        return 'red';
      }
    }
    return '';
  };

  useEffect(() => {
    if (!mixIns.isEditing) {
      setValue(rowData._onHand);
    }
  }, [mixIns.isEditing, rowData._onHand]);

  return (
    <div className="stock-input-container">
      {mixIns.isEditing ? (
        <>
          <input
            type="number"
            onChange={(e) => {
              handleChange(e);
            }}
            value={value}
            min="0"
            className="table-input"
            data-testid="inventory-stock-input"
          />
          {rowData._preferredStockLevel && (
            <span className="preferred-stock">/{rowData._preferredStockLevel}</span>
          )}
        </>
      ) : (
        <>
          <span className={`indicator ${stockPercentageColor()}`}>
            <span>{value}</span>
            {rowData._preferredStockLevel && <span> /{rowData._preferredStockLevel}</span>}
          </span>
        </>
      )}
    </div>
  );
};

export default InventoryStockLevel;
