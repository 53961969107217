import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import {
  checkCurrencyFormatting,
  fieldRequired,
  requiredInput,
} from '../../../core/formValidation';

export const PurchaseOrderItemFields = ({ fields }) => {
  return (
    <div className="inline-add-item-button">
      <button
        className={classNames('btn btn-outline-success', 'add-purchase-item')}
        onClick={() => {
          fields.push();
        }}
      >
        + Add Item
      </button>
      {fields.map((item, index) => (
        <div key={index} className="readings-form input-padding">
          <div>
            <button
              className={classNames('close', 'multi-form-delete', 'mr-3')}
              onClick={() => {
                fields.remove(index);
              }}
              style={{ marginTop: index === 0 ? '6%' : '1%' }}
            >
              <i className="icon icon-squared-cross" />
            </button>
            <div>
              <span className="mx-1">Item {index + 1}</span>
              <div className="form-group form-small-row mx-1">
                <span className="mx-1">
                  {index === 0 && (
                    <small htmlFor="name" className="form-text text-muted text-left">
                      Name
                    </small>
                  )}
                  <Field
                    className="form-control row-padding"
                    name={`${item}.name`}
                    id="name"
                    component={requiredInput}
                    type="text"
                    aria-describedby="descriptionHelp"
                    placeholder="Placeholder"
                    validate={[fieldRequired]}
                  />
                </span>
                <span className="mx-1">
                  {index === 0 && (
                    <small htmlFor="quantity" className="form-text text-muted text-left">
                      Qty
                    </small>
                  )}
                  <Field
                    className="form-control row-padding"
                    name={`${item}.quantity`}
                    id="quantity"
                    component={requiredInput}
                    type="number"
                    aria-describedby="descriptionHelp"
                    placeholder="0"
                    min="0"
                    validate={[fieldRequired]}
                    style={{ width: '65px' }}
                  />
                </span>
                <span className="mx-1">
                  {index === 0 && (
                    <small htmlFor="price" className="form-text text-muted text-left">
                      Price
                    </small>
                  )}
                  <Field
                    className="form-control row-padding"
                    name={`${item}.price`}
                    id="price"
                    component={requiredInput}
                    type="text"
                    commas="true"
                    decimal="2"
                    aria-describedby="descriptionHelp"
                    placeholder="$0.00"
                    validate={[checkCurrencyFormatting]}
                    style={{ width: '113px' }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

PurchaseOrderItemFields.propTypes = {
  fields: PropTypes.shape({}),
};

export default PurchaseOrderItemFields;
