import { useSelector } from 'react-redux';
import moment from 'moment';
import { MaintenanceTask } from '../models';
import { selectTaskLocations } from '../../tasks/reducer';
import { createIntervalDescription } from '../../tasks/helpers';
import { handleTaskStatusBadge } from '../helpers';

interface TaskDetailsProps {
  intl: any;
  task: MaintenanceTask;
  employees: any;
}

const TaskDetails = ({ intl, task, employees }: TaskDetailsProps) => {
  const taskLocationList = useSelector(selectTaskLocations);
  const location = taskLocationList.find(
    (taskLocation: any) => taskLocation.type === task.locationId
  );
  const lastCompletion = task?.latestCompletion;
  const findEmployee = (id: string) => {
    const name = employees.find((employee: any) => employee.id === id);
    return name ? name.firstName : intl.formatMessage({ id: 'notAvailable' });
  };

  return (
    <div className="task-details">
      <strong>{intl.formatMessage({ id: 'taskDetails' })}</strong>
      <p>{intl.formatMessage({ id: 'taskStatus' })}</p>
      {handleTaskStatusBadge(lastCompletion?.status, intl)}
      <p>{intl.formatMessage({ id: 'interval' })}</p>
      <strong>{createIntervalDescription(task, intl.formatMessage)}</strong>
      <p>{intl.formatMessage({ id: 'start' })}</p>
      <strong>{moment(task?.start).format('MM/DD/YYYY')}</strong>
      <p>{intl.formatMessage({ id: 'end' })}</p>
      <strong>
        {task?.end
          ? moment(task.end).format('MM/DD/YYYY')
          : intl.formatMessage({ id: 'notAvailable' })}
      </strong>
      <p>{intl.formatMessage({ id: 'taskLocation' })}</p>
      <strong>{location?.message || intl.formatMessage({ id: 'notAvailable' })}</strong>
      <p>{intl.formatMessage({ id: 'assignedTo' })}</p>
      <strong>N/A</strong>
      <p>{intl.formatMessage({ id: 'lastCompletedBy' })}</p>
      <strong>
        {lastCompletion && lastCompletion.employeeId
          ? findEmployee(lastCompletion.employeeId)
          : intl.formatMessage({ id: 'notAvailable' })}
      </strong>
      <p>{intl.formatMessage({ id: 'lastCompletedDate' })}</p>
      <strong>
        {task?.latestCompletion
          ? moment(task.latestCompletion).format('MM/DD/YYYY')
          : intl.formatMessage({ id: 'notAvailable' })}
      </strong>
    </div>
  );
};

export default TaskDetails;
