import { find } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { selectCarMakes, selectCarModels } from '../../store/carModelReducer';
import { fetchCarMakes, fetchCarModels } from '../actions';
import SuggestedTextPicker from './SuggestedTextPicker';

class CarModelSelect extends Component {
  constructor(props) {
    super(props);

    this.makeId = null;
    this.makesField = React.createRef();
  }

  componentDidMount() {
    this.props.fetchCarMakes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.makes !== this.props.makes) {
      this.selectModelsForCurrentMake();
    }
  }

  getModels = () => {
    if (this.makeId && this.props.models[this.makeId]) {
      return this.props.models[this.makeId];
    }
    return [];
  };

  selectModelsForCurrentMake() {
    const value = this.makesField.current.value;
    if (value && value !== '') {
      this.selectModelsForMake(value);
    }
  }

  selectModelsForMake = (make) => {
    const item = find(this.props.makes, { make });
    if (item) {
      this.makeId = item.makeId;
      this.props.fetchCarModels(item.makeId);
    } else {
      this.makeId = null;
    }
  };

  selectMake = (event, make) => {
    this.selectModelsForMake(make);
  };

  renderMakeSuggestion = (item) => (
    <div className="suggestion">
      <span>{item.make}</span>
    </div>
  );

  renderModelSuggestion = (item) => (
    <div className="suggestion">
      <span>{item.model}</span>
    </div>
  );

  render() {
    const makes = this.props.makes;

    return (
      <div className="row">
        <div className="form-group col">
          <Field
            name={this.props.makeProperty}
            id="make"
            onChange={this.selectMake}
            ref={this.makesField}
            component={(input) => (
              <SuggestedTextPicker
                {...input}
                items={makes}
                renderSuggestion={this.renderMakeSuggestion}
                placeholder="make"
                matchProperty="make"
              />
            )}
          />
        </div>
        <div className="form-group col">
          <Field
            name={this.props.modelProperty}
            id="model"
            component={(input) => (
              <SuggestedTextPicker
                {...input}
                itemCallback={this.getModels}
                renderSuggestion={this.renderModelSuggestion}
                placeholder="model"
                matchProperty="model"
              />
            )}
          />
        </div>
      </div>
    );
  }
}

CarModelSelect.propTypes = {
  fetchCarMakes: PropTypes.func.isRequired,
  fetchCarModels: PropTypes.func.isRequired,
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      makeId: PropTypes.string.isRequired,
    })
  ),
  models: PropTypes.shape({}),
  makeProperty: PropTypes.string.isRequired,
  modelProperty: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    makes: selectCarMakes(state),
    models: selectCarModels(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCarMakes,
      fetchCarModels,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CarModelSelect);
