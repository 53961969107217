import { PropTypes } from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store/createStore';
import hydrateStore from '../store/hydrateStore';

const initialState = hydrateStore();
const store = configureStore(initialState);

const ReduxProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

ReduxProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ReduxProvider;
