import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Select } from '../../../core/formValidation';
import { ManualEntryType } from '../models';
import { SelectProducts } from './AddressFields';

export const ManualAddressFields = ({ fields, products, handleProductChange, intl }) => {
  const addAddressField = () => {
    const { id } = products[0] || {};
    const latestField = fields.get(fields.length - 1);
    fields.push({
      address: latestField ? latestField.address + 1 : fields.length,
      productId: id,
      type: ManualEntryType.ReadyToWash,
    });
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => {
        return (
          <div className="form-inline mb-2" key={index}>
            <div className="mr-2 flex-1">
              <Field
                name={`${item}.address`}
                data-testid={`${item}.address`}
                parse={(value) => Number(value, 10)}
                component="input"
                type="number"
                className="form-control w-100"
              />
            </div>
            <div className="flex-1 mr-2">
              <Field
                name={`${item}.productId`}
                data-testid={`${item}.productId`}
                component={(input) => SelectProducts(input, products, handleProductChange, item)}
                products={products}
                handleProductChange={handleProductChange}
                parse={(value) => Number(value)}
              />
            </div>
            <div className="flex-1">
              <Field
                name={`${item}.type`}
                data-testid={`${item}.type`}
                component={Select}
                parse={(value) => Number(value)}
              >
                <option value={ManualEntryType.ReadyToWash}>
                  {intl.formatMessage({ id: 'readyToWash' })}
                </option>
                <option value={ManualEntryType.PushButton}>
                  {intl.formatMessage({ id: 'pushButton' })}
                </option>
              </Field>
            </div>
            <button
              className="btn btn-secondary ml-2"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => fields.remove(index)}
            >
              <i className="icon icon-cross" />
            </button>
          </div>
        );
      })}
      <div className="wash-counter-register-buttons-container">
        <button
          id="add-manual-address-button"
          className="btn btn-secondary"
          data-testid="add-manual-address-button"
          type="button"
          onClick={() => addAddressField(fields, products)}
        >
          {intl.formatMessage({ id: 'addManualAddress' })}
        </button>
      </div>
    </React.Fragment>
  );
};

export default ManualAddressFields;
