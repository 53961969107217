import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  fieldRequired,
  requiredDatePicker,
  requiredInput,
  requiredSelect,
} from '../../../core/formValidation';
import { toString as enumToString } from '../../../core/utils';
import { isNumber } from '../../../utils/number-util';
import { UtilityType } from '../models';

const UtilityEditForm = ({ change, utilityUnits, utilityType, intl }) => {
  const utilityTypeOptions = Object.keys(UtilityType).map((type) => (
    <option key={type} value={UtilityType[type]}>
      {intl.formatMessage({ id: type })}
    </option>
  ));

  let utilityUnitOptions;
  if (isNumber(utilityType)) {
    const currentUtilityType = enumToString(UtilityType, utilityType);
    const currentUtilityUnits = utilityUnits[currentUtilityType];

    utilityUnitOptions = Object.keys(currentUtilityUnits || {}).map((unit) => (
      <option key={unit} value={currentUtilityUnits[unit]}>
        {unit}
      </option>
    ));
  }

  const typeChangedHandler = () => {
    change('unit', '0');
  };

  return (
    <div className="form-group">
      <div className="form-group">
        <label htmlFor="type">{intl.formatMessage({ id: 'type' })}</label>
        <Field
          name="type"
          id="type"
          component={requiredSelect}
          className="form-control"
          onChange={typeChangedHandler}
          parse={(value) => Number(value)}
          validate={[fieldRequired]}
        >
          {utilityTypeOptions}
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="name">{intl.formatMessage({ id: 'description' })}</label>
        <Field
          name="name"
          id="name"
          component={requiredInput}
          type="text"
          className="form-control"
          placeholder={intl.formatMessage({ id: 'utilityNamePlaceholder' })}
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <div className="form-small-row">
          <span className="text-left form-row-item">
            <label htmlFor="start" className="mr-2">
              {intl.formatMessage({ id: 'startDate' })}
            </label>
            <Field
              name="start"
              id="start"
              component={requiredDatePicker}
              type="date"
              className="form-control"
              placeholderText={intl.formatMessage({ id: 'utilityStartDatePlaceholder' })}
              parse={(value) => value.format('YYYY-MM-DD')}
              validate={[fieldRequired]}
            />
          </span>
          <span className="text-left form-row-item">
            <label htmlFor="end" className="mr-2">
              {intl.formatMessage({ id: 'endDate' })}
            </label>
            <Field
              name="end"
              id="end"
              component={requiredDatePicker}
              type="date"
              className="form-control"
              placeholderText={intl.formatMessage({ id: 'utilityEndDatePlaceholder' })}
              parse={(value) => value.format('YYYY-MM-DD')}
              validate={[fieldRequired]}
            />
          </span>
        </div>
      </div>
      <div className="form-group">
        <div className="form-full-row">
          <span className="text-left form-row-item">
            <label htmlFor="startReading">{intl.formatMessage({ id: 'startReading' })}</label>
            <Field
              name="startReading"
              id="startReading"
              component={requiredInput}
              type="text"
              decimal="4"
              commas="true"
              className="form-control"
              placeholder={intl.formatMessage({ id: 'utilityReadingPlaceholder' })}
              validate={[fieldRequired]}
            />
          </span>
          <span className="text-left form-row-item">
            <label htmlFor="endReading">{intl.formatMessage({ id: 'endReading' })}</label>
            <Field
              name="endReading"
              id="endReading"
              component={requiredInput}
              type="text"
              decimal="4"
              commas="true"
              className="form-control"
              placeholder={intl.formatMessage({ id: 'utilityReadingPlaceholder' })}
              validate={[fieldRequired]}
            />
          </span>
        </div>
        <div className="form-full-row">
          <span className="text-left form-row-item">
            <label htmlFor="unit">{intl.formatMessage({ id: 'readingUnit' })}</label>
            <Field
              name="unit"
              id="unit"
              component={requiredSelect}
              className="form-control"
              parse={(value) => Number(value)}
              validate={[fieldRequired]}
            >
              {utilityUnitOptions}
            </Field>
          </span>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="cost">{intl.formatMessage({ id: 'amount' })}</label>
        <Field
          name="cost"
          id="cost"
          component={requiredInput}
          type="text"
          decimal="2"
          commas="true"
          className="form-control"
          placeholder={intl.formatMessage({ id: 'utilityAmountPlaceholder' })}
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="currency">{intl.formatMessage({ id: 'currency' })}</label>
        <Field
          name="currency"
          id="currency"
          component={requiredSelect}
          className="form-control"
          validate={[fieldRequired]}
        >
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </Field>
      </div>
    </div>
  );
};

UtilityEditForm.propTypes = {
  change: PropTypes.func.isRequired,
  utilityUnits: PropTypes.shape({}).isRequired,
  utilityType: PropTypes.number,
  intl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const utilityType = formValueSelector('utilities')(state, 'type');
  return {
    utilityType,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm({ form: 'utilities' })
)(UtilityEditForm);
