import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import { hideDialog } from '../../../../core/actions';
import '../../../../styles/user-dialog.scss';
import { IntlType } from '../../../../utils/typing';
import { setAddEmployeeStatus } from '../../../employees/actions';
import { selectAddEmployeeStatus } from '../../../employees/reducer';
import useFormTimeout from '../../hooks/useFormTimeout';
import UserFormSelector from '../UserFormSelector';
import CreateUserButton from '../UserFormSelector/CreateUserButton';
import useCreateForm from './hooks/useCreateForm';
import UserCreatedSuccessfully from './UserCreatedSuccessfully';

interface Props {
  intl: IntlType;
}

const CreateUserDialog = ({ intl }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const formProps = useCreateForm();
  const status = useSelector(selectAddEmployeeStatus);

  const closeDialog = () => {
    dispatch(hideDialog());
    dispatch(setAddEmployeeStatus('idle'));
  };

  useFormTimeout(closeDialog, [status]);

  const { formik, handleSubmitClick } = formProps;
  const editingDisabled = !formik.isValid || formik.isSubmitting;
  const loginCredentialsNeeded = formProps.formik.values.loginCredentialsNeeded;

  return (
    <Modal contentClassName="user-modal-content" toggle={closeDialog} size="xl" isOpen>
      <ModalHeader toggle={closeDialog}>{intl.formatMessage({ id: 'createUser' })}</ModalHeader>

      {status === 'success' ? (
        <UserCreatedSuccessfully intl={intl} isEmailSent={loginCredentialsNeeded} />
      ) : (
        <UserFormSelector
          intl={intl}
          formProps={formProps}
          submitButton={
            <CreateUserButton
              intl={intl}
              handleClick={handleSubmitClick}
              disabled={editingDisabled}
            />
          }
        />
      )}
    </Modal>
  );
};

export default injectIntl(CreateUserDialog);
