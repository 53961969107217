import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function AddBar(props) {
  const [newEntry, setNewEntry] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const { onAdd } = props;

  useEffect(() => {
    setDisableButton(() => {
      if (newEntry) {
        return false;
      }
      return true;
    });
  }, [newEntry]);

  const handleAdd = () => {
    onAdd(newEntry);
    setNewEntry('');
  };

  return (
    <div>
      <input
        className="fm-addBarInput"
        onChange={(e) => setNewEntry(e.target.value)}
        value={newEntry}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && newEntry.length) {
            handleAdd();
          }
        }}
      />
      <button
        className="btn btn-success btn-sm fm-addBarButton"
        onClick={handleAdd}
        disabled={disableButton}
      >
        <i className="icon icon-plus" />
      </button>
    </div>
  );
}

AddBar.propTypes = {
  onAdd: PropTypes.func.isRequired,
};
