import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import UploadSection from './UploadSection';

class Videos extends Component {
  static propTypes = {
    videos: PropTypes.arrayOf(PropTypes.shape({})),
    deleteFile: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="media-content" data-testid="videos">
        <UploadSection fileType="video" addFile={this.props.addFile} />
        <div className="media media-video">
          {this.props.videos &&
            this.props.videos.map((video, index) => (
              <div data-testid="video" className="media-item" key={index}>
                <div className="media-item-container">
                  <video className="media-item-video" src={video.downloadUrl} controls />
                </div>
                <div className="media-item-description">{video.description}</div>
                <div
                  className="media-item-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.deleteFile(video);
                  }}
                >
                  <i className="icon icon-trash" />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Videos;
