import { API_REQUEST } from '../../core/actions';
import * as repairItemApi from '../../core/api/repair-items';
import { getReclamation } from '../reclamations/actions';

export const ADD_REPAIR_PART = 'ADD_REPAIR_PART';
export const DELETE_REPAIR_PART = 'DELETE_REPAIR_PART';

export const addRepairItem = (data) => ({
  type: API_REQUEST,
  callAPI: repairItemApi.postRepairItem,
  data,
  action: ADD_REPAIR_PART,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});

export const deleteRepairItem = (data) => ({
  type: API_REQUEST,
  callAPI: repairItemApi.deleteRepairItem,
  data,
  action: DELETE_REPAIR_PART,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});
