import React from 'react';
import Employee from '../../views/employees/models';

interface Props {
  employees: Employee[];
  currentEmployeeId: number;
  setEmployee: any;
  intl?: any;
  defaultOption?: string;
}

const EmployeeDropdown = (props: Props) => {
  const { employees, currentEmployeeId, setEmployee, intl } = props;
  return (
    <select
      className="form-control"
      data-testid="employee-dropdown"
      value={currentEmployeeId ?? intl.formatMessage({ id: 'select' })}
      onChange={(e) => setEmployee(e.target.value)}
    >
      {props.defaultOption && <option value={props.defaultOption}>{props.defaultOption}</option>}
      {employees.length &&
        employees.map((employee: Employee) => (
          <option key={employee.id} value={employee.id}>
            {`${employee.lastName}, ${employee.firstName}`}
          </option>
        ))}
    </select>
  );
};

export default EmployeeDropdown;
