import { isNil, sumBy } from 'lodash';
import numbro from 'numbro';

export const symbolForCurrency = (currency) => {
  if (isNil(currency)) {
    return '$'; // default return usd
  }
  switch (currency.toLowerCase()) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    case 'aud':
      return 'A$';
    case 'czk':
      return 'Kč'; // Czech koruna
    case 'sek':
      return 'kr';
    default: {
      // eslint-disable-next-line
      console.warn('Unknown currency:', currency);
      return currency ? currency.toUpperCase() : currency;
    }
  }
};

export const formatAmount = (amount, currency, leaveZeroBlank) => {
  if (isNil(amount) || (leaveZeroBlank && amount && parseFloat(amount) === 0)) {
    return '';
  }
  const cultureData = numbro.cultureData();
  cultureData.currency.symbol = symbolForCurrency(currency);
  const format = cultureData.formats ? cultureData.formats.fullWithTwoDecimals : '$ 0,0.00';
  return numbro(amount).format(format);
};

export const parseFloatSum = (counts = []) => sumBy(counts, (count) => parseFloat(count));
