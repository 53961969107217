import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { setStatusFilter } from '../actions';
import { ReclamationStatus } from '../models';

export const StatusFilter = ({ intl, setFilter, statusFilter }) => {
  const handleChange = ({ target: { value } }) => {
    setFilter(Number(value));
  };

  return (
    <div className="form-group">
      <label>{intl.formatMessage({ id: 'status' })}</label>
      <select className="form-control" value={statusFilter} onChange={handleChange}>
        <option value={ReclamationStatus.All}>{intl.formatMessage({ id: 'All' })}</option>
        <option disabled>------</option>
        <option value={ReclamationStatus.AllOpen}>{intl.formatMessage({ id: 'AllOpen' })}</option>
        <option value={ReclamationStatus.NeedsAttention}>
          {intl.formatMessage({ id: 'NeedsAttention' })}
        </option>
        <option value={ReclamationStatus.Investigating}>
          {intl.formatMessage({ id: 'Investigating' })}
        </option>
        <option value={ReclamationStatus.NeedsRepair}>
          {intl.formatMessage({ id: 'NeedsRepair' })}
        </option>
        <option value={ReclamationStatus.UnderRepair}>
          {intl.formatMessage({ id: 'UnderRepair' })}
        </option>
        <option disabled>------</option>
        <option value={ReclamationStatus.AllClosed}>
          {intl.formatMessage({ id: 'AllClosed' })}
        </option>
        <option value={ReclamationStatus.Repaired}>{intl.formatMessage({ id: 'Repaired' })}</option>
        <option value={ReclamationStatus.Resolved}>{intl.formatMessage({ id: 'Resolved' })}</option>
        <option value={ReclamationStatus.NotCaused}>
          {intl.formatMessage({ id: 'NotCaused' })}
        </option>
        <option value={ReclamationStatus.Denied}>{intl.formatMessage({ id: 'Denied' })}</option>
        <option value={ReclamationStatus.Completed}>
          {intl.formatMessage({ id: 'Completed' })}
        </option>
      </select>
    </div>
  );
};

StatusFilter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFilter: setStatusFilter }, dispatch);
};

export default compose(connect(null, mapDispatchToProps), injectIntl)(StatusFilter);
