import * as api from '../api';

export const uploadProfitAndLoss = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPostWithFile(`/manager/sites/${siteId}/purchase-orders/profit-and-loss`, args);
};

export const updateVendingSales = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-orders/vending-sales`, args);
};
