import _ from 'lodash';
import moment from 'moment-timezone';
import { Alert } from '../alerts/models';
import Detergent from '../detergents/models';
import { TaskCompletion } from '../tasks/models';
import { SHOW_OVERVIEW_SUMMARY_CHART } from './actions';

const from = sessionStorage.getItem('overviewWashCountsFrom');
const until = sessionStorage.getItem('overviewWashCountsUntil');
const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

export const initialState = {
  maintenanceTaskCompletions: [],
  maintenanceTaskCompletionsTotal: 0,
  projectTaskCompletions: [],
  projectTaskCompletionsTotal: 0,
  alerts: [],
  alertsTotal: 0,
  error: null,
  detergents: [],
  detergentData: [],
  myCharts: {
    washCounts: [],
    totalWindowSalesCount: 0,
    totalClubsGained: 0,
    surveyResults: null,
    costPerCar: null,
    overviewRevenue: {
      results: {},
      bestDayResults: [],
      bestDayTotal: [],
      bestDayDate: [],
      currency: 'usd',
    },
  },
  washCounts: [],
  overviewReclamations: {
    count: 0,
    countsByStatus: [],
  },
  hourlyWashCounts: {},
  closest: [],
  closestHourlyWashCounts: {},
  summaryChart: 'detergentLevels',
  activeSubscriptionCount: {},
  fromDate: from ? fromDate : moment.tz(tz).startOf('day'),
  untilDate: until ? untilDate : moment.tz(tz).endOf('day'),
};

const fetchAlertsSuccess = (state, response) => {
  const { results, total } = response;
  const alerts = results.map((alert) => new Alert(alert));
  return {
    ...state,
    alerts,
    alertsTotal: total,
  };
};

const fetchMaintenanceTaskCompletionsSuccess = (state, response) => {
  const { results, total } = response;
  const maintenanceTaskCompletions = results.map((task) => new TaskCompletion(task));
  return {
    ...state,
    maintenanceTaskCompletions,
    maintenanceTaskCompletionsTotal: total,
  };
};

const fetchProjectTaskCompletionsSuccess = (state, response) => {
  const { results, total } = response;
  const projectTaskCompletions = results.map((task) => new TaskCompletion(task));
  return {
    ...state,
    projectTaskCompletions,
    projectTaskCompletionsTotal: total,
  };
};

const showSummaryChart = (state, summaryChart) => {
  return {
    ...state,
    summaryChart,
  };
};

const getDetergentsSuccess = (state, { results }) => {
  const detergents = results.map((d) => new Detergent(d));
  const detergentData = Detergent.mapChartData(detergents);
  return {
    ...state,
    detergents,
    detergentData,
    error: null,
  };
};

const getWashCountsSuccess = (state, { results }) => {
  return {
    ...state,
    washCounts: results,
  };
};

const getMyChartsWashCountsSuccess = (state, { results }) => {
  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      washCounts: results,
    },
  };
};

const getHourlyWashCountsSuccess = (state, response) => {
  return {
    ...state,
    hourlyWashCounts: response,
  };
};
const getClosestsHourlyWashCountsSuccess = (state, response) => {
  return {
    ...state,
    closestHourlyWashCounts: response,
  };
};
const getClosestsSuccess = (state, response) => {
  return {
    ...state,
    closest: response.results,
  };
};
const getClaimCountsSuccess = (state, action) => {
  return {
    ...state,
    overviewReclamations: {
      count: action.count,
      countsByStatus: action.countsByStatus,
    },
  };
};

const fetchRevenueSummarySuccess = (state, response) => {
  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      overviewRevenue: {
        ...state.myCharts.overviewRevenue,
        results: response,
      },
    },
  };
};

const fetchRevenueBestDaySummarySuccess = (state, action) => {
  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      overviewRevenue: {
        ...state.myCharts.overviewRevenue,
        bestDayTotal: action.bestDay,
        bestDayResults: action.bestDayData,
        bestDayDate: action.bestDate,
        currency: action.currency,
      },
    },
  };
};

const fetchActiveSubscriptionSuccess = (state, response) => {
  return {
    ...state,
    activeSubscriptionCount: response,
  };
};

const getCostPerCarDataSuccess = (state, response) => {
  let costPerCar;
  if (response?.aggregate) {
    costPerCar = response.aggregate;
  } else {
    const siteId = _.keys(response)[0];
    costPerCar = response[siteId];
  }

  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      costPerCar,
    },
  };
};

const getWindowSalesSuccess = (state, response, query) => {
  let windowCounts;
  if (response?.aggregate) {
    windowCounts = _.sumBy(response.aggregate, 'count');
  } else {
    const siteId = _.keys(response)[0];
    windowCounts = response[siteId].count;
  }

  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      totalWindowSalesCount: windowCounts,
    },
  };
};

const fetchProductPlansSuccess = (state, response, query) => {
  const planCounts = response?.aggregate ? _.sum(response.aggregate) : response[query.siteId];

  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      totalClubsGained: planCounts,
    },
  };
};

const getSurveyResultsSuccess = (state, response) => {
  return {
    ...state,
    myCharts: {
      ...state.myCharts,
      surveyResults: response,
    },
  };
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('overviewWashCountsFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('overviewWashCountsUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

export const home = (state = initialState, action) => {
  const { response, meta } = action;
  const returnedValue = response?.results ? response.results : response;
  switch (action.type) {
    case 'FETCH_MAINTENANCE_TASKS_SUCCESS':
      return fetchMaintenanceTaskCompletionsSuccess(state, action.response);
    case 'FETCH_PROJECT_TASKS_SUCCESS':
      return fetchProjectTaskCompletionsSuccess(state, action.response);
    case 'OVERVIEW_GET_DETERGENTS_SUCCESS':
      return getDetergentsSuccess(state, action.response);
    case 'FETCH_ALERTS_SUCCESS':
      return fetchAlertsSuccess(state, action.response);
    case 'OVERVIEW_GET_MYCHARTS_WASHCOUNTS_SUCCESS':
      return getMyChartsWashCountsSuccess(state, action.response);
    case 'OVERVIEW_GET_WASHCOUNTS_SUCCESS':
      return getWashCountsSuccess(state, action.response);
    case SHOW_OVERVIEW_SUMMARY_CHART:
      return showSummaryChart(state, action.chart);
    case 'FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS':
      return fetchActiveSubscriptionSuccess(state, action.response);
    case 'SET_OVERVIEW_WASH_COUNT_UNTIL_DATE':
      return setUntilDate(state, action.date);
    case 'SET_OVERVIEW_WASH_COUNT_FROM_DATE':
      return setFromDate(state, action.date);
    case 'OVERVIEW_GET_HOURLY_WASHCOUNTS_SUCCESS':
      return getHourlyWashCountsSuccess(state, action.response);
    case 'OVERVIEW_GET_CLOSEST_SITES_HOURLY_WASHCOUNTS_SUCCESS':
      return getClosestsHourlyWashCountsSuccess(state, action.response);
    case 'OVERVIEW_GET_CLOSEST_SITES_SUCCESS':
      return getClosestsSuccess(state, action.response);
    case 'GET_OVERVIEW_CLAIMCOUNTS_SUCCESS':
      return getClaimCountsSuccess(state, returnedValue);
    case 'FETCH_WINDOW_SALES_SUCCESS':
      return getWindowSalesSuccess(state, returnedValue);
    case 'FETCH_COST_PER_CAR_DATA_SUCCESS':
      return getCostPerCarDataSuccess(state, returnedValue);
    case 'FETCH_PRODUCT_PLAN_COUNTS_SUCCESS':
      return fetchProductPlansSuccess(state, returnedValue, meta.query);
    case 'GET_SURVEY_RESULTS_SUCCESS':
      return getSurveyResultsSuccess(state, action.response);
    case 'FETCH_REVENUE_SUMMARY_SUCCESS':
      return fetchRevenueSummarySuccess(state, action.response);
    case 'FETCH_REVENUE_BEST_DAY_SUMMARY_SUCCESS':
      return fetchRevenueBestDaySummarySuccess(state, action.response);
    default:
      return state;
  }
};

export const selectMaintenanceTaskCompletions = (state) => {
  return state.home.maintenanceTaskCompletions;
};

export const selectMaintenanceTaskCompletionsTotal = (state) => {
  return state.home.maintenanceTaskCompletionsTotal;
};

export const selectProjectTaskCompletions = (state) => {
  return state.home.projectTaskCompletions;
};

export const selectProjectTaskCompletionsTotal = (state) => {
  return state.home.projectTaskCompletionsTotal;
};

export const selectAlerts = (state) => {
  return state.home.alerts;
};

export const selectAlertsTotal = (state) => {
  return state.home.alertsTotal;
};

export const selectSummaryChart = (state) => {
  return state.home.summaryChart;
};

export const selectDetergents = (state) => {
  return state.home.detergents;
};

export const selectDetergentData = (state) => {
  return state.home.detergentData;
};

export const selectFromDate = (state) => {
  return state.home.fromDate;
};

export const selectUntilDate = (state) => {
  return state.home.untilDate;
};

export const selectMyChartsWashCounts = (state) => {
  return state.home.myCharts.washCounts;
};
export const selectClaimCounts = (state) => {
  return state.home.overviewReclamations.countsByStatus;
};
export const selectRevenueSummary = (state) => {
  return state.home.myCharts.overviewRevenue.results;
};
export const selectRevenueBestDaySummary = (state) => {
  return state.home.myCharts.overviewRevenue.bestDayResults;
};
export const selectRevenueBestDayTotal = (state) => {
  return state.home.myCharts.overviewRevenue.bestDayTotal;
};
export const selectRevenueCurrency = (state) => {
  return state.home.myCharts.overviewRevenue.currency;
};
export const selectWashCounts = (state) => {
  return state.home.washCounts;
};
export const selectHourlyWashCounts = (state) => {
  return state.home.hourlyWashCounts;
};
export const selectClosestHourlyWashCounts = (state) => {
  return state.home.closestHourlyWashCounts;
};
export const selectClosestSites = (state) => {
  return state.home.closest;
};
export const selectActiveSubscriptionCount = (state) => {
  return state.home.activeSubscriptionCount;
};

export const selectWindowSalesGained = (state) => {
  return state.home.myCharts.totalWindowSalesCount;
};

export const selectTotalClubsGained = (state) => {
  return state.home.myCharts.totalClubsGained;
};

export const selectSurveyResults = (state) => {
  return state.home.myCharts.surveyResults;
};

export const selectCostPerCarResults = (state) => {
  return state.home.myCharts.costPerCar;
};
