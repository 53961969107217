import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSites } from '../../../../../core/actions';
import { selectUserGroups } from '../../../../../store/authReducer';
import { UserGroup } from '../../../../employees/models';
import { Site } from '../../../../site-selection/models';
import { selectSites } from '../../../../site-selection/reducer';
import { fetchUserGroups } from '../../../actions';

export interface FormSelections {
  sites: Site[];
  userGroups: UserGroup[];
}

const useFormSelections = (): FormSelections => {
  const dispatch = useDispatch();

  const sites = useSelector(selectSites);
  const userGroups = useSelector(selectUserGroups);
  const customerAdminUserGroupId = 7;
  const customerUserGroupId = 8;

  useEffect(() => {
    dispatch(fetchSites());
    dispatch(
      fetchUserGroups({
        query: { includeAll: true, exclude: [customerAdminUserGroupId, customerUserGroupId] },
      })
    );
  }, []);

  return {
    sites,
    userGroups,
  };
};

export default useFormSelections;
