const multiLogicOperator = (target, sites) => {
  return {
    schema: 'http://powerbi.com/product/schema#advanced',
    ...target,
    logicalOperator: 'Or',
    conditions: [...sites],
    filterType: 0,
  };
};

export const setFilter = (sites) => {
  const filter = {
    target: {
      table: 'Site',
      column: 'NameShort',
    },
  };

  return multiLogicOperator(filter, sites);
};

export const setWashCountsFilter = (sites) => {
  const filter = {
    target: {
      table: 'dim_site',
      column: 'short_name',
    },
  };

  return multiLogicOperator(filter, sites);
};

export default setFilter;
