import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import { injectIntl } from 'react-intl';

class FileReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileSizeError: false,
    };
  }

  validateFileSize = (files) => {
    const fileSizeLimit = 209715200; // 200 MBs;

    if (files.fileList[0].size < fileSizeLimit) {
      this.props.handleFiles(files);
      this.setState({ showFileSizeError: false });
    } else {
      this.setState({ showFileSizeError: true });
    }
  };

  render() {
    const intl = this.props.intl;
    return (
      <div>
        {this.state.showFileSizeError && (
          <div className="alert alert-danger">{intl.formatMessage({ id: 'fileSizeError' })}</div>
        )}
        <ReactFileReader
          base64
          multipleFiles={false}
          handleFiles={this.validateFileSize}
          fileTypes={`${this.props.fileType}/*`}
        >
          <button className="file-reader file-reader-button upload-button">
            <span className="upload-plus">+</span>
            <span className="upload-text">
              {`${intl.formatMessage({ id: this.props.title.toLowerCase() })}
                 ${intl.formatMessage({ id: this.props.fileType })}`}
            </span>
          </button>
        </ReactFileReader>
      </div>
    );
  }
}

FileReader.propTypes = {
  fileType: PropTypes.string.isRequired,
  handleFiles: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

export default injectIntl(FileReader);
