import classNames from 'classnames';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const NavTabs = ({ userView, pages, currentPage, setCurrentPage }) => {
  const viewPages = pages.filter((page) => page.displayName.includes(userView));

  return map(viewPages, (page) => (
    <button
      key={page.order}
      className={
        page.name === currentPage
          ? classNames('powerbi-tabs', 'powerbi-active')
          : classNames('powerbi-tabs', 'powerbi-inactive')
      }
      onClick={() => setCurrentPage(page.name)}
    >
      {page.displayName.replace(`${userView}-`, '').trim()}
    </button>
  )).sort((a, b) => a.key - b.key);
};

export default NavTabs;

NavTabs.propTypes = {
  userView: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
};
