import React from 'react';
import { FieldArray } from 'redux-form';
import { fieldRequired } from '../../../core/formValidation';
import VendorFormFields from './VendorFormFields';

const PurchaseOrderVendorForm = () => {
  return (
    <div className="form-group">
      <label htmlFor="items">Vendors</label>
      <br />
      <FieldArray name="items" component={VendorFormFields} validate={[fieldRequired]} />
    </div>
  );
};

export default PurchaseOrderVendorForm;
