import * as api from '../api';

export const postForgotPassword = (args: any): Promise<any> => {
  args.options = { noAuth: true };
  return api.callPost('/users/password/forgot', args);
};

export const postCheckPasswordResetToken = (args: any): Promise<any> => {
  args.options = { noAuth: true };
  return api.callPost('/users/password/token-status', args);
};

export const postResetPassword = (args: any): Promise<any> => {
  args.options = { noAuth: true };
  return api.callPost('/users/password', args);
};
