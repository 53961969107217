import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import FileUploadForm from '../../../core/components/FileUploadForm';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { FileType } from '../models';

export class UpsertFileDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    file: PropTypes.shape({}).isRequired,
    fileType: PropTypes.oneOf([FileType.image, FileType.video]).isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    initialFormValues: PropTypes.shape({}),
    intl: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  onReady = () => {
    this.props.triggerSubmit('fileUploadForm');
  };

  onSubmit = (data) => {
    this.setState({ submitting: true }, () => {
      this.props.onSubmit(data);
    });
  };

  render() {
    const { closeDialog, isOpen, file, fileType, initialFormValues } = this.props;
    const submitDisabled = checkErrors(this.props.errors);
    const intl = this.props.intl;

    return (
      <ModalDialog
        title={intl.formatMessage({ id: 'uploadFile' })}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
        showFooter={!this.state.submitting}
      >
        {!this.state.submitting ? (
          <FileUploadForm
            file={file}
            fileType={fileType}
            onSubmit={this.onSubmit}
            initialValues={initialFormValues}
          />
        ) : (
          <span>{intl.formatMessage({ id: 'uploading' })}...</span>
        )}
      </ModalDialog>
    );
  }
}
const getErrors = getFormSyncErrors('fileUploadForm');

const mapStateToProps = (state, ownProps) => {
  const initialFormValues = {
    file: ownProps.file ? ownProps.file.fileList[0] : {},
    description: '',
  };
  const errors = getErrors(state);
  return {
    errors,
    initialFormValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(UpsertFileDialog);
