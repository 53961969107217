import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { map, omit, toPairs } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import baseChart from '../../../core/components/BaseChart';
import DataTable from '../../../core/components/DataTable';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);
interface Props {
  chartData: {
    NeedsAttention: number;
    Investigating: number;
    UnderRepair: number;
    Repaired: number;
    Resolved: number;
    NotCaused: number;
    Incomplete: number;
    NeedsRepair: number;
    Denied: number;
    Completed: number;
    closedCount: number;
    openCount: number;
  };
}
export const ClaimsKpi = (props: Props): JSX.Element => {
  const [chartInfo, setChartInfo] = useState({ data: { datasets: [] }, options: {} });
  const [chartView, setChartView] = useState(true);
  const [padding, setPadding] = useState(10);
  const loadingApiCall = useSelector((state) => getApiStatus(state, 'GET_OVERVIEW_CLAIMCOUNTS'));
  const barRef = useRef<any>();
  const intl = useIntl();

  const createChartData = () => {
    const labels = ['Claims'];
    const borderRadius = 5;
    const borderRadiusAllCorners = {
      topLeft: borderRadius,
      topRight: borderRadius,
      bottomLeft: borderRadius,
      bottomRight: borderRadius,
    };
    const options: any = {
      indexAxis: 'y',
      responsive: true,
      borderSkipped: false,
      barPercentage: 0.5,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
        },
        title: {
          position: 'top',
          align: 'start',
          display: false,
          font: {
            size: 30,
          },
        },
        elements: {
          bar: {
            borderRadius: 5,
            pointStyle: 'circle',
          },
        },
        legend: {
          usePointStyle: 'true',
          pointStyle: 'circle',
          position: 'bottom',
          labels: {
            usePointStyle: 'true',
            pointStyle: 'circle',
            textAlign: 'center',
            boxWidth: 6,
            display: true,
            padding: padding,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
    };

    const data: any = {
      labels,
      datasets: [
        {
          label: ` Needs Attention (${props.chartData.NeedsAttention?.toString()})`,
          data: [props.chartData.NeedsAttention],
          backgroundColor: 'rgb(254,206,0)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
        {
          label: ` Investigating (${props.chartData.Investigating?.toString()})`,
          data: [props.chartData.Investigating],
          backgroundColor: 'rgb(23,151,198)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
        {
          label: ` Under Repair (${props.chartData.UnderRepair?.toString()})`,
          data: [props.chartData.UnderRepair],
          backgroundColor: 'rgb(252,118,255)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
        {
          label: ` Needs Repair (${props.chartData.NeedsRepair?.toString()})`,
          data: [props.chartData.NeedsRepair],
          backgroundColor: 'rgb(255,166,1)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
        {
          label: ` Incomplete (${props.chartData.Incomplete?.toString()})`,
          data: [props.chartData.Incomplete],
          backgroundColor: 'rgb(244, 67, 54)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
        {
          label: ` Closed (${props.chartData.closedCount?.toString()})`,
          data: [props.chartData.closedCount],
          backgroundColor: 'rgb(0,213,99)',
          borderRadius: borderRadiusAllCorners,
          borderWidth: 1,
        },
      ],
    };

    setChartInfo({
      data,
      options,
    });
  };

  const initializeDataTable = () => {
    const headers = [{ name: 'Status' }, { name: 'Count' }];
    const dataPair: [string, number][] = toPairs(
      omit(props.chartData, ['openCount', 'closedCount'])
    );

    const data = map(dataPair, (pair: [string, number]) => ({
      label: pair[0],
      count: pair[1],
    }));

    return {
      data,
      headers,
    };
  };

  const triggerMobileView = (chart?: ChartJS | null) => {
    if (!chart) {
      return;
    }

    if (chart.width <= 550) {
      setPadding(17);
      chart.render();
    } else {
      setPadding(10);
      chart.render();
    }
  };

  const handleChartDraw = () => {
    if (!loadingApiCall && Object.keys(chartInfo.data).length) {
      if (chartView) {
        return (
          <div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="claims-kpi-total-count">{props.chartData.openCount}</div>
            </div>
            <div className="claims-bar-height">
              <Bar ref={barRef} options={chartInfo.options} data={chartInfo.data} />
            </div>
          </div>
        );
      }
      return <DataTable {...initializeDataTable()} />;
    }
    return <Loader />;
  };

  useEffect(createChartData, [props.chartData]);
  useEffect(() => {
    const chart = barRef.current;

    triggerMobileView(chart);
  }, []);
  return (
    <div>
      <section className="container-fluid overview-chart-card">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'claimCounts' })}</div>
          <button className="button small" onClick={() => setChartView(!chartView)}>
            {chartView
              ? intl.formatMessage({ id: 'tableView' })
              : intl.formatMessage({ id: 'chartView' })}
          </button>
        </div>
        {handleChartDraw()}
      </section>
    </div>
  );
};

export default baseChart(ClaimsKpi);
