import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'redux-form';
import { requiredVideoEmbedInput } from '../../../core/formValidation';

export const TaskVideoEmbed = ({ fields }: { fields: any }) => {
  const intl = useIntl();
  const videoForm = () => {
    return (
      <div>
        {fields.map((item: any, index: number) => (
          <div key={index} className="readings-form input-padding">
            <div>
              <div>
                <div
                  className="form-group"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span style={{ width: '100%' }}>
                    <Field
                      name={`${item}`}
                      className="form-control"
                      component={requiredVideoEmbedInput}
                      placeholder={intl.formatMessage({ id: 'videoSupport' })}
                      onBlur={(e: any) => {
                        e.preventDefault();
                      }}
                    />
                  </span>
                  <button
                    className={'close multi-form-delete'}
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <i className="icon icon-squared-cross" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="inline-add-item-button">
      <button
        className={'btn btn-outline-success add-purchase-item'}
        onClick={() => {
          fields.push();
        }}
      >
        + {intl.formatMessage({ id: 'addVideo' })}
      </button>
      {fields ? videoForm() : <div />}
    </div>
  );
};
