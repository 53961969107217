import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import NotFound from '../core/components/404';
import Detergents from '../views/detergents/containers/Detergents';
import MyCharts from '../views/overview/containers/Charts';
import AnalyticsRoute from './AnalyticsRoute';
import ClaimsRoute from './ClaimsRoute';
import EmployeeRoute from './EmployeeRoute';
import ExpensesRoute from './ExpensesRoute';
import InventoryRoute from './InventoryRoute';
import RewashesRoute from './RewashesRoute';
import SettingsRoute from './SettingsRoute';
import SiteDispatcher from './SiteDispatcher';
import TasksRoute from './TasksRoute';
import WashCountsRoute from './WashCountsRoute';
import MaintenanceTasksRoute from './MaintenanceTasksRoute';

const siteRouteMap = {
  overview: MyCharts,
  detergents: Detergents,
  washcounts: WashCountsRoute,
  expenses: ExpensesRoute,
  analytics: AnalyticsRoute,
  tasks: TasksRoute,
  maintenance: MaintenanceTasksRoute,
  claims: ClaimsRoute,
  team: EmployeeRoute,
  inventory: InventoryRoute,
  rewashes: RewashesRoute,
  settings: SettingsRoute,
};

const SiteRoute = () => {
  const siteRouteKeys = Object.keys(siteRouteMap);

  const getSiteRoute = (key) => {
    return <Route key={key} path={`/:site/${key}`} component={siteRouteMap[key]} />;
  };

  return (
    <SiteDispatcher>
      <Switch>
        {siteRouteKeys.map(getSiteRoute)}
        <Route component={NotFound} />
      </Switch>
    </SiteDispatcher>
  );
};

export default SiteRoute;
