import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/products';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ARCHIVE_PRODUCT = 'ARCHIVE_PRODUCT';

export const fetchProducts = () => ({
  type: API_REQUEST,
  callAPI: API.fetchProducts,
  action: FETCH_PRODUCTS,
  meta: { permissionKey: viewPermissionRefs.products },
});

export const addProduct = (data) => ({
  type: API_REQUEST,
  callAPI: API.postProduct,
  action: ADD_PRODUCT,
  closeModal: true,
  data,
  callback: () => fetchProducts(),
});

export const updateProduct = (data) => ({
  type: API_REQUEST,
  callAPI: API.patchProduct,
  action: UPDATE_PRODUCT,
  closeModal: true,
  data,
  callback: () => fetchProducts(),
});

export const archiveProduct = (data) => ({
  type: API_REQUEST,
  callAPI: API.archiveProduct,
  action: ARCHIVE_PRODUCT,
  closeModal: true,
  data,
  callback: () => fetchProducts(),
});
