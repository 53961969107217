import React from 'react';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../../providers/AppProvider';
import '../../../styles/landing-page-header.scss';

const SetPasswordHeader = (): JSX.Element => {
  const { theme } = useAppContext();
  const intl = useIntl();
  return (
    <header className="landing-page-header-container">
      <img className="landing-page-header-logo" src={theme.logo} alt="logo" />
      <h1 className="landing-page-header-text">{intl.formatMessage({ id: 'setPassword' })}</h1>
      <div>{intl.formatMessage({ id: 'passwordLength' })}</div>
    </header>
  );
};

export default SetPasswordHeader;
