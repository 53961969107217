import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/inventory';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { getUrlParameter } from '../tasks/helpers';

export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const FETCH_INVENTORY_TYPES = 'FETCH_INVENTORY_TYPES';
export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM';
export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
export const BATCH_ADD_INVENTORY_ITEM = 'BATCH_SAVE_INVENTORY_ITEM';
export const FETCH_DETERGENT_DATA = 'FETCH_DETERGENT_DATA';
export const UPSERT_DETERGENT_DATA = 'UPSERT_DETERGENT_DATA';
export const SAVE_INVENTORY_ITEM = 'SAVE_INVENTORY_ITEM';
export const UPDATE_INVENTORY_STOCK = 'UPDATE_INVENTORY_STOCK';
export const DELETE_INVENTORY_ITEM = 'DELETE_INVENTORY_ITEM';
export const DELETE_INVENTORY_IN_EDIT = 'DELETE_INVENTORY_IN_EDIT';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';
export const SET_INVENTORY_FROM_DATE = 'SET_INVENTORY_FROM_DATE';
export const SET_INVENTORY_UNTIL_DATE = 'SET_INVENTORY_UNTIL_DATE';
export const SET_INVENTORY_TYPE_FILTER = 'SET_INVENTORY_TYPE_FILTER';
export const SET_STOCK_UPDATE_STATUS = 'SET_STOCK_UPDATE_STATUS';

export const fetchInventory = (query) => {
  let queryData = {
    inventoryTypeId: query && query.inventoryType ? query.inventoryType : getUrlParameter('types'),
    page: (query && query.page) || 0,
    includeAll: query ? query.includeAll : false,
  };
  if (query.fromDate && query.untilDate) {
    queryData = {
      ...queryData,
      from: query.fromDate,
      until: query.untilDate,
    };
  }
  return {
    type: API_REQUEST,
    callAPI: API.fetchInventory,
    data: { query: queryData },
    meta: { permissionKey: viewPermissionRefs.inventory, useManagerSiteId: true },
    action: FETCH_INVENTORY,
  };
};

export const fetchInventoryTypes = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.getInventoryTypes,
    data,
    meta: { permissionKey: viewPermissionRefs.inventory, useManagerSiteId: true },
    action: FETCH_INVENTORY_TYPES,
  };
};

export const getInventoryItem = (id) => ({
  type: API_REQUEST,
  callAPI: API.getInventoryItem,
  data: { id },
  action: GET_INVENTORY_ITEM,
});

export const addInventoryItem = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.postInventory,
    data: data.item,
    action: ADD_INVENTORY_ITEM,
    callback: data.callback || fetchInventory,
    closeModal: true,
  };
};

export const batchAddInventoryItems = (inventories) => {
  return {
    type: API_REQUEST,
    callAPI: API.batchPostInventory,
    data: { inventories },
    action: BATCH_ADD_INVENTORY_ITEM,
    callback: fetchInventory,
    closeModal: true,
  };
};

export const fetchDetergentData = (query, closeModal = true) => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchDetergentData,
    data: { query },
    action: FETCH_DETERGENT_DATA,
    closeModal,
  };
};

export const upsertDetergentData = (data) => {
  const query = {
    id: data.id,
    direction: 'desc',
    page: 0,
  };
  return {
    type: API_REQUEST,
    callAPI: API.upsertDetergentData,
    data,
    action: UPSERT_DETERGENT_DATA,
    callback: data.callback || (() => fetchDetergentData(query)),
    closeModal: true,
  };
};

export const saveInventoryItem = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.patchInventory,
    data: data.item,
    action: SAVE_INVENTORY_ITEM,
    callback: data.callback || fetchInventory,
    closeModal: true,
  };
};

export const updateInventoryStock = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.updateInventoryStock,
    data: data,
    action: UPDATE_INVENTORY_STOCK,
  };
};

export const setStockUpdateStatus = (status) => ({
  type: SET_STOCK_UPDATE_STATUS,
  status,
});

export const deleteInventoryItem = (item) => {
  return {
    type: API_REQUEST,
    callAPI: API.deleteInventory,
    data: { id: item.id },
    action: DELETE_INVENTORY_ITEM,
    navigate: item.navigate,
    callback: item.callback || fetchInventory,
    closeModal: true,
  };
};

export const deleteInventoryInEdit = () => ({ type: DELETE_INVENTORY_IN_EDIT });
export const setFromDate = (date) => ({ type: SET_INVENTORY_FROM_DATE, date: date });
export const setUntilDate = (date) => ({ type: SET_INVENTORY_UNTIL_DATE, date: date });
export const setTypeFilter = (typeFilter) => ({ type: SET_INVENTORY_TYPE_FILTER, typeFilter });
