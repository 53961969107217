import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { PermissionKeys } from '../../../core/components/UserPermissions';
import { selectSite } from '../../../store/authReducer';
import { formatAmount } from '../../../utils/money-util';
import { formatNumber } from '../../../utils/number-util';
import * as actions from '../actions';
import UtilityFilters from '../components/UtilityFilters';
import { selectPage, selectPageSize, selectTotal, selectUtilities } from '../utilityReducer';

export class Utilities extends Component {
  static propTypes = {
    utilities: PropTypes.arrayOf(PropTypes.object),
    fetchUtilityUnits: PropTypes.func.isRequired,
    fetchUtilities: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    getUtility: PropTypes.func.isRequired,
    saveUtility: PropTypes.func.isRequired,
    deleteUtility: PropTypes.func.isRequired,
    currentSite: PropTypes.string.isRequired,
    filter: PropTypes.string,
    permissions: PropTypes.shape({}),
    intl: PropTypes.shape({}).isRequired,
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  };

  componentDidMount() {
    if (this.props.permissions[PermissionKeys.viewUtilsTab]) {
      this.props.fetchUtilities();
      this.props.fetchUtilityUnits();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.props.fetchUtilities();
    }
  }

  getUtilitiesPage = (page) => {
    this.props.fetchUtilities({ page });
  };

  getPaginationInfo() {
    return {
      total: this.props.total,
      page: this.props.page,
      pageSize: this.props.pageSize,
      goToPage: this.getUtilitiesPage,
    };
  }

  editUtility = (event, { _id }) => {
    this.props.getUtility(_id);
    const dialogProps = {
      title: this.props.intl.formatMessage({ id: 'editUtility' }),
      onSubmit: this.props.saveUtility,
      isNew: false,
    };
    this.props.showDialog('UPSERT_UTILITY', dialogProps);
  };

  deleteUtility = (event, { _id, name }) => {
    const confirmProps = {
      onReady: this.props.deleteUtility.bind(null, _id),
      text: this.props.intl.formatMessage({ id: 'archiveUtilityConfirmation' }, { name }),
      title: this.props.intl.formatMessage({ id: 'archiveUtility' }),
    };
    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  initializeDatatable = (utilities) => {
    const { intl } = this.props;

    const data = utilities.map((u) => {
      return {
        _id: u.id,
        name: u.name,
        type: u.type,
        start: moment(u.start).format('MM/DD/YYYY'),
        end: moment(u.end).format('MM/DD/YYYY'),
        startReading: formatNumber(u.startReading, 4, true),
        endReading: formatNumber(u.endReading, 4, true),
        unit: u.unit,
        cost: formatAmount(u.cost, u.currency, true),
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'description' }) },
      { name: intl.formatMessage({ id: 'type' }) },
      { name: intl.formatMessage({ id: 'startDate' }) },
      { name: intl.formatMessage({ id: 'endDate' }) },
      { name: intl.formatMessage({ id: 'startReading' }) },
      { name: intl.formatMessage({ id: 'endReading' }) },
      { name: intl.formatMessage({ id: 'readingUnitShort' }) },
      { name: intl.formatMessage({ id: 'amount' }) },
      this.initializeActionButtonHeader(intl),
    ];

    return {
      data,
      headers,
    };
  };

  initializeActionButtonHeader = (intl) => {
    const actionButtonHeader = {
      name: intl.formatMessage({ id: 'action' }),
      cell: {
        component: TableActionButtonsCell,
        mixIns: {
          buttons: [],
        },
      },
    };

    if (this.props.permissions[PermissionKeys.deleteUtils]) {
      actionButtonHeader.cell.mixIns.buttons.push({
        icon: 'icon-trash',
        buttonAction: this.deleteUtility,
      });
    }
    if (this.props.permissions[PermissionKeys.addEditUtils]) {
      actionButtonHeader.cell.mixIns.buttons.push({
        icon: 'icon-edit',
        buttonAction: this.editUtility,
      });
    }
    return actionButtonHeader;
  };

  render() {
    const addDialogProps = {
      title: this.props.intl.formatMessage({ id: 'addUtility' }),
      isNew: true,
    };

    return (
      <React.Fragment>
        <div className="table-buttons">
          <UtilityFilters currentSite={this.props.currentSite} selectedFilter={this.props.filter} />
          <PageActions
            actionFunction={this.props.showDialog}
            modalProps={addDialogProps}
            actions={[
              {
                dialogType: 'UPSERT_UTILITY',
                dialogProps: addDialogProps,
                permissionKey: PermissionKeys.addEditUtils,
                text: this.props.intl.formatMessage({ id: 'addUtility' }),
              },
            ]}
          />
        </div>
        <DataTable
          paginationInfo={this.getPaginationInfo()}
          scrolling
          {...this.initializeDatatable(this.props.utilities)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilities: selectUtilities(state),
    currentSite: selectSite(state).toString(),
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showDialog, ...actions }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(Utilities);
