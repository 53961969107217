import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import AdjustWashCountForm from '../components/AdjustPOSCountForm';
import { AdjustPOSCountDialogPropTypes } from '../types';

export class AdjustPOSCountDialog extends Component {
  static propTypes = AdjustPOSCountDialogPropTypes;

  onReady = () => {
    this.props.triggerSubmit('adjustPOSCount');
  };

  onSubmit = ({ notes, adjustmentField }) => {
    const date = this.props.date.format('YYYY-MM-DD');
    const adjustments = [];

    if (adjustmentField) {
      adjustmentField.forEach((field) => {
        // Skip adjustments with unselected products
        if (field.product === 'Select product') {
          return;
        }

        adjustments.push({
          numAdjustments: field.newAdjustment,
          operationName: field.product,
          date,
          notes,
        });
      });
    }

    this.props.onSubmit({ adjustments, notes, date });
  };

  render() {
    // eslint-disable-next-line
    const { closeDialog, errors, isOpen, intl, formData } = this.props;
    const initialValues = { notes: formData.notes };

    return (
      <ModalDialog
        title={intl.formatMessage({ id: 'adjustPOSWashes' })}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={checkErrors(errors)}
        className="modal-xl"
      >
        <AdjustWashCountForm
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          formData={formData}
          enableReinitialize
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('adjustPOSCount');

const mapStateToProps = (state) => ({ errors: getErrors(state) });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(AdjustPOSCountDialog);
