import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import DetailHeader from '../../../core/components/DetailHeader';
import TinyTable from '../components/TinyTable';

const ExpenseDetailHeader = (props) => {
  const { intl, buttons, purchaseOrder } = props;

  const getTitles = (po) => {
    return [
      {
        label: intl.formatMessage({ id: 'poNumber' }),
        content: po.orderNumber,
      },
      {
        label: intl.formatMessage({ id: 'orderDate' }),
        content: moment(po.orderDate).format('MM/DD/YYYY'),
      },
      {
        label: intl.formatMessage({ id: 'createdAt' }),
        content: moment(po.createdAt).format('MM/DD/YYYY'),
      },
      {
        label: '',
        content: (
          <TinyTable
            data={[
              { name: 'Sub-Total', value: `${po.subTotal}` },
              { name: 'Discount', value: `${po.discount}` },
              { name: 'Tax', value: `${po.taxAmount}` },
              { name: 'Shipping', value: `${po.shippingAmount}` },
              {
                name: 'Total',
                value: `${po.total}`,
                className: ['', 'fontSize16'],
              },
            ]}
            caption="Order Summary"
          />
        ),
      },
      {
        className: 'expenseDetail--infoHeader',
        label: intl.formatMessage({ id: 'orderInfo' }),
      },
      {
        className: 'expenseDetail--orderedBySection',
        label: intl.formatMessage({ id: 'orderedBy' }),
        content: po.employee && `${po.employee.firstName} ${po.employee.lastName}`,
      },
      {
        className: 'expenseDetail--vendorSection',
        label: intl.formatMessage({ id: 'vendor' }),
        content: po.vendorName,
      },
      {
        className: 'expenseDetail--categorySection',
        label: intl.formatMessage({ id: 'category' }),
        content: po.categoryName,
      },
      {
        className: 'expenseDetail--trackingSection',
        label: intl.formatMessage({ id: 'trackingNumber' }),
        content: (
          <a href={`https://www.google.com/search?q=${po.trackingNumber}`}>{po.trackingNumber}</a>
        ),
      },
    ];
  };

  return (
    <DetailHeader
      titles={getTitles(purchaseOrder)}
      buttons={buttons}
      buttonClass="expenseDetail--gridActionButtons"
    />
  );
};

ExpenseDetailHeader.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  purchaseOrder: PropTypes.shape({}).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
};

export default injectIntl(ExpenseDetailHeader);
