import * as utils from '../../core/utils';
import { createIntervalDescription } from './helpers';

export const TaskType = {
  Maintenance: 0,
  Project: 1,
  OpenChecklist: 2,
  ClosingChecklist: 3,
  PreventativeMaintenance: 4,
  CleaningList: 5,
  HourlyInspection: 6,
  ExpressInterior: 7,
};

export const Interval = {
  CarCount: 0,
  Once: 1,
  Daily: 2,
  Weekly: 3,
  Monthly: 4,
  Yearly: 5,
  Hourly: 6,
};

export class Task {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.type = utils.toEnum(TaskType, props.type);
    this.interval = utils.toEnum(Interval, props.interval);
    this.createdAt = props.createdAt;
    this.creator = props.creator;
    this.modifier = props.modifier;
    this.modifiedAt = props.modifiedAt;
    this.start = props.start;
    this.end = props.end;
    this.intervalCount = props.intervalCount || 1;
    this.weeklySchedule = props.weeklySchedule;
    this.carCount = props.carCount;
    this.hourlySchedule = props.hourlySchedule;
    this.monthlySameWeekday = props.monthlySameWeekday;
    this.alertIfOverdue = props.alertIfOverdue;
    this.siteId = props.siteId;
    this.latestCompletion = props.latestCompletion;
    this.description = props.description;
    this.videos = props.videos;
    this.inventory = props.inventory;
    this.intervalCarCount = props.intervalCarCount;
    this.startingCarCount = props.startingCarCount;
    this.groupId = props.groupId;
    this.prefixId = props.prefixId;
    this.resource = props.resource;
    this.locationId = props.locationId;
    this.operationTypeId = props.operationTypeId;
    this.modelYears = props.modelYears;
    this.notes = props.notes;
    this.linkedTasks = props.linkedTasks;
    this.linkedCompletions = props.linkedCompletions;
    this.subTitle = props.subTitle;
    this.forecast = props.forecast;
  }

  getIntervalDescription(formatMessage) {
    return createIntervalDescription(this, formatMessage);
  }

  get isMasterTask() {
    return this.groupId !== undefined && this.groupId !== null;
  }
}

export class TaskCompletion {
  constructor(props = {}) {
    this.id = props.id;
    this.archivedAt = props.archivedAt;
    this.createdAt = props.createdAt;
    this.creator = props.creator;
    this.employeeId = props.employeeId;
    this.employee = props.employee;
    this.modifiedAt = props.modifiedAt;
    this.modifier = props.modifier;
    this.taskId = props.taskId;
    this.timestamp = props.timestamp;
    this.status = props.status;
    this.scheduleEnd = props.scheduleEnd;
    this.scheduleStart = props.scheduleStart;
    this.scheduleCarCount = props.scheduleCarCount;
    this.siteId = props.siteId;
    this.task = props.task;
    this.carCount = props.carCount;
  }
}

export class TaskPrefix {
  constructor(props = {}) {
    this.type = props.id;
    this.message = props.name;
  }
}

export class TaskLocation {
  constructor(props = {}) {
    this.type = props.id;
    this.message = props.name;
    this.order = props.orderNumber;
  }
}

export const TaskStatus = {
  Pending: 0,
  Snoozed: 1,
  Completed: 2,
};
