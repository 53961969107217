import { IntlType } from '../../../../utils/typing';

interface Props {
  intl: IntlType;
  handleClick: () => void;
}

const ClearFieldsButton = (props: Props): JSX.Element => {
  const { intl, handleClick } = props;

  return (
    <button className="clear-fields-button" onClick={handleClick}>
      {intl.formatMessage({ id: 'clearFields' })}
    </button>
  );
};

export default ClearFieldsButton;
