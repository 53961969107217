import * as api from '../api';

export const fetchProducts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/products`, args);
};

export const postProduct = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/products`, args);
};

export const patchProduct = (args) => {
  const {
    options: { siteId },
    data: { productId },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/products/${productId}`, args);
};

export const archiveProduct = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/products/${id}/archive`, args);
};
