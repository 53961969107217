import React, { memo } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';

type Header = {
  name: string;
  databaseProperty: string;
  key: string;
};

interface Props {
  data: any[];
  headers: Header[];
  filename?: string;
}
// CSVExport component
// Use property 'key' in headers to map the data to the correct column

const CSVExport = (props: Props) => {
  const { data, headers, filename } = props;
  const intl = useIntl();
  const csvHeaders = headers.map((header) => ({ label: header.name, key: header.key }));
  const csvFilename = filename || `export-${new Date().toISOString()}.csv`;

  return (
    <CSVLink data={data} headers={csvHeaders} filename={csvFilename}>
      <button
        disabled={data?.length === 0}
        className="btn btn-secondary drs-button-shadow has-alerts mt-2"
      >
        {intl.formatMessage({ id: 'exportToCsv' })}
      </button>
    </CSVLink>
  );
};

export default memo(CSVExport);
