import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import DataTable from '../../../core/components/DataTable';

export const DetergentBasedReadings = ({
  intl,
  detergents,
  detergentReadings,
  getReadings,
  removeReadings,
}) => {
  const initializeDataTable = () => {
    const toRows = (detergent) => ({
      _id: `${detergent.id}-${detergent.name}`,
      name: detergent.name,
      reading: '',
      timestamp: '',
    });

    const toSubRowData = (data) => ({
      name: '',
      reading: data.value,
      timestamp: moment(data.timestamp).format('MM/DD/YYYY'),
    });

    const subRows = detergents.map((detergent) => {
      const data = detergentReadings[detergent.id];

      return {
        parentRow: `${detergent.id}-${detergent.name}`,
        rows: { data: data ? data.map(toSubRowData) : [] },
      };
    });

    const subRowFunction = (func) => (id) => {
      const detergentId = id.split('-')[0];
      func([detergentId]);
    };

    const subRowInfo = {
      identifier: '_id',
      displayProperty: 'name',
      subRows,
      subRowFetchFunction: subRowFunction(getReadings),
      subRowRemovalFunction: subRowFunction(removeReadings),
    };

    return {
      headers: [
        { name: intl.formatMessage({ id: 'name' }) },
        { name: intl.formatMessage({ id: 'reading' }) },
        { name: intl.formatMessage({ id: 'timestamp' }) },
      ],
      data: detergents.map(toRows),
      subRowInfo,
      addedClass: 'historical-tank-readings-table detergent-based mb-0',
      scrolling: true,
    };
  };

  return <DataTable {...initializeDataTable()} />;
};

DetergentBasedReadings.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object),
  detergentReadings: PropTypes.shape({}),
  getReadings: PropTypes.func.isRequired,
  removeReadings: PropTypes.func.isRequired,
};

export default injectIntl(DetergentBasedReadings);
