import moment from 'moment';
import { AlertObject } from './Alerts';
import { acknowledgeAlert, archiveAlert } from '../../views/site-selection/actions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

interface AlertProps {
  /** Pass in the alert object from the backend */
  alert: AlertObject;
}

export const Alert = ({ alert }: AlertProps) => {
  const dispatch = useDispatch();
  const [unread, setUnread] = useState(!alert.acknowledged);
  const handleReadAlert = () => {
    if (unread) {
      setUnread(false);
      dispatch(acknowledgeAlert(alert.id));
    }
  };
  const handleArchiveAlert = () => {
    dispatch(archiveAlert(alert.id));
  };
  useEffect(() => {
    setUnread(!alert.acknowledged);
  }, [alert]);
  return (
    <div className={`alert-item${unread ? ' unread' : ''}`} role="menuitem">
      <div className="alert-description" data-testid="alert-description" onClick={handleReadAlert}>
        <p>{alert.description}</p>
        <span>{alert.createdAt ? moment(alert.createdAt).fromNow() : ''}</span>
      </div>
      {unread ? (
        <div>
          <span className="unread-dot"></span>
        </div>
      ) : (
        <button
          className="button transparent"
          type="button"
          title="Clear notification"
          onClick={handleArchiveAlert}
        >
          <i className="uil uil-times"></i>
        </button>
      )}
    </div>
  );
};

export default Alert;
