import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { getSelf } from '../core/auth';
import { INITIALIZED, UNAUTHORIZED } from '../core/constants';
import { getJwtToken, getRefreshToken } from '../core/storage';
import {
  getSelfRequest,
  getSelfSuccess,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
} from '../views/login/actions';
import { refresh } from '../views/login/sagas';

function* requestToken({ tokens }) {
  const refreshResponse = yield call(refresh, tokens);

  if (!refreshResponse) {
    yield put({ type: UNAUTHORIZED });
  }
}

function* fetchUser(tokens) {
  const userResponse = yield call(getSelf, tokens);
  yield put(getSelfSuccess(userResponse));
}

function* handleInitialAuthentication() {
  const tokens = {
    jwtToken: yield call(getJwtToken),
    refreshToken: yield call(getRefreshToken),
  };

  if (!tokens.jwtToken || !tokens.refreshToken) {
    return;
  }

  yield put(getSelfRequest());

  try {
    yield call(fetchUser, tokens);
  } catch (error) {
    yield call(requestToken, { tokens });
  }
}

export function* getUserAfterRefresh(tokens) {
  try {
    yield call(fetchUser, tokens);
  } catch (error) {
    yield put({ type: UNAUTHORIZED });
  }
}

export function* watchRefreshTokenRequests() {
  yield takeLeading(REFRESH_REQUEST, requestToken);
}

export function* watchRefreshTokenSuccess() {
  yield takeEvery(REFRESH_SUCCESS, getUserAfterRefresh);
}

export function* watchInitialized() {
  yield takeEvery(INITIALIZED, handleInitialAuthentication);
}
