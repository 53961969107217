import moment from 'moment';
import { API_REQUEST } from '../../core/actions';
import * as taskCompletionsAPI from '../../core/api/task-completions';

export const GET_TASK_COMPLETIONS_FOR_TASK = 'GET_TASK_COMPLETIONS_FOR_TASK';
export const CLEAR_TASK_COMPLETIONS_FOR_TASK = 'CLEAR_TASK_COMPLETIONS_FOR_TASK';
export const SET_TASK_FROM_DATE = 'SET_TASK_FROM_DATE';
export const SET_TASK_UNTIL_DATE = 'SET_TASK_UNTIL_DATE';

export const setFromDate = (date) => ({ type: SET_TASK_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_TASK_UNTIL_DATE, date });
export const setNotes = (draft) => ({
  type: SET_TASK_NOTES,
  taskId: draft.taskId,
  notes: draft.notes,
});

export const getTaskCompletionsForTask = (query) => ({
  type: API_REQUEST,
  callAPI: taskCompletionsAPI.getTaskCompletionsForTask,
  data: {
    taskId: query.id,
    query: {
      id: query.id,
      page: (query && query.page) || 0,
      pageSize: query.pageSize,
      direction: 'desc',
      fromDate: moment(query.fromDate).format('YYYY-MM-DD'),
      untilDate: moment(query.untilDate).format('YYYY-MM-DD'),
      notes: query?.notes ?? 0,
    },
  },
  action: GET_TASK_COMPLETIONS_FOR_TASK,
});

export const clearTaskCompletionsForTask = () => ({
  type: CLEAR_TASK_COMPLETIONS_FOR_TASK,
});
