import * as api from '../api';

export const fetchPurchaseOrderVendors = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/purchase-order-vendors`, args);
};

export const postPurchaseOrderVendors = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/purchase-order-vendors`, args);
};
