import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ViewTabs from '../../../core/components/ViewTabs';
import { selectSite } from '../../../store/authReducer';

const EmployeeViews = ({ children, intl, currentSite }) => {
  /* shifts view extends team route in order to preserve active state
     on team side menu to initially render employees table in React Router
  */

  const viewOptions = [
    {
      view: intl.formatMessage({ id: 'teamMembers' }),
      pathname: `/${currentSite}/team`,
    },
    {
      view: intl.formatMessage({ id: 'shifts' }),
      pathname: `/${currentSite}/team/shifts`,
    },
  ];

  return (
    <div className="container-fluid">
      <ViewTabs viewOptions={viewOptions} />
      {children}
    </div>
  );
};

EmployeeViews.propTypes = {
  children: PropTypes.shape({}).isRequired,
  currentSite: PropTypes.string.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentSite: selectSite(state).toString(),
  };
};

export default connect(mapStateToProps, null)(injectIntl(EmployeeViews));
