import React from 'react';
import { Field } from 'redux-form';
import CarModelSelect from '../../../core/components/CarModelSelect';

const VehicleDetails = () => {
  const safeParseInt = (value) =>
    Number(value) === 0 || Number.isNaN(Number(value)) ? '' : Number(Number(value));
  return (
    <div>
      <div className="form-group row" style={{ marginBottom: '0' }}>
        <div className="col-sm-6">
          <label>Vehicle Details</label>
        </div>
      </div>
      <CarModelSelect makeProperty="vehicleDetails.make" modelProperty="vehicleDetails.model" />
      <div className="form-group row">
        <div className="form-group col-sm-6">
          <Field
            className="form-control"
            name="vehicleDetails.year"
            id="year"
            component="input"
            type="text"
            parse={safeParseInt}
            aria-describedby="descriptionHelp"
            placeholder="Year"
            data-testid="vehicleYear-reclaimation-form"
          />
        </div>
        <div className="form-group col-sm-6">
          <Field
            className="form-control"
            name="vehicleDetails.color"
            id="color"
            component="input"
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Color"
            data-testid="vehicleColor-reclaimation-form"
          />
        </div>
        <div className="col-sm-6">
          <Field
            className="form-group form-control"
            name="vehicleDetails.vin"
            id="vin"
            component="input"
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="VIN"
            data-testid="vehicleVin-reclaimation-form"
          />
        </div>
        <div className="form-group col-sm-6">
          <Field
            className="form-control"
            name="vehicleDetails.plate"
            id="plate"
            component="input"
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Plate"
            data-testid="vehiclePlate-reclaimation-form"
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
