import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useAppContext } from '../../../providers/AppProvider';

export default function useDateOperations(dates = []) {
  const tz = useAppContext().timeZone;
  const formatDate = (date) => moment(date).format('MM/DD/YYYY');
  const getDisplayDateRange = () =>
    dates.length === 2 && `${formatDate(dates[0])} - ${formatDate(dates[1])}`;

  return useMemo(
    () => ({
      IsEqualTo: {
        id: 'IsEqualTo',
        name: 'is equal to',
        calendars: 1,
        displayDate: dates && formatDate(dates[0]),
        until: moment.tz(dates[0], tz).endOf('day'),
      },
      IsBetween: {
        id: 'IsBetween',
        name: 'is between',
        calendars: 2,
        displayDate: dates && dates.length === 2 && getDisplayDateRange(),
        until: moment.tz(dates[1], tz).endOf('day'),
      },
      IsOnAndAfter: {
        id: 'IsOnAndAfter',
        name: 'is on and after',
        calendars: 1,
        displayDate: dates && dates.length === 2 && getDisplayDateRange(),
        until: moment.tz(tz).endOf('day'),
      },
    }),
    [dates, tz]
  );
}
