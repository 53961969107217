import { injectIntl } from 'react-intl';
import DataTable from '../../../../core/components/DataTable';
import '../../../../styles/manage-users.scss';
import { IntlType } from '../../../../utils/typing';
import { useUsersTableProps } from './hooks/useUsersTableProps';
import IncludeArchivedCheckbox from './IncludeArchivedCheckbox';
import ManageUsersHeader from './ManageUsersHeader';

interface Props {
  intl: IntlType;
}

const ManageUsers = ({ intl }: Props) => {
  const { setSearchParams, ...tableProps } = useUsersTableProps(intl);

  const handleIncludeArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({
      includeArchived: event.target.checked,
    });
  };

  return (
    <>
      <ManageUsersHeader intl={intl} />
      <IncludeArchivedCheckbox intl={intl} handleChange={handleIncludeArchived} />
      <DataTable {...tableProps} />
    </>
  );
};

export default injectIntl(ManageUsers);
