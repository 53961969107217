import { PropTypes } from 'prop-types';
import React from 'react';
import Button from './Button';
import UserGroupPermissions from './UserGroupPermissions';

const DetailHeader = ({ titles, buttons, addedClass, buttonClass }) => (
  <div className="detail-header-container">
    <div data-testid="detailHeader" className={`detail-header ${addedClass}`}>
      {titles.map(
        (title) =>
          title.children || (
            <div
              data-testid="detailHeader-title"
              className={title.className}
              key={title.key || title.label}
            >
              <label>{title.label}</label>
              {title.content}
            </div>
          )
      )}
      {buttons && (
        <div className={`detail-actions ${buttonClass}`}>
          {buttons.map((button) => (
            <UserGroupPermissions key={button.label} itemRef={button.permissionKey}>
              <Button
                onClick={button.onClick}
                className={button.class || 'detail-action blue-button'}
                key={button.label}
              >
                {button.label}
              </Button>
            </UserGroupPermissions>
          ))}
        </div>
      )}
    </div>
  </div>
);

DetailHeader.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.shape({})),
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  addedClass: PropTypes.string,
  buttonClass: PropTypes.string,
  permissionKey: PropTypes.string, // eslint-disable-line
};

export default DetailHeader;
