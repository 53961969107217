import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { requiredInput as RequiredInput } from '../../../../core/formValidation';
import { IntlType } from '../../../../utils/typing';
import { archiveEmployee, unarchiveEmployee } from '../../../employees/actions';
import Employee from '../../../employees/models';
import { Formik } from '../../hooks/useForm';
import ConfirmDialog from '../ConfirmDialog';
import useEmployees from '../ManageUsers/hooks/useEmployees';

interface Props {
  intl: IntlType;
  formik: Formik;
  isNew: boolean;
  employee?: Employee;
}

interface DialogParams {
  isOpen: boolean;
  header: string;
  text: string;
  handleConfirm: () => void;
}

const EmployeeForm = ({ intl, formik, isNew, employee }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { getEmployees, setSearchParams } = useEmployees();

  const [confirmationDialogParams, setConfirmationDialogParams] = useState<DialogParams>({
    isOpen: false,
    header: '',
    text: '',
    handleConfirm: () => {},
  });

  const getTextInput = (fieldName: string, placeholderId: string) => {
    return {
      id: fieldName,
      type: 'text',
      placeholder: intl.formatMessage({ id: placeholderId }),
      ...formik.getFieldProps(fieldName),
    };
  };

  const openArchiveConfirmation = (event: any) => {
    event.preventDefault();

    setConfirmationDialogParams({
      isOpen: true,
      header: intl.formatMessage({ id: 'archiveUser' }),
      text: intl.formatMessage({ id: 'archiveConfirmation' }),
      handleConfirm: handleArchiveUser,
    });
  };

  const openUnarchiveConfirmation = (event: any) => {
    event.preventDefault();

    setConfirmationDialogParams({
      isOpen: true,
      header: intl.formatMessage({ id: 'unarchiveUser' }),
      text: intl.formatMessage({ id: 'unarchiveConfirmation' }),
      handleConfirm: handleUnarchiveUser,
    });
  };

  const handleArchiveUser = () => {
    const body: any = { id: employee?.id };

    const isUserArchived = !!employee?.coreUser?.archivedAt;

    if (!isUserArchived) {
      body.coreUserId = employee?.coreUserId;
    }

    dispatch(archiveEmployee({ data: { body }, closeModal: false }));

    setConfirmationDialogParams({ isOpen: false, header: '', text: '', handleConfirm: () => {} });

    setTimeout(() => {
      setSearchParams({ page: 0 });
      getEmployees();
    }, 2000);
  };

  const handleUnarchiveUser = () => {
    const body: any = { id: employee?.id };

    const isUserArchived = !!employee?.coreUser?.archivedAt;

    if (isUserArchived) {
      body.coreUserId = employee?.coreUserId;
    }

    dispatch(unarchiveEmployee({ data: { body }, closeModal: false }));

    setConfirmationDialogParams({ isOpen: false, header: '', text: '', handleConfirm: () => {} });

    setTimeout(() => {
      setSearchParams({ page: 0 });
      getEmployees();
    }, 2000);
  };

  return (
    <Form data-testid="employee-form">
      <Row md={2} form>
        <Col md={6} className="mb-3">
          <Label for="firstName" className="font-weight-bold">
            {intl.formatMessage({ id: 'firstName' })}
          </Label>
          <RequiredInput input={getTextInput('firstName', 'enterName')} />
        </Col>
        <Col md={6} className="mb-3">
          <Label for="lastName" className="font-weight-bold">
            {intl.formatMessage({ id: 'lastName' })}
          </Label>
          <RequiredInput input={getTextInput('lastName', 'enterName')} />
        </Col>
      </Row>
      <Row form>
        <Col md={6} className="mb-3">
          <Label for="email" className="font-weight-bold">
            {intl.formatMessage({ id: 'email' })}
          </Label>
          <RequiredInput input={getTextInput('email', 'emailExample')} />
        </Col>
        <Col md={6} className="mb-3">
          <Label for="phoneNumber" className="font-weight-bold">
            {intl.formatMessage({ id: 'phoneNumber' })}
          </Label>
          <RequiredInput input={getTextInput('phoneNumber', 'enterPhone')} />
        </Col>
      </Row>
      <Row form>
        <Col md={6} className="mb-3">
          <Label for="position" className="font-weight-bold">
            {intl.formatMessage({ id: 'jobTitle' })}
          </Label>
          <RequiredInput input={getTextInput('position', 'enterTitle')} />
        </Col>
        <Col md={6} className="mb-3">
          <Label for="loginCredentials" className="font-weight-bold">
            {intl.formatMessage({ id: 'loginCredentials' })}
          </Label>
          <FormGroup className="d-flex">
            <Input
              id="loginCredentials"
              {...formik.getFieldProps('loginCredentialsNeeded')}
              type="checkbox"
              className="dr-styled-checkbox"
              checked={formik.values.loginCredentialsNeeded}
            />
            <Label className="checkbox-mark" for="loginCredentials" />
            <Label className="ml-2 align-self-center">
              {intl.formatMessage({ id: 'managerLoginNeeded' })}
            </Label>
          </FormGroup>
          <FormGroup className="d-flex">
            <Input
              id="isCashier"
              {...formik.getFieldProps('isCashier')}
              type="checkbox"
              className="dr-styled-checkbox"
              checked={formik.values.isCashier}
            />
            <Label className="checkbox-mark" for="isCashier" />
            <Label className="ml-2 align-self-center">
              {intl.formatMessage({ id: 'isCashier' })}
            </Label>
          </FormGroup>
        </Col>
      </Row>

      {!isNew && (
        <Row form>
          <Col md={4} className="mb-3">
            <Label for="position" className="font-weight-bold">
              {intl.formatMessage({ id: 'actions' })}
            </Label>
            <FormGroup className="d-flex flex-column">
              {!employee?.archivedAt && (
                <button className="archive-user-button" onClick={openArchiveConfirmation}>
                  {intl.formatMessage({ id: 'archiveUser' })}
                </button>
              )}

              {employee?.archivedAt && (
                <button className="archive-user-button" onClick={openUnarchiveConfirmation}>
                  {intl.formatMessage({ id: 'unarchiveUser' })}
                </button>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}

      {confirmationDialogParams.isOpen && (
        <ConfirmDialog
          intl={intl}
          header={confirmationDialogParams.header}
          text={confirmationDialogParams.text}
          handleConfirm={confirmationDialogParams.handleConfirm}
          toggle={() =>
            setConfirmationDialogParams({
              isOpen: false,
              header: '',
              text: '',
              handleConfirm: () => {},
            })
          }
        />
      )}
    </Form>
  );
};

export default EmployeeForm;
