import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCategory } from '../../products/models';
import { selectSiteId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { fetchCostPerCarData } from '../actions';
import { selectCostPerCarResults } from '../reducer';
import CostPerCarKpi from './CostPerCarKpi';
import { WashCategory } from '../../wash-counts/models';

interface Props {
  fromDate: moment.Moment;
  untilDate: moment.Moment;
}

const CostPerCarDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const costPerCarData = useSelector(selectCostPerCarResults),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    managerSiteId = useSelector(selectSiteId);

  const handleCostPerCarData = () => {
    if (managerSiteId) {
      dispatch(
        fetchCostPerCarData(
          managerSiteId,
          props.fromDate,
          props.untilDate,
          ProductCategory.SingleWash,
          WashCategory.Exterior
        )
      );
    }
  };
  useEffect(handleCostPerCarData, [
    managerSiteId,
    props.fromDate,
    props.untilDate,
    multiSiteSelection,
  ]);

  return (
    <CostPerCarKpi
      costPerCarData={costPerCarData}
      fromDate={props.fromDate}
      untilDate={props.untilDate}
    />
  );
};

export default CostPerCarDataGetter;
