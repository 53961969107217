import { all, fork } from 'redux-saga/effects';
import { watchEmbedTokenExpiration } from '../views/analytics/sagas';
import { loginFlowSaga, logoutFlowSaga } from '../views/login/sagas';
import { watchSiteSettingsSagas } from '../views/settings/sagas';
import { watchSiteSelectionSaga } from '../views/site-selection/sagas';
import { watchWorkLogSagas } from '../views/work-logs/sagas';
import { watchApiSagas } from './apiSaga';
import { watchExpensesSagas } from '../views/expenses/sagas';
import { watchInitialized, watchRefreshTokenRequests, watchRefreshTokenSuccess } from './authSaga';
import { requestErrorSaga } from './requestErrorSaga';

export default function* rootSaga() {
  yield all([
    fork(loginFlowSaga),
    fork(logoutFlowSaga),
    fork(requestErrorSaga),
    fork(watchSiteSelectionSaga),
    fork(watchApiSagas),
    fork(watchWorkLogSagas),
    fork(watchSiteSettingsSagas),
    fork(watchEmbedTokenExpiration),
    fork(watchRefreshTokenRequests),
    fork(watchRefreshTokenSuccess),
    fork(watchInitialized),
    fork(watchExpensesSagas),
  ]);
}
