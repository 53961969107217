import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import EmployeeEditForm from '../components/EmployeeEditForm';
import { selectEmployeeInEdit } from '../reducer';
import { injectIntl } from 'react-intl';
const initialFormValues = {
  lastName: '',
  firstName: '',
  email: '',
  phoneNumber: '',
};

export class UpsertEmployeeDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    initialEmployee: PropTypes.shape({}).isRequired, // instanceOf Employee
    isNew: PropTypes.bool,
    errors: PropTypes.shape({}),
    intl: PropTypes.shape({}).isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('employee');
  };

  render() {
    const { closeDialog, isOpen, initialEmployee, isNew, onSubmit } = this.props;
    const enableReinitialize = !isNew;
    const submitDisabled = checkErrors(this.props.errors);
    const intl = this.props.intl;

    return (
      <ModalDialog
        title="Edit Team Member"
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <EmployeeEditForm
          onSubmit={(values) => onSubmit({ data: { body: values } })}
          intl={intl}
          initialValues={initialEmployee}
          enableReinitialize={enableReinitialize}
        />
      </ModalDialog>
    );
  }
}
const getErrors = getFormSyncErrors('employee');

const mapStateToProps = (state, ownProps) => {
  const initialEmployee = ownProps.isNew ? initialFormValues : selectEmployeeInEdit(state);
  const errors = getErrors(state);
  return {
    initialEmployee,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};
const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(UpsertEmployeeDialog);
