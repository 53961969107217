import * as api from '../api';

export const fetchOperationPermissionSurveyResults = (args: any): Promise<any> => {
  const {
    data: {
      query: { siteId },
    },
  } = args;
  return api.callGet(`/survey-responses/${siteId}/operation-permissions`, args);
};
