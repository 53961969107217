import { filter, sumBy } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { Select } from '../../../core/formValidation';

const AdjustmentFields = ({ fields, intl, products, adjustments, combinedCounts }) => {
  const addFieldRow = () => {
    fields.push({
      index: fields.length,
      product: 'Select product',
      current: 0,
      todaysAdjustments: 0,
      newAdjustment: 0,
      newTotal: 0,
    });
  };

  const removeFieldRow = (index) => () => {
    fields.splice(index, 1);
  };

  const getOptions = () => (
    <React.Fragment>
      <option value="Select product" disabled>
        {intl.formatMessage({ id: 'selectProduct' })}
      </option>
      {products.map((product) => (
        <option key={product.name} value={product.name}>
          {product.name}
        </option>
      ))}
    </React.Fragment>
  );

  const setFieldProduct = (value, item) => {
    const filteredAdjustments = filter(adjustments, ['operationName', value]);
    const numAdjustments = sumBy(filteredAdjustments, 'numAdjustments');
    const selectedWash = combinedCounts.washes.find((wash) => wash.name === value);
    const itemReference = item;

    itemReference.current = selectedWash ? selectedWash.count : 0;
    itemReference.todaysAdjustments = numAdjustments;
    itemReference.adjustment = 0;
    itemReference.newTotal = item.current;
  };

  const setFieldAdjustment = (value, item) => {
    const itemReference = item;
    itemReference.newTotal = itemReference.current + value;
  };

  const adjustmentRowNumber = ({ input }) => (
    <div className="adjustment-row-number">{input.value}</div>
  );

  adjustmentRowNumber.propTypes = { input: PropTypes.shape({}) };

  const parseAdjustment = (value, item) => {
    const valueNumber = Number(value);

    if (item.todaysAdjustments + valueNumber < 0) {
      return -item.todaysAdjustments;
    }
    if (!valueNumber) {
      return 0;
    }

    return valueNumber;
  };

  const isDisabled = (index) => fields.get(index).product === 'Select product';

  const getFields = () =>
    fields.map((item, index) => (
      <div className="adjustment-row" key={index}>
        <Field
          name={`${item}.product`}
          component={Select}
          className="form-control"
          onChange={(event, value) => {
            setFieldProduct(value, fields.get(index));
          }}
          value="Select product"
        >
          {getOptions()}
        </Field>
        <Field name={`${item}.current`} component={adjustmentRowNumber} className="form-control" />
        <Field
          name={`${item}.todaysAdjustments`}
          component={adjustmentRowNumber}
          className="form-control"
        />
        <Field
          name={`${item}.newAdjustment`}
          component="input"
          type="number"
          parse={(value) => parseAdjustment(value, fields.get(index))}
          className="form-control adjustment-row-number-input"
          onChange={(event, value) => {
            setFieldAdjustment(value, fields.get(index));
          }}
          disabled={isDisabled(index)}
        />
        <Field name={`${item}.newTotal`} component={adjustmentRowNumber} className="form-control" />
        <div className="adjustment-row-actions">
          <div className="adjustment-row-action-container cross" onClick={removeFieldRow(index)}>
            <i className="icon icon-cross" />
          </div>
        </div>
      </div>
    ));

  return (
    <div>
      {getFields()}
      <div className="add-adjustment-row">
        <div className="add-adjustment-row-button" onClick={addFieldRow}>
          + {intl.formatMessage({ id: 'addAdjustment' })}
        </div>
      </div>
    </div>
  );
};

AdjustmentFields.propTypes = {
  intl: PropTypes.shape({}),
  fields: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  adjustments: PropTypes.arrayOf(PropTypes.shape({})),
  combinedCounts: PropTypes.shape({}),
};

export default injectIntl(AdjustmentFields);
