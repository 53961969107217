import { chunk } from 'lodash/array';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

const InfoRows = ({ header, rows, intl }) => {
  const rowContents = Object.keys(rows).map((key) => (
    <div className="col-md item" key={key}>
      <section className="small">{intl.formatMessage({ id: key.toLowerCase() })}</section>
      {rows[key]}
    </div>
  ));

  const formattedRows = chunk(rowContents, 2).map((row, index) => (
    <div className="row" key={index}>
      {row}
    </div>
  ));

  return (
    <div>
      <h3>{header}</h3>
      {formattedRows}
    </div>
  );
};

InfoRows.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  header: PropTypes.string,
  rows: PropTypes.shape({}),
};

export default injectIntl(InfoRows);
