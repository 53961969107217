import { FormikComputedProps, FormikHandlers, FormikState, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmptyValues } from '../../../core/formValidation';
import { getApiStatus } from '../../../core/utils';
import { statusSelector } from '../../../store/authReducer';
import { login as loginAction, statusReset } from '../actions';

export interface FormValues {
  username: string;
  password: string;
}

interface Form {
  formik: FormikHandlers & FormikState<FormValues> & FormikComputedProps<FormValues>;
  handleSubmitClick: () => void;
  isLoading: boolean;
  statusMessage: { type: string; message: string };
  removeStatusMessage: () => void;
}

const useLoginForm = (): Form => {
  const dispatch = useDispatch();
  const statusMessage = useSelector((state) => statusSelector(state));
  const isLoading = useSelector((state) => getApiStatus(state, 'LOGIN'));

  useEffect(() => {
    if (!isLoading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [isLoading]);

  const removeStatusMessage = () => {
    dispatch(statusReset());
  };

  const handleSubmit = (values: FormValues) => {
    dispatch(loginAction({ username: values.username, password: values.password }));
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      username: '',
      password: '',
    },
    validate: checkEmptyValues,
    onSubmit: handleSubmit,
    validateOnMount: true,
    validateOnChange: true,
  });

  const handleSubmitClick = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return {
    formik,
    handleSubmitClick,
    statusMessage,
    isLoading,
    removeStatusMessage,
  };
};

export default useLoginForm;
