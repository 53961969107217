const inventoryMessages = {
  en: {
    onHand: 'On Hand',
    preferredStockLevel: 'Preferred Stock Level',
    installedAt: 'Installed At',
    historyLogs: 'History Logs',
    vendorUrl: 'Vendor URL',
    unitOfMeasure: 'Unit of Measure',
    employeeId: 'Employee ID',
    inventoryData: 'Inventory Data',
    inventoryTypes: 'Inventory Types',
    siteId: 'Site ID',
    ContainerSizes: 'Container Sizes',
    InventoryHistory: 'Inventory History',
    Inventory: 'Inventory',
  },
  es: {
    onHand: 'En mano',
    preferredStockLevel: 'Nivel de stock preferido',
    installedAt: 'Instalado en',
    historyLogs: 'Registros de historial',
    vendorUrl: 'URL del proveedor',
    unitOfMeasure: 'Unidad de medida',
    employeeId: 'ID de empleado',
    inventoryData: 'Datos de inventario',
    inventoryTypes: 'Tipos de inventario',
    siteId: 'ID del sitio',
    ContainerSizes: 'Tamaños de contenedor',
    InventoryHistory: 'Historial de inventario',
    Inventory: 'Inventario',
  },
  fr: {
    onHand: 'En main',
    preferredStockLevel: 'Niveau de stock préféré',
    installedAt: 'Installé à',
    historyLogs: 'Historique des journaux',
    vendorUrl: 'URL du fournisseur',
    unitOfMeasure: 'Unité de mesure',
    employeeId: 'ID employé',
    inventoryData: "Données d'inventaire",
    inventoryTypes: "Types d'inventaire",
    siteId: 'ID du site',
    ContainerSizes: 'Tailles de conteneur',
    InventoryHistory: "Historique d'inventaire",
    Inventory: 'Inventaire',
  },
  de: {
    onHand: 'Auf der Hand',
    preferredStockLevel: 'Bevorzugter Lagerbestand',
    installedAt: 'Installiert am',
    historyLogs: 'Verlauf Protokolle',
    vendorUrl: 'URL des Anbieters',
    unitOfMeasure: 'Maßeinheit',
    employeeId: 'Mitarbeiter-ID',
    inventoryData: 'Inventardaten',
    inventoryTypes: 'Inventartypen',
    siteId: 'Standort-ID',
    ContainerSizes: 'Behältergrößen',
    InventoryHistory: 'Inventarhistorie',
    Inventory: 'Inventar',
  },
  nl: {
    onHand: 'In hand',
    preferredStockLevel: 'Voorraadniveau',
    installedAt: 'Geïnstalleerd op',
    historyLogs: 'Geschiedenislogboeken',
    vendorUrl: 'URL van leverancier',
    unitOfMeasure: 'Eenheid van maat',
    employeeId: 'Werknemer ID',
    inventoryData: 'Inventarisgegevens',
    inventoryTypes: 'Inventaris typen',
    siteId: 'Site ID',
    ContainerSizes: 'Containergroottes',
    InventoryHistory: 'Inventaris geschiedenis',
    Inventory: 'Inventaris',
  },
  it: {
    onHand: 'In mano',
    preferredStockLevel: 'Livello di stock preferito',
    installedAt: 'Installato a',
    historyLogs: 'Log della cronologia',
    vendorUrl: 'URL del fornitore',
    unitOfMeasure: 'Unità di misura',
    employeeId: 'ID dipendente',
    inventoryData: 'Dati di inventario',
    inventoryTypes: 'Tipi di inventario',
    siteId: 'ID sito',
    ContainerSizes: 'Dimensioni del contenitore',
    InventoryHistory: "Cronologia dell'inventario",
    Inventory: 'Inventario',
  },
  pt: {
    onHand: 'Na mão',
    preferredStockLevel: 'Nível de estoque preferido',
    installedAt: 'Instalado em',
    historyLogs: 'Registros de histórico',
    vendorUrl: 'URL do fornecedor',
    unitOfMeasure: 'Unidade de medida',
    employeeId: 'ID do funcionário',
    inventoryData: 'Dados do inventário',
    inventoryTypes: 'Tipos de inventário',
    siteId: 'ID do site',
    ContainerSizes: 'Tamanhos de contêiner',
    InventoryHistory: 'Histórico de inventário',
    Inventory: 'Inventário',
  },
  ja: {
    onHand: '手持ち',
    preferredStockLevel: '希望在庫レベル',
    installedAt: 'インストール日',
    historyLogs: '履歴ログ',
    vendorUrl: 'ベンダーURL',
    unitOfMeasure: '単位',
    employeeId: '従業員ID',
    inventoryData: '在庫データ',
    inventoryTypes: '在庫タイプ',
    siteId: 'サイトID',
    ContainerSizes: 'コンテナサイズ',
    InventoryHistory: '在庫履歴',
    Inventory: '在庫',
  },
};

export default inventoryMessages;
