import { PropTypes } from 'prop-types';
import React from 'react';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import TabsWithContent from '../../../core/components/TabsWithContent';
import DateBasedReadings from './DateBasedReadings';
import DetergentBasedReadings from './DetergentBasedReadings';

export const HistoricalTankReadings = ({ asOfDateProps, ...props }) => {
  const getTabComponents = () => ({
    dateBased: <DateBasedReadings {...props} />,
    detergentBased: <DetergentBasedReadings {...props} />,
  });

  return (
    <React.Fragment>
      <DateRangeSelector
        dates={[asOfDateProps.fromDate, asOfDateProps.untilDate]}
        onSubmit={(dates) => {
          asOfDateProps.setFromDate(dates.from);
          asOfDateProps.setUntilDate(dates.until);
        }}
      />
      <div className="content-container mt-3">
        <TabsWithContent
          tabHeaders={Object.keys(getTabComponents())}
          components={getTabComponents()}
          classes="detergent-override"
        />
      </div>
    </React.Fragment>
  );
};

HistoricalTankReadings.propTypes = {
  asOfDateProps: PropTypes.shape({}).isRequired,
};

export default HistoricalTankReadings;
