import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function TableEditCell(props) {
  const { rowData, mixIns } = props;
  const isDisabled = rowData[mixIns.idField] !== mixIns.inEdit;
  const originalValue = rowData[mixIns.valueField];
  const [inputValue, setInputValue] = useState(originalValue);

  useEffect(() => {
    setInputValue(rowData[mixIns.valueField]);
  }, [rowData]);

  const handleChange = (value) => setInputValue(value);

  const handleCancel = () => {
    setInputValue(originalValue);
    mixIns.onCancel();
  };

  return (
    <span>
      <input
        onChange={(e) => handleChange(e.target.value)}
        value={inputValue}
        disabled={isDisabled}
      />
      <span className={`tec-button-area${isDisabled ? '--hidden' : ''}`}>
        <button
          className="btn btn-primary btn-sm"
          onClick={(e) => mixIns.onApply(e, rowData, inputValue)}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary btn-sm"
          style={{ marginLeft: '8px' }}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </span>
    </span>
  );
}

TableEditCell.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
  mixIns: PropTypes.shape({
    onApply: PropTypes.func,
    inEdit: PropTypes.number,
    idField: PropTypes.string,
    valueField: PropTypes.string,
    onCancel: PropTypes.func,
  }).isRequired,
};
