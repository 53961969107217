import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  fieldRequired,
  missingDays,
  requiredDatePicker,
  requiredInput,
  requiredSelect,
} from '../../../core/formValidation';
import WeeklyRepeatOptions from '../../tasks/components/WeeklyRepeatOptions';
import { createIntervalDescription } from '../helpers';
import { IntervalOptions } from '../models';

const ShiftEditForm = ({ interval, description, employees }) => {
  const startDateText = interval === IntervalOptions.Once ? 'Shift Date' : 'Start';

  const employeeList = employees.map((e) => {
    const fullName = `${e.lastName}, ${e.firstName}`;
    return (
      <option key={e.id} value={e.id}>
        {fullName}
      </option>
    );
  });

  return (
    <div>
      <div className="form-group">
        <label htmlFor="employeeId">Employee</label>
        <Field
          className="form-control"
          name="employeeId"
          id="employeeId"
          component={requiredSelect}
          type="text"
          parse={(value) => value && Number(value, 10)}
          validate={[fieldRequired]}
        >
          <option value="">Choose Name...</option>
          {employeeList}
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="name">Shift Name</label>
        <Field
          className="form-control"
          name="name"
          id="name"
          component={requiredInput}
          type="text"
          placeholder="Enter name for shift"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="interval">Repeat</label>
        <Field
          id="interval"
          name="interval"
          className="form-control"
          component="select"
          parse={(value) => Number(value)}
        >
          <option value={IntervalOptions.Weekly}>Weekly</option>
          <option value={IntervalOptions.Once}>Once</option>
        </Field>
      </div>
      {(() => {
        switch (interval) {
          case IntervalOptions.Weekly:
            return (
              <Field
                name="weeklySchedule"
                component={WeeklyRepeatOptions}
                validate={[missingDays]}
              />
            );
          default:
            return null;
        }
      })()}
      <div className="form-inline mb-3">
        <div className="form-group mr-2">
          <label htmlFor="start" className="mr-1">
            {startDateText}
          </label>
          <Field
            name="start"
            id="start"
            component={requiredDatePicker}
            placeholderText="Select start date"
            showToday
            validate={[fieldRequired]}
          />
        </div>
        {interval !== IntervalOptions.Once && (
          <div className="form-group">
            <label htmlFor="end" className="mr-1">
              End
            </label>
            <Field
              name="end"
              id="end"
              component={requiredDatePicker}
              placeholderText="Select end date"
              showToday={false}
              required
              validate={[fieldRequired]}
            />
          </div>
        )}
      </div>
      <div className="mb-2">
        <strong>{description}</strong>
      </div>
    </div>
  );
};

ShiftEditForm.propTypes = {
  interval: PropTypes.number,
  description: PropTypes.string,
  employees: PropTypes.arrayOf(PropTypes.object),
};

const formSelector = formValueSelector('shift');

const mapStateToProps = (state, ownProps) => {
  const {
    intl: { formatMessage },
  } = ownProps;
  const intervalConfig = formSelector(state, 'interval', 'weeklySchedule', 'start');
  const description = createIntervalDescription(intervalConfig, formatMessage);
  return {
    interval: intervalConfig.interval,
    description,
  };
};

// TODO: add validator
const enhance = compose(injectIntl, connect(mapStateToProps, null), reduxForm({ form: 'shift' }));

export default enhance(ShiftEditForm);
