import { find, result } from 'lodash';
import { UserReportViewType } from './models';

const initialState = {
  reports: {},
};

const handleGetReportSuccess = (state, action) => {
  const { id, embedToken, embedUrl, pages } = action.response;
  const key = action?.meta?.options?.reportName;
  const isMulti = pages.find((page) => page.displayName.includes('Multi'));

  if (state.reports[key]) {
    return state;
  } else {
    return {
      ...state,
      reports: {
        ...state.reports,
        [key]: {
          reportId: id,
          embedToken: embedToken.token,
          embedUrl,
          pages,
          permission: isMulti ? UserReportViewType.Multi : UserReportViewType.Single,
          redirectedPage: pages[0],
        },
      },
    };
  }
};

const handleGetDashboardTilesSuccess = (state, action) => {
  const { embedToken, accessToken, tileEmbedToken, tiles } = action.response;
  const key = action?.meta?.options?.specificReport;

  if (!tiles || !Array.isArray(tiles)) {
    console.error('Tiles are missing or not an array in the action response');
    return state;
  }

  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      [key]: {
        name: key,
        embedToken: embedToken.token,
        accessToken: accessToken,
      },
    },
  };
};

export const azure = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REPORT_SUCCESS':
      return handleGetReportSuccess(state, action);
    case 'SET_PERMISSION':
      return {
        ...state,
        permission: action.payload,
      };
    case 'SET_ANALYTICS_PAGE':
      return {
        ...state,
        redirectedPage: action.payload,
      };
    case 'CLEAR_REPORT':
      return initialState;
    case 'GET_DASHBOARD_TILES_SUCCESS':
      return handleGetDashboardTilesSuccess(state, action);
    default:
      return state;
  }
};

export const selectPages = (state, id) => {
  return state.azure.reports[id]?.pages;
};

export const selectEmbedToken = (state, id) => {
  return state.azure.reports[id]?.embedToken || null;
};

export const selectEmbedUrl = (state, id) => {
  return state.azure.reports[id]?.embedUrl;
};

export const selectReportId = (state, id) => {
  return state.azure.reports[id]?.reportId;
};

export const selectPermission = (state, id) => {
  return state.azure?.reports[id]?.permission;
};

export const selectRedirectedPage = (state, id) => {
  return state.azure?.reports[id]?.redirectedPage;
};

export const selectPowerBiConfig = (state, id) => {
  if (!state.azure.reports[id]) {
    return null;
  }
  return {
    embedToken: state.azure.reports[id].embedToken,
    embedUrl: state.azure.reports[id].embedUrl,
    reportId: state.azure.reports[id].reportId,
  };
};

export const selectInitialPage = (state, id) => {
  return result(
    find(state.azure?.reports[id]?.pages, (page) => page.displayName.includes('Single')),
    'name'
  );
};
