import { isEqual } from 'lodash/lang';
import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showDialog } from '../actions';
import AsOfDateForm from './AsOfDateForm';
import Button from './Button';

const styles = {
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
};

class AsOfDate extends Component {
  static propTypes = {
    fetchWithQuery: PropTypes.func,
    setFromDate: PropTypes.func,
    setUntilDate: PropTypes.func,
    fromDate: PropTypes.shape({}),
    untilDate: PropTypes.shape({}),
    title: PropTypes.string,
    dateTarget: PropTypes.string,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      dateInEdit: false,
      // eslint-disable-next-line
      abortController: new AbortController(),
    };

    this.toggleEditDate = this.toggleEditDate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const datesChanged =
      !isEqual(prevProps.fromDate, this.props.fromDate) ||
      !isEqual(prevProps.untilDate, this.props.untilDate);

    if (datesChanged && this.props.fetchWithQuery) {
      this.state.abortController.abort();
      // eslint-disable-next-line
      this.setState({ abortController: new AbortController() }, () => {
        this.props.fetchWithQuery(this.state.abortController.signal);
      });
    }
  }

  onSubmit(values) {
    const tz = this.context.timeZone;
    const from = moment.tz(values.from, tz).startOf('day');
    const until = moment.tz(values.until, tz).endOf('day');

    this.setDates({ from, until });
    this.toggleEditDate();
  }

  getDesiredDate = (range) => {
    const tz = this.context.timeZone;
    return {
      today: {
        from: moment.tz(tz).startOf('day'),
        until: moment.tz(tz).endOf('day'),
      },
      yesterday: {
        from: moment.tz(tz).subtract(1, 'days').startOf('day'),
        until: moment.tz(tz).subtract(1, 'days').endOf('day'),
      },
      thisWeek: {
        from: moment.tz(tz).weekday(0).startOf('day'),
        until: moment.tz(tz).weekday(6).endOf('day'),
      },
      lastWeek: {
        from: moment.tz(tz).weekday(-7).startOf('day'),
        until: moment.tz(tz).weekday(-1).endOf('day'),
      },
      thisMonth: {
        from: moment.tz(tz).startOf('month'),
        until: moment.tz(tz).endOf('month'),
      },
      lastMonth: {
        from: moment.tz(tz).subtract(1, 'months').startOf('month'),
        until: moment.tz(tz).subtract(1, 'months').endOf('month'),
      },
      yearToDate: { from: moment.tz(tz).startOf('year'), until: moment.tz(tz) },
    }[range];
  };

  setDates = ({ from, until }) => {
    this.props.setFromDate(from);
    this.props.setUntilDate(until);
    window.sessionStorage.setItem(`${this.props.dateTarget}From`, from.format('YYYY-MM-DD'));
    window.sessionStorage.setItem(`${this.props.dateTarget}Until`, until.format('YYYY-MM-DD'));
  };

  toggleEditDate() {
    this.setState((state) => {
      return { dateInEdit: !state.dateInEdit };
    });
  }

  showToday = () => {
    this.setDates(this.getDesiredDate('today'));
  };

  showYesterday = () => {
    this.setDates(this.getDesiredDate('yesterday'));
  };

  showThisWeek = () => {
    this.setDates(this.getDesiredDate('thisWeek'));
  };

  showLastWeek = () => {
    this.setDates(this.getDesiredDate('lastWeek'));
  };

  showThisMonth = () => {
    this.setDates(this.getDesiredDate('thisMonth'));
  };

  showLastMonth = () => {
    this.setDates(this.getDesiredDate('lastMonth'));
  };

  showYearToDate = () => {
    this.setDates(this.getDesiredDate('yearToDate'));
  };

  showCurrentDate() {
    const { fromDate, untilDate, intl } = this.props;
    const period = `${moment(fromDate).format('MM/DD/YYYY')} - ${moment(untilDate).format(
      'MM/DD/YYYY'
    )}`;
    return [
      <div style={styles.dateContainer} key="0">
        <div>{this.props.title}</div>
        <div className="ml-2">{period}</div>
      </div>,
      <div className="mt-2 date-range-buttons" key="1">
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showToday}
        >
          {intl.formatMessage({ id: 'today' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showYesterday}
        >
          {intl.formatMessage({ id: 'yesterday' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showThisWeek}
        >
          {intl.formatMessage({ id: 'thisWeek' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showLastWeek}
        >
          {intl.formatMessage({ id: 'lastWeek' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showThisMonth}
        >
          {intl.formatMessage({ id: 'thisMonth' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showLastMonth}
        >
          {intl.formatMessage({ id: 'lastMonth' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.showYearToDate}
        >
          {intl.formatMessage({ id: 'yearToDate' })}
        </Button>
        <Button
          isEnabled={!this.props.disabled}
          type="button"
          styleName={['button small']}
          onClick={this.toggleEditDate}
        >
          {intl.formatMessage({ id: 'custom' })}
        </Button>
      </div>,
    ];
  }

  render() {
    const { fromDate, untilDate } = this.props;
    const tz = this.context.timeZone;

    return (
      <div className="as-of-date">
        {!this.state.dateInEdit ? (
          this.showCurrentDate()
        ) : (
          <div>
            <AsOfDateForm
              initialValues={{
                from: moment.tz(fromDate, tz),
                until: moment.tz(untilDate, tz),
              }}
              onSubmit={this.onSubmit}
              onCancel={this.toggleEditDate}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showDialog }, dispatch);
};

export default connect(null, mapDispatchToProps)(AsOfDate);
