import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteExternalId, selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection, selectSites } from '../../site-selection/reducer';
import { fetchRevenueBestDaySummary, fetchRevenueSummary } from '../actions';
import {
  selectRevenueBestDaySummary,
  selectRevenueBestDayTotal,
  selectRevenueCurrency,
  selectRevenueSummary,
} from '../reducer';
import { generateToastr } from '../../../core/toastMessages';
import RevenueComparison from './RevenueComparison';
interface Props {
  fromDate: any;
  untilDate: any;
}

const RevenueComparisonKpiDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteExtId);
  const multiSites = useSelector(selectMultiSiteSelection);
  const siteExternalId = useSelector(selectSiteExternalId);
  const revenueData = useSelector(selectRevenueSummary);
  const revenueBestDaySummary = useSelector(selectRevenueBestDaySummary);
  const revenueBestDayTotal = useSelector(selectRevenueBestDayTotal);
  const currency = useSelector(selectRevenueCurrency);
  const permittedSites = useSelector(selectSites);
  const permittedSitesExtId = permittedSites.map((site: { extId: any }) => site.extId);

  const fetchRevenue = () => {
    if (siteId && permittedSitesExtId.includes(siteId)) {
      dispatch(fetchRevenueSummary(props.fromDate, props.untilDate, null, siteId));
      dispatch(
        fetchRevenueBestDaySummary(
          moment(props.fromDate).startOf('year').format('YYYY-MM-DD'),
          moment(props.untilDate).endOf('day').format('YYYY-MM-DD'),
          null,
          siteId
        )
      );
    } else {
      {
        generateToastr('error', 'Error: Unauthorized Site Access', {
          timeOut: 5000,
        });
      }
    }
  };

  useEffect(fetchRevenue, [siteId, props.fromDate, props.untilDate, multiSites]);
  return (
    <RevenueComparison
      chartData={revenueData}
      bestDayChartData={revenueBestDaySummary}
      RevenueBestDay={revenueBestDayTotal}
      fromDate={props.fromDate}
      untilDate={props.untilDate}
      siteId={siteExternalId}
      currency={currency}
    />
  );
};

export default RevenueComparisonKpiDataGetter;
