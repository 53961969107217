import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IntlType } from '../../../../../utils/typing';
import { UserGroupPermission } from '../../../../employees/models';
import ConfirmButton from '../ConfirmButton';

interface Props {
  intl: IntlType;
  permission: UserGroupPermission;
  toggle: (permission: UserGroupPermission) => void;
}

const PermissionModal = ({ intl, permission, toggle }: Props): JSX.Element => {
  return (
    <Modal toggle={() => toggle(permission)} fade={false} isOpen centered>
      <ModalHeader toggle={() => toggle(permission)}>{permission.displayName}</ModalHeader>
      <ModalBody>{permission.description}</ModalBody>
      <ModalFooter>
        <ConfirmButton intl={intl} handleClick={() => toggle(permission)} />
      </ModalFooter>
    </Modal>
  );
};

export default PermissionModal;
