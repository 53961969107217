import * as api from '../api';

export const getEssentialTasks = (args) => {
  const {
    options: { groupId },
  } = args;

  if (!groupId) {
    return {
      results: [],
      page: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return api.callGet(`/manager/groups/${groupId}/essential-tasks`, args);
};

export const patchEssentialTask = (args) => {
  const {
    options: { groupId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/groups/${groupId}/essential-tasks/${id}`, args);
};

export const postEssentialTask = (args) => {
  const { groupId } = args.options;
  return api.callPost(`/manager/groups/${groupId}/essential-tasks`, args);
};

export const getEssentialTask = (args) => {
  const {
    options: { groupId },
    data: { id },
  } = args;
  return api.callGet(`/manager/groups/${groupId}/essential-tasks/${id}`, args);
};

export const archiveEssentialTask = (args) => {
  const {
    options: { groupId },
    data: { id },
  } = args;
  return api.callPost(`/manager/groups/${groupId}/essential-tasks/${id}/archive`, {
    options: args.options,
  });
};
