import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { selectEmployees } from '../../employees/reducer';
import { addTaskCompletion } from '../../overview/actions';
import { clearTaskSelections } from '../actions';
import TaskCompleteForm from '../components/TaskCompleteForm';

class CompleteTaskDialog extends Component {
  static propTypes = {
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onReady: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(PropTypes.shape({})),
    clearTaskSelections: PropTypes.func.isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('taskComplete');
  };

  onSubmit = (values) => {
    /* eslint-disable */
    const { employeeId } = values;

    const data = {
      taskIds: this.props.taskCompletion.taskIds,
      notes: this.props.taskCompletion.notes,
      employeeId,
      callBack: this.props.taskCompletion.callBack,
    };

    this.props.clearTaskSelections();
    this.props.onReady(data);
  };

  render() {
    const { closeDialog, isOpen, taskCompletion, employees } = this.props;
    const initialValues = { employeeId: employees[0] && employees[0].id };
    return (
      <ModalDialog
        title="Complete Multiple Tasks"
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
      >
        <TaskCompleteForm
          onSubmit={this.onSubmit}
          enableReinitialize
          employees={employees}
          task={taskCompletion}
          initialValues={initialValues}
        />
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: selectEmployees(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTaskCompletion,
      closeDialog: hideDialog,
      triggerSubmit: submit,
      clearTaskSelections,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTaskDialog);
