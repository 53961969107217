import React, { useState } from 'react';
import Papa from 'papaparse';
import DataTable from './DataTable';

const CsvUploader = (props) => {
  const [csvData, setCsvData] = useState(null);
  const [valid, setValid] = useState(false);

  // Handle file selection
  const handleFileChange = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    Papa.parse(e.target.files[0], {
      header: true,
      complete: (result) => {
        setCsvData(result.data);
        checkHeaders(result.data);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
        setValid(false);
      },
    });
  };

  // check that each header has the correct properties
  const checkHeaders = (data) => {
    let correctProps = [
      'preferredStockLevel',
      'inventoryTypeId',
      'siteId',
      'groupId',
      'priority',
      'description',
      'number',
      'name',
    ];
    let headers = Object.keys(data[0] || {}).map((header) => ({ name: header }));
    let validRow = headers.every(({ name }) => correctProps.includes(name));
    if (validRow) {
      setValid(true);
    }
  };

  const initializeData = () => {
    const headers = Object.keys(csvData[0] || {}).map((header) => ({ name: header }));
    const data = csvData.map((row) => {
      return {
        ...row,
      };
    });
    return { headers, data, scrolling: true };
  };

  return (
    <div className="parts-file-upload m-3">
      <div className="file-submit">
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button
          className="button confirm"
          disabled={!valid}
          onClick={() => props.onSubmit(csvData)}
        >
          {props.intl.formatMessage({ id: 'uploadCSV' })}
        </button>
      </div>
      {csvData && valid && <DataTable {...initializeData()} />}
      {csvData && !valid && (
        <div className="error">{props.intl.formatMessage({ id: 'invalidCSV' })}</div>
      )}
    </div>
  );
};

export default CsvUploader;
