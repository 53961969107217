import { find } from 'lodash';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import {
  selectAnalyticsEnabled,
  selectPermissions,
  selectUser,
  selectUserGroup,
} from '../store/authReducer';
import * as api from './api';
import Loader from './components/Loader';
import { RoleTypes } from './components/Permission';
import { findPermissionRef, viewPermissionRefs } from './components/UserGroupPermissions';
import { REFRESH_TOKEN_HEADER, TOKEN_HEADER } from './constants';

export const login = (user) => {
  return api.callPost('/login', { data: user });
};

export const refresh = ({ jwtToken, refreshToken }) => {
  const options = {
    headers: {
      [TOKEN_HEADER]: jwtToken,
      [REFRESH_TOKEN_HEADER]: refreshToken,
    },
  };
  return api.callGet('/refresh', { options });
};

export const getSelf = ({ jwtToken, refreshToken }) => {
  const options = {
    headers: {
      [TOKEN_HEADER]: jwtToken,
      [REFRESH_TOKEN_HEADER]: refreshToken,
    },
  };
  return api.callGet('/users/self', { options });
};

export const hasAllAccess = (userGroup) => {
  return (
    find(userGroup.permissions, ['ref', viewPermissionRefs.admin]) ||
    RoleTypes[userGroup.role] >= RoleTypes.OperatorGroupAdmin
  );
};

export const userIsAdmin = connectedRouterRedirect({
  authenticatedSelector: (state) => !!hasAllAccess(selectUserGroup(state)),
  wrapperDisplayName: 'UserHasAdmin',
  redirectPath: 'overview',
});

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  authenticatedSelector: (state) => !!selectUser(state),
  wrapperDisplayName: 'UserIsAuthenticated',
  authenticatingSelector: (state) => state.auth.loadingSelf,
  AuthenticatingComponent: Loader,
  redirectPath: '/login',
});

export const userHasAnalyticsEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.analytics) ||
    selectAnalyticsEnabled(state) !== false,
  wrapperDisplayName: 'UserHasAnalytics',
  redirectPath: 'overview',
});

export const userHasExpenseEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.expense) ||
    !!selectPermissions(state).viewUtilsTab ||
    !!selectPermissions(state).viewPurchaseOrdersTab,
  wrapperDisplayName: 'UserHasExpenses',
  redirectPath: 'overview',
});

export const userHasWashCountsEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.wash_count) ||
    !!selectPermissions(state).viewWashCountsPage,
  wrapperDisplayName: 'UserHasWashCounts',
  redirectPath: 'overview',
});

export const userHasDetergentsEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.detergent),
  wrapperDisplayName: 'UserHasDetergents',
  redirectPath: 'overview',
});

export const userHasTasksEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.task),
  wrapperDisplayName: 'UserHasTasks',
  redirectPath: 'overview',
});

export const userHasWarrantyEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.warranty),
  wrapperDisplayName: 'UserHasWarranty',
  redirectPath: 'overview',
});

export const userHasInventoryEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.inventory),
  wrapperDisplayName: 'UserHasInventory',
  redirectPath: 'overview',
});

export const userHasFeedbackEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.feedback),
  wrapperDisplayName: 'UserHasFeedback',
  redirectPath: 'overview',
});

export const userHasTeamEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.team),
  wrapperDisplayName: 'UserHasTeam',
  redirectPath: 'overview',
});

export const userHasRewashEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.rewash),
  wrapperDisplayName: 'UserHasRewash',
  redirectPath: 'overview',
});

export const userHasSettingsEnabledRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!hasAllAccess(selectUserGroup(state)) ||
    !!findPermissionRef(selectUserGroup(state).permissions, viewPermissionRefs.settings),
  wrapperDisplayName: 'UserHasSettings',
  redirectPath: 'overview',
});
