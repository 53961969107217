import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { toString } from '../../../core/utils';
import TaskFilters from '../components/TaskFilters';
import { TaskType } from '../models';

const TaskTypeFilter = (props) => {
  const { intl, selectedFilter, setFilter } = props;

  const getTaskTypeString = (type) => {
    return toString(TaskType, type);
  };

  const taskTypes = [
    {
      type: getTaskTypeString(TaskType.Maintenance),
      message: intl.formatMessage({ id: 'maintenance' }),
    },
    { type: getTaskTypeString(TaskType.Project), message: intl.formatMessage({ id: 'project' }) },
    {
      type: getTaskTypeString(TaskType.OpenChecklist),
      message: intl.formatMessage({ id: 'openChecklist' }),
    },
    {
      type: getTaskTypeString(TaskType.ClosingChecklist),
      message: intl.formatMessage({ id: 'closingChecklist' }),
    },
    {
      type: getTaskTypeString(TaskType.PreventativeMaintenance),
      message: intl.formatMessage({ id: 'preventativeMaintenance' }),
    },
    {
      type: getTaskTypeString(TaskType.CleaningList),
      message: intl.formatMessage({ id: 'cleaningList' }),
    },
    {
      type: getTaskTypeString(TaskType.HourlyInspection),
      message: intl.formatMessage({ id: 'hourlyInspection' }),
    },
    {
      type: getTaskTypeString(TaskType.ExpressInterior),
      message: intl.formatMessage({ id: 'expressInterior' }),
    },
  ];

  return (
    <TaskFilters
      selectedFilter={selectedFilter}
      options={taskTypes}
      label={intl.formatMessage({ id: 'taskType' })}
      setFilter={setFilter}
    />
  );
};

TaskTypeFilter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  selectedFilter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};

const enhance = compose(injectIntl);

export default enhance(memo(TaskTypeFilter, isEqual));
