import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import { hideDialog } from '../../../../core/actions';
import '../../../../styles/user-dialog.scss';
import {
  setArchiveEmployeeStatus,
  setEditEmployeeStatus,
  setUnarchiveEmployeeStatus,
} from '../../../employees/actions';
import Employee from '../../../employees/models';
import {
  selectArchiveEmployeeStatus,
  selectEditEmployeeStatus,
  selectUnarchiveEmployeeStatus,
} from '../../../employees/reducer';
import useFormTimeout from '../../hooks/useFormTimeout';
import UserFormSelector from '../UserFormSelector';
import EditUserButton from '../UserFormSelector/EditUserButton';
import ActionCompletedSuccessfully from './ActionCompletedSuccessfully';
import useEditForm from './hooks/useEditForm';

interface Props {
  employee: Employee;
}

const EditUserDialog = ({ employee }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const formProps = useEditForm(employee);
  const intl = useIntl();

  const userEditStatus = useSelector(selectEditEmployeeStatus);
  const archiveUserStatus = useSelector(selectArchiveEmployeeStatus);
  const unarchiveUserStatus = useSelector(selectUnarchiveEmployeeStatus);

  const closeDialog = () => {
    dispatch(hideDialog());
    dispatch(setEditEmployeeStatus('idle'));
    dispatch(setArchiveEmployeeStatus('idle'));
    dispatch(setUnarchiveEmployeeStatus('idle'));
  };

  useFormTimeout(closeDialog, [userEditStatus, archiveUserStatus, unarchiveUserStatus]);

  const { formik, handleSubmitClick } = formProps;
  const creationDisabled = !formik.isValid || formik.isSubmitting;

  return (
    <Modal contentClassName="user-modal-content" toggle={closeDialog} size="xl" isOpen>
      <ModalHeader toggle={closeDialog}>
        {intl.formatMessage({ id: 'editUser' })} <span>ABC</span>
      </ModalHeader>

      {archiveUserStatus === 'success' && (
        <ActionCompletedSuccessfully text={intl.formatMessage({ id: 'userArchived' })} />
      )}

      {unarchiveUserStatus === 'success' && (
        <ActionCompletedSuccessfully text={intl.formatMessage({ id: 'userUnarchived' })} />
      )}

      {userEditStatus === 'success' && (
        <ActionCompletedSuccessfully text={intl.formatMessage({ id: 'userEdited' })} />
      )}

      {archiveUserStatus === 'idle' &&
        unarchiveUserStatus === 'idle' &&
        userEditStatus === 'idle' && (
          <span>
            {employee.archivedAt && (
              <div className="badge-container">
                <div className="badge bg-red">{intl.formatMessage({ id: 'archived' })}</div>
              </div>
            )}

            <UserFormSelector
              intl={intl}
              formProps={formProps}
              submitButton={
                <EditUserButton
                  intl={intl}
                  handleClick={handleSubmitClick}
                  disabled={creationDisabled}
                />
              }
            />
          </span>
        )}
    </Modal>
  );
};

export default EditUserDialog;
