import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import ContentTabs from '../../../core/components/ContentTabs';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import Photos from '../../../core/components/Photos';
import {
  deletePermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import {
  addFile,
  clearPurchaseOrderInEdit,
  deleteFile,
  deletePurchaseOrder,
  fetchPurchaseOrder,
  receivePurchaseOrder,
  unreceivePurchaseOrder,
} from '../../expenses/actions';
import { selectPurchaseOrderInEdit } from '../../expenses/purchaseOrderReducer';
import Items from '../components/Items';
import ExpenseDetailHeader from './ExpenseDetailHeader';

const ExpenseDetails = (props) => {
  const {
    intl,
    getPurchaseOrder,
    clearPurchaseOrder,
    purchaseOrder,
    receiveOrder,
    unreceiveOrder,
    deleteOrder,
    showDialogs,
    addImage,
    deleteImage,
  } = props;

  const [components, setComponents] = useState({
    Memo: <React.Fragment />,
    Items: <React.Fragment />,
    Photos: <React.Fragment />,
  });
  const [buttons, setButtons] = useState([]);
  const mounted = useRef(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const id = params.expense;
    if (id) {
      getPurchaseOrder(id);
    }

    return () => {
      clearPurchaseOrder();
    };
  }, [getPurchaseOrder, params.expense]);

  useEffect(() => {
    if (mounted.current && !purchaseOrder) {
      const siteId = params.siteId;
      history.push(`/${siteId}/expenses`);
    }

    if (purchaseOrder) {
      const receiveHandleClick = () => {
        if (purchaseOrder.receivedAt) {
          unreceiveOrder(purchaseOrder.id, () => getPurchaseOrder(purchaseOrder.id));
        } else {
          const receivedProps = {
            onReady: () => {
              receiveOrder(purchaseOrder.id, () => getPurchaseOrder(purchaseOrder.id));
            },
            text: `Are you sure sure you would like to mark this order as received: "${purchaseOrder.orderNumber}"`,
            title: 'Confirm order has been received',
          };
          showDialogs('CONFIRM_DIALOG', receivedProps);
        }
      };

      const getFiles = () => {
        const files = props.purchaseOrder.files;
        return files ? files.filter((file) => !file.archivedAt) : [];
      };

      const _addImage = (formData) => {
        const data = {
          siteId: purchaseOrder.siteId,
          purchaseOrderId: purchaseOrder.id,
          file: formData,
        };

        addImage(data);
      };

      const editHandleClick = () => {
        showDialogs('ADD_PURCHASE_ORDER', {
          isNew: false,
          title: 'Edit Purchase Order',
        });
      };

      const deleteHandleClick = () => {
        if (purchaseOrder && purchaseOrder.receivedAt) {
          const receivedProps = {
            text: `This order has already been received: "${purchaseOrder.orderNumber}"`,
            title: 'Unable to Archive Purchase Order',
          };
          showDialogs('CONFIRM_DIALOG', receivedProps);
        } else {
          const confirmProps = {
            onReady: () => {
              deleteOrder(purchaseOrder.id);
              clearPurchaseOrder();
            },
            text: `Are you sure you want to archive purchase order: "${purchaseOrder.orderNumber}"`,
            title: 'Archive Purchase Order',
          };
          showDialogs('CONFIRM_DIALOG', confirmProps);
        }
      };

      const _deleteImage = (file) => {
        const confirmProps = {
          onReady: () =>
            deleteImage({
              fileId: file.id,
              purchaseOrderId: purchaseOrder.id,
            }),
          text: intl.formatMessage({ id: 'confirmFileArchive' }, { fileName: file.originalName }),
          title: intl.formatMessage({ id: 'archiveFile' }),
        };
        props.showDialogs('CONFIRM_DIALOG', confirmProps);
      };

      setComponents({
        Memo: <div>{purchaseOrder && purchaseOrder.memo}</div>,
        Items: <Items data={purchaseOrder ? purchaseOrder.items : []} />,
        Photos: <Photos photos={getFiles()} addFile={_addImage} deleteFile={_deleteImage} />,
      });

      setButtons([
        {
          onClick: () => deleteHandleClick(),
          label: intl.formatMessage({ id: 'delete' }),
          class: 'button primary inverted mr-2',
          permissionKey: deletePermissionRefs.expense,
        },
        {
          onClick: () => editHandleClick(),
          label: intl.formatMessage({ id: 'edit' }),
          class: 'button mr-2',
          permissionKey: editPermissionRefs.expense,
        },
        {
          onClick: () => receiveHandleClick(),
          label: intl.formatMessage({
            id: purchaseOrder && purchaseOrder.receivedAt ? 'return' : 'receive',
          }),
          class: 'button confirm',
          permissionKey: editPermissionRefs.expense,
        },
      ]);
    }

    if (!mounted.current) {
      mounted.current = true;
    }
  }, [purchaseOrder, setComponents, setButtons]);

  return (
    <div className="container-fluid">
      <section className="page-main-content">
        <DetailButtonGroup history={history} intl={intl} buttons={buttons} />
        {purchaseOrder && purchaseOrder.archivedAt && (
          <span className="badge badge-warning mb-2">{intl.formatMessage({ id: 'archived' })}</span>
        )}
        {purchaseOrder && <ExpenseDetailHeader purchaseOrder={purchaseOrder} />}
        <ContentTabs components={components} />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  purchaseOrder: selectPurchaseOrderInEdit(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPurchaseOrder: fetchPurchaseOrder,
      clearPurchaseOrder: clearPurchaseOrderInEdit,
      receiveOrder: receivePurchaseOrder,
      unreceiveOrder: unreceivePurchaseOrder,
      deleteOrder: deletePurchaseOrder,
      showDialogs: showDialog,
      deleteImage: deleteFile,
      addImage: addFile,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

ExpenseDetails.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    location: PropTypes.any,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      siteId: PropTypes.string,
      expense: PropTypes.string,
    }),
  }),
  getPurchaseOrder: PropTypes.func.isRequired,
  clearPurchaseOrder: PropTypes.func.isRequired,
  purchaseOrder: PropTypes.shape({}),
  receiveOrder: PropTypes.func.isRequired,
  unreceiveOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  showDialogs: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
};

export default enhance(ExpenseDetails);
