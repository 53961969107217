import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

const ChangeLogMenu = (props) => {
  const [offset, setOffset] = useState(0);
  const menu = useRef(null);
  const cardWidth = 458;

  useEffect(() => {
    const currentX = menu.current.getBoundingClientRect().x;
    const screenSize = window.innerWidth;
    if (cardWidth + currentX > screenSize) {
      const offsetValue = screenSize - (cardWidth + currentX);
      if (currentX + offsetValue > 0) {
        setOffset((prevValue) => prevValue + offsetValue);
      }
    }
  }, [menu.current, offset]);

  return (
    <div ref={menu} style={{ left: `${offset}px` }} className="drs-card fm-changeLogMenu">
      <div className="card-header">
        <h4>{props.intl.formatMessage({ id: 'currentChanges' })}</h4>
      </div>
      <div className="card-body fm-changeLogMenuBody">
        <table className="fm-margin-auto">
          <tbody>{props.children}</tbody>
        </table>
      </div>
      <div className="card-footer" />
    </div>
  );
};

ChangeLogMenu.propTypes = {
  children: PropTypes.node,
  intl: PropTypes.shape({}).isRequired,
};

const enhance = compose(injectIntl);

export default enhance(ChangeLogMenu);
