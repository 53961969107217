import { FormikComputedProps, FormikHandlers, FormikState, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmptyValues, emailValidation } from '../../../core/formValidation';
import { getApiStatus } from '../../../core/utils';
import { statusSelector } from '../../../store/authReducer';
import { forgotPassword, FORGOT_PASSWORD_REQUEST, statusError, statusReset } from '../actions';

export interface FormValues {
  username: string;
}

interface Form {
  formik: FormikHandlers & FormikState<FormValues> & FormikComputedProps<FormValues>;
  handleSubmitClick: () => void;
  statusMessage: { type: string; message: string };
  removeErrorMessage: () => void;
}

const useForgotPasswordForm = (): Form => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => getApiStatus(state, FORGOT_PASSWORD_REQUEST));
  const statusMessage = useSelector((state) => statusSelector(state));

  useEffect(() => {
    if (!isLoading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [isLoading]);

  const removeErrorMessage = () => {
    dispatch(statusReset());
  };

  const handleSubmit = (values: FormValues) => {
    if (emailValidation(values.username)) {
      dispatch(forgotPassword(values.username));
    } else {
      dispatch(statusError('invalidEmail'));
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      username: '',
    },
    validate: checkEmptyValues,
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnChange: true,
  });

  const handleSubmitClick = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return {
    formik,
    handleSubmitClick,
    statusMessage,
    removeErrorMessage,
  };
};

export default useForgotPasswordForm;
