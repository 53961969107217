import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';

const EditorBlockButton = (props) => {
  const toggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  return (
    <div
      className={classNames('toolbar-button', 'toolbar-block-button')}
      value={props.active}
      onClick={toggle}
    >
      <i title={props.title} className={props.icon} />
    </div>
  );
};

EditorBlockButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  style: PropTypes.string,
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default EditorBlockButton;
