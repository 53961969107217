import { models } from 'powerbi-client';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Embed from './Embed';

const createConfig = ({ accessToken, embedUrl, embedId, pageName, layoutType }) => ({
  type: 'report',
  tokenType: models.TokenType.Embed,
  accessToken,
  embedUrl,
  id: embedId,
  pageName,
  permissions: models.Permissions.Read,
  settings: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    layoutType: layoutType ? layoutType : models.LayoutType.Master,
    hideErrors: true,
  },
});

export class Report extends PureComponent {
  static getDerivedStateFromProps(props) {
    return { currentConfig: createConfig(props) };
  }

  constructor(props) {
    super(props);
    this.state = {
      currentConfig: null,
    };
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  updateState = (props) => {
    this.setState({
      currentConfig: createConfig(props),
    });
  };

  render() {
    return <Embed config={this.state.currentConfig} performOnEmbed={this.props.onLoad} />;
  }
}

Report.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Report;
