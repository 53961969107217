import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';

class SuggestedItemPicker extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      onChange: PropTypes.func.isRequired,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    intl: PropTypes.shape({}).isRequired,
    renderSuggestion: PropTypes.func,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
    clearAfterItemSelected: PropTypes.bool,
  };

  static defaultProps = {
    clearAfterItemSelected: false,
  };

  constructor(props) {
    super(props);

    const selectedItem =
      props.items.length > 0 && props.items.find((item) => item.id === props.input.value);

    this.state = {
      value: selectedItem ? selectedItem.name : '',
      suggestions: props.items,
    };
  }

  onSuggestionSelected = (event, selected) => {
    if (this.props.clearAfterItemSelected) {
      this.setState({ value: '' }, () => {
        this.props.input.onChange(selected.suggestion.id);
      });
    } else {
      this.setState({ value: selected.suggestion.name }, () => {
        this.props.input.onChange(selected.suggestion.id);
      });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  // eslint-disable-next-line
  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  onBlur = () => {
    const selectedItem =
      this.props.items.length > 0 &&
      this.props.items.find((item) => item.id === this.props.input.value);
    const value = selectedItem ? selectedItem.name : '';
    const emptyValue = this.state.value === '';

    if (!emptyValue) {
      this.setState({ value });
    } else {
      this.props.input.onChange(null);
    }
  };

  getFuzzyMatches = (input) => (object) => {
    const tokens = object.name.split('');
    let searchPosition = 0;

    for (const token of tokens) {
      if (token.toLowerCase() === input[searchPosition]) {
        searchPosition += 1;
        if (searchPosition >= input.length) {
          break;
        }
      }
    }

    if (searchPosition !== input.length) {
      return {};
    }

    return { ...object, name: tokens.join('') };
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();

    return this.props.items.map(this.getFuzzyMatches(inputValue)).filter((result) => result.name);
  };

  getSuggestionValue = (suggestion) => suggestion.name;

  defaultRenderSuggestion = (suggestion) => (
    <div className="suggestion">
      <span>{suggestion.name}</span>
    </div>
  );

  renderSuggestion = (suggestion) => {
    if (this.props.renderSuggestion) {
      return this.props.renderSuggestion(suggestion);
    }
    return this.defaultRenderSuggestion(suggestion);
  };

  renderInputComponent = (inputProps) => <input {...inputProps} className="form-control" />;

  render() {
    const inputProps = {
      placeholder: this.props.intl.formatMessage({
        id: this.props.placeholder,
      }),
      value: this.state.value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        shouldRenderSuggestions={() => true}
        theme={{
          container: this.props.containerClassName || '',
          suggestionsContainer: 'suggestions-container',
          suggestionsList: 'suggestions-list',
        }}
      />
    );
  }
}

export default injectIntl(SuggestedItemPicker);
