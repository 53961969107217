import { omit } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApiStatus } from '../../../../../core/utils';
import { saveEmployee, SAVE_EMPLOYEE } from '../../../../employees/actions';
import Employee from '../../../../employees/models';
import useForm, { Form, FormValues, userFields } from '../../../hooks/useForm';

type EmployeeFields = Exclude<FormValues, 'userGroupId' | 'coreSites'>;

const useEditForm = (employee: Employee): Form & { isNew: boolean; employee?: Employee } => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => getApiStatus(state, SAVE_EMPLOYEE));

  const getDefaultValues = (): FormValues => {
    let loginCredentialsNeeded = false;

    if (employee.coreUser) {
      loginCredentialsNeeded = !employee.coreUser?.archivedAt;
    }

    return {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      position: employee.position,
      loginCredentialsNeeded: loginCredentialsNeeded,
      isCashier: employee.isCashier,
      userGroupId: employee.coreUser?.userGroupId || null,
      coreSites: employee.coreUser?.sites || [],
    };
  };

  const formatBody = (values: FormValues): FormValues | EmployeeFields => {
    if (!values.loginCredentialsNeeded) {
      return omit(values, userFields) as EmployeeFields;
    }

    return values;
  };

  const handleSubmit = (values: FormValues) => {
    const patchBody = {
      data: {
        body: {
          id: employee.id,
          ...formatBody(values),
        },
      },
      closeModal: false,
    };

    dispatch(saveEmployee(patchBody));
  };

  const formProps = useForm({
    isLoading,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Setting values after initialization
    // so that validation works properly
    formProps.formik.setValues(getDefaultValues());
  }, []);

  return {
    ...formProps,
    employee,
    isNew: false,
  };
};

export default useEditForm;
