import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { TextArea } from '../../../core/formValidation';
import { selectSiteTimeZone } from '../../settings/reducer';
import AdjustmentFields from './AdjustmentFields';

const AdjustWashCountForm = ({ intl, formData }) => {
  const getHeaders = () =>
    ['product', 'current', 'todaysAdjustments', 'newAdjustment', 'newTotal'].map((id) => (
      <div className="adjustment-rows-header" key={id}>
        {intl.formatMessage({ id })}
      </div>
    ));

  return (
    <div className="adjust-wash-count-form-grid">
      <div className="grid-cell adjustment-rows-grid">
        <div className="adjustment-rows-headers-grid">{getHeaders()}</div>
        <FieldArray
          name="adjustmentField"
          component={(props) => <AdjustmentFields {...props} {...formData} />}
        />
      </div>
      <div className="grid-cell adjustment-notes">
        <label htmlFor="notes">{intl.formatMessage({ id: 'notes' })}</label>
        <Field
          className="form-control"
          name="notes"
          id="notes"
          component={TextArea}
          type="text"
          aria-describedby="descriptionHelp"
          placeholder={intl.formatMessage({ id: 'addNotes' })}
        />
      </div>
    </div>
  );
};

AdjustWashCountForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({ timeZone: selectSiteTimeZone(state) });

const enhance = compose(
  connect(mapStateToProps, null),
  reduxForm({ form: 'adjustPOSCount' }),
  injectIntl
);

export default enhance(AdjustWashCountForm);
