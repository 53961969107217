import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setAndStoreSelectedSite, setPrimarySite, updateTimeZone } from '../actions';
import { Site } from '../models';
import { selectSites } from '../reducer';

export const DefaultSiteList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);
  const history = useHistory();

  const onSiteSelect = (site: Site) => {
    dispatch(updateTimeZone({ settings: site.settings }));
    dispatch(setAndStoreSelectedSite({ id: site.id }));
    dispatch(setPrimarySite(site));
    history.push(`/${site.id}/overview`);
  };
  const listItems = sites?.map((i: Site) => {
    const classes = `list-group-item list-group-item-action`;
    const listName = i.externalId ? `${i.name} - ${i.externalId}` : i.name;

    return (
      <button
        key={i.id}
        style={{ cursor: 'pointer' }}
        className={classes}
        onClick={() => onSiteSelect(i)}
        data-testid="site-list-item"
      >
        {listName}
      </button>
    );
  }) || [
    <div key="noSite" className="list-group-item">
      {intl.formatMessage({ id: 'noSites' })}
    </div>,
  ];

  return <div className="default-site-selection list-group">{listItems}</div>;
};

export default DefaultSiteList;
