import { map } from 'lodash/collection';
import { sum } from 'lodash/math';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { spacesToCamelCaseString } from '../../../core/utils';
import { formatAmount } from '../../../utils/money-util';
import { addRepairItem, deleteRepairItem } from '../actions';

const Repair = (props) => {
  const { intl, showDialogMenu, addRepairItems, deleteRepairItems } = props;
  const _addRepairItem = (data) => {
    addRepairItems({
      reclamationId: props.reclamation.id,
      ...data,
    });
  };

  const _deleteRepairItem = (event, data) => {
    deleteRepairItems({
      reclamationId: props.reclamation.id,
      repairItemId: data._id,
    });
  };

  const getPageActions = () => {
    const text = intl.formatMessage({ id: 'addRepairData' });
    const carPartId = props.reclamation.claimDamagePartId;
    const modalProps = {
      text,
      title: text,
      onSubmit: _addRepairItem,
      carPartId,
    };

    return {
      modalProps,
      actionFunction: showDialogMenu,
      actions: [
        {
          dialogType: 'REPAIR_DATA_DIALOG',
          dialogProps: modalProps,
          text,
          permissionKey: addPermissionRefs.warranty,
        },
      ],
    };
  };

  const getDataTableHeaders = () => {
    const headers = [
      'carPart',
      'type',
      'name',
      'vendor',
      'description',
      'price',
      'quantity',
      'total',
    ].map((id) => ({ name: intl.formatMessage({ id }) }));

    headers.push({
      name: props.intl.formatMessage({ id: 'action' }),
      cell: {
        component: TableActionButtonsCell,
        mixIns: {
          buttons: [{ icon: 'icon-trash', buttonAction: _deleteRepairItem }],
        },
      },
    });

    return headers;
  };

  const toDataTable = (part) => ({
    _id: part.id,
    _total: Number(part.total),
    carPart: spacesToCamelCaseString(part.carPart),
    type: part.type,
    name: part.name,
    vendor: part.vendor,
    description: part.description,
    price: formatAmount(part.price, part.currency, true),
    quantity: part.quantity,
    totalWithCurrency: formatAmount(part.total, part.currency, true),
  });

  const initializeDatatable = () => {
    const repairItems =
      props.reclamation && props.reclamation.repairItems ? props.reclamation.repairItems : [];

    const data = repairItems.map(toDataTable);
    const totalSummary = sum(map(data, '_total'));

    return {
      data,
      headers: getDataTableHeaders(),
      summary:
        data.length > 0 ? [{ index: 7, value: formatAmount(totalSummary, 'usd', true) }] : [],
    };
  };

  return (
    <div className="repair-content">
      <PageActions {...getPageActions()} />
      <DataTable {...initializeDatatable()} />
    </div>
  );
};

Repair.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  showDialogMenu: PropTypes.func.isRequired,
  addRepairItems: PropTypes.func.isRequired,
  deleteRepairItems: PropTypes.func.isRequired,
  reclamation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    repairItems: PropTypes.arrayOf(PropTypes.shape({})),
    claimDamagePartId: PropTypes.number.isRequired,
  }),
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDialogMenu: showDialog,
      addRepairItems: addRepairItem,
      deleteRepairItems: deleteRepairItem,
    },
    dispatch
  );

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(Repair);
