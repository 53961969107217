import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

/**
 * TaskModal component to display a modal with a title, children, and buttons to submit or cancel.
 *
 * @param {*} props
 * @return {*} JSX.Element
 */
const TaskModal = (props) => {
  const {
    children,
    title,
    isNew,
    isOpen,
    close,
    onReady,
    onReset,
    className,
    disabled,
    showFooter,
    onReadyParams,
  } = props;

  const intl = useIntl();
  const deleteMessage = isNew
    ? intl.formatMessage({ id: 'clearData' })
    : intl.formatMessage({ id: 'cancel' });
  const submitMessage = isNew
    ? intl.formatMessage({ id: 'addNewTask' })
    : intl.formatMessage({ id: 'saveTask' });

  const onReadyWithParams = () => {
    onReady(...onReadyParams);
  };
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader className="modal-title" toggle={close}>
        {title}
      </ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter>
          <Button
            className="submit-button delete"
            color="secondary"
            onClick={onReset}
            disabled={disabled}
          >
            <i className="icon icon-trash"></i> {deleteMessage}
          </Button>
          {onReady && !onReadyParams ? (
            <Button
              className="submit-button confirm"
              color="primary"
              onClick={onReady}
              disabled={disabled}
            >
              {submitMessage}
            </Button>
          ) : onReady && onReadyParams ? (
            <Button
              color="primary"
              className="submit-button confirm"
              onClick={onReadyWithParams}
              disabled={disabled}
            >
              {submitMessage}
            </Button>
          ) : null}
        </ModalFooter>
      )}
    </Modal>
  );
};

TaskModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onReady: PropTypes.func,
  onReset: PropTypes.func,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  disabled: PropTypes.bool,
  showFooter: PropTypes.bool,
  onReadyParams: PropTypes.arrayOf(PropTypes.any),
};

TaskModal.defaultProps = {
  showFooter: true,
};

export default TaskModal;
