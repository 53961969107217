import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { fieldRequired, requiredInput } from '../../../core/formValidation';

const EmployeeEditForm = ({ intl, ...props }: any) => {
  return (
    <div data-testid="edit-form">
      <div className="form-group">
        <label htmlFor="firstName">{intl.formatMessage({ id: 'firstName' })}</label>
        <Field
          name="firstName"
          id="firstName"
          type="text"
          className="form-control"
          component={requiredInput}
          placeholder="Enter team member first name"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">{intl.formatMessage({ id: 'lastName' })}</label>
        <Field
          name="lastName"
          id="lastName"
          type="text"
          className="form-control"
          component={requiredInput}
          placeholder="Enter team member last name"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">{intl.formatMessage({ id: 'email' })}</label>
        <Field
          name="email"
          id="email"
          type="email"
          className="form-control"
          component={requiredInput}
          placeholder="Enter team member email"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">{intl.formatMessage({ id: 'phoneNumber' })}</label>
        <Field
          name="phoneNumber"
          id="phoneNumber"
          type="text"
          className="form-control"
          component={requiredInput}
          placeholder="Enter team member phone number (ex. XXX-XXX-XXXX)"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">{intl.formatMessage({ id: 'position' })}</label>
        <Field
          name="position"
          id="position"
          type="text"
          className="form-control"
          component={requiredInput}
          placeholder="Enter team member position"
        />
      </div>
    </div>
  );
};

export default reduxForm({ form: 'employee' })(EmployeeEditForm);
