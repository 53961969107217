import React from 'react';
import Reclamations from '../../reclamations/containers/Reclamations';
import { ReclamationType } from '../../reclamations/models';

const Claims = () => {
  const navigationInfo = {
    path: ' ',
    navigationProperty: '_id',
  };
  return <Reclamations reclamationType={ReclamationType.Claim} navigationInfo={navigationInfo} />;
};

export default Claims;
