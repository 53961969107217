import { setRandomColor } from '../../core/utils';

export const surveyResponseColors = (answer: string) => {
  switch (answer) {
    case 'Seasonal':
      return ['rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 1)'];
    case 'Moving':
      return ['rgba(54, 162, 235, 0.2)', 'rgba(54, 162, 235, 1)'];
    case 'Unlimited is too much':
      return ['rgba(255, 206, 86, 0.2)', 'rgba(255, 206, 86, 1)'];
    case 'Wash Quality':
      return ['rgba(75, 192, 192, 0.2)', 'rgba(75, 192, 192, 1)'];
    case 'Not Applicable':
      return ['rgba(153, 102, 255, 0.2)', 'rgba(153, 102, 255, 1)'];
    case 'New Car':
      return ['rgba(255, 159, 64, 0.2)', 'rgba(255, 159, 64, 1)'];
    case 'Promotion Ended':
      return ['rgba(165, 55, 253, 0.2)', 'rgba(165, 55, 253, 1)'];
    case 'Not Using':
      return ['rgba(65, 147, 169, 0.2)', 'rgba(65, 147, 169, 1)'];
    case 'To Expensive':
      return ['rgba(255, 206, 86, 0.2)', 'rgba(255, 206, 86, 1)'];
    case 'No longer have the vehicle':
      return ['rgba(75, 192, 192, 0.2)', 'rgba(75, 192, 192, 1)'];
    case 'Wash damaged vehicle':
      return ['rgba(252, 214, 112, 0.2)', 'rgba(252, 214, 112, 1)'];
    case "Didn't know how it worked":
      return ['rgba(255, 159, 64, 0.2)', 'rgba(255, 159, 64, 1)'];
    case 'Bad Guest Experience':
      return ['rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 1)'];
    case 'Other':
      return ['rgba(254, 206, 0, 0.2)', 'rgba(254, 206, 0, 1)'];

    default:
      return setRandomColor();
  }
};
