import React from 'react';
import { Route, useLocation } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasWarrantyEnabledRedir } from '../core/auth';
import ClaimDetail from '../views/claimDetail/containers/ClaimDetail';
import ClaimsViews from '../views/claims/components/ClaimsViews';
import ClaimReports from '../views/claims/containers/ClaimReports';
import Claims from '../views/claims/containers/Claims';
import ClaimAddedConfirmation from '../views/reclamations/components/ClaimAddedConfirmation';
import ReclamationEdit from '../views/reclamations/containers/ReclamationEdit';
import ReclamationFormWrapper from '../views/reclamations/containers/ReclamationFormWrapper';

const ClaimsRoute = () => {
  const location = useLocation();
  const isReports = location.pathname.split('/')[3] === 'reports';

  return (
    <Switch>
      {!isReports && [
        <Route key="0" path="/:site/claims/create">
          <ReclamationFormWrapper />
        </Route>,
        <Route key="1" exact path="/:site/claims/:reclamation/edit">
          <ReclamationEdit />
        </Route>,
        <Route key="2" path="/:site/claims/:claim/success">
          <ClaimAddedConfirmation />
        </Route>,
        <Route key="3" path="/:site/claims/:claim">
          <ClaimDetail />
        </Route>,
      ]}
      <ClaimsViews>
        <Route exact path="/:site/claims/">
          <Claims />
        </Route>
        <Route exact path="/:site/claims/reports">
          <ClaimReports />
        </Route>
      </ClaimsViews>
    </Switch>
  );
};

export default userHasWarrantyEnabledRedir(ClaimsRoute);
