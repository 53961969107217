import { PropTypes } from 'prop-types';
import { toEnum } from '../utils';

export const RoleTypes = {
  Alpr: 0,
  Customer: 1,
  CustomerAdmin: 2,
  SiteManager: 3,
  Operator: 4,
  OperatorAdmin: 5,
  OperatorGroupAdmin: 6,
  Admin: 7,
  System: 8,
};

export const hasRole = (currentRole, role) => {
  return toEnum(RoleTypes, currentRole) >= toEnum(RoleTypes, role);
};

export const hasRoleInOperatorGroup = (currentSession, minRole, operatorGroupId) => {
  if (hasRole(currentSession.role, RoleTypes.Admin)) {
    return true;
  }
  return (
    hasRole(currentSession.role, minRole) && currentSession.operatorGroupId === operatorGroupId
  );
};

const Permission = ({ role, minRole, children }) => {
  return hasRole(role, minRole) ? <>{children}</> : <></>;
};

Permission.propTypes = {
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default Permission;
