import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';
import AddBar from './AddBar';
import { MenuContext } from './menuContext';

const FocusSelect = (props) => {
  const { options, onClick, onAdd } = props;
  const [active, setActive] = useState(null);
  const { setHasChanged, setRowInEdit } = useContext(MenuContext);

  useEffect(() => {
    setActive(null);
    onClick(null);
  }, [options]);

  const handleDeleteParent = (index) => {
    props.onChange(null, index);
    setHasChanged(true);
    setRowInEdit(null);
  };

  const handleAdd = (name) => {
    onAdd(name);
    setHasChanged(true);
  };

  const generateFocusOptions = () =>
    options.map((option, i) => (
      <div key={i} className="btn-group fm-focusSelect-buttonGroup">
        <button
          className={`btn btn-secondary btn-lg ${
            active === i ? 'fm-focusSelect-focusButton-active' : 'fm-focusSelect-focusButton'
          }`}
          onClick={() => {
            setActive(i);
            onClick(i);
          }}
        >
          {option}
        </button>
        <button
          className="btn btn-danger fm-focusSelect-borderRadius"
          onClick={() => handleDeleteParent(i)}
        >
          <i className="icon icon-cross" />
        </button>
      </div>
    ));

  return (
    <div className="fm-focusSelect-container">
      <AddBar onAdd={handleAdd} />
      <div className="fm-focusSelect-optionContainer">{generateFocusOptions()}</div>
    </div>
  );
};

FocusSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
};

export default memo(FocusSelect, isEqual);
