import { useEffect, useState } from 'react';
import { SelectedOptionValue } from 'react-select-search';
import { Label } from 'reactstrap';
import { IntlType } from '../../../../../utils/typing';
import { Site } from '../../../../site-selection/models';
import { Formik } from '../../../hooks/useForm';
import { FormSelections } from '../hooks/useFormSelections';
import SelectedSitesList from './SelectedSitesList';
import SiteSelectorInput from './SiteSelectorInput';

export interface SearchOption {
  value: number;
  name: string;
}

interface Props {
  intl: IntlType;
  formSelections: FormSelections;
  formik: Formik;
}

const SiteSelector = ({ intl, formSelections, formik }: Props): JSX.Element => {
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>(
    formSelections.sites.map((site) => ({
      name: site.name,
      value: site.id,
    }))
  );

  const [selectedSites, setSelectedSites] = useState<Site[]>(
    formSelections.sites.filter((site) =>
      formik.values.coreSites.some((coreSite) => coreSite.id === site.extId)
    )
  );

  useEffect(() => {
    formik.setFieldValue(
      'coreSites',
      selectedSites.map((site) => ({ id: site.extId }))
    );
  }, [selectedSites.length]);

  const addSelectedSite = (selectedSiteId: SelectedOptionValue | SelectedOptionValue[]) => {
    const site = formSelections.sites.find((s) => s.id === selectedSiteId) as Site;

    setSelectedSites([...selectedSites, site]);
    setSearchOptions(
      searchOptions.filter((option) => option.value !== (selectedSiteId as unknown as number))
    );
  };

  const removeSelectedSite = (site: Site) => {
    setSelectedSites(selectedSites.filter((s) => s.id !== site.id));
    setSearchOptions([...searchOptions, { value: site.id, name: site.name }]);
  };

  return (
    <>
      <Label for="siteSelection" className="font-weight-bold">
        {intl.formatMessage({ id: 'siteSelection' })}
      </Label>

      <SiteSelectorInput
        intl={intl}
        searchOptions={searchOptions}
        addSelectedSite={addSelectedSite}
        selectedSites={selectedSites}
      />

      <SelectedSitesList
        intl={intl}
        selectedSites={selectedSites}
        removeSelectedSite={removeSelectedSite}
      />
    </>
  );
};

export default SiteSelector;
