import React from 'react';
import { useIntl } from 'react-intl';
import '../../../styles/landing-page-footer.scss';
import { useAppContext } from '../../../providers/AppProvider';

const LandingPageFooter = () => {
  const supportAddress = 'support@tommys-express.com';
  const washClubSupportAddress = 'support@washclub.com';
  const intl = useIntl();
  const { theme } = useAppContext();
  return (
    <footer className="landing-page-footer">
      <span>{intl.formatMessage({ id: 'needHelp' })}</span>
      <br />
      <span>
        {intl.formatMessage({ id: 'contact' })}{' '}
        {theme.hostName === 'portal.wash.club' ? (
          <a href={`mailto: ${washClubSupportAddress}`}>{washClubSupportAddress}</a>
        ) : (
          <a href={`mailto: ${supportAddress}`}>{supportAddress}</a>
        )}
      </span>
    </footer>
  );
};

export default LandingPageFooter;
