const sideNavMessages = {
  en: {
    // get the labels for our side menu
    tasks: 'Tasks',
    maintenanceTasks: 'Maintenance Tasks',
    inventory: 'Inventory',
    analytics: 'Analytics',
    expenses: 'Expenses',
    team: 'Team',
    washCounts: 'Wash Counts',
    detergents: 'Detergents',
    rewash: 'Rewashes',
    claims: 'Claims',
    expenses: 'Expenses',
    home: 'Home',
    settings: 'Settings',
    reports: 'Reports',
  },
};

export default sideNavMessages;
