import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectRewashes, selectFromDate, selectUntilDate } from '../reducer';
import { selectSiteExtId } from '../../settings/reducer';
import moment from 'moment';
import { getApiStatus } from '../../../core/utils';
import Loader from '../../../core/components/Loader';
import { fetchRewashes, setFromDate, setUntilDate } from '../actions';
import { map, has, join, keys, pickBy } from 'lodash';
import SearchBar from '../../../core/components/SearchBar';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { useHistory } from 'react-router';

const Rewashes = () => {
  const [searchResults, setSearchResults] = React.useState(null);
  const [searchResultsDetails, setSearchResultsDetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  const rewashes = useSelector(selectRewashes);
  const rewashesLoading = useSelector((state) => getApiStatus(state, 'FETCH_REWASHES'));
  const fromDate = useSelector(selectFromDate);
  const untilDate = useSelector(selectUntilDate);
  const siteId = useSelector(selectSiteExtId);
  const multiSite = useSelector(selectMultiSiteSelection);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const getRewashes = () => {
    dispatch(
      fetchRewashes({
        types: 'Rewash',
        fromDate,
        untilDate,
      })
    );
  };

  const setSearchMatch = (sResults, sResultsDetails, sText) => {
    setSearchResults(sResults);
    setSearchResultsDetails(sResultsDetails);
    setSearchText(sText);
  };

  const getRewashReason = (reclamation) => {
    if (!has(reclamation, 'incidentDetails.needsRewash')) {
      return intl.formatMessage({ id: 'noRewashReason' });
    }
    const rewashReasons = keys(pickBy(reclamation.incidentDetails.needsRewash));
    return join(
      map(rewashReasons, (reasonName) => {
        if (reasonName === '') {
          return intl.formatMessage({ id: 'other' });
        }
        return intl.formatMessage({ id: reasonName });
      }),
      ', '
    );
  };

  useEffect(() => {
    getRewashes();
  }, [fromDate, untilDate, siteId, multiSite]);

  return (
    <div className="container-fluid" data-testid="Rewashes">
      <section className="page-main-content">
        <div className="table-buttons">
          <DateRangeSelector
            dates={[fromDate, untilDate]}
            onSubmit={(dates) => {
              dispatch(setFromDate(dates.from));
              dispatch(setUntilDate(dates.until));
            }}
          />
        </div>
        {rewashesLoading ? (
          <Loader />
        ) : (
          <div className="content-container">
            <div className="table-filters">
              {rewashes && (
                <SearchBar
                  intl={intl}
                  dataToSearchOn={rewashes}
                  dataToSearchFor={['customerName', 'customerEmail', 'rewashOperation']}
                  setSearchMatch={setSearchMatch}
                  uniqueField="id"
                  styles={['data-table-search mb-2']}
                />
              )}
            </div>
            {rewashes.length ? (
              <div className="media-content">
                <div className="media media-rewash rewash-section">
                  {map(searchResults ? searchResults : rewashes, (rewash, i) => (
                    <Card
                      className="mouse-pointer"
                      key={i}
                      onClick={() => history.push(`rewashes/${rewash.id}/`)}
                    >
                      {rewash?.files && rewash?.files.length && rewash?.files[0]?.downloadUrl ? (
                        <div className="media-item no-border">
                          <img
                            src={rewash.files[0].downloadUrl}
                            alt="rewash vehicle image"
                            title="Rewash vehicle image"
                          />
                        </div>
                      ) : (
                        <div className="media-item">
                          <p className="media-item-description no-image">
                            <i
                              className="uil uil-image-times"
                              title={intl.formatMessage({ id: 'noImage' })}
                            ></i>
                          </p>
                        </div>
                      )}
                      <div className="details">
                        <p>
                          {intl.formatMessage({ id: 'reason' })}: {getRewashReason(rewash)}
                        </p>
                        <p data-testid="rewashDateIssued">
                          {intl.formatMessage({ id: 'dateIssued' })}:{' '}
                          {moment(rewash.issueDate).format('MM/DD/YYYY h:mm a')}
                        </p>
                        <p>
                          {intl.formatMessage({ id: 'operation' })}: {rewash.rewashOperation}
                        </p>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            ) : (
              <div className="text-center">
                <h3>{intl.formatMessage({ id: 'noRewashes' })}</h3>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default Rewashes;
