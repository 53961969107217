import { invokeMap, isEqual, values } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import useAbortController from '../../../core/abortController';
import Tabs from '../../../core/components/Tabs';
import { viewPermissionRefs } from '../../../core/components/UserGroupPermissions';
import * as actions from '../actions';
import DetergentCharts from '../components/DetergentCharts';
import HistoricalTankReadings from '../components/HistoricalTankReadings';
import Detergent from '../models';
import {
  selectCostPerCarChartFromDate,
  selectCostPerCarChartUntilDate,
  selectCostPerCarData,
  selectReadingsFromDate,
  selectReadingsUntilDate,
} from '../reducer';

export const component = ({ intl, detergents, chartData, ...props }) => {
  const [tabType, setTabType] = useState('detergentLevels');
  const readingsAbortController = useAbortController(values(props.readingsDates));

  const getReadings = (ids) => {
    const detergentIds = ids || Object.keys(props.detergentReadings);
    if (detergentIds && detergentIds.length > 0) {
      const [fromDate, untilDate] = invokeMap(props.readingsDates, 'format', 'YYYY-MM-DD');
      props.getDetergentReadings(
        { fromDate, untilDate, detergentIds },
        readingsAbortController.signal
      );
    }
  };

  useEffect(getReadings, [readingsAbortController]);
  const asOfDateProps = {
    setFromDate: props.setCostPerCarChartFromDate,
    setUntilDate: props.setCostPerCarChartUntilDate,
    ...props.costPerCarChartDates,
  };

  const detergentChartProps = {
    detergents: Detergent.mapChartData(detergents),
    chartData,
    asOfDateProps,
  };
  const tankReadingsData = {
    detergents,
    asOfDateProps: {
      setFromDate: props.setReadingsFromDate,
      setUntilDate: props.setReadingsUntilDate,
      ...props.readingsDates,
    },
    detergentReadings: props.detergentReadings,
    removeReadings: props.removeReadings,
    getReadings,
  };

  const tabComponents = [
    {
      component: <DetergentCharts {...detergentChartProps} />,
      title: intl.formatMessage({ id: 'detergentLevels' }),
      type: 'detergentLevels',
      permissionKey: viewPermissionRefs.detergent,
    },
    {
      component: (
        <div className="col-sm-12">
          <HistoricalTankReadings {...tankReadingsData} />
        </div>
      ),
      title: intl.formatMessage({ id: 'historicalTankReadings' }),
      type: 'historicalTankReadings',
      permissionKey: viewPermissionRefs.detergent,
    },
  ];

  const getTabsProps = () => ({
    tabs: tabComponents,
    onClick: setTabType,
    selectedType: tabType,
  });

  const visibleComponent = tabComponents.find((t) => t.type === tabType);

  return (
    <React.Fragment>
      <Tabs {...getTabsProps()} />
      <div className="detergent-tab-container">
        <div className="row">{visibleComponent.component}</div>
      </div>
    </React.Fragment>
  );
};

component.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergentReadings: PropTypes.shape({}).isRequired,
  readingsDates: PropTypes.shape({}).isRequired,
  costPerCarChartDates: PropTypes.shape({}).isRequired,
  showChart: PropTypes.func,
  setCostPerCarChartFromDate: PropTypes.func.isRequired,
  setCostPerCarChartUntilDate: PropTypes.func.isRequired,
  setReadingsFromDate: PropTypes.func.isRequired,
  setReadingsUntilDate: PropTypes.func.isRequired,
  removeReadings: PropTypes.func.isRequired,
  getDetergentReadings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  readingsDates: {
    fromDate: selectReadingsFromDate(state),
    untilDate: selectReadingsUntilDate(state),
  },
  costPerCarChartDates: {
    fromDate: selectCostPerCarChartFromDate(state),
    untilDate: selectCostPerCarChartUntilDate(state),
  },
  chartData: selectCostPerCarData(state),
});

const enhance = compose(connect(mapStateToProps, actions), injectIntl);

export const DetergentTabsContainer = React.memo(component, isEqual);

export default enhance(DetergentTabsContainer);
