import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/reclamations';

export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
export const SET_REPORT_FROM_DATE = 'SET_REPORT_FROM_DATE';
export const SET_REPORT_UNTIL_DATE = 'SET_REPORT_UNTIL_DATE';
export const GET_CAR_PART_REPORTS = 'GET_CAR_PART_REPORTS';
export const GET_CAR_MAKE_REPORTS = 'GET_CAR_MAKE_REPORTS';
export const GET_CAR_MODEL_REPORTS = 'GET_CAR_MODEL_REPORTS';
export const REMOVE_CAR_REPORTS = 'REMOVE_CAR_REPORTS';
export const REMOVE_CAR_MODEL_REPORTS = 'REMOVE_CAR_MODEL_REPORTS';

export const getCarPartReports = (query) => {
  return {
    type: API_REQUEST,
    callAPI: API.getCarPartReports,
    action: GET_CAR_PART_REPORTS,
    data: query,
  };
};

export const getCarMakeReports = (query) => {
  return {
    type: API_REQUEST,
    callAPI: API.getCarMakeReports,
    action: GET_CAR_MAKE_REPORTS,
    data: query,
  };
};

export const getCarModelReports = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.getCarModelReports,
    action: GET_CAR_MODEL_REPORTS,
    data,
    meta: { makeId: data.makeId },
  };
};

export const removeReports = () => {
  return { type: REMOVE_CAR_REPORTS };
};

export const removeCarModelReport = (makeId) => {
  return {
    type: REMOVE_CAR_MODEL_REPORTS,
    meta: { makeId },
  };
};

export const setFromDate = (date) => ({ type: SET_REPORT_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_REPORT_UNTIL_DATE, date });
export const setReportType = (reportType) => ({
  type: SET_REPORT_TYPE,
  reportType,
});
