import { PropTypes } from 'prop-types';
import React from 'react';

export const VendorFilter = (props) => {
  const handleChange = ({ target: { value } }) => {
    props.setFilter({
      ...props.currentFilter,
      currentVendor: value,
    });
  };

  return (
    <div className="form-group">
      <label>Vendor</label>
      <select
        className="form-control"
        value={props.currentFilter.currentVendor}
        onChange={handleChange}
      >
        <option value="All">All</option>
        <option disabled>------</option>
        {props.vendors.map((v) => {
          return (
            <option key={v.id} value={v.name}>
              {v.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

VendorFilter.defaultProps = {
  currentFilter: {
    currentVendor: 'All',
  },
  vendors: [],
};

VendorFilter.propTypes = {
  currentFilter: PropTypes.shape({
    currentVendor: PropTypes.string,
  }),
  vendors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default VendorFilter;
