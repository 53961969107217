import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

const StatusButton = (props) => {
  const { rowData, mixIns } = props;
  const [received, setReceived] = useState(!!rowData._receivedAt);

  const handleClick = () => {
    if (!received) {
      const confirmProps = {
        onReady: () => {
          setReceived(true);
          mixIns.receive(rowData._id);
        },
        text: `Are you sure sure you would like to mark this order as received: "${rowData.poNumber}"`,
        title: 'Confirm order has been received',
      };
      mixIns.showDialogs('CONFIRM_DIALOG', confirmProps);
    }
  };

  return (
    <div>
      <button
        type="button"
        className={`btn ${received ? 'btn-link' : 'btn-outline-success'}`}
        style={{
          borderRadius: '3px',
          padding: '1px 20px',
          fontSize: '12px',
        }}
        onClick={handleClick}
        disabled={mixIns.disabled || received}
      >
        {received ? 'Received' : 'Receive'}
      </button>
    </div>
  );
};

StatusButton.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
  mixIns: PropTypes.shape({
    receive: PropTypes.func.isRequired,
    unreceive: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    showDialogs: PropTypes.func.isRequired,
  }).isRequired,
};

export default StatusButton;
