import { parseInt } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import BaseKpi from '../../../core/components/BaseKpi';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { getApiStatus } from '../../../core/utils';
import { setAnalyticsPage } from '../../analytics/actions';
import { FETCH_COST_PER_CAR_DATA } from '../actions';

interface Props {
  fromDate: any;
  untilDate: any;
  costPerCarData: {
    claimsCostPerCarDifference: string;
    claimsCostPerCarThirtyDayPercentage: string;
    claimsCostPerCarPastThirtyDays: string;
  };
}

const CostPerCarKpi = (props: Props): JSX.Element => {
  const costPerCarLoading = useSelector((state) => getApiStatus(state, FETCH_COST_PER_CAR_DATA));
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const pushToAnalytics = () => {
    dispatch(setAnalyticsPage('Single-P&L'));
    history.push('analytics');
  };

  const dailyInstaClubCard = (): JSX.Element => {
    return (
      <Container fluid>
        {props.costPerCarData?.claimsCostPerCarThirtyDayPercentage === '0' ? (
          <Row>
            <Col xs={12} s={12} md={12} lg={12}>
              <BaseKpi
                headerClassName={'insta-club-kpi-title'}
                header={'costBreakdown'}
                count={intl.formatMessage({ id: 'moreDataRequired' })}
                isLoading={costPerCarLoading}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={6} s={6} md={6} lg={6}>
              <BaseKpi
                countClassName={
                  parseFloat(props.costPerCarData?.claimsCostPerCarDifference) < 0
                    ? 'insta-club-kpi-count-large-negative icon icon-arrow-up'
                    : 'insta-club-kpi-count-large icon icon-arrow-down'
                }
                headerClassName={'insta-club-kpi-title'}
                header={'costBreakdown'}
                count={`$${props.costPerCarData?.claimsCostPerCarPastThirtyDays}`}
                isLoading={costPerCarLoading}
              />
            </Col>
            <Col xs={6} s={6} md={6} lg={6}>
              <BaseKpi
                countClassName={
                  parseInt(props?.costPerCarData?.claimsCostPerCarThirtyDayPercentage) > 0
                    ? 'insta-club-kpi-count-negative icon icon-arrow-up'
                    : 'insta-club-kpi-count icon icon-arrow-down'
                }
                headerClassName={'this-month'}
                count={`${props?.costPerCarData?.claimsCostPerCarThirtyDayPercentage}%`}
                header={'thisMonth'}
                isLoading={costPerCarLoading}
              />
            </Col>
          </Row>
        )}
        <hr />
        <Row>
          <Col xs={3} s={3} md={3} lg={3}>
            <BaseKpi
              countClassName={
                parseFloat(props.costPerCarData?.claimsCostPerCarDifference) < 0
                  ? 'insta-club-kpi-count-negative'
                  : 'insta-club-kpi-count'
              }
              headerClassName={'insta-club-kpi-title'}
              count={
                props.costPerCarData?.claimsCostPerCarPastThirtyDays === '0'
                  ? '-'
                  : `$${props.costPerCarData?.claimsCostPerCarPastThirtyDays}`
              }
              header={'claims'}
              isLoading={costPerCarLoading}
            />
          </Col>
          <Col className="cost-per-car-plus" xs={1} s={1} md={1} lg={1}>
            <span className="icon icon-plus" />
          </Col>
          <Col xs={3} s={3} md={3} lg={3}>
            <BaseKpi
              countClassName={'coming-soon'}
              headerClassName={'insta-club-kpi-title'}
              header={'utilities'}
              count={'Coming Soon'}
              isLoading={costPerCarLoading}
            />
          </Col>
          <Col className="cost-per-car-plus" xs={1} s={1} md={1} lg={1}>
            <span className="icon icon-plus" />
          </Col>
          <Col xs={3} s={3} md={3} lg={3}>
            <BaseKpi
              countClassName={'coming-soon'}
              headerClassName={'insta-club-kpi-title'}
              header={'detergents'}
              count={'Coming Soon'}
              isLoading={costPerCarLoading}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <section className="overview-chart-card insta-club-kpi-body">
      <div>
        <div className="chart-title">
          {intl.formatMessage({ id: 'yourCostPerCar' })}
          <Button className="cost-per-car-info" id="PopoverFocus" type="button">
            <i style={{ fontSize: '22px' }} className="uil uil-info-circle"></i>
          </Button>
          <UncontrolledPopover placement="right" target="PopoverFocus" trigger="focus">
            <PopoverHeader>{intl.formatMessage({ id: 'costPerCarInfoHeader' })}</PopoverHeader>
            <PopoverBody>{intl.formatMessage({ id: 'costPerCarInfo' })}</PopoverBody>
          </UncontrolledPopover>
        </div>
        {dailyInstaClubCard()}
        <div className="button-with-loader-overview-container">
          <ButtonWithLoader style="button full-width bold gradient" handleClick={pushToAnalytics}>
            <React.Fragment>{intl.formatMessage({ id: 'goToPL' })}</React.Fragment>
          </ButtonWithLoader>
        </div>
      </div>
    </section>
  );
};

export default CostPerCarKpi;
