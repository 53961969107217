import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import useQuickSelectDateObject from './useQuickSelectDateObject';

const DateRangeQuickSelect = (props) => {
  const { onSelection, activeDateObject, disabled, additionalOptions, removeItems } = props;
  const quickList = useQuickSelectDateObject(additionalOptions, removeItems);

  const getClassNames = (key) => {
    const classNames = [
      'btn',
      'btn-outline-primary',
      'drs-button-shadow',
      'drs-quickselect-mh',
      'drs-button-override',
    ];

    if (key === activeDateObject) {
      classNames.push('drs-button-active');
    }

    return classNames.join(' ');
  };

  return (
    <div className="date-range-buttons drs-date-range-padding">
      {map(quickList, (value, key) => (
        <button
          key={key}
          className={getClassNames(key)}
          disabled={disabled}
          onClick={() => {
            onSelection(value);
          }}
        >
          {value.name}
        </button>
      ))}
    </div>
  );
};

DateRangeQuickSelect.propTypes = {
  onSelection: PropTypes.func,
  activeDateObject: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(DateRangeQuickSelect, isEqual);
