import * as api from '../api';

export const postReclamationFile = (args) => {
  const {
    options: { siteId },
    data: { reclamationId, file },
  } = args;
  return api.callPostWithFormData(`/manager/sites/${siteId}/reclamations/${reclamationId}/files`, {
    data: file,
    options: args.options,
  });
};

export const deleteReclamationFile = (args) => {
  const {
    options: { siteId },
    data: { reclamationId, fileId },
  } = args;
  return api.callPost(
    `/manager/sites/${siteId}/reclamations/${reclamationId}/files/${fileId}/archive`,
    {
      options: args.options,
    }
  );
};

export const postPurchaseOrderFile = (args) => {
  const {
    options: { siteId },
    data: { purchaseOrderId, file },
  } = args;
  return api.callPostWithFormData(
    `/manager/sites/${siteId}/purchase-orders/${purchaseOrderId}/files`,
    {
      data: file,
      options: args.options,
    }
  );
};

export const deletePurchaseOrderFile = (args) => {
  const {
    options: { siteId },
    data: { purchaseOrderId, fileId },
  } = args;
  return api.callPost(
    `/manager/sites/${siteId}/purchase-orders/${purchaseOrderId}/files/${fileId}/archive`,
    {
      options: args.options,
    }
  );
};
