import * as utils from '../../core/utils';

export const ReclamationType = {
  Claim: 0,
  Rewash: 1,
};

export const ReclamationWashType = {
  Exterior: 0,
  Interior: 1,
  Both: 2,
};

export const PluralReclamationType = {
  claims: 0,
  rewashes: 1,
};

export const ReclamationStatus = {
  NeedsAttention: 0,
  Investigating: 1,
  UnderRepair: 2,
  Repaired: 3,
  Resolved: 4,
  NotCaused: 5,
  Incomplete: 6,
  NeedsRepair: 7,
  Denied: 8,
  Completed: 9,
  All: 10,
  AllOpen: 11,
  AllClosed: 12,
};

export const ReclamationClaimType = {
  StationaryAntenna: 0,
  PowerAntenna: 1,
  Wiper: 2,
  Mirror: 3,
  TrimOrMolding: 4,
  Scratch: 5,
  Bumper: 6,
  Windshield: 7,
  Wheel: 8,
  Tire: 9,
  Other: 10,
  All: 11,
};

export const IssueSiteStatus = {
  Pending: 0,
  Accepted: 1,
  Declined: 2,
};

class Reclamation {
  constructor(props = {}) {
    this.id = props.id;
    this.customerName = props.customerName;
    this.customerEmail = props.customerEmail;
    this.customerPhone = props.customerPhone;
    this.type = utils.toEnum(ReclamationType, props.type);
    this.issueWashType = utils.toEnum(ReclamationWashType, props.issueWashType);
    this.status = utils.toEnum(ReclamationStatus, props.status);
    this.description = props.description;
    this.incidentDetails = props.incidentDetails;
    this.vehicleDetails = props.vehicleDetails;
    this.cost = props.cost;
    this.currency = props.currency;
    this.rewashed = props.rewashed;
    this.employeeIds = props.employeeIds;
    this.siteId = props.siteId;
    this.site = props.site;
    this.createdAt = props.createdAt;
    this.issueDate = props.issueDate;
    this.issueSite = props.issueSite;
    this.issueSiteStatus = utils.toEnum(IssueSiteStatus, props.issueSiteStatus);
    this.issueSiteNote = props.issueSiteNote;
    this.files = props.files;
    this.rewashOperation = props.rewashOperation;
    this.notes = props.notes;
    this.repairItems = props.repairItems;
    this.claimDamagePartId = props.claimDamagePartId;
    this.claimDamageAreaId = props.claimDamageAreaId;
    this.archivedAt = props.archivedAt;
  }

  static carPartData() {
    return [
      {
        id: 'StationaryAntenna',
        value: 'Stationary Antenna',
        options: [
          { id: 'CrackedBase', value: 'Cracked Base' },
          { id: 'LostAntenna', value: 'Lost Antenna' },
        ],
      },
      {
        id: 'PowerAntenna',
        value: 'Power Antenna',
        options: [
          { id: 'CrackedBase', value: 'Cracked Base' },
          { id: 'LostAntenna', value: 'Lost Antenna' },
        ],
      },
      {
        id: 'Wiper',
        value: 'Wiper',
      },
      {
        id: 'Mirror',
        value: 'Mirror',
        options: [
          { id: 'MirrorGlass', value: 'Mirror Glass' },
          { id: 'MirrorPlastic', value: 'Mirror Plastic' },
        ],
      },
      {
        id: 'TrimOrMolding',
        value: 'Trim / Molding',
        options: [
          { id: 'TrimLost', value: 'Trim Lost' },
          { id: 'TrimDamaged', value: 'Trim Damaged' },
          { id: 'PlasticBurnt', value: 'Plastic Burnt' },
        ],
      },
      {
        id: 'Scratch',
        value: 'Scratch',
        options: [
          { id: 'ClearcoatScratch', value: 'Clearcoat Scratch' },
          { id: 'BuffableScratch', value: 'Buffable Scratch' },
        ],
      },
      {
        id: 'Bumper',
        value: 'Bumper',
      },
      {
        id: 'Windshield',
        value: 'Windshield',
        options: [{ id: 'WindshieldCrack', value: 'Windshield Crack' }],
      },
      {
        id: 'Wheel',
        value: 'Wheel',
        options: [
          { id: 'WheelScratched', value: 'Wheel Scratched' },
          { id: 'WheelCoatingPeeled', value: 'Wheel Coating Peeled' },
          { id: 'WheelsBurnt', value: 'Wheels Burnt' },
        ],
      },
      {
        id: 'Tire',
        value: 'Tire',
        options: [{ id: 'TirePopped', value: 'Tire Popped' }],
      },
      {
        id: 'Other',
        value: 'Other',
      },
    ];
  }

  static locationInWashData() {
    return [
      { id: 'EntranceOfWash', value: 'Entrance of Wash' },
      { id: 'PresoakArea', value: 'Presoak Area' },
      { id: 'ClothModule1', value: 'Cloth Module 1' },
      { id: 'ClothModule2', value: 'Cloth Module 2' },
      { id: 'ClothModule3', value: 'Cloth Module 3' },
      { id: 'ClothModule4', value: 'Cloth Module 4' },
      { id: 'Wrap1DS', value: 'Wrap 1 DS' },
      { id: 'Wrap1PS', value: 'Wrap 1 PS' },
      { id: 'Wrap2DS', value: 'Wrap 2 DS' },
      { id: 'Wrap2PS', value: 'Wrap 2 PS' },
      { id: 'SideBrushes', value: 'Side Brushes' },
      { id: 'MitterCurtain', value: 'Mitter Curtain' },
      { id: 'HighPressureArch1', value: 'High Pressure Arch 1' },
      { id: 'HighPressureArch2', value: 'High Pressure Arch 2' },
      { id: 'RinseArea', value: 'Rinse Area' },
      { id: 'BlowerRoom', value: 'Blower Room' },
      { id: 'InteriorCleaning', value: 'Interior Cleaning' },
    ];
  }
}

export default Reclamation;
