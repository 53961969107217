import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { hotjar } from 'react-hotjar';
import ReduxToastr from 'react-redux-toastr';
import { hideDialog } from './core/actions';
import AppProvider from './providers/AppProvider';
import IntlProviderWrapper from './providers/IntlProvider';
import ReduxProvider from './providers/ReduxProvider';
import RouteProvider from './providers/RouteProvider';
import createStore from './store/createStore';
import hydrateStore from './store/hydrateStore';
//import Enzyme from 'enzyme';
//import Adapter from '@cfaester/enzyme-adapter-react-18';

//Enzyme.configure({ adapter: new Adapter() });
import './styles/styles.scss';
import googleAnalytics from './utils/google-analytics-gtag';

const isProductionBuild = process.env.NODE_ENV === 'production';
const container = document.getElementById('root');
const root = createRoot(container);

if (isProductionBuild) {
  hotjar.initialize(2630731, 6);
} else {
  hotjar.initialize(2630755, 6);
}

const initialState = hydrateStore();
const store = createStore(initialState);
const history = createBrowserHistory();

const trackingKey = isProductionBuild ? 'UA-173498189-1' : 'UA-173498189-2';
const gaGtag = googleAnalytics(trackingKey);

root.render(
  <IntlProviderWrapper>
    <ReduxProvider>
      <AppProvider>
        <div style={{ height: '100vh' }}>
          <RouteProvider />
          <ReduxToastr preventDuplicates />
        </div>
      </AppProvider>
    </ReduxProvider>
  </IntlProviderWrapper>
);

// hide dialogs whenever route changes
history.listen((location) => {
  gaGtag.gtag('config', trackingKey, { page_path: location.pathname });
  if (store) store.dispatch(hideDialog());
});
