import { useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import '../../../../styles/user-dialog-body.scss';
import { IntlType } from '../../../../utils/typing';
import Employee from '../../../employees/models';
import { Form } from '../../hooks/useForm';
import ClearFieldsButton from './ClearFieldsButton';
import EmployeeForm from './EmployeeForm';
import useFormSelections from './hooks/useFormSelections';
import NextPageButton from './NextPageButton';
import PreviousPageButton from './PreviousPageButton';
import UserForm from './UserForm';

interface Props {
  intl: IntlType;
  formProps: Form & { isNew: boolean; employee?: Employee };
  submitButton: JSX.Element;
}

const UserFormSelector = ({ intl, formProps, submitButton }: Props): JSX.Element => {
  const formSelections = useFormSelections();

  const [formPage, setFormPage] = useState<'employeeForm' | 'userForm'>('employeeForm');

  const { formik, handleResetClick, isNew, employee } = formProps;

  const clearFields = () => {
    setFormPage('employeeForm');
    handleResetClick();
  };

  if (!formik.values.loginCredentialsNeeded) {
    return (
      <>
        <ModalBody>
          <EmployeeForm intl={intl} formik={formik} isNew={isNew} employee={employee} />
        </ModalBody>
        <ModalFooter>
          <ClearFieldsButton intl={intl} handleClick={clearFields} />
          {submitButton}
        </ModalFooter>
      </>
    );
  }

  return formPage === 'employeeForm' ? (
    <>
      <ModalBody>
        <EmployeeForm intl={intl} formik={formik} isNew={isNew} employee={employee} />
      </ModalBody>
      <ModalFooter>
        <ClearFieldsButton intl={intl} handleClick={clearFields} />
        <NextPageButton intl={intl} handleClick={() => setFormPage('userForm')} />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <UserForm intl={intl} formik={formik} formSelections={formSelections} />
      </ModalBody>
      <ModalFooter>
        <ClearFieldsButton intl={intl} handleClick={clearFields} />
        <PreviousPageButton intl={intl} handleClick={() => setFormPage('employeeForm')} />
        {submitButton}
      </ModalFooter>
    </>
  );
};

export default UserFormSelector;
