import Product from '../products/models';

export class DetergentReading {
  constructor(props = {}) {
    this.id = props.id;
    this.value = props.value;
    this.timestamp = props.timestamp;
    this.createdAt = props.createdAt;
    this.creator = props.creator;
    this.modifier = props.modifier;
    this.modifiedAt = props.modifiedAt;
    this.detergentId = props.detergentId;
  }
}

class Detergent {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.capacity = props.capacity;
    this.alarmLevel = props.alarmLevel;
    this.reorderLevel = props.reorderLevel;
    this.siteId = props.siteId;
    if (props.inventory) {
      this.inventory = props.inventory[0];
    }
    if (props.latestReading) {
      this.latestReading = new DetergentReading(props.latestReading);
    }
    if (props.products) {
      this.products = props.products.map((p) => new Product(p));
    }
  }

  static mapChartData(detergents) {
    return !detergents
      ? []
      : detergents.map((d) => ({
          label: d.name,
          currentValue: d.latestReading ? d.latestReading.value : null,
          capacity: d.capacity,
          currentCostPerCar: 0, // TODO
          goalCostPerCar: 0.05, // TODO
        }));
  }
}

export default Detergent;
