import { call, select, takeEvery } from 'redux-saga/effects';
import * as toastMessages from '../../core/toastMessages';
import { selectEmployees } from '../employees/reducer';

/*
Only employee id is available in the response to a work log post/patch call. This function
retrieves the employee info from state based on employee id
*/

/* eslint-disable */
function* getEmployeeName(workLog) {
  const employees = yield select(selectEmployees);

  const employee = employees
    .filter(function (e) {
      return e.id === workLog.employeeId;
    })
    .reduce(function (obj, e) {
      return { ...obj, ...e };
    }, {});

  return `${employee.lastName}, ${employee.firstName}`;
}

function* notifyWorkLog(action, notify) {
  const employeeName = yield call(getEmployeeName, action.response);
  yield call(notify, [employeeName]);
}

export function* notifyWorkLogAdded(action) {
  yield call(notifyWorkLog, action, toastMessages.workLogAdded);
}

export function* notifyWorkLogClosed(action) {
  yield call(notifyWorkLog, action, toastMessages.workLogClosed);
}

export function* watchWorkLogSagas() {
  yield takeEvery('ADD_WORK_LOG_SUCCESS', notifyWorkLogAdded);
  yield takeEvery('CLOSE_WORK_LOG_SUCCESS', notifyWorkLogClosed);
}
