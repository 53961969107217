import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, submit } from 'redux-form';
import { fetchSites } from '../../../core/actions';
import { checkErrors } from '../../../core/formValidation';
import { selectApiRequests } from '../../../store/apiRequestReducer';
import { selectSite } from '../../../store/authReducer';
import { fetchEmployees } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import { selectSites } from '../../site-selection/reducer';
import * as actions from '../actions';
import ReclamationForm from '../components/ReclamationForm';
import Reclamation, { PluralReclamationType, ReclamationStatus, ReclamationType } from '../models';
import { selectFromDate, selectReclamationDamageOptions, selectUntilDate } from '../reducer';

export class ReclamationFormWrapper extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    type: PropTypes.number,
    triggerSubmit: PropTypes.func.isRequired,
    initialReclamation: PropTypes.shape({
      status: PropTypes.number.isRequired,
    }).isRequired, // instanceOf Reclamation
    fetchEmployees: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    getDamageClaimOptions: PropTypes.func.isRequired,
    reclamationDamageOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    sites: PropTypes.arrayOf(PropTypes.shape({})),
    employees: PropTypes.arrayOf(PropTypes.shape({})),
    errors: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    isNew: PropTypes.bool,
    addReclamation: PropTypes.func.isRequired,
    saveReclamation: PropTypes.func,
    change: PropTypes.func.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isNew: true,
  };

  componentDidMount() {
    this.props.fetchEmployees();
    this.props.fetchSites();
    this.props.getDamageClaimOptions();
  }

  onSubmit = (data) => {
    const rec = omit(data, 'reclamation.site');
    const navigate = this.getNavigationFunction();
    (this.props.isNew ? this.props.addReclamation : this.props.saveReclamation)({
      ...rec,
      navigate,
    });
  };

  getNavigationFunction = () => {
    const { site } = this.props.match.params;
    return this.props.type === ReclamationType.Claim
      ? (response) => {
          this.props.history.push(
            `/${site}/claims/${response.id}/${this.props.isNew ? 'success' : ''}`
          );
        }
      : () => {
          this.props.history.push(`/${site}/rewashes`);
        };
  };

  title = () => {
    const { intl } = this.props;
    switch (this.props.type) {
      case ReclamationType.Rewash:
        return this.props.isNew
          ? intl.formatMessage({ id: 'addRewash' })
          : intl.formatMessage({ id: 'editRewash' });
      case ReclamationType.Claim:
      default:
        return this.props.isNew
          ? intl.formatMessage({ id: 'addClaim' })
          : intl.formatMessage({ id: 'editClaim' });
    }
  };

  /*
   * The state of affectedArea doesn't update when carPart is changed.
   * This function fixes that.
   */
  handleChange = (values) => {
    if (this.props.type === ReclamationType.Claim) {
      const carPartData = this.props.reclamationDamageOptions.find(
        (part) => part.id === values.claimDamagePartId
      );
      const options = carPartData && map(carPartData.options, 'id');

      if (options && !options.includes(values.claimDamageAreaId)) {
        this.props.change('reclamation', 'claimDamageAreaId', options[0] || null);
      }
    }
  };

  render() {
    // Force status change when editing Incomplete reclamation
    const initialReclamation = {
      ...this.props.initialReclamation,
    };
    if (!this.props.isNew && initialReclamation.status === ReclamationStatus.Incomplete) {
      initialReclamation.status = ReclamationStatus.NeedsAttention;
    }

    return (
      <ReclamationForm
        type={this.props.type}
        onSubmit={(reclamation) => this.onSubmit({ reclamation, page: 0 })}
        initialValues={initialReclamation}
        employees={this.props.employees}
        sites={this.props.sites}
        title={this.title()}
        onReady={() => {
          this.props.triggerSubmit('reclamation');
        }}
        onChange={this.handleChange}
        disabled={checkErrors(this.props.errors) || this.props.isLoading}
        isNew={this.props.isNew}
        enableReinitialize={this.props.isNew}
        currentStatus={this.props.initialReclamation.status}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const type = ownProps.location.pathname.split('/')[2];
  const employees = selectEmployees(state);
  const apiRequests = selectApiRequests(state);
  const isLoading = get(apiRequests, `${actions.ADD_RECLAMATION}.isLoading`, false);
  const reclamationDamageOptions = selectReclamationDamageOptions(state);
  const initialFormValues = {
    status: ReclamationStatus.Incomplete,
    type: PluralReclamationType[type],
    issueSite: { id: Number(selectSite(state)) },
  };

  if (PluralReclamationType[type] === ReclamationType.Claim && reclamationDamageOptions.length) {
    initialFormValues.claimDamagePartId = reclamationDamageOptions[0].id;
    if (reclamationDamageOptions[0].options.length) {
      initialFormValues.claimDamageAreaId = reclamationDamageOptions[0].options[0].id;
    }
  }

  const { issueSite, ...otherProps } = ownProps.initialReclamation
    ? ownProps.initialReclamation
    : new Reclamation(initialFormValues);

  return {
    type: PluralReclamationType[type],
    initialReclamation: { issueSiteId: issueSite.id, ...otherProps },
    employees,
    sites: selectSites(state),
    errors: getFormSyncErrors('reclamation')(state),
    fromDate: selectFromDate(state),
    untilDate: selectUntilDate(state),
    reclamationDamageOptions,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      triggerSubmit: submit,
      fetchEmployees,
      fetchSites,
      change,
    },
    dispatch
  );
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(ReclamationFormWrapper)
);
