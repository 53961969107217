import * as CarQueryAPI from './api/car-query';
import * as OperatorGroupAPI from './api/operator-group';
import * as SiteAPI from './api/sites';
import { ModalType } from './components/ModalRoot';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const API_REQUEST = 'API_REQUEST';
export const ADD_TASK_COMPLETION = 'ADD_TASK_COMPLETION';
export const COMPLETE_TASK_COMPLETION = 'COMPLETE_TASK_COMPLETION';
export const COMPLETE_TASK_COMPLETION_AOT = 'COMPLETE_TASK_COMPLETION_AOT';
export const SNOOZE_TASK_COMPLETION = 'SNOOZE_TASK_COMPLETION';
export const FETCH_SITES = 'FETCH_SITES';
export const FETCH_SITE_SETTINGS = 'FETCH_SITE_SETTINGS';
export const FETCH_CAR_MAKES = 'FETCH_CAR_MAKES';
export const FETCH_CAR_MODELS = 'FETCH_CAR_MODELS';
export const FETCH_SITE_OPERATIONS = 'FETCH_SITE_OPERATIONS';
export const FETCH_ZENDESK_KEY = 'FETCH_ZENDESK_KEY';
export const FETCH_OPERATOR_GROUP = 'FETCH_OPERATOR_GROUP';

export const showDialog = (modalType: ModalType, modalProps: any): any => {
  return {
    type: SHOW_DIALOG,
    modalType,
    modalProps,
  };
};

export const hideDialog = (): any => ({ type: HIDE_DIALOG });

export const fetchSites = (): any => ({
  type: API_REQUEST,
  callAPI: SiteAPI.fetchSites,
  action: FETCH_SITES,
});

export const fetchSiteSettings = (history: any, route: string): any => ({
  type: API_REQUEST,
  callAPI: SiteAPI.fetchSite,
  action: FETCH_SITE_SETTINGS,
  meta: {
    errorApi: 'fetchSite',
    navigate: () => history.push(route),
    errorMessage: 'Failed to fetch site',
  },
});

export const fetchCarMakes = (): any => ({
  type: API_REQUEST,
  callAPI: CarQueryAPI.getMakes,
  action: FETCH_CAR_MAKES,
});

export const fetchCarModels = (makeId: number): any => ({
  type: API_REQUEST,
  callAPI: CarQueryAPI.getModels,
  action: FETCH_CAR_MODELS,
  data: { makeId },
  meta: { makeId },
});

export const fetchSiteOperations = (): any => ({
  type: API_REQUEST,
  callAPI: SiteAPI.fetchSiteOperations,
  action: FETCH_SITE_OPERATIONS,
});

export const fetchZendeskKey = (): any => ({
  type: API_REQUEST,
  callAPI: OperatorGroupAPI.fetchOperatorGroupPublic,
  action: FETCH_ZENDESK_KEY,
});

export const fetchOperatorGroup = (): any => ({
  type: API_REQUEST,
  callAPI: OperatorGroupAPI.fetchOperatorGroupPublic,
  action: FETCH_OPERATOR_GROUP,
});
