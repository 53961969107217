import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { hasRole, hasRoleInOperatorGroup, RoleTypes } from '../../../core/components/Permission';
import { selectRole } from '../../../store/authReducer';

interface TaskDropdownProps {
  intl: any;
  options: any;
  taskInEdit: any;
}

const TaskDropdown = ({ intl, options, taskInEdit }: TaskDropdownProps) => {
  const myRole = useSelector(selectRole);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [buttonText, setButtonText] = React.useState(
    intl?.formatMessage({ id: 'maintenanceTasks' })
  );
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const dropdownChange = (value: string, callback: any = () => {}, filter = true) => {
    if (filter) {
      setButtonText(value);
    }
    callback();
  };

  useEffect(() => {
    if (taskInEdit && taskInEdit.type === 1) {
      const selectedOption = options.find(
        (option: { textId: any }) => option.textId === taskInEdit.name
      );
      if (selectedOption) {
        dropdownChange(
          selectedOption.project
            ? selectedOption.textId
            : intl?.formatMessage({ id: selectedOption.textId }),
          selectedOption.callback,
          selectedOption.filter
        );
        setButtonText(taskInEdit.name);
      }
    }
  }, [taskInEdit, options]);

  return (
    <Dropdown
      className="maintenance-site-dropdown"
      disabled={!hasRole(myRole, RoleTypes.Operator)}
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle className="dropdown-title">
        {buttonText}
        {/* hidden until maintenance task phase 2: <i className="icon icon-chevron-down"></i> */}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-left">
        {options?.map((value: any) => (
          <DropdownItem
            key={value.textId}
            onClick={() =>
              dropdownChange(
                value.project ? value.textId : intl?.formatMessage({ id: value.textId }),
                value.callback,
                value.filter
              )
            }
          >
            {value.project ? value.textId : intl?.formatMessage({ id: value.textId })}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default TaskDropdown;
