import { PropTypes } from 'prop-types';
import React from 'react';
import { Weekdays } from '../types';

export const convertScheduleToSet = (str = '') => {
  const set = new Set();
  let i;
  for (i = 0; i < str.length; i += 1) {
    set.add(Number(str.charAt(i)));
  }
  return set;
};

export const convertSetToString = (set = []) => {
  return Array.from(set)
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    })
    .join('');
};

const WeeklyRepeatOptions = ({ input: { value, onChange } }) => {
  const scheduleSet = convertScheduleToSet(value);
  const onValueChange = ({ target }) => {
    const val = Number(target.value);
    const newWeeklySchedule = new Set(scheduleSet);
    if (newWeeklySchedule.has(val)) {
      newWeeklySchedule.delete(val);
    } else {
      newWeeklySchedule.add(val);
    }
    onChange(convertSetToString(newWeeklySchedule));
  };
  const options = Weekdays.map((day, index) => {
    const checked = scheduleSet.has(index);
    return (
      <div key={day} className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            value={index}
            onChange={onValueChange}
            checked={checked}
          />{' '}
          {day}
        </label>
      </div>
    );
  });
  return <div className="form-group">{options}</div>;
};

WeeklyRepeatOptions.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

export default WeeklyRepeatOptions;
