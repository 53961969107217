import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/shifts';

export const FETCH_SHIFTS = 'FETCH_SHIFTS';
export const GET_SHIFT = 'GET_SHIFT';
export const ADD_SHIFT = 'ADD_SHIFT';
export const SAVE_SHIFT = 'SAVE_SHIFT';
export const DELETE_SHIFT = 'DELETE_SHIFT';

export const fetchShifts = (query) => ({
  type: API_REQUEST,
  callAPI: API.fetchShifts,
  action: FETCH_SHIFTS,
  data: query,
});

export const getShift = (id, employeeId) => ({
  type: API_REQUEST,
  callAPI: API.getShift,
  data: { id, employeeId },
  action: GET_SHIFT,
});

export const addShift = (shift) => ({
  type: API_REQUEST,
  callAPI: API.postShift,
  data: shift,
  action: ADD_SHIFT,
  callback: fetchShifts,
  closeModal: true,
});

export const saveShift = (shift) => ({
  type: API_REQUEST,
  callAPI: API.patchShift,
  data: shift,
  action: SAVE_SHIFT,
  callback: fetchShifts,
  closeModal: true,
});

export const deleteShift = (id, employeeId) => ({
  type: API_REQUEST,
  callAPI: API.deleteShift,
  data: { id, employeeId },
  action: DELETE_SHIFT,
  callback: fetchShifts,
  closeModal: true,
});
