import { useEffect, useState } from 'react';
import fs from 'fs';
import { useIntl } from 'react-intl';
import { profitAndLossExpectedHeadersAndData } from '../helpers';
import { generateToastr } from '../../../core/toastMessages';
import ProgressBar from '../../../core/components/ProgressBar';
import Papa from 'papaparse';

interface FileUploadProps {
  siteLabel: string;
  siteId: number;
  allowedFiletypes: string[];
  selectedFiles: number[];
  displayFiletypes?: boolean;
  fileAddedCallback: any;
  inputId: string;
  isLoading: boolean;
}

interface PapaParseResult {
  data: any[];
  errors: any[];
  meta: any;
}

const SiteFileUpload = ({
  siteLabel,
  siteId,
  allowedFiletypes,
  displayFiletypes = false,
  fileAddedCallback,
  inputId,
  isLoading,
  selectedFiles,
}: FileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFilename] = useState('');
  const [isDragging, setDragging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const fieldId = `file_upload_siteId_${inputId}`;
  const fileTypes = allowedFiletypes.map((type) => `.${type},`).join('');
  const intl = useIntl();
  const reader = new FileReader();

  const validFileType = (filename: string) => {
    const extension = filename.split('.').pop() || '';
    if (!allowedFiletypes.includes(extension)) {
      generateToastr('error', intl.formatMessage({ id: 'invalidFileType' }), 'Please use CSV', {
        timeOut: 10000,
      });

      return false;
    }
    return allowedFiletypes.includes(extension);
  };

  const modifyFile = (oldFile: File) => {
    const newFile = new File([oldFile], `${siteId}-${oldFile.name}`, {
      type: oldFile.type,
      lastModified: oldFile.lastModified,
    });

    return newFile;
  };

  const checkForInvalidRows = (values: string[]) => {
    if (values[0].length !== 2) {
      generateToastr('error', intl.formatMessage({ id: 'profitAndLostAdditionalColumns' }), {
        timeOut: 10000,
      });
      return true;
    }
  };

  const checkForNegativeValues = (initialData: string[]) => {
    for (let i = 0; i < initialData.length; i++) {
      if (
        (initialData[i] && initialData[i].includes('(')) ||
        (initialData[i] && Number(initialData[i].replace(/[^0-9.-]+/g, '')) < 0) ||
        parseFloat(initialData[i]) < 0
      ) {
        generateToastr(
          'error',
          `${intl.formatMessage({ id: 'profitAndLostNegative' })}: ${initialData[i]}`,
          {
            timeOut: 10000,
          }
        );
        return true;
      }
    }
  };

  const checkForMissingHeaders = (data: string[]) => {
    const headers = data.map((row: any) => row[0]);
    const missingHeaders = profitAndLossExpectedHeadersAndData.headers.filter(
      (header) => !!headers && !headers.includes(header)
    );
    return missingHeaders;
  };

  // using papaparse to parse the file
  const parseCSVWithPapa = (unmodifiedFile: File) => {
    Papa.parse(unmodifiedFile, {
      complete: (results: PapaParseResult) => {
        const data: string[] = results.data;
        const missingHeaders = checkForMissingHeaders(data);
        const isInvalidRows = checkForInvalidRows(data);
        const amount = data.map((row: any) => row[1]);
        const isNegativeValues = checkForNegativeValues(amount);
        if (missingHeaders.length > 0 || isInvalidRows || isNegativeValues) {
          setHasError(true);
          setFile(null);
          generateToastr(
            'error',
            intl.formatMessage({ id: 'invaildProfitAndLossFile' }),
            ...missingHeaders,
            {
              timeOut: 10000,
            }
          );
        } else {
          const modifiedContent = modifyFile(unmodifiedFile);
          setFile(modifiedContent);
        }
      },
    });
  };

  const validateFileColumns = (content: File | null) => {
    if (content) {
      reader.readAsText(content);
      reader.onload = () => {
        if (!reader.result) {
          setHasError(true);
          return;
        }
        parseCSVWithPapa(content);
      };
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files?.length) {
      if (validFileType(e.target.files[0]?.name)) {
        validateFileColumns(e.target.files[0]);
      } else {
        e.target.value = null;
        setHasError(true);
      }
    }
  };
  const handleDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(true);
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragging(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      if (validFileType(droppedFiles[0]?.name)) {
        validateFileColumns(droppedFiles[0]);
      } else {
        setHasError(true);
      }
    }
    setDragging(false);
  };

  useEffect(() => {
    if (file) {
      setFilename(file.name);
      fileAddedCallback({ siteId, file });
    }
  }, [file]);

  return isLoading && selectedFiles.includes(siteId) ? (
    <div className="form-group file-upload has-file loading">
      <p className="label">{siteLabel}</p>
      <ProgressBar />
    </div>
  ) : (
    <div
      className={`form-group file-upload${file ? ' has-file' : ''}${isDragging ? ' dragging' : ''}${
        hasError ? ' error' : ''
      }`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onAnimationEnd={() => setHasError(false)}
    >
      <p className="label">
        <i className={`uil uil-check-circle form-checkmark${file ? ' visible' : ''}`}></i>
        <span>
          {siteLabel}
          {fileName && <span className="filename"> ({fileName})</span>}
        </span>
      </p>
      <div className="file-picker">
        {displayFiletypes && (
          <p className="filetypes">{fileTypes.substring(0, fileTypes.length - 1)}</p>
        )}
        <label htmlFor={fieldId}>
          <input
            type="file"
            id={fieldId}
            name={fieldId}
            accept={fileTypes}
            onChange={handleChange}
          />
          {file
            ? intl.formatMessage({ id: 'updateFile' })
            : intl.formatMessage({ id: 'chooseFile' })}
        </label>
      </div>
    </div>
  );
};

export default SiteFileUpload;
