import * as api from '../api';

export const postRepairItem = (args) => {
  const {
    options: { siteId },
    data: { reclamationId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/reclamations/${reclamationId}/repair-items`, args);
};

export const deleteRepairItem = (args) => {
  const {
    options: { siteId },
    data: { reclamationId, repairItemId },
  } = args;
  return api.callPost(
    `/manager/sites/${siteId}/reclamations/${reclamationId}/repair-items/${repairItemId}/archive`,
    {
      options: args.options,
    }
  );
};
