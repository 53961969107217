import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { deleteInventoryItem } from '../actions';
import { ControlledRichText } from '../../../core/formValidation';

const DeleteInventoryDialog = ({ title, onSubmitCallback, isOpen, inventoryItem, intl }) => {
  const dispatch = useDispatch();
  const [deleteAll, setDeleteAll] = useState(false);

  const onSubmit = () => {
    dispatch(deleteInventoryItem({ id: inventoryItem.id, cascadeDelete: deleteAll }));
    onSubmitCallback();
    closeDialog();
  };

  const closeDialog = () => {
    dispatch(hideDialog());
  };

  return (
    <ModalDialog
      title={title}
      className={`enhanced-dialog narrow inventory-delete${deleteAll ? ' warning' : ''}`}
      onReady={onSubmit}
      isOpen={isOpen}
      close={closeDialog}
      submitButtonText={
        deleteAll
          ? intl.formatMessage({ id: 'archiveInventoryItems' })
          : intl.formatMessage({ id: 'archiveInventoryItem' })
      }
    >
      {inventoryItem.priority ? (
        <>
          <p className={`form-alert warning${!deleteAll ? ' hidden' : ''}`}>
            <i className="uil uil-info-circle"></i>
            {intl.formatMessage({ id: 'essentialInventoryDeleteWarning' })}
          </p>
          <div className="delete-content">
            <p>
              {intl.formatMessage(
                {
                  id: 'inventoryItemArchiveConfirmation',
                },
                { name: inventoryItem.name }
              )}
            </p>
            <label>
              <input
                type="checkbox"
                onChange={() => setDeleteAll(!deleteAll)}
                checked={deleteAll}
              />{' '}
              {intl.formatMessage({ id: 'essentialInventoryDeleteCheckbox' })}
            </label>
          </div>
        </>
      ) : (
        <div className="delete-content">
          <p>
            {intl.formatMessage(
              { id: 'inventoryItemArchiveConfirmation' },
              { name: inventoryItem.name }
            )}
          </p>
        </div>
      )}
    </ModalDialog>
  );
};

export default DeleteInventoryDialog;
