import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { showDialog } from '../../../core/actions';
import { selectSite } from '../../../store/authReducer';
import { patchReclamation } from '../../reclamations/actions';
import { IssueSiteStatus } from '../../reclamations/models';

class IssueSiteApproval extends PureComponent {
  patchStatus = (status, note) => {
    this.props.patchReclamation({
      reclamation: {
        id: this.props.reclamation.id,
        issueSiteStatus: status,
        issueSiteNote: note,
      },
      options: {
        siteId: this.props.reclamation.siteId,
      },
    });
  };

  approveIssueSite = () => {
    this.patchStatus(IssueSiteStatus.Accepted);
  };

  declineIssueSite = (values) => {
    this.patchStatus(IssueSiteStatus.Declined, values.note);
  };

  isOutgoingReclamation = () => {
    return (
      this.props.reclamation.siteId &&
      this.props.reclamation.siteId === this.props.currentSite &&
      this.props.reclamation.issueSite &&
      this.props.reclamation.issueSite.id !== this.props.currentSite
    );
  };

  isIncomingReclamation = () => {
    return (
      this.props.reclamation.issueSite &&
      this.props.reclamation.issueSite.id === this.props.currentSite &&
      this.props.reclamation.issueSite.id !== this.props.reclamation.siteId
    );
  };

  outgoingPending = () => {
    const intl = this.props.intl;
    return (
      <div className="alert alert-info" role="alert">
        {intl.formatMessage({ id: 'issueSitePending' })}
      </div>
    );
  };

  outgoingDeclined = () => {
    const hasNote = !isEmpty(this.props.reclamation.issueSiteNote);
    const intl = this.props.intl;
    return (
      <div className="alert alert-danger" role="alert">
        {hasNote
          ? intl.formatMessage({ id: 'issueSiteDeclinedWithNote' }) +
            this.props.reclamation.issueSiteNote
          : intl.formatMessage({ id: 'issueSiteDeclined' })}
      </div>
    );
  };

  openDeclineModal = () => {
    this.props.showDialog('DECLINE_ISSUE_SITE', {
      onSubmit: this.declineIssueSite,
    });
  };

  incomingPending = () => {
    const intl = this.props.intl;
    return (
      <div className="row">
        <div className="col-lg-6">
          <i className="icon icon-warning" />
          <div className="approval-title">
            <h3 className="d-table-cell">
              {intl.formatMessage({ id: 'claimReportedByAnotherSite' })}
            </h3>
          </div>
        </div>
        <div className="form-group form-inline col-lg-6 approval-action">
          <button type="button" onClick={this.approveIssueSite} className="btn btn-accept mr-2">
            {intl.formatMessage({ id: 'accept' })}
          </button>
          <button type="button" onClick={this.openDeclineModal} className="btn btn-danger">
            {intl.formatMessage({ id: 'decline' })}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const isPendingReclamation = this.props.reclamation.issueSiteStatus === IssueSiteStatus.Pending;
    const isDeclinedReclamation =
      this.props.reclamation.issueSiteStatus === IssueSiteStatus.Declined;
    const isOutgoingReclamation = this.isOutgoingReclamation();
    const isIncomingReclamation = this.isIncomingReclamation();

    let component;
    if (isOutgoingReclamation && isPendingReclamation) {
      component = this.outgoingPending();
    } else if (isIncomingReclamation && isPendingReclamation) {
      component = this.incomingPending();
    } else if (isOutgoingReclamation && isDeclinedReclamation) {
      component = this.outgoingDeclined();
    }

    if (component) {
      return <div className="issuesite-approval">{component}</div>;
    }
    return null;
  }
}

IssueSiteApproval.propTypes = {
  reclamation: PropTypes.shape({
    id: PropTypes.number,
    siteId: PropTypes.number,
    issueSiteStatus: PropTypes.number,
    issueSite: PropTypes.shape({
      id: PropTypes.number,
    }),
    issueSiteNote: PropTypes.string,
  }),
  patchReclamation: PropTypes.func.isRequired,
  currentSite: PropTypes.number.isRequired,
  intl: PropTypes.shape({}).isRequired,
  showDialog: PropTypes.func.isRequired,
};
IssueSiteApproval.defaultProps = {
  reclamation: {},
};

const mapStateToProps = (state) => ({
  currentSite: Number(selectSite(state), 10),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchReclamation,
      showDialog,
    },
    dispatch
  );
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'issueSiteApproval' }),
  injectIntl
);

export default enhance(IssueSiteApproval);
