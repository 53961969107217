import React, { useCallback } from 'react';
import bgImageTwo from '../../../images/bg_image_1.jpg';
import bgImageOne from '../../../images/bg_image_2.jpg';
import bgImageThree from '../../../images/bg_image_3.jpg';
import bgImageFour from '../../../images/bg_image_4.jpg';
import bgImageFive from '../../../images/bg_image_5.jpg';

const LoginSlideshow = () => {
  const images = [
    {
      id: 1,
      url: bgImageOne,
    },
    {
      id: 2,
      url: bgImageTwo,
    },
    {
      id: 3,
      url: bgImageThree,
    },
    {
      id: 4,
      url: bgImageFour,
    },
    {
      id: 5,
      url: bgImageFive,
    },
  ];
  return (
    <div className="background-slideshow">
      {images.map((image, index) => (
        <div
          key={image.id}
          className={`background-slide ${index === 0 ? 'active' : ''}`}
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,.1), rgba(0,0,0,.1)), url(${image.url})`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default LoginSlideshow;
