import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import AsOfDate from '../../../core/components/AsOfDate';
import DataTable from '../../../core/components/DataTable';
import * as utils from '../../../core/utils';
import { selectSiteName } from '../../settings/reducer';
import { setFromDate, setUntilDate } from '../../tasks/actions';
import { Interval } from '../../tasks/models';
import { selectFromDate, selectUntilDate } from '../../tasks/reducer';
import { TaskCompletionStatus } from '../../tasks/types';
import { clearTaskCompletionsForTask, getTaskCompletionsForTask } from '../actions';
import {
  selectTaskCompletionsForTask,
  selectTaskCompletionsForTaskPage,
  selectTaskCompletionsForTaskPageSize,
  selectTaskCompletionsForTaskTotal,
} from '../reducer';

class History extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    taskCompletions: PropTypes.arrayOf(PropTypes.shape({})),
    getTaskCompletionsForTask: PropTypes.func.isRequired,
    clearTaskCompletionsForTask: PropTypes.func.isRequired,
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    fromDate: PropTypes.shape({
      isSame: PropTypes.func,
      format: PropTypes.func,
    }),
    untilDate: PropTypes.shape({ format: PropTypes.func }),
    setFromDate: PropTypes.func.isRequired,
    setUntilDate: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.number.isRequired,
      interval: PropTypes.number.isRequired,
      isEssentialTask: PropTypes.bool,
    }),
    siteName: PropTypes.string,
  };

  componentDidMount() {
    this.getTaskCompletionPage(0);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.fromDate.isSame(prevProps.fromDate) ||
      this.props.task != prevProps.task ||
      !this.props.untilDate.isSame(prevProps.untilDate)
    ) {
      this.getTaskCompletionPage(0);
    }
  }

  componentWillUnmount() {
    this.props.clearTaskCompletionsForTask();
  }

  getTaskCompletionPage = (page) => {
    this.props.getTaskCompletionsForTask({
      id: this.props.task.id,
      page,
      pageSize: 10,
      fromDate: this.props.fromDate.format('YYYY-MM-DD'),
      untilDate: this.props.untilDate.format('YYYY-MM-DD'),
    });
  };

  getPaginationInfo() {
    return {
      total: this.props.total,
      page: this.props.page,
      pageSize: this.props.pageSize,
      goToPage: this.getTaskCompletionPage,
    };
  }

  getDue = (taskCompletion) => {
    if (this.props.task.interval === Interval.CarCount) {
      return taskCompletion.scheduleCarCount;
    }
    if (taskCompletion.scheduleEnd) {
      if (this.props.task.interval === Interval.Hourly) {
        return moment(taskCompletion.scheduleEnd).format('MM/DD/YYYY HH:m a');
      }
      return moment(taskCompletion.scheduleEnd).format('MM/DD/YYYY');
    }
    return this.props.intl.formatMessage({ id: 'notAvailable' });
  };

  initializeDatatable = (taskCompletions) => {
    const data = taskCompletions.map((taskCompletion) => {
      const employee = taskCompletion.employee;
      const due = this.getDue(taskCompletion);
      const late =
        moment().utc().isAfter(moment(taskCompletion.scheduleEnd).utc()) &&
        utils.toEnum(TaskCompletionStatus, taskCompletion.status) === TaskCompletionStatus.Pending;
      const rowData = {
        _id: taskCompletion.id,
        due,
        status: late ? this.props.intl.formatMessage({ id: 'expired' }) : taskCompletion.status,
        timestamp:
          taskCompletion.status !== 'Pending'
            ? moment(taskCompletion.timestamp).format('MM/DD/YYYY h:mm:ss a')
            : '',
        carCount: taskCompletion.carCount,
        employee: employee ? `${employee.firstName} ${employee.lastName}` : '',
      };
      if (this.props.task.interval !== Interval.CarCount) {
        delete rowData.carCount;
      }
      return rowData;
    });

    const headers = [
      { name: this.props.intl.formatMessage({ id: 'due' }) },
      { name: this.props.intl.formatMessage({ id: 'status' }) },
      { name: this.props.intl.formatMessage({ id: 'timestamp' }) },
      { name: this.props.intl.formatMessage({ id: 'carCount' }) },
      { name: this.props.intl.formatMessage({ id: 'teamMember' }) },
    ];
    if (this.props.task.interval !== Interval.CarCount) {
      headers.splice(3, 1);
    }

    return {
      data,
      headers,
    };
  };

  fetchAsOfDate = () => {
    this.getTaskCompletionPage(0);
  };

  render() {
    return (
      <div>
        <div className="as-of-date-container">
          <AsOfDate
            fromDate={this.props.fromDate}
            untilDate={this.props.untilDate}
            setFromDate={this.props.setFromDate}
            setUntilDate={this.props.setUntilDate}
            fetchWithQuery={this.fetchAsOfDate}
            title={`${this.props.intl.formatMessage({ id: 'tasks' })}: `}
            dateTarget="history"
            intl={this.props.intl}
          />
        </div>
        {this.props.task.isEssentialTask && (
          <div className="mt-2">{`${this.props.intl.formatMessage({
            id: 'site',
          })}: ${this.props.siteName}`}</div>
        )}
        <DataTable
          paginationInfo={this.getPaginationInfo()}
          {...this.initializeDatatable(this.props.taskCompletions)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taskCompletions: selectTaskCompletionsForTask(state),
  page: selectTaskCompletionsForTaskPage(state),
  pageSize: selectTaskCompletionsForTaskPageSize(state),
  total: selectTaskCompletionsForTaskTotal(state),
  fromDate: selectFromDate(state),
  untilDate: selectUntilDate(state),
  siteName: selectSiteName(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTaskCompletionsForTask,
      clearTaskCompletionsForTask,
      setFromDate,
      setUntilDate,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(History);
