import { PropTypes } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const styles = {
  normalBadge: {
    background: '#2097f4',
  },
  activeBadge: {
    background: '#B0312D',
  },
};

const FilterLink = ({ pathname, types, message, isSelected }) => {
  return (
    <NavLink to={types ? { pathname, search: `?types=${types}` } : { pathname }}>
      <span className={`button small filter-link primary ${!isSelected ? 'inverted' : ''}`}>
        {message}
      </span>
    </NavLink>
  );
};

FilterLink.propTypes = {
  types: PropTypes.string,
  message: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

export default FilterLink;
