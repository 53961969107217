import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SlideOut = ({ hide, width, style, children }) => {
  const [render, setRender] = useState(!hide);
  useEffect(() => {
    if (!hide) {
      setRender(true);
    }
  }, [hide]);

  const onTransitionEnd = () => {
    if (hide) {
      setRender(false);
    }
  };

  return (
    <span
      style={{
        width: !hide ? width : '1px',
        ...style,
      }}
      className={!hide ? 'so-slide show' : 'so-slide'}
      onTransitionEnd={onTransitionEnd}
    >
      {render && children}
    </span>
  );
};

SlideOut.defaultProps = {
  hide: false,
};

SlideOut.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.bool,
  width: PropTypes.string,
  style: PropTypes.shape({}),
};

export default SlideOut;
