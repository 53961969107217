import { useDispatch, useSelector } from 'react-redux';
import SiteFileUpload from './SiteFileUpload';
import { useEffect, useState } from 'react';
import { getApiStatus } from '../../../core/utils';
import { clearFileUploadStatus, uploadProfitAndLoss } from '../actions';
import { selectFileUploadStatus } from '../purchaseOrderReducer';
import { generateToastr } from '../../../core/toastMessages';
import { downloadProfitAndLossCSV } from '../helpers';

interface PLUploadFormProps {
  employeeId: number;
  sites: any;
  formReset: any;
  intl: any;
}

interface FileObject {
  siteId: number;
  file: File;
}

const PAndLUploadForm = ({ employeeId, sites, formReset, intl }: PLUploadFormProps) => {
  const [displayedSites, setDisplayedSites] = useState(10);
  const [fileArray, setFiles] = useState<FileObject[]>([]);
  const fileTypes = ['csv'];

  // selectors
  const filesAddedStatus = useSelector((state) => getApiStatus(state, 'UPLOAD_PROFIT_AND_LOSS'));
  const fileUploadStatus = useSelector(selectFileUploadStatus);
  const dispatch = useDispatch();

  const showMoreSites = () => {
    setDisplayedSites(displayedSites + 10);
  };

  const fileAdded = (fileObject: FileObject) => {
    const newFileArray = [...fileArray];
    const updatedFile = newFileArray.find((f) => f.siteId === fileObject.siteId);
    if (updatedFile) {
      updatedFile.file = fileObject.file;
    } else {
      newFileArray.push(fileObject);
    }
    setFiles(newFileArray);
  };

  const handleSubmit = () => {
    dispatch(
      uploadProfitAndLoss({
        employeeId: employeeId,
        files: fileArray,
      })
    );
  };

  const handleFormUploadMessage = () => {
    if (fileUploadStatus === 'success') {
      generateToastr('success', intl.formatMessage({ id: 'fileUploadSuccess' }));
      formReset();
    } else if (fileUploadStatus === 'error') {
      generateToastr('error', intl.formatMessage({ id: 'fileUploadFailure' }));
    }

    dispatch(clearFileUploadStatus());
  };

  useEffect(() => {
    handleFormUploadMessage();
  }, [fileUploadStatus]);

  return (
    <>
      <div className="p-l-upload upload-form">
        {sites.slice(0, displayedSites).map((site: any) => (
          <SiteFileUpload
            siteLabel={site.externalId ? `${site.externalId} - ${site.name}` : site.name}
            siteId={site.id}
            allowedFiletypes={fileTypes}
            fileAddedCallback={fileAdded}
            inputId={site.id}
            key={site.id}
            isLoading={filesAddedStatus}
            selectedFiles={fileArray.map((f) => f.siteId)} // This is the prop that is being used in the SiteFileUpload component
          />
        ))}
        {displayedSites < sites.length && (
          <button className="button" onClick={showMoreSites}>
            {intl.formatMessage({ id: 'showMore' })}
          </button>
        )}
      </div>
      <button
        className="button confirm full-width"
        onClick={handleSubmit}
        disabled={!fileArray.length}
      >
        {intl.formatMessage({ id: 'submit' })}
      </button>
    </>
  );
};

export default PAndLUploadForm;
