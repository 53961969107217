import { put, takeEvery } from 'redux-saga/effects';
import { fetchAlerts } from './actions';

export function* updateAlerts() {
  yield put(fetchAlerts());
}

export function* watchSiteSelectionSaga() {
  yield takeEvery('ADD_DETERGENT_READING_SUCCESS', updateAlerts);
  yield takeEvery('ACKNOWLEDGE_ALERT_SUCCESS', updateAlerts);
  yield takeEvery('ARCHIVE_ALERT_SUCCESS', updateAlerts);
}
