import moment from 'moment';
import * as api from '../api';

const getQueryWithDates = (query, from, until) => ({
  ...query,
  fromDate: from || moment().subtract(90, 'days').format('YYYY-MM-DD'),
  untilDate: until || moment().endOf('day').format('YYYY-MM-DD'),
});

export const getReclamations = async (args) => {
  const maxRetries = 3;
  let retries = 0;

  const {
    options: { siteId },
    data: {
      query: { fromDate, untilDate },
    },
  } = args;
  const query = getQueryWithDates(args.data.query, fromDate, untilDate);

  while (retries < maxRetries) {
    const response = await api.callGet(`/manager/sites/${siteId}/reclamations`, {
      ...args,
      data: { query },
    });

    const lastPage = Math.ceil(response.total / response.pageSize - 1);
    if (response.page != lastPage && response.results.length != response.pageSize) {
      retries++;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } else {
      return response;
    }
  }
};

export const getClaims = (args) => {
  const {
    options: { siteId },
    data: {
      query: { fromDate, untilDate },
    },
  } = args;
  const query = getQueryWithDates(args.data.query, fromDate, untilDate);
  return api.callGet(`/manager/sites/${siteId}/claims`, {
    ...args,
    data: { query },
  });
};
export const getClaimCounts = (args) => {
  const {
    options: { siteId },
  } = args;
  const _args = {
    ...args,
    options: { ...args.options },
  };
  return api.callGet(`/manager/sites/${siteId}/claimCounts`, _args);
};

export const getCarPartReports = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/claims/report/car-part`, args);
};

export const getCarMakeReports = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/claims/report/car-make`, args);
};

export const getCarModelReports = (args) => {
  const {
    options: { siteId },
    data: { makeId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/claims/report/car-make/${makeId}/models`, args);
};

export const patchReclamation = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/reclamations/${id}`, args);
};

export const postReclamation = (args) => {
  const { siteId } = args.options;
  return api.callPost(`/manager/sites/${siteId}/reclamations`, args);
};

export const deleteReclamation = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callDelete(`/manager/sites/${siteId}/reclamations/${id}`, args);
};

export const archiveReclamation = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/reclamations/${id}/archive`, args);
};

export const unarchiveReclamation = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/reclamations/${id}/unarchive`, args);
};

export const getReclamation = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/reclamations/${id}`, args);
};

export const postReclamationNote = (args) => {
  const {
    options: { siteId },
    data: { reclamationId, note },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/reclamations/${reclamationId}/notes`, {
    data: note,
    options: args.options,
  });
};

export const archiveReclamationNote = (args) => {
  const {
    options: { siteId },
    data: { reclamationId, noteId },
  } = args;
  return api.callPost(
    `/manager/sites/${siteId}/reclamations/${reclamationId}/notes/${noteId}/archive`,
    {
      options: args.options,
    }
  );
};

export const getClaimDamageOptions = (args) => {
  return api.callGet(`/manager/claims-damage-options`, args);
};

export const postClaimDamageOptions = (args) => {
  return api.callPost(`/manager/claims-damage-options`, args);
};
