// TOOD: move to api folder
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import SearchParams from './api/searchParams';
import * as config from './config';

const ACCEPT_HEADER = 'Accept';
const CONTENT_TYPE_HEADER = 'Content-Type';
const BACKEND_URL = config.getBackendUrl();

const parseJSON = (response) => {
  return response
    .json()
    .then((json) => {
      return Array.isArray(json)
        ? { results: json, responseStatus: response.status }
        : { ...json, responseStatus: response.status };
    })
    .catch(() => {
      const error = { message: response.statusText };
      return Promise.reject({ responseStatus: response.status, error });
    });
};

const checkStatus = (response) => {
  const { responseStatus, ...originalResponse } = response;
  if (responseStatus >= 200 && responseStatus < 300) {
    return originalResponse;
  }
  return Promise.reject(response);
};

const defaultOptions = {
  headers: {
    [ACCEPT_HEADER]: 'application/json',
    [CONTENT_TYPE_HEADER]: 'application/json',
  },
};

const supportedFetchOptions = ['method', 'body', 'headers', 'credentials', 'signal'];

// TODO: use https://github.com/paularmstrong/normalizr
export const callApi = (url, opts, useDefaultOptions = true) => {
  const options = pick(
    merge({}, useDefaultOptions ? defaultOptions : {}, opts),
    supportedFetchOptions
  );
  return fetch(`${BACKEND_URL}${url}`, options).then(parseJSON).then(checkStatus);
};

export const callGet = (url, { options, data } = {}) => {
  const query = data && data.query ? new SearchParams(data.query).toString() : null;
  const fullUrl = query ? `${url}?${query}` : url;
  return callApi(fullUrl, options);
};

export const callPatch = (url, { data, options } = {}) => {
  const opts = {
    method: 'PATCH',
    body: JSON.stringify(data),
  };

  const merged = merge({}, opts, options);
  return callApi(url, merged);
};

export const callPost = (url, { data, options } = {}) => {
  const opts = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  const merged = merge({}, opts, options);
  return callApi(url, merged);
};

export const callPostWithFile = (url, { data, options } = {}) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key == 'files') {
      Object.keys(data[key]).forEach((file) => {
        formData.append('files', data[key][file].file);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  const opts = {
    method: 'POST',
    body: formData,
  };

  const merged = merge({}, opts, options);
  return callApi(url, merged, false);
};

export const callPut = (url, { data, options } = {}) => {
  const opts = {
    method: 'PUT',
    body: JSON.stringify(data),
  };
  const merged = merge({}, opts, options);
  return callApi(url, merged);
};

export const callPostWithFormData = (url, { data, options } = {}) => {
  const opts = {
    method: 'POST',
    body: data,
  };
  const merged = merge({}, opts, options);
  return callApi(url, merged, false);
};

export const callDelete = (url, { options } = {}) => {
  const opts = { method: 'DELETE' };
  const merged = merge({}, opts, options);
  return callApi(url, merged);
};
