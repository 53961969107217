import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../../core/actions';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from 'reactstrap';
import ContentTabs from '../../../core/components/ContentTabs';
import { EmbeddedUrlCarousel } from '../../../core/components/EmbeddedUrlCarousel';
import ReadOnlyEditor from '../../../core/components/ReadOnlyEditor';
import History from '../../taskDetail/components/History';
import Parts from '../../taskDetail/components/Parts';
import TaskDetailNotes from '../../taskDetail/components/TaskDetailNotes';
import {
  deleteEssentialTask,
  deleteTask,
  fetchTasks,
  getMaintenanceTask,
  getTask,
  saveEssentialTask,
  saveTask,
  setMaintenanceTask,
} from '../../tasks/actions';
import TaskDetails from './TaskDetails';
import { Task } from '../../tasks/models';
import TaskLink from './TaskLink';
import { TaskList } from '../containers/MaintenanceTasks';
import { completeTaskCompletion } from '../../overview/actions';
import { createDialogProps } from '../helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import Loader from '../../../core/components/Loader';

interface MaintenanceTaskDetailProps {
  intl: any;
  collapseSelf: any;
  toggleExpand: any;
  paginationInfo: any;
  task: Task;
  tasksFetched: boolean;
  savingTask?: boolean;
  deletingTask?: boolean;
  employees?: any;
  handleDone?: (taskList: TaskList) => void;
}

const MaintenanceTaskDetail = ({
  intl,
  toggleExpand,
  collapseSelf,
  employees,
  handleDone,
  paginationInfo,
  task,
  tasksFetched,
  savingTask,
  deletingTask,
}: MaintenanceTaskDetailProps): JSX.Element => {
  const { page, status } = paginationInfo;
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getMaintenanceTask(task.id));
  }, [tasksFetched, savingTask, deletingTask]);

  const checkLinkedTasks = () => {
    if (task?.linkedTasks?.length > 0) {
      if (task.linkedTasks.length > 1) {
        return (
          <span>
            {intl.formatMessage({ id: 'theseTasksHaveToBeDoneBefore' })}{' '}
            <span style={{ textDecoration: 'underline' }}>
              {intl.formatMessage({ id: 'multipleTasks' })}
            </span>
          </span>
        );
      }
      return (
        <span>
          {intl.formatMessage({ id: 'thisTaskHasToBeDoneBefore' })}{' '}
          <span style={{ textDecoration: 'underline' }}>{task.linkedTasks[0].linkedTaskId}</span>
        </span>
      );
    }
    return <span />;
  };

  const defaultTabStruct = {
    Instructions: (
      <ReadOnlyEditor text={task?.description === undefined ? '' : task?.description} />
    ),
    Details: <TaskDetails intl={intl} task={task} employees={employees} />,
    Videos: <EmbeddedUrlCarousel urls={task?.videos} isVideo={true} />,
    History: <History task={task} />,
    'Linked Tasks': <TaskLink intl={intl} task={task} />,
    Notes: <TaskDetailNotes task={task} />,
  };

  const components = task?.groupId
    ? defaultTabStruct
    : { ...defaultTabStruct, Parts: <Parts task={task} /> };

  const editDialogProps = {
    title: 'Edit Task',
    onSubmit: (editedTask: Task) => {
      return task?.groupId
        ? dispatch(
            saveEssentialTask({
              task: editedTask,
              callback: { type: 'Maintenance', page, status },
            })
          )
        : dispatch(
            saveTask({
              task: editedTask,
              callback: { type: 'Maintenance', page, status },
            })
          );
    },
    location: location,
    isNew: false,
    initialTask: task,
    isEssentialTask: task?.groupId ? true : false,
    modelYears: task.modelYears,
  };

  const handleDeleteTask = () => {
    const { id, name, groupId } = task;
    const isEssentialTask = groupId ? true : false;
    const deletionParams = {
      id,
    };

    const confirmProps = {
      title: intl.formatMessage({ id: 'archiveTask' }),
      text: intl.formatMessage({ id: 'taskArchiveConfirmation' }, { name }),
      onReady: isEssentialTask
        ? () => dispatch(deleteEssentialTask(deletionParams))
        : () => dispatch(deleteTask(deletionParams)),
    };

    dispatch(showDialog('CONFIRM_DIALOG', confirmProps));
  };

  const handleEditTask = () => {
    dispatch(showDialog('UPSERT_TASK', editDialogProps));
  };
  const handleExpand = () => {
    toggleExpand();
  };

  return tasksFetched || savingTask || deletingTask ? (
    <Loader />
  ) : (
    <div className="maintenance-task-detail">
      <div className="task-main">
        <section>
          <div className="task-header">
            <div className="task-badge-container">
              <span className="badge preventative">Preventative</span>
            </div>
            <div className="icon-controls">
              <button
                className="icon resize-task"
                onClick={() => handleExpand()}
                title={intl.formatMessage({ id: 'toggleFullTask' })}
              >
                <i className="icon icon-swap"></i>
              </button>
              <button
                className="icon resize-task"
                onClick={() => handleEditTask()}
                title={intl.formatMessage({ id: 'edit' })}
              >
                <i className="icon icon-pencil"></i>
              </button>
              <button
                className="icon resize-task"
                onClick={() => handleDeleteTask()}
                title={intl.formatMessage({ id: 'delete' })}
              >
                <i className="icon icon-trash"></i>
              </button>
              <button
                className="icon resize-task"
                onClick={() => collapseSelf()}
                title={intl.formatMessage({ id: 'close' })}
              >
                <i className="icon icon-cross"></i>
              </button>
              <button
                className="icon collapse-task mobile-button"
                onClick={() => collapseSelf()}
                title={intl.formatMessage({ id: 'collapseTask' })}
              >
                <i className="icon icon-chevron-left"></i>
              </button>
              <UncontrolledDropdown className="task-actions-dropdown">
                <DropdownToggle
                  className="dropdown-title icon mobile-button"
                  title={intl.formatMessage({ id: 'taskOptions' })}
                >
                  <i className="icon icon-dots-three-vertical"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right" right>
                  <DropdownItem onClick={() => collapseSelf()}>
                    {intl.formatMessage({ id: 'collapseTask' })}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleEditTask()}>
                    {intl.formatMessage({ id: 'editTask' })}
                  </DropdownItem>
                  <DropdownItem divider />
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="task-title">
            <p>{intl.formatMessage({ id: 'taskName' })}</p>
            <h2>{task?.name}</h2>
            {task?.linkedTasks?.length ? (
              <Alert color="warning">{checkLinkedTasks()}</Alert>
            ) : (
              <span />
            )}
          </div>
          <div className="task-tabs">
            <ContentTabs components={components} />
          </div>
        </section>
      </div>
      <div className="task-details-container">
        <TaskDetails intl={intl} task={task} employees={employees} />
      </div>
    </div>
  );
};

export default MaintenanceTaskDetail;
