import { PropTypes } from 'prop-types';
import React from 'react';
import { PurchaseOrderStatus } from '../models';

export const StatusFilter = (props) => {
  const handleChange = ({ target: { value } }) => {
    props.setFilter({
      ...props.currentFilter,
      currentStatus: value,
    });
  };

  return (
    <div className="form-group">
      <label>Status</label>
      <select
        className="form-control"
        value={props.currentFilter.currentStatus}
        onChange={handleChange}
      >
        <option value={PurchaseOrderStatus.All}>All</option>
        <option disabled>------</option>
        <option value={PurchaseOrderStatus.Received}>Received</option>
        <option value={PurchaseOrderStatus.Open}>Not Received</option>
      </select>
    </div>
  );
};

StatusFilter.defaultProps = {
  currentFilter: {
    currentStatus: 'All',
  },
};

StatusFilter.propTypes = {
  setFilter: PropTypes.func,
  currentFilter: PropTypes.shape({
    currentStatus: PropTypes.string,
  }),
};

export default StatusFilter;
