import * as api from '../api';

export const fetchShifts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/shifts`, args);
};

export const getShift = (args) => {
  const {
    options: { siteId },
    data: { id, employeeId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/employees/${employeeId}/shifts/${id}`, args);
};

export const postShift = (args) => {
  const {
    options: { siteId },
    data: { employeeId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/employees/${employeeId}/shifts`, args);
};

export const patchShift = (args) => {
  const {
    options: { siteId },
    data: { id, employeeId },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/employees/${employeeId}/shifts/${id}`, args);
};

export const deleteShift = (args) => {
  const {
    options: { siteId },
    data: { id, employeeId },
  } = args;
  return api.callDelete(`/manager/sites/${siteId}/employees/${employeeId}/shifts/${id}`, args);
};
