import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import PurchaseOrderVendorForm from './PurchaseOrderVendorForm';

const VendorFormWrapper = (props) => {
  return <PurchaseOrderVendorForm {...props} />;
};
const selector = formValueSelector('vendor');
const mapStateToProps = (state) => {
  return {
    fields: selector(state, 'vendors'),
  };
};

const enhance = compose(connect(mapStateToProps, null), reduxForm({ form: 'vendor' }));

export default enhance(VendorFormWrapper);
