import { forEach } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { fieldRequired, requiredInput, Select } from '../../../core/formValidation';
import * as utils from '../../../core/utils';
import { ProductCategory } from '../../products/models';

const ProductForm = ({ intl, operations, isCustomProductNameChecked }) => {
  const getOperationSelects = () => {
    const operationSelects = [{ value: -1, text: intl.formatMessage({ id: 'selectOperation' }) }];

    if (operations) {
      operations.forEach((operation) => {
        operationSelects.push({ value: operation.id, text: operation.name });
      });
    }

    return operationSelects;
  };

  const getCategorySelects = () => {
    const categorySelects = [{ value: -1, text: intl.formatMessage({ id: 'selectCategory' }) }];

    forEach(ProductCategory, (categoryNumber) => {
      const categoryString = utils.toString(ProductCategory, categoryNumber);

      categorySelects.push({
        value: categoryNumber,
        text: utils.spacesToCamelCaseString(categoryString),
      });
    });

    return categorySelects;
  };

  const handleChange = (event) => {
    if (Number(event.target.value) < 0) {
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="form-group">
        <label>{intl.formatMessage({ id: 'operation' })}</label>
        <Field
          name="operation"
          component={Select}
          options={getOperationSelects()}
          parse={Number}
          onChange={handleChange}
          onBlur={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="form-group">
        <label>{intl.formatMessage({ id: 'productName' })}</label>
        <div className="row">
          <div className="col-6">
            <Field
              id="product-name-field"
              className="form-control"
              name="name"
              component={requiredInput}
              validate={[fieldRequired]}
              type="text"
              aria-describedby="productNameHelp"
              placeholder={intl.formatMessage({ id: 'defaultsToOperation' })}
              disabled={!isCustomProductNameChecked}
            />
          </div>
          <div className="col-6 vertically-centered-checkbox">
            <label className="form-check-label">
              <Field
                name="isCustomProductNameChecked"
                component="input"
                className="form-check-input"
                type="checkbox"
              />
              {intl.formatMessage({ id: 'customProductName' })}
            </label>
          </div>
        </div>
        {isCustomProductNameChecked && (
          <div className="alert alert-warning mt-2" role="alert">
            {intl.formatMessage({ id: 'customProductNameWarning' })}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>{intl.formatMessage({ id: 'category' })}</label>
        <Field
          name="category"
          component={Select}
          options={getCategorySelects()}
          parse={Number}
          onChange={handleChange}
          onBlur={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </React.Fragment>
  );
};

ProductForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  operations: PropTypes.arrayOf(PropTypes.shape({})),
  isCustomProductNameChecked: PropTypes.bool,
};

const enhance = compose(injectIntl, reduxForm({ form: 'product' }));

export default enhance(ProductForm);
