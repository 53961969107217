import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasRewashEnabledRedir } from '../core/auth';
import ReclamationEdit from '../views/reclamations/containers/ReclamationEdit';
import ReclamationFormWrapper from '../views/reclamations/containers/ReclamationFormWrapper';
import RewashDetail from '../views/rewashDetail/containers/RewashDetail';
import Rewashes from '../views/rewashes/containers/Rewashes';

const RewashesRoute = () => {
  return (
    <Switch>
      <Route exact path="/:site/rewashes/">
        <Rewashes />
      </Route>
      <Route path="/:site/rewashes/create">
        <ReclamationFormWrapper />
      </Route>
      <Route exact path="/:site/rewashes/:reclamation/edit">
        <ReclamationEdit />
      </Route>
      <Route path="/:site/rewashes/:rewash">
        <RewashDetail />
      </Route>
    </Switch>
  );
};

export default userHasRewashEnabledRedir(RewashesRoute);
