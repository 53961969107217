import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface DependencyModalProps {
  intl: any;
  children: any;
  title: string;
  isOpen: boolean;
  close: any;
  snoozeTask: any;
  completeTask: any;
  className: string;
  showFooter: boolean;
}

const DependencyModal = ({
  intl,
  children,
  title,
  isOpen,
  close,
  snoozeTask,
  completeTask,
  className,
  showFooter,
}: DependencyModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter className="full-width">
          <Button className="submit-button delete" color="secondary" onClick={completeTask}>
            {intl.formatMessage({ id: 'completeTaskAnyway' })}
          </Button>
          {snoozeTask && (
            <Button className="submit-button confirm" color="primary" onClick={snoozeTask}>
              {intl.formatMessage({ id: 'snooze' })}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DependencyModal;
