import React from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasTasksEnabledRedir } from '../core/auth';
import TodaysTasks from '../views/tasks/containers/TodaysTasks';
import MaintenanceTasks from '../views/maintenanceTasks/containers/MaintenanceTasks';
import TaskDetail from '../views/taskDetail/containers/TaskDetail';

const MaintenanceTasksRoute = () => {
  const match = useRouteMatch('/:site/maintenance/:task');
  const history = useHistory();
  return (
    <Switch>
      <Route path="/:site/maintenance/:task">
        <TaskDetail history={history} match={match} />
      </Route>
      <Route path="/:site/maintenance">
        <MaintenanceTasks />
      </Route>
      <Route path="/:site/maintenance/calendar/">
        <TodaysTasks />
      </Route>
    </Switch>
  );
};

export default userHasTasksEnabledRedir(MaintenanceTasksRoute);
