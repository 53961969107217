import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getApiStatus } from '../../../../../core/utils';
import { addEmployee, ADD_EMPLOYEE } from '../../../../employees/actions';
import useForm, { Form, FormValues, userFields } from '../../../hooks/useForm';
import useEmployees from '../../ManageUsers/hooks/useEmployees';

type EmployeeFields = Exclude<FormValues, 'userGroupId' | 'coreSites'>;

const useCreateForm = (): Form & { isNew: boolean } => {
  const dispatch = useDispatch();

  const { getEmployees } = useEmployees();

  const isLoading = useSelector((state) => getApiStatus(state, ADD_EMPLOYEE));

  const formatBody = (values: FormValues): FormValues | EmployeeFields => {
    if (!values.loginCredentialsNeeded) {
      return omit(values, userFields) as EmployeeFields;
    }

    return values;
  };

  const handleSubmit = (values: FormValues) => {
    const postBody = {
      data: {
        body: formatBody(values),
      },
      closeModal: false,
      voidCallback: getEmployees,
    };

    dispatch(addEmployee(postBody));
  };

  const formProps = useForm({
    isLoading,
    onSubmit: handleSubmit,
  });

  return {
    ...formProps,
    isNew: true,
  };
};

export default useCreateForm;
