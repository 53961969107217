import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { fieldRequired, requiredInput } from '../../../core/formValidation';

export const VendorFormFields = ({ fields }) => {
  return (
    <div className="inline-add-item-button">
      {fields.map((vendor, index) => (
        <div key={index}>
          <div>
            <button
              className={classNames('close', 'multi-form-delete')}
              onClick={() => {
                fields.remove(index);
              }}
            >
              <i className="icon icon-squared-cross" />
            </button>
            <div className="mt-3">{`Vendor #${index + 1}`}</div>
            <div className="form-group form-small-row">
              <Field
                className="form-control row-padding"
                name={`${vendor}.name`}
                id="name"
                component={requiredInput}
                type="text"
                aria-describedby="descriptionHelp"
                placeholder="Name"
                validate={[fieldRequired]}
              />
            </div>
          </div>
        </div>
      ))}
      <button
        className={classNames('button')}
        data-testid="addVendorButton"
        onClick={() => {
          fields.push();
        }}
      >
        Add Vendor
      </button>
    </div>
  );
};

VendorFormFields.propTypes = {
  fields: PropTypes.shape({}),
};

export default VendorFormFields;
