export const ProductCategory = {
  SingleWash: 0,
  Subscription: 1,
};

export default class Product {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.creator = props.creator;
    this.createdAt = props.createdAt;
    this.siteId = props.siteId;
    this.coreOperationId = props.coreOperationId;
    this.category = props.category;
  }
}
