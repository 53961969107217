import React from 'react';
import { useIntl } from 'react-intl';
import Loader from './Loader';

interface Props {
  isLoading: boolean;
  header?: string;
  count?: any;
  classNames?: string;
  headerClassName?: string | undefined;
  countClassName?: string | undefined;
  secondHeader?: string;
}

const loader = (): JSX.Element => {
  return (
    <div className="powerbi-loading">
      <Loader />
    </div>
  );
};

const BaseKpi = (props: Props): JSX.Element => {
  const intl = useIntl();
  return props.isLoading ? (
    loader()
  ) : (
    <div className={`${props.classNames || ''}`}>
      <h2 className={props.countClassName ? `${props.countClassName}` : 'count-title'}>
        {props.count === undefined ? intl.formatMessage({ id: 'noCounts' }) : props.count}
      </h2>
      <p className={props.headerClassName ? `${props.headerClassName}` : 'count-text'}>
        {props.header ? intl.formatMessage({ id: props.header }) : ''}
        <br />
        {props.secondHeader ? intl.formatMessage({ id: props.secondHeader }) : ''}
      </p>
    </div>
  );
};

export default BaseKpi;
