import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import { selectUserGroup } from '../../../store/authReducer';
import * as actions from '../actions';
import PurchaseOrderDataGetter from '../components/PurchaseOrderDataGetter';
import {
  selectCategories,
  selectFromDate,
  selectPage,
  selectPageSize,
  selectPurchaseOrders,
  selectTotal,
  selectUntilDate,
  selectVendors,
} from '../purchaseOrderReducer';

export const PurchaseOrders = (props) => {
  return <PurchaseOrderDataGetter {...props} />;
};

const mapStateToProps = (state) => {
  return {
    fromDate: selectFromDate(state),
    untilDate: selectUntilDate(state),
    purchaseOrders: selectPurchaseOrders(state),
    categories: selectCategories(state),
    vendors: selectVendors(state),
    userGroup: selectUserGroup(state),
    pageInfo: {
      total: selectTotal(state),
      page: selectPage(state),
      pageSize: selectPageSize(state),
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDialog,
      ...actions,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter);

export default enhance(PurchaseOrders);
