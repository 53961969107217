import moment from 'moment';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import { SortDirection } from '../../../core/components/DataTableHeaders';
import { getApiStatus } from '../../../core/utils';
import UserGroupPermissions, {
  addPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { toString } from '../../../core/utils';
import { selectRole, selectSession, selectSite } from '../../../store/authReducer';
import { selectSiteExternalId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { fetchEmployees, setSearchParams } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import {
  completeTaskAOT,
  completeTaskCompletion,
  snoozeTaskCompletion,
} from '../../overview/actions';
import { Interval } from '../models';
import {
  selectPage,
  selectPageSize,
  selectMaintenanceTaskInEdit,
  selectSelectedTasks,
  selectTasks,
  selectTotal,
  selectLocationFilter,
  selectStatusFilter,
  selectTypeFilter,
} from '../../tasks/reducer';
import { TaskCompletionStatus } from '../types';
import { createDialogProps, initializeMaintenanceTaskDatatable } from '../helpers';
import {
  addTask,
  clearMaintenanceTask,
  fetchTasks,
  getMaintenanceTask,
  setTypeFilter,
} from '../../tasks/actions';
import MaintenanceTaskDetail from '../components/MaintenanceTaskDetail';
import { Task, TaskStatus } from '../../tasks/models';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import SearchBar from '../../../core/components/SearchBar';
import { Container } from 'reactstrap';
import LocationFilter from '../components/LocationFilter';
import TaskDropdown from '../components/TaskDropdown';
import TaskCompletionModalDialog from '../components/TaskCompletionModalDialog';
import { map } from 'lodash';
import TaskFilter from '../components/TaskFilters';
import { setStatusFilter } from '../../tasks/actions';
import { hasRole, RoleTypes } from '../../../core/components/Permission';
import { Drawer, DrawerContainer } from '../../../core/components/Drawer';

export type TaskList = {
  [key: string]: {
    _id: number;
    _taskId: number;
    _showAction: boolean;
    _resource?: string;
    name: string;
    intervalDescription: string;
    start: string;
    end?: string;
  };
};

const MaintenanceTasks = (): JSX.Element => {
  const [taskExpanded, setExpanded] = React.useState(false);
  const [taskMaximized, setMaximized] = React.useState(false);
  const [highlightedRow, setHighlightedRow] = React.useState(0);
  const [searchResults, setSearchResults] = React.useState(null);
  const [sortQuery, setSortQuery] = React.useState({});
  const [searchResultsDetails, setSearchResultsDetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [clickedRowData, setClickedRowData] = React.useState<any>(null);
  const [projectTaskSelected, setProjectTask] = React.useState<Task>();
  const [taskType, setTaskType] = React.useState('Maintenance');
  // const [data, setData] = React.useState<any>([]);
  const site = Number(useSelector(selectSite)),
    tasks = useSelector(selectTasks),
    selectedTasks = useSelector(selectSelectedTasks),
    taskInEdit = useSelector(selectMaintenanceTaskInEdit),
    siteExtId = useSelector(selectSiteExternalId),
    multiSites = useSelector(selectMultiSiteSelection),
    total = useSelector(selectTotal),
    page = useSelector(selectPage),
    pageSize = useSelector(selectPageSize),
    session = useSelector(selectSession),
    employees = useSelector(selectEmployees),
    location = useSelector(selectLocationFilter),
    status = useSelector(selectStatusFilter),
    tasksFetched = useSelector((state) => getApiStatus(state, 'FETCH_TASKS'));

  const dispatch = useDispatch();
  const intl: any = useIntl();

  const handleFetchData = (values?: any) => {
    if (!tasksFetched) {
      dispatch(fetchTasks({ type: taskType, location, status, ...values }));
      dispatch(fetchEmployees());
      setExpanded(false);
    }
  };

  useEffect(() => {
    handleFetchData({ location, type: taskType, page, ...sortQuery });
  }, [location, status, taskType, page, siteExtId, multiSites, sortQuery]);

  const getPaginationInfo = () => {
    return {
      total: total,
      page,
      pageSize: pageSize,
      goToPage: (value: number) => handleFetchData({ page: value }),
      type: taskType,
      location,
      status,
    };
  };

  const getTaskCompletionText = (task: Task) => {
    return task.interval !== Interval.CarCount
      ? intl.formatMessage({ id: 'willSkipNextTime' })
      : intl.formatMessage({ id: 'willUpdateCarCountTask' });
  };

  const taskIsValidForPreCompletion = ({ latestCompletion }: any) => {
    if (latestCompletion) {
      const pending = toString(TaskCompletionStatus, TaskCompletionStatus.Pending);
      if (latestCompletion.status === pending) {
        // Check if the completion is expired
        return moment().utc().isAfter(moment(latestCompletion.scheduleEnd).utc());
      }
    }
    return true;
  };

  const addDialogProps = {
    title: 'Add Task',
    onSubmit: (task: Task) => dispatch(addTask(task)),
    isNew: true,
    isEssentialTask: false,
  };

  const addProject = () => {
    dispatch(
      showDialog('UPSERT_PROJECT', {
        title: 'Add Project',
        onSumit: () => {},
        isNew: true,
      })
    );
  };

  const getDropdownOptions = () => {
    const options = [
      {
        textId: 'maintenanceTasks',
        callback: () => handleTasksFilter('Maintenance', true),
        filter: true,
        project: false,
      },
      // {
      //   textId: 'myProjectList',
      //   callback: () => handleTasksFilter('Project', true),
      //   filter: true,
      //   project: false,
      // },
      // {
      //   textId: 'allTasks',
      //   callback: () => handleTasksFilter('', true),
      //   filter: true,
      //   project: false,
      // },
      // { textId: 'essentialTasks', callback: () => {} },
      // { textId: 'todaysTasks', callback: () => {} },
      // { textId: 'hourlyInspections', callback: () => {} },
      // { textId: 'addNewProject', callback: addProject, filter: false },
    ];

    if (taskInEdit && taskInEdit.type === 1 && !projectTaskSelected) {
      options.push({
        textId: taskInEdit.name,
        callback: () => handleProjectTasksFilter(''),
        filter: true,
        project: true,
      });
    }

    return options;
  };

  const handleSnoozeMultiple = (event: any, taskList: TaskList) => {
    const dialogProps: any = {
      completeTask: snoozeTaskCompletion,
      specificComponent: TaskCompletionModalDialog,
      selectedTasks: taskList,
      employees,
      title: intl.formatMessage({ id: 'snooze' }),
      descriptor: 'snooze',
    };

    dispatch(showDialog('CONFIRM_DIALOG', dialogProps));
  };

  const handleDoneMultiple = () => {
    if (Object.keys(selectedTasks).length === 0) {
      return;
    }
    const requiredDependencies: any[] = [];
    map(Object.keys(selectedTasks), (_id: any) => {
      const task = tasks.find((t: any) => t.id === parseInt(_id, 10));
      if (task?.linkedTasks?.length > 0) {
        requiredDependencies.push(task);
      }
    });

    const dialogProps: any = createDialogProps(
      completeTaskCompletion,
      requiredDependencies,
      selectedTasks,
      employees,
      intl,
      dispatch
    );

    dispatch(showDialog('CONFIRM_DIALOG', dialogProps));
  };

  const handleDone = ({ _id }: any) => {
    const task = tasks.find((t: any) => t.id === _id);

    const dialogProps = {
      onReady: () => dispatch(completeTaskAOT(task)),
      task,
      text: getTaskCompletionText(task),
      title: intl.formatMessage({ id: 'completeTaskAOT' }),
      isValid: taskIsValidForPreCompletion(task),
    };

    dispatch(showDialog('COMPLETE_TASK_AOT', dialogProps));
  };
  const handleProjectTasksFilter = (filterType: string) => {
    setTaskType(filterType);
    setProjectTask(taskInEdit);
  };
  const handleTasksFilter = (filterType: string, clearTaskinEdit: boolean) => {
    if (clearTaskinEdit) {
      setHighlightedRow(0);
      dispatch(clearMaintenanceTask());
      setProjectTask(undefined);
      setExpanded(false);
    }
    setTaskType(filterType);
  };
  const rowClickFunction = (data: any) => {
    const keys = Object.keys(selectedTasks);
    if (keys.length > 0) {
      if (keys.find((key) => parseInt(key) === data._id)) {
        setHighlightedRow(data._id);
        dispatch(getMaintenanceTask(data._id));
        return;
      }
    }
    setHighlightedRow(data._id);
    dispatch(getMaintenanceTask(data._id));

    if (data._taskType != 1) {
      setExpanded(true);
    }
  };

  const setSearchMatch = (sResults: any, sResultsDetails: any, sText: string) => {
    setSearchResults(sResults);
    setSearchResultsDetails(sResultsDetails);
    setSearchText(sText);
  };

  const collapseTask = () => {
    setExpanded(false);
    setMaximized(false);
  };

  const toggleTaskExpand = () => {
    if (taskInEdit?.type != 1) {
      setMaximized(!taskMaximized);
    }
  };

  const getRowClickInfo = () => ({
    callback: (data: any) => {
      rowClickFunction(data);
      setClickedRowData(data);
    },
  });

  const checkCompletedStatus = (): boolean => {
    const completed: any[] = [];
    map(selectedTasks, (task: any) => {
      const foundTask = tasks.find((t: any) => t.id === task._id);
      if (foundTask?.latestCompletion) {
        if (foundTask?.latestCompletion?.status === 'Completed') {
          completed.push(task);
        }
      }
    });

    if (completed.length > 0) {
      return true;
    }
    return false;
  };

  const fetchSortedTasks = (sortParameters: any) => {
    const newQuery: any = {};
    if (sortParameters) {
      if (sortParameters.key) {
        newQuery.key = sortParameters.key;
      }
      if (!_.isNil(sortParameters.direction)) {
        newQuery.direction = toString(SortDirection, sortParameters.direction);
      }
      setSortQuery(newQuery);
    }
  };

  let data = searchResults !== null ? searchResults : tasks;
  if (projectTaskSelected && data) {
    const linkedTaskIds = projectTaskSelected.linkedTasks.map(
      (task: { linkedTaskId: any }) => task.linkedTaskId
    );

    const filteredData = data.filter((task: { id: any }) => linkedTaskIds.includes(task.id));
    data = filteredData;
  }

  return (
    <Container fluid className="nopadding">
      <div className="page-main-content maintenance-tasks-container">
        <div className="task-table-header tasks-buttons-container">
          <div>
            <TaskDropdown intl={intl} options={getDropdownOptions()} taskInEdit={taskInEdit} />
            {!Object.values(selectedTasks).length && (
              <>
                <LocationFilter intl={intl} />
                <TaskFilter
                  intl={intl}
                  selectedFilter={status}
                  options={map(TaskStatus, (value: any, i: string) => ({
                    type: i,
                    message: i,
                  }))}
                  label={intl?.formatMessage({ id: 'taskStatus' })}
                  setFilter={setStatusFilter}
                />
              </>
            )}
          </div>
          {!!Object.values(selectedTasks).length && status != 'Completed' ? (
            <span className="multi-select-actions">
              <button
                className="button blue mr-2"
                disabled={checkCompletedStatus()}
                onClick={(e) => {
                  handleSnoozeMultiple(e, selectedTasks);
                }}
              >
                {intl.formatMessage({ id: 'snooze' })} ({Object.keys(selectedTasks).length})
              </button>
              <button
                className="button green"
                disabled={checkCompletedStatus()}
                onClick={(e) => {
                  handleDoneMultiple();
                }}
              >
                {intl.formatMessage({ id: 'complete' })} ({Object.keys(selectedTasks).length})
              </button>
            </span>
          ) : (
            <UserGroupPermissions itemRef={addPermissionRefs.task}>
              <ButtonWithLoader
                style="button add-task"
                handleClick={() => dispatch(showDialog('UPSERT_TASK', addDialogProps))}
              >
                <>
                  <i className="icon icon-plus"></i>
                  <span className="add-button-text">{intl.formatMessage({ id: 'addTask' })}</span>
                </>
              </ButtonWithLoader>
            </UserGroupPermissions>
          )}
        </div>
        <DrawerContainer>
          <>
            <div>
              <SearchBar
                intl={intl}
                dataToSearchOn={tasks}
                dataToSearchFor={['name']}
                setSearchMatch={setSearchMatch}
                uniqueField="id"
                styles={['data-table-search mb-2']}
              />
              <DataTable
                paginationInfo={getPaginationInfo()}
                scrolling
                highlightedRow={highlightedRow}
                {...initializeMaintenanceTaskDatatable({
                  intl,
                  session,
                  tasks: data,
                  employees,
                  handleDone,
                  dispatch,
                  selectedTasks,
                  fetchSortedTasks,
                  projectTaskSelected,
                  getRowClickInfo,
                })}
              />
            </div>
            <Drawer expanded={taskExpanded} maximized={taskMaximized} expandedWidth={65}>
              {taskInEdit !== null ? (
                <MaintenanceTaskDetail
                  intl={intl}
                  collapseSelf={collapseTask}
                  toggleExpand={toggleTaskExpand}
                  employees={employees}
                  handleDone={handleDoneMultiple}
                  task={taskInEdit}
                  paginationInfo={getPaginationInfo()}
                  tasksFetched={tasksFetched}
                />
              ) : (
                ''
              )}
            </Drawer>
          </>
        </DrawerContainer>
      </div>
    </Container>
  );
};

export default MaintenanceTasks;
