import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface TaskUnlinkModalProps {
  intl: any;
  children: any;
  title: string;
  isOpen: boolean;
  close: any;
  viewDependencies: any;
  unLinkTask: any;
  linkedTask: any;
  className: string;
  showFooter: boolean;
}

const TaskUnlinkModal = ({
  intl,
  children,
  title,
  isOpen,
  close,
  unLinkTask,
  linkedTask,
  className,
  showFooter,
}: TaskUnlinkModalProps) => {
  const confirmUnlinkTask = () => {
    unLinkTask(linkedTask);
  };
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter className="full-width">
          <Button className="submit-button delete" color="secondary" onClick={confirmUnlinkTask}>
            {intl.formatMessage({ id: 'removeLink' })}
          </Button>
          <Button className="submit-button confirm" color="primary" onClick={close}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default TaskUnlinkModal;
