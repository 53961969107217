import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import Photos from '../../../core/components/Photos';
import TabsWithContent from '../../../core/components/TabsWithContent';
import Videos from '../../../core/components/Videos';
import { addFile, deleteFile } from '../../reclamations/actions';
import Description from './Description';
import Notes from './Notes';
import Repair from './Repair';

export const ClaimDetailContent = (props) => {
  const { addFiles, showDialogMenu, deleteFiles } = props;
  const getFiles = (type) => {
    const isType = new RegExp(type);
    const files = props.reclamation.files;
    return files ? files.filter((file) => isType.test(file.mimeType) && !file.archivedAt) : [];
  };

  const addReclamationFile = (formData) => {
    const data = {
      siteId: props.reclamation.siteId,
      reclamationId: props.reclamation.id,
      file: formData,
    };

    addFiles(data);
  };

  const _deleteFile = (file) => {
    const confirmProps = {
      onReady: deleteFiles.bind(null, {
        fileId: file.id,
        reclamationId: props.reclamation.id,
      }),
      text: `Are you sure you want to archive file: "${file.originalName}" ?`,
      title: 'Archive File',
    };
    showDialogMenu('CONFIRM_DIALOG', confirmProps);
  };

  const getTabComponents = () => ({
    description: <Description text={props.reclamation.description} />,
    photos: (
      <Photos photos={getFiles('image')} addFile={addReclamationFile} deleteFile={_deleteFile} />
    ),
    videos: (
      <Videos videos={getFiles('video')} addFile={addReclamationFile} deleteFile={_deleteFile} />
    ),
    notes: <Notes reclamation={props.reclamation} />,
    repair: <Repair reclamation={props.reclamation} />,
  });

  return (
    <div data-testid="claimDetailContent" className="content-container">
      <TabsWithContent
        tabHeaders={Object.keys(getTabComponents())}
        components={getTabComponents()}
      />
    </div>
  );
};

ClaimDetailContent.propTypes = {
  showDialogMenu: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  addFiles: PropTypes.func.isRequired,
  reclamation: PropTypes.shape({
    id: PropTypes.number,
    siteId: PropTypes.number,
    description: PropTypes.string,
    files: PropTypes.any,
    notes: PropTypes.any,
  }),
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteFiles: deleteFile,
      showDialogMenu: showDialog,
      addFiles: addFile,
    },
    dispatch
  );
};

const enhance = compose(connect(null, mapDispatchToProps));

export default enhance(ClaimDetailContent);
