import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { showDialog } from '../../../core/actions';
import ContentTabs from '../../../core/components/ContentTabs';
import DetailHeader from '../../../core/components/DetailHeader';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import {
  deletePermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { momentInMMDDYYYYFormat } from '../../../core/utils';
import {
  deleteInventoryInEdit,
  deleteInventoryItem,
  getInventoryItem,
  saveInventoryItem,
} from '../actions';
import { selectEmployees } from '../../employees/reducer';
import { fetchEmployees } from '../../employees/actions';
import { InventoryType } from '../models';
import { selectInventoryInEdit } from '../reducer';
import DetergentInventoryData from './DetergentInventoryData';
import History from '../../taskDetail/components/History';
import InventoryHistoryTab from './InventoryHistory';

const InventoryDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const inventoryInEdit = useSelector(selectInventoryInEdit);
  const employees = useSelector(selectEmployees);

  useEffect(() => {
    if (props.isPage) {
      const id = params.inventory;
      dispatch(getInventoryItem(id));
    }
  }, [params.inventory]);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);

  const getEmployee = (employeeId) => {
    let employeeString = 'N/A';
    employees.map((employee) => {
      if (employee.id == employeeId) {
        employeeString = `${employee.firstName} ${employee.lastName}`;
      }
    });
    return employeeString;
  };

  const getTitles = (inventory) => {
    const formatMessage = intl.formatMessage;
    const onHand = inventory.onHand || 0;
    const preferredStock = inventory.preferredStockLevel || 0;
    return [
      {
        className: 'detail-name',
        key: formatMessage({ id: 'name' }),
        content: inventory.name,
      },
      {
        label: formatMessage({ id: 'partNumber' }),
        content: inventory.number,
      },
      {
        label: formatMessage({ id: 'createdAt' }),
        content: inventory.createdAt
          ? momentInMMDDYYYYFormat(moment(inventory.createdAt))
          : formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'installationDate' }),
        content: inventory.installedAt
          ? momentInMMDDYYYYFormat(moment(inventory.installedAt))
          : formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'installedBy' }),
        content: getEmployee(inventory.employeeId),
      },
      {
        label: formatMessage({ id: 'stockLevel' }),
        content: `${onHand}/${preferredStock}`,
      },
      {
        label: formatMessage({ id: 'unit' }),
        content: inventory?.unitOfMeasure || formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'url' }),
        content: formatMessage({ id: 'notAvailable' }),
        children: (
          <div key={formatMessage({ id: 'url' })} className="detail-url">
            <label>{formatMessage({ id: 'url' })}</label>
            <a href={inventory.vendorUrl}>{inventory.vendorUrl}</a>
          </div>
        ),
      },
    ];
  };

  const handleEditInventoryItem = () => {
    const saveInventoryWithCallBack = (item) => {
      dispatch(
        saveInventoryItem({
          ...item,
          callback: () => getInventoryItem(inventoryInEdit.id),
        })
      );
    };
    const confirmProps = {
      title: 'Edit Inventory',
      onSubmit: saveInventoryWithCallBack,
      isNew: false,
      intl: intl,
    };
    dispatch(showDialog('UPSERT_INVENTORY', confirmProps));
  };

  const handleDeleteInventoryItem = () => {
    const { id, name } = inventoryInEdit;
    const { site } = params;

    const query = {
      id,
      navigate: () => {
        history.push(`/${site}/inventory`);
      },
    };
    const deleteProps = {
      onSubmitCallback: () => {
        dispatch(deleteInventoryInEdit());
        if (!props.isPage) {
          props.drawerControls.closeFunction();
        }
      },
      title: 'Archive Inventory',
      inventoryItem: inventoryInEdit,
      intl: intl,
    };
    dispatch(showDialog('DELETE_INVENTORY', deleteProps));
  };

  const inventory = inventoryInEdit;
  const components = {
    Description: <div>{inventory.description}</div>,
    History: (
      <div data-testid="inventory-history-tab">
        <InventoryHistoryTab history={inventory?.historyLogs || []} />
      </div>
    ),
  };

  if (inventoryInEdit.type === InventoryType.Detergent) {
    components['Detergent Inventory Data'] = <DetergentInventoryData inventory={inventory} />;
  }

  const buttons = [
    {
      onClick: handleDeleteInventoryItem,
      label: intl.formatMessage({ id: 'delete' }),
      class: 'button primary inverted mr-2',
      permissionKey: deletePermissionRefs.inventory,
    },
    {
      onClick: handleEditInventoryItem,
      label: intl.formatMessage({ id: 'edit' }),
      permissionKey: editPermissionRefs.inventory,
    },
  ];

  return (
    <>
      <DetailButtonGroup
        history={history}
        intl={intl}
        buttons={buttons}
        isPage={props.isPage}
        drawerControls={props.drawerControls}
      />
      {employees != null && (
        <>
          {inventory.priority && (
            <span className="badge preventative">
              <i className="uil uil-clipboard-notes"></i> {intl.formatMessage({ id: 'essential' })}
            </span>
          )}
          <DetailHeader addedClass="inventory-detail" titles={getTitles(inventory)} />
        </>
      )}
      <ContentTabs components={components} />
    </>
  );
};

export default InventoryDetail;
