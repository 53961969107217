import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showDialog } from '../../../../../core/actions';
import { IntlType } from '../../../../../utils/typing';
import Employee from '../../../../employees/models';
import { EmployeeTableData, EmployeeTableHeader, PaginationInfo } from '../../../types';
import useEmployees from './useEmployees';

interface TableProps {
  headers: EmployeeTableHeader[];
  data: EmployeeTableData[];
  addedClass: string;
  paginationInfo: PaginationInfo;
  setSearchParams: (params: any) => void;
}

export const useUsersTableProps = (intl: IntlType): TableProps => {
  const dispatch = useDispatch();

  const { employees, pagination, setSearchParams, searchParams, getEmployees, siteId, multiSite } =
    useEmployees();

  useEffect(() => {
    getEmployees();
  }, [searchParams.page, searchParams.includeArchived, siteId, multiSite]);

  const openEmployeeEditDialog = (employee: Employee) => {
    dispatch(showDialog('EDIT_USER', { employee }));
  };

  const getTableHeaders = (): EmployeeTableHeader[] => {
    const headerIds = ['lastName', 'firstName', 'jobTitle'];

    const headers = headerIds.map((id) => {
      return { name: intl.formatMessage({ id }) };
    });

    return headers as EmployeeTableHeader[];
  };

  const getTableData = (): EmployeeTableData[] => {
    const data = employees.map((employee) => {
      return {
        _id: employee.id,
        _onClick: () => openEmployeeEditDialog(employee),
        _isArchived: !!employee.archivedAt,
        lastName: employee.lastName,
        firstName: employee.firstName,
        jobTitle: employee.position,
      };
    });

    return data;
  };

  const getPaginationInfo = (): PaginationInfo => {
    return {
      ...pagination,
      goToPage: (page: number) => setSearchParams({ page }),
    };
  };

  return {
    headers: getTableHeaders(),
    data: getTableData(),
    addedClass: 'table-hover manage-users-table',
    paginationInfo: getPaginationInfo(),
    setSearchParams,
  };
};
