import { isUndefined } from 'lodash';
import moment from 'moment';
import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/washCounts';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import QueueAction from '../../core/queueAction';
import { fetchProducts } from '../products/actions';

export const FETCH_CORE_COUNTS = 'FETCH_CORE_COUNTS';
export const FETCH_PRODUCT_PLAN_COUNTS = 'FETCH_PRODUCT_PLAN_COUNTS';
export const FETCH_CORE_PAY_AS_YOU_GO_COUNTS = 'FETCH_CORE_PAY_AS_YOU_GO_COUNTS';
export const FETCH_CORE_FLEET_PAY_AS_YOU_GO_COUNTS = 'FETCH_CORE_FLEET_PAY_AS_YOU_GO_COUNTS';
export const FETCH_CORE_DISCOUNTS = 'FETCH_CORE_DISCOUNTS';
export const FETCH_CORE_PAY_AS_YOU_GO_DISCOUNTS = 'FETCH_CORE_PAY_AS_YOU_GO_DISCOUNTS';
export const FETCH_CORE_REFUNDS = 'FETCH_CORE_REFUNDS';
export const FETCH_WASH_COUNTS = 'FETCH_WASH_COUNTS';
export const ADD_WASH_COUNTS = 'ADD_WASH_COUNTS';
export const SHOW_WASH_COUNT_CHART = 'SHOW_WASH_COUNT_CHART';
export const SET_WASH_COUNT_DATE = 'SET_WASH_COUNT_DATE';
export const SET_WASH_COUNT_FROM_DATE = 'SET_WASH_COUNT_FROM_DATE';
export const SET_WASH_COUNT_UNTIL_DATE = 'SET_WASH_COUNT_UNTIL_DATE';
export const SET_TOP_WASHES_FROM_DATE = 'SET_TOP_WASHES_FROM_DATE';
export const SET_TOP_WASHES_UNTIL_DATE = 'SET_TOP_WASHES_UNTIL_DATE';
export const SET_SUBSCRIPTION_DATE = 'SET_SUBSCRIPTION_DATE';
export const SET_TOP_SINGLE_WASHES_LOADERS_TRUE = 'SET_TOP_SINGLE_WASHES_LOADERS_TRUE';
export const ADJUST_POS_COUNT = 'ADJUST_POS_COUNT';
export const FETCH_ADJUSTMENTS = 'FETCH_ADJUSTMENTS';
export const FETCH_ADJUSTMENT_NOTES = 'FETCH_ADJUSTMENT_NOTES';
export const FETCH_TOP_PERFORMING_SINGLE_WASHES = 'FETCH_TOP_PERFORMING_SINGLE_WASHES';
export const FETCH_TOP_PERFORMING_SUBSCRIPTION_WASHES = 'FETCH_TOP_PERFORMING_SUBSCRIPTION_WASHES';
export const FETCH_HOURLY_WASH_COUNTS = 'FETCH_HOURLY_WASH_COUNTS';
export const FETCH_CORE_FLEET_PAY_AS_YOU_GO_DISCOUNTS = 'FETCH_CORE_FLEET_PAY_AS_YOU_GO_DISCOUNTS';

export const fetchCoreCounts = (fromDate, untilDate, signal, extId, shouldOmitDailyStats) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
    shouldOmitDailyStats: isUndefined(shouldOmitDailyStats) ? true : shouldOmitDailyStats,
  };

  return {
    type: API_REQUEST,
    callAPI: API.fetchCoreCounts,
    data: { query, signal },
    action: FETCH_CORE_COUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const fetchInstaClubCounts = (fromDate, untilDate, signal, extId, shouldOmitDailyStats) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
    shouldOmitDailyStats: isUndefined(shouldOmitDailyStats) ? true : shouldOmitDailyStats,
  };

  return {
    type: API_REQUEST,
    callAPI: API.fetchInstaClubCounts,
    data: { query, signal },
    action: FETCH_PRODUCT_PLAN_COUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const fetchCorePayAsYouGoCounts = (fromDate, untilDate, signal, extId) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().endOf('day').toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCorePayAsYouGoCounts,
    data: { query, signal },
    action: FETCH_CORE_PAY_AS_YOU_GO_COUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const fetchCoreFleetPayAsYouGoCounts = (fromDate, untilDate, signal, extId) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().endOf('day').toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCoreFleetPayAsYouGoCounts,
    data: { query, signal },
    action: FETCH_CORE_FLEET_PAY_AS_YOU_GO_COUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const fetchCoreFleetPayAsYouGoDiscounts = (fromDate, untilDate, signal, extId) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().endOf('day').toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCoreFleetPayAsYouGoDiscounts,
    data: { query, signal },
    action: FETCH_CORE_FLEET_PAY_AS_YOU_GO_DISCOUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const fetchCoreDiscounts = (fromDate, untilDate, signal, extId) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCoreDiscounts,
    data: { query, signal },
    action: FETCH_CORE_DISCOUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
      queueAction: QueueAction.AddToQueue,
    },
  };
};

export const fetchCorePayAsYouGoDiscounts = (fromDate, untilDate, signal, extId) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCorePayAsYouGoDiscounts,
    data: { query, signal },
    action: FETCH_CORE_PAY_AS_YOU_GO_DISCOUNTS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
      queueAction: QueueAction.AddToQueue,
    },
  };
};

export const fetchWashCounts = (from, until) => {
  const query = {
    fromDate: from || moment().startOf('day').toISOString(),
    untilDate: until || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchWashCounts,
    data: { query },
    action: FETCH_WASH_COUNTS,
    callback: () => fetchProducts(),
    meta: { permissionKey: viewPermissionRefs.wash_count },
  };
};

export const fetchNotes = (from, until, signal) => {
  const query = {
    fromDate: from || moment().startOf('day').toISOString(),
    untilDate: until || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchNotes,
    data: { query, signal },
    action: FETCH_ADJUSTMENT_NOTES,
    meta: { query },
  };
};

export const fetchCoreRefunds = (from, until, signal, extId) => {
  const query = {
    fromDate: from || moment().startOf('day').toISOString(),
    untilDate: until || moment().toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchCoreRefunds,
    data: { query, signal },
    action: FETCH_CORE_REFUNDS,
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      query,
    },
  };
};

export const addWashCounts = (washCountData, updateFromDate, updateUntilDate, extId) => {
  return {
    type: API_REQUEST,
    callAPI: API.addWashCounts,
    data: washCountData,
    action: ADD_WASH_COUNTS,
    callback: () => fetchCoreCounts(updateFromDate, updateUntilDate, null, extId),
    closeModal: true,
  };
};

export const fetchAdjustments = (fromDate, untilDate, page, signal) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    direction: 'desc',
    page,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchAdjustments,
    action: FETCH_ADJUSTMENTS,
    data: { query, signal },
  };
};

export const adjustWashCount = (adjustment, updateFromDate, updateUntilDate, extId) => {
  return {
    type: API_REQUEST,
    callAPI: API.adjustWashCount,
    data: adjustment,
    action: ADJUST_POS_COUNT,
    callback: [
      () => fetchCoreCounts(updateFromDate, updateUntilDate, null, extId),
      () => fetchAdjustments(updateFromDate, updateUntilDate),
      () => fetchNotes(updateFromDate, updateUntilDate),
    ],
    closeModal: true,
  };
};

export const fetchTopPerformingSingleWashes = (fromDate, untilDate, extId, signal) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchTopPerformingSingleWashes,
    action: FETCH_TOP_PERFORMING_SINGLE_WASHES,
    data: { query, signal },
  };
};

export const fetchTopPerformingSubscriptionWashes = (date, extId, signal) => {
  const query = {
    selectedDate: date || moment().startOf('day').toISOString(),
    siteId: extId,
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchTopPerformingSubscriptionWashes,
    action: FETCH_TOP_PERFORMING_SUBSCRIPTION_WASHES,
    data: { query, signal },
  };
};

export const fetchHourlyWashCounts = (fromDate, untilDate) => {
  const query = {
    fromDate: fromDate || moment().startOf('day').toISOString(),
    untilDate: untilDate || moment().toISOString(),
  };
  return {
    type: API_REQUEST,
    callAPI: API.fetchHourlyWashCounts,
    action: FETCH_HOURLY_WASH_COUNTS,
    data: { query },
    meta: {
      permissionKey: viewPermissionRefs.wash_count,
      useManagerSiteId: true,
      errorApi: 'fetchHourlyWashCounts',
      errorMessage: 'Failed to fetch hourly wash counts',
    },
  };
};

export const showChart = (chart) => ({ type: SHOW_WASH_COUNT_CHART, chart });
export const setFromDate = (date) => ({ type: SET_WASH_COUNT_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_WASH_COUNT_UNTIL_DATE, date });
export const setTopWashesFromDate = (date) => ({ type: SET_TOP_WASHES_FROM_DATE, date });
export const setTopWashesUntilDate = (date) => ({ type: SET_TOP_WASHES_UNTIL_DATE, date });
export const setSubscriptionDate = (date) => ({ type: SET_SUBSCRIPTION_DATE, date });
export const setTopSingleWashesLoadersTrue = () => ({
  type: SET_TOP_SINGLE_WASHES_LOADERS_TRUE,
});
