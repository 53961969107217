import { get, omit } from 'lodash';
import { SiteModelYear, SiteOperationType } from './models';
import { Task } from '../tasks/models';

const initialState = {
  washCounter: {},
  interiorWashCounter: {},
  washCounterEnabled: false,
  workLogsDisabled: true,
  managerAccessDisabled: false,
  dateRangeLimiterEnabled: false,
  templateLoaded: false,
  modelYearId: null,
  operationTypeId: null,
  taskInDetail: null,
  dateRangeLimiter: {
    range: 1,
    type: 'days',
  },
  site: {
    totalCarCount: 0,
    operations: [],
    siteModelYears: [],
    siteOperationTypes: [],
  },
};

const updateSettingsSuccess = (state, response) => {
  const siteSettings = response.settings;
  return {
    ...state,
    ...siteSettings,
    modelYearId: response.modelYearId,
    operationTypeId: response.operationTypeId,
  };
};

const setTaskInDetail = (state, task) => {
  return {
    ...state,
    taskInDetail: new Task(task),
  };
};

const setSite = (state, response) => {
  const site = omit(response, 'settings');
  const dateRangeLimiterEnabledCheck = get(response, 'settings.dateRangeLimiterEnabled', false);
  const dateRangeLimiter = get(response, 'settings.dateRangeLimiter', {});

  if (dateRangeLimiterEnabledCheck) {
    dateRangeLimiter.range = dateRangeLimiter.range || 0;
    dateRangeLimiter.type = dateRangeLimiter.type || 'days';
  }

  if (site.timeZone) {
    window.sessionStorage.setItem('timeZone', site.timeZone);
  }

  return {
    ...state,
    ...response.settings,
    modelYearId: site.modelYearId,
    operationTypeId: site.operationTypeId,
    dateRangeLimiterEnabled: dateRangeLimiterEnabledCheck,
    dateRangeLimiter,
    site: {
      ...state.site,
      ...site,
    },
  };
};

const setSiteOperations = (state, response) => {
  return {
    ...state,
    site: {
      ...state.site,
      operations: response.results,
    },
  };
};

const loadTemplateDataSuccess = (state) => {
  return {
    ...state,
    templateLoaded: true,
  };
};

const getSiteOperationTypesucess = (state, action) => {
  const siteOperationTypes = action.results.map((t) => new SiteOperationType(t));
  return {
    ...state,
    site: {
      ...state.site,
      siteOperationTypes,
    },
  };
};

const getSiteModelYearsuccess = (state, action) => {
  const siteModelYears = action.results.map((t) => new SiteModelYear(t));
  return {
    ...state,
    site: {
      ...state.site,
      siteModelYears,
    },
  };
};

export const siteSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SITE_SETTINGS_SUCCESS':
      return updateSettingsSuccess(state, action.response);
    case 'LOAD_TEMPLATE_DATA_SUCCESS':
      return loadTemplateDataSuccess(state);
    case 'FETCH_SITE_SETTINGS_SUCCESS':
    case 'SET_PRIMARY_SITE':
      return setSite(state, action.response);
    case 'FETCH_SITE_OPERATIONS_SUCCESS':
      return setSiteOperations(state, action.response);
    case 'FETCH_SITE_OPERATION_TYPE_SUCCESS':
      return getSiteOperationTypesucess(state, action.response);
    case 'FETCH_SITE_MODEL_YEAR_SUCCESS':
      return getSiteModelYearsuccess(state, action.response);
    case 'SET_TASK_IN_DETAIL':
      return setTaskInDetail(state, action.response);
    default:
      return state;
  }
};

export const selectManagerDisabled = (state) => {
  return state.siteSettings.managerDisabled;
};

export const selectDateRangeLimiter = (state) => {
  return state.siteSettings?.dateRangeLimiter;
};

export const selectDateRangeLimiterEnabled = (state) => {
  return state.siteSettings?.dateRangeLimiterEnabled || false;
};

export const selectWashCounterEnabled = (state) => {
  return state.siteSettings?.washCounterEnabled || false;
};

export const selectWorkLogsDisabled = (state) => {
  return state.siteSettings.workLogsDisabled;
};

export const selectSiteSettings = (state) => {
  return state.siteSettings;
};

export const selectSiteSettingsSite = (state) => {
  return state.siteSettings.site;
};

export const selectSiteInteriorDetailRadius = (state) => {
  return state.siteSettings.site?.interiorDetailRadius || 0;
};

export const selectSiteTotalCarCount = (state) => {
  return state.siteSettings.site.totalCarCount;
};

export const selectSiteTimeZone = (state) => {
  return state.siteSettings.site.timeZone;
};

export const selectSiteName = (state) => {
  return state.siteSettings.site.name;
};

export const selectSiteExtId = (state) => {
  return state.siteSettings.site.extId;
};

export const selectSiteId = (state) => {
  return state.siteSettings.site.id;
};

export const selectSiteExternalId = (state) => {
  return state.siteSettings.site.externalId;
};

export const selectSiteGroupId = (state) => {
  return state.siteSettings.site.groupId;
};

export const selectSiteOperations = (state) => {
  return state.siteSettings.site.operations;
};

export const selectSiteOperationTypes = (state) => {
  return state.siteSettings.site.siteOperationTypes;
};

export const selectSiteModelYears = (state) => {
  return state.siteSettings.site.siteModelYears;
};

export const selectTaskInDetail = (state) => {
  return state.siteSettings.taskInDetail;
};
