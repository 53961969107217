import { Session } from 'inspector';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import { IntlShape } from 'react-intl';
import { MaintenanceTask } from './models';
import { setTaskSelections } from '../tasks/actions';
import moment from 'moment';
import { startsWith } from 'lodash';
import { showDialog } from '../../core/actions';
import { snoozeTaskCompletion } from '../overview/actions';
import DependencyWarningDialog from './components/DependencyWarningDialog';
import TaskCompletionModalDialog from './components/TaskCompletionModalDialog';
import { TaskList } from './containers/MaintenanceTasks';
import { Task } from '../tasks/models';
import Employee from '../employees/models';
import { Link } from './components/TaskLink';

interface TableProps {
  intl: IntlShape;
  session: Session;
  tasks: MaintenanceTask[];
  employees: any[];
  handleDone: (event: any, id: string) => void;
  projectTaskSelected: any;
  dispatch: any;
  selectedTasks: any;
  fetchSortedTasks: any;
  getRowClickInfo: any;
}

interface DataTableProps {
  _id: string;
  _showActions: boolean;
  name: string | JSX.Element;
  intervalDescription: string;
  start: string;
  end: string;
  lastCompleted: string | JSX.Element;
  _resource: string;
}

interface TableHeaders {
  name: string;
  cell?: any;
}

interface TableReturn {
  data: DataTableProps[];
  headers: TableHeaders[];
  options: any;
  selectedRows: any[];
  updateSelectedRows: any;
  fetchSortedData: any;
  rowClickInfo: any;
  multiSelectEnabled: boolean;
}

// if linked task has a completed status, then the task can be completed
const checkLinkedTaskStatus = (linkedTasks: Link[]): Link[] => {
  const linkedTaskStatus: Link[] = [];
  linkedTasks.forEach((task) => {
    if (task.status === 'Completed') {
      linkedTaskStatus.push(task);
    }
  });
  return linkedTaskStatus;
};

export const createDialogProps = (
  completeTask: any,
  dependencies: Link[],
  task: TaskList | Task[],
  employees: Employee[],
  intl: any,
  dispatch: any
): any => {
  const canCompleteTask = checkLinkedTaskStatus(dependencies).length === dependencies.length;
  const dialogProps: any = {
    completeTask: () =>
      dispatch(
        showDialog('CONFIRM_DIALOG', {
          completeTask,
          dependencies,
          task,
          employees,
          title: 'Complete',
        })
      ),
    snoozeTask: null,
    specificComponent: '',
    selectedTasks: '',
    employees,
    title: intl.formatMessage({ id: 'info' }),
    descriptor: '',
  };

  if (dependencies.length) {
    dialogProps.specificComponent = canCompleteTask
      ? TaskCompletionModalDialog
      : DependencyWarningDialog;
    dialogProps.title = canCompleteTask
      ? intl.formatMessage({ id: 'complete' })
      : intl.formatMessage({ id: 'snooze' });
    dialogProps.selectedTasks = task;
    dialogProps.completeTask = () =>
      dispatch(
        showDialog('CONFIRM_DIALOG', {
          ...dialogProps,
          completeTask,
          specificComponent: TaskCompletionModalDialog,
          selectedTasks: task,
          title: 'Complete',
        })
      );
    dialogProps.snoozeTask = () =>
      dispatch(
        showDialog('CONFIRM_DIALOG', {
          ...dialogProps,
          specificComponent: TaskCompletionModalDialog,
          selectedTasks: task,
          completeTask: snoozeTaskCompletion,
          title: 'Snooze',
        })
      );
  } else {
    dialogProps.specificComponent = TaskCompletionModalDialog;
    dialogProps.title = 'Complete';
    dialogProps.selectedTasks = task;
  }

  return dialogProps;
};

export const handleTaskStatusBadge = (status: string | undefined, intl: any): JSX.Element => {
  if (!status) {
    return <span className="badge undefined">{intl.formatMessage({ id: 'noStatus' })}</span>;
  }
  if (status === 'Expired') {
    return <span className="badge overdue">{intl.formatMessage({ id: 'incomplete' })}</span>;
  }
  if (status === 'Completed') {
    return <span className="badge complete">{intl.formatMessage({ id: 'completed' })}</span>;
  }
  if (status === 'Snoozed') {
    return <span className="badge snoozed">{intl.formatMessage({ id: 'snoozed' })}</span>;
  }
  return <span className="badge incomplete">{intl.formatMessage({ id: 'inProgress' })}</span>;
};

export const initializeMaintenanceTaskDatatable = (props: TableProps): TableReturn => {
  const { intl, tasks, dispatch, selectedTasks, fetchSortedTasks, getRowClickInfo } = props;

  //TODO:
  const setSelectedRows = (rows: any) => {
    const selectRow = (rowList: any, rowData: any) => {
      if (rowList[rowData._id]) {
        return omit(rowList, [rowData._id]);
      }
      return { ...rowList, [rowData._id]: rowData };
    };

    const selectAll = (rowList: any, selectedList: any) => {
      if (Object.keys(rowList).length !== selectedList.length) {
        return keyBy(selectedList, '_id');
      }
      return {};
    };

    let currentSelections = selectedTasks;

    if (rows.length) {
      currentSelections = selectAll(currentSelections, rows);
    } else {
      currentSelections = selectRow(currentSelections, rows);
    }

    dispatch(setTaskSelections(currentSelections));
  };

  const data: DataTableProps[] = tasks.map((task) => ({
    _id: task.id,
    _taskId: task.id,
    _showActions: false,
    name: (
      <div>
        <div>{task.name}</div>
        <div className="task-subtitle">
          {task && task.subTitle && task.subTitle.length > 50
            ? task.subTitle.slice(0, 47) + '...'
            : task.subTitle}
        </div>
      </div>
    ),
    intervalDescription: task.getIntervalDescription(intl.formatMessage),
    start: moment(task.start).format('MM/DD/YYYY'),
    end: task.end && moment(task.end).format('MM/DD/YYYY'),
    lastCompleted:
      task.latestCompletion?.timestamp &&
      moment(task.latestCompletion?.timestamp).format('MM/DD/YYYY'),
    _resource: task.resource,
  }));

  const headers: {
    name: string;
    databaseProperty?: any;
    cell?: any;
  }[] = [
    { name: intl.formatMessage({ id: 'name' }), databaseProperty: 'name' },
    { name: intl.formatMessage({ id: 'interval' }) },
    { name: intl.formatMessage({ id: 'startDate' }), databaseProperty: 'start' },
    { name: intl.formatMessage({ id: 'endDate' }), databaseProperty: 'end' },
    { name: intl.formatMessage({ id: 'lastCompleted' }) },
  ];
  headers.push({
    name: props.intl.formatMessage({ id: 'resource' }),
    cell: {
      component: (p: any) => {
        const url = startsWith(p.rowData._resource, 'http')
          ? p.rowData._resource
          : `https://${p.rowData._resource}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {p.rowData._resource && (
              <i className="icon icon-link px-4 text-info" title={`${p.rowData._resource}`} />
            )}
          </a>
        );
      },
    },
  });

  return {
    data,
    headers,
    multiSelectEnabled: true,
    options: {
      isSelect: true,
    },
    selectedRows: selectedTasks,
    rowClickInfo: getRowClickInfo(),
    updateSelectedRows: setSelectedRows,
    fetchSortedData: fetchSortedTasks,
  };
};
