import { API_REQUEST } from '../../core/actions';
import * as EssentialTaskAPI from '../../core/api/essential-tasks';
import * as taskCompletionsAPI from '../../core/api/task-completions';
import * as taskLocationAPI from '../../core/api/task-locations';
import * as taskPrefixAPI from '../../core/api/task-prefixes';
import * as TaskAPI from '../../core/api/tasks';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { getTodaysTaskCompletionsDefaultQuery } from './helpers';

export const SAVE_TASK = 'SAVE_TASK';
export const ADD_TASK = 'ADD_TASK';
export const ADD_TASKS = 'ADD_TASKS';
export const ADD_PART = 'ADD_PART';
export const FETCH_TASKS = 'FETCH_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASKINEDIT = 'DELETE_TASKINEDIT';
export const GET_TASK = 'GET_TASK';
export const UNLINK_TASK = 'UNLINK_TASK';
export const GET_MAINTENANCE_TASK = 'GET_MAINTENANCE_TASK';
export const SET_MAINTENANCE_TASK = 'SET_MAINTENANCE_TASK';
export const ADD_TASK_COMPLETION = 'ADD_TASK_COMPLETION';
export const GET_TASK_COMPLETIONS_FOR_TASK = 'GET_TASK_COMPLETIONS_FOR_TASK';
export const GET_ALL_TASK_COMPLETIONS = 'GET_ALL_TASK_COMPLETIONS';
export const GET_TODAYS_TASK_COMPLETIONS = 'GET_TODAYS_TASK_COMPLETIONS';
export const GET_ESSENTIAL_TASKS = 'GET_ESSENTIAL_TASKS';
export const ADD_ESSENTIAL_TASK = 'ADD_ESSENTIAL_TASK';
export const ADD_TASK_NOTE = 'ADD_TASK_NOTE';
export const DELETE_ESSENTIAL_TASK = 'DELETE_ESSENTIAL_TASK';
export const SAVE_ESSENTIAL_TASK = 'SAVE_ESSENTIAL_TASK';
export const SET_TASK_FROM_DATE = 'SET_TASK_FROM_DATE';
export const SET_TASK_UNTIL_DATE = 'SET_TASK_UNTIL_DATE';
export const SET_TASK_SELECTIONS = 'SET_TASK_SELECTIONS';
export const CLEAR_TASK_SELECTIONS = 'CLEAR_TASK_SELECTIONS';
export const FETCH_TASK_PREFIXES = 'FETCH_TASK_PREFIXES';
export const FETCH_TASK_LOCATIONS = 'FETCH_TASK_LOCATIONS';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SET_PREFIX_FILTER = 'SET_PREFIX_FILTER';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const SET_EMPLOYEE_FILTER = 'SET_EMPLOYEE_FILTER';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const CLEAR_MAINTENANCE_TASK = 'CLEAR_MAINTENANCE_TASK';
export const GET_COMPLETION_LOGS = 'GET_COMPLETION_LOGS';

export const fetchTasks = (query) => {
  return {
    type: API_REQUEST,
    callAPI: TaskAPI.fetchTasks,
    data: { query },
    meta: { permissionKey: viewPermissionRefs.task },
    action: FETCH_TASKS,
  };
};

export const addTask = (task) => ({
  type: API_REQUEST,
  callAPI: TaskAPI.postTask,
  data: task,
  action: ADD_TASK,
  callback: () => fetchTasks(),
  closeModal: true,
});

export const addTaskNote = (note) => ({
  type: API_REQUEST,
  callAPI: TaskAPI.postTaskNote,
  data: note,
  action: ADD_TASK_NOTE,
  callback: () => getTask(note.id),
});

export const saveTask = (data) => {
  return {
    type: API_REQUEST,
    callAPI: TaskAPI.patchTask,
    data: data.task,
    action: SAVE_TASK,
    callback: () => fetchTasks(data.callback),
    closeModal: true,
  };
};

export const deleteTask = (query) => ({
  type: API_REQUEST,
  callAPI: TaskAPI.archiveTask,
  data: { id: query.id },
  action: DELETE_TASK,
  callback: query.callback,
  closeModal: true,
});

export const getTask = (id) => ({
  type: API_REQUEST,
  data: { id },
  callAPI: TaskAPI.getTask,
  action: GET_TASK,
});

export const getMaintenanceTask = (id) => ({
  type: API_REQUEST,
  data: { id },
  callAPI: TaskAPI.getTask,
  action: GET_MAINTENANCE_TASK,
});

export const deleteTaskInEdit = () => ({ type: DELETE_TASKINEDIT });

export const getTodaysTaskCompletions = (query) => {
  return {
    type: API_REQUEST,
    callAPI: taskCompletionsAPI.getTaskCompletions,
    data: { query: { ...getTodaysTaskCompletionsDefaultQuery(), ...query } },
    action: GET_TODAYS_TASK_COMPLETIONS,
    meta: { permissionKey: viewPermissionRefs.task, useManagerSiteId: true },
  };
};

export const fetchCompletionLogs = (query) => {
  return {
    type: API_REQUEST,
    callAPI: taskCompletionsAPI.fetchCompletionLogs,
    data: { query },
    action: GET_COMPLETION_LOGS,
    meta: { permissionKey: viewPermissionRefs.task },
  };
};

export const unLinkTask = (task) => ({
  type: API_REQUEST,
  callAPI: TaskAPI.unLinkTask,
  data: task,
  action: UNLINK_TASK,
  callback: task.callback,
  closeModal: true,
});

export const getEssentialTasks = (query) => {
  return {
    type: API_REQUEST,
    callAPI: EssentialTaskAPI.getEssentialTasks,
    data: { query },
    action: GET_ESSENTIAL_TASKS,
  };
};

export const addEssentialTask = (task) => ({
  type: API_REQUEST,
  callAPI: EssentialTaskAPI.postEssentialTask,
  data: task,
  action: ADD_ESSENTIAL_TASK,
  callback: () => getEssentialTasks(),
  closeModal: true,
});

export const saveEssentialTask = (data) => ({
  type: API_REQUEST,
  callAPI: EssentialTaskAPI.patchEssentialTask,
  data: data.task,
  action: SAVE_ESSENTIAL_TASK,
  callback: data.callback,
  closeModal: true,
});

export const deleteEssentialTask = (query) => ({
  type: API_REQUEST,
  callAPI: EssentialTaskAPI.archiveEssentialTask,
  data: { id: query.id },
  action: DELETE_ESSENTIAL_TASK,
  callback: query.callback,
  closeModal: true,
});

export const setFromDate = (date) => ({ type: SET_TASK_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_TASK_UNTIL_DATE, date });

export const setTaskSelections = (rows) => ({
  type: SET_TASK_SELECTIONS,
  rows,
});
export const clearTaskSelections = () => ({ type: CLEAR_TASK_SELECTIONS });
export const clearMaintenanceTask = () => ({ type: CLEAR_MAINTENANCE_TASK });

export const getTaskPrefixes = () => ({
  type: API_REQUEST,
  callAPI: taskPrefixAPI.getTaskPrefixes,
  action: FETCH_TASK_PREFIXES,
});

export const getTaskLocations = () => ({
  type: API_REQUEST,
  callAPI: taskLocationAPI.getTaskLocations,
  action: FETCH_TASK_LOCATIONS,
});

export const setMaintenanceTask = (taskId) => ({
  type: GET_MAINTENANCE_TASK,
  taskId,
});

export const setTypeFilter = (value) => ({
  type: SET_TYPE_FILTER,
  value,
});

export const setPrefixFilter = (value) => ({
  type: SET_PREFIX_FILTER,
  value,
});

export const setLocationFilter = (value) => ({
  type: SET_LOCATION_FILTER,
  value,
});

export const setEmployeeFilter = (value) => ({
  type: SET_EMPLOYEE_FILTER,
  value,
});

export const setStatusFilter = (value) => ({
  type: SET_STATUS_FILTER,
  value,
});
