import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import UploadSection from './UploadSection';

export class Photos extends Component {
  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.shape({})),
    deleteFile: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEnlargedPhoto: false,
    };
  }

  handleMediaClick = (photo) => {
    this.setState({
      showEnlargedPhoto: true,
      enlargedPhoto: {
        downloadUrl: photo.downloadUrl,
        description: photo.description,
      },
    });
  };

  render() {
    return (
      <div className="media-content" data-testid="photos">
        <UploadSection fileType="image" addFile={this.props.addFile} />
        <div className="media">
          {this.props.photos &&
            this.props.photos.map((photo, index) => (
              <div
                data-testid="photo"
                className="media-item"
                key={index}
                onClick={() => this.handleMediaClick(photo)}
              >
                <div className="media-item-container">
                  <img className="media-item-photo" src={photo.downloadUrl} alt="url" />
                </div>
                <div className="media-item-description text-truncate">{photo.description}</div>
                <div
                  className="media-item-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.deleteFile(photo);
                  }}
                >
                  <i className="icon icon-trash" />
                </div>
              </div>
            ))}
        </div>
        {this.state.showEnlargedPhoto && (
          <div
            className="media-enlarged-img-wrapper"
            onClick={() => this.setState({ showEnlargedPhoto: false })}
          >
            <div className="media-enlarged-img">
              <img
                className="media-enlarged-photo"
                src={this.state.enlargedPhoto.downloadUrl}
                alt="url"
              />
              <div className="media-enlarged-photo-description">
                {this.state.enlargedPhoto.description}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Photos;
