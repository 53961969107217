import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/detergents';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';

export const ADD_DETERGENT = 'ADD_DETERGENT';
export const ADD_DETERGENTS = 'ADD_DETERGENTS';
export const SAVE_DETERGENT = 'SAVE_DETERGENT';
export const DELETE_DETERGENT = 'DELETE_DETERGENT';
export const GET_DETERGENT = 'GET_DETERGENT';
export const GET_DETERGENTS = 'GET_DETERGENTS';
export const GET_CHART_DATA = 'GET_CHART_DATA';
export const ADD_DETERGENT_READING = 'ADD_DETERGENT_READING';
export const SET_COST_PER_CAR_CHART_FROM_DATE = 'SET_COST_PER_CAR_CHART_FROM_DATE';
export const SET_COST_PER_CAR_CHART_UNTIL_DATE = 'SET_COST_PER_CAR_CHART_UNTIL_DATE';
export const GET_DETERGENT_READINGS = 'GET_DETERGENT_READINGS';
export const SHOW_DETERGENT_SUMMARY_CHART = 'SHOW_DETERGENT_SUMMARY_CHART';
export const SET_DETERGENT_READINGS_FROM_DATE = 'SET_DETERGENT_READINGS_FROM_DATE';
export const SET_DETERGENT_READINGS_UNTIL_DATE = 'SET_DETERGENT_READINGS_UNTIL_DATE';
export const REMOVE_DETERGENT_READINGS = 'REMOVE_DETERGENT_READINGS';

export const getDetergents = (query) => ({
  type: API_REQUEST,
  callAPI: API.getDetergents,
  action: GET_DETERGENTS,
  data: query,
  meta: { permissionKey: viewPermissionRefs.detergent, useManagerSiteId: true },
});

export const saveDetergent = (detergent) => ({
  type: API_REQUEST,
  callAPI: API.patchDetergent,
  action: SAVE_DETERGENT,
  data: detergent,
  callback: () => getDetergents(),
  closeModal: true,
});

export const addDetergent = (detergent) => ({
  type: API_REQUEST,
  callAPI: API.postDetergent,
  data: detergent,
  action: ADD_DETERGENT,
  callback: () => getDetergents(),
  closeModal: true,
});

export const addDetergents = (detergents) => ({
  type: API_REQUEST,
  callAPI: API.postDetergents,
  data: detergents,
  action: ADD_DETERGENTS,
  callback: () => getDetergents(),
  closeModal: true,
});

export const deleteDetergent = (id) => ({
  type: API_REQUEST,
  callAPI: API.deleteDetergent,
  action: DELETE_DETERGENT,
  data: { id },
  callback: () => getDetergents(),
  closeModal: true,
});

export const getDetergent = (id) => ({
  type: API_REQUEST,
  callAPI: API.getDetergent,
  action: GET_DETERGENT,
  data: { id },
});

export const getChartData = (query, signal) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentChartData,
  action: GET_CHART_DATA,
  data: { query, signal },
});

export const getDetergentReadings = (query, signal) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentReadings,
  action: GET_DETERGENT_READINGS,
  data: { query, signal },
});

export const addDetergentReading = (reading, chartQuery, readingsQuery) => {
  const callbacks = [() => getDetergents()];

  // if (chartQuery) {
  //   callbacks.push(() => getChartData(chartQuery));
  // }
  if (readingsQuery) {
    callbacks.push(() => getDetergentReadings(readingsQuery));
  }

  return {
    type: API_REQUEST,
    callAPI: API.postDetergentReading,
    action: ADD_DETERGENT_READING,
    data: reading,
    callback: callbacks,
    closeModal: true,
  };
};

export const removeReadings = (id) => ({ type: REMOVE_DETERGENT_READINGS, id });
export const showChart = (chart) => ({
  type: SHOW_DETERGENT_SUMMARY_CHART,
  chart,
});
export const setCostPerCarChartFromDate = (date) => ({
  type: SET_COST_PER_CAR_CHART_FROM_DATE,
  date,
});
export const setCostPerCarChartUntilDate = (date) => ({
  type: SET_COST_PER_CAR_CHART_UNTIL_DATE,
  date,
});
export const setReadingsFromDate = (date) => ({
  type: SET_DETERGENT_READINGS_FROM_DATE,
  date,
});
export const setReadingsUntilDate = (date) => ({
  type: SET_DETERGENT_READINGS_UNTIL_DATE,
  date,
});
