import { sumBy } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import DataTable from '../../../core/components/DataTable';
import { formatAmount } from '../../../utils/money-util';
import { PayAsYouGoCount } from '../models';

const PayAsYouGoTable = ({
  intl,
  payAsYouGoCounts = {},
  showLoader,
  header,
  interiorFilter,
  propertyFilter,
}) => {
  const generateSummaryRow = (data) => {
    if (!data.length) {
      return [];
    }

    const currency = data[data.length - 1].currency;
    const count = sumBy(data, (i) => parseFloat(i.count));
    const amount = formatAmount(
      sumBy(data, (i) => parseFloat(i.amount)),
      currency
    );
    const tax = formatAmount(
      sumBy(data, (i) => parseFloat(i.tax)),
      currency
    );
    const total = formatAmount(
      sumBy(data, (i) => parseFloat(i.total)),
      currency
    );

    return [
      { index: 0, value: 'Total' },
      { index: 1, value: count },
      { index: 2, value: null },
      { index: 3, value: amount },
      { index: 4, value: tax },
      { index: 5, value: total },
    ];
  };

  const initializePayAsYouGoTable = () => {
    const headerIds = ['name', 'count', 'price', 'amount', 'tax', 'total'];
    const translatedHeaders = headerIds.map((id) => ({
      name: intl.formatMessage({ id }),
    }));
    let data = [];
    let summary = [];
    if (interiorFilter) {
      data = propertyFilter(data, 'productCategory', 'Interior');
      summary = generateSummaryRow(data);
      data = data.map((count) => count.formatToTableData());
    } else {
      summary = generateSummaryRow(payAsYouGoCounts);
      data = payAsYouGoCounts.map((count) => count.formatToTableData());
    }

    return {
      headers: translatedHeaders,
      data,
      summary,
      showLoader,
      addedClass: 'wash-count-table shrinked-table',
    };
  };

  return (
    <section className="wash-details wc-table-container">
      <h2 className="wc-table-header">{header}</h2>
      <DataTable {...initializePayAsYouGoTable()} />
    </section>
  );
};

PayAsYouGoTable.defaultProps = {
  payAsYouGoCounts: [],
};

PayAsYouGoTable.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  header: PropTypes.string.isRequired,
  payAsYouGoCounts: PropTypes.arrayOf(PropTypes.instanceOf(PayAsYouGoCount)),
  showLoader: PropTypes.bool.isRequired,
};

export default React.memo(PayAsYouGoTable);
