import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { requiredInput } from '../../../core/formValidation';
import { useIntl } from 'react-intl';

export const SelectProducts = ({ input, products, handleProductChange }) => (
  <select
    {...input}
    data-testid={input.name}
    onChange={handleProductChange}
    className="form-control w-100"
  >
    <option value="">{'Select Product'}</option>
    {products.map((p, index) => (
      <option key={index} value={p.id} data-testid="product-option">
        {p.name}
      </option>
    ))}
  </select>
);

SelectProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  handleProductChange: PropTypes.func,
};

export const AddressFields = ({ fields, startAddress, products, handleProductChange }) => {
  const intl = useIntl();
  const addAddressField = (isNew) => {
    const { id, name } = products[0] || {};
    fields.push({
      index: fields.length,
      address: Number(startAddress + fields.length) || fields.length,
      productId: !isNew && id ? id : null,
      name: !isNew && name ? name : null,
      isNew,
    });
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => {
        const { isNew } = fields.get(index);
        return (
          <div className="form-inline mb-2" key={index}>
            <div className="mr-2 flex-1">
              <Field
                name={`${item}.address`}
                value={startAddress + index}
                parse={(value) => Number(value, 10)}
                component="input"
                type="number"
                className="form-control w-100"
                readOnly
              />
            </div>
            <div className="flex-1">
              <Field
                name={`${item}.productId`}
                component={SelectProducts}
                type="select"
                products={products}
                handleProductChange={handleProductChange}
                parse={(value) => Number(value)}
              />
            </div>
            <button
              className="btn btn-secondary ml-2"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => fields.remove(index)}
            >
              <i className="icon icon-cross" />
            </button>
          </div>
        );
      })}
      <div className="wash-counter-register-buttons-container">
        <button
          id="add-address-button"
          className="btn btn-secondary"
          data-testid="add-address-button"
          type="button"
          onClick={() =>
            addAddressField(fields, startAddress, products, !(products && products[0]) || undefined)
          }
        >
          {intl.formatMessage({ id: 'addAddress' })}
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddressFields;
