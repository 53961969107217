import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import useDamageClaimOptions from '../../reclamations/hooks/useDamageClaimOptions';
import { removeReports, setFromDate, setUntilDate } from '../actions';
import DamageAreaReport from '../components/DamageAreaReport';
import MakeModelReport from '../components/MakeModelReport';
import ReportTypeSelector from '../components/ReportTypeSelector';
import { ReportType } from '../models';
import { selectFromDate, selectReportType, selectUntilDate } from '../reducer';

export const ClaimReports = () => {
  const dispatch = useDispatch();
  const reportType = useSelector(selectReportType);
  const fromDate = useSelector(selectFromDate);
  const untilDate = useSelector(selectUntilDate);
  useDamageClaimOptions();

  useEffect(() => {
    return function cleanUp() {
      dispatch(removeReports());
    };
  }, []);

  return (
    <section className="claim-reports">
      <div className="as-of-date-container">
        <DateRangeSelector
          dates={[fromDate, untilDate]}
          onSubmit={(dates) => {
            dispatch(setFromDate(dates.from));
            dispatch(setUntilDate(dates.until));
          }}
        />
      </div>
      <ReportTypeSelector />
      {reportType === ReportType.ClaimsByVehicleModel && <MakeModelReport />}
      {reportType === ReportType.ClaimsByDamageArea && <DamageAreaReport />}
    </section>
  );
};

export default ClaimReports;
