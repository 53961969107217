import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasInventoryEnabledRedir } from '../core/auth';
import Inventory from '../views/inventory/containers/Inventory';
import InventoryDetailPage from '../views/inventory/containers/InventoryDetailPage';

const InventoryRoute = () => {
  return (
    <Switch>
      <Route exact path="/:site/inventory/">
        <Inventory />
      </Route>
      <Route path="/:site/inventory/:inventory">
        <InventoryDetailPage />
      </Route>
    </Switch>
  );
};

export default userHasInventoryEnabledRedir(InventoryRoute);
