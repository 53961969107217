import { PropTypes } from 'prop-types';
import React from 'react';

const IntervalCount = ({ input }) => {
  const intervalCountOptions = [];
  for (let i = 1; i < 31; i += 1) {
    intervalCountOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return (
    <div className="form-group">
      <label htmlFor="intervalCount">Repeat Interval</label>
      <select {...input} id="intervalCount" className="form-control" style={{ maxWidth: '75px' }}>
        {intervalCountOptions}
      </select>
    </div>
  );
};

IntervalCount.propTypes = {
  input: PropTypes.shape({}),
};

export default IntervalCount;
