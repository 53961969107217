import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { requiredSelect } from '../../../core/formValidation';

const OpenWorkLogForm = (props) => {
  const { employees, openLog, showDialog, openWorkLogs, saveWorkLog } = props;
  const employeeOptions = employees.map((e) => {
    const fullName = `${e.lastName}, ${e.firstName}`;
    return (
      <option key={e.id} value={e.id}>
        {fullName}
      </option>
    );
  });

  const closeLog = showDialog.bind(null, 'CLOSE_WORK_LOG', {
    title: 'Clock Out',
    isNew: false,
    openLog,
    employees,
    openWorkLogs,
    saveWorkLog,
  });

  return (
    <div className="form-group">
      <div className="form-group">
        <label htmlFor="employeeId">Select Your Name</label>
        <Field
          name="employeeId"
          component={requiredSelect}
          className="form-control"
          parse={(value) => value && Number(value, 10)}
        >
          <option value="">Choose Name...</option>
          {employeeOptions}
        </Field>
        {openLog && (
          <div className="mt-2">
            <div>
              You are still clocked in from {moment(openLog.start).format('MM/DD/YY')}. Please clock
              out before continuing.
            </div>
            <button className="btn btn-secondary mt-2" onClick={closeLog}>
              Clock out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

OpenWorkLogForm.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  openLog: PropTypes.shape({ start: PropTypes.string }),
  showDialog: PropTypes.func,
  saveWorkLog: PropTypes.func,
  openWorkLogs: PropTypes.arrayOf(PropTypes.object),
};

const enhance = compose(reduxForm({ form: 'openWorkLogForm' }));

export default enhance(OpenWorkLogForm);
