import { Col, Form, Row } from 'reactstrap';
import '../../../../../styles/user-form.scss';
import { IntlType } from '../../../../../utils/typing';
import { Formik } from '../../../hooks/useForm';
import { FormSelections } from '../hooks/useFormSelections';
import SiteSelector from './SiteSelector';
import UserGroupSelect from './UserGroupSelect';

interface Props {
  intl: IntlType;
  formik: Formik;
  formSelections: FormSelections;
}

const UserForm = (props: Props): JSX.Element => {
  return (
    <Form data-testid="user-form">
      <Row className="user-form-row" form>
        <Col md={6} className="user-form-col">
          <UserGroupSelect {...props} />
        </Col>
        <Col md={6} className="site-selection-column">
          <SiteSelector {...props} />
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
