import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasExpenseEnabledRedir } from '../core/auth';
import ExpensesDetail from '../views/expenseDetail/containers/ExpenseDetail';
import Expenses from '../views/expenses/containers/Expenses';

const ExpensesRoute = () => {
  return (
    <Switch>
      <Route exact path="/:site/expenses">
        <Expenses />
      </Route>
      <Route path="/:site/expenses/:expense">
        <ExpensesDetail />
      </Route>
    </Switch>
  );
};

export default userHasExpenseEnabledRedir(ExpensesRoute);
