import React, { useState } from 'react';
import { viewPermissionRefs } from '../../../core/components/UserGroupPermissions';
import TodaysTasks from './TodaysTasks';
import Tasks from './Tasks';
import Tabs from '../../../core/components/Tabs';
import { useIntl } from 'react-intl';
import CompletionLogs from './CompletionLogs';

const TasksViews = () => {
  const [tabType, setTabType] = useState('todaysTasks');
  const intl = useIntl();

  const tabComponents = [
    {
      component: (
        <div className="col-sm-12">
          <TodaysTasks />
        </div>
      ),
      title: intl.formatMessage({ id: 'todaysTasks' }),
      type: 'todaysTasks',
      permissionKey: viewPermissionRefs.task,
    },
    {
      component: <Tasks />,
      title: intl.formatMessage({ id: 'All tasks' }),
      type: 'tasks',
      permissionKey: viewPermissionRefs.task,
    },
    {
      component: (
        <div className="col-sm-12">
          <CompletionLogs />
        </div>
      ),
      title: intl.formatMessage({ id: 'completedTasks' }),
      type: 'completedTasks',
      permissionKey: viewPermissionRefs.view_logs,
    },
  ];

  const getTabsProps = () => ({
    tabs: tabComponents,
    onClick: setTabType,
    selectedType: tabType,
  });
  const visibleComponent = tabComponents.find((t) => t.type === tabType);

  return (
    <div className="container-fluid">
      <Tabs {...getTabsProps()} />
      <div className="detergent-tab-container">
        <div>{visibleComponent.component}</div>
      </div>
    </div>
  );
};

export default TasksViews;
