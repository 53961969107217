import { useIntl } from 'react-intl';

interface DrawerProps {
  children: any;
  /** Is the drawer currently expanded? */
  expanded?: boolean;
  /** Is the drawer currently maximized? */
  maximized?: boolean;
  /** Percentage-based width of the drawer when expanded. */
  expandedWidth?: number;
}

const Drawer = ({
  children,
  expanded = false,
  maximized = false,
  expandedWidth = 50,
}: DrawerProps) => {
  const drawerStyles = () => {
    let width = 0;
    if (expanded) {
      if (maximized) {
        width = 100;
      } else {
        width = expandedWidth;
      }
    }
    const styles = {
      flexBasis: `${width}%`,
    };
    return styles;
  };
  return (
    <div
      className={['drawer', expanded ? 'expanded' : '', maximized ? 'maximized' : '']
        .join(' ')
        .trim()}
      style={drawerStyles()}
    >
      <div className="drawer-content">{children}</div>
    </div>
  );
};

interface DrawerControlsProps {
  /** Is the drawer currently maximized? */
  maximized?: boolean;
  /** Function to call when close button is clicked */
  closeFunction: any;
  /** Function to call when maximize button is clicked */
  maximizeFunction?: any;
}

const DrawerControls = ({
  closeFunction,
  maximized = false,
  maximizeFunction = null,
}: DrawerControlsProps) => {
  const intl = useIntl();
  return (
    <div className="drawer-controls">
      {maximizeFunction && (
        <button
          type="button"
          onClick={() => maximizeFunction()}
          className="button drawer-maximize mr-2"
          title={
            maximized
              ? intl.formatMessage({ id: 'minimizeDrawer' })
              : intl.formatMessage({ id: 'maximizeDrawer' })
          }
        >
          {maximized ? <i className="uil uil-compress"></i> : <i className="uil uil-focus"></i>}
        </button>
      )}
      <button
        type="button"
        onClick={() => closeFunction()}
        className="button drawer-close"
        title={intl.formatMessage({ id: 'closeDrawer' })}
      >
        <i className="uil uil-times"></i>
      </button>
    </div>
  );
};

const DrawerContainer = ({ children }: any) => {
  return <div className="drawer-container">{children}</div>;
};

export { Drawer, DrawerContainer, DrawerControls };
