import { add, sum } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { generateToastr } from '../../../core/toastMessages';
import { parseNumber } from '../../../utils/number-util';
import * as actions from '../actions';
import PurchaseOrderFormWrapper from '../components/PurchaseOrderFormWrapper';
import { selectAdditionalCost, selectSubTotal } from '../purchaseOrderReducer';

const PurchaseOrderDialog = (props) => {
  const onReady = () => {
    props.submit('purchaseOrder');
  };
  const formatItems = (items) => {
    return items.map((item) => {
      return {
        name: item.name,
        quantity: Number(item.quantity),
        price: `${parseNumber(item.price)}`,
      };
    });
  };
  const checkValid = (value) => {
    if (value.categoryId === 0 || value.employeeId === 0 || value.vendorId === 0) {
      generateToastr('error', 'Invalid Field', 'Please select all required options', {
        timeOut: 5000,
      });
      return false;
    }
    const formTotal = add(sum(props.itemCosts), props.additionalCost) || 0;
    if (formTotal - parseFloat(value.discount) < 0) {
      generateToastr('error', 'Invalid Value', 'Discount cannot reduce the Total below zero', {
        timeOut: 5000,
      });
      return false;
    }

    return true;
  };

  const onSubmit = (values) => {
    const newValues = {
      id: values.id,
      categoryId: Number(values.categoryId),
      discount: `${parseNumber(values.discount)}`,
      employeeId: Number(values.employeeId),
      items: formatItems(values.items),
      orderDate: values.orderDate,
      shippingAmount: `${parseNumber(values.shippingAmount)}`,
      taxAmount: `${parseNumber(values.taxAmount)}`,
      trackingNumber: values.trackingNumber,
      memo: values.memo,
      vendorId: Number(values.vendorId),
    };
    if (checkValid(newValues)) {
      if (props.isNew) {
        props.addPurchaseOrder(newValues, props.callback);
      } else {
        props.editPurchaseOrder(newValues, props.callback);
      }
    }
  };

  const submitDisabled = checkErrors(props.errors, 'items');
  return (
    <ModalDialog
      title="Add Purchase Order"
      onReady={onReady}
      isOpen={props.isOpen}
      close={props.hideDialog}
      disabled={submitDisabled}
    >
      <PurchaseOrderFormWrapper {...props} onSubmit={onSubmit} />
    </ModalDialog>
  );
};

const getErrors = getFormSyncErrors('purchaseOrder');

PurchaseOrderDialog.propTypes = {
  addPurchaseOrder: PropTypes.func.isRequired,
  editPurchaseOrder: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hideDialog: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  initialValue: PropTypes.shape({}), // eslint-disable-line
  isNew: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return {
    errors,
    itemCosts: selectSubTotal(state),
    additionalCost: selectAdditionalCost(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addPurchaseOrder: actions.addPurchaseOrder,
      editPurchaseOrder: actions.editPurchaseOrder,
      submit,
      hideDialog,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDialog);
