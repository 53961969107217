import { isEqual } from 'lodash/lang';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';

/* Lint has a problem with 'page-items' being divs instead of buttons */
/* eslint-disable */
class Pagination extends Component {
  shouldComponentUpdate(nextProps) {
    if (isEqual(nextProps, this.props)) {
      return false;
    }
    return true;
  }

  getTotalPages() {
    return Math.ceil(this.props.info.total / this.props.info.pageSize) || 0;
  }

  getPagesArray() {
    const totalPages = this.getTotalPages();
    const totalPagesArray = Array.from(Array(totalPages).keys());
    const page = this.props.info.page;

    let from = Math.max(page - 5, 0);
    let to = Math.min(page + 6, totalPages);

    const atTheStartOfArray = from === 0;
    const atTheEndOfArray = to === totalPages;

    if (atTheStartOfArray) {
      to = 11;
    } else if (atTheEndOfArray) {
      from = totalPages - 11;
    }

    return totalPagesArray.slice(from, to);
  }

  getPreviousPage() {
    return this.props.info.page > 0 ? this.props.info.page - 1 : 0;
  }

  getNextPage() {
    return this.props.info.page < this.getTotalPages() - 1
      ? this.props.info.page + 1
      : this.getTotalPages() - 1;
  }

  getActivePage(page) {
    return this.props.info.page === page ? 'page-item active' : 'page-item';
  }

  render() {
    if (this.props.info) {
      return (
        <div className="pagination justify-content-center">
          <div className="page-item" onClick={() => this.props.info.goToPage(0)}>
            <div className="page-link">First</div>
          </div>
          <div className="page-item">
            <div
              className="page-link"
              onClick={() => this.props.info.goToPage(this.getPreviousPage())}
            >
              <span>&laquo;</span>
              <span className="sr-only">Previous</span>
            </div>
          </div>
          {this.getPagesArray().map((page) => (
            <div
              key={page}
              onClick={() => this.props.info.goToPage(page)}
              className={this.getActivePage(page)}
            >
              <div className="page-link">{page + 1}</div>
            </div>
          ))}
          <div className="page-item">
            <div
              className="page-link"
              onClick={() => this.props.info.goToPage(Math.max(this.getNextPage(), 0))}
            >
              <span>&raquo;</span>
              <span className="sr-only">Next</span>
            </div>
          </div>
          <div
            className="page-item"
            onClick={() => this.props.info.goToPage(Math.max(this.getTotalPages() - 1, 0))}
          >
            <div className="page-link">Last</div>
          </div>
        </div>
      );
    }
    return null;
  }
}

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  goToPage: PropTypes.func,
};

export default Pagination;
