import { map } from 'lodash';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import Permission, { RoleTypes } from '../../../core/components/Permission';

import {
  TextArea,
  fieldRequired,
  Input,
  requiredDatePicker,
  requiredInput,
  requiredSelect,
} from '../../../core/formValidation';
import { ProjectType } from '../models';

const ProjectEditForm = () => {
  const intl = useIntl();
  const typeOptions = [
    { value: ProjectType.Maintenance, text: 'Maintenance' },
    { value: ProjectType.Preventative, text: 'Project' },
    { value: ProjectType.Enhancement, text: 'Enhancement' },
  ];

  return (
    <div>
      <div className="form-title">{intl.formatMessage({ id: 'projectInformation' })} </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="name">{intl.formatMessage({ id: 'projectName' })}</label>
          <Field
            name="name"
            id="name"
            component={requiredInput}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder={intl.formatMessage({ id: 'projectEnterName' })}
            validate={[fieldRequired]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="projectType">Project Type</label>
          <Field
            id="projectType"
            name="projectType"
            component={requiredSelect}
            parse={(value: any) => value && Number(value)}
            options={typeOptions}
            validate={[fieldRequired]}
            onBlur={(e: any) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="start">{intl.formatMessage({ id: 'startDate' })}</label>
          <Field
            name="start"
            id="start"
            component={requiredDatePicker}
            placeholderText={intl.formatMessage({ id: 'utilityStartDatePlaceholder' })}
            showToday
            validate={[fieldRequired]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="end">{intl.formatMessage({ id: 'endDate' })}</label>
          <Field
            name="end"
            id="end"
            component={DateTimePicker}
            placeholderText={intl.formatMessage({ id: 'utilityEndDatePlaceholder' })}
            showToday={false}
            className="form-control"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group task-description-field">
          <label className="form-label" htmlFor="description">
            {' '}
            {intl.formatMessage({ id: 'description' })}
          </label>
          <Field
            className="tasks-textarea form-control"
            name="description"
            id="description"
            component={TextArea}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder={intl.formatMessage({ id: 'projectEnterDescription' })}
          />
        </div>
        {/* Is this field going to be automatically populated somehow? */}
        <div className="form-group">
          <label htmlFor="projectId">Project ID</label>
          <Field
            name="projectId"
            id="projectId"
            component={Input}
            parse={(value: any) => Number(value)}
            validate={[fieldRequired]}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'project' })(ProjectEditForm);
