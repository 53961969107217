import { call, takeEvery } from 'redux-saga/effects';
import * as storage from '../../core/storage';
import * as toastMsg from '../../core/toastMessages';

export function* templateSuccess() {
  yield call(toastMsg.templateDataLoaded);
}

export function* templateError() {
  yield call(toastMsg.templateDataFailed);
}

export function* storeSelectedSite({ response }) {
  if (response.id) {
    yield call(storage.setSelectedSite, response.id);
  }
}

export function* watchSiteSettingsSagas() {
  yield takeEvery('LOAD_TEMPLATE_DATA_SUCCESS', templateSuccess);
  yield takeEvery('LOAD_TEMPLATE_DATA_FAILURE', templateError);
  yield takeEvery('FETCH_SITE_SETTINGS_SUCCESS', storeSelectedSite);
}
