import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import DataTable from '../../../core/components/DataTable';
import { selectCompletionLogs } from '../reducer';
import { TaskCompletionStatus } from '../types';
import { fetchCompletionLogs } from '../actions';
import { getLastCompleted } from '../helpers';
import { selectEmployees } from '../../employees/reducer';
import { fetchEmployees } from '../../employees/actions';
import { selectSites } from '../../site-selection/reducer';
import { getApiStatus, getKeyFromEnum } from '../../../core/utils';
import EmployeeDropdown from '../../../core/components/EmployeeDropdown';

const CompletionLogs = () => {
  const taskCompletions = useSelector(selectCompletionLogs),
    employees = useSelector(selectEmployees),
    siteList = useSelector(selectSites),
    logsAreLoading = useSelector((state) => getApiStatus(state, 'GET_COMPLETION_LOGS'));
  const intl = useIntl();
  const dispatch = useDispatch();

  const [sortedQuery, setSortedQuery] = useState({});
  const [currentEmployeeId, setEmployee] = useState(null);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [untilDate, setUntilDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));

  const getTaskCompletionPage = useCallback(() => {
    dispatch(
      fetchCompletionLogs({
        fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        untilDate: moment().endOf('day').format('YYYY-MM-DD'),
        status: getKeyFromEnum(TaskCompletionStatus, TaskCompletionStatus.Completed),
        ...sortedQuery,
        employeeId: currentEmployeeId ?? '',
      })
    );
    dispatch(fetchEmployees());
  }, [fetchCompletionLogs, fromDate, untilDate, sortedQuery, currentEmployeeId]);

  useEffect(() => {
    getTaskCompletionPage();
  }, [fromDate, untilDate, sortedQuery, currentEmployeeId]);

  const getTaskSiteName = (task) => {
    let site;
    if (task?.siteId) {
      site = siteList.find((s) => s.id === task.siteId);
    } else {
      site = siteList.find((s) => s.id === task.task.siteId);
    }
    return site ? site.name : '';
  };

  const handleSortedQuery = (query) => {
    setSortedQuery(query);
  };

  const formatEmployeeName = (taskCompletion) => {
    const employee = employees.find((e) => e.id === taskCompletion.employeeId);
    return employee ? `${employee.firstName} ${employee.lastName}` : '';
  };

  const initializeDatatable = useCallback(() => {
    const data = taskCompletions.map((taskCompletion) => ({
      _id: taskCompletion.id,
      _taskId: taskCompletion.taskId,
      _timestamp: taskCompletion.timestamp,
      _name: taskCompletion.task && taskCompletion.task.name,
      name: (
        <div>
          <div>{taskCompletion.task && taskCompletion.task.name}</div>
          <div className="task-subtitle">
            {taskCompletion.task &&
            taskCompletion.task.subTitle &&
            taskCompletion.task.subTitle.length > 50
              ? taskCompletion.task.subTitle.slice(0, 47) + '...'
              : taskCompletion.task.subTitle}
          </div>
        </div>
      ),
      lastCompleted: getLastCompleted(taskCompletion, employees),
      employee: formatEmployeeName(taskCompletion),
      site: taskCompletion?.task && getTaskSiteName(taskCompletion),
    }));

    const headers = [
      {
        name: intl.formatMessage({ id: 'name' }),
        databaseProperty: 'name',
        key: 'name.props.children[0].props.children',
      },
      {
        name: intl.formatMessage({ id: 'lastCompleted' }),
        databaseProperty: 'timestamp',
        key: '_timestamp',
      },
      {
        name: intl.formatMessage({ id: 'employee' }),
        databaseProperty: 'employee',
        key: 'employee',
      },
      { name: intl.formatMessage({ id: 'site' }), databaseProperty: 'siteId', key: 'site' },
    ];

    return {
      data,
      headers,
      toCSV: true,
      scrolling: true,
      fetchSortedData: handleSortedQuery,
      searchOptions: {
        uniqueField: '_id',
        dataToSearchFor: ['_name'],
      },
    };
  }, [taskCompletions, intl]);

  const handleSetEmployee = (e) => {
    if (e === 'All') {
      setEmployee(null);
    } else {
      setEmployee(Number(e));
    }
  };

  const setDateActions = {
    setFromDate: (date) => setFromDate(date),
    setUntilDate: (date) => setUntilDate(date),
  };

  return (
    <div data-testid="completion-log">
      <div className="table-filters no-margin">
        <div className="form-group">
          <div className="as-of-date-container">
            <DateRangeSelector
              dates={[fromDate, untilDate]}
              removeItems={[
                'yearToDate',
                'lastYearToDate',
                'lastMonth',
                'thisMonth',
                'lastWeek',
                'thisWeek',
              ]}
              includeCustomSelect={false}
              onSubmit={(dates) => {
                setDateActions.setFromDate(dates.from);
                setDateActions.setUntilDate(dates.until);
              }}
            />
          </div>
          <label htmlFor="employeeId" className="form-check-label">
            {intl.formatMessage({ id: 'teamMember' })}
          </label>
          <EmployeeDropdown
            employees={employees}
            currentEmployeeId={currentEmployeeId}
            setEmployee={handleSetEmployee}
            defaultOption={intl.formatMessage({ id: 'all' })}
            intl={intl}
          />
        </div>
      </div>
      <DataTable showLoader={logsAreLoading} intl={intl} {...initializeDatatable()} />
    </div>
  );
};

export default CompletionLogs;
