import toPairs from 'lodash/toPairs';

export default class SearchParams {
  constructor(query = {}) {
    this.paramsMap = new Map(toPairs(query));
  }

  append(key, value) {
    this.paramsMap.set(key, value);
  }

  toString() {
    const paramsList = [];

    this.paramsMap.forEach((val, key) => {
      if (Array.isArray(val)) {
        val.forEach((e) => {
          paramsList.push(`${key}=${e}`);
        });
      } else {
        paramsList.push(`${key}=${val}`);
      }
    });

    return paramsList.join('&');
  }
}
