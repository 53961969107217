import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/work-logs';

export const FETCH_OPEN_WORK_LOGS = 'FETCH_OPEN_WORK_LOGS';
export const FETCH_WORK_LOGS = 'FETCH_WORK_LOGS';
export const ADD_WORK_LOG = 'ADD_WORK_LOG';
export const CLOSE_WORK_LOG = 'CLOSE_WORK_LOG';

export const fetchOpenWorkLogs = () => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchOpenWorkLogs,
    action: FETCH_OPEN_WORK_LOGS,
  };
};

export const fetchWorkLogs = (employeeId) => {
  return {
    type: API_REQUEST,
    data: { employeeId },
    callAPI: API.fetchWorkLogs,
    action: FETCH_WORK_LOGS,
  };
};

export const addWorkLog = (log) => {
  return {
    type: API_REQUEST,
    callAPI: API.postWorkLog,
    data: log,
    action: ADD_WORK_LOG,
    callback: fetchOpenWorkLogs,
    closeModal: true,
  };
};

export const saveWorkLog = (log) => {
  return {
    type: API_REQUEST,
    callAPI: API.patchWorkLog,
    data: log,
    action: CLOSE_WORK_LOG,
    callback: fetchOpenWorkLogs,
    closeModal: true,
  };
};
