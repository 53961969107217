import React from 'react';
import Loader from '../../../core/components/Loader';
const locale = navigator.language;

const RevenueCount = ({ count, text, currency }: any) => {
  if (!count) {
    return <Loader />;
  }

  const dollarUSLocale = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  return (
    <div>
      <div className="revenue-count-title">{text}</div>
      <div className="revenue-count">{dollarUSLocale.format(parseInt(count))}</div>
    </div>
  );
};

export default RevenueCount;
