import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

const tabStyle = {
  color: '#292b2c',
};

export const TabsWithContent = ({ intl, tabHeaders, components, classes }) => {
  const [shownComponent, setShownComponent] = useState(tabHeaders[0]);

  const getTabs = () =>
    tabHeaders.map((link) => (
      <li className="nav-item" key={link}>
        <button
          data-testid={`${link}Tab`}
          className={`nav-link${(shownComponent === link && ' active') || ''}`}
          style={tabStyle}
          onClick={() => setShownComponent(link)}
        >
          {intl.formatMessage({ id: link })}
        </button>
      </li>
    ));

  return (
    <span data-testid="tabsWithContent" className={classes}>
      <ul className="nav nav-tabs">{getTabs()}</ul>
      <div className="content">{components[shownComponent]}</div>
    </span>
  );
};

TabsWithContent.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  tabHeaders: PropTypes.arrayOf(PropTypes.string),
  components: PropTypes.shape({}),
};

export default injectIntl(TabsWithContent);
