import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'redux-form';

const RewashCauses = () => {
  const intl = useIntl();
  return (
    <div>
      <div className="form-group row" style={{ marginBottom: '0' }}>
        <div className="col-sm-12">
          <label className="detail-info-label">{intl.formatMessage({ id: 'rewashCauses' })}</label>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.dirtyWheels"
                id="dirtyWheels"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'dirtyWheels' })}
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.dirtyRockerPanel"
                id="dirtyRockerPanel"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'dirtyRockerPanel' })}
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.dirtyBackend"
                id="dirtyBackend"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'dirtyBackend' })}
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.other"
                id="other"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'other' })}
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.serviceFailure"
                id="serviceFailure"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'serviceFailure' })}
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.missedSpots"
                id="missedSpots"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'missedSpots' })}
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.needsRewash.bugs"
                id="bugs"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              {intl.formatMessage({ id: 'bugs' })}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewashCauses;
