import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const component = ({ input, products, selectedProducts }) => {
  const [selectedWashes, setSelectedWashes] = useState(selectedProducts);

  useEffect(() => {
    input.onChange(selectedWashes);
  }, [selectedWashes]);

  const toggleWashSelection = (wash) => () => {
    const newSelectedWashes = selectedWashes.includes(wash)
      ? selectedWashes.filter((selectedWash) => selectedWash !== wash)
      : [...selectedWashes, wash];

    setSelectedWashes(newSelectedWashes);
  };

  const toWashListItems = (detergent, i) => {
    const selected = selectedWashes && selectedWashes.includes(detergent.id) ? ' selected' : '';

    return (
      <li
        key={`${detergent.name}-${i}`}
        className={`list-group-item detergent-wash-selection${selected}`}
        onClick={toggleWashSelection(detergent.id)}
      >
        {detergent.name}
      </li>
    );
  };

  const washListItems = products ? products.map(toWashListItems) : [];

  return <ul className="list-group">{washListItems}</ul>;
};

component.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  selectedProducts: PropTypes.arrayOf(PropTypes.number),
};

component.defaultProps = {
  selectedProducts: [],
};

export default React.memo(component, isEqual);
