import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import DataTableHeaderSelect from './DataTableHeaderSelect';

export const SortDirection = {
  ASC: true,
  DESC: false,
};

const DataTableColumns = ({ headers, fetchSortedData, isLoading, headerSelectProps }) => {
  const [sortingData, setSortingData] = useState({
    key: null,
    direction: null,
    name: null,
  });

  const handleSorting = (header) => {
    if (!isLoading && fetchSortedData && header.databaseProperty) {
      const upArrow = String.fromCharCode(9650);
      const downArrow = String.fromCharCode(9660);

      const direction = !sortingData.direction;

      const newSortingData = {
        key: header.databaseProperty,
        name: header.name + ' ' + (direction ? upArrow : downArrow),
        direction,
      };

      setSortingData(newSortingData);
      fetchSortedData(newSortingData);
    }
  };

  const getClassNames = (header) => {
    return classNames({
      'sortable-column': fetchSortedData && header.databaseProperty,
      'column-header': true,
      disabled: isLoading,
    });
  };

  const getColumnElement = (header) => {
    const isSortedColumn = sortingData.key === header.databaseProperty && fetchSortedData;

    return (
      <th
        data-testid="datatable-header"
        key={header.name}
        onClick={() => handleSorting(header)}
        className={getClassNames(header)}
      >
        {isSortedColumn ? sortingData.name : header.name}
      </th>
    );
  };

  return (
    <tr>
      <DataTableHeaderSelect {...headerSelectProps} />
      {headers.map(getColumnElement)}
    </tr>
  );
};

DataTableColumns.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  fetchSortedData: PropTypes.func,
  isLoading: PropTypes.bool,
  headerSelectProps: PropTypes.shape({}),
};

export default DataTableColumns;
