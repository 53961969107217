import * as _ from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { formatNumber, fragmentsDelimForLocale, restrictFragments } from '../utils/number-util';
import DateTimePicker from './components/DateTimePicker';
import RichTextEditor from './components/RichTextEditor';

export const checkEmptyValues = (values) => {
  const errors = {};

  _.forEach(values, (value, key) => {
    if ((!value && typeof value !== 'boolean') || (Array.isArray(value) && !value.length)) {
      errors[key] = 'Required';
    }
  });

  return errors;
};

export const hasValue = (value) => {
  return !!(_.isNumber(value) || value);
};

export const fieldRequired = (value) => {
  if (_.isArray(value)) {
    return value.length === 0 ? 'Required' : undefined;
  }
  return hasValue(value) ? undefined : 'Required';
};

export const isNumber = (value) => {
  // TODO: use isNumber from number-util
  return Number.isNaN(Number(value)) ? 'Not a number' : undefined;
};

export const missingFields = (current, requiredList) => {
  const values = _.values(current);
  const currentValues = values.filter((value) => value !== null);
  return requiredList.length !== currentValues.length;
};

export const checkCurrencyFormatting = (value) => {
  if (!value) {
    return 'required';
  }

  const fragmentsDelim = fragmentsDelimForLocale();
  const valuesAroundDecimal = value.toString().split(fragmentsDelim);
  const onlyDecimal = valuesAroundDecimal[0].length === 0 && valuesAroundDecimal[1].length === 0;
  const noTrailingValueAfterDecimal = valuesAroundDecimal.length > 1 && !valuesAroundDecimal[1];
  const nonDigits = new RegExp('\\D', 'g');

  if (onlyDecimal || noTrailingValueAfterDecimal) {
    return 'required';
  }

  if (valuesAroundDecimal.length > 2) {
    return 'required';
  }

  if (valuesAroundDecimal[1] && nonDigits.test(valuesAroundDecimal[1])) {
    return 'required';
  }
  return undefined;
};

export const missingDays = (value) => (value ? undefined : 'Missing schedule');

export const checkVideoUrl = (value) => {
  let embedUrl = '';
  if (String(value).match(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/)) {
    if (String(value).includes('embed')) {
      return value;
    }

    const url = new URL(value);
    const videoId = url.searchParams.get('v')
      ? url.searchParams.get('v')
      : url.pathname.split('/')[1];
    embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : 'Invalid Video URL';
  }

  if (String(value).match(/^(http(s)?:\/\/)?((w){3}.)?vimeo\.com\/.+$/)) {
    const url = new URL(value);
    const videoId = url.pathname.split('/')[1];
    embedUrl = videoId ? `https://player.vimeo.com/video/${videoId}` : 'Invalid Video URL';
  }

  return embedUrl;
};

export const checkErrors = (errors, topic) => {
  let errorKeys = _.keys(errors);
  if (errors && errors[topic]) {
    const validateItems = _.map(errors[topic], (item) => {
      return _.keys(item).length > 0;
    });
    if (!validateItems.includes(true)) {
      errorKeys = _.keys(_.omit(errors, topic));
    }
  }

  return errorKeys.length > 0;
};

export const emailValidation = (email) => {
  return String(email).match('^($|[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+)$');
};

const styles = {
  general: {
    width: '0.2rem',
    height: '100%',
    display: 'inline-block',
    borderRadius: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
};

const Validator = ({ isValid }) => {
  const backgroundColor = isValid ? 'rgb(94,185,94)' : 'rgb(217,83,79)';
  const requiredStyle = { ...styles.general, backgroundColor };

  return <div style={requiredStyle} />;
};

Validator.propTypes = {
  isValid: PropTypes.bool.isRequired,
};

const wrapperStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  formField: {
    display: 'inline-block',
    width: '100%',
  },
};

export const RequiredWrapper = (props) => {
  const { value, children, exclusions } = props;

  const validation = _.get(children, 'props.meta.error', false);

  const checkValidity = () => {
    if (Array.isArray(value)) {
      return !!value.length;
    }

    return hasValue(value) && (exclusions ? !exclusions.includes(value) : true) && !validation;
  };

  return (
    <div style={wrapperStyles.container}>
      <Validator isValid={checkValidity()} />
      <div style={wrapperStyles.formField}>{children}</div>
    </div>
  );
};

RequiredWrapper.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])),
  ]),
  exclusions: PropTypes.arrayOf(PropTypes.any),
};

const ErrorWrapper = (props) => {
  const { isValid, children } = props;
  return (
    <div style={wrapperStyles.container}>
      <Validator isValid={isValid} />
      <div style={wrapperStyles.formField}>{children}</div>
    </div>
  );
};

ErrorWrapper.propTypes = {
  children: PropTypes.node,
  isValid: PropTypes.bool.isRequired,
};

const validationProps = ['meta', 'parse', 'validate'];

/*
Select options can be entered as a prop with the name "options",
or as children of the Field component. If a prop, options can either
be React elements or a traditional array of objects with at least
"value" and "text" properties
*/
export const Select = ({ input, options, children, ...props }) => {
  const knownProps = _.omit(props, validationProps);

  return (
    <select {...input} className="form-control" {...knownProps}>
      {children}
      {options &&
        (React.isValidElement(options[0])
          ? options
          : options.map((o) => (
              <option key={o.value} value={o.value} disabled={o.disabled}>
                {o.text}
              </option>
            )))}
    </select>
  );
};

Select.propTypes = {
  input: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
};

export const Input = ({ input, ...props }) => {
  const knownProps = _.omit(props, validationProps);
  let value = input.value;

  if (props.decimal) {
    value = restrictFragments(value, props.decimal);
  }
  if (props.commas) {
    value = formatNumber(value);
  }
  return (
    <input {...input} value={value} className={`form-control ${input.className}`} {...knownProps} />
  );
};

Input.propTypes = {
  input: PropTypes.shape({}),
  commas: PropTypes.string,
  decimal: PropTypes.string,
};

export const VideoEmbedInput = ({ input, ...props }) => {
  const knownProps = _.omit(props, validationProps);
  const value = input.value;
  return (
    <input {...input} value={value} className={`form-control ${input.className}`} {...knownProps} />
  );
};

VideoEmbedInput.propTypes = {
  input: PropTypes.shape({}),
};

export const TextArea = ({ input, ...props }) => {
  const knownProps = _.omit(props, validationProps);
  return <textarea {...input} className="form-control" {...knownProps} />;
};

TextArea.propTypes = {
  input: PropTypes.shape({}),
  defaultValue: PropTypes.string,
};

export const ControlledRichText = (props) => {
  const {
    placeholder,
    input: { onChange, value },
    meta: { initial },
    disabled,
    id,
  } = props;
  return (
    <RichTextEditor
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      initialValue={initial}
      {...props}
    />
  );
};

ControlledRichText.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.string }),
  meta: PropTypes.shape({ initial: PropTypes.string }),
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export const requiredSelect = (props) => (
  <RequiredWrapper value={props.input.value}>
    <Select {...props} />
  </RequiredWrapper>
);

requiredSelect.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
  children: PropTypes.any,
};

export const requiredSelectWithExclusions = (props) => (
  <RequiredWrapper value={props.input.value} exclusions={props.exclusions}>
    <Select {...props} />
  </RequiredWrapper>
);

requiredSelectWithExclusions.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
  children: PropTypes.any,
  exclusions: PropTypes.arrayOf(PropTypes.any),
};

export const requiredInput = (props) => (
  <RequiredWrapper value={props.input.value}>
    <Input {...props} />
  </RequiredWrapper>
);

requiredInput.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
};

export const requiredVideoEmbedInput = (props) => (
  <RequiredWrapper value={props.input.value}>
    <VideoEmbedInput {...props} />
  </RequiredWrapper>
);

requiredVideoEmbedInput.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
};

export const errorInput = (props) => {
  const showError = props.meta.visited && props.meta.error;
  return (
    <React.Fragment>
      <ErrorWrapper isValid={!showError && hasValue(props.input.value)}>
        <Input {...props} />
      </ErrorWrapper>
      {showError && <div className="invalid-feedback d-block">{props.meta.error}</div>}
    </React.Fragment>
  );
};

errorInput.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }).isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

export const requiredDatePicker = (props) => (
  <RequiredWrapper value={props.input.value}>
    <DateTimePicker {...props} />
  </RequiredWrapper>
);

requiredDatePicker.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
};

export const requiredTextArea = (props) => (
  <RequiredWrapper value={props.input.value}>
    <TextArea {...props} />
  </RequiredWrapper>
);

requiredTextArea.propTypes = {
  input: PropTypes.shape({ value: PropTypes.any }),
};
