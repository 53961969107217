import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import Detergent from '../models';
import DetergentReadingEditFields from './DetergentReadingEditFields';

export class DetergentReadingEditForm extends Component {
  static propTypes = {
    detergents: PropTypes.arrayOf(PropTypes.instanceOf(Detergent)),
    fields: PropTypes.arrayOf(PropTypes.shape({ detergentId: PropTypes.number.isRequired })),
  };

  getFormProps = () => {
    const { detergents, fields } = this.props;
    const selectedDetergentIds = _.map(fields, 'detergentId');

    return {
      detergents,
      createdFields: fields,
      remainingDetergents: detergents.filter(
        (detergent) => !selectedDetergentIds.includes(detergent.id)
      ), // eslint-disable-line
    };
  };

  render() {
    return (
      <FieldArray name="readings" component={DetergentReadingEditFields} {...this.getFormProps()} />
    );
  }
}

const selector = formValueSelector('detergentReading');
const mapStateToProps = (state) => {
  return {
    fields: selector(state, 'readings'),
  };
};
const enhance = compose(reduxForm({ form: 'detergentReading' }), connect(mapStateToProps));

export default enhance(DetergentReadingEditForm);
