import deepEqual from 'deep-equal';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class DetergentLevels extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return !deepEqual(nextProps, this.props);
  }

  createChartData() {
    const { data } = this.props;
    const labels = data.map((i) => i.label);
    const capacities = data.map((i) => i.capacity);
    const currentValues = data.map((i) => i.currentValue);
    const maxValue = Math.max(...capacities) || 0;

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Gallons Remaining',
          backgroundColor: '#b0312d',
          data: currentValues,
        },
        {
          label: 'Drum Size',
          backgroundColor: '#d8d8d8',
          data: capacities,
        },
      ],
    };
    const chartOptions = {
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
        y: {
          gridLines: { display: false },
          ticks: { max: maxValue, min: 0 },
        },
      },
    };

    return { chartData, chartOptions, maxValue };
  }

  render() {
    const chartData = this.createChartData();
    const { chartData: data, chartOptions: options, maxValue } = chartData;

    const bar = maxValue > 0 && <Bar data={data} options={options} />;
    return <div>{bar}</div>;
  }
}

export default DetergentLevels;
