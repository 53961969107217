import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import VendorFormWrapper from '../components/VendorFormWrapper';

const AddVendorDialog = (props) => {
  const onReady = () => {
    props.submit('vendor');
  };
  const onSubmit = (values) => {
    props.purchaseOrderVendor(values.items);
  };
  const submitDisabled = checkErrors(props.errors);

  return (
    <ModalDialog
      title="Add Vendors"
      onReady={onReady}
      isOpen={props.isOpen}
      close={props.hideDialog}
      disabled={submitDisabled}
    >
      <VendorFormWrapper onSubmit={onSubmit} />
    </ModalDialog>
  );
};

const getErrors = getFormSyncErrors('vendor');

AddVendorDialog.propTypes = {
  purchaseOrderVendor: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hideDialog: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return { errors };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      purchaseOrderVendor: actions.purchaseOrderVendor,
      submit,
      hideDialog,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVendorDialog);
