import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';
import VisualEmbed from './VisualEmbed';
import { selectPowerBiConfig } from '../../analytics/reducer';
import { GET_REPORT } from '../../analytics/actions';

interface ReportConfig {
  embedToken: string;
  embedUrl: string;
  reportId: string;
}

interface Props {
  pageName: string;
  visualId: string;
}

const MemoizedVisualEmbed = React.memo(VisualEmbed);

export const HourlyWashVisual = (props: Props): JSX.Element => {
  const loader = useSelector((state) => getApiStatus(state, GET_REPORT));
  const intl = useIntl();
  const reportConfig: ReportConfig | null = useSelector((state) =>
    selectPowerBiConfig(state, 'Manager-Analytics')
  );

  const getVisualComponent = () => {
    return !loader && reportConfig ? (
      <MemoizedVisualEmbed
        accessToken={reportConfig.embedToken}
        embedUrl={reportConfig.embedUrl}
        reportId={reportConfig.reportId}
        pageName={props.pageName}
        visualId={props.visualId}
      />
    ) : (
      <div className="powerbi-loading">
        <Loader />
      </div>
    );
  };

  return (
    <div>
      <section className="container-fluid overview-chart-card">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'hourlyWashCountsTable' })}</div>
        </div>
        {getVisualComponent()}
      </section>
    </div>
  );
};

export default HourlyWashVisual;
