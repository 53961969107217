import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { showDialog } from '../../../core/actions';
import DetailHeader from '../../../core/components/DetailHeader';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import {
  deleteReclamation,
  deleteReclamationInEdit,
  getReclamation,
} from '../../reclamations/actions';
import Reclamation from '../../reclamations/models';
import { selectReclamationInEdit } from '../../reclamations/reducer';
import RewashDetailContent from '../components/RewashDetailContent';
import RewashDetailInfo from '../components/RewashDetailInfo';

const RewashDetail = (props) => {
  const params = useParams();
  const intl = useIntl();
  const history = useHistory();
  const reclamationInEdit = useSelector(selectReclamationInEdit);

  useEffect(() => {
    const id = params.rewash;
    props.getReclamation(id);
    return () => {
      props.deleteReclamationInEdit();
    };
  }, []);

  const getTitles = (reclamation) => {
    return [
      {
        className: 'detail-id',
        label: intl.formatMessage({ id: 'issue' }),
        content: reclamation.id,
      },
      {
        className: 'detail-date',
        label: intl.formatMessage({ id: 'issueDate' }),
        content: moment(reclamation.issueDate).format('MM-DD-YYYY'),
      },
      {
        className: 'detail-created',
        label: intl.formatMessage({ id: 'issueCreated' }),
        content: moment(reclamation.createdAt).format('MM-DD-YYYY'),
      },
    ];
  };

  const deleteClaim = () => {
    const { id, type } = reclamationInEdit;
    const { site } = params;

    const query = {
      id,
      type,
      callback: () => props.deleteReclamationInEdit(),
      navigate: () => {
        history.push(`/${site}/rewashes`);
      },
    };

    const confirmProps = {
      onReady: props.deleteReclamation(query),
      text: `${intl.formatMessage({
        id: 'confirmReclamationDelete',
      })}: ${id}?`,
      title: intl.formatMessage({ id: 'deleteRewash' }),
    };

    showDialog('CONFIRM_DIALOG', confirmProps);
  };

  const editClaim = () => {
    const path = history.location.pathname.trim();
    history.push(`${path}edit`);
  };

  const reclamation = new Reclamation(reclamationInEdit || {});
  const buttons = [
    {
      onClick: deleteClaim,
      class: 'button primary inverted mr-2',
      label: intl.formatMessage({ id: 'delete' }),
    },
    {
      onClick: editClaim,
      label: intl.formatMessage({ id: 'edit' }),
    },
  ];

  return (
    <div className="container-fluid">
      <section className="page-main-content">
        <DetailButtonGroup history={history} intl={intl} buttons={buttons} />
        <DetailHeader titles={getTitles(reclamation)} />
        <RewashDetailInfo reclamation={reclamation} />
        <RewashDetailContent reclamation={reclamation} />
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getReclamation,
      deleteReclamation,
      deleteReclamationInEdit,
      showDialog,
    },
    dispatch
  );
};

RewashDetail.propTypes = {
  getReclamation: PropTypes.func.isRequired,
  deleteReclamationInEdit: PropTypes.func.isRequired,
  deleteReclamation: PropTypes.func.isRequired,
  reclamationInEdit: PropTypes.shape({
    status: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }),
  showDialog: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(RewashDetail);
