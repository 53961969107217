import { PropTypes } from 'prop-types';
import React from 'react';
import UserGroupPermissions from './UserGroupPermissions';

const Tabs = ({ tabs, onClick, selectedType }) => (
  <div className="tabs-container">
    {tabs.map((t) => (
      <UserGroupPermissions key={t.type} itemRef={t.permissionKey}>
        <button
          className={`btn tab-button${selectedType === t.type ? ' active' : ''}`}
          onClick={() => onClick(t.type)}
        >
          {t.title}
        </button>
      </UserGroupPermissions>
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      permissionKey: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
  selectedType: PropTypes.string.isRequired,
};

export default Tabs;
