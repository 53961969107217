import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { completeTaskCompletion } from '../../overview/actions';
import { Task } from '../../tasks/models';
import {
  selectLocationFilter,
  selectMaintenanceTaskInEdit,
  selectPage,
  selectStatusFilter,
} from '../../tasks/reducer';
import {
  clearMaintenanceTask,
  clearTaskSelections,
  fetchTasks,
  getMaintenanceTask,
} from '../../tasks/actions';

interface ModalProps {
  intl: any;
  children: any;
  title: string;
  isOpen: boolean;
  close: any;
  teamMember: { value: string; label: string };
  completeTask: any;
  className: string;
  showFooter: boolean;
  selectedTasks: { [key: string]: { _id: number; name: string } }[] | Task[];
}

const TaskCompletionModal = ({
  intl,
  children,
  title,
  isOpen,
  close,
  completeTask,
  teamMember,
  className,
  showFooter,
  selectedTasks,
}: ModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const status: number = useSelector(selectStatusFilter);
  const location = useSelector(selectLocationFilter);
  const page = useSelector(selectPage);
  const currentSelectedtask = useSelector(selectMaintenanceTaskInEdit);
  const currentTitle =
    title === 'Snooze'
      ? intl.formatMessage({ id: 'snooze' })
      : intl.formatMessage({ id: 'complete' });
  const taskIds = Object.keys(selectedTasks);
  const completeTaskWithTeamMember = () => {
    dispatch(
      completeTaskCompletion({
        employeeId: teamMember?.value || null,
        taskIds: taskIds.map((taskId) => parseInt(taskId, 10)),
        callback: () => {
          dispatch(clearTaskSelections());
          dispatch(fetchTasks({ type: 'Maintenance', location, status, page }));
          if (currentSelectedtask?.id) {
            dispatch(clearMaintenanceTask());
            dispatch(getMaintenanceTask(currentSelectedtask.id));
          }
        },
      })
    );
  };
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close}>{currentTitle}</ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter className="full-width">
          <Button className="submit-button delete" color="secondary" onClick={close}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
          <Button
            className="submit-button confirm"
            color="primary"
            onClick={completeTaskWithTeamMember}
          >
            {currentTitle}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default TaskCompletionModal;
