/* eslint-disable import/prefer-default-export */
export class Site {
  constructor(props = {}) {
    this.id = props.id;
    this.extId = props.extId;
    this.externalId = props.externalId;
    this.name = props.name;
    this.streetAddress = props.streetAddress;
    this.city = props.city;
    this.postalCode = props.postCode;
    this.country = props.country;
    this.settings = props.settings;
    this.dailyWashCountCapacity = props.dailyWashCountCapacity;
  }
}
/* eslint-enable import/prefer-default-export */
