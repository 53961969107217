import moment from 'moment-timezone';
import Reclamation, { ReclamationStatus } from './models';

const from = sessionStorage.getItem('reclamationsFrom');
const until = sessionStorage.getItem('reclamationsUntil');

const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

export const initialState = {
  reclamations: [],
  error: null,
  reclamationInEdit: null,
  fromDate: from ? fromDate : moment.tz(tz).subtract(90, 'days'),
  untilDate: until ? untilDate : moment.tz(tz).startOf('day'),
  statusFilter: ReclamationStatus.AllOpen,
  typeFilter: 0,
  archivedFilter: false,
  reclamationDamageOptions: [],
};

const getReclamationsSuccess = (state, action) => {
  const reclamations = action.results.map((i) => new Reclamation(i));
  return {
    ...state,
    reclamations,
    error: null,
    page: action.page,
    total: action.total,
    pageSize: action.pageSize,
  };
};

const getReclamationSuccess = (state, reclamation) => {
  return {
    ...state,
    reclamationInEdit: new Reclamation(reclamation),
  };
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('reclamationsFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('reclamationsUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

const setStatusFilter = (state, statusFilter) => {
  return {
    ...state,
    statusFilter,
  };
};

const setTypeFilter = (state, typeFilter) => {
  return {
    ...state,
    typeFilter,
  };
};

const setArchivedFilter = (state, archivedFilter) => {
  return {
    ...state,
    archivedFilter,
  };
};

const deleteReclamationInEdit = (state) => {
  return {
    ...state,
    reclamationInEdit: null,
  };
};

const getClaimDamageOptions = (state, options) => {
  return {
    ...state,
    reclamationDamageOptions: options.results,
    typeFilter: 0,
  };
};

export const reclamations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RECLAMATIONS_SUCCESS':
      return getReclamationsSuccess(state, action.response);
    case 'GET_REWASHES_SUCCESS':
      return getRewashesSuccess(state, action.response);
    case 'GET_RECLAMATION_SUCCESS':
      return getReclamationSuccess(state, action.response);
    case 'SET_RECLAMATION_FROM_DATE':
      return setFromDate(state, action.date);
    case 'SET_RECLAMATION_UNTIL_DATE':
      return setUntilDate(state, action.date);
    case 'SET_STATUS_FILTER':
      return setStatusFilter(state, action.statusFilter);
    case 'SET_TYPE_FILTER':
      return setTypeFilter(state, action.typeFilter);
    case 'SET_ARCHIVED_FILTER':
      return setArchivedFilter(state, action.archivedFilter);
    case 'DELETE_RECLAMATIONINEDIT':
      return deleteReclamationInEdit(state);
    case 'GET_CLAIM_DAMAGE_OPTIONS_SUCCESS':
      return getClaimDamageOptions(state, action.response);
    default:
      return state;
  }
};

export const selectReclamations = (state) => {
  return state.reclamations.reclamations;
};

export const selectReclamationInEdit = (state) => {
  return state.reclamations.reclamationInEdit;
};

export const selectTotal = (state) => {
  return state.reclamations.total;
};

export const selectPage = (state) => {
  return state.reclamations.page;
};

export const selectPageSize = (state) => {
  return state.reclamations.pageSize;
};

export const selectFromDate = (state) => {
  return state.reclamations.fromDate;
};

export const selectUntilDate = (state) => {
  return state.reclamations.untilDate;
};

export const selectStatusFilter = (state) => {
  return state.reclamations.statusFilter;
};

export const selectTypeFilter = (state) => {
  return state.reclamations.typeFilter;
};

export const selectArchivedFilter = (state) => {
  return state.reclamations.archivedFilter;
};

export const selectReclamationDamageOptions = (state) => {
  return state.reclamations.reclamationDamageOptions;
};
