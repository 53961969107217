import startsWith from 'lodash/startsWith';
import moment from 'moment';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import { SortDirection } from '../../../core/components/DataTableHeaders';
import { hasRole, RoleTypes } from '../../../core/components/Permission';
import { getApiStatus, toString } from '../../../core/utils';
import { selectRole } from '../../../store/authReducer';
import { getSiteModelYears, getSiteOperationTypes } from '../actions';
import {
  selectSiteGroupId,
  selectSiteId,
  selectSiteModelYears,
  selectSiteOperationTypes,
} from '../reducer';
import * as actions from '../../tasks/actions';
import { buildQuery } from '../../tasks/helpers';
import { Task, TaskType } from '../../tasks/models';
import {
  selectEssentialPage,
  selectEssentialPageSize,
  selectEssentialTasks,
  selectEssentialTotal,
  selectMaintenanceTaskInEdit,
  selectTaskInEdit,
  selectTaskLocations,
  selectTaskPrefixes,
} from '../../tasks/reducer';
import TaskPrefixFilter from '../../tasks/containers/TaskPrefixFilter';
import TaskTypeFilter from '../../tasks/containers/TaskTypeFilter';
import UserGroupPermissions, {
  addPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import SearchBar from '../../../core/components/SearchBar';
import MaintenanceTaskDetail from '../../maintenanceTasks/components/MaintenanceTaskDetail';
import { selectEmployees } from '../../employees/reducer';
import { fetchEmployees } from '../../employees/actions';
import { DrawerContainer, Drawer } from '../../../core/components/Drawer';

const styles = {
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const EssentialTasks = (): JSX.Element => {
  // subscribes
  const role = useSelector(selectRole),
    essentialTasks = useSelector(selectEssentialTasks),
    taskInDetail = useSelector(selectMaintenanceTaskInEdit),
    total = useSelector(selectEssentialTotal),
    page = useSelector(selectEssentialPage),
    employees = useSelector(selectEmployees),
    pageSize = useSelector(selectEssentialPageSize),
    taskPrefixList = useSelector(selectTaskPrefixes),
    taskLocationList = useSelector(selectTaskLocations),
    siteModelYearList = useSelector(selectSiteModelYears),
    siteOperationTypeList = useSelector(selectSiteOperationTypes),
    groupId = useSelector(selectSiteGroupId),
    siteId = useSelector(selectSiteId),
    tasksFetched = useSelector((state) => getApiStatus(state, 'FETCH_TASKS')),
    savingEssentialTask = useSelector((state) => getApiStatus(state, 'SAVE_ESSENTIAL_TASK')),
    deletingEssentialTask = useSelector((state) => getApiStatus(state, 'DELETE_ESSENTIAL_TASK'));

  // hooks
  const dispatch = useDispatch();
  const intl = useIntl();

  // local state
  const [typeFilter, setTypeFilter] = useState('');
  const [prefixFilter, setPrefixFilter] = useState('');
  const [taskExpanded, setExpanded] = useState(false);
  const [taskMaximized, setMaximized] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(0);
  const [searchResults, setSearchResults] = useState(null);
  const [sortQuery, setSortQuery] = React.useState({});
  const [searchResultsDetails, setSearchResultsDetails] = useState([]);
  const [searchText, setSearchText] = useState('');

  const getTaskQuery = (queryParameters = {}) => {
    const defaultQuery = { page: 0, siteId: null };
    if (siteId) {
      defaultQuery.siteId = siteId;
    }

    return buildQuery(defaultQuery, queryParameters, {
      typeFilter,
      prefixFilter,
      sortQuery,
    });
  };

  const handleFetchData = (values?: any) => {
    if (!tasksFetched) {
      const taskQuery = getTaskQuery(values);
      dispatch(actions.getEssentialTasks(taskQuery));
    }
  };

  const fetchSortedTasks = (sortParameters: any) => {
    setSortQuery(sortParameters);
  };

  useEffect(() => {
    handleFetchData();
    if (!taskPrefixList.length) {
      dispatch(actions.getTaskPrefixes());
    }
    if (!taskLocationList.length) {
      dispatch(actions.getTaskLocations());
    }
    if (!employees.length) {
      dispatch(fetchEmployees());
    }
    if (hasRole(role, RoleTypes.OperatorGroupAdmin)) {
      if (!siteModelYearList.length) {
        dispatch(getSiteModelYears());
      }

      if (!siteOperationTypeList.length) {
        dispatch(getSiteOperationTypes());
      }
    }
  }, []);

  useEffect(() => {
    handleFetchData();
  }, [typeFilter, prefixFilter, groupId, sortQuery, savingEssentialTask]);

  useEffect(() => {
    handleFetchData();
    setExpanded(false);
  }, [deletingEssentialTask]);

  const getPaginationInfo = () => {
    return {
      total: total,
      page,
      pageSize: pageSize,
      goToPage: (value: number) => handleFetchData({ page: value }),
      location,
      status,
    };
  };

  const addTaskWithGroupId = (task: Task) => {
    dispatch(actions.addEssentialTask(task));
  };

  const addDialogProps = {
    title: intl.formatMessage({ id: 'addEssentialTask' }),
    onSubmit: (value: any) => addTaskWithGroupId(value),
    isNew: true,
    isEssentialTask: true,
  };

  const addProject = () => {
    dispatch(
      showDialog('UPSERT_PROJECT', {
        title: 'Add Project',
        onSumit: () => {},
        isNew: true,
      })
    );
  };

  const dropdownOptions = [
    { textId: 'allTasks', callback: () => {} },
    { textId: 'essentialTasks', callback: () => {} },
    { textId: 'todaysTasks', callback: () => {} },
    { textId: 'hourlyInspections', callback: () => {} },
    { textId: 'myProjectList', callback: () => {} },
    { textId: 'addNewProject', callback: addProject, filter: false },
  ];

  const rowClickFunction = (data: any) => {
    setHighlightedRow(data._id);
    dispatch(actions.getMaintenanceTask(data._id));
    setExpanded(true);
  };

  const setSearchMatch = (sResults: any, sResultsDetails: any, sText: string) => {
    setSearchResults(sResults);
    setSearchResultsDetails(sResultsDetails);
    setSearchText(sText);
  };

  const collapseTask = () => {
    setExpanded(false);
  };

  const toggleTaskExpand = () => {
    setMaximized(!taskMaximized);
  };

  const getRowClickInfo = () => ({
    callback: (data: any) => {
      rowClickFunction(data);
    },
  });

  const initializeDatatable = (filteredData: any) => {
    const headers: any = [
      { name: intl.formatMessage({ id: 'name' }), databaseProperty: 'name' },
      { name: intl.formatMessage({ id: 'interval' }), databaseProperty: 'interval' },
      { name: intl.formatMessage({ id: 'startDate' }), databaseProperty: 'start' },
      { name: intl.formatMessage({ id: 'endDate' }), databaseProperty: 'end' },
    ];
    headers.push({
      name: intl.formatMessage({ id: 'resource' }),
      cell: {
        component: (componentProps: any) => {
          const url = startsWith(componentProps.rowData._resource, 'http')
            ? componentProps.rowData._resource
            : `https://${componentProps.rowData._resource}`;
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {componentProps.rowData._resource && (
                <i
                  className="icon icon-link px-4 text-info"
                  title={`${componentProps.rowData._resource}`}
                />
              )}
            </a>
          );
        },
      },
    });

    const data = filteredData.map((task: Task) => ({
      _id: task.id,
      name: task.name,
      intervalDescription: task ? task.getIntervalDescription(intl.formatMessage) : '',
      start: moment(task.start).format('MM/DD/YYYY'),
      end: task.end && moment(task.end).format('MM/DD/YYYY'),
      _resource: task.resource,
    }));

    return {
      headers,
      data,
    };
  };

  const data = searchResults !== null ? searchResults : essentialTasks;

  return (
    <div data-testid="essentialTaskContainer" className="maintenance-tasks-container">
      <div className="task-table-header tasks-buttons-container">
        <div style={styles.tableHeader} className="tasks-buttons-container">
          <div>
            <div data-testid="typeTaskFilters">
              <TaskTypeFilter selectedFilter={typeFilter} setFilter={setTypeFilter} />
            </div>
            {typeFilter === toString(TaskType, TaskType.Project) && (
              <div data-testid="prefixTaskFilters">
                <TaskPrefixFilter selectedFilter={prefixFilter} setFilter={setPrefixFilter} />
              </div>
            )}
          </div>
        </div>
        <UserGroupPermissions itemRef={addPermissionRefs.task}>
          <ButtonWithLoader
            style="button add-task"
            handleClick={() => dispatch(showDialog('UPSERT_TASK', addDialogProps))}
          >
            <>
              <i className="icon icon-plus"></i>
              <span data-testid="addEssentialTaskButton" className="add-button-text">
                {intl.formatMessage({ id: 'addEssentialTask' })}
              </span>
            </>
          </ButtonWithLoader>
        </UserGroupPermissions>
      </div>
      <DrawerContainer>
        <div data-testid="EssentialTasksearchBar">
          <SearchBar
            intl={intl}
            dataToSearchOn={essentialTasks}
            dataToSearchFor={['name']}
            setSearchMatch={setSearchMatch}
            uniqueField="id"
            styles={['data-table-search mb-2']}
          />
          <DataTable
            paginationInfo={getPaginationInfo()}
            rowClickInfo={getRowClickInfo()}
            highlightedRow={highlightedRow}
            scrolling
            fetchSortedData={fetchSortedTasks}
            {...initializeDatatable(data)}
          />
        </div>
        <Drawer expanded={taskExpanded} maximized={taskMaximized} expandedWidth={65}>
          {taskInDetail && (
            <MaintenanceTaskDetail
              intl={intl}
              collapseSelf={collapseTask}
              toggleExpand={toggleTaskExpand}
              task={taskInDetail}
              paginationInfo={getPaginationInfo()}
              savingTask={savingEssentialTask}
              deletingTask={deletingEssentialTask}
              tasksFetched={tasksFetched}
              employees={employees}
            />
          )}
        </Drawer>
      </DrawerContainer>
    </div>
  );
};

export default EssentialTasks;
