import { useState } from 'react';
import { Label } from 'reactstrap';
import '../../../../../styles/permission-summary.scss';
import { IntlType } from '../../../../../utils/typing';
import { UserGroupPermission } from '../../../../employees/models';
import PermissionModal from './PermissionModal';
import PermissionName from './PermissionName';

interface Props {
  intl: IntlType;
  permissions: UserGroupPermission[];
}

const PermissionSummary = ({ intl, permissions }: Props): JSX.Element | null => {
  const [selectedPermission, setSelectedPermission] = useState<number | undefined>();

  const handlePermissionClick = (permission: UserGroupPermission) => {
    if (selectedPermission === permission.id) {
      setSelectedPermission(undefined);
    } else {
      setSelectedPermission(permission.id);
    }
  };

  const isSelectedPermission = (permission: UserGroupPermission): boolean => {
    return selectedPermission === permission.id;
  };

  if (!permissions.length) {
    return null;
  }

  return (
    <div className="permission-summary">
      <Label for="userGroup" className="font-weight-bold">
        {intl.formatMessage({ id: 'permissionSummary' })}
      </Label>
      <div className="permission-summary-item-list">
        {permissions.map((permission) => {
          const key = permission.id + '-' + permission.displayName;

          return (
            <div key={key} className="permission-summary-item">
              <PermissionName permission={permission} handleOnClick={handlePermissionClick} />

              {permission.description && isSelectedPermission(permission) && (
                <PermissionModal
                  intl={intl}
                  permission={permission}
                  toggle={handlePermissionClick}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissionSummary;
