import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import useLogEngine from './useLogEngine';

export default function useMenuHistory(baseLine, parentLabel, childKey, childLabel) {
  const [history, setHistory] = useState([]);
  const [outputLog, setOutputLog] = useState([]);
  const baselineLookUp = useRef({});

  useEffect(() => {
    if (baseLine && baseLine.length) {
      const baselineHistory = baseLine.map((base) => ({
        id: `${base._uniqId}`,
        change: base,
        type: 'base',
        scope: 'parent',
      }));
      setHistory(baselineHistory);
      baselineLookUp.current = _keyBy(baselineHistory, 'id');
    }
  }, [baseLine]);

  const { generateLog, undo } = useLogEngine(
    baselineLookUp,
    parentLabel,
    childKey,
    childLabel,
    history,
    setHistory,
    setOutputLog
  );

  useEffect(generateLog, [generateLog]);

  const _log = (id, logType, scope, draft) => {
    const workDraft = _cloneDeep(draft);
    workDraft.type = logType;
    workDraft.scope = scope;
    workDraft.id = id;

    if (scope === 'parent') {
      const cloneHistory = _cloneDeep(history);
      cloneHistory.push(workDraft);
      setHistory(cloneHistory);
    }

    if (scope === 'child') {
      setHistory((hist) => {
        const cloneHist = [...hist];
        cloneHist.push(workDraft);
        return cloneHist;
      });
    }
  };

  const logAddParent = (draft, id) => {
    _log(id, 'add', 'parent', draft);
  };

  const logRemoveParent = (draft, id) => {
    _log(id, 'remove', 'parent', draft);
  };

  const logAddChild = (draft, id) => {
    _log(id, 'add', 'child', draft);
  };

  const logEditChild = (draft, id) => {
    _log(id, 'edit', 'child', draft);
  };

  const logRemoveChild = (draft, id) => {
    _log(id, 'remove', 'child', draft);
  };

  const clearLog = () => {
    setHistory([]);
  };

  return useMemo(
    () => ({
      logAddParent,
      logRemoveParent,
      logEditChild,
      logAddChild,
      logRemoveChild,
      outputLog,
      clearLog,
      undo,
    }),
    [outputLog, undo]
  );
}
