import moment from 'moment-timezone';
import { Task, TaskCompletion, TaskLocation, TaskPrefix } from './models';

const from = sessionStorage.getItem('historyFrom');
const until = sessionStorage.getItem('historyUntil');

const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

export const initialState = {
  allTasks: {
    tasks: [],
    total: 0,
    page: 0,
    pageSize: 50,
  },
  taskInEdit: null,
  maintenanceTaskInEdit: null,
  taskCompletions: {
    taskCompletions: [],
    total: 0,
    page: 0,
    pageSize: 50,
  },
  notesForTasks: {},
  todaysTaskCompletions: {
    taskCompletions: [],
    total: 0,
    page: 0,
    pageSize: 50,
  },
  fromDate: from ? fromDate : moment.tz(tz).subtract(90, 'days'),
  untilDate: until ? untilDate : moment.tz(tz).endOf('day'),
  essentialTasks: {
    tasks: [],
    total: 0,
    page: 0,
    pageSize: 50,
  },
  selectedTasks: {},
  taskPrefixes: [],
  taskLocations: [],
  completionLogs: {
    taskCompletions: [],
    total: 0,
    page: 0,
    pageSize: 50,
  },
  typeFilter: '',
  prefixFilter: '',
  locationFilter: '',
  employeeFilter: '',
  statusFilter: 'All',
};

const fetchTasksSuccess = (state, action) => {
  const tasks = action.results.map((t) => new Task(t));

  return {
    ...state,
    allTasks: {
      tasks,
      page: action.page,
      total: action.total,
      pageSize: action.pageSize,
    },
  };
};

const getEditTaskSuccess = (state, task) => {
  return {
    ...state,
    taskInEdit: new Task(task),
  };
};

const getMaintenanceTaskSuccess = (state, task) => {
  return {
    ...state,
    maintenanceTaskInEdit: new Task(task),
  };
};

const deleteTaskInEdit = (state) => {
  return {
    ...state,
    taskInEdit: null,
  };
};

const setFromDate = (state, date) => ({
  ...state,
  fromDate: date,
});

const setUntilDate = (state, date) => ({
  ...state,
  untilDate: date,
});

const getTaskCompletionsSuccess = (state, action) => {
  const taskCompletions = action.results.map((t) => new TaskCompletion(t));

  return {
    ...state,
    taskCompletions: {
      taskCompletions,
      page: action.page,
      total: action.total,
      pageSize: action.pageSize,
    },
  };
};

const setCompletionLogs = (state, action) => {
  const logs = action.results.map((t) => new TaskCompletion(t));

  return {
    ...state,
    completionLogs: {
      taskCompletions: logs,
      page: action.page,
      total: action.total,
      pageSize: action.pageSize,
    },
  };
};

const getTodaysTaskCompletionsSuccess = (state, action) => {
  const taskCompletions = action.results.map((t) => new TaskCompletion(t));
  return {
    ...state,
    todaysTaskCompletions: {
      taskCompletions,
      page: action.page,
      total: action.total,
      pageSize: action.pageSize,
    },
  };
};

const getEssentialTaskSuccess = (state, action) => {
  const tasks = action.results.map((t) => new Task(t));
  return {
    ...state,
    essentialTasks: {
      tasks,
      page: action.page,
      total: action.total,
      pageSize: action.pageSize,
    },
  };
};

const clearTaskSelections = (state) => {
  return {
    ...state,
    selectedTasks: {},
  };
};

const setTaskSelections = (state, action) => {
  return {
    ...state,
    selectedTasks: action.rows,
  };
};

const getTaskPrefixesSuccess = (state, action) => {
  const taskPrefixes = action.results.map((t) => new TaskPrefix(t));
  return {
    ...state,
    taskPrefixes,
  };
};

const getTaskLocationsSuccess = (state, action) => {
  const taskLocations = action.results.map((t) => new TaskLocation(t));
  return {
    ...state,
    taskLocations,
  };
};

const setNotesForTask = (state, action) => {
  return {
    ...state,
    notesForTasks: {
      ...state.notesForTasks,
      [action.taskId]: action.notes,
    },
  };
};

const setTypeFilter = (state, action) => {
  return {
    ...state,
    typeFilter: action.value,
  };
};

const setPrefixFilter = (state, action) => {
  return {
    ...state,
    prefixFilter: action.value,
  };
};

const setLocationFilter = (state, action) => {
  return {
    ...state,
    locationFilter: action.value,
  };
};

const setEmployeeFilter = (state, action) => {
  return {
    ...state,
    employeeFilter: action.value,
  };
};

const setStatusFilter = (state, action) => {
  return {
    ...state,
    statusFilter: action.value,
  };
};

export const tasks = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TASKS_SUCCESS':
      return fetchTasksSuccess(state, action.response);
    case 'GET_TASK_SUCCESS':
      return getEditTaskSuccess(state, action.response);
    case 'SAVE_TASK_SUCCESS':
      return getEditTaskSuccess(state, action.response);
    case 'GET_MAINTENANCE_TASK_SUCCESS':
      return getMaintenanceTaskSuccess(state, action.response);
    case 'SET_MAINENANCE_TASK':
      return getMaintenanceTaskSuccess(state, action.response);
    case 'DELETE_TASKINEDIT':
      return deleteTaskInEdit(state);
    case 'SET_TASK_FROM_DATE':
      return setFromDate(state, action.date);
    case 'SET_TASK_UNTIL_DATE':
      return setUntilDate(state, action.date);
    case 'GET_ALL_TASK_COMPLETIONS_SUCCESS':
      return getTaskCompletionsSuccess(state, action.response);
    case 'GET_COMPLETION_LOGS_SUCCESS':
      return setCompletionLogs(state, action.response);
    case 'GET_TODAYS_TASK_COMPLETIONS_SUCCESS':
      return getTodaysTaskCompletionsSuccess(state, action.response);
    case 'GET_ESSENTIAL_TASKS_SUCCESS':
      return getEssentialTaskSuccess(state, action.response);
    case 'CLEAR_TASK_SELECTIONS':
      return clearTaskSelections(state);
    case 'SET_TASK_SELECTIONS':
      return setTaskSelections(state, action);
    case 'FETCH_TASK_PREFIXES_SUCCESS':
      return getTaskPrefixesSuccess(state, action.response);
    case 'FETCH_TASK_LOCATIONS_SUCCESS':
      return getTaskLocationsSuccess(state, action.response);
    case 'SET_TASK_NOTES':
      return setNotesForTask(state, action);
    case 'SET_TYPE_FILTER':
      return setTypeFilter(state, action);
    case 'SET_PREFIX_FILTER':
      return setPrefixFilter(state, action);
    case 'SET_LOCATION_FILTER':
      return setLocationFilter(state, action);
    case 'SET_EMPLOYEE_FILTER':
      return setEmployeeFilter(state, action);
    case 'SET_STATUS_FILTER':
      return setStatusFilter(state, action);
    case 'CLEAR_MAINTENANCE_TASK':
      return {
        ...state,
        maintenanceTaskInEdit: null,
      };
    default:
      return state;
  }
};

export const selectTasks = (state) => {
  return state.tasks.allTasks.tasks;
};

export const selectTaskInEdit = (state) => {
  return state.tasks.taskInEdit;
};

export const selectMaintenanceTaskInEdit = (state) => {
  return state.tasks.maintenanceTaskInEdit;
};

export const selectTotal = (state) => {
  return state.tasks.allTasks.total;
};

export const selectPage = (state) => {
  return state.tasks.allTasks.page;
};

export const selectPageSize = (state) => {
  return state.tasks.allTasks.pageSize;
};

export const selectLogsTotal = (state) => {
  return state.tasks.completionLogs.total;
};

export const selectLogsPage = (state) => {
  return state.tasks.completionLogs.page;
};

export const selectLogsPageSize = (state) => {
  return state.tasks.completionLogs.pageSize;
};

export const selectFromDate = (state) => {
  return state.tasks.fromDate;
};

export const selectUntilDate = (state) => {
  return state.tasks.untilDate;
};

export const selectTaskCompletions = (state) => {
  return state.tasks.taskCompletions.taskCompletions;
};

export const selectCompletionLogs = (state) => {
  return state.tasks.completionLogs.taskCompletions;
};

export const selectTaskCompletionsTotal = (state) => {
  return state.tasks.taskCompletions.total;
};

export const selectTaskCompletionsPage = (state) => {
  return state.tasks.taskCompletions.page;
};

export const selectTaskCompletionsPageSize = (state) => {
  return state.tasks.taskCompletions.pageSize;
};

export const selectTodaysTaskCompletions = (state) => {
  return state.tasks.todaysTaskCompletions.taskCompletions;
};

export const selectTodaysTaskCompletionsTotal = (state) => {
  return state.tasks.todaysTaskCompletions.total;
};

export const selectTodaysTaskCompletionsPage = (state) => {
  return state.tasks.todaysTaskCompletions.page;
};

export const selectTodaysTaskCompletionsPageSize = (state) => {
  return state.tasks.todaysTaskCompletions.pageSize;
};

export const selectEssentialTasks = (state) => {
  return state.tasks.essentialTasks.tasks;
};

export const selectEssentialTotal = (state) => {
  return state.tasks.essentialTasks.total;
};

export const selectEssentialPage = (state) => {
  return state.tasks.essentialTasks.page;
};

export const selectEssentialPageSize = (state) => {
  return state.tasks.essentialTasks.pageSize;
};

export const selectSelectedTasks = (state) => {
  return state.tasks.selectedTasks;
};

export const selectTaskPrefixes = (state) => {
  return state.tasks.taskPrefixes;
};

export const selectTaskLocations = (state) => {
  return state.tasks.taskLocations;
};

export const selectTypeFilter = (state) => {
  return state.tasks.typeFilter;
};

export const selectPrefixFilter = (state) => {
  return state.tasks.prefixFilter;
};

export const selectLocationFilter = (state) => {
  return state.tasks.locationFilter;
};

export const selectEmployeeFilter = (state) => {
  return state.tasks.employeeFilter;
};

export const selectStatusFilter = (state) => {
  return state.tasks.statusFilter;
};
