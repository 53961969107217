import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDamageClaimOptions } from '../actions';
import { selectReclamationDamageOptions } from '../reducer';

export default function useDamageClaimOptions(makeGet = true) {
  const reclamationDamageOptions = useSelector(selectReclamationDamageOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (makeGet) {
      dispatch(getDamageClaimOptions());
    }
  }, [makeGet]);

  return reclamationDamageOptions;
}
