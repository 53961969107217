// TODO: rename to session?

const JWT_TOKEN_KEY = 'token';
const REFRESH_TOKEN = 'refreshToken';
const SELECTED_SITE = 'site';
const SELECTED_MULTI_SITE = 'multiSites';

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const getJwtToken = () => {
  return getItem(JWT_TOKEN_KEY);
};

export const setJwtToken = (value) => {
  setItem(JWT_TOKEN_KEY, value);
};

export const removeJwtToken = () => {
  removeItem(JWT_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (value) => {
  setItem(REFRESH_TOKEN, value);
};

export const removeRefreshToken = () => {
  removeItem(REFRESH_TOKEN);
};

export const getSelectedSite = () => {
  return getItem(SELECTED_SITE);
};

export const setSelectedSite = (value) => {
  setItem(SELECTED_SITE, value);
};

export const setMultiSites = (sites) => {
  setItem(SELECTED_MULTI_SITE, JSON.stringify(sites));
};

export const getMultiSites = () => {
  return getItem(SELECTED_MULTI_SITE);
};

export const removeMultiSites = () => {
  localStorage.removeItem(SELECTED_MULTI_SITE);
};
