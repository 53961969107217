import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  fieldRequired,
  Input,
  requiredInput,
  requiredSelect,
  Select,
  TextArea,
} from '../../../core/formValidation';
import { formatAmount } from '../../../utils/money-util';
import { RepairItemType } from '../models';

class RepairDataForm extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    total: PropTypes.number,
    currency: PropTypes.string,
    damageClaimOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
          })
        ),
      })
    ),
  };

  getSelect =
    (array) =>
    ({ input }) =>
      (
        // onFocus has to be overridden due to dropdowns not working properly with it
        <select {...input} onFocus={null} className="form-control">
          <option value={0}>All</option>
          {array &&
            array.map((e) => (
              <option key={e.id} value={e.id}>
                {e.value}
              </option>
            ))}
        </select>
      );

  render() {
    const safeParseNumber = (value) =>
      Number(value) === 0 || Number.isNaN(value) ? '' : Number(value);
    const safeParseInt = (value) =>
      Number(value) === 0 || Number.isNaN(value) ? '' : Number(Number(value));
    const formatMessage = this.props.intl.formatMessage;
    return (
      <div>
        <div className="form-group">
          <label htmlFor="affectedArea">{formatMessage({ id: 'carPart' })}</label>
          <Field
            name="carPart"
            id="carPart"
            parse={Number}
            component={this.getSelect(this.props.damageClaimOptions)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">{formatMessage({ id: 'type' })}</label>
          <Field
            id="type"
            name="type"
            className="form-control"
            component={Select}
            parse={Number}
            validate={[fieldRequired]}
          >
            <option value={RepairItemType.Item}>{formatMessage({ id: 'item' })}</option>
            <option value={RepairItemType.Labor}>{formatMessage({ id: 'labor' })}</option>
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="name">{formatMessage({ id: 'name' })}</label>
          <Field
            className="form-control"
            name="name"
            id="name"
            component={Input}
            type="text"
            aria-describedby="nameHelp"
            placeholder="Name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">{formatMessage({ id: 'description' })}</label>
          <Field
            className="form-control"
            name="description"
            id="description"
            maxLength="255"
            data-testid="description-repair-form"
            component={TextArea}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Description"
          />
        </div>
        <div className="form-group">
          <label htmlFor="vendor">{formatMessage({ id: 'vendor' })}</label>
          <Field
            className="form-control"
            name="vendor"
            id="vendor"
            component={Input}
            type="text"
            aria-describedby="vendorHelp"
            placeholder="Vendor"
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">{formatMessage({ id: 'price' })}</label>
          <Field
            className="form-control"
            name="price"
            id="price"
            component={requiredInput}
            validate={[fieldRequired]}
            type="number"
            aria-describedby="priceHelp"
            placeholder="Price"
            parse={safeParseNumber}
          />
        </div>
        <div className="form-group">
          <label htmlFor="quantity">{formatMessage({ id: 'quantity' })}</label>
          <Field
            className="form-control"
            name="quantity"
            id="quantity"
            component={requiredInput}
            validate={[fieldRequired]}
            type="number"
            aria-describedby="quantityHelp"
            placeholder="Quantity"
            parse={safeParseInt}
          />
        </div>
        <div className="form-group">
          <label htmlFor="currency">{formatMessage({ id: 'currency' })}</label>
          <Field
            name="currency"
            id="currency"
            component={requiredSelect}
            className="form-control"
            validate={[fieldRequired]}
          >
            <option value="usd">USD</option>
            <option value="eur">EUR</option>
          </Field>
        </div>
        <div className="form-group">
          <label>
            {formatMessage({ id: 'total' })}:{' '}
            {formatAmount(this.props.total, this.props.currency, true)}
          </label>
        </div>
      </div>
    );
  }
}

const formSelector = formValueSelector('repairDataForm');

const mapStateToProps = (state) => ({
  currency: formSelector(state, 'currency'),
  total: formSelector(state, 'price') * formSelector(state, 'quantity') || 0,
});

const enhance = compose(
  connect(mapStateToProps, null),
  reduxForm({ form: 'repairDataForm' }),
  injectIntl
);

export default enhance(RepairDataForm);
