import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

class HourlyRepeatOptions extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    hours: PropTypes.arrayOf(PropTypes.number),
  };

  constructor(props) {
    super(props);

    const hours24Array = Array.from(Array(24).keys());

    this.state = {
      hours: hours24Array.map((h) => ({
        time: moment(h, 'HH').format('hh:mm a'),
        active: props.hours.includes(h),
      })),
    };
  }

  getActives = () => this.state.hours.filter((h) => h.active);

  change = () => {
    const actives = this.getActives();
    const activesIn24Format = actives.map((active) =>
      Number(moment(active.time, ['h:mm a']).format('HH'))
    );
    this.props.input.onChange(activesIn24Format);
  };

  toggleHour = (i) => () => {
    this.setState(
      (prevState) => ({
        hours: prevState.hours.map((hour, index) => {
          return i !== index ? hour : { ...hour, active: !hour.active };
        }),
      }),
      this.change
    );
  };

  hours = (slots) => () => {
    return (
      <div className="hours-container">
        {slots.map((hour, i) => (
          // eslint-disable-next-line
          <div
            key={i}
            className={`hours-item${hour.active ? ' active' : ''}`}
            onClick={this.toggleHour(i)}
          >
            {hour.time}
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="form-group hours-group">
        <label className="form-check-inline hours">
          <Field
            name="hourlyOptions"
            id="hourlyOptions"
            className="form-check-input"
            component={this.hours(this.state.hours)}
          />
        </label>
      </div>
    );
  }
}

export default HourlyRepeatOptions;
