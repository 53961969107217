import Button from './Button';
import { DrawerControls } from './Drawer';
import UserGroupPermissions from './UserGroupPermissions';

interface Props {
  buttons: any;
  history: any;
  intl: any;
  isPage?: boolean;
  drawerControls?: any;
}

export const DetailButtonGroup = ({
  buttons,
  history,
  intl,
  drawerControls,
  isPage = true,
}: Props) => {
  return (
    <>
      <div className="flex space-between">
        <div className="left-buttons">
          {isPage && (
            <button type="button" onClick={history.goBack} className="button">
              {intl.formatMessage({ id: 'back' })}
            </button>
          )}
          {drawerControls && <DrawerControls closeFunction={drawerControls.closeFunction} />}
        </div>
        <div className="align-right">
          {buttons.map((button: any) => (
            <UserGroupPermissions key={button.label} itemRef={button.permissionKey}>
              <Button
                onClick={button.onClick}
                className={button.class || 'button primary'}
                key={button.label}
              >
                {button.label}
              </Button>
            </UserGroupPermissions>
          ))}
        </div>
      </div>
      <hr />
    </>
  );
};
