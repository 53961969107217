import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getTaskLocations } from '../actions';
import TaskFilters from '../components/TaskFilters';
import { selectTaskLocations } from '../reducer';

const TaskLocationFilter = (props) => {
  const { intl, selectedFilter, taskLocationList, fetchTaskLocations, setFilter } = props;

  useEffect(() => {
    if (!taskLocationList.length) {
      fetchTaskLocations();
    }
  }, [fetchTaskLocations, taskLocationList]);

  return (
    <TaskFilters
      selectedFilter={selectedFilter}
      options={taskLocationList}
      label={intl.formatMessage({ id: 'taskLocation' })}
      setFilter={setFilter}
    />
  );
};

TaskLocationFilter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  selectedFilter: PropTypes.string,
  taskLocationList: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      message: PropTypes.string,
    })
  ),
  fetchTaskLocations: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
};

TaskLocationFilter.defaultProps = {
  taskLocationList: [],
};

const mapStateToProps = (state) => {
  return {
    taskLocationList: selectTaskLocations(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchTaskLocations: getTaskLocations,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(memo(TaskLocationFilter, isEqual));
