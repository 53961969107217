import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';

const styles = {
  tab: {
    color: '#292b2c',
  },
};

export class ContentTabs extends Component {
  static propTypes = {
    components: PropTypes.shape({}),
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    showNavLinks: PropTypes.bool,
    intl: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);

    if (!props.showNavLinks) {
      const defaultComponent = props.components ? Object.keys(props.components)[0] : '';

      this.state = {
        showComponent: defaultComponent,
      };
    }
  }

  getComponent = () => this.props.components[this.state.showComponent];

  getTabs() {
    const componentNames = this.props.components && Object.keys(this.props.components);
    return componentNames.map((link) => (
      <li className="nav-item" key={link}>
        <button
          className={`nav-link${(this.state.showComponent === link && ' active') || ''}`}
          style={styles.tab}
          onClick={() => this.showComponent(link)}
        >
          {link}
        </button>
      </li>
    ));
  }

  getNavLinks = () => {
    return this.props.locations.map((location, i) => (
      <li className="nav-item" key={i}>
        <NavLink
          to={location.pathname}
          activeClassName="active"
          className="nav-link"
          key="1"
          isActive={this.checkActive(location.pathname)}
        >
          {this.props.intl.formatMessage({ id: location.label })}
        </NavLink>
      </li>
    ));
  };

  checkActive = (targetLocation) => (match, location) => {
    return location.pathname === targetLocation;
  };

  showComponent = (component) => {
    this.setState({ showComponent: component });
  };

  render() {
    return (
      <div data-testid="contentTabs" className="content-container">
        <ul className="nav nav-tabs restyled">
          {this.props.showNavLinks ? this.getNavLinks() : this.getTabs()}
        </ul>
        {!this.props.showNavLinks && <div className="content">{this.getComponent()}</div>}
      </div>
    );
  }
}

const enhance = compose(injectIntl);

export default enhance(ContentTabs);
