import { RoleTypes } from '../../core/components/Permission';

export interface UserGroupPermission {
  id: number;
  displayName: string;
  ref: string;
  description?: string;
}

interface CoreSite {
  id: number;
  name: string;
}

export interface UserGroup {
  id: number;
  name: string;
  description?: string;
  role: keyof typeof RoleTypes;
  operatorGroupId: number;
  parentGroupId: number;
  permissions: UserGroupPermission[];

  operatorGroup?: any;
  parent?: UserGroup;
  children?: UserGroup[];
}

interface User {
  id: number;
  archivedAt: string | undefined;
  username: string;
  firstName: string;
  role: keyof typeof RoleTypes;
  lastName: string;
  phoneNumber: string;
  customerId: number;
  operatorId: number;
  operatorGroupId: number;
  userGroupId: number;
  locale: string;
  userGroup?: UserGroup;
  sites?: CoreSite[];
}

export default class Employee {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  position: string;
  siteId: number;
  archiver?: string;
  archivedAt?: string;
  coreUserId?: number;
  coreUser?: User;
  isCashier: boolean;

  constructor(props: any = {}) {
    this.id = props.id;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.email = props.email;
    this.phoneNumber = props.phoneNumber;
    this.position = props.position;
    this.siteId = props.siteId;
    this.coreUserId = props.coreUserId;
    this.archiver = props.archiver;
    this.archivedAt = props.archivedAt;
    this.isCashier = props.isCashier;

    if (props.coreUser) {
      this.coreUser = props.coreUser;
    }
  }
}
