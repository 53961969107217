import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

const TaskFilters = ({ intl, selectedFilter, options, label, setFilter }) => {
  const handleOnChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className="form-group">
      <label className="bold">{label}</label>
      <select
        data-testid="selectTaskFilter"
        className="form-control"
        value={selectedFilter}
        onChange={handleOnChange}
      >
        <option value="All">{intl.formatMessage({ id: 'all' })}</option>
        {options.map((taskType, i) => (
          <option data-testid={`${taskType.type}`} key={`${taskType}-${i}`} value={taskType.type}>
            {taskType.message}
          </option>
        ))}
      </select>
    </div>
  );
};

TaskFilters.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  selectedFilter: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
    })
  ),
  label: PropTypes.string,
  setFilter: PropTypes.func,
};

export default injectIntl(TaskFilters);
