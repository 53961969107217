import { isEqual } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import ContentTabs from '../../../core/components/ContentTabs';
import DetailHeader from '../../../core/components/DetailHeader';
import { EmbeddedUrlCarousel } from '../../../core/components/EmbeddedUrlCarousel';
import { hasRoleInOperatorGroup, RoleTypes } from '../../../core/components/Permission';
import ReadOnlyEditor from '../../../core/components/ReadOnlyEditor';
import { selectSession } from '../../../store/authReducer';
import { getSiteModelYears, getSiteOperationTypes } from '../../settings/actions';
import { selectSiteModelYears, selectSiteOperationTypes } from '../../settings/reducer';
import History from '../../taskDetail/components/History';
import Parts from '../../taskDetail/components/Parts';
import TaskDetailNotes from '../../taskDetail/components/TaskDetailNotes';
import {
  deleteEssentialTask,
  deleteTask,
  deleteTaskInEdit,
  getTask,
  getTaskLocations,
  getTaskPrefixes,
  saveEssentialTask,
  saveTask,
  fetchTasks,
} from '../../tasks/actions';
import { createIntervalDescription } from '../../tasks/helpers';
import {
  selectTaskCompletions,
  selectTaskInEdit,
  selectTaskLocations,
  selectTaskPrefixes,
} from '../../tasks/reducer';
import UserGroupPermissions, {
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { getApiStatus } from '../../../core/utils';
import Loader from '../../../core/components/Loader';
import { DrawerControls } from '../../../core/components/Drawer';

export class TaskDetail extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    getTask: PropTypes.func,
    saveTask: PropTypes.func,
    saveEssentialTask: PropTypes.func,
    deleteTask: PropTypes.func,
    deleteEssentialTask: PropTypes.func,
    deleteTaskInEdit: PropTypes.func,
    showDialog: PropTypes.func,
    getTaskPrefixes: PropTypes.func,
    getTaskLocations: PropTypes.func,
    taskInEdit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      interval: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      isEssentialTask: PropTypes.bool,
      description: PropTypes.string,
      modelYears: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          year: PropTypes.string,
        })
      ),
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    session: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string,
        task: PropTypes.string,
      }),
    }),
    taskPrefixList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
      })
    ),
    taskLocationList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
        orderNumber: PropTypes.number,
      })
    ),
    siteOperationTypeList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    siteModelYearList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
      })
    ),
    getSiteModelYears: PropTypes.func,
    getSiteOperationTypes: PropTypes.func,
  };

  componentDidMount() {
    const id = this.props._taskId;
    this.props.getTask(id);
    if (!this.props.taskPrefixList.length) {
      this.props.getTaskPrefixes();
    }
    if (!this.props.taskLocationList.length) {
      this.props.getTaskLocations();
    }
    if (hasRoleInOperatorGroup(this.props.session, RoleTypes.OperatorGroupAdmin)) {
      if (!this.props.siteModelYearList.length) {
        this.props.getSiteModelYears();
      }

      if (!this.props.siteOperationTypeList.length) {
        this.props.getSiteOperationTypes();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const taskToEditChanged = !isEqual(this.props?._id, prevProps?._id);
    if (taskToEditChanged) {
      const id = this.props._taskId;
      this.props.getTask(id);
    }
    if (!isEqual(this.props.taskInEdit, prevProps.taskInEdit)) {
      this.props.getTask(this.props.taskInEdit.id);
    }
    if (this.props.isDeletionProcess && !prevProps.isDeletionProcess) {
      this.props.deleteTaskInEdit();
      this.props.removeSelectedTask();
    }
  }

  editTask = () => {
    const { id, isEssentialTask } = this.props.taskInEdit;
    const formatMessage = this.props.intl.formatMessage;

    const title = isEssentialTask
      ? formatMessage({ id: 'editEssentialTask' })
      : formatMessage({ id: 'editTask' });

    const onSubmit = (task) => {
      const saveProps = { task };

      if (isEssentialTask) {
        this.props.saveEssentialTask(saveProps);
      } else {
        this.props.saveTask(saveProps);
      }
    };

    this.props.showDialog('UPSERT_TASK', {
      title,
      onSubmit,
      isEssentialTask,
      isNew: false,
      modelYears: this.props.taskInEdit.modelYears,
    });
  };

  deleteTask = () => {
    const { id, name, isEssentialTask } = this.props.taskInEdit;
    const deletionParams = {
      id,
      callback: () => this.props.fetchTasks(),
    };

    const confirmProps = {
      title: this.props.intl.formatMessage({ id: 'archiveTask' }),
      text: this.props.intl.formatMessage({ id: 'taskArchiveConfirmation' }, { name }),
      onReady: isEssentialTask
        ? () => this.props.deleteEssentialTask(deletionParams)
        : () => this.props.deleteTask(deletionParams),
    };

    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  createActionButtons = () => {
    if (
      this.props?.taskInEdit?.groupId &&
      !hasRoleInOperatorGroup(this.props.session, RoleTypes.OperatorGroupAdmin)
    ) {
      return <div></div>;
    }

    return (
      <span>
        <button
          className="button primary inverted mr-2 ml-2"
          onClick={() => {
            this.deleteTask();
          }}
        >
          {this.props.intl.formatMessage({ id: 'delete' })}
        </button>
        <button
          className="button primary"
          onClick={() => {
            this.editTask();
          }}
        >
          {this.props.intl.formatMessage({ id: 'edit' })}
        </button>
      </span>
    );
  };

  getTitles = (task) => {
    const prefix = this.props.taskPrefixList.find(
      (taskPrefix) => taskPrefix.type === task.prefixId
    );
    const location = this.props.taskLocationList.find(
      (taskLocation) => taskLocation.type === task.locationId
    );

    return [
      {
        className: 'detail-name',
        label: this.props.intl.formatMessage({ id: 'name' }),
        content: task.name,
      },
      {
        className: 'detail-id',
        label: this.props.intl.formatMessage({ id: 'id' }),
        content: task.id,
      },
      {
        className: 'detail-interval',
        label: this.props.intl.formatMessage({ id: 'interval' }),
        content: createIntervalDescription(task, this.props.intl.formatMessage),
      },
      {
        label: this.props.intl.formatMessage({ id: 'start' }),
        content: moment(task.start).format('MM/DD/YYYY'),
      },
      {
        label: this.props.intl.formatMessage({ id: 'end' }),
        content: task.end
          ? moment(task.end).format('MM/DD/YYYY')
          : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
      {
        label: this.props.intl.formatMessage({ id: 'taskPrefix' }),
        content: prefix ? prefix.message : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
      {
        label: this.props.intl.formatMessage({ id: 'taskLocation' }),
        content: location
          ? location.message
          : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
    ];
  };

  render() {
    const task = this.props.taskInEdit || {};
    const intl = this.props.intl;
    const components = task.isEssentialTask
      ? {
          Instructions: (
            <ReadOnlyEditor text={task.description === undefined ? '' : task.description} />
          ),
          Videos: <EmbeddedUrlCarousel urls={task.videos} isVideo={true} />,
          History: <History task={task} />,
          Notes: <TaskDetailNotes task={task} />,
        }
      : {
          Instructions: (
            <ReadOnlyEditor text={task.description === undefined ? '' : task.description} />
          ),
          Videos: <EmbeddedUrlCarousel urls={task.videos} isVideo={true} />,
          Parts: <Parts task={task} />,
          History: <History task={task} />,
          Notes: <TaskDetailNotes task={task} />,
        };
    return this.props.isEditingProcess ? (
      <Loader />
    ) : (
      <div
        data-testid="todays-task-in-detail"
        className="todays-task-detail container-fluid no-padding"
      >
        <section className="page-details-content no-padding">
          <div className="flex space-between">
            <div>
              {/*{window.innerWidth > 990 && (
                <button
                  type="button"
                  onClick={() => this.props.setTaskInEditExpanded()}
                  className="button mr-2"
                >
                  {!this.props.taskInEditExpanded ? (
                    <i class="uil uil-angle-left"></i>
                  ) : (
                    <i class="uil uil-angle-right"></i>
                  )}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.props.removeSelectedTask()}
                className="button"
              >
                {intl.formatMessage({ id: 'back' })}
                <i class="uil uil-times"></i>
              </button>*/}
              <DrawerControls
                closeFunction={() => this.props.removeSelectedTask()}
                maximized={this.props.taskInEditExpanded}
                maximizeFunction={() => this.props.setTaskInEditExpanded()}
              />
            </div>
            <UserGroupPermissions itemRef={editPermissionRefs.task}>
              <div className="align-right">{this.createActionButtons()}</div>
            </UserGroupPermissions>
          </div>
          <hr />
          {task.isEssentialTask && (
            <span className="badge badge-secondary mb-2">
              {intl.formatMessage({ id: 'essentialTask' })}
            </span>
          )}
          <DetailHeader titles={this.getTitles(task)} buttonClass={'mt-2'} />
          <ContentTabs components={components} />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taskInEdit: selectTaskInEdit(state),
  taskCompletions: selectTaskCompletions(state),
  session: selectSession(state),
  taskPrefixList: selectTaskPrefixes(state),
  taskLocationList: selectTaskLocations(state),
  siteModelYearList: selectSiteModelYears(state),
  siteOperationTypeList: selectSiteOperationTypes(state),
  isDeletionProcess: getApiStatus(state, 'DELETE_TASK'),
  isEditingProcess: getApiStatus(state, 'SAVE_TASK'),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchTasks,
      getTask,
      deleteTask,
      deleteEssentialTask,
      deleteTaskInEdit,
      saveTask,
      saveEssentialTask,
      showDialog,
      getTaskPrefixes,
      getTaskLocations,
      getSiteModelYears,
      getSiteOperationTypes,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(TaskDetail);
