import { orderBy } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoteList from '../../../core/components/NoteList';
import { addReclamationNote, archiveReclamationNote } from '../../reclamations/actions';

export class Notes extends Component {
  static propTypes = {
    reclamation: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    addReclamationNote: PropTypes.func.isRequired,
    archiveReclamationNote: PropTypes.func.isRequired,
  };

  addReclamationNote = (values) => {
    const data = {
      ...values,
      reclamationId: this.props.reclamation.id,
    };
    this.props.addReclamationNote(data);
  };

  archiveReclamationNote = (note) => {
    const data = {
      noteId: note.id,
      reclamationId: this.props.reclamation.id,
    };
    this.props.archiveReclamationNote(data);
  };

  render() {
    const notes = this.props.reclamation
      ? orderBy(this.props.reclamation.notes, 'createdAt', 'desc')
      : [];

    return (
      <NoteList
        notes={notes}
        onAddNote={this.addReclamationNote}
        onArchiveNote={this.archiveReclamationNote}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addReclamationNote,
      archiveReclamationNote,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Notes);
