import { invert as _invert, isArray as _isArray } from 'lodash';

const initialState = {
  units: {
    Water: {
      Gallons: 0,
    },
    Electric: {
      kWh: 0,
    },
    Gas: {
      Gallons: 0,
      Therms: 1,
    },
    Sewer: {
      Gallons: 0,
    },
  },
};

const fetchUtilityUnitsSuccess = (state, response) => {
  const transformedUnits = { ...initialState.units };
  const units = response.results;
  if (units && _isArray(units)) {
    const existingUtilityTypes = Object.keys(initialState.units);
    units.forEach((entry) => {
      if (existingUtilityTypes.indexOf(entry.utilityType) >= 0) {
        transformedUnits[entry.utilityType] = _invert({ ...entry.units });
      }
    });
  }

  return {
    ...state,
    units: transformedUnits,
  };
};

export const utilityUnits = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_UTILITY_UNITS_SUCCESS':
      return fetchUtilityUnitsSuccess(state, action.response);
    default:
      return state;
  }
};

export const selectUtilityUnits = (state) => {
  return state.utilityUnits.units;
};
