import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { TextArea } from '../../../core/formValidation';

const DeclineIssueForm = () => {
  const intl = useIntl();
  return (
    <form className="form-group">
      <Field
        className="form-control"
        name="note"
        id="note"
        component={TextArea}
        type="text"
        placeholder={intl.formatMessage({ id: 'declineNote' })}
      />
    </form>
  );
};

export default reduxForm({ form: 'declineIssueForm' })(DeclineIssueForm);
