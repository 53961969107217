import { groupBy, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Card,
  CardBody,
  CardText,
  Container,
  Button,
  UncontrolledCollapse,
} from 'reactstrap';
import { showDialog } from '../../../core/actions';
import { unLinkTask, getMaintenanceTask } from '../../tasks/actions';
import { selectRole } from '../../../store/authReducer';
import { handleTaskStatusBadge } from '../helpers';
import { RoleTypes, hasRole } from '../../../core/components/Permission';

export interface Link {
  archivedAt: string;
  archivedBy: string;
  createdAt: string;
  creator: string;
  dependency: string;
  id: number;
  linkedTaskId: number;
  modifiedAt: string;
  modifier: string;
  name: string;
  taskId: number;
  status: string;
}

const TaskLink = ({ intl, task }: any): JSX.Element => {
  const myRole = useSelector(selectRole);
  const dispatch = useDispatch();
  const handleUnLinkTask = (linkedTask: any) => {
    dispatch(showDialog('UNLINK_TASK', { unLinkTask: preformUnLinkTask, linkedTask, intl, task }));
  };

  const preformUnLinkTask = (linkedTask: any) => {
    dispatch(
      unLinkTask({
        id: linkedTask.id,
        callback: () => {
          dispatch(getMaintenanceTask(task.id));
        },
      })
    );
  };

  if (!task?.linkedTasks?.length) {
    return <div>{intl.formatMessage({ id: 'noLinkedTasks' })}</div>;
  }

  const dependecyState: { [key: string]: string } = {
    BlockedBy: intl.formatMessage({ id: 'blockedBy' }),
    DoneBefore: intl.formatMessage({ id: 'doneBefore' }),
    DoneAfter: intl.formatMessage({ id: 'doneAfter' }),
    RelatesTo: intl.formatMessage({ id: 'relatesTo' }),
    SubTask: intl.formatMessage({ id: 'subTask' }),
  };

  const groupedLinkedTasks: { [key: string]: { linkedTaskId: number; dependency: string }[] } =
    groupBy(task.linkedTasks, 'dependency');

  return (
    <div>
      {map(groupedLinkedTasks, (links: any, key: string) => {
        return (
          <Container key={key} className="linked-tasks">
            <Alert className="mb-0" color="light">
              {dependecyState[key]}
            </Alert>
            {links.map((link: Link) => {
              return (
                <Card
                  key={`taskId${link.linkedTaskId}`}
                  id={`taskId${link.linkedTaskId}`}
                  className="mb-3"
                >
                  <CardBody className="task-link-card-body">
                    <CardText>Task Id: {link.linkedTaskId}</CardText>
                    <div>{link.name}</div>
                    {handleTaskStatusBadge(link?.status, intl)}
                  </CardBody>
                  {hasRole(myRole, RoleTypes.OperatorGroupAdmin) && (
                    <UncontrolledCollapse toggler={`taskId${link.linkedTaskId}`}>
                      <CardBody>
                        <Button
                          className="btn btn-primary red mr-1"
                          onClick={() => handleUnLinkTask(link)}
                        >
                          {intl.formatMessage({ id: 'removeLink' })}
                        </Button>
                      </CardBody>
                    </UncontrolledCollapse>
                  )}
                </Card>
              );
            })}
          </Container>
        );
      })}
    </div>
  );
};

export default TaskLink;
