import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteExtId, selectSiteId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import {
  fetchCoreCounts,
  fetchCorePayAsYouGoCounts,
  fetchInstaClubCounts,
} from '../../wash-counts/actions';
import { selectUniqueWashes } from '../../wash-counts/reducer';
import { selectTotalClubsGained } from '../reducer';
import InstaClubKpi from './InstaClubKpi';

interface Props {
  fromDate: any;
  untilDate: any;
}

const InstaClubDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const plansCount = useSelector(selectTotalClubsGained),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    managerSiteId = useSelector(selectSiteId),
    unqiueWashCounts = useSelector(selectUniqueWashes),
    coreSiteId = useSelector(selectSiteExtId);

  const handleWindowSales = () => {
    if (coreSiteId) {
      dispatch(
        fetchInstaClubCounts(
          props.fromDate.format('YYYY-MM-DD'),
          props.untilDate.format('YYYY-MM-DD'),
          null,
          coreSiteId
        )
      );
      dispatch(
        fetchCoreCounts(
          props.fromDate.format('YYYY-MM-DD'),
          props.untilDate.format('YYYY-MM-DD'),
          null,
          coreSiteId
        )
      );
      dispatch(
        fetchCorePayAsYouGoCounts(
          props.fromDate.format('YYYY-MM-DD'),
          props.untilDate.format('YYYY-MM-DD'),
          null,
          coreSiteId
        )
      );
    }
  };
  useEffect(handleWindowSales, [
    managerSiteId,
    coreSiteId,
    props.fromDate,
    props.untilDate,
    multiSiteSelection,
  ]);

  return (
    <InstaClubKpi
      plansCount={plansCount}
      popSingleWash={unqiueWashCounts}
      fromDate={props.fromDate}
      untilDate={props.untilDate}
    />
  );
};

export default InstaClubDataGetter;
