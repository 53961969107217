import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // Simulate a loading delay
    const interval = setInterval(() => {
      setPercentage((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 100); // 3 seconds divided into 10 steps

    // Clean up the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${percentage}%` }}>
        {percentage}%
      </div>
    </div>
  );
};

export default ProgressBar;
