import deepEqual from 'deep-equal';
import React, { Component } from 'react';

const BaseChart = (ComposedComponent) =>
  class extends Component {
    shouldComponentUpdate(nextProps) {
      return !deepEqual(nextProps, this.props);
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };

export default BaseChart;
