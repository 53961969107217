/* eslint-disable */
import { sumBy } from 'lodash';
import values from 'lodash/values';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loader from '../../../core/components/Loader';
import WashCounts from '../../../core/components/WashCounts';
import * as actions from '../actions';

const styles = {
  chartContainer: {
    padding: '0px 12%',
  },
  label: {
    fontSize: '18px',
  },
  totalWashes: {
    fontSize: '36px',
    lineHeight: 1,
  },
};

export const WashCountChart = ({ washCounts, intl, showLoader }) => {
  const chartConfig = {
    chartData: washCounts,
    chartOptions: {
      maintainAspectRatio: false,
      cutoutPercentage: 60,
      legend: {
        display: false,
      },
    },
    chartProps: {
      height: 250,
    },
  };

  return (
    <div style={styles.container} className="wash-chart shadowed">
      {showLoader ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div style={styles.chartContainer}>
            <WashCounts title={intl.formatMessage({ id: 'washCounts' })} {...chartConfig} />
          </div>
          <div>
            <div style={styles.label}>Total Washes</div>
            <div style={styles.totalWashes}>{sumBy(values(washCounts), ({ count }) => count)}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

WashCountChart.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  washCounts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showLoader: PropTypes.bool,
};

const enhance = compose(connect(null, actions), injectIntl);

export default enhance(WashCountChart);
