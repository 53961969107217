import { PropTypes } from 'prop-types';
import React from 'react';

export const CategoryFilter = (props) => {
  const handleChange = ({ target: { value } }) => {
    props.setFilter({
      ...props.currentFilter,
      currentCategory: value,
    });
  };

  return (
    <div className="form-group">
      <label>Category</label>
      <select
        className="form-control"
        value={props.currentFilter.currentCategory}
        onChange={handleChange}
      >
        <option value="All">All</option>
        <option disabled>------</option>
        {props.categories.map((c) => {
          return (
            <option key={c.id} value={c.name}>
              {c.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

CategoryFilter.defaultProps = {
  currentFilter: {
    currentCategory: 'All',
  },
  categories: [],
};

CategoryFilter.propTypes = {
  currentFilter: PropTypes.shape({
    currentCategory: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CategoryFilter;
