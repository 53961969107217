import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Col, Form, Label, Row } from 'reactstrap';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { requiredInput as RequiredInput } from '../../../core/formValidation';
import '../../../styles/login-form.scss';
import useLoginForm from '../hooks/useLoginForm';
import LoginHeader from './LandingPageHeader';
import LandingPageFooter from './LandingPageFooter';
import LoginSlideshow from './LoginSlideshow';

const LoginForm = (): JSX.Element => {
  const { formik, handleSubmitClick, statusMessage, removeStatusMessage } = useLoginForm();
  const formDisabled = !formik.isValid || formik.isSubmitting;
  const history = useHistory();
  const intl = useIntl();

  const getTextInput = (fieldName: string, placeholderId: string, type: string) => {
    return {
      id: fieldName,
      type,
      className: 'login-form-input',
      placeholder: intl.formatMessage({ id: placeholderId }),
      ...formik.getFieldProps(fieldName),
    };
  };
  const handleForgotPasswordButton = () => {
    removeStatusMessage();
    history.push('/forgot-password');
  };
  return (
    <div className="login-container">
      <LoginSlideshow />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="login-form"
        data-testid="login-form"
      >
        <LoginHeader />
        <Row>
          <Col className="login-input-remove-padding">
            <Label for="username" className="login-input-label">
              {intl.formatMessage({ id: 'emailAddress' })}
            </Label>
            <RequiredInput input={getTextInput('username', 'emailAddress', 'text')} />
          </Col>
        </Row>
        <Row>
          <Col className="login-input-remove-padding">
            <Label for="password" className="login-input-label">
              {intl.formatMessage({ id: 'password' })}
            </Label>
            <RequiredInput input={getTextInput('password', 'password', 'password')} />
          </Col>
        </Row>
        <Row>
          <Col className="login-input-remove-padding">
            <ButtonWithLoader
              handleClick={handleSubmitClick}
              disabled={formDisabled}
              isLoading={formik.isSubmitting}
            >
              <div className="login-button-text">{intl.formatMessage({ id: 'login' })}</div>
            </ButtonWithLoader>
            {statusMessage && statusMessage.type === 'failure' && (
              <div data-testid="error-alert" className="login-error alert alert-danger">
                {statusMessage.message}
              </div>
            )}
            <div className="forgot-password-back" onClick={handleForgotPasswordButton}>
              {intl.formatMessage({ id: 'forgotPassword' })}{' '}
            </div>
            <LandingPageFooter />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoginForm;
