const initialState = {
  selectedForm: 'pl_upload',
  uploadAuthor: 0,
};

const setFormSelection = (state, form) => {
  return {
    ...state,
    selectedForm: form,
  };
};

const setUploadAuthor = (state, employeeId) => {
  return {
    ...state,
    uploadAuthor: employeeId,
  };
};

export const uploadForms = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_UPLOADS_FORM_SELECTION':
      return setFormSelection(state, action.form);
    case 'SET_UPLOADS_AUTHOR':
      return setUploadAuthor(state, action.employeeId);
    default:
      return state;
  }
};

export const selectForm = (state) => {
  return state.uploadForms.selectedForm;
};

export const selectAuthor = (state) => {
  return state.uploadForms.uploadAuthor;
};
