import omit from 'lodash/omit';
import { API_REQUEST } from '../../core/actions';
import * as FileApi from '../../core/api/files';
import * as API from '../../core/api/reclamations';

export const ADD_RECLAMATION = 'ADD_RECLAMATION';
export const SAVE_RECLAMATION = 'SAVE_RECLAMATION';
export const DELETE_RECLAMATION = 'DELETE_RECLAMATION';
export const ARCHIVE_RECLAMATION = 'ARCHIVE_RECLAMATION';
export const UNARCHIVE_RECLAMATION = 'UNARCHIVE_RECLAMATION';
export const GET_RECLAMATION = 'GET_RECLAMATION';
export const DELETE_RECLAMATIONINEDIT = 'DELETE_RECLAMATIONINEDIT';
export const GET_RECLAMATIONS = 'GET_RECLAMATIONS';
export const ADD_RECLAMATION_READING = 'ADD_RECLAMATION_READING';
export const SET_RECLAMATION_FROM_DATE = 'SET_RECLAMATION_FROM_DATE';
export const SET_RECLAMATION_UNTIL_DATE = 'SET_RECLAMATION_UNTIL_DATE';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SET_ARCHIVED_FILTER = 'SET_ARCHIVED_FILTER';
export const ADD_RECLAMATION_NOTE = 'ADD_RECLAMATION_NOTE';
export const ARCHIVE_RECLAMATION_NOTE = 'ARCHIVE_RECLAMATION_NOTE';
export const ADD_FILE = 'ADD_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const GET_CLAIM_DAMAGE_OPTIONS = 'GET_CLAIM_DAMAGE_OPTIONS';
export const UPDATE_CLAIM_DAMAGE_OPTIONS = 'UPDATE_CLAIM_DAMAGE_OPTIONS';

// TODO: Split into separate actions
export const getReclamations = (query) => {
  if (query.types === 'Claim') {
    const claimQuery = omit(query, 'types');
    return {
      type: API_REQUEST,
      callAPI: API.getClaims,
      action: GET_RECLAMATIONS,
      data: { query: claimQuery },
      meta: { useManagerSiteId: true },
    };
  }
  return {
    type: API_REQUEST,
    callAPI: API.getReclamations,
    action: GET_RECLAMATIONS,
    data: { query },
  };
};

export const getReclamation = (id) => ({
  type: API_REQUEST,
  callAPI: API.getReclamation,
  action: GET_RECLAMATION,
  data: { id },
});

export const saveReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.patchReclamation,
  action: SAVE_RECLAMATION,
  data: data.reclamation,
  navigate: data.navigate,
});

export const patchReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.patchReclamation,
  action: SAVE_RECLAMATION,
  data: data.reclamation,
  closeModal: true,
  callback: () => getReclamation(data.reclamation.id),
});

export const addReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.postReclamation,
  data: data.reclamation,
  action: ADD_RECLAMATION,
  closeModal: true,
  navigate: data.navigate,
});

export const archiveReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.archiveReclamation,
  action: ARCHIVE_RECLAMATION,
  data: { id: data.id },
  closeModal: true,
  navigate: data.navigate,
  callback: data.callback,
});

export const unarchiveReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.unarchiveReclamation,
  action: UNARCHIVE_RECLAMATION,
  data: { id: data.id },
  closeModal: true,
  navigate: data.navigate,
  callback: data.callback,
});

export const deleteReclamation = (data) => ({
  type: API_REQUEST,
  callAPI: API.deleteReclamation,
  action: DELETE_RECLAMATION,
  data: { id: data.id },
  closeModal: true,
  navigate: data.navigate,
  callback: data.callback,
});

export const addReclamationNote = (data) => ({
  type: API_REQUEST,
  callAPI: API.postReclamationNote,
  action: ADD_RECLAMATION_NOTE,
  data,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});

export const archiveReclamationNote = (data) => ({
  type: API_REQUEST,
  callAPI: API.archiveReclamationNote,
  data,
  action: ARCHIVE_RECLAMATION_NOTE,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});

export const deleteReclamationInEdit = () => ({
  type: DELETE_RECLAMATIONINEDIT,
});

export const addFile = (data) => ({
  type: API_REQUEST,
  callAPI: FileApi.postReclamationFile,
  data,
  action: ADD_FILE,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});

export const deleteFile = (data) => ({
  type: API_REQUEST,
  callAPI: FileApi.deleteReclamationFile,
  data,
  action: DELETE_FILE,
  closeModal: true,
  callback: () => getReclamation(data.reclamationId),
});

export const getDamageClaimOptions = () => ({
  type: API_REQUEST,
  callAPI: API.getClaimDamageOptions,
  action: GET_CLAIM_DAMAGE_OPTIONS,
});

export const updateDamageClaimOptions = (data) => ({
  type: API_REQUEST,
  callAPI: API.postClaimDamageOptions,
  action: UPDATE_CLAIM_DAMAGE_OPTIONS,
  data,
});

export const setFromDate = (date) => ({ type: SET_RECLAMATION_FROM_DATE, date });
export const setUntilDate = (date) => ({ type: SET_RECLAMATION_UNTIL_DATE, date });
export const setStatusFilter = (statusFilter) => ({ type: SET_STATUS_FILTER, statusFilter });
export const setTypeFilter = (typeFilter) => ({ type: SET_TYPE_FILTER, typeFilter });
export const setArchivedFilter = (archivedFilter) => ({
  type: SET_ARCHIVED_FILTER,
  archivedFilter,
});
