import { map } from 'lodash';
import { Card, CardBody, CardText, Container } from 'reactstrap';
import { TaskList } from '../containers/MaintenanceTasks';

const CompleteTaskList = ({
  intl,
  selectedTasks,
}: {
  intl: any;
  selectedTasks: TaskList[];
}): JSX.Element => {
  return (
    <div>
      {map(selectedTasks, (task: TaskList, key: string) => {
        return (
          <Container key={key}>
            <Card key={`taskId${task.id}`} id={`taskId${task.id}`} className="mb-3">
              <CardBody className="task-link-card-body">
                <CardText>Task Id: {task._id}</CardText>
                <CardText>{task.name}</CardText>
              </CardBody>
            </Card>
          </Container>
        );
      })}
    </div>
  );
};

export default CompleteTaskList;
