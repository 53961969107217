import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { fieldRequired, requiredInput, Select } from '../../../core/formValidation';
import WashSelection from './WashSelection';

const DetergentEditForm = ({ products, selectedProducts, intl, inventory }) => {
  const washSelectionWithProps = (props) => (
    <WashSelection input={props.input} products={products} selectedProducts={selectedProducts} />
  );
  washSelectionWithProps.propTypes = {
    input: PropTypes.shape({}).isRequired,
  };

  return (
    <div>
      <div className="form-group">
        <Field
          className="form-control"
          name="name"
          id="name"
          component={requiredInput}
          type="text"
          aria-describedby="descriptionHelp"
          placeholder="Enter name for detergent"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <Field
          className="form-control"
          name="capacity"
          id="capacity"
          component={requiredInput}
          type="number"
          aria-describedby="descriptionHelp"
          placeholder="Capacity of the container"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <Field
          className="form-control"
          name="alarmLevel"
          id="alarmLevel"
          component="input"
          type="number"
          aria-describedby="descriptionHelp"
          placeholder="Alarm level"
        />
      </div>
      <div className="form-group">
        <Field
          className="form-control"
          name="reorderLevel"
          id="reorderLevel"
          component="input"
          type="number"
          aria-describedby="descriptionHelp"
          placeholder="Reorder Level"
        />
      </div>
      <div className="form-group">
        <label>Washes using this detergent</label>
        <Field name="detergentWashes" component={washSelectionWithProps} />
      </div>
      <div className="form-group">
        <label htmlFor="inventoryItem">{intl.formatMessage({ id: 'relatedInventoryItem' })}</label>
        <Field
          name="inventoryItem"
          id="inventoryItem"
          component={Select}
          className="form-control"
          parse={Number}
          onBlur={(e) => {
            e.preventDefault();
          }}
        >
          <option value={-1} disabled>
            {intl.formatMessage({ id: 'selectInventoryItem' })}
          </option>
          {inventory &&
            inventory.map((inv, i) => (
              <option key={i} value={inv.id}>
                {inv.name}
              </option>
            ))}
        </Field>
      </div>
    </div>
  );
};

DetergentEditForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  selectedProducts: PropTypes.arrayOf(PropTypes.number),
};

// TODO: add validator
const enhance = compose(injectIntl, reduxForm({ form: 'detergent' }));

export default enhance(DetergentEditForm);
