import classNames from 'classnames';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import {
  fieldRequired,
  requiredDatePicker,
  requiredInput,
  requiredSelect,
} from '../../../core/formValidation';
import Detergent from '../models';

export const DetergentReadingEditFields = ({
  detergents,
  remainingDetergents,
  createdFields,
  fields,
}) => {
  // eslint-disable-line
  const createDetergentList = (fieldIndex) => {
    const selectedDetergentForCurrentField = detergents.find(
      (d) => createdFields[fieldIndex] && d.id === createdFields[fieldIndex].detergentId
    );
    const options = [];

    if (selectedDetergentForCurrentField) {
      const { id, name } = selectedDetergentForCurrentField;

      options.push(
        <option id="selected" key={`field-${fieldIndex}-detergent-${id}-selected`} value={id}>
          {name}
        </option>
      );
    }

    const remainingOptions = remainingDetergents.map((d) => (
      <option id="remaining" key={`field-${fieldIndex}-detergent-${d.id}`} value={d.id}>
        {d.name}
      </option>
    ));

    options.push(...remainingOptions);

    return options;
  };

  return (
    <React.Fragment>
      {fields.map((reading, index) => (
        <div key={index}>
          <div className="readings-form">
            <button
              className={classNames('close', 'multi-form-delete')}
              onClick={() => {
                fields.remove(index);
              }}
            >
              <i className="icon icon-squared-cross" />
            </button>
            <div className="mt-3">{`Reading #${index + 1}`}</div>
            <div className="form-group">
              <Field
                id="detergentId"
                name={`${reading}.detergentId`}
                className="form-control"
                component={requiredSelect}
                validate={[fieldRequired]}
                parse={Number}
              >
                <option value="">Choose Detergent...</option>
                {createDetergentList(index)}
              </Field>
            </div>
            <div className="form-group">
              <Field
                className="form-control"
                name={`${reading}.value`}
                id="value"
                component={requiredInput}
                type="number"
                aria-describedby="descriptionHelp"
                placeholder="Gallons Left"
                validate={[fieldRequired]}
              />
            </div>
            <div className="form-group">
              <Field
                name={`${reading}.timestamp`}
                component={requiredDatePicker}
                placeholderText={`${moment(Date.now()).format('MM/DD/YY h:mm a')}`}
                showToday
                validate={[fieldRequired]}
                timeProps={{
                  showTimeSelect: true,
                  timeFormat: 'hh:mm aa',
                  timeIntervals: 15,
                  dateFormat: 'MM/dd/YYY h:mm aa',
                  timeCaption: 'Time',
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <button
        className={classNames('button', 'full-width', 'add-reading')}
        onClick={() => {
          fields.push({ detergentId: 0 });
        }}
      >
        Add Reading
      </button>
    </React.Fragment>
  );
};

DetergentReadingEditFields.propTypes = {
  detergents: PropTypes.arrayOf(PropTypes.instanceOf(Detergent)),
  remainingDetergents: PropTypes.arrayOf(PropTypes.instanceOf(Detergent)),
  fields: PropTypes.shape({}), // FieldArrayFieldProps
  createdFields: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DetergentReadingEditFields;
