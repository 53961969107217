import React from 'react';
import Select from 'react-select';

type Option = {
  value: string;
  label: string;
};

interface Props {
  options: Option[];
  multi: boolean;
  placeholder: string;
  onChange: (value: any) => void;
  defaultValue?: Option | Option[];
  value?: any;
  meta?: any;
  styles?: any;
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles: any = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

/**
 * Custom select input component for react-select library with multi select option and custom styles support
 *  @param {Option[]} options - options for the select input
 *  @param {boolean} multi - multi select option
 *  @param {string} placeholder - placeholder for the select input
 *  @param {(value: any) => void} onChange - onChange event handler for the select input
 *  @param {Option | Option[]} defaultValue - default value for the select input
 * @param {Props} props
 * @return {*}  {JSX.Element}
 */

const CustomSelectInput = (props: Props): JSX.Element => {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '300px',
    }),
    menu: ({ width, ...css }: any) => ({ ...css, width: '300px', zIndex: 9999 }),
  };

  if (props?.styles) {
    customStyles.container = (provided: any) => ({
      ...provided,
      ...props.styles.container,
    });
    customStyles.menu = ({ width, ...css }: any) => ({
      ...css,
      ...props.styles.menu,
    });
  }

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const multiValueContainer: any = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = Array.isArray(selectProps?.value)
      ? selectProps?.value
      : [selectProps?.value];
    const index = allSelected.findIndex((selected: any) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <div>
      <Select
        isMulti={props.multi}
        defaultValue={props?.defaultValue}
        value={props?.value}
        components={{ MultiValueContainer: multiValueContainer }}
        options={props.options}
        formatGroupLabel={formatGroupLabel}
        closeMenuOnSelect={false}
        onChange={(value: any) => props.onChange(value)}
        hideSelectedOptions={false}
        styles={customStyles}
        isSearchable={true}
        placeholder={props.placeholder}
        onBlur={(e: any) => e.preventDefault()}
        blurInputOnSelect={true}
      />
    </div>
  );
};

export default CustomSelectInput;
