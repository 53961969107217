import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';
import { addTaskNote } from '../../tasks/actions';
import { Task } from '../../tasks/models';

interface Props {
  task: Task;
}

const TaskDetailNotes = (props: Props): JSX.Element => {
  const [noteInEdit, setNoteInEdit] = useState<boolean | null>(false);
  const dispatch = useDispatch();
  const noteQueryLoading = useSelector((state) => getApiStatus(state, 'GET_TASK'));
  const intl = useIntl();

  const handleNoteInTask = () => {
    return (
      <div style={{ display: 'flex' }}>
        <textarea
          style={{ border: 'none' }}
          name="note"
          placeholder="Add note..."
          id="notes"
          cols={window.innerWidth / 30}
          rows={2}
        ></textarea>
      </div>
    );
  };

  const handleNotesToList = () => {
    const notes = document.getElementById('notes') as HTMLInputElement;
    if (!notes.value) return;
    const body = {
      id: props.task.id,
      text: notes.value,
    };
    dispatch(addTaskNote(body));
    setNoteInEdit(!noteInEdit);
    notes.value = '';
  };

  return (
    <section>
      {noteInEdit ? (
        <div>
          <button onClick={() => handleNotesToList()} className="button confirm mr-1">
            {intl.formatMessage({ id: 'submit' })}
          </button>
          <button className="button" onClick={() => setNoteInEdit(!noteInEdit)}>
            {intl.formatMessage({ id: 'cancel' })}
          </button>
        </div>
      ) : (
        <button
          disabled={!!noteInEdit}
          onClick={() => setNoteInEdit(!noteInEdit)}
          className="button"
        >
          {intl.formatMessage({ id: 'addNote' })}
        </button>
      )}
      {noteInEdit && (
        <div className="container-fluid overview-chart-card mt-3">{handleNoteInTask()}</div>
      )}
      {props.task?.notes.length && !noteQueryLoading ? (
        <div className="task-notes-section mt-2">
          {props.task?.notes
            .reverse()
            .map((note: { id: number; createdAt: string; text: string }) => (
              <div key={note.id} className="container-fluid overview-chart-card task-note">
                <span className="task-note-posted">
                  {intl.formatMessage({ id: 'posted' })}:{' '}
                  {moment(note.createdAt).format('DD/MM/YYYY')}
                </span>
                {note.text}
              </div>
            ))}
        </div>
      ) : noteQueryLoading ? (
        <Loader />
      ) : (
        <span />
      )}
    </section>
  );
};

export default TaskDetailNotes;
