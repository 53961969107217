import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useAppContext } from '../../../providers/AppProvider';
import DateTimePicker from '../DateTimePicker';
import useDateOperations from './useDateOperations';

const DateRangeMenuInputs = (props) => {
  const { onChange, currentOperation, isLoading, dates, disabled } = props;
  const tz = useAppContext().timeZone;
  const dateOperations = useDateOperations(dates);
  const dualInputs = currentOperation.calendars === 2;

  const handleChange = (calDate, index) => {
    if (calDate) {
      const newDates = [...dates];

      if (newDates.length < index + 1) {
        newDates.push(null);
      }

      newDates[index] = calDate.format('YYYY-MM-DD');
      onChange(currentOperation.id, newDates);
    }
  };

  const handleSelectChange = (e) => {
    const newDates = [...dates];
    const newDateOperationId = e.target.value;
    const { calendars, id, until } = dateOperations[newDateOperationId];

    if (calendars === 1 && newDates.length === 2) {
      newDates.pop();
    }

    if (calendars === 2 && newDates.length === 1) {
      newDates.push(moment.tz(tz).format('YYYY-MM-DD'));
    }

    if (id === 'IsOnAndAfter') {
      newDates.push(moment(until).format('YYYY-MM-DD'));
    }
    onChange(newDateOperationId, newDates);
  };

  return (
    <React.Fragment>
      <span className="drs-date-operation-dropdown">
        <select
          className="drs-form-control"
          value={currentOperation.id}
          onChange={(e) => handleSelectChange(e)}
          disabled={disabled || isLoading}
        >
          {map(dateOperations, (operation, i) => (
            <option key={i} value={i}>
              {operation.name}
            </option>
          ))}
        </select>
      </span>
      <hr className="drs-divider" />
      <div className="drs-date-input-container">
        <div>
          <div className="float-left">
            <DateTimePicker
              className="drs-calendar-inputs"
              disabled={disabled}
              input={{
                value: dates[0] || moment.tz(tz).format('YYYY-MM-DD'),
                onChange: (calDate) => handleChange(calDate, 0),
              }}
            />
          </div>
        </div>
        {dualInputs && <span className="drs-calendar-and">and</span>}
        {dualInputs && (
          <div>
            <div className="second-datetime-picker">
              <DateTimePicker
                className="drs-calendar-inputs"
                disabled={disabled}
                input={{
                  value: dates[1] || moment.tz(tz).format('YYYY-MM-DD'),
                  onChange: (calDate) => handleChange(calDate, 1),
                }}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

DateRangeMenuInputs.propTypes = {
  onChange: PropTypes.func,
  currentOperation: PropTypes.shape({
    id: PropTypes.string,
    calendars: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  dates: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

export default memo(DateRangeMenuInputs, isEqual);
