import { Big } from 'big.js';
import { add, isNaN, map, round, sum } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field, FieldArray } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import {
  checkCurrencyFormatting,
  fieldRequired,
  Input,
  requiredInput,
  requiredSelect,
} from '../../../core/formValidation';
import { formatNumber } from '../../../utils/number-util';
import PurchaseOrderItemFields from './PurchaseOrderItemFields';

const PurchaseOrderForm = (props) => {
  const totals = round(add(props.additionalCost, sum(props.itemCosts)), 2);
  const employeeListOptions = (employeeList) => {
    const options = employeeList.map((e) => ({
      value: e.id,
      text: e.value || `${e.lastName}, ${e.firstName}`,
    }));
    options.unshift({ value: null, text: '' });
    return options;
  };

  return (
    <div>
      <div className="form-group form-small-row">
        <span className="mx-1 text-left">
          <small htmlFor="PO" className="form-label">
            PO
          </small>
          <Field
            className="form-control"
            name="orderNumber"
            disabled
            id="orderNumber"
            component={Input}
            type="text"
            aria-describedby="descriptionHelp"
            placeholder="Generated on creation"
          />
        </span>
        <span className="mx-1 text-left">
          <small htmlFor="Order Date" className="form-label">
            Order Date
          </small>
          <Field
            className="form-control"
            name="orderDate"
            id="orderDate"
            showToday
            component={DateTimePicker}
            type="text"
            aria-describedby="descriptionHelp"
            required
            parse={(value) => value.format('YYYY-MM-DD')}
            placeholderText={`${moment(Date.now()).format('MM/DD/YY')}`}
          />
        </span>
      </div>
      <div className="form-group">
        <label htmlFor="categoryId">Category</label>
        <Field
          className="form-control"
          name="categoryId"
          id="categoryId"
          component={requiredSelect}
          aria-describedby="descriptionHelp"
          validate={[fieldRequired]}
        >
          <option value={null} text="" />
          {map(props.categories, (category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="vendorId">Vendor</label>
        <Field
          className="form-control"
          name="vendorId"
          id="vendorId"
          value="Select a Vendor"
          component={requiredSelect}
          aria-describedby="descriptionHelp"
          validate={[fieldRequired]}
        >
          <option value={null} text="" />
          {map(props.vendors, (vendor) => (
            <option key={vendor.id} value={vendor.id}>
              {vendor.name}
            </option>
          ))}
        </Field>
      </div>
      <div className="form-group" style={{ minHeight: '40px', background: '#19181803' }}>
        <label htmlFor="items" className="pl-3 mb-4">
          Items
        </label>
        <FieldArray name="items" component={PurchaseOrderItemFields} validate={[fieldRequired]} />
      </div>
      <div className="form-group form-small-row">
        <div className="mx-2">
          <small htmlFor="taxAmount" className="form-text text-muted text-left">
            Tax Amount
          </small>
          <Field
            className="form-control"
            name="taxAmount"
            id="taxAmount"
            component={requiredInput}
            type="text"
            commas="true"
            decimal="2"
            placeholder="$0.00"
            aria-describedby="taxAmount"
            validate={[checkCurrencyFormatting]}
          />
        </div>
        <div className="mx-2">
          <small htmlFor="shippingAmount" className="form-text text-muted text-left">
            Shipping Cost
          </small>
          <Field
            className="form-control"
            name="shippingAmount"
            id="shippingAmount"
            component={requiredInput}
            type="text"
            decimal="2"
            commas="true"
            aria-describedby="shippingCost"
            placeholder="$0.00"
            validate={[checkCurrencyFormatting]}
          />
        </div>
        <div className="mx-2">
          <small htmlFor="discount" className="form-text text-muted text-left">
            Discount
          </small>
          <Field
            className="form-control"
            name="discount"
            id="discount"
            component={requiredInput}
            type="text"
            decimal="2"
            commas="true"
            aria-describedby="discount"
            placeholder="$0.00"
            validate={[checkCurrencyFormatting]}
          />
        </div>
      </div>
      <hr />
      <div className="form-group form-purchase-order-totals">
        {/* eslint-disable */}
        <div>SubTotal: $ {formatNumber(Big(sum(props.itemCosts)).toFixed(2))}</div>
        <div>
          Total: ${' '}
          {isNaN(totals)
            ? '0.00'
            : formatNumber(Big(props.calculateWithDiscount(totals, props.discount)).toFixed(2))}
        </div>
      </div>
      <hr />
      <div className="form-group">
        <label htmlFor="trackingNumber">Tracking Number</label>
        <Field
          className="form-control"
          name="trackingNumber"
          id="trackingNumber"
          component={Input}
          type="text"
          aria-describedby="descriptionHelp"
          placeholder="Tracking Number"
        />
      </div>
      <div className="form-group">
        <label htmlFor="memo">Memo</label>
        <Field
          className="form-control"
          name="memo"
          id="memo"
          component={Input}
          type="text"
          aria-describedby="descriptionHelp"
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label htmlFor="employeeId">Team Member</label>
        <Field
          className="form-control"
          name="employeeId"
          id="employeeId"
          component={requiredSelect}
          validate={[fieldRequired]}
          options={employeeListOptions(props.employees)}
        />
      </div>
    </div>
  );
};

PurchaseOrderForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  vendors: PropTypes.arrayOf(PropTypes.shape({})),
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  itemCosts: PropTypes.arrayOf(PropTypes.number),
  calculateWithDiscount: PropTypes.func,
  discount: PropTypes.string,
  additionalCost: PropTypes.number,
};

export default PurchaseOrderForm;
