import { map } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Permission, { RoleTypes } from '../../../core/components/Permission';
import { Button, Collapse } from 'reactstrap';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import {
  TextArea,
  fieldRequired,
  Input,
  requiredDatePicker,
  requiredInput,
  requiredSelect,
  ControlledRichText,
  missingDays,
} from '../../../core/formValidation';
import { createIntervalDescription } from '../helpers';
import { Interval, TaskType } from '../models';
import IntervalCount from './IntervalCount';
import HourlyRepeatOptions from './HourlyRepeatOptions';
import WeeklyRepeatOptions from './WeeklyRepeatOptions';
import SiteModelYearFields from './SiteModelYearFields';
import TaskInventoryEdit from './TaskInventoryEdit';
import { TaskVideoEmbed } from './TaskVideoEmbed';
import { LinkedTaskField } from './LinkedTaskField';

class TaskEditForm extends Component {
  state = {
    instructionsToggle: false,
    resourceToggle: false,
    linkedTaskToggle: false,
    siteDetailsToggle: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.type !== 1) {
      prevProps.change('prefixId', null);
    }
  }

  handleToggle = (toggle) => {
    this.setState({ [toggle]: !this.state[toggle] });
  };

  render() {
    const {
      interval,
      isEssentialTask,
      type,
      taskPrefixes,
      taskLocations,
      siteOperationTypes,
      siteModelYears,
      employees,
      intl,
    } = this.props;
    const intervalDescription = this.props.intervalDescription;
    const startDateText = interval === Interval.Once ? 'Deadline' : 'Start';
    const typeOptions = [
      { value: TaskType.Maintenance, text: 'Maintenance' },
      { value: TaskType.Project, text: 'Project' },
      { value: TaskType.OpenChecklist, text: 'Open Checklist' },
      { value: TaskType.ClosingChecklist, text: 'Closing Checklist' },
      {
        value: TaskType.PreventativeMaintenance,
        text: 'Preventative Maintenance',
      },
      { value: TaskType.CleaningList, text: 'Cleaning List' },
      { value: TaskType.HourlyInspection, text: 'Hourly Inspection' },
      { value: TaskType.ExpressInterior, text: 'Express Interior' },
    ];

    const intervalOptions = [
      { value: Interval.CarCount, text: 'Car Count' },
      { value: Interval.Once, text: 'Once' },
      { value: Interval.Daily, text: 'Daily' },
      { value: Interval.Weekly, text: 'Weekly' },
      { value: Interval.Monthly, text: 'Monthly' },
      { value: Interval.Yearly, text: 'Yearly' },
      { value: Interval.Hourly, text: 'Hourly' },
    ];

    let prefixesDisabled = !(type === TaskType.Project);
    const prefixOptions = taskPrefixes.map((prefix) => ({
      text: prefix.message,
      value: prefix.type,
    }));
    prefixOptions.unshift({ text: '', value: null });

    const locationOptions = taskLocations.map((location) => ({
      text: location.message,
      value: location.type,
    }));
    locationOptions.unshift({ text: '', value: null });

    const EmployeeOptions = employees.map((employee) => ({
      text: `${employee.lastName}, ${employee.firstName}`,
      value: employee.id,
    }));
    EmployeeOptions.unshift({ text: '', value: null });

    const operationTypeOptions = siteOperationTypes.map((operation) => ({
      text: operation.name,
      value: operation.id,
    }));
    operationTypeOptions.unshift({ text: '', value: null });

    const hourlyRepeatOptionsWithProps = (props) => (
      <HourlyRepeatOptions input={props.input} hours={this.props.hours} />
    );

    const safeParseInt = (value) =>
      Number(value) === 0 || Number.isNaN(Number(value)) ? '' : Number(Number(value));

    const getModelYearFieldArrayProps = () => {
      const fields = this.props;
      const selectedYears = map(fields, 'yearId');

      return {
        siteModelYears,
        createdFields: fields,
        remainingModelYears: siteModelYears.filter((model) => !selectedYears.includes(model.id)),
        existingModelYears: this.props.modelYears || [],
        intl: this.props.intl,
      };
    };
    return (
      <div>
        <div className="form-title">
          {this.props.isNew
            ? intl.formatMessage({ id: 'newTaskDetails' })
            : intl.formatMessage({ id: 'editTaskDetails' })}{' '}
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="name">{intl.formatMessage({ id: 'taskName' })}</label>
            <Field
              name="name"
              id="name"
              component={requiredInput}
              type="text"
              aria-describedby="descriptionHelp"
              placeholder="Enter name for task"
              validate={[fieldRequired]}
            />
          </div>
          <div className="form-group">
            <label htmlFor="type">{intl.formatMessage({ id: 'taskSubTitle' })}</label>
            <Field
              name="subTitle"
              id="subTitle"
              component={Input}
              type="text"
              aria-describedby="descriptionHelp"
              placeholder="Enter sub title for task"
            />
          </div>
          {!prefixesDisabled ?? (
            <div className="form-group">
              <label htmlFor="prefixId">{intl.formatMessage({ id: 'taskPrefix' })}</label>
              <Field
                name="prefixId"
                id="prefixId"
                component={requiredSelect}
                parse={(value) => Number(value)}
                options={prefixOptions}
                disabled={prefixesDisabled}
              />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="type">{intl.formatMessage({ id: 'type' })}</label>
            <Field
              name="type"
              id="type"
              component={requiredSelect}
              parse={(value) => Number(value)}
              options={typeOptions}
            />
          </div>
          <div className="form-group">
            <label htmlFor="locationId">{intl.formatMessage({ id: 'location' })}</label>
            <Field
              name="locationId"
              id="locationId"
              component={requiredSelect}
              parse={(value) => value && Number(value)}
              options={locationOptions}
              validate={[fieldRequired]}
            />
          </div>
          {!this.props.isNew ? (
            <div className="form-group">
              <label htmlFor="taskId">{intl.formatMessage({ id: 'taskId' })}</label>
              <Field
                name="id"
                id="taskId"
                component={Input}
                parse={(value) => Number(value)}
                disabled={true}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="form-title">{intl.formatMessage({ id: 'interval' })}</div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="interval">{intl.formatMessage({ id: 'dueDateIntervalType' })}</label>
            <Field
              id="interval"
              name="interval"
              component={requiredSelect}
              parse={(value) => value && Number(value)}
              options={intervalOptions}
              validate={[fieldRequired]}
              onBlur={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="form-group interval-description">
            <strong>{intervalDescription}</strong>
          </div>
        </div>
        {/* <div className="form-row">
          <div className="form-group">
            <label htmlFor="interval">{intl.formatMessage({ id: 'dueDateIntervalType' })}</label>
            <Field
              id="additionalInterval"
              name="additionalInterval"
              component={requiredSelect}
              parse={(value) => value && Number(value)}
              options={additionalIntervalOptions}
              validate={[fieldRequired]}
              onBlur={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div> */}
        <div className="form-row">
          {interval !== Interval.CarCount && (
            <>
              {/* <div className="form-group">
                <label htmlFor="start">{startDateText}</label>
                <Field
                  name="additionalInterval"
                  id="additionalInterval"
                  component={requiredDatePicker}
                  placeholderText="Choose date"
                  showToday
                  validate={[fieldRequired]}
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="start">{startDateText}</label>
                <Field
                  name="start"
                  id="start"
                  component={requiredDatePicker}
                  placeholderText="Choose date"
                  showToday
                  validate={[fieldRequired]}
                />
              </div>
              {interval !== Interval.Once && (
                <div className="form-group">
                  <label htmlFor="end">End</label>
                  <Field
                    name="end"
                    id="end"
                    component={DateTimePicker}
                    placeholderText="Choose date"
                    showToday={false}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="form-row full">
          {/* {interval === Interval.Daily && ( */}
          {(interval === Interval.Daily ||
            interval === Interval.Monthly ||
            interval === Interval.Yearly) && (
            <Field
              name="intervalCount"
              component={IntervalCount}
              parse={(value) => value && Number(value)}
            />
          )}
          {interval === Interval.Weekly && (
            <Field name="weeklySchedule" component={WeeklyRepeatOptions} validate={[missingDays]} />
          )}
          {interval === Interval.CarCount && (
            <>
              <div className="form-group" key="0">
                <label htmlFor="startingCarCount" className="mr-1">
                  Starting car count
                </label>
                <Field
                  name="startingCarCount"
                  id="startingCarCount"
                  component={requiredInput}
                  type="number"
                  placeholder="When the task should be completed for the first time"
                  className="form-control"
                  parse={(value) => value && Number(value)}
                  validate={[fieldRequired]}
                  min="1"
                />
              </div>

              {!isEssentialTask && (
                <div className="form-group" key="1">
                  <label htmlFor="carCount" className="mr-1">
                    {this.props.intl.formatMessage(
                      { id: 'currentCarCount' },
                      { carCount: this.props.totalCarCount }
                    )}{' '}
                    {`(${moment().format('MM/DD/YYYY')})`}
                  </label>
                </div>
              )}
              <div className="form-group" key="2">
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      name="repeatTask"
                      id="repeatTask"
                      className="form-check-input"
                      component="input"
                      type="checkbox"
                    />{' '}
                    Repeat task
                  </label>
                </div>
              </div>
              <div className="form-group" key="3">
                <label htmlFor="intervalCarCount" className="mr-1">
                  Repeat interval
                </label>
                <Field
                  name="intervalCarCount"
                  id="intervalCarCount"
                  component={this.props.repeatTask ? requiredInput : Input}
                  type="number"
                  placeholder="Car count for repeating task"
                  className="form-control"
                  parse={safeParseInt}
                  validate={this.props.repeatTask ? [fieldRequired] : []}
                  disabled={!this.props.repeatTask}
                  min="1"
                />
              </div>
            </>
          )}
          {interval === Interval.Hourly && (
            <Field name="hourlySchedule" component={hourlyRepeatOptionsWithProps} />
          )}
        </div>
        <div
          onClick={() => this.handleToggle('instructionsToggle')}
          className="form-title collapsible"
        >
          <span>{intl.formatMessage({ id: 'instructions' })}</span>
          <Button style={{ border: '0px' }}>
            <i className="icon icon-chevron-down" />
          </Button>
        </div>
        <Collapse isOpen={this.state.instructionsToggle}>
          <div className="collapse-padding">
            <Field
              name="description"
              id="description"
              component={ControlledRichText}
              placeholder=""
            />
          </div>
        </Collapse>
        <div
          onClick={() => this.handleToggle('linkedTaskToggle')}
          className="form-title collapsible"
        >
          <span>
            {intl.formatMessage({ id: 'linkTasks' })}
            {this.props?.linkedTasks?.length ? ` (+${this.props?.linkedTasks?.length})` : ''}
          </span>
          <Button style={{ border: '0px' }}>
            <i className="icon icon-chevron-down" />
          </Button>
        </div>
        <Collapse isOpen={this.state.linkedTaskToggle}>
          <div className="collapse-padding linked-tasks">
            <FieldArray
              id="linkedTasks"
              name="linkedTasks"
              component={(fields) => (
                <LinkedTaskField intl={this.props.intl} fields={fields.fields} {...this.props} />
              )}
            />
          </div>
        </Collapse>
        <div onClick={() => this.handleToggle('resourceToggle')} className="form-title collapsible">
          <span>
            {this.props.intl.formatMessage({ id: 'videos' })} &{' '}
            {intl.formatMessage({ id: 'resources' })}
          </span>
          <Button style={{ border: '0px' }}>
            <i className="icon icon-chevron-down" />
          </Button>
        </div>
        <Collapse isOpen={this.state.resourceToggle}>
          <div className="form-row full">
            <div className="form-group">
              <label htmlFor="videos">{this.props.intl.formatMessage({ id: 'videos' })}</label>
              <FieldArray name="videos" component={TaskVideoEmbed} />
            </div>
            {isEssentialTask && (
              <div className="form-group">
                <label htmlFor="resource" className="mt-3">
                  {this.props.intl.formatMessage({ id: 'resource' })}
                </label>
                <Field
                  name="resource"
                  id="resource"
                  component={Input}
                  type="text"
                  placeholder="Enter resource url"
                />
              </div>
            )}
          </div>
          {!isEssentialTask && (
            <div className="form-row full">
              <div className="form-group">
                <label htmlFor="inventory">Parts</label>
                <FieldArray name="inventory" id="inventory" component={TaskInventoryEdit} />
              </div>
            </div>
          )}
        </Collapse>
        <Permission role={RoleTypes.OperatorGroupAdmin} minRole={RoleTypes.OperatorGroupAdmin}>
          {isEssentialTask && (
            <>
              <div
                onClick={() => this.handleToggle('siteDetailsToggle')}
                className="form-title collapsible"
              >
                <span>{intl.formatMessage({ id: 'siteDetails' })}</span>
                <Button style={{ border: '0px' }}>
                  <i className="icon icon-chevron-down" />
                </Button>
              </div>
              <Collapse isOpen={this.state.siteDetailsToggle}>
                <div className="form-row half">
                  <div className="form-group">
                    <label htmlFor="name">Operation Type</label>
                    <Field
                      id="operationTypeId"
                      name="operationTypeId"
                      component={requiredSelect}
                      options={operationTypeOptions}
                      parse={(value) => value && Number(value)}
                      validate={[fieldRequired]}
                      onBlur={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              </Collapse>
            </>
          )}
        </Permission>
      </div>
    );
  }
}

TaskEditForm.propTypes = {
  interval: PropTypes.number,
  intervalDescription: PropTypes.string,
  hours: PropTypes.arrayOf(PropTypes.number),
  totalCarCount: PropTypes.number,
  intl: PropTypes.shape({}).isRequired,
  repeatTask: PropTypes.bool,
  isEssentialTask: PropTypes.bool,
  type: PropTypes.number,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  taskPrefixes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      message: PropTypes.string,
    })
  ),
  taskLocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      message: PropTypes.string,
      orderNumber: PropTypes.number,
    })
  ),
  modelYears: PropTypes.arrayOf(
    PropTypes.shape({
      yearId: PropTypes.number,
    })
  ),
  siteModelYears: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      year: PropTypes.string,
    })
  ),
  siteOperationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

const formSelector = formValueSelector('task');

const mapStateToProps = (state, ownProps) => {
  const {
    intl: { formatMessage },
  } = ownProps;
  const intervalConfig = formSelector(
    state,
    'interval',
    'intervalCount',
    'hourlySchedule',
    'weeklySchedule',
    'monthlySameWeekday',
    'start',
    'startingCarCount',
    'intervalCarCount',
    'repeatTask',
    'type',
    'prefixId',
    'years',
    'linkedTasks'
  );
  const intervalDescription = createIntervalDescription(intervalConfig, formatMessage);
  return {
    interval: intervalConfig.interval,
    schedule: intervalConfig.weeklySchedule,
    repeatTask: intervalConfig.repeatTask,
    type: intervalConfig.type,
    intervalDescription,
    intervalYears: intervalConfig.years,
    description: formSelector(state, 'description'),
    linkedTasks: formSelector(state, 'linkedTasks'),
  };
};

// TODO: add validator
const enhance = compose(injectIntl, connect(mapStateToProps), reduxForm({ form: 'task' }));

export default enhance(TaskEditForm);
