import { FormikComputedProps, FormikHandlers, FormikHelpers, FormikState, useFormik } from 'formik';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { checkEmptyValues } from '../../../core/formValidation';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  position: string;
  loginCredentialsNeeded: boolean;
  isCashier: boolean;
  userGroupId: number | null;
  coreSites: { id: number }[];
}

export type Formik = FormikHandlers &
  FormikState<FormValues> &
  FormikComputedProps<FormValues> &
  FormikHelpers<FormValues>;

type FormPage = 'employeeFormPage' | 'userFormPage';

export interface Form {
  formik: Formik;
  handleResetClick: () => void;
  handleSubmitClick: () => void;
  handleTogglePage: () => void;
  formPage: FormPage;
}

export interface FormConfigs {
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
}

interface UserFields {
  userGroupId: number;
  coreSites: { id: number }[];
}

export const userFields: (keyof UserFields)[] = ['userGroupId', 'coreSites'];

const useForm = ({ onSubmit, isLoading }: FormConfigs): Form => {
  const [formPage, setFormPage] = useState<FormPage>('employeeFormPage');

  useEffect(() => {
    if (!isLoading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [isLoading]);

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      position: '',
      loginCredentialsNeeded: false,
      isCashier: false,
      userGroupId: null,
      coreSites: [],
    },
    validate: (values: FormValues) => {
      if (!values.loginCredentialsNeeded) {
        return checkEmptyValues(omit(values, userFields));
      }

      return checkEmptyValues(values);
    },
    onSubmit,
    isInitialValid: false,
    validateOnChange: true,
  });

  const handleResetClick = () => {
    formik.resetForm();
  };

  const handleSubmitClick = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  const handleTogglePage = () => {
    if (formPage === 'employeeFormPage') {
      setFormPage('userFormPage');
    } else {
      setFormPage('employeeFormPage');
    }
  };

  return {
    formik,
    handleResetClick,
    handleSubmitClick,
    handleTogglePage,
    formPage,
  };
};

export default useForm;
