import { PropTypes } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const styles = {
  tab: {
    color: '#292b2c',
  },
};

const ViewTabs = ({ viewOptions }) => {
  const views = viewOptions.map((v, index) => {
    return (
      <li key={index} className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          exact
          to={v.pathname}
          style={styles.tab}
        >
          {v.view}
        </NavLink>
      </li>
    );
  });

  return <ul className="nav nav-tabs restyled">{views}</ul>;
};

ViewTabs.propTypes = {
  viewOptions: PropTypes.arrayOf(PropTypes.object),
};

export default ViewTabs;
