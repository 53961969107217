import { FormikComputedProps, FormikHandlers, FormikState, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmptyValues } from '../../../core/formValidation';
import { getApiStatus } from '../../../core/utils';
import { statusSelector } from '../../../store/authReducer';
import {
  checkTokenStatus,
  PASSWORD_RESET_REQUEST,
  setPassword,
  statusError,
  statusReset,
} from '../actions';

export interface FormValues {
  password: string;
  confirmPassword: string;
}

interface Form {
  formik: FormikHandlers & FormikState<FormValues> & FormikComputedProps<FormValues>;
  handleSubmitClick: () => void;
  statusMessage: { type: string; message: string };
  removeStatusMessage: () => void;
  setErrorMessage: (message: string) => void;
  checkValidationToken: (token: string) => void;
}

const useSetPasswordForm = (): Form => {
  const [validToken, setToken] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getApiStatus(state, PASSWORD_RESET_REQUEST));
  const statusMessage = useSelector((state) => statusSelector(state));

  const removeStatusMessage = () => {
    dispatch(statusReset());
  };

  const setErrorMessage = (message: string) => {
    dispatch(statusError(message));
  };

  const checkValidationToken = (token: any) => {
    dispatch(checkTokenStatus(token));
    setToken(token);
  };

  useEffect(() => {
    if (!isLoading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [isLoading]);

  const handleSubmit = (values: FormValues) => {
    if (statusMessage && statusMessage.message !== 'Token is invalid') {
      dispatch(setPassword({ token: validToken, password: values.confirmPassword }));
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: checkEmptyValues,
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnChange: true,
  });

  const handleSubmitClick = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return {
    formik,
    handleSubmitClick,
    statusMessage,
    removeStatusMessage,
    setErrorMessage,
    checkValidationToken,
  };
};

export default useSetPasswordForm;
