import { reduce } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import DataTable from '../../../core/components/DataTable';
import { formatAmount } from '../../../utils/money-util';
import { PayAsYouGoDiscount } from '../models';

const Big = require('big.js');

const PayAsYouGoDiscounts = ({ intl, data, showLoader }) => {
  const formatMessage = intl.formatMessage;

  const getTableHeaders = () => {
    return ['name', 'purchases', 'totalDiscount'].map((id) => ({
      name: formatMessage({ id }),
    }));
  };

  const sumTotalDiscount = (prev, current) => {
    return Big(prev).add(current.totalDiscount).toFixed(2);
  };

  const getTotalRow = () => {
    const totalCounts = reduce(data, (prev, current) => prev + current.count, 0);
    const totalDiscount = reduce(data, sumTotalDiscount, 0);

    return {
      name: formatMessage({ id: 'total' }),
      count: totalCounts,
      totalDiscount: formatAmount(totalDiscount, data[0].currency),
      _highlightInformation: {
        index: 2,
        highlight: true,
      },
    };
  };

  const getTableData = () => {
    const tableData = data.map((row) => row.formatToTableData());

    if (data.length) {
      const totalRow = getTotalRow();

      tableData.push(totalRow);
    }

    return tableData;
  };

  const inititalizeDataTable = () => {
    return {
      headers: getTableHeaders(),
      data: getTableData(),
      showLoader,
      addedClass: 'wash-count-table shrinked-table',
    };
  };

  return (
    <section className="wc-table-container">
      <h2 className="wc-table-header">{intl.formatMessage({ id: 'payPerWashDiscounts' })}</h2>
      <DataTable {...inititalizeDataTable()} />
    </section>
  );
};

PayAsYouGoDiscounts.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(PayAsYouGoDiscount).isRequired),
  showLoader: PropTypes.bool.isRequired,
};

export default PayAsYouGoDiscounts;
