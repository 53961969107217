import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSite } from '../../../store/authReducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { getOverviewWashCounts } from '../actions';
import { selectMyChartsWashCounts } from '../reducer';
import StackedBarChart from './StackedBarChart';

interface Props {
  fromDate: any;
  untilDate: any;
  hourlyWashCounts: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[];
  closestHourly: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[];
}
const WashCountsChartDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const primarySiteId = useSelector(selectSite),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    overviewWashCounts = useSelector(selectMyChartsWashCounts);
  const fetchWashCounts = () => {
    dispatch(getOverviewWashCounts(moment(props.fromDate).toISOString()));
  };
  useEffect(fetchWashCounts, [
    props.fromDate,
    primarySiteId,
    multiSiteSelection,
    props.hourlyWashCounts,
  ]);
  return (
    <StackedBarChart
      hourlyWashCounts={props.hourlyWashCounts}
      closestHourlyWashCounts={props.closestHourly}
      washCounts={overviewWashCounts}
      fromDate={props.fromDate}
      untilDate={props.untilDate}
    />
  );
};

export default WashCountsChartDataGetter;
