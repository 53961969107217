import * as api from '../api';

export const fetchCostPerCarData = (args: any): Promise<any> => {
  const {
    data: {
      query: { siteId },
    },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/cost-per-car`, args);
};
