import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { setTypeFilter } from '../actions';
import useDamageClaimOptions from '../hooks/useDamageClaimOptions';

export const TypeFilter = ({ intl, setFilter, typeFilter }) => {
  const handleChange = ({ target: { value } }) => {
    setFilter(Number(value));
  };

  const reclamationDamageOptions = useDamageClaimOptions(false);

  return (
    <div className="form-group">
      <label>{intl.formatMessage({ id: 'type' })}</label>
      <select className="form-control" value={typeFilter} onChange={handleChange}>
        <option value={0}>All</option>
        {reclamationDamageOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
};

TypeFilter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  typeFilter: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFilter: setTypeFilter }, dispatch);
};

export default compose(connect(null, mapDispatchToProps), injectIntl)(TypeFilter);
