import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasAnalyticsEnabledRedir } from '../core/auth';
import PowerBiReport from '../views/analytics/containers/PowerBiReport';

const AnalyticsRoute = () => {
  return (
    <Switch>
      <Route exact path="/:site/analytics/">
        <PowerBiReport />
      </Route>
    </Switch>
  );
};

export default userHasAnalyticsEnabledRedir(AnalyticsRoute);
