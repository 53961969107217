import { clone, forEach, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  intl: any;
  /** Will update parent component state */
  setSearchMatch: any | React.Dispatch<React.SetStateAction<any[]>>;
  /** Field that is distinct, i.e. object.id, to the collection */
  uniqueField: number | string;
  /** Collection to search through i.e. [{id: number, name: string}] or [{id: number, data: [{name: string}]}] */
  dataToSearchOn: any[];
  /** Any properties of an object you wish to search for in the provided collection,
   * example: ["foo.bar", "foo.baz"] or ["foo", "bar"] */
  dataToSearchFor: string[];
  styles?: string[];
  placeholder?: string;
}

const SearchBar = ({
  dataToSearchOn,
  dataToSearchFor,
  uniqueField,
  setSearchMatch,
  styles,
  placeholder,
}: Props) => {
  const topicPath = useRef<string[][]>();
  const [inputText, setInputText] = useState('');
  const intl = useIntl();

  const createSearchContentRecursively = (path: string[], data: string): string => {
    const newPath = clone(path);
    const isDataPathDeeper = newPath.length > 0;

    if (isDataPathDeeper) {
      const index: any = newPath.pop();
      const newDataPath = data[index];
      return createSearchContentRecursively(newPath, newDataPath);
    }

    return data;
  };

  const getSearchDetailsMatch = (searchText: string) => {
    const results: string[] = [];
    const resultDetails: string[] = [];
    const lowerCasedSearchText = searchText.toLowerCase();

    forEach(topicPath.current, (path) => {
      forEach(dataToSearchOn, (data) => {
        const content = createSearchContentRecursively(path, data);
        if (!content) {
          return;
        }
        if (content.toLowerCase().includes(lowerCasedSearchText)) {
          if (!resultDetails.includes(data[uniqueField])) {
            results.push(data);
            resultDetails.push(data[uniqueField]);
          }
        }
      });
    });

    const searchResults = results.length > 0 ? results : null;

    setSearchMatch(searchResults, resultDetails, lowerCasedSearchText);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;

    setInputText(searchText);
    getSearchDetailsMatch(searchText);
  };

  useEffect(() => {
    topicPath.current = map(dataToSearchFor, (topic) => topic.split('.').reverse());
  }, [dataToSearchFor]);

  useEffect(() => {
    getSearchDetailsMatch(inputText);
  }, [dataToSearchOn]);

  return (
    <input
      type="search"
      data-testid="search-bar"
      placeholder={placeholder || intl.formatMessage({ id: 'search' })}
      className={`form-control nosubmit ${styles || ''}`}
      onChange={handleOnChange}
    />
  );
};

export default SearchBar;
