import _ from 'lodash';
import { useMemo } from 'react';
import { formatAmount } from '../../../utils/money-util';

const useSummaryData = (summaryCounts) => {
  const formatCountsToTableData = (counts) => {
    const { name, amount, currency, tax, total } = counts;

    return {
      name,
      amount: formatAmount(amount, currency),
      tax: formatAmount(tax, currency),
      total: formatAmount(total, currency),
    };
  };

  const getSummaryData = () => {
    const countOrder = [
      'gross-sales',
      'pm-total-pos-sales',
      'cde-expected-cash-sales-summary',
      'over-short',
    ];

    return _(countOrder)
      .map((order) => summaryCounts.find((counts) => counts.id === order))
      .compact()
      .map(formatCountsToTableData)
      .value();
  };

  const getCashDrawerSubRows = () => {
    const cashDrawerSummary = summaryCounts.find(
      (counts) => counts.id === 'cde-expected-cash-sales-summary'
    );

    if (!cashDrawerSummary || !cashDrawerSummary.items) {
      return null;
    }

    return _(cashDrawerSummary.items)
      .sortBy('id')
      .map((item) => {
        const total = item.displayTotal || item.total;

        return {
          _id: item.id,
          name: item.name,
          amount: '',
          tax: '',
          total: formatAmount(total, item.currency),
        };
      })
      .value();
  };

  const getTotalPosSalesSubRows = () => {
    const subRowIds = ['pm-total-pos-cash', 'pm-total-pos-non-cash'];

    return summaryCounts
      .filter((counts) => subRowIds.includes(counts.id))
      .map(formatCountsToTableData);
  };

  return useMemo(() => {
    return {
      data: getSummaryData(),
      cashDrawerSubRows: getCashDrawerSubRows(),
      totalPosSalesSubRows: getTotalPosSalesSubRows(),
    };
  }, [summaryCounts]);
};

export default useSummaryData;
