import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ContentTabs from '../../../core/components/ContentTabs';

class ClaimsViews extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    const locations = [
      {
        pathname: `/${this.props.match.params.site}/claims`,
        label: 'claims',
      },
      {
        pathname: `/${this.props.match.params.site}/claims/reports`,
        label: 'claimsReports',
      },
    ];

    return (
      <div className="container-fluid">
        <ContentTabs locations={locations} showNavLinks />
        <section className="page-main-content">{this.props.children}</section>
      </div>
    );
  }
}

export default withRouter(ClaimsViews);
