import * as _ from 'lodash';
import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import DataTable from '../../../core/components/DataTable';
import { useAppContext } from '../../../providers/AppProvider';

export const DateBasedReadings = ({ intl, detergents, detergentReadings, getReadings }) => {
  useEffect(() => {
    const detergentIds = _.map(detergents, 'id');
    getReadings(detergentIds);
  }, []);

  const tz = useAppContext().timeZone;

  const initializeDataTable = () => {
    const readingsGroupedByDate = _.chain(detergentReadings)
      .values()
      .flatten()
      .map((reading) => {
        const detergent = detergents.find((d) => d.id === reading.detergentId);

        return !detergent
          ? null
          : {
              ...reading,
              date: moment.tz(reading.timestamp, tz).format('MM/DD/YYYY'),
              detergent: detergent.name,
            };
      })
      .filter((reading) => reading !== null)
      .groupBy('date')
      .value();

    const detergentHeaders = _.map(detergents, 'name');

    const getDataRows = () => {
      const dates = Object.keys(readingsGroupedByDate);
      const rows = dates.map((date) => {
        const row = { _id: `${date}-readings`, Date: date };

        detergentHeaders.forEach((header) => {
          row[header] = '';
        });

        return row;
      });

      return rows.sort((first, second) => {
        const firstAsMoment = new Date(first.Date);
        const secondAsMoment = new Date(second.Date);

        return secondAsMoment - firstAsMoment;
      });
    };

    const getSubRowData = (data) => {
      const groupedByTimestamp = _.chain(data)
        .map((d) => ({
          ...d,
          timestamp: moment.tz(d.timestamp, tz).startOf('minute').format(),
        }))
        .groupBy('timestamp')
        .value();

      const subRows = [];

      _.forEach(groupedByTimestamp, (readings, timestamp) => {
        const groupedByDetergent = _.groupBy(readings, 'detergent');
        let maxRows = 0;

        _.forEach(groupedByDetergent, (readingsForDetergent) => {
          if (readingsForDetergent.length > maxRows) {
            maxRows = readingsForDetergent.length;
          }
        });

        for (let i = 0; i < maxRows; i++) {
          const subRow = { date: moment.tz(timestamp, tz).format('hh:mm a') };

          detergentHeaders.forEach((header) => {
            const readingsForDetergent = groupedByDetergent[header];
            subRow[header] =
              readingsForDetergent && readingsForDetergent[i] ? readingsForDetergent[i].value : '';
          });

          subRows.push(subRow);
        }
      });

      return subRows.sort((first, second) => {
        const firstAsMoment = moment.utc(first.date, 'hh:mm a');
        const secondAsMoment = moment.utc(second.date, 'hh:mm a');

        return secondAsMoment - firstAsMoment;
      });
    };

    const getSubRows = () => {
      const dates = Object.keys(readingsGroupedByDate);

      return dates.map((date) => ({
        parentRow: `${date}-readings`,
        rows: { data: getSubRowData(readingsGroupedByDate[date]) },
      }));
    };

    const subRowInfo = {
      identifier: '_id',
      displayProperty: 'Date',
      subRows: getSubRows(),
    };

    return {
      headers: [{ name: intl.formatMessage({ id: 'date' }) }, ...detergents],
      data: getDataRows(),
      subRowInfo,
      addedClass: 'historical-tank-readings-table mb-0',
      scrolling: true,
    };
  };

  return <DataTable {...initializeDataTable()} />;
};

DateBasedReadings.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object),
  detergentReadings: PropTypes.shape({}),
  getReadings: PropTypes.func.isRequired,
};

export default injectIntl(DateBasedReadings);
