import { get } from 'lodash';

export const initialState = {};

const increaseApiRequestCount = (state, action) => {
  const pendingRequestCount = get(state, `${action.data}.pendingRequestCount`, 0) + 1;

  return {
    ...state,
    [action.data]: { pendingRequestCount, isLoading: true },
  };
};

const decreaseApiRequestCount = (state, action) => {
  const pendingRequestCount = get(state, `${action.data}.pendingRequestCount`, 0) - 1;

  return {
    ...state,
    [action.data]: {
      pendingRequestCount: Math.max(pendingRequestCount, 0),
      isLoading: pendingRequestCount > 0,
    },
  };
};

export function apiRequests(state = initialState, action) {
  switch (action.type) {
    case 'INCREASE_API_REQUEST_COUNT':
      return increaseApiRequestCount(state, action);
    case 'DECREASE_API_REQUEST_COUNT':
      return decreaseApiRequestCount(state, action);
    default:
      return state;
  }
}

export const selectApiRequests = (state) => {
  return state.apiRequests;
};
