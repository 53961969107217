import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getTaskPrefixes } from '../actions';
import TaskFilters from '../components/TaskFilters';
import { selectTaskPrefixes } from '../reducer';

const TaskPrefixFilter = (props) => {
  const { intl, selectedFilter, fetchTaskPrefixes, taskPrefixList, taskListType, setFilter } =
    props;

  useEffect(() => {
    if (!taskPrefixList.length) {
      fetchTaskPrefixes();
    }
  }, [fetchTaskPrefixes, taskPrefixList]);

  return (
    <TaskFilters
      label={intl.formatMessage({ id: 'taskPrefix' })}
      taskListType={taskListType}
      selectedFilter={selectedFilter}
      options={taskPrefixList}
      setFilter={setFilter}
    />
  );
};

TaskPrefixFilter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  selectedFilter: PropTypes.string,
  taskPrefixList: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      message: PropTypes.string,
    })
  ),
  fetchTaskPrefixes: PropTypes.func,
  taskListType: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};

TaskPrefixFilter.propDefault = {
  taskPrefixList: [],
};

const mapStateToProps = (state) => {
  return {
    taskPrefixList: selectTaskPrefixes(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchTaskPrefixes: getTaskPrefixes,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(memo(TaskPrefixFilter, isEqual));
