import * as utils from '../../core/utils';

export const AlertType = {
  DetergentLevel: 0,
  TaskOverdue: 1,
  InventoryLevel: 2,
};

export class Alert {
  constructor(props = {}) {
    this.id = props.id;
    this.type = utils.toEnum(AlertType, props.type);
    this.description = props.description;
    this.createdAt = props.createdAt;
    this.creator = props.creator;
    this.modifier = props.modifier;
    this.modifiedAt = props.modifiedAt;
    this.archivedAt = props.archivedAt;
    this.archiver = props.archiver;
    this.acknowledged = props.acknowledged;
    this.taskId = props.taskId;
    this.detergentId = props.detergentId;
    this.siteId = props.siteId;
  }
}
