import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';

class SuggestedTextPicker extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      onChange: PropTypes.func.isRequired,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    itemCallback: PropTypes.func,
    intl: PropTypes.shape({}).isRequired,
    renderSuggestion: PropTypes.func,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
    matchProperty: PropTypes.string,
  };

  static defaultProps = {
    matchProperty: 'name',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.input.value,
      suggestions: props.items ? props.items : [],
    };
  }

  onSuggestionSelected = (event, selected) => {
    this.setState({ value: selected.suggestion[this.props.matchProperty] }, () => {
      this.props.input.onChange(selected.suggestion[this.props.matchProperty]);
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  // eslint-disable-next-line
  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  getFuzzyMatches = (input) => (object) => {
    const tokens = object[this.props.matchProperty].split('');
    let searchPosition = 0;

    for (const token of tokens) {
      if (token.toLowerCase() === input[searchPosition]) {
        searchPosition += 1;
        if (searchPosition >= input.length) {
          break;
        }
      }
    }

    if (searchPosition !== input.length) {
      return {};
    }

    return { ...object, name: tokens.join('') };
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();

    const items = this.props.itemCallback ? this.props.itemCallback() : this.props.items;
    return items
      .map(this.getFuzzyMatches(inputValue))
      .filter((result) => result[this.props.matchProperty]);
  };

  getSuggestionValue = (suggestion) => suggestion[this.props.matchProperty];

  defaultRenderSuggestion = (suggestion) => (
    <div className="suggestion">
      <span>{suggestion[this.props.matchProperty]}</span>
    </div>
  );

  renderSuggestion = (suggestion) => {
    if (this.props.renderSuggestion) {
      return this.props.renderSuggestion(suggestion);
    }
    return this.defaultRenderSuggestion(suggestion);
  };

  renderInputComponent = (inputProps) => <input {...inputProps} className="form-control" />;

  render() {
    const inputProps = {
      placeholder: this.props.intl.formatMessage({
        id: this.props.placeholder,
      }),
      value: this.state.value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        shouldRenderSuggestions={() => true}
        theme={{
          container: this.props.containerClassName || '',
          suggestionsContainer: 'suggestions-container',
          suggestionsList: 'suggestions-list',
        }}
      />
    );
  }
}

export default injectIntl(SuggestedTextPicker);
