import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import {
  fieldRequired,
  requiredInput,
  requiredSelect,
  requiredTextArea,
} from '../../../core/formValidation';
import { ActionDisplayFormats, FeedbackType, PriorityType } from '../models';

const FeedbackEditForm = () => {
  const intl = useIntl();
  return (
    <div>
      <div className="form-group">
        <label htmlFor="customerName">{intl.formatMessage({ id: 'customerName' })}</label>
        <Field
          name="customerName"
          id="customerName"
          type="text"
          className="form-control"
          component={requiredInput}
          placeholder="Enter customer's full name"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="comment">{intl.formatMessage({ id: 'comment' })}</label>
        <Field
          name="comment"
          id="comment"
          type="text"
          className="form-control"
          component={requiredTextArea}
          maxLength="255"
          placeholder="Enter comment"
          validate={[fieldRequired]}
          data-testid="comment-feedback-form"
        />
      </div>
      <div className="form-group">
        <label htmlFor="type">{intl.formatMessage({ id: 'feedbackType' })}</label>
        <Field
          name="type"
          id="type"
          type="text"
          className="form-control"
          component={requiredSelect}
          parse={(value: any) => Number(value)}
          validate={[fieldRequired]}
        >
          <option value={FeedbackType.Positive}>{intl.formatMessage({ id: 'positive' })}</option>
          <option value={FeedbackType.Neutral}>{intl.formatMessage({ id: 'neutral' })}</option>
          <option value={FeedbackType.Negative}>{intl.formatMessage({ id: 'negative' })}</option>
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="priority">Priority</label>
        <Field
          name="priority"
          id="priority"
          type="text"
          className="form-control"
          component={requiredSelect}
          parse={(value: any) => Number(value)}
          validate={[fieldRequired]}
        >
          <option value={PriorityType.Low}>{intl.formatMessage({ id: 'low' })}</option>
          <option value={PriorityType.Medium}>{intl.formatMessage({ id: 'medium' })}</option>
          <option value={PriorityType.High}>{intl.formatMessage({ id: 'high' })}</option>
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="actionTaken">{intl.formatMessage({ id: 'actionTaken' })}</label>
        <Field
          name="actionTaken"
          id="actionTaken"
          type="text"
          className="form-control"
          component={requiredSelect}
          parse={(value: any) => Number(value)}
          validate={[fieldRequired]}
        >
          <option value={ActionDisplayFormats.NoneNeeded}>
            {intl.formatMessage({ id: 'noneNeeded' })}
          </option>
          <option value={ActionDisplayFormats.Resolved}>
            {intl.formatMessage({ id: 'resolved' })}
          </option>
          <option value={ActionDisplayFormats.NotYetResolved}>
            {intl.formatMessage({ id: 'notYetResolved' })}
          </option>
        </Field>
      </div>
    </div>
  );
};

export default reduxForm({ form: 'feedback' })(FeedbackEditForm);
