import { forEach } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import InfoRows from '../../../core/components/InfoRows';
import { spacesToCamelCaseString } from '../../../core/utils';

const RewashDetailInfo = ({ reclamation, intl }) => {
  const vehicleDetails = reclamation.vehicleDetails || {};
  const incidentDetails = reclamation.incidentDetails;
  const rewashCauses = [];

  if (incidentDetails && incidentDetails.needsRewash) {
    forEach(incidentDetails.needsRewash, (reason, key) => {
      if (reason === true) {
        rewashCauses.push(key);
      }
    });
  }

  const infos = [
    {
      header: intl.formatMessage({ id: 'customerInfo' }),
      rows: {
        Name: reclamation.customerName,
        Email: reclamation.customerEmail,
        Phone: reclamation.customerPhone,
      },
    },
    {
      header: intl.formatMessage({ id: 'vehicleInfo' }),
      rows: {
        Plate: vehicleDetails.plate,
        Make: vehicleDetails.make,
        Model: vehicleDetails.model,
        Year: vehicleDetails.year,
        Color: vehicleDetails.color,
        VIN: vehicleDetails.vin,
      },
    },
  ];
  return (
    <div className="row">
      {infos.map((info, index) => (
        <div className="col-sm claim-detail-info" key={index}>
          <InfoRows {...info} />
        </div>
      ))}
      <div className="col-sm claim-detail-info">
        <h3>{intl.formatMessage({ id: 'rewashCauses' })}</h3>
        {rewashCauses.map((reason) => (
          <div key={reason}>{spacesToCamelCaseString(reason)}</div>
        ))}
      </div>
    </div>
  );
};

RewashDetailInfo.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  reclamation: PropTypes.shape({
    customerName: PropTypes.string,
    issueSite: PropTypes.shape({
      name: PropTypes.string,
    }),
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
    customerEmail: PropTypes.string,
    customerPhone: PropTypes.string,
    vehicleDetails: PropTypes.shape({
      plate: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.number,
      color: PropTypes.string,
      vin: PropTypes.string,
    }),
  }),
};

export default injectIntl(RewashDetailInfo);
