import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/employees';
import { SearchParams } from './reducer';

export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const ARCHIVE_EMPLOYEE = 'ARCHIVE_EMPLOYEE';
export const UNARCHIVE_EMPLOYEE = 'UNARCHIVE_EMPLOYEE';
export const SET_ADD_EMPLOYEE_STATUS = 'SET_ADD_EMPLOYEE_STATUS';
export const SET_SAVE_EMPLOYEE_STATUS = 'SET_SAVE_EMPLOYEE_STATUS';
export const SET_ARCHIVE_EMPLOYEE_STATUS = 'SET_ARCHIVE_EMPLOYEE_STATUS';
export const SET_UNARCHIVE_EMPLOYEE_STATUS = 'SET_UNARCHIVE_EMPLOYEE_STATUS';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

interface ActionProps {
  data: {
    query?: any;
    body?: any;
  };
  closeModal?: boolean;
  meta?: any;
  callback?: () => any;
  voidCallback?: () => void;
}

interface Action {
  type: any;
  callAPI: (args: any) => Promise<any>;
  action: any;
  data: ActionProps['data'];
  callback?: () => any;
  voidCallback?: () => any;
  closeModal?: boolean;
  addEmployeeStatus?: string;
  editEmployeeStatus?: string;
  meta?: any;
}

interface StatusAction {
  type: any;
  addEmployeeStatus?: string;
  editEmployeeStatus?: string;
  archiveEmployeeStatus?: string;
  unarchiveEmployeeStatus?: string;
}

interface SearchParamsAction {
  type: any;
  searchParams: SearchParams;
}

export const fetchEmployees = (props?: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchEmployees,
    action: FETCH_EMPLOYEES,
    data: props?.data || { query: {} },
    meta: props?.meta || {
      useManagerSiteId: true,
    },
  };
};

export const getEmployee = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.getEmployee,
    data: props.data,
    action: GET_EMPLOYEE,
  };
};

export const addEmployee = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.postEmployee,
    data: props.data.body,
    action: ADD_EMPLOYEE,
    voidCallback: props.voidCallback,
    closeModal: props.closeModal !== undefined ? props.closeModal : true,
  };
};
export const addTeamMember = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.postEmployee,
    data: props.data.body,
    action: ADD_TEAM_MEMBER,
    callback: () => fetchEmployees(),
    closeModal: props.closeModal !== undefined ? props.closeModal : true,
  };
};
export const saveEmployee = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.patchEmployee,
    data: props.data.body,
    action: SAVE_EMPLOYEE,
    callback: () => fetchEmployees({ data: { query: { key: 'id', direction: 'desc' } } }),
    closeModal: props.closeModal !== undefined ? props.closeModal : true,
  };
};

export const archiveEmployee = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.archiveEmployee,
    data: props.data.body,
    action: ARCHIVE_EMPLOYEE,
    closeModal: props.closeModal !== undefined ? props.closeModal : true,
  };
};

export const unarchiveEmployee = (props: ActionProps): Action => {
  return {
    type: API_REQUEST,
    callAPI: API.unarchiveEmployee,
    data: props.data.body,
    action: UNARCHIVE_EMPLOYEE,
    closeModal: props.closeModal !== undefined ? props.closeModal : true,
  };
};

export const setAddEmployeeStatus = (addEmployeeStatus: string): StatusAction => {
  return {
    type: SET_ADD_EMPLOYEE_STATUS,
    addEmployeeStatus,
  };
};

export const setEditEmployeeStatus = (editEmployeeStatus: string): StatusAction => {
  return {
    type: SET_SAVE_EMPLOYEE_STATUS,
    editEmployeeStatus,
  };
};

export const setArchiveEmployeeStatus = (archiveEmployeeStatus: string): StatusAction => {
  return {
    type: SET_ARCHIVE_EMPLOYEE_STATUS,
    archiveEmployeeStatus,
  };
};

export const setUnarchiveEmployeeStatus = (unarchiveEmployeeStatus: string): StatusAction => {
  return {
    type: SET_UNARCHIVE_EMPLOYEE_STATUS,
    unarchiveEmployeeStatus,
  };
};

export const setSearchParams = (searchParams: SearchParams): SearchParamsAction => {
  return {
    type: SET_SEARCH_PARAMS,
    searchParams,
  };
};
