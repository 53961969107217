import * as api from '../api';

export const postTaskCompletion = (args) => {
  const {
    options: { siteId },
    data: { taskId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/tasks/${taskId}/complete`, args);
};

export const postTaskCompletionCompleted = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/task-completions/complete`, args);
};

export const postTaskCompletionAOT = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/task-completions/pre-completed`, args);
};

export const postTaskCompletionSnoozed = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/task-completions/snooze`, args);
};

export const getTaskCompletionsForTask = (args) => {
  const {
    options: { siteId },
    data: { taskId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/tasks/${taskId}/completions`, args);
};

export const getTaskCompletions = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/task-completions`, args);
};

export const fetchCompletionLogs = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/task-completions/logs`, args);
};
