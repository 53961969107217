import moment from 'moment-timezone';
import { ActionResponse } from '../../utils/typing';
import Reclamation from '../reclamations/models';

const from = sessionStorage.getItem('rewashesFrom');
const until = sessionStorage.getItem('rewashesUntil');
const tz: string = sessionStorage.getItem('timeZone') || 'UTC';

interface RewashState {
  rewashes: Reclamation[];
  rewashInEdit: Reclamation | null;
  fromDate: string;
  untilDate: string;
}

export const initialState = {
  rewashes: [],
  rewashInEdit: null,
  fromDate: from || moment.tz(tz).subtract(90, 'days').format('YYYY-MM-DD'),
  untilDate: until || moment.tz(tz).endOf('day').format(),
};

const getRewashesSuccess = (state: RewashState, action: ActionResponse) => {
  const rewashes = action.results.map((i: Reclamation) => new Reclamation(i));
  return {
    ...state,
    rewashes,
  };
};

export const rewashes = (state = initialState, action: ActionResponse): RewashState => {
  switch (action.type) {
    case 'FETCH_REWASHES_SUCCESS':
      return getRewashesSuccess(state, action.response);
    case 'SET_REWASH_FROM_DATE':
      return { ...state, fromDate: action.payload };
    case 'SET_REWASH_UNTIL_DATE':
      return { ...state, untilDate: action.payload };
    default:
      return state;
  }
};

export const selectRewashes = (state: { rewashes: RewashState }) => state?.rewashes?.rewashes;
export const selectFromDate = (state: { rewashes: RewashState }) => state?.rewashes?.fromDate;
export const selectUntilDate = (state: { rewashes: RewashState }) => state?.rewashes?.untilDate;
export const selectRewashInEdit = (state: { rewashes: RewashState }) =>
  state?.rewashes?.rewashInEdit;
