interface Props {
  errorMessageArray: string[];
}

const ErrorNotificationText = ({ errorMessageArray }: Props): JSX.Element => {
  return (
    <div className="rrt-text">
      {errorMessageArray.map((msg, i) => (
        <div key={i}>{msg}</div>
      ))}
    </div>
  );
};

export default ErrorNotificationText;
