import WorkLog from './models';

const initialState = {
  workLogs: [],
  openWorkLogs: [],
};

const fetchOpenWorkLogsSuccess = (state, response) => {
  const openWorkLogs = response.results.map((w) => new WorkLog(w));
  return {
    ...state,
    openWorkLogs,
  };
};

const fetchWorkLogsSuccess = (state, response) => {
  const workLogs = response.results.map((w) => new WorkLog(w));
  return {
    ...state,
    workLogs,
  };
};

/* eslint-disable import/prefer-default-export */
export const workLogs = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_OPEN_WORK_LOGS_SUCCESS':
      return fetchOpenWorkLogsSuccess(state, action.response);
    case 'FETCH_WORK_LOGS_SUCCESS':
      return fetchWorkLogsSuccess(state, action.response);
    default:
      return state;
  }
};

export const selectOpenWorkLogs = (state) => {
  return state.workLogs.openWorkLogs;
};

export const selectWorkLogs = (state) => {
  return state.workLogs.workLogs;
};
