import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IntlType } from '../../../../utils/typing';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';

interface Props {
  intl: IntlType;
  toggle: () => void;
  handleConfirm: () => void;
  header: string;
  text: string;
}

const ConfirmDialog = ({ intl, toggle, handleConfirm, header, text }: Props): JSX.Element => {
  return (
    <Modal toggle={toggle} fade={false} isOpen centered>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>{text}</ModalBody>
      <ModalFooter>
        <CancelButton intl={intl} handleClick={toggle} />
        <ConfirmButton intl={intl} handleClick={handleConfirm} />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDialog;
