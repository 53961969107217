import React from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasWashCountsEnabledRedir } from '../core/auth';
import POSCountAdjustments from '../views/wash-counts/containers/POSCountAdjustments';
import WashCounts from '../views/wash-counts/containers/WashCounts';

const WashCountsRoute = () => {
  const history = useHistory();
  const match = useRouteMatch('/:site/washCounts/');
  return (
    <Switch>
      <Route exact path="/:site/washCounts/">
        <WashCounts history={history} match={match} />
      </Route>
      <Route path="/:site/washCounts/adjustments">
        <POSCountAdjustments history={history} match={match} />
      </Route>
    </Switch>
  );
};

export default userHasWashCountsEnabledRedir(WashCountsRoute);
