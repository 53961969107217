export const initialState = {
  modalType: null,
  modalProps: {},
};

export const modal = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_DIALOG':
      return { modalType: action.modalType, modalProps: action.modalProps };
    case 'HIDE_DIALOG':
      return initialState;
    default:
      return state;
  }
};

export const selectModal = (state) => {
  return {
    modalType: state.modal?.modalType,
    modalProps: state.modal?.modalProps,
  };
};
