import { Big } from 'big.js';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { formatNumber, parseNumber } from '../../../utils/number-util';
import * as actions from '../actions';
import UtilityEditForm from '../components/UtilityEditForm';
import { utilityHelper } from '../helpers';
import { selectUtilityInEdit } from '../utilityReducer';
import { selectUtilityUnits } from '../utilityUnitReducer';

const initialFormValues = {
  type: 0,
  name: null,
  start: null,
  end: null,
  startReading: null,
  endReading: null,
  unit: 0,
  cost: null,
  currency: 'USD',
};

class UpsertUtilityDialog extends Component {
  static propTypes = {
    addUtility: PropTypes.func.isRequired,
    saveUtility: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    utilityUnits: PropTypes.shape({}).isRequired,
    initialUtility: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool,
    title: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
  };

  onReady = () => {
    this.props.triggerSubmit('utilities');
  };

  onSubmit = (values) => {
    const { utilityUnits, isNew, addUtility, saveUtility } = this.props;
    const endReading = parseNumber(values.endReading);
    const startReading = parseNumber(values.startReading);
    const utilityType = utilityHelper.utilityTypeEnumKeyFromVal(values.type);
    const utilityUnit = utilityHelper.utilityUnitEnumKeyFromVal(
      values.unit,
      utilityType,
      utilityUnits
    );

    const allValues = {
      ...values,
      startReading: `${startReading}`,
      endReading: `${endReading}`,
      unit: utilityUnit,
      cost: `${parseNumber(values.cost)}`,
      usage: `${Big(endReading - startReading).toFixed(4)}`,
    };

    if (isNew) {
      addUtility(allValues);
    } else {
      saveUtility(allValues);
    }
  };

  getRenderableUtilityUnits = (utilityType, utilityUnit) => {
    let renderableUtilityUnits = { ...this.props.utilityUnits };
    // Set of available units might have changed. Making sure the original unit is available.
    renderableUtilityUnits = utilityHelper.ensureCurrentUnitExists(
      renderableUtilityUnits,
      utilityType,
      utilityUnit
    );
    return renderableUtilityUnits;
  };

  getRenderableInitialUtility = (initialUtility, rendableUtilityUnits) => {
    const renderableUtility = { ...initialUtility };
    renderableUtility.startReading = formatNumber(initialUtility.startReading, 4, true);
    renderableUtility.endReading = formatNumber(initialUtility.endReading, 4, true);
    renderableUtility.cost = formatNumber(initialUtility.cost, 2);
    // existing utility type and unit are string but form field values needs to be integer equivalent
    renderableUtility.unit = utilityHelper.utilityUnitEnumValFromKey(
      initialUtility.unit,
      initialUtility.type,
      rendableUtilityUnits
    );
    renderableUtility.type = utilityHelper.utilityTypeEnumValFromKey(initialUtility.type);

    return renderableUtility;
  };

  render() {
    const { closeDialog, isOpen, isNew, initialUtility } = this.props;
    const renderableUtilityUnits = this.getRenderableUtilityUnits(
      initialUtility.type,
      initialUtility.unit
    );
    const renderableUtility = this.getRenderableInitialUtility(
      initialUtility,
      renderableUtilityUnits
    );
    const enableReinitialize = !isNew;
    const submitDisabled = checkErrors(this.props.errors);

    return (
      <ModalDialog
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <UtilityEditForm
          onSubmit={this.onSubmit}
          utilityUnits={renderableUtilityUnits}
          initialValues={{ ...renderableUtility }}
          enableReinitialize={enableReinitialize}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('utilities');

const mapStateToProps = (state, ownProps) => {
  const initialUtility = ownProps.isNew ? initialFormValues : selectUtilityInEdit(state);
  const utilityUnits = selectUtilityUnits(state);
  const errors = getErrors(state);
  return {
    utilityUnits,
    initialUtility,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions, closeDialog: hideDialog, triggerSubmit: submit },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertUtilityDialog);
