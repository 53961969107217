import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose } from 'redux';
import tabletFinger from '../../../images/tablet_finger.svg';

const ClaimAddedConfirmation = ({ history, match, intl }) => {
  const { site, claim } = match.params;

  return (
    <div className="container-fluid">
      <section className="page-main-content">
        <div className="claim-success-content">
          <img src={tabletFinger} alt="Tablet img" />
          <div className="claim-success-instructions">
            <h1>{intl.formatMessage({ id: 'thankYou' })}</h1>
            {intl.formatMessage({ id: 'returnTablet' })}
          </div>
          <Button
            color="primary"
            onClick={() => {
              history.push(`/${site}/claims/${claim}/edit`);
            }}
          >
            {intl.formatMessage({ id: 'continue' })} &gt;
          </Button>
        </div>
      </section>
    </div>
  );
};

ClaimAddedConfirmation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      site: PropTypes.string.isRequired,
      claim: PropTypes.string.isRequired,
    }).isRequired,
  }),
  intl: PropTypes.shape({}).isRequired,
};

export default compose(withRouter, injectIntl)(ClaimAddedConfirmation);
