import { PropTypes } from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TaskCompletionStatus } from '../types';

const TodaysTasksForm = ({ task, status, employeeName }) => {
  const taskAmount = Object.keys(task.taskIds).length;
  return (
    <div>
      <div className="form-group">
        <div>{employeeName && <label>Team Member: {employeeName}</label>}</div>
        <label>
          Are You Sure You Want To{' '}
          {status === TaskCompletionStatus.Snoozed ? 'Snooze ' : 'Complete '}
          {taskAmount}
          {taskAmount > 1 ? ' tasks' : ' task'}?
        </label>
      </div>
    </div>
  );
};

TodaysTasksForm.propTypes = {
  task: PropTypes.shape({}).isRequired,
  status: PropTypes.number.isRequired,
};

export default reduxForm({ form: 'todaysTasksForm' })(TodaysTasksForm);
