import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { create, map, sum } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import DataTable from '../../../core/components/DataTable';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';
import { setAnalyticsPage } from '../../analytics/actions';
import { surveyResponseColors } from '../helpers';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  fromDate: any;
  untilDate: any;
  surveyResults: { total: number; answers: any };
}

export const CancelationSurveyKpi = (props: Props): JSX.Element => {
  const [chartInfo, setChartInfo] = useState({ data: { datasets: [] }, options: {} });
  const [chartView, setChartView] = useState(true);
  const loadingApiCall = useSelector((state) => getApiStatus(state, 'GET_SURVEY_RESULTS'));
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const pushToAnalytics = () => {
    dispatch(setAnalyticsPage('Single-Membership'));
    history.push('analytics');
  };

  const labels = props?.surveyResults ? Object.keys(props?.surveyResults.answers).slice(0, 5) : [];
  const createChartData = () => {
    const data: any = {
      labels: labels,
      datasets: props?.surveyResults
        ? [
            {
              label: intl.formatMessage({ id: 'responses' }),
              data: map(props.surveyResults.answers, 'count').slice(0, 5),
              backgroundColor: labels.map((label) => surveyResponseColors(label)[0]),
              borderColor: labels.map((label) => surveyResponseColors(label)[1]),
              borderWidth: 1,
            },
          ]
        : [],
    };
    const options: any = {
      plugins: {
        legend: {
          title: {
            display: true,
            text:
              props?.surveyResults && map(props.surveyResults.answers, 'count').length
                ? intl.formatMessage(
                    {
                      id: 'topResponses',
                    },
                    { number: map(props.surveyResults.answers, 'count').slice(0, 5).length }
                  )
                : intl.formatMessage({ id: 'noResponses' }),
          },
          display: true,
          position: 'left',
        },
      },
      responsive: false,
    };

    setChartInfo({
      data,
      options,
    });
  };

  const initializeDataTable = () => {
    const headers = [{ name: 'Responses' }, { name: 'Count' }];
    const data: any = [];
    const responseCount: number[] = [];
    for (const prop in props.surveyResults.answers) {
      data.push({ _id: prop, responses: prop, count: props.surveyResults.answers[prop].count });
      responseCount.push(props.surveyResults.answers[prop].count);
    }

    let noResponseCount = props.surveyResults.total;
    if (sum(responseCount) > 0) {
      noResponseCount = props.surveyResults.total - sum(responseCount);
    }

    data.push({
      _id: 'noResponse',
      responses: 'No Response',
      count: noResponseCount,
    });

    return {
      data,
      headers,
    };
  };

  const displaySurveyTotal = () => {
    if (props.surveyResults) {
      return props.surveyResults.total;
    }

    return intl.formatMessage({ id: 'noCounts' });
  };

  const handleChartDraw = (): JSX.Element => {
    if (props?.surveyResults) {
      if (chartView && Object.keys(props.surveyResults.answers).length) {
        return (
          <div className="survey-chart">
            <Pie data={chartInfo.data} options={chartInfo.options} />
          </div>
        );
      }
      return <DataTable {...initializeDataTable()} />;
    }
    return <div />;
  };

  useEffect(createChartData, [props.surveyResults]);

  return (
    <section className="container-fluid overview-chart-card insta-club-kpi-body">
      <div className="chart-title-section">
        <div className="chart-title">{intl.formatMessage({ id: 'guestCancel' })}</div>
        <button className="button small" onClick={() => setChartView(!chartView)}>
          {chartView
            ? intl.formatMessage({ id: 'tableView' })
            : intl.formatMessage({ id: 'chartView' })}
        </button>
      </div>
      {!loadingApiCall ? (
        <div>
          <div data-testid="cancellation-total" className="claims-kpi-total-count">
            {displaySurveyTotal()}
          </div>
          {handleChartDraw()}
          <div className="button-with-loader-overview-container">
            <ButtonWithLoader style="button full-width bold gradient" handleClick={pushToAnalytics}>
              <React.Fragment>{intl.formatMessage({ id: 'cancelSurveyResults' })}</React.Fragment>
            </ButtonWithLoader>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </section>
  );
};
