import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import HourlyWashCountsTable from './HourlyWashCountsTable';

const HourlyWashCountsDataGetter = (props) => {
  const siteExtId = useSelector(selectSiteExtId),
    multiSites = useSelector(selectMultiSiteSelection);
  const getHourlyWashCounts = () => {
    const { fetchHourlyWashCounts, fromDate, untilDate } = props;
    fetchHourlyWashCounts(fromDate, untilDate);
  };
  useEffect(getHourlyWashCounts, [props.fromDate, props.untilDate, siteExtId, multiSites]);

  return (
    <HourlyWashCountsTable {...props} intl={props.intl} hourlyWashCounts={props.hourlyWashCounts} />
  );
};

HourlyWashCountsDataGetter.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
  fetchHourlyWashCounts: PropTypes.func.isRequired,
  hourlyWashCounts: PropTypes.shape({}).isRequired,
};

export default HourlyWashCountsDataGetter;
