const GoogleAnalytics = (id) => {
  const gTagUrl = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  const elements = document.getElementsByTagName('script');

  let exists = false;
  for (const item of elements) {
    if (item.src === gTagUrl) {
      exists = true;
      break;
    }
  }

  function gtag() {
    // eslint-disable-next-line
    window.dataLayer.push(arguments);
  }

  if (!exists) {
    const script = document.createElement('script');
    const head = document.head;

    script.src = gTagUrl;
    script.type = 'text/javascript';
    script.async = true;
    head.insertBefore(script, head.firstChild);

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', id);
  }

  return { gtag };
};

export default GoogleAnalytics;
