import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import useDamageClaimOptions from '../../reclamations/hooks/useDamageClaimOptions';
import RepairDataForm from '../components/RepairDataForm';
import { RepairItemType } from '../models';

export const RepairDataDialog = (props) => {
  const { closeDialog, isOpen, text, carPartId, onSubmit, triggerSubmit } = props;

  const damageClaimOptions = useDamageClaimOptions(false);
  const onReady = () => {
    triggerSubmit('repairDataForm');
  };

  const _onSubmit = (data) => {
    const repairData = {
      ...data,
      price: data.price.toString(),
      total: (data.price * data.quantity).toString(),
    };

    onSubmit(repairData);
  };

  const submitDisabled = checkErrors(props.errors);

  const initialValues = () => {
    const output = {
      carPart: null,
      type: RepairItemType.Item,
      currency: 'usd',
    };
    const part = damageClaimOptions.find((option) => option.id === carPartId);
    if (part) {
      output.carPart = part.value;
    }
    return output;
  };

  return (
    <ModalDialog
      title={text}
      onReady={onReady}
      isOpen={isOpen}
      close={closeDialog}
      disabled={submitDisabled}
    >
      <RepairDataForm
        initialValues={initialValues()}
        damageClaimOptions={damageClaimOptions}
        onSubmit={_onSubmit}
      />
    </ModalDialog>
  );
};
const getErrors = getFormSyncErrors('repairDataForm');

RepairDataDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  triggerSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
  carPartId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({ errors: getErrors(state) });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RepairDataDialog);
