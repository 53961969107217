import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Parts extends Component {
  static propTypes = {
    task: PropTypes.shape({
      inventory: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  getParts = () => {
    return this.props.task.inventory.map((part, i) => (
      // eslint-disable-next-line
      <li className="part-list-item" key={i} onClick={() => this.goToInventoryDetail(part.id)}>
        {part.name}
        <span style={{ fontSize: '12px' }}> - {part.number}</span>
      </li>
    ));
  };

  goToInventoryDetail = (id) => {
    const site = this.props.match.params.site;
    const path = `/${site}/inventory/${id}/`;
    this.props.history.push(path);
  };

  render() {
    return (
      <div className="parts-container">
        <ol className="part-list">{this.getParts()}</ol>
      </div>
    );
  }
}

export default withRouter(Parts);
