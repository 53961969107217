import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkDisabled } from '../../../core/utils';
import { fetchOpenWorkLogs } from '../actions';
import CloseWorkLogForm from '../components/CloseWorkLogForm';

class CloseWorkLogDialog extends Component {
  static propTypes = {
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    saveWorkLog: PropTypes.func.isRequired,
    openWorkLogs: PropTypes.arrayOf(PropTypes.object),
    fetchOpenWorkLogs: PropTypes.func.isRequired,
    endTime: PropTypes.shape({}),
    currentLog: PropTypes.shape({
      id: PropTypes.number,
    }),
    initialLog: PropTypes.shape({
      end: PropTypes.object,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTime: moment(),
    };
  }

  componentDidMount() {
    this.props.fetchOpenWorkLogs();
    this.interval = setInterval(() => this.setState({ currentTime: moment() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onReady = () => {
    this.props.triggerSubmit('closeWorkLogForm');
  };

  /*
  onSubmit needed here because we don't have access to relevant work log until
  it is selected in the form.
  */
  onSubmit = (values) => {
    const { openWorkLogs } = this.props;
    let closedWorkLog = openWorkLogs.filter((w) => values.id === w.id)[0];
    closedWorkLog = { ...closedWorkLog, end: values.end };
    this.props.saveWorkLog(closedWorkLog);
  };

  render() {
    // TODO maybe use hoc which handles isOpen, closeDialog?
    const { closeDialog, isOpen, openWorkLogs, endTime, initialLog, currentLog } = this.props;

    const disabledCases = [!currentLog, !endTime];

    return (
      <ModalDialog
        title="Team Member Clock Out"
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={checkDisabled(disabledCases)}
      >
        <CloseWorkLogForm
          onSubmit={this.onSubmit}
          openWorkLogs={openWorkLogs}
          currentLog={currentLog}
          currentTime={this.state.currentTime}
          initialValues={initialLog}
        />
      </ModalDialog>
    );
  }
}

const selector = formValueSelector('closeWorkLogForm');

const mapStateToProps = (state, ownProps) => {
  const { openWorkLogs, openLog, isNew } = ownProps;
  const id = selector(state, 'id');
  const currentLog = openWorkLogs.filter((w) => w.id === id)[0];
  const endTime = selector(state, 'end');
  const initialLog = isNew ? { end: moment() } : { ...openLog, end: moment() };

  return {
    currentLog,
    endTime,
    initialLog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
      fetchOpenWorkLogs,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseWorkLogDialog);
