import { PropTypes } from 'prop-types';
import React from 'react';

const Description = ({ text }) => <div>{text}</div>;

Description.propTypes = {
  text: PropTypes.string,
};

export default Description;
