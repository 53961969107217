import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import DataTable from '../../../core/components/DataTable';
import { getCarPartReports } from '../actions';
import { formatReportTableData } from '../helpers';
import { ReportType } from '../models';
import { selectCarPartReports, selectFromDate, selectUntilDate } from '../reducer';
import { DamageAreaReportPropTypes } from '../types';

export class DamageAreaReport extends PureComponent {
  static propTypes = DamageAreaReportPropTypes;

  componentDidMount = () => {
    this.props.getCarPartReports();
  };

  componentDidUpdate = (prevProps) => {
    const datesChanged =
      !prevProps.fromDate.isSame(this.props.fromDate) ||
      !prevProps.untilDate.isSame(this.props.untilDate);

    if (datesChanged) {
      this.props.getCarPartReports({ query: this.props.dateQuery });
    }
  };

  getColGroup = () => (
    <colgroup>
      <col className="first-column" />
    </colgroup>
  );

  reportTableDataGetter = () => {
    const { intl, carPartReports } = this.props;

    return formatReportTableData(ReportType.ClaimsByDamageArea, intl.formatMessage, carPartReports);
  };

  initializeDatatable = () => ({
    ...this.reportTableDataGetter(),
    addedClass: 'report-table',
    colGroup: this.getColGroup(),
  });

  render = () => <DataTable {...this.initializeDatatable()} />;
}

const mapStateToProps = (state) => {
  const [fromDate, untilDate] = [selectFromDate(state), selectUntilDate(state)];
  return {
    carPartReports: selectCarPartReports(state),
    fromDate,
    untilDate,
    dateQuery: {
      fromDate: fromDate.format('YYYY-MM-DD'),
      untilDate: untilDate.format('YYYY-MM-DD'),
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCarPartReports,
    },
    dispatch
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(DamageAreaReport);
