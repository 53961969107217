import PropTypes from 'prop-types';
import React from 'react';

const TableActionButtonsCell = (props) => {
  const { rowData, mixIns } = props;

  const generateButtons = () =>
    mixIns.buttons.map((buttonProps, i) => {
      const hasIcons = !!buttonProps.icon;
      return (
        <button
          key={i}
          data-testid={`table-button ${buttonProps.icon}`}
          className={hasIcons ? 'dr-button' : buttonProps.class}
          onClick={(e) => buttonProps.buttonAction(e, rowData)}
          disabled={buttonProps.disabled}
        >
          {hasIcons && buttonProps.icon ? (
            <i className={`icon ${buttonProps.icon}`} />
          ) : (
            buttonProps.title
          )}
        </button>
      );
    });

  return <span className="dr-actions">{generateButtons()}</span>;
};

TableActionButtonsCell.defaultProps = {
  mixIns: {
    buttons: [],
  },
};

TableActionButtonsCell.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
  mixIns: PropTypes.shape({
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        buttonAction: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        title: PropTypes.string,
        class: PropTypes.string,
      })
    ),
  }),
};

export default TableActionButtonsCell;
