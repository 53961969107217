import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import Photos from '../../../core/components/Photos';
import Videos from '../../../core/components/Videos';
import { addFile, deleteFile } from '../../reclamations/actions';
import Description from './Description';
import Notes from './Notes';

const styles = {
  tab: {
    color: '#292b2c',
  },
};

export class RewashDetailContent extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    showDialog: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    reclamation: PropTypes.shape({
      id: PropTypes.number,
      siteId: PropTypes.number,
      description: PropTypes.string,
      files: PropTypes.any,
      notes: PropTypes.any,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      showComponent: 'Description',
    };
  }

  getComponent = () => {
    return {
      Description: <Description text={this.props.reclamation.description} />,
      Photos: (
        <Photos
          photos={this.getFiles('image')}
          deleteFile={this.deleteFile}
          addFile={this.addFile}
        />
      ),
      Videos: (
        <Videos
          videos={this.getFiles('video')}
          deleteFile={this.deleteFile}
          addFile={this.addFile}
        />
      ),
      Notes: <Notes reclamation={this.props.reclamation} />,
    }[this.state.showComponent];
  };

  getTabs = () =>
    ['Description', 'Photos', 'Videos', 'Notes'].map((link) => (
      <li className="nav-item" key={link}>
        <button
          className={`nav-link${(this.state.showComponent === link && ' active') || ''}`}
          style={styles.tab}
          onClick={() => this.showComponent(link)}
        >
          {this.props.intl.formatMessage({ id: link.toLowerCase() })}
        </button>
      </li>
    ));

  getFiles = (type) => {
    const isType = new RegExp(type);
    return this.props.reclamation.files
      ? this.props.reclamation.files.filter(
          (file) => isType.test(file.mimeType) && !file.archivedAt
        )
      : [];
  };

  addFile = (formData) => {
    const data = {
      siteId: this.props.reclamation.siteId,
      reclamationId: this.props.reclamation.id,
      file: formData,
    };

    this.props.addFile(data);
  };

  deleteFile = (file) => {
    const confirmProps = {
      onReady: this.props.deleteFile.bind(null, {
        fileId: file.id,
        reclamationId: this.props.reclamation.id,
      }),
      text: this.props.intl.formatMessage(
        { id: 'confirmFileArchive' },
        { fileName: file.originalName }
      ),
      title: this.props.intl.formatMessage({ id: 'archiveFile' }),
    };
    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  showComponent = (component) => {
    this.setState({ showComponent: component });
  };

  render() {
    return (
      <div data-testid="rewashDetailContent" className="content-container">
        <ul className="nav nav-tabs">{this.getTabs()}</ul>
        <div className="content">{this.getComponent()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFile,
      deleteFile,
      showDialog,
    },
    dispatch
  );
};

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(RewashDetailContent);
