import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { fieldRequired, requiredSelect } from '../../../core/formValidation';
import { formatTimeElapsed } from '../../../core/utils';

const CloseWorkLogForm = ({ openWorkLogs, currentLog, currentTime, setTime }) => {
  const workLogOptions = openWorkLogs.map((w) => {
    const employeeName = `${w.employee.lastName}, ${w.employee.firstName}`;
    return (
      <option key={w.id} value={w.id}>
        {employeeName}
      </option>
    );
  });

  const logTime = (log) => {
    const startTime = moment(log.start);
    const ms = currentTime.diff(startTime);
    const duration = moment.duration(ms);

    return {
      startDay: startTime.format('MM/DD/YYYY'),
      startTime: startTime.format('hh:mm A'),
      timeElapsed: formatTimeElapsed(duration),
    };
  };

  return (
    <div className="form-group">
      <div className="form-group">
        <label htmlFor="workLog">Team Members Currently Clocked In</label>
        <Field
          id="workLog"
          name="id"
          component={requiredSelect}
          className="form-control"
          parse={(value) => value && Number(value, 10)}
          validate={[fieldRequired]}
        >
          <option value="">Select Open Log...</option>
          {workLogOptions}
        </Field>
        {currentLog && (
          <div>
            <div style={{ fontSize: '24px' }} className="mt-1">
              {logTime(currentLog).timeElapsed}
            </div>
            <div className="mt-1">
              You clocked in on {logTime(currentLog).startDay} at {logTime(currentLog).startTime}
            </div>
          </div>
        )}
        {currentLog && (
          <div className="form-check mt-3">
            <label className="form-check-label">
              <Field
                name="setTime"
                type="checkbox"
                component="input"
                className="form-check-input"
              />{' '}
              Set clock out time manually
            </label>
          </div>
        )}
        {currentLog && setTime && (
          <div className="mt-2">
            <label htmlFor="end" className="mr-2">
              Clock Out Time
            </label>
            <Field
              name="end"
              id="end"
              component={DateTimePicker}
              placeholderText="Select Date/Time"
              showToday={false}
              required
            />
          </div>
        )}
      </div>
    </div>
  );
};

CloseWorkLogForm.propTypes = {
  openWorkLogs: PropTypes.arrayOf(PropTypes.object),
  currentLog: PropTypes.shape({ start: PropTypes.string }),
  currentTime: PropTypes.shape({}),
  setTime: PropTypes.bool,
};

const formSelector = formValueSelector('closeWorkLogForm');

const mapStateToProps = (state) => {
  const setTime = formSelector(state, 'setTime');
  return { setTime };
};

const enhance = compose(connect(mapStateToProps, null), reduxForm({ form: 'closeWorkLogForm' }));

export default enhance(CloseWorkLogForm);
