import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { hideDialog } from '../actions';
import ModalDialog from './Modal';
import NoteForm from './NoteForm';

class NoteDialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onReady: PropTypes.func.isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('addNote');
  };

  onSubmit = (values) => {
    const data = {
      note: values,
    };

    this.props.onReady(data);
  };

  render() {
    const { closeDialog, isOpen, title } = this.props;
    return (
      <ModalDialog title={title} onReady={this.onReady} isOpen={isOpen} close={closeDialog}>
        <NoteForm onSubmit={this.onSubmit} />
      </ModalDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(NoteDialog);
