import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../actions';
import { selectEmployees, selectPage, selectPageSize, selectTotal } from '../reducer';

const styles = {
  tableHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export class Employees extends Component {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.object),
    getEmployee: PropTypes.func.isRequired,
    saveEmployee: PropTypes.func.isRequired,
    addTeamMember: PropTypes.func.isRequired,
    archiveEmployee: PropTypes.func.isRequired,
    fetchEmployees: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    intl: PropTypes.shape({}).isRequired,
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  };

  componentDidMount() {
    this.props.fetchEmployees();
  }

  componentDidUpdate(prevProps) {
    const newSite = prevProps.primarySite !== this.props.primarySite;
    const newMultiSiteSelection = prevProps.multiSite !== this.props.multiSite;

    if (newSite || newMultiSiteSelection) {
      this.props.fetchEmployees();
    }
  }

  getEmployeesPage = (page) => {
    this.props.fetchEmployees({ data: { query: { page } } });
  };

  getPaginationInfo() {
    return {
      total: this.props.total,
      page: this.props.page,
      pageSize: this.props.pageSize,
      goToPage: this.getEmployeesPage,
    };
  }

  editEmployee = (event, { _id }) => {
    this.props.getEmployee({
      data: { id: _id },
    });

    const dialogProps = {
      title: 'Edit Employee',
      onSubmit: this.props.saveEmployee,
      isNew: false,
    };

    this.props.showDialog('UPSERT_EMPLOYEE', dialogProps);
  };

  archiveEmployee = (event, { _id, lastName, firstName }) => {
    const confirmProps = {
      onReady: this.props.archiveEmployee.bind(null, { data: { body: { id: _id } } }),
      text: `Are you sure you want to archive employee: "${firstName} ${lastName}" ?`,
      title: 'Archive Employee',
    };
    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  initializeDatatable = (employees) => {
    const { intl } = this.props;
    const data = employees
      .sort((a, b) => {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((employee) => {
        return {
          _id: employee.id,
          lastName: employee.lastName,
          firstName: employee.firstName,
          email: employee.email || '',
          phoneNumber: employee.phoneNumber || '',
          position: employee.position || '',
        };
      });

    const headers = [
      { name: intl.formatMessage({ id: 'lastName' }) },
      { name: intl.formatMessage({ id: 'firstName' }) },
      { name: intl.formatMessage({ id: 'email' }) },
      { name: intl.formatMessage({ id: 'phoneNumber' }) },
      { name: intl.formatMessage({ id: 'position' }) },
      {
        name: intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-trash', buttonAction: this.archiveEmployee },
              { icon: 'icon-edit', buttonAction: this.editEmployee },
            ],
          },
        },
      },
    ];

    return {
      data,
      headers,
    };
  };

  render() {
    const addDialogProps = {
      title: 'Add Employee',
      onSubmit: this.props.addTeamMember,
      intl: this.props.intl,
      isNew: true,
    };

    return (
      <section className="page-main-content">
        <div className="table-buttons">
          <div></div>
          <PageActions
            actionFunction={this.props.showDialog}
            modalProps={addDialogProps}
            actions={[
              {
                dialogType: 'UPSERT_EMPLOYEE',
                dialogProps: addDialogProps,
                text: 'Add Team Member',
                permissionKey: addPermissionRefs.team,
              },
            ]}
          />
        </div>
        <DataTable
          paginationInfo={this.getPaginationInfo()}
          scrolling
          {...this.initializeDatatable(this.props.employees)}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: selectEmployees(state),
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
    primarySite: selectSiteExtId(state),
    multiSite: selectMultiSiteSelection(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, showDialog }, dispatch);
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(Employees);
