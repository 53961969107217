import React from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasTasksEnabledRedir } from '../core/auth';
import TaskDetail from '../views/taskDetail/containers/TaskDetail';
import EssentialTasks from '../views/settings/components/EssentialTasks';
import Tasks from '../views/tasks/containers/Tasks';
import TasksViews from '../views/tasks/containers/TasksViews';
import TodaysTasks from '../views/tasks/containers/TodaysTasks';

const TasksRoute = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch('/:site/tasks/:task');

  return (
    <Switch>
      <Route path="/:site/tasks/">
        <TasksViews />
      </Route>
    </Switch>
  );
};

export default userHasTasksEnabledRedir(TasksRoute);
