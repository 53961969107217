import * as api from '../api';

export const fetchCoreCounts = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      signal,
      ..._args.options,
    },
    data: { query: { ...query, combined: true } },
  };
  return api.callGet('/integrations/daily-numbers/daily-numbers', args);
};
export const fetchInstaClubCounts = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      signal,
      ..._args.options,
    },
    data: { query: { ...query, combined: true } },
  };
  return api.callGet('/integrations/daily-numbers/plan-purchases', args);
};
export const fetchRevenueSummary = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      ..._args.options,
    },
    data: { query: { ...query, combined: true } },
  };
  return api.callGet('/integrations/daily-numbers/revenue-summary', args);
};
export const fetchRevenueBestDaySummary = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      ..._args.options,
    },
    data: { query: { ...query, combined: true } },
  };
  return api.callGet('/integrations/daily-numbers/revenue-best-day-summary', args);
};
export const fetchCorePayAsYouGoCounts = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      signal,
      ..._args.options,
    },
    data: { query: { ...query, combined: true } },
  };
  return api.callGet('/integrations/daily-numbers/pay-as-you-go-counts', args);
};

export const fetchCoreFleetPayAsYouGoCounts = (args) => {
  const newArgs = {
    ...args,
    options: {
      ...args.options,
      signal: args.data.signal,
    },
    data: {
      query: {
        ...args.data.query,
        combined: true,
      },
    },
  };

  return api.callGet('/integrations/daily-numbers/fleet-pay-as-you-go-counts', newArgs);
};

export const fetchCoreFleetPayAsYouGoDiscounts = (args) => {
  const newArgs = {
    ...args,
    options: {
      ...args.options,
      signal: args.data.signal,
    },
    data: {
      query: {
        ...args.data.query,
        combined: true,
      },
    },
  };

  return api.callGet('/integrations/daily-numbers/fleet-pay-as-you-go-discounts', newArgs);
};

export const fetchCoreDiscounts = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      signal,
      ..._args.options,
    },
    data: { query: { ...query } },
  };
  return api.callGet('/integrations/daily-numbers/discounts', args);
};

export const fetchCorePayAsYouGoDiscounts = (_args) => {
  const {
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: {
      signal,
      ..._args.options,
    },
    data: { query: { ...query } },
  };

  return api.callGet('/integrations/daily-numbers/pay-as-you-go-discounts', args);
};

export const fetchCoreRefunds = (args) => {
  const options = {
    signal: args.data.signal,
    ...args.options,
  };

  const formattedArgs = {
    ...args,
    options,
    data: {
      query: args.data.query,
    },
  };

  return api.callGet('/integrations/daily-numbers/refunds', formattedArgs);
};

export const fetchWashCounts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/wash-counts`, args);
};

export const fetchDailyWashCounts = (args) => {
  return api.callGet(`/manager/sites/daily-wash-counts`, args);
};

export const fetchOverviewWashCounts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/overview-wash-counts`, args);
};

export const addWashCounts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/wash-counts`, args);
};

export const adjustWashCount = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/adjustment-logs`, args);
};

export const fetchAdjustments = (args) => {
  const {
    options: { siteId },
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet(`/manager/sites/${siteId}/wash-counts/adjustments`, _args);
};

export const fetchNotes = (args) => {
  const {
    options: { siteId },
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet(`/manager/sites/${siteId}/wash-counts/adjustments/current-note`, _args);
};

export const fetchTopPerformingSingleWashes = (args) => {
  const {
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet('/integrations/daily-numbers/top-performing-single-washes', _args);
};

export const fetchTopPerformingSubscriptionWashes = (args) => {
  const {
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet('/integrations/daily-numbers/top-performing-subscription-washes', _args);
};

export const fetchActiveSubscriptions = (args) => {
  const {
    data: { extId },
  } = args;
  return api.callGet(
    `/statistics/sites/${extId}/active-subscription-counts?type=Subscription`,
    args
  );
};

export const fetchHourlyWashCounts = (args) => {
  const {
    options: { siteId },
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet(`/manager/sites/${siteId}/hourly-wash-counts`, _args);
};

export const fetchDailyWindowSalesGained = (args) => {
  const {
    options: { siteId },
  } = args;

  return api.callGet(`/statistics/sites/${siteId}/new-window-sales-count`, args);
};

export const fetchCloseSites = (args) => {
  const {
    options: { siteId },
    data: { signal },
  } = args;
  const _args = {
    ...args,
    options: { signal, ...args.options },
  };
  return api.callGet(`/manager/sites/distance/${siteId}`, _args);
};
