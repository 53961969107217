import '../../../../../styles/permission-name.scss';
import { UserGroupPermission } from '../../../../employees/models';

interface Props {
  permission: UserGroupPermission;
  handleOnClick: (permission: UserGroupPermission) => void;
}

const PermissionName = ({ permission, handleOnClick }: Props): JSX.Element => {
  return (
    <div className="permission-name" onClick={() => handleOnClick(permission)}>
      <span>{permission.displayName}</span>
      <div className="icon-container">
        {permission.description && (
          <i data-testid="help-icon" className="icon icon-help-with-circle" />
        )}
      </div>
    </div>
  );
};

export default PermissionName;
