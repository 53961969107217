import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../providers/AppProvider';
import Fade from '../Fade';
import DateRangeMenu from './DateRangeMenu';
import useDateOperations from './useDateOperations';
import useQuickSelectDateObject from './useQuickSelectDateObject';

const DateRangeSelector = (props) => {
  const { onSubmit, disabled, isLoading, includeCustomSelect = true } = props;
  const tz = useAppContext().timeZone;
  const [dates, setDates] = useState(props.dates);
  const dateOperations = useDateOperations(dates);
  const [currentOperation, setCurrentOperation] = useState(dateOperations.IsEqualTo);
  const [hide, setHide] = useState(true);
  const [activeApply, setActiveApply] = useState(false);
  const [activeQuickSelectDateObject, setActiveQuickSelectDateObject] = useState(null);
  const [displayDate, setDisplayDate] = useState(currentOperation.displayDate);
  const quickSelectDateObject = useQuickSelectDateObject();

  useEffect(() => {
    const formatedDates = props.dates.map((d) => {
      if (typeof d !== 'string') {
        return moment(d).format('YYYY-MM-DD');
      }

      return d;
    });

    setDates(formatedDates);
  }, [props.dates]);

  useEffect(() => {
    const refreshedCurrentOperation = dateOperations[currentOperation.id];
    setCurrentOperation(refreshedCurrentOperation);
  }, [dateOperations]);

  useEffect(() => {
    const dateObject = find(quickSelectDateObject, (object) => {
      const { from, until } = object.dateRange;
      const formatUntil = until
        ? moment(until).format('YYYY-MM-DD')
        : moment(dateOperations[object.dateOperation].until).format('YYYY-MM-DD');

      const compareRange = [moment(from).format('YYYY-MM-DD'), formatUntil];
      const compareDates = dates.length === 1 ? [dates[0], dates[0]] : [...dates];

      return isEqual(compareRange, compareDates);
    });

    if (dateObject) {
      setActiveQuickSelectDateObject(dateObject.id);
    }
  }, [dates, quickSelectDateObject, dateOperations]);

  const handleSubmit = () => {
    onSubmit({
      from: moment.tz(dates[0], tz).startOf('day'),
      until: currentOperation.until,
    });
  };

  const handleShowHide = () => {
    setHide(!hide);
  };

  const handleQuickSelectClick = (quickDateObject) => {
    const newDates = Object.values(quickDateObject.dateRange).map((val) =>
      moment(val).format('YYYY-MM-DD')
    );
    setDates(newDates);
    setCurrentOperation(dateOperations[quickDateObject.dateOperation]);
    setActiveApply(true);
  };

  const handleDatePickerChange = (newDateOperationId, newDates) => {
    const workingDates = newDates;
    const newOperation = dateOperations[newDateOperationId];
    setDates(workingDates);
    setCurrentOperation(newOperation);
    setActiveApply(true);
    setActiveQuickSelectDateObject(null);
  };

  const handleApply = () => {
    handleSubmit();
    setActiveApply(false);
    setDisplayDate(currentOperation.displayDate);
    handleShowHide();
  };

  return (
    <div className="drs-container">
      <div>
        <button
          className="btn btn-primary drs-button-override drs-button-shadow"
          onClick={handleShowHide}
        >
          <i className="icon icon-calendar drs-mr-7" />
          {currentOperation.displayDate}
        </button>
        <Fade hide={hide}>
          <DateRangeMenu
            dates={dates}
            currentOperation={currentOperation}
            activeQuickDateObject={activeQuickSelectDateObject}
            handleDatePickerChange={handleDatePickerChange}
            handleQuickSelectClick={handleQuickSelectClick}
            handleApply={handleApply}
            handleShowHide={handleShowHide}
            activeApply={activeApply}
            isLoading={isLoading}
            disabled={disabled}
            includeCustomSelect={includeCustomSelect}
            additionalOptions={props.additionalOptions}
            removeItems={props.removeItems}
          />
        </Fade>
      </div>
    </div>
  );
};

DateRangeSelector.defaultProps = {
  dates: [moment().format('YYYY-MM-DD')],
  disabled: false,
  isLoading: false,
};

DateRangeSelector.propTypes = {
  dates: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  includeCustomSelect: PropTypes.bool,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      from: PropTypes.shape({}),
      until: PropTypes.shape({}),
    })
  ),
  removeItems: PropTypes.arrayOf(PropTypes.string),
};

export default DateRangeSelector;
