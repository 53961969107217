import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { selectEmployees } from '../../employees/reducer';
import { clearTaskSelections } from '../actions';
import TodaysTasksForm from '../components/TodaysTasksForm';
import { TaskCompletionStatus } from '../types';

class TodaysTasksDialog extends Component {
  static propTypes = {
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    taskCompletion: PropTypes.shape({}),
    onReady: PropTypes.func.isRequired,
    clearTaskSelections: PropTypes.func.isRequired,
    currentEmployeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('todaysTasksForm');
  };

  onSubmit = () => {
    /* eslint-disable */
    const data = {
      employeeId: this.props.currentEmployeeId,
      taskIds: this.props.taskCompletion.taskIds,
      voidCallback: this.props.taskCompletion.voidCallback,
    };

    this.props.status === TaskCompletionStatus.Completed
      ? Object.assign(data, { notes: this.props.taskCompletion.notes })
      : data;

    this.props.clearTaskSelections();
    this.props.onReady(data);
  };

  getEmployeeName = () => {
    const { employees, currentEmployeeId } = this.props;
    const employee = employees.find((emp) => emp.id === currentEmployeeId);
    return employee ? `${employee.firstName} ${employee.lastName}` : '';
  };

  render() {
    const { closeDialog, isOpen, taskCompletion, title, status, currentEmployeeId } = this.props;
    return (
      <ModalDialog title={title} onReady={this.onReady} isOpen={isOpen} close={closeDialog}>
        <TodaysTasksForm
          onSubmit={this.onSubmit}
          employeeName={this.getEmployeeName()}
          enableReinitialize
          task={taskCompletion}
          status={status}
        />
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => ({ employees: selectEmployees(state) });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
      clearTaskSelections,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TodaysTasksDialog);
