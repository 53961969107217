import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { getDetergents } from '../actions';
import { selectDetergentReadings, selectDetergents } from '../reducer';
import DetergentTableContainer from './DetergentTableContainer';
import DetergentTabsContainer from './DetergentTabsContainer';

export const Detergents = ({ fetchDetergents, detergents, detergentReadings }) => {
  const siteId = useSelector(selectSiteExtId),
    multiSites = useSelector(selectMultiSiteSelection);

  useEffect(() => {
    fetchDetergents();
  }, [siteId, multiSites]);

  return (
    <div className="container-fluid">
      <section className="mt-3 mb-3">
        <DetergentTabsContainer detergents={detergents} detergentReadings={detergentReadings} />
      </section>
      <section className="page-main-content">
        <DetergentTableContainer />
      </section>
    </div>
  );
};

Detergents.propTypes = {
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergentReadings: PropTypes.shape({}),
  fetchDetergents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  detergents: selectDetergents(state),
  detergentReadings: selectDetergentReadings(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDetergents: getDetergents,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(Detergents);
