import moment from 'moment-timezone';

const from = sessionStorage.getItem('claimReportsFrom');
const until = sessionStorage.getItem('claimReportsUntil');

const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

export const initialState = {
  reportType: null,
  carPartReports: [],
  carMakeReports: [],
  carModelReports: {},
  fromDate: from ? fromDate : moment.tz(tz).subtract(90, 'days'),
  untilDate: until ? untilDate : moment.tz(tz).endOf('day'),
};

const getCarPartReports = (state, carPartReports) => {
  return {
    ...state,
    carPartReports,
  };
};

const getCarMakeReports = (state, carMakeReports) => {
  return {
    ...state,
    carMakeReports,
  };
};

const getCarModelReports = (state, carModelReports, meta) => {
  const reports = {
    ...state.carModelReports,
  };

  if (meta && meta.makeId) {
    reports[meta.makeId] = carModelReports;
  }

  return {
    ...state,
    carModelReports: reports,
  };
};

const setReportType = (state, reportType) => {
  return {
    ...state,
    reportType,
  };
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('claimReportsFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('claimReportsUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

const removeCarReports = (state) => {
  return {
    ...state,
    reportType: null,
    carPartReports: [],
    carMakeReports: [],
    carModelReports: {},
  };
};

const removeCarModelReport = (state, meta) => {
  const carModelReports = state.carModelReports;
  delete carModelReports[meta.makeId];

  return {
    ...state,
    carModelReports,
  };
};

export const claimReports = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REPORT_TYPE':
      return setReportType(state, action.reportType);
    case 'GET_CAR_PART_REPORTS_SUCCESS':
      return getCarPartReports(state, action.response.results);
    case 'GET_CAR_MAKE_REPORTS_SUCCESS':
      return getCarMakeReports(state, action.response.results);
    case 'GET_CAR_MODEL_REPORTS_SUCCESS':
      return getCarModelReports(state, action.response.results, action.meta);
    case 'REMOVE_CAR_REPORTS':
      return removeCarReports(state);
    case 'REMOVE_CAR_MODEL_REPORTS':
      return removeCarModelReport(state, action.meta);
    case 'SET_REPORT_FROM_DATE':
      return setFromDate(state, action.date);
    case 'SET_REPORT_UNTIL_DATE':
      return setUntilDate(state, action.date);
    default:
      return state;
  }
};

export const selectReportType = (state) => {
  return state.claimReports.reportType;
};

export const selectCarPartReports = (state) => {
  return state.claimReports.carPartReports;
};

export const selectCarMakeReports = (state) => {
  return state.claimReports.carMakeReports;
};

export const selectCarModelReports = (state) => {
  return state.claimReports.carModelReports;
};

export const selectFromDate = (state) => {
  return state.claimReports.fromDate;
};

export const selectUntilDate = (state) => {
  return state.claimReports.untilDate;
};
