import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { checkVideoUrl } from '../formValidation';
import { useIntl } from 'react-intl';

export const EmbeddedUrlCarousel = ({ urls, isVideo }: { urls: string[]; isVideo?: boolean }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const intl = useIntl();
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === urls.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? urls.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const carouselItems: { key: number; src: string }[] = [];
  const slides = urls.map((url: string, i: number) => {
    carouselItems.push({ key: i, src: url });
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={url}
      >
        <div>
          {isVideo ? (
            <iframe
              width={window.innerWidth / 2.8}
              height="315"
              src={checkVideoUrl(url)}
              allowFullScreen
            />
          ) : (
            <div className="media-item-container">
              <img className="media-item-photo" src={url} alt="url" />
            </div>
          )}
        </div>
      </CarouselItem>
    );
  });

  return (
    <div>
      <div>
        <button
          style={urls.length < 2 ? { display: 'none' } : {}}
          className="btn btn-secondary"
          onClick={next}
        >
          <i className="icon icon-arrow-left" />
        </button>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          slide={false}
          enableTouch={true}
          dark={true}
          interval={false}
          pause="hover"
        >
          {slides}
        </Carousel>
        <button
          style={urls.length < 2 ? { display: 'none' } : {}}
          className="btn btn-secondary"
          onClick={previous}
        >
          <i className="icon icon-arrow-right" />
        </button>
      </div>
    </div>
  );
};
