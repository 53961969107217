import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import DeclineIssueForm from '../components/DeclineIssueForm';

export class DeclineIssueDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    intl: PropTypes.shape({}).isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('declineIssueForm');
  };

  onSubmit = (data) => {
    this.props.onSubmit(data);
  };

  render() {
    const { closeDialog, isOpen, errors } = this.props;
    const intl = this.props.intl;
    const submitDisabled = checkErrors(errors);

    return (
      <ModalDialog
        title={intl.formatMessage({ id: 'declineIssueSiteTitle' })}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <DeclineIssueForm onSubmit={this.onSubmit} initialValues={{ note: '' }} />
      </ModalDialog>
    );
  }
}
const getErrors = getFormSyncErrors('declineIssueForm');

const mapStateToProps = (state) => {
  return {
    errors: getErrors(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(DeclineIssueDialog);
