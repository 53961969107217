import { PropTypes } from 'prop-types';
import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { requiredInput } from '../../../core/formValidation';

/**
 * validateNewProdcts included as a workaround for an issue with Redux Form not properly
 * resetting sync errors. When using field-level validation for new products, Redux Form
 * includes a sync error for new products when valid data is entered in each field, but then the
 * field is removed. The submit button is then disabled. Setting general validation for
 * new products seems to work.
 */

const validateNewProducts = (values) => {
  const errors = {};
  if (!values.newProducts) {
    return errors;
  }
  const newProdsArrayErrors = [];
  values.newProducts.forEach((prod, index) => {
    const newProdErrors = {};
    if (!prod || !prod.name) {
      newProdErrors.name = 'Required';
      newProdsArrayErrors[index] = newProdErrors;
    }
    if (!prod || !prod.count) {
      newProdErrors.count = 'Required';
      newProdsArrayErrors[index] = newProdErrors;
    }
  });
  if (newProdsArrayErrors.length) {
    errors.newProducts = newProdsArrayErrors;
  }
  return errors;
};

const newProductFields = ({ fields }) => {
  return (
    <div>
      {fields.map((product, index) => (
        <div className="form-inline mb-2" key={index}>
          <Field
            name={`${product}.name`}
            type="text"
            className="form-control"
            component={requiredInput}
            placeholder="Enter product name"
          />
          <div className="ml-1">
            <Field
              name={`${product}.count`}
              type="number"
              className="form-control"
              component={requiredInput}
              parse={(value) => value && Number(value, 10)}
            />
          </div>
          <button
            className="btn btn-secondary ml-1"
            style={{ display: 'inline-block' }}
            type="button"
            onClick={() => fields.remove(index)}
          >
            <i className="icon icon-cross" />
          </button>
        </div>
      ))}
    </div>
  );
};

newProductFields.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
  }),
};

const WashCountsForm = ({ products, washCounts, isNew }) => {
  const visibleProducts = isNew ? products : washCounts;
  const productFields = visibleProducts.map((product) => {
    return (
      <div className="form-inline mb-2" key={product.id}>
        <Field
          name={`product_${product.id}.name`}
          component="input"
          type="text"
          className="form-control"
          readOnly
        />
        <div className="ml-1">
          <Field
            name={`product_${product.id}.count`}
            component="input"
            type="number"
            className="form-control"
            parse={(value) => value && Number(value, 10)}
          />
        </div>
      </div>
    );
  });

  return (
    <form className="form-group">
      <label htmlFor="timestamp">Date</label>
      <span className="ml-2">
        <Field
          name="timestamp"
          id="timestamp"
          component={DateTimePicker}
          placeholderText="Enter Date"
        />
      </span>
      <div className="form-group mt-3 mb-0">
        <label>Enter Wash Counts:</label>
        {productFields}
      </div>
      <FieldArray name="newProducts" component={newProductFields} />
    </form>
  );
};

WashCountsForm.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  washCounts: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.string.isRequired,
    })
  ),
  isNew: PropTypes.bool,
};

export default reduxForm({
  form: 'washCounts',
  validate: validateNewProducts,
})(WashCountsForm);
