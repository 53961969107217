import { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, bindActionCreators } from 'redux';
import { hideDialog } from '../../../core/actions';
import TaskLinkingModal from './TaskLinkingModal';
import { FieldArray, reduxForm } from 'redux-form';
import Loader from '../../../core/components/Loader';
import { selectTasks } from '../../tasks/reducer';
import { LinkedTaskField } from '../../tasks/components/LinkedTaskField';

class TaskLinkingDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    linkTasks: PropTypes.func.isRequired,
  };

  render() {
    const title = 'Warning';
    return (
      <TaskLinkingModal
        intl={this.props.intl}
        className="enhanced-dialog medium padded"
        title={title}
        linkTasks={this.props.linkTasks}
        isOpen={this.props.isOpen}
        close={this.props.closeDialog}
        showFooter={true}
      >
        <>
          <h2>
            {this.props.intl.formatMessage({ id: 'linkTasks' })} to {this.props.task?.name}
          </h2>
          <p>{this.props.intl.formatMessage({ id: 'linkTasksInstructions' })}</p>
          <Suspense fallback={Loader}>
            <FieldArray
              name="linkedTasks"
              component={(fields) => (
                <LinkedTaskField intl={this.props.intl} fields={fields.fields} {...this.props} />
              )}
            />
          </Suspense>
        </>
      </TaskLinkingModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: selectTasks(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeDialog: hideDialog }, dispatch);
};

const enhance = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'taskLinks' })
);

export default enhance(TaskLinkingDialog);
