import * as api from '../api';

export const fetchOpenWorkLogs = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/employees/work-logs/open`, args);
};

export const fetchWorkLogs = (args) => {
  const {
    options: { siteId },
    data: { employeeId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/employees/${employeeId}/work-logs`, args);
};

export const postWorkLog = (args) => {
  const {
    options: { siteId },
    data: { employeeId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/employees/${employeeId}/work-logs`, args);
};

export const patchWorkLog = (args) => {
  const {
    options: { siteId },
    data: { employeeId, id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/employees/${employeeId}/work-logs/${id}`, args);
};
