import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import {
  errorInput,
  fieldRequired,
  isNumber,
  requiredDatePicker,
  requiredInput,
  requiredSelectWithExclusions,
} from '../../../core/formValidation';
import { ContainerSizes } from '../models';

const UpsertDetergentDataForm = () => {
  const intl = useIntl();
  const safeParseInt = (value: any) =>
    Number(value) === 0 || Number.isNaN(value) ? '' : Number(Number(value));
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor="volume">{intl.formatMessage({ id: 'containerSize' })}</label>
        <Field
          name="container"
          id="container"
          component={requiredSelectWithExclusions}
          exclusions={[-1]}
          validate={[fieldRequired]}
          className="form-control"
          parse={parseFloat}
        >
          <option value={-1} disabled>
            {intl.formatMessage({ id: 'selectContainer' })}
          </option>
          {ContainerSizes.map((size) => (
            <option key={`detergent-container-${size}`} value={size}>{`${size} gallons`}</option>
          ))}
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="quantityOfContainers">
          {intl.formatMessage({ id: 'quantityOfContainers' })}
        </label>
        <Field
          name="quantityOfContainers"
          id="quantityOfContainers"
          component={requiredInput}
          type="number"
          validate={[fieldRequired]}
          className="form-control"
          placeholder={intl.formatMessage({ id: 'enterQuantityOfContainers' })}
          parse={safeParseInt}
        />
      </div>
      <div className="form-group">
        <label htmlFor="totalPrice">{intl.formatMessage({ id: 'totalPrice' })}</label>
        <Field
          name="totalPrice"
          id="totalPrice"
          component={errorInput}
          validate={[fieldRequired, isNumber]}
          className="form-control"
          placeholder={intl.formatMessage({ id: 'enterPrice' })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="price">{intl.formatMessage({ id: 'timestamp' })}</label>
        <Field
          name="timestamp"
          id="timestamp"
          placeholderText={intl.formatMessage({ id: 'chooseDate' })}
          component={requiredDatePicker}
          validate={[fieldRequired]}
          showToday
          timeProps={{
            showTimeSelect: true,
            timeFormat: 'HH:mm aa',
            timeIntervals: 15,
            dateFormat: 'MM/dd/YYY h:mm aa',
            timeCaption: 'Time',
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default reduxForm({ form: 'upsertDetergentData' })(UpsertDetergentDataForm);
