import { createIntervalDescription } from './helpers';

export const Weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const IntervalOptions = {
  Weekly: 0,
  Once: 1,
};

/**
 * This is currently needed because the backend does not accept the interval property.
 * Will most likely update this to read from interval once available.
 */
const getInterval = (weeklySchedule) => {
  return weeklySchedule === null ? IntervalOptions.Once : IntervalOptions.Weekly;
};

export default class Shift {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.start = props.start;
    this.end = props.end;
    this.employeeId = props.employeeId;
    this.weeklySchedule = props.weeklySchedule;
    this.interval = getInterval(props.weeklySchedule);
    this.employee = props.employee;
  }

  getDescription(formatMessage) {
    return createIntervalDescription(this, formatMessage);
  }
}
