import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSite } from '../../../store/authReducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { getClaimCounts } from '../actions';
import { selectClaimCounts } from '../reducer';
import ClaimsKpi from './ClaimsKpi';

interface Props {
  fromDate: any;
  untilDate: any;
}

const ClaimsKpiChartDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const claimCounts = useSelector(selectClaimCounts);
  const primarySiteId = useSelector(selectSite);
  const multiSiteSelection = useSelector(selectMultiSiteSelection);

  const fetchClaimCounts = () => {
    dispatch(getClaimCounts(props.fromDate, props.untilDate));
  };

  useEffect(fetchClaimCounts, [props.fromDate, props.untilDate, primarySiteId, multiSiteSelection]);
  return <ClaimsKpi chartData={claimCounts} />;
};

export default ClaimsKpiChartDataGetter;
