import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, reduxForm } from 'redux-form';
import { FileType } from '../../views/claimDetail/models';
import { TextArea } from '../formValidation';
import * as utils from '../utils';
import FileReader from './FileReader';

class FileUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.file,
      fileType: props.fileType,
    };
  }

  getFileReader = () => {
    return (
      <FileReader
        handleFiles={this.handleFiles}
        fileType={utils.toString(FileType, this.state.fileType)}
        title="Select"
      />
    );
  };

  handleFiles = (files) => {
    let fileTypeValid = false;

    if (/image/.test(files.fileList[0].type)) {
      fileTypeValid = true;
      this.setState({ fileType: FileType.image });
    } else if (/video/.test(files.fileList[0].type)) {
      fileTypeValid = true;
      this.setState({ fileType: FileType.video });
    }

    if (fileTypeValid) {
      this.setState({ file: files }, () => {
        this.props.dispatch(change('fileUploadForm', 'file', files.fileList[0]));
      });
    }
  };

  render() {
    const intl = this.props.intl;
    return (
      <div>
        <div className="form-group">
          <label htmlFor="file">{intl.formatMessage({ id: 'file' })}</label>
          <div className="form-file-preview">
            {this.state.fileType === FileType.image ? (
              <img src={this.state.file.base64} alt={this.state.file.fileList[0].name} />
            ) : (
              <span className="badge badge-pill badge-primary">
                {this.state.file.fileList[0].name}
              </span>
            )}
            <Field name="file" id="file" type="file" component={this.getFileReader} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="description">{intl.formatMessage({ id: 'description' })}</label>
          <Field
            name="description"
            id="description"
            type="text"
            className="form-control"
            component={TextArea}
            placeholder="Enter description"
          />
        </div>
      </div>
    );
  }
}

FileUploadForm.propTypes = {
  file: PropTypes.shape({}).isRequired,
  fileType: PropTypes.oneOf([FileType.image, FileType.video]).isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

// TODO: add validator
const enhance = compose(connect(null, null), reduxForm({ form: 'fileUploadForm' }), injectIntl);

export default enhance(FileUploadForm);
