import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import { PropTypes } from 'prop-types';
import React from 'react';
import DataTable from '../../../core/components/DataTable';
import { formatAmount } from '../../../utils/money-util';
import { FleetPayAsYouGoDiscount } from '../models';

const Big = require('big.js');

const FleetPayAsYouGoDiscounts = ({ intl, data, showLoader }) => {
  const formatMessage = intl.formatMessage;

  const getTableHeaders = () => {
    return ['name', 'count', 'totalDiscount'].map((id) => ({
      name: formatMessage({ id }),
    }));
  };

  const sumTotalDiscount = (prev, current) => {
    return Big(prev).add(current.total).toFixed(2);
  };

  const getTotalRow = () => {
    const totalCounts = reduce(data, (prev, current) => prev + current.count, 0);
    const totalDiscount = reduce(data, sumTotalDiscount, 0);

    return {
      name: formatMessage({ id: 'total' }),
      count: totalCounts,
      totalDiscount: formatAmount(totalDiscount, data[0].currency),
      _highlightInformation: {
        index: 2,
        highlight: true,
      },
    };
  };

  const getTableData = () => {
    const groupedData = groupBy(data, 'operationName');
    const tableData = [];

    forEach(groupedData, (discounts, operationName) => {
      const formattedData = sortBy(discounts, 'tierLevel').map((row) => ({
        ...row.formatToTableData(),
        _tdClassNames: {
          0: ['td-padded-first-column'],
        },
      }));

      const operationHeaderRow = { name: operationName, count: '', totalDiscount: '' };

      tableData.push({
        operationName,
        data: [operationHeaderRow, ...formattedData],
        orderNumber: discounts[0].orderNumber,
      });
    });

    const sortedTableData = sortBy(tableData, 'orderNumber');
    const flatData = flatten(map(sortedTableData, 'data'));

    if (flatData.length) {
      const totalRow = getTotalRow();

      flatData.push(totalRow);
    }

    return flatData;
  };

  const initializeDataTable = () => {
    return {
      headers: getTableHeaders(),
      data: getTableData(),
      showLoader,
      addedClass: 'wash-count-table shrinked-table',
    };
  };

  return (
    <section className="wc-table-container">
      <h2 className="wc-table-header">{intl.formatMessage({ id: 'fleetPayPerWashDiscounts' })}</h2>
      <DataTable {...initializeDataTable()} />
    </section>
  );
};

FleetPayAsYouGoDiscounts.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(FleetPayAsYouGoDiscount).isRequired),
  showLoader: PropTypes.bool.isRequired,
};

export default React.memo(FleetPayAsYouGoDiscounts, isEqual);
