import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import ContentTabs from '../../../core/components/ContentTabs';
import DetailHeader from '../../../core/components/DetailHeader';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import { EmbeddedUrlCarousel } from '../../../core/components/EmbeddedUrlCarousel';
import { hasRoleInOperatorGroup, RoleTypes } from '../../../core/components/Permission';
import ReadOnlyEditor from '../../../core/components/ReadOnlyEditor';
import {
  deletePermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { selectSession } from '../../../store/authReducer';
import { getSiteModelYears, getSiteOperationTypes } from '../../settings/actions';
import { selectSiteModelYears, selectSiteOperationTypes } from '../../settings/reducer';
import {
  deleteEssentialTask,
  deleteTask,
  deleteTaskInEdit,
  getTask,
  getTaskLocations,
  getTaskPrefixes,
  saveEssentialTask,
  saveTask,
} from '../../tasks/actions';
import { createIntervalDescription } from '../../tasks/helpers';
import {
  selectTaskCompletions,
  selectTaskInEdit,
  selectTaskLocations,
  selectTaskPrefixes,
} from '../../tasks/reducer';
import History from '../components/History';
import Parts from '../components/Parts';
import TaskDetailNotes from '../components/TaskDetailNotes';

export class TaskDetail extends Component {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    getTask: PropTypes.func,
    saveTask: PropTypes.func,
    saveEssentialTask: PropTypes.func,
    deleteTask: PropTypes.func,
    deleteEssentialTask: PropTypes.func,
    deleteTaskInEdit: PropTypes.func,
    showDialog: PropTypes.func,
    getTaskPrefixes: PropTypes.func,
    getTaskLocations: PropTypes.func,
    taskInEdit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      interval: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      isEssentialTask: PropTypes.bool,
      description: PropTypes.string,
      modelYears: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          year: PropTypes.string,
        })
      ),
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    session: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string.isRequired,
        task: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    taskPrefixList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
      })
    ),
    taskLocationList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
        orderNumber: PropTypes.number,
      })
    ),
    siteOperationTypeList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    siteModelYearList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
      })
    ),
    getSiteModelYears: PropTypes.func,
    getSiteOperationTypes: PropTypes.func,
  };

  componentDidMount() {
    const id = this.props.match.params.task;
    this.props.getTask(id);
    if (!this.props.taskPrefixList.length) {
      this.props.getTaskPrefixes();
    }
    if (!this.props.taskLocationList.length) {
      this.props.getTaskLocations();
    }
    if (hasRoleInOperatorGroup(this.props.session, RoleTypes.OperatorGroupAdmin)) {
      if (!this.props.siteModelYearList.length) {
        this.props.getSiteModelYears();
      }

      if (!this.props.siteOperationTypeList.length) {
        this.props.getSiteOperationTypes();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.taskInEdit && prevProps.taskInEdit) {
      const siteId = this.props.match.params.site;
      this.props.history.push(`/${siteId}/tasks`);
    }
  }

  componentWillUnmount() {
    this.props.deleteTaskInEdit();
  }

  getTitles = (task) => {
    const prefix = this.props.taskPrefixList.find(
      (taskPrefix) => taskPrefix.type === task.prefixId
    );
    const location = this.props.taskLocationList.find(
      (taskLocation) => taskLocation.type === task.locationId
    );

    return [
      {
        className: 'detail-name',
        label: this.props.intl.formatMessage({ id: 'name' }),
        content: task.name,
      },
      {
        className: 'detail-subTitle',
        label: this.props.intl.formatMessage({ id: 'subTitle' }),
        content: task.subTitle,
      },
      {
        className: 'detail-id',
        label: this.props.intl.formatMessage({ id: 'id' }),
        content: task.id,
      },
      {
        className: 'detail-interval',
        label: this.props.intl.formatMessage({ id: 'interval' }),
        content: createIntervalDescription(task, this.props.intl.formatMessage),
      },
      {
        label: this.props.intl.formatMessage({ id: 'start' }),
        content: moment(task.start).format('MM/DD/YYYY'),
      },
      {
        label: this.props.intl.formatMessage({ id: 'end' }),
        content: task.end
          ? moment(task.end).format('MM/DD/YYYY')
          : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
      {
        label: this.props.intl.formatMessage({ id: 'taskPrefix' }),
        content: prefix ? prefix.message : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
      {
        label: this.props.intl.formatMessage({ id: 'taskLocation' }),
        content: location
          ? location.message
          : this.props.intl.formatMessage({ id: 'notAvailable' }),
      },
    ];
  };

  editTask = () => {
    const { id, isEssentialTask } = this.props.taskInEdit;
    const formatMessage = this.props.intl.formatMessage;

    const title = isEssentialTask
      ? formatMessage({ id: 'editEssentialTask' })
      : formatMessage({ id: 'editTask' });

    const onSubmit = (task) => {
      const saveProps = { task, callback: () => getTask(id) };

      if (isEssentialTask) {
        this.props.saveEssentialTask(saveProps);
      } else {
        this.props.saveTask(saveProps);
      }
    };

    this.props.showDialog('UPSERT_TASK', {
      title,
      onSubmit,
      isEssentialTask,
      isNew: false,
      modelYears: this.props.taskInEdit.modelYears,
    });
  };

  deleteTask = () => {
    const { id, name, isEssentialTask } = this.props.taskInEdit;
    const deletionParams = {
      id,
      callback: deleteTaskInEdit,
    };

    const confirmProps = {
      title: this.props.intl.formatMessage({ id: 'archiveTask' }),
      text: this.props.intl.formatMessage({ id: 'taskArchiveConfirmation' }, { name }),
      onReady: isEssentialTask
        ? () => this.props.deleteEssentialTask(deletionParams)
        : () => this.props.deleteTask(deletionParams),
    };

    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  render() {
    const task = this.props.taskInEdit || {};
    const intl = this.props.intl;

    const components = task.isEssentialTask
      ? {
          Instructions: (
            <ReadOnlyEditor text={task.description === undefined ? '' : task.description} />
          ),
          Videos: <EmbeddedUrlCarousel urls={task.videos} isVideo={true} />,
          History: <History task={task} />,
          Notes: <TaskDetailNotes task={task} />,
        }
      : {
          Instructions: (
            <ReadOnlyEditor text={task.description === undefined ? '' : task.description} />
          ),
          Videos: <EmbeddedUrlCarousel urls={task.videos} isVideo={true} />,
          Parts: <Parts task={task} />,
          History: <History task={task} />,
          Notes: <TaskDetailNotes task={task} />,
        };

    const buttons =
      task.isEssentialTask &&
      !hasRoleInOperatorGroup(this.props.session, RoleTypes.OperatorGroupAdmin, task.groupId)
        ? []
        : [
            {
              onClick: this.deleteTask,
              label: intl.formatMessage({ id: 'delete' }),
              class: 'button primary inverted mr-2',
              permissionKey: deletePermissionRefs.task,
            },
            {
              onClick: this.editTask,
              label: intl.formatMessage({ id: 'edit' }),
              permissionKey: editPermissionRefs.edit,
            },
          ];

    return (
      <div className="container-fluid">
        <section className="page-main-content">
          <DetailButtonGroup history={this.props.history} intl={intl} buttons={buttons} />
          {task.isEssentialTask && (
            <span className="badge badge-secondary mb-2">
              {intl.formatMessage({ id: 'masterTask' })}
            </span>
          )}
          <DetailHeader titles={this.getTitles(task)} />
          <ContentTabs components={components} />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taskInEdit: selectTaskInEdit(state),
  taskCompletions: selectTaskCompletions(state),
  session: selectSession(state),
  taskPrefixList: selectTaskPrefixes(state),
  taskLocationList: selectTaskLocations(state),
  siteModelYearList: selectSiteModelYears(state),
  siteOperationTypeList: selectSiteOperationTypes(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTask,
      deleteTask,
      deleteEssentialTask,
      deleteTaskInEdit,
      saveTask,
      saveEssentialTask,
      showDialog,
      getTaskPrefixes,
      getTaskLocations,
      getSiteModelYears,
      getSiteOperationTypes,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(TaskDetail);
