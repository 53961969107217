import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import DetergentEditForm from '../components/DetergentEditForm';

const initialFormValues = {};

export class AddDetergentDialog extends Component {
  static propTypes = {
    addDetergent: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hideDialog: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    inventory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
  };

  onReady = () => {
    this.props.triggerSubmit('detergent');
  };

  // TODO: manage validationErrors
  // TODO: create new detergent instance out of the values and pass that?
  onSubmit = (values) => {
    this.props.addDetergent(values);
  };

  render() {
    const submitDisabled = checkErrors(this.props.errors);

    initialFormValues.inventoryItem = -1;

    return (
      <ModalDialog
        title="Add Detergent"
        onReady={this.onReady}
        isOpen={this.props.isOpen}
        close={this.props.hideDialog}
        disabled={submitDisabled}
      >
        <DetergentEditForm
          onSubmit={this.onSubmit}
          initialValues={initialFormValues}
          products={this.props.products}
          inventory={this.props.inventory}
          selectedInventory={initialFormValues.inventoryItem}
        />
      </ModalDialog>
    );
  }
}
const getErrors = getFormSyncErrors('detergent');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return { errors };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, triggerSubmit: submit, hideDialog }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetergentDialog);
