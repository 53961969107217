import { Label } from 'reactstrap';
import { IntlType } from '../../../../../utils/typing';
import { Site } from '../../../../site-selection/models';

interface Props {
  intl: IntlType;
  selectedSites: Site[];
  removeSelectedSite: (site: Site) => void;
}

const SelectedSitesList = (props: Props): JSX.Element | null => {
  const { intl, selectedSites, removeSelectedSite } = props;

  if (!selectedSites.length) {
    return null;
  }

  return (
    <div className="mt-3">
      <Label for="userGroup" className="font-weight-bold">
        {intl.formatMessage({ id: 'selectedSites' })}
      </Label>

      <div className="selected-sites-container">
        {selectedSites.map((site) => (
          <span key={site.name} className="selected-site">
            <div className="site-name">{site.externalId || site.name}</div>
            <i
              data-testid={`remove-site-icon-${site.name}`}
              className="icon icon-cross"
              onClick={() => removeSelectedSite(site)}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default SelectedSitesList;
