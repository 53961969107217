import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectTypeFilter } from '../reducer';
import { setTypeFilter } from '../actions';

const InventoryTypeFilter = ({ inventoryTypes }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const typeFilter = useSelector(selectTypeFilter);
  const handleChange = ({ target: { value } }) => {
    dispatch(setTypeFilter(Number(value)));
  };

  return (
    <div className="form-group">
      <label htmlFor="typeFilter">{intl.formatMessage({ id: 'type' })}</label>
      <select id="typeFilter" className="form-control" value={typeFilter} onChange={handleChange}>
        <option value={0}>All</option>
        {inventoryTypes.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

InventoryTypeFilter.propTypes = {
  inventoryTypes: PropTypes.array,
};

export default InventoryTypeFilter;
