import { PropTypes } from 'prop-types';
import React from 'react';

const Loader = ({ options }) => {
  const { stroke, width, height } = options || {};

  return (
    <div data-testid="loader" className="loader-img-container">
      <svg
        width={width || '100px'}
        height={height || '100px'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-ripple"
        style={{ background: 'none' }}
      >
        <circle
          cx="50"
          cy="50"
          r="33.786"
          fill="none"
          ng-attr-stroke="{{config.c1}}"
          ng-attr-stroke-width="{{config.width}}"
          stroke={stroke || '#e6e6ea'}
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            calcMode="spline"
            values="0;40"
            keyTimes="0;1"
            dur="1.5"
            keySplines="0 0.2 0.8 1"
            begin="-0.75s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            calcMode="spline"
            values="1;0"
            keyTimes="0;1"
            dur="1.5"
            keySplines="0.2 0 0.8 1"
            begin="-0.75s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          cx="50"
          cy="50"
          r="14.2835"
          fill="none"
          ng-attr-stroke="{{config.c2}}"
          ng-attr-stroke-width="{{config.width}}"
          stroke={stroke || '#e6e6ea'}
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            calcMode="spline"
            values="0;40"
            keyTimes="0;1"
            dur="1.5"
            keySplines="0 0.2 0.8 1"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            calcMode="spline"
            values="1;0"
            keyTimes="0;1"
            dur="1.5"
            keySplines="0.2 0 0.8 1"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

Loader.propTypes = {
  options: PropTypes.shape({
    stroke: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

export default Loader;
