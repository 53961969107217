import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userIsAuthenticatedRedir } from '../core/auth';
import AuthenticatedWrapper from '../core/components/AuthenticatedWrapper';
import DefaultSiteList from '../views/site-selection/components/DefaultSiteList';
import SiteRoute from './SiteRoute';

const AuthenticatedRoute = () => {
  return (
    <AuthenticatedWrapper>
      <Switch>
        <Route path="/site-selection">
          <DefaultSiteList />
        </Route>
        <Route strict path="/:site">
          <SiteRoute />
        </Route>
      </Switch>
    </AuthenticatedWrapper>
  );
};

export default userIsAuthenticatedRedir(AuthenticatedRoute);
