import isEqual from 'lodash/isEqual';
import { PropTypes } from 'prop-types';
import React, { Fragment, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import Zendesk from 'react-zendesk';
import { bindActionCreators } from 'redux';
import { selectOperatorGroupId, selectZendeskWidgetKey } from '../../store/authReducer';
import { fetchZendeskKey } from '../actions';

const ZendeskSupportWidget = (props) => {
  const { zendeskWidgetKey, operatorGroupId, fetchZendeskWidgetKey } = props;

  useEffect(() => {
    if (operatorGroupId) {
      fetchZendeskWidgetKey();
    }
  }, [operatorGroupId, fetchZendeskWidgetKey]);

  return <Fragment>{zendeskWidgetKey && <Zendesk zendeskKey={zendeskWidgetKey} />}</Fragment>;
};

ZendeskSupportWidget.propTypes = {
  fetchZendeskWidgetKey: PropTypes.func,
  zendeskWidgetKey: PropTypes.string,
  operatorGroupId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchZendeskWidgetKey: fetchZendeskKey,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  zendeskWidgetKey: selectZendeskWidgetKey(state),
  operatorGroupId: selectOperatorGroupId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ZendeskSupportWidget, isEqual));
