import React from 'react';
import { Field } from 'redux-form';
import { taskSelection } from '../helpers';
import { dependencies } from '../../maintenanceTasks/models';
import { Card } from 'reactstrap';
import { showDialog } from '../../../core/actions';
export const LinkedTaskField = (props: any): JSX.Element => {
  const intl = props.intl;

  return (
    <div key={props.index} className="form-control inline-add-item-button">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <button
          className="btn btn-outline-secondary add-purchase-item"
          style={{ marginTop: '10px', borderRadius: '20px' }}
          onClick={() =>
            props.fields.push({
              dependency: 'Select a task dependency', // just push dummy data
              linkedTask: 'select a task to link',
            })
          }
        >
          <span>+</span>
        </button>
      </div>
      <Card style={{ border: '0px' }}>
        {props.fields &&
          props.fields.map((item: any, index: number) => (
            <Card key={index} className="form-control inline-add-item-button">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <label>{intl.formatMessage({ id: 'chooseDependency' })}</label>
                  <Field
                    id="dependency"
                    name={`${item}.dependency`}
                    component={({ input }: any) =>
                      taskSelection({
                        options: dependencies,
                        intl: props.intl,
                        input,
                        placeholder: 'search',
                        ...props,
                      })
                    }
                  />
                </div>
                <div>
                  <label>{intl.formatMessage({ id: 'chooseTask' })}</label>
                  <Field
                    id="linkedTask"
                    name={`${item}.linkedTask`}
                    component={({ input }: any) =>
                      taskSelection({
                        options: props.tasks,
                        intl: props.intl,
                        input,
                        placeholder: 'search',
                        ...props,
                      })
                    }
                  />
                </div>
                <div style={{ marginTop: 'auto', marginBottom: '8px' }}>
                  <button
                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                    className="close add-purchase-item"
                    onClick={() => props.fields.remove(index)}
                  >
                    <i className="icon icon-squared-cross"></i>
                  </button>
                </div>
              </div>
            </Card>
          ))}
      </Card>
    </div>
  );
};
