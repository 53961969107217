import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { fetchSurveyCancellationResults } from '../actions';
import { selectSurveyResults } from '../reducer';
import { CancelationSurveyKpi } from './CancellationSurveyKpi';

interface Props {
  fromDate: any;
  untilDate: any;
}

const GuestCancellationDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const surveyResults = useSelector(selectSurveyResults),
    multiSites = useSelector(selectMultiSiteSelection),
    siteId = useSelector(selectSiteExtId);

  const fetchSurveyResults = () => {
    if (siteId) {
      dispatch(fetchSurveyCancellationResults(siteId, props.fromDate, props.untilDate));
    }
  };

  useEffect(fetchSurveyResults, [siteId, props.fromDate, props.untilDate, multiSites]);

  return (
    <CancelationSurveyKpi
      surveyResults={surveyResults}
      fromDate={props.fromDate}
      untilDate={props.untilDate}
    />
  );
};

export default GuestCancellationDataGetter;
