import moment from 'moment';
import { Weekdays } from './models';

export const getWeekdays = (schedule) => {
  if (!schedule) return null;
  const weekdays = [];
  let i;
  for (i = 0; i < schedule.length; i += 1) {
    const day = Number(schedule.charAt(i));
    weekdays.push(moment().day(day).format('dd'));
  }

  weekdays.sort((a, b) => {
    if (Weekdays.indexOf(a) < Weekdays.indexOf(b)) {
      return -1;
    }
    if (Weekdays.indexOf(a) > Weekdays.indexOf(b)) {
      return 1;
    }
    return 0;
  });

  return weekdays.join(', ');
};

export const createIntervalDescription = (intervalConfig, formatMessage) => {
  const { interval, weeklySchedule } = intervalConfig;

  if (typeof formatMessage !== 'function') throw new Error('formatMessage missing');
  if (interval < 0) throw new Error('invalid value for interval');

  switch (interval) {
    case 0: {
      // weekly
      const weekdays = getWeekdays(weeklySchedule);
      const hasSchedule = formatMessage({ id: 'shifts.schedule.weekly' }, { weekdays });
      const noSchedule = formatMessage({
        id: 'shifts.schedule.weekly.noSchedule',
      });
      return weekdays ? hasSchedule : noSchedule;
    }
    case 1: {
      // one-time
      return formatMessage({ id: 'shifts.schedule.onetime' });
    }
    default:
      return 'Invalid configuration';
  }
};
