import { Field } from 'redux-form';
import { fieldRequired, requiredSelect } from '../formValidation';
import Employee from '../../views/employees/models';
import { useIntl } from 'react-intl';

interface Props {
  employees: Employee[];
  remainingEmployees: Employee[];
  createdFields: any;
  fields: any;
}

export const EmployeeMultiSelect = ({
  employees,
  remainingEmployees,
  createdFields,
  fields,
}: Props): JSX.Element => {
  const intl = useIntl();
  const createEmployeeList = (fieldIndex: any) => {
    const selectedEmployeeForCurrentField = employees.find(
      (e) => createdFields[fieldIndex] && e.id === createdFields[fieldIndex].employeeId
    );
    const options = [];

    if (selectedEmployeeForCurrentField) {
      const { id, firstName, lastName } = selectedEmployeeForCurrentField;

      options.push(
        <option
          id="selected"
          key={`field-${fieldIndex}-employee-${id}-selected`}
          value={id}
          data-testid={`${firstName} ${lastName}`}
        >
          {firstName} {lastName}
        </option>
      );
    }

    const remainingOptions = remainingEmployees.map((e) => (
      <option
        id="remaining"
        key={`field-${fieldIndex}-employee-${e.id}`}
        value={e.id}
        data-testid={`${e.firstName} ${e.lastName}`}
      >
        {e.firstName} {e.lastName}
      </option>
    ));

    options.push(...remainingOptions);

    return options;
  };

  return (
    <div>
      {!!createdFields &&
        fields.map((employee: Employee, index: any) => (
          <div key={index}>
            <div className="readings-form">
              <button
                className="close multi-form-delete"
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <i className="icon icon-squared-cross" />
              </button>
              <div className="mt-3">{`Employee #${index + 1}`}</div>
              <div className="form-group">
                <Field
                  id="employeeId"
                  name={`${employee}.employeeId`}
                  data-testid="employeeid-reclaimation-form"
                  parse={(value: any) => value && Number(value)}
                  className="form-control"
                  component={requiredSelect}
                  validate={[fieldRequired]}
                >
                  {createEmployeeList(index)}
                </Field>
              </div>
            </div>
          </div>
        ))}
      <button
        className="button"
        data-testid="add-employee-reclamation-form"
        onClick={() => {
          fields.push({ employeeId: remainingEmployees[0].id });
        }}
      >
        {intl.formatMessage({ id: 'addEmployee' })}
      </button>
    </div>
  );
};
