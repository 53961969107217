import { ChangeEvent, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { requiredSelectWithExclusions as RequiredSelect } from '../../../../../core/formValidation';
import { IntlType } from '../../../../../utils/typing';
import { UserGroup } from '../../../../employees/models';
import { Formik } from '../../../hooks/useForm';
import { FormSelections } from '../hooks/useFormSelections';
import useUserGroupOptions from './hooks/useUserGroupOptions';
import PermissionSummary from './PermissionSummary';

interface Props {
  intl: IntlType;
  formik: Formik;
  formSelections: FormSelections;
}

const UserGroupSelect = ({ intl, formik, formSelections }: Props): JSX.Element => {
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup | undefined>();

  const userGroupOptions = useUserGroupOptions(intl, formSelections.userGroups);

  useEffect(() => {
    const userGroup = formSelections.userGroups.find((ug) => ug.id === formik.values.userGroupId);

    setSelectedUserGroup(userGroup);
  }, [formik.values.userGroupId]);

  const fieldProps = formik.getFieldProps('userGroupId');

  const handleOnChange = (event: ChangeEvent<any>) => {
    const parsedValue = parseInt(event.target.value, 10);

    formik.setFieldValue('userGroupId', parsedValue);
  };

  const permissions = selectedUserGroup?.permissions || [];

  return (
    <>
      <Label for="userGroupId" className="font-weight-bold">
        {intl.formatMessage({ id: 'userGroup' })}
      </Label>
      <RequiredSelect
        input={{
          ...fieldProps,
          value: fieldProps.value || '',
          type: 'select',
          id: 'userGroupId',
          onChange: handleOnChange,
        }}
        exclusions={['']}
      >
        {userGroupOptions}
      </RequiredSelect>
      <PermissionSummary intl={intl} permissions={permissions} />
    </>
  );
};

export default UserGroupSelect;
