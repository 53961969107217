import { escapeRegExp } from 'lodash';

const DEFAULT_LOCALE = 'en-us';
const NORMALIZED_FRAGMENTS_DELIM = '.';

const thousandsDelimForLocale = (locale = DEFAULT_LOCALE) => {
  switch (locale.toLowerCase()) {
    case 'en-ca':
      return ' ';
    case 'en-us':
    case 'en':
      return ',';
    default:
      return ',';
  }
};

export const fragmentsDelimForLocale = (locale = DEFAULT_LOCALE) => {
  switch (locale.toLowerCase()) {
    case 'en-ca':
      return ',';
    case 'en-us':
    case 'en':
      return '.';
    default:
      return '.';
  }
};

/**
 * Parses formatted (localized) number to normalized one. For example 12,534.94 -> 12534.94
 * @param {*} val
 * @param {*} locale
 * @returns A number type or 0 with empty or nil val.
 */
export const parseNumber = (val, locale = DEFAULT_LOCALE) => {
  if (!val || `${val}`.trim().length === 0) {
    return 0;
  }

  const value = `${val}`.trim();
  const thousandsDelim = thousandsDelimForLocale(locale);
  const fragmentsDelim = fragmentsDelimForLocale(locale);
  const parsedValue = value
    .split(thousandsDelim)
    .join('')
    .replace(fragmentsDelim, NORMALIZED_FRAGMENTS_DELIM);
  return parseFloat(parsedValue);
};

export const isNumber = (
  val,
  allowThousandsSeparator = false,
  allowFragments = false,
  locale = DEFAULT_LOCALE
) => {
  let value = val;

  if ((!value && Number.isNaN(Number(value))) || `${value}`.trim().length === 0) {
    return false;
  }
  if (Number.isNaN(Number(value)) && (allowFragments || allowThousandsSeparator)) {
    value = parseNumber(value, locale);
  }

  return !Number.isNaN(Number(value));
};

/**
 * Given val to have given max number of fraction digits.
 * Does not add fraction numbers if val does not have any.
 * Also strips all but numbers from fraction part.
 * Also related to @see formatNumber
 * @param {*} val Required. String or a number.
 * @param {*} digits Required number. Given number of fraction digits.
 * @param {*} locale
 */
export const restrictFragments = (val, digits, locale = DEFAULT_LOCALE) => {
  const value = `${val}`;
  const sourceFragmentsDelim = Number.isNaN(Number(value))
    ? fragmentsDelimForLocale(locale)
    : NORMALIZED_FRAGMENTS_DELIM;

  const escapedFragmentsDelim = escapeRegExp(sourceFragmentsDelim);
  const nonDigitRegex = new RegExp(`(${escapedFragmentsDelim})\\D+`, 'g');

  if (nonDigitRegex.test(value)) {
    return value.replace(nonDigitRegex, '$1');
  }

  const nonDigitsAfterDigits = new RegExp(
    `(${escapedFragmentsDelim}\\d{${digits}})\\d+(\\D*)`,
    'g'
  );
  return value.replace(nonDigitsAfterDigits, '$1');
};

/**
 * Strips all trailig zeros from fraction part of given val. Strips all the fraction part, if it only has zeros.
 * Also related to @see formatNumber
 * @param {*} val Required. String or a number.
 * @param {*} locale
 */
export const trimTrailingFragmentZeros = (val, locale = DEFAULT_LOCALE) => {
  const value = `${val}`;
  const sourceFragmentsDelim = Number.isNaN(Number(value))
    ? fragmentsDelimForLocale(locale)
    : NORMALIZED_FRAGMENTS_DELIM;
  if (value.indexOf(sourceFragmentsDelim) === -1) {
    return value;
  }

  const escapedFragmentsDelim = escapeRegExp(sourceFragmentsDelim);
  const regex = new RegExp(`(${escapedFragmentsDelim}0+$|0+$)`, 'g');
  return value.replace(regex, '');
};

/**
 * Formats given val to localized number with 'en-us' locale by default.
 * @param {*} val Required. String or a number.
 * @param {*} digits Optional number. Given val to have given max number of fraction digits.
 * @param {*} stripTrailingDigitZeros Optional boolean with default value false. When true, strips all trailig zeros from fraction part.
 * @param {*} locale  Optional string with default value 'en-us'. Can be used to localize formatted val.
 *                    Available locales are 'en', 'en-us' and 'en-ca'. If unregognized locale is passed, default is used.
 * @returns Formatted val as a string.
 */
export const formatNumber = (
  val,
  digits,
  stripTrailingDigitZeros = false,
  locale = DEFAULT_LOCALE
) => {
  const value = `${val}`.trim();
  const fragmentsDelim = fragmentsDelimForLocale(locale);
  const thousandsDelim = thousandsDelimForLocale(locale);
  const sourceFragmentsDelim = isNumber(value) ? NORMALIZED_FRAGMENTS_DELIM : fragmentsDelim;
  const parts = value.split(sourceFragmentsDelim);

  parts[0] = parts[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, thousandsDelim);

  if (parts.length >= 2) {
    parts[1] = parts[1].replace(/\D/g, '');
  }

  let formattedNumber = parts.splice(0, 2).join(fragmentsDelim);
  formattedNumber = digits ? restrictFragments(formattedNumber, digits, locale) : formattedNumber;
  formattedNumber = stripTrailingDigitZeros
    ? trimTrailingFragmentZeros(formattedNumber, locale)
    : formattedNumber;

  return formattedNumber;
};

export const formatPhoneNumber = (str) => {
  if (!str) return '';
  // Remove all non-digits
  let digits = str.replace(/\D/g, '');

  // Format the digits as a phone number
  let phoneNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return phoneNumber;
};
