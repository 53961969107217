import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import DataTable from '../../../core/components/DataTable';

const headers = [{ name: 'Name' }, { name: 'Qty' }, { name: 'Price' }, { name: 'Total' }];

const Items = (props) => {
  const data = props.data;
  const [rowData, setRowData] = useState([]);
  let options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
  useEffect(() => {
    const row = data.map((item) => ({
      _id: item.id,
      name: item.name,
      qty: item.quantity,
      price: `$${Number(item.price).toLocaleString(undefined, options)}`,
      total: `$${Number(item.price * item.quantity).toLocaleString(undefined, options)}`,
    }));

    setRowData(row);
  }, [setRowData, data]);

  return <DataTable headers={headers} data={rowData} />;
};

Items.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Items.defaultProps = {
  data: [],
};

export default memo(Items, isEqual);
