import Product from './models';

const initialState = {
  products: [],
};

const fetchProductsSuccess = (state, response) => {
  const products = response.results.map((p) => {
    return new Product(p);
  });
  return {
    ...state,
    products,
  };
};

export const products = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PRODUCTS_SUCCESS':
      return fetchProductsSuccess(state, action.response);
    default:
      return state;
  }
};

export const selectProducts = (state) => {
  return state.products.products;
};
