import React, { useRef, useEffect, useState } from 'react';
import {
  models,
  service,
  factories,
  IEmbedConfiguration,
  Visual,
  IVisualEmbedConfiguration,
} from 'powerbi-client';
import { setWashCountsFilter } from '../../analytics/helpers';
import { useSelector } from 'react-redux';
import {
  selectClosestExternalIds,
  selectMultiSiteSelectionExternalIds,
  selectSiteExternalId,
} from '../../site-selection/reducer';

interface VisualEmbedProps {
  accessToken: string;
  embedUrl: string;
  reportId: string;
  pageName: string;
  visualId: string;
}

const VisualEmbed: React.FC<VisualEmbedProps> = ({
  accessToken,
  embedUrl,
  reportId,
  pageName,
  visualId,
}) => {
  const [visual, setVisual] = useState<Visual | null>(null);
  const embedContainer = useRef<HTMLDivElement>(null);
  const multiSiteExtIds = useSelector(selectMultiSiteSelectionExternalIds);
  const currentSiteExternalId = useSelector(selectSiteExternalId);
  const closestSiteExtIds = useSelector(selectClosestExternalIds);

  const handleMultiSiteFilter = () => {
    const sitesExtIds =
      multiSiteExtIds && multiSiteExtIds.length > 0 ? multiSiteExtIds : closestSiteExtIds;
    let conditions = [];

    if (sitesExtIds) {
      conditions = sitesExtIds.map((id: { toString: () => any }) => {
        return {
          operator: 'Is',
          value: id.toString(),
        };
      });
    }
    conditions.push({
      operator: 'Is',
      value: currentSiteExternalId,
    });
    return conditions;
  };

  useEffect(() => {
    const embedConfig: IVisualEmbedConfiguration = {
      type: 'visual',
      width: 350,
      height: 200,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: reportId,
      pageName: pageName,
      visualName: visualId,
      filters: [setWashCountsFilter(handleMultiSiteFilter())],
      settings: {
        background: models.BackgroundType.Transparent,
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: models.LayoutType.Master,
      },
    };

    if (embedContainer.current) {
      const powerbiService = new service.Service(
        factories.hpmFactory,
        factories.wpmpFactory,
        factories.routerFactory
      );

      const visualComponent = powerbiService.embed(embedContainer.current, embedConfig) as Visual;
      visualComponent.on('loaded', () => {
        setVisual(visualComponent);
      });
      visualComponent.on('error', (event) => {
        console.error('Error embedding visual', event.detail);
      });

      return () => {
        if (embedContainer.current) {
          powerbiService.reset(embedContainer.current!);
        }
      };
    }
  }, []);

  useEffect(() => {
    const updateFilter = () => {
      const conditions = handleMultiSiteFilter();
      if (visual) {
        visual
          .setFilters([setWashCountsFilter(conditions)])
          .catch((error) => console.error('ERROR: ', error));
      }
    };

    updateFilter();
  }, [multiSiteExtIds, currentSiteExternalId]);

  return (
    <div ref={embedContainer} style={{ width: '100%', maxHeight: '600px', aspectRatio: '16/9' }} />
  );
};

export default VisualEmbed;
