import { useEffect, useState } from 'react';

interface ToggleProps {
  /** Whether the toggle is true or false */
  initialState?: boolean;
  /**  Label to display next to the toggle */
  label?: string;
  /** Set to true if you want the label to the left of the toggle */
  leftLabel?: boolean;
  /** Toggle callback */
  toggleCallback?: any;
}

export const ToggleButton = ({
  initialState = false,
  label = '',
  leftLabel = false,
  toggleCallback,
}: ToggleProps) => {
  const [value, setValue] = useState(initialState);
  const handleToggle = () => {
    setValue(!value);
    toggleCallback(!value);
  };
  return (
    <span className={`toggle-button${value ? ' active' : ''}`} onClick={handleToggle}>
      {leftLabel && <span className="toggle-label">{label}</span>}
      <span className="toggle-container">
        <span className="toggle"></span>
      </span>
      {!leftLabel && <span className="toggle-label">{label}</span>}
    </span>
  );
};

export default ToggleButton;
