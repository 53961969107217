import React from 'react';

interface Props {
  children: React.ReactNode;
}

const CheckMark = ({ children }: Props): JSX.Element => {
  return (
    <div className="success-container">
      <div className="checkmark-container">
        <div className="checkmark" />
      </div>
      {children}
    </div>
  );
};

export default CheckMark;
