import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../actions';
import Button from './Button';

class NoteList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedNote: null,
    };
  }

  onArchiveNoteReady = (note) => {
    this.clearNoteSelection();
    this.props.onArchiveNote(note);
  };

  selectNote = (note) => {
    this.setState({
      selectedNote: note,
    });
  };

  clearNoteSelection = () => {
    this.setState({
      selectedNote: null,
    });
  };

  openAddNoteDialog = () => {
    const dialogProps = {
      onReady: this.props.onAddNote,
      title: this.props.intl.formatMessage({ id: 'addNote' }),
    };
    this.props.showDialog('ADD_NOTE', dialogProps);
  };

  archiveNoteClicked = (note) => {
    const dialogProps = {
      onReady: () => this.onArchiveNoteReady(note),
      title: this.props.intl.formatMessage({ id: 'deleteNote' }),
      text: this.props.intl.formatMessage({ id: 'deleteNoteConfirmation' }),
    };
    this.props.showDialog('CONFIRM_DIALOG', dialogProps);
  };

  renderOpenedNote = () => {
    const { intl } = this.props;
    const note = this.state.selectedNote;

    return (
      <div className="note">
        <button type="button" onClick={this.clearNoteSelection} className="btn btn-primary btn-sm">
          <i className="icon icon-arrow-left" /> {intl.formatMessage({ id: 'backToNotes' })}
        </button>

        {this.props.onArchiveNote && (
          <Button styleName="ml-1 float-right" onClick={() => this.archiveNoteClicked(note)}>
            {intl.formatMessage({ id: 'deleteNote' })}
          </Button>
        )}
        <div className="row mx-0 mt-3">
          <div className="col text mb-2">{note.text}</div>
        </div>
        <div>{moment(note.createdAt).format('MM/DD/YYYY HH:mm a')}</div>
      </div>
    );
  };

  renderList = () => {
    const notes = this.props.notes;

    return (
      <div className="notes">
        {this.props.onAddNote && (
          <div className="row">
            <div className="col">
              {this.props.onAddNote && (
                <Button styleName="ml-1 float-right" onClick={this.openAddNoteDialog}>
                  {this.props.intl.formatMessage({ id: 'addNote' })}
                </Button>
              )}
            </div>
          </div>
        )}
        <div className="mt-3">
          {notes &&
            notes.map((note, index) => (
              // eslint-disable-next-line
              <div key={index} className="row notelist-item" onClick={() => this.selectNote(note)}>
                <div className="col-md-3 date">
                  {moment(note.createdAt).format('MM/DD/YYYY HH:mm a')}
                </div>
                <div className="col-md-9 text-preview">{note.text}</div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    return this.state.selectedNote ? this.renderOpenedNote() : this.renderList();
  }
}

NoteList.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  onAddNote: PropTypes.func,
  onArchiveNote: PropTypes.func,
  showDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showDialog,
    },
    dispatch
  );
};

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(NoteList);
