import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import values from 'lodash/values';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import baseChart from './BaseChart';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const defaultColors = [
  'rgb(31, 150, 243)',
  'rgb(244, 67, 54)',
  'rgb(254, 153, 1)',
  'rgb(255, 214, 26)',
  'rgb(23, 186, 23)',
  'rgb(99, 14, 164)',
];

const WashCounts = ({ chartOptions, chartProps, chartData }) => {
  const newChartInfo = values(chartData);
  let colorIndex = 0;
  function getColorIndex() {
    colorIndex += 1;
    return colorIndex;
  }

  function getColor(washCount) {
    if (washCount.color) {
      return `#${washCount.color}`;
    }
    return defaultColors[getColorIndex()];
  }

  const labels = newChartInfo.map((washCount) => washCount.product);
  const washCounts = newChartInfo.map((washCount) => washCount.count);
  const colors = newChartInfo.map((washCount) => getColor(washCount));

  const data = {
    labels,
    datasets: [
      {
        data: washCounts,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    cutoutPercentage: 50,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: 'left',
        display: false,
        labels: {
          fontSize: 14,
          padding: 15,
        },
      },
    },
  };

  return <Pie data={data} options={{ ...options, ...chartOptions }} {...chartProps} />;
};

WashCounts.propTypes = {
  chartOptions: PropTypes.shape({
    maintainAspectRatio: PropTypes.bool,
  }),
  chartProps: PropTypes.shape({
    height: PropTypes.number,
  }),
  chartData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default baseChart(WashCounts);
