import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [rowInEdit, setRowInEdit] = useState(null);

  const menuContext = { hasChanged, setHasChanged, rowInEdit, setRowInEdit };
  return <MenuContext.Provider value={menuContext}>{children}</MenuContext.Provider>;
};

MenuProvider.propTypes = {
  children: PropTypes.node,
};
