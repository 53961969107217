import { IntlType } from '../../../../../../utils/typing';
import { UserGroup } from '../../../../../employees/models';

const useUserGroupOptions = (intl: IntlType, userGroups: UserGroup[]): JSX.Element => {
  return (
    <>
      <option value="" disabled>
        {intl.formatMessage({ id: 'selectUserGroup' })}
      </option>

      {userGroups.map((userGroup) => (
        <option key={userGroup.name + '-' + userGroup.id} value={userGroup.id}>
          {userGroup.name}
        </option>
      ))}
    </>
  );
};

export default useUserGroupOptions;
