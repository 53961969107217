import React from 'react';
import { useEffect, useState, useRef } from 'react';

interface VisualScrollProps {
  horizontal?: boolean;
  vertical?: boolean;
  classes?: string;
  children: any;
}

export const VisualScroll = ({
  vertical = true,
  horizontal = false,
  classes = '',
  children,
}: VisualScrollProps) => {
  const element = useRef<HTMLDivElement>(null);
  const [topO, setTopO] = useState(0);
  const [bottomO, setBottomO] = useState(0);
  const [leftO, setLeftO] = useState(0);
  const [rightO, setRightO] = useState(0);

  useEffect(() => {
    const onScroll = (e: any) => {
      const target = e.target as HTMLTextAreaElement;
      if (vertical) {
        setTopO(target.scrollTop > 0 ? 1 : 0);
        setBottomO(
          Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) <= 1 ? 0 : 1
        );
      }
      if (horizontal) {
        setLeftO(target.scrollLeft > 0 ? 1 : 0);
        setRightO(
          Math.abs(target.scrollWidth - target.clientWidth - target.scrollLeft) <= 1 ? 0 : 1
        );
      }
    };
    if (element && element.current) {
      element.current.addEventListener('scroll', onScroll);
    }
    return () => {
      element.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div ref={element} className={`${classes} visual-scroll`}>
      <div className="scroll-container">
        {vertical && (
          <>
            <div className="scroll top" style={{ opacity: topO }}></div>
            <div className="scroll bottom" style={{ opacity: bottomO }}></div>
          </>
        )}
        {horizontal && (
          <>
            <div className="scroll left" style={{ opacity: leftO }}></div>
            <div className="scroll right" style={{ opacity: rightO }}></div>
          </>
        )}
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default VisualScroll;
