import { PropTypes } from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import DateTimePicker from './DateTimePicker';

const AsOfDateForm = ({ handleSubmit, onCancel }) => {
  return (
    <form className="form-inline" onSubmit={handleSubmit}>
      <Field name="from" id="from" component={DateTimePicker} className="small-date" />
      <Field name="until" id="until" component={DateTimePicker} className="small-date" />
      <button type="submit" className="button confirm ml-2">
        Ok
      </button>
      <button type="button" className="button ml-2" onClick={onCancel}>
        Cancel
      </button>
    </form>
  );
};

AsOfDateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'asOfDate' })(AsOfDateForm);
