import _ from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useAppContext } from '../../../providers/AppProvider';

export default function useQuickSelectDateObject(additionalOptions = [], removeItems = []) {
  const tz = useAppContext().timeZone;

  let quickSelect = useMemo(
    () => ({
      today: {
        id: 'today',
        name: 'Today',
        dateOperation: 'IsEqualTo',
        dateRange: { from: moment.tz(tz).startOf('day') },
      },
      yesterday: {
        id: 'yesterday',
        name: 'Yesterday',
        dateOperation: 'IsEqualTo',
        dateRange: { from: moment.tz(tz).subtract(1, 'days').startOf('day') },
      },
      thisWeek: {
        id: 'thisWeek',
        name: 'This Week',
        dateOperation: 'IsBetween',
        dateRange: {
          from: moment.tz(tz).weekday(0).startOf('day'),
          until: moment.tz(tz).weekday(6).endOf('day'),
        },
      },
      lastWeek: {
        id: 'lastWeek',
        name: 'Last Week',
        dateOperation: 'IsBetween',
        dateRange: {
          from: moment.tz(tz).weekday(-7).startOf('day'),
          until: moment.tz(tz).weekday(-1).endOf('day'),
        },
      },
      thisMonth: {
        id: 'thisMonth',
        name: 'This Month',
        dateOperation: 'IsBetween',
        dateRange: { from: moment.tz(tz).startOf('month'), until: moment.tz(tz).endOf('month') },
      },
      lastMonth: {
        id: 'lastMonth',
        name: 'Last Month',
        dateOperation: 'IsBetween',
        dateRange: {
          from: moment.tz(tz).subtract(1, 'months').startOf('month'),
          until: moment.tz(tz).subtract(1, 'months').endOf('month'),
        },
      },
      yearToDate: {
        id: 'yearToDate',
        name: 'Year to Date',
        dateOperation: 'IsOnAndAfter',
        dateRange: { from: moment.tz(tz).startOf('year'), until: moment.tz(tz) },
      },
      lastYearToDate: {
        id: 'lastYearToDate',
        name: 'Last Year',
        dateOperation: 'IsBetween',
        dateRange: {
          from: moment.tz(tz).subtract(1, 'years').startOf('year'),
          until: moment.tz(tz).subtract(1, 'years').endOf('year'),
        },
      },
    }),
    [tz]
  );

  if (additionalOptions.length > 0) {
    _.map(additionalOptions, (option) => {
      _.assign(quickSelect, {
        [option.name]: {
          id: option.name,
          name: option.displayName,
          dateOperation: 'IsBetween',
          dateRange: {
            from: option.from,
            until: option.until,
          },
        },
      });
    });
  }

  if (removeItems.length > 0) {
    _.map(removeItems, (item) => {
      delete quickSelect[item];
    });
  }

  return quickSelect;
}
