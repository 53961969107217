import React from 'react';
import { Field } from 'redux-form';

const DamageInformation = () => {
  return (
    <div>
      <div className="form-group row" style={{ marginBottom: '0' }}>
        <div className="col-sm-12">
          <label className="detail-info-label">Damage Information</label>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.damageInformation.preExistingCondition"
                id="preExistingCondition"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              Evidence of pre-existing condition
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.damageInformation.customerAdvicedBefore"
                id="customerAdvicedBefore"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              Customer advised before wash
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.damageInformation.itemNotFound"
                id="itemNotFound"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              Item not found
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.damageInformation.afterMarketItem"
                id="afterMarketItem"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              After market non-factory item
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="incidentDetails.damageInformation.oldVehicle"
                id="oldVehicle"
                className="form-check-input"
                component="input"
                type="checkbox"
              />{' '}
              Old vehicle
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DamageInformation;
