import { ModalBody } from 'reactstrap';
import { IntlType } from '../../../../utils/typing';
import CheckMark from '../CheckMark';

interface Props {
  intl: IntlType;
  isEmailSent: boolean;
}

const UserCreatedSuccessfully = ({ intl, isEmailSent }: Props): JSX.Element => {
  return (
    <ModalBody>
      <CheckMark>
        <div className="instructions">
          <span>{intl.formatMessage({ id: 'newEmployeeCreated' })}</span>
          {isEmailSent && (
            <>
              <br />
              <span>{intl.formatMessage({ id: 'emailSent' })}</span>
            </>
          )}
        </div>
      </CheckMark>
    </ModalBody>
  );
};

export default UserCreatedSuccessfully;
