// eslint-disable-next-line
export const ReportType = {
  ClaimsByDamageArea: 0,
  ClaimsByVehicleModel: 1,
};

export const ReportTableType = {
  Header: 0,
  Data: 1,
};
