import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { userHasTeamEnabledRedir } from '../core/auth';
import EmployeeViews from '../views/employees/components/EmployeeViews';
import Employee from '../views/employees/containers/Employees';
import Shifts from '../views/shifts/containers/Shifts';

const EmployeeRoute = () => {
  return (
    <EmployeeViews>
      <Switch>
        <Route exact path="/:site/team/">
          <Employee />
        </Route>
        <Route path="/:site/team/shifts">
          <Shifts />
        </Route>
      </Switch>
    </EmployeeViews>
  );
};

export default userHasTeamEnabledRedir(EmployeeRoute);
