import { arrayOf, func, number, shape, string } from 'prop-types';

const sharedPropTypes = {
  intl: shape({}).isRequired,
  fromDate: shape({ format: func }),
  untilDate: shape({ format: func }),
  dateQuery: shape({
    fromDate: string.isRequired,
    untilDate: string.isRequired,
  }),
};

// eslint-disable-next-line
export const MakeModelReportPropTypes = {
  ...sharedPropTypes,
  getCarModelReports: func.isRequired,
  getCarMakeReports: func.isRequired,
  removeCarModelReport: func.isRequired,
  carMakeReports: arrayOf(shape({})),
  carModelReports: shape({}),
  reclamationDamageOptions: arrayOf(
    shape({
      id: number.isRequired,
      value: string.isRequired,
      options: arrayOf(
        shape({
          id: number,
          value: string,
        })
      ),
    })
  ),
};

export const DamageAreaReportPropTypes = {
  ...sharedPropTypes,
  getCarPartReports: func.isRequired,
  carPartReports: arrayOf(shape({})),
};
