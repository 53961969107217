import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../../../core/actions';
import UserGroupPermissions, {
  viewPermissionRefs,
} from '../../../../core/components/UserGroupPermissions';
import { selectRole } from '../../../../store/authReducer';
import { IntlType } from '../../../../utils/typing';

interface Props {
  intl: IntlType;
}

const ManageUsersHeader = ({ intl }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const currentRole = useSelector(selectRole);

  const handleCreateUser = () => {
    dispatch(showDialog('CREATE_USER', {}));
  };

  return (
    <header className="d-flex justify-content-between align-items-center mb-3">
      <h3 className="mb-0">{intl.formatMessage({ id: 'activeUsers' })}</h3>
      <UserGroupPermissions itemRef={viewPermissionRefs.manageUsers}>
        <button className="button" onClick={handleCreateUser}>
          {intl.formatMessage({ id: 'createUser' })}
        </button>
      </UserGroupPermissions>
    </header>
  );
};

export default ManageUsersHeader;
