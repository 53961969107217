import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import UpsertDetergentDataForm from '../components/UpsertDetergentDataForm';

const UpsertDetergentDataDialog = ({
  closeDialog,
  isOpen,
  errors,
  title,
  triggerSubmit,
  onSubmit,
  inventoryInEdit,
}) => {
  const onReady = () => {
    triggerSubmit('upsertDetergentData');
  };

  const handleSubmit = (values) => {
    onSubmit({ id: inventoryInEdit.id, ...values });
  };

  return (
    <ModalDialog
      title={title}
      onReady={onReady}
      isOpen={isOpen}
      close={closeDialog}
      disabled={checkErrors(errors)}
    >
      <UpsertDetergentDataForm onSubmit={handleSubmit} initialValues={{ container: -1 }} />
    </ModalDialog>
  );
};

const getErrors = getFormSyncErrors('upsertDetergentData');

UpsertDetergentDataDialog.propTypes = {
  triggerSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  inventoryInEdit: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({ errors: getErrors(state) });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpsertDetergentDataDialog);
