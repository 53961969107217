export const UtilityType = {
  Water: 0,
  Electric: 1,
  Gas: 2,
  Sewer: 3,
};

export class Utility {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.start = props.start;
    this.end = props.end;
    this.startReading = props.startReading;
    this.endReading = props.endReading;
    this.cost = props.cost;
    this.currency = props.currency;
    this.type = props.type;
    this.unit = props.unit;
    this.siteId = props.siteId;
  }
}

export const PurchaseOrderStatus = {
  Received: 'Received',
  Open: 'Open',
  All: 'All',
};

export class PurchaseOrder {
  constructor(props = {}) {
    this.id = props.id;
    this.siteId = props.siteId;
    this.siteName = props.siteName;
    this.orderNumber = props.orderNumber;
    this.orderDate = props.orderDate;
    this.categoryId = props.categoryId;
    this.category = props.category;
    this.vendor = props.vendor;
    this.vendorId = props.vendorId;
    this.taxAmount = props.taxAmount;
    this.shippingAmount = props.shippingAmount;
    this.discount = props.discount;
    this.subTotal = props.subTotal;
    this.total = props.total;
    this.receivedAt = props.receivedAt;
    this.trackingNumber = props.trackingNumber;
    this.employeeId = props.employeeId;
    this.vendorName = props.vendorName;
    this.categoryName = props.categoryName;
    this.employee = props.employee;
    this.createdAt = props.createdAt;
    this.items = props.items;
    this.archivedAt = props.archivedAt;
    this.memo = props.memo;
    this.files = props.files;
  }
}
