import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import React, { memo } from 'react';
import DataTable from '../../../core/components/DataTable';
import useRefundData from '../hooks/useRefundData';
import useSummaryData from '../hooks/useSummaryData';
import { Refund } from '../models';

interface Props {
  intl: any;
  summaryCounts: {
    id: string;
    name: string;
    total: string;
    currency?: string;
    amount?: string;
    tax?: string;
  }[];
  refunds: Partial<Refund>[];
  loaders: { areRefundsLoading: boolean; areCoreCountsLoading: boolean };
}

const SummaryTable = (props: Props) => {
  const { intl, summaryCounts, refunds, loaders } = props;

  const summaryData = useSummaryData(summaryCounts);
  const refundData = useRefundData(refunds);

  const checkAllDataIsLoading = () => {
    return values(loaders).every((flag) => flag);
  };

  const getLoaderRow = () => {
    return [{ _id: 'loader', _loading: true, _classNames: ['dr-row-loader'] }];
  };

  const getTableData = () => {
    const { areCoreCountsLoading, areRefundsLoading } = loaders;

    const summaryRows = areCoreCountsLoading ? getLoaderRow() : summaryData.data;
    const refundRows = areRefundsLoading ? getLoaderRow() : refundData.data;
    return [...summaryRows, ...refundRows];
  };

  const getTableHeaders = () => {
    return [
      { id: 'summaryHeader1', name: intl.formatMessage({ id: 'name' }) },
      { id: 'summaryHeader2', name: intl.formatMessage({ id: 'amount' }) },
      { id: 'summaryHeader3', name: intl.formatMessage({ id: 'totalTax' }) },
      { id: 'summaryHeader4', name: intl.formatMessage({ id: 'total' }) },
    ];
  };

  const getParentRowName = (counts: typeof summaryCounts | any[], id: string) => {
    const parentRow = counts.find((item) => item.id === id);

    if (!parentRow) {
      return null;
    }

    return parentRow.name;
  };

  const getSubRows = () => {
    return [
      {
        parentRow: getParentRowName(summaryCounts, 'cde-expected-cash-sales-summary'),
        rows: { data: summaryData.cashDrawerSubRows },
      },
      {
        parentRow: getParentRowName(summaryCounts, 'pm-total-pos-sales'),
        rows: { data: summaryData.totalPosSalesSubRows },
      },
      {
        parentRow: getParentRowName(refunds, 'pm-pay-as-you-go-refunds'),
        rows: { data: refundData.subRows },
      },
    ];
  };

  const getSubRowInfo = () => {
    return {
      identifier: 'name',
      displayProperty: 'name',
      subRows: getSubRows(),
    };
  };

  const initializeDataTable = () => {
    return {
      headers: getTableHeaders(),
      data: getTableData(),
      addedClass: 'wash-count-table shrinked-table',
      subRowInfo: getSubRowInfo(),
      showLoader: checkAllDataIsLoading(),
    };
  };
  return (
    <section className="wc-table-container">
      <h2 className="wc-table-header">{intl.formatMessage({ id: 'summary' })}</h2>
      <DataTable {...initializeDataTable()} />
    </section>
  );
};

export default memo(SummaryTable, isEqual);
