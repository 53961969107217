import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';

export default function useLogEngine(
  baselineLookUp,
  parentLabel,
  childKey,
  childLabel,
  history,
  setHistory,
  setOutputLog
) {
  const _rollupChildEntry = (entries, baseEntries, parentId) => {
    const editingBase = {};
    let rollup = {};

    _forEach(entries, (child) => {
      const childId = child.id.split(':')[1];
      const childIndex = child.change[childKey].findIndex((c) => c._uniqId === childId);

      // populates editingBase with the original state of object by unique parentId prior to all changes
      if (
        baseEntries.includes(parentId) &&
        (!editingBase[parentId] || (editingBase[parentId] && !editingBase[parentId][childId]))
      ) {
        const baselineChangeIndex = baselineLookUp.current[parentId].change[childKey].findIndex(
          (c) => c._uniqId === childId
        );
        editingBase[parentId] = {
          ...editingBase[parentId],
          [childId]: _omit(baselineLookUp.current[parentId].change[childKey][baselineChangeIndex], [
            'type',
            'scope',
          ]),
        };
      }

      const pLabel = child.change[parentLabel];
      const label =
        child.change[childKey].length &&
        childIndex !== -1 &&
        child.change[childKey][childIndex][childLabel];
      switch (child.type) {
        case 'remove': {
          const workingCopy = _cloneDeep(editingBase);
          // needs to display the original item being removed
          let removedLabel = '';
          if (baselineLookUp.current[parentId] && childIndex !== -1) {
            removedLabel =
              baselineLookUp.current[parentId].change[childKey][childIndex][childLabel];
          } else {
            removedLabel = workingCopy[parentId][childId][childLabel];
          }

          rollup = {
            ...rollup,
            [childId]: {
              text: `REMOVED OPTION: ${removedLabel} under ${pLabel}`,
              id: child.id,
              type: child.type,
            },
          };

          editingBase[parentId] = {
            ...editingBase[parentId],
            [childId]: undefined,
          };
          break;
        }

        case 'add':
          rollup = {
            ...rollup,
            [childId]: {
              text: `ADDED OPTION: ${label} under ${pLabel}`,
              id: child.id,
              type: child.type,
            },
          };

          editingBase[parentId] = {
            ...editingBase[parentId],
            [childId]: {
              id: child.id,
              [childLabel]: label,
            },
          };
          break;

        case 'edit': {
          const workingCopy = _cloneDeep(editingBase);
          const prev = workingCopy[parentId][childId][childLabel];
          rollup = {
            ...rollup,
            [childId]: {
              text: `EDIT OPTION: from ${prev} -> ${label} under ${pLabel}`,
              id: child.id,
              type: child.type,
            },
          };

          editingBase[parentId] = {
            ...editingBase[parentId],
            [childId]: {
              id: child.id,
              [childLabel]: label,
            },
          };
          break;
        }

        default:
          break;
      }
    });

    return rollup;
  };

  const generateLog = useCallback(() => {
    const parentRemovalIds = [];
    const parentRemovalUniqIds = [];
    const parentRemovals = history
      .filter((hist) => hist.type === 'remove' && hist.scope === 'parent')
      .map((hist) => {
        parentRemovalIds.push(hist.id);
        parentRemovalUniqIds.push(hist.change._uniqId);
        return {
          text: `REMOVED: ${hist.change[parentLabel]} Category`,
          id: hist.id,
          type: hist.type,
        };
      });

    const presentParentIds = [];
    const parentAdditions = history
      .filter(
        (hist) =>
          hist.type === 'add' &&
          hist.scope === 'parent' &&
          !parentRemovalIds.includes(hist.id) &&
          !parentRemovalUniqIds.includes(hist.change._uniqId)
      )
      .map((hist) => {
        presentParentIds.push(hist.id);
        return {
          text: `ADDED: ${hist.change[parentLabel]} Category`,
          id: hist.id,
          type: hist.type,
        };
      });

    const childEntries = history.filter((hist) => hist.scope === 'child');

    const childRollUps = {};
    const base = Object.keys(baselineLookUp.current).filter(
      (key) => !parentRemovalIds.includes(key)
    );

    presentParentIds.concat(base).forEach((parentId) => {
      // eslint-disable-next-line
      const entries = childEntries.filter((child) => child.id.split(':')[0] == parentId);
      childRollUps[parentId] = _rollupChildEntry(entries, base, parentId);
    });

    const output = [];

    // lone edits
    _forEach(childRollUps, (childRollUp, key) => {
      if (!presentParentIds.includes(key)) {
        _forEach(childRollUp, (roll) => {
          output.push(roll);
        });
      }
    });

    // additions with edits
    parentAdditions.forEach((parent) => {
      output.push(parent);
      _forEach(childRollUps[parent.id], (childRollUp) => {
        output.push(childRollUp);
      });
    });

    // removals
    parentRemovals.forEach((parent) => {
      output.push(parent);
    });

    setOutputLog(output);
  }, [history, parentLabel, childKey, childLabel, baselineLookUp.current]);

  const undo = useCallback(
    (id, type) => {
      const rebuildDraft = _cloneDeep(baselineLookUp.current);
      /*
       * for loop to find the undone change by iterating through history from newest to oldest
       * we want to iterate like this based on how the change log entries get rolled up in generateLog
       */
      let hasParentAddition = false;
      for (let index = history.length - 1; index >= 0; index--) {
        const element = history[index];
        if (element.id === id && element.type === type) {
          let cloneHist = _cloneDeep(history);
          cloneHist.splice(index, 1);

          // handle undone parent adds and the orphaned entries that follow
          let leavesOrphans = false;
          if (element.type === 'add' && element.scope === 'parent') {
            leavesOrphans = true;
            cloneHist = cloneHist.filter(
              (cHist) => cHist.id.split(':')[0] !== element.id.split(':')[0]
            );
          }

          // eslint-disable-next-line
          cloneHist.forEach((cHist) => {
            const ids = cHist.id.split(':');
            const isOrphan = leavesOrphans && ids[0] === element.id;
            const childIndex = cHist.change[childKey].findIndex(
              (child) => child._uniqId === ids[1]
            );
            if (!isOrphan && cHist.type === 'remove' && ids.length === 2) {
              rebuildDraft[ids[0]][childKey].splice(childIndex, 1);
            }

            if (!isOrphan && cHist.type === 'edit' && ids.length === 2) {
              rebuildDraft[ids[0]][childKey][childIndex] = cHist.change[childKey][childIndex];
            }

            if (!isOrphan && cHist.type === 'add' && ids.length === 2) {
              rebuildDraft[ids[0]][childKey].push(cHist.change[childKey][childIndex]);
            }

            if (cHist.type === 'remove' && ids.length === 1) {
              delete rebuildDraft[ids[0]];
            }

            // handles parent adds
            if (cHist.type === 'add' && ids.length === 1) {
              hasParentAddition = true;
              rebuildDraft[ids[0]] = {
                [parentLabel]: cHist.change[parentLabel],
                _uniqId: ids[0],
                [childKey]: [],
              };
            }

            if (cHist.type === 'base') {
              rebuildDraft[ids[0]] = _cloneDeep(baselineLookUp.current[ids[0]].change);
            }
          });

          setHistory(cloneHist);
          break;
        }
      }

      return hasParentAddition
        ? Object.values(rebuildDraft).reverse()
        : Object.values(rebuildDraft);
    },
    [history, baselineLookUp.current]
  );

  return useMemo(
    () => ({
      generateLog,
      undo,
    }),
    [generateLog, undo]
  );
}
