import { ModalBody } from 'reactstrap';
import CheckMark from '../CheckMark';

interface Props {
  text: string;
}

const ActionCompletedSuccessfully = ({ text }: Props): JSX.Element => {
  return (
    <ModalBody>
      <CheckMark>
        <div className="instructions">
          <span>{text}</span>
        </div>
      </CheckMark>
    </ModalBody>
  );
};

export default ActionCompletedSuccessfully;
