import React from 'react';
import { hideDialog } from '../../../core/actions';
import DependencyModal from './DependencyModal';
import TaskLink from './TaskLink';
import { filter, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectTasks } from '../../tasks/reducer';
import { UncontrolledCollapse } from 'reactstrap';

/**
 * DependencyWarningDialog component to display a modal with a title, children, and buttons to submit or cancel.
 * It also displays a warning message about the task's dependencies.
 * @param {*} props
 * @return {*} JSX.Element
 */
const DependencyWarningDialog = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const closeDialog = () => dispatch(hideDialog());
  const getTasks = useSelector(selectTasks);

  const matchedTasks = filter(getTasks, (task) => {
    return props.selectedTasks[task.id];
  });

  const title = 'Warning';
  return (
    <DependencyModal
      intl={intl}
      className="enhanced-dialog medium padded"
      title={title}
      completeTask={props.completeTask}
      snoozeTask={props?.snoozeTask}
      isOpen={props.isOpen}
      close={closeDialog}
      showFooter={true}
    >
      <>
        <h2>
          {intl.formatMessage({ id: 'warning' })}:{' '}
          {intl.formatMessage({ id: 'taskDependenciesPresent' })}
        </h2>
        <p>{intl.formatMessage({ id: 'taskDependenciesDescription' })}</p>
        {map(matchedTasks, (task, i) => {
          return (
            <div key={i} className="mb-2">
              <div style={{ display: 'flex' }}>
                <h3 id={`toggler${i}`}>{task.name}</h3>
                <i className="icon icon-chevron-down" />{' '}
              </div>
              <UncontrolledCollapse toggler={`#toggler${i}`} defaultOpen>
                <hr />
                <TaskLink task={task} intl={intl} />
              </UncontrolledCollapse>
            </div>
          );
        })}
      </>
    </DependencyModal>
  );
};

export default DependencyWarningDialog;
