import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectPermissions, selectRole } from '../../store/authReducer';
import Permission from './Permission';

export const PermissionKeys = {
  addEditPurchaseOrders: 'addEditPurchaseOrders',
  addEditUtils: 'addEditUtils',
  addVendors: 'addVendors',
  deleteArchiveVendors: 'deleteArchiveVendors',
  deletePurchaseOrders: 'deletePurchaseOrders',
  deleteUtils: 'deleteUtils',
  editCashDrawerEvents: 'editCashDrawerEvents',
  receivePurchaseOrders: 'receivePurchaseOrders',
  viewPurchaseOrdersTab: 'viewPurchaseOrdersTab',
  viewUtilsTab: 'viewUtilsTab',
  viewWashCountsPage: 'viewWashCountsPage',
  analytics: 'analytics',
};

export const hasUserPermission = (key) => {
  return PermissionKeys[key];
};

export const UserPermissions = ({ permissionKey, children, permissions, role, minRole }) => {
  if (!permissionKey || !permissions) {
    return (
      <Permission role={role} minRole={minRole}>
        {children}
      </Permission>
    );
  }

  if (permissionKey === PermissionKeys.analytics) {
    return permissions[permissionKey].enabled ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <React.Fragment />
    );
  }

  return permissions[permissionKey] ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <React.Fragment />
  );
};

UserPermissions.defaultProps = {
  minRole: 'SiteManager',
};

UserPermissions.propTypes = {
  /* *Permission Key takes a string of the permission name: i.e. 'addUser' */
  permissionKey: PropTypes.string,
  children: PropTypes.node,
  permissions: PropTypes.shape({}),
  role: PropTypes.string,
  minRole: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    permissions: selectPermissions(state),
    role: selectRole(state),
  };
};

export default connect(mapStateToProps, null)(UserPermissions);
