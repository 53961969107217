import { API_REQUEST } from '../core/actions';
import { REFRESH_REQUEST } from '../views/login/actions';

const getRequestCountModifierAction = (actionType) => {
  const typeWithoutSuffix = actionType.replace(/(_.[A-Z]*$)/gi, '');

  if (actionType.endsWith('_REQUEST')) {
    return { type: 'INCREASE_API_REQUEST_COUNT', data: typeWithoutSuffix };
  }

  return { type: 'DECREASE_API_REQUEST_COUNT', data: typeWithoutSuffix };
};

const apiRequestCounter = (state) => (next) => (action) => {
  const isApiRequest =
    action.type !== REFRESH_REQUEST &&
    action.type !== API_REQUEST &&
    action.type.match(/(_FAILURE$|_SUCCESS$|_REQUEST$)/gi);

  if (isApiRequest) {
    const dispatchAction = getRequestCountModifierAction(action.type);

    state.dispatch(dispatchAction);
  }

  next(action);
};

export default apiRequestCounter;
