import { TaskCompletion } from '../tasks/models';

export const initialState = {
  taskCompletions: [],
  total: 0,
  page: 0,
  pageSize: 50,
};

const getTaskCompletionsForTaskSuccess = (state, action) => {
  const taskCompletions = action.results.map((t) => new TaskCompletion(t));
  return {
    ...state,
    taskCompletions,
    page: action.page,
    total: action.total,
    pageSize: action.pageSize,
  };
};

const clearTaskCompletionsForTask = (state) => {
  return {
    ...state,
    ...initialState,
  };
};

export const taskDetail = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TASK_COMPLETIONS_FOR_TASK_SUCCESS':
      return getTaskCompletionsForTaskSuccess(state, action.response);
    case 'CLEAR_TASK_COMPLETIONS_FOR_TASK':
      return clearTaskCompletionsForTask(state);
    default:
      return state;
  }
};

export const selectTaskCompletionsForTask = (state) => {
  return state.taskDetail.taskCompletions;
};

export const selectTaskCompletionsForTaskTotal = (state) => {
  return state.taskDetail.total;
};

export const selectTaskCompletionsForTaskPage = (state) => {
  return state.taskDetail.page;
};

export const selectTaskCompletionsForTaskPageSize = (state) => {
  return state.taskDetail.pageSize;
};
